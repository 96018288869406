import { useRef } from 'react';

import { SIP } from '@constants';
import { useWebAppSelector, selectSIPDataCallsCapabilities } from '@redux';

import { sipDebugConsoleLogger } from '../../../../../../../helpers';

import { webRTCMOSGetAccumulatedRawData, webRTCMOSIdentifyReportObject, webRTCMOSResults } from './helpers';
import {
  UseWebRTCConnectionsMOS,
  WebRTCMOSIntervals,
  WebRTCMOSData,
  WebRTCMOSStartProps,
  WebRTCMOSStart,
  WebRTCMOSStopAndGetResults,
  WebRTCMOSResultsReturn,
} from './types';

export type { WebRTCMOSStart, WebRTCMOSStopAndGetResults, WebRTCMOSDataStats } from './types';

export const useWebRTCConnectionsMOS: UseWebRTCConnectionsMOS = () => {
  const { isCallsWebRTCHdCodecEnabled } = useWebAppSelector(selectSIPDataCallsCapabilities);

  const refMOSIntervals = useRef<WebRTCMOSIntervals>({});
  const refMOSDataList = useRef<WebRTCMOSData[]>([]);

  const mosDataListAccumulator = async ({
    idWebRTC,
    track,
    connectionGetStats,
  }: WebRTCMOSStartProps): Promise<void> => {
    try {
      const rtcStatsReport: RTCStatsReport = await connectionGetStats(track);

      rtcStatsReport.forEach((WebRTCReportObject: Record<string, unknown>) => {
        /**
         * Identify and Return
         * If the WebRTCReportObject is not related to MOS
         */
        const { isWebRTCReportObjectRelatedToMOS } = webRTCMOSIdentifyReportObject({ WebRTCReportObject });
        if (isWebRTCReportObjectRelatedToMOS === false) {
          return;
        }

        /**
         * Accumulate the Existing MOS Data List with WebRTCReportObject
         */
        const { mosDataListNew } = webRTCMOSGetAccumulatedRawData({
          WebRTCReportObject,
          mosDataListExisting: refMOSDataList.current,
          idWebRTC,
          isCallMuted: track.muted,
          isCodecPCMA: isCallsWebRTCHdCodecEnabled,
        });

        /**
         * Update Existing MOS Data
         */
        refMOSDataList.current = mosDataListNew;
      });
    } catch {
      // ignore
    }
  };

  const webRTCMOSStart: WebRTCMOSStart = ({ idWebRTC, track, connectionGetStats }): void => {
    sipDebugConsoleLogger('SIP WebRTC MOS: CALCULATION START TRIGGERED');

    /**
     * CHECK AVAILABILITY, and JUST RETURN
     */
    if (SIP.CALL_MOS.ENABLED === false) {
      sipDebugConsoleLogger('SIP WebRTC MOS: UNAVAILABLE');
      return;
    }

    /**
     * Prevent Duplications
     *
     * During a Call => if "HOLD", "UNHOLD", "NETWORK CHANGES", "ETC..." is happening,
     * This function will be triggered again by the "connection.track" listeners.
     * But the existing connection is there, we are still getting stats,
     * and the accumulations is continuing with the existing interval already.
     * So, we are checking and preventing to create another interval/duplications for no reason.
     */
    const mosIntervalsExisting: WebRTCMOSIntervals = refMOSIntervals.current;
    const hasMOSInterval = mosIntervalsExisting[idWebRTC] !== undefined;
    if (hasMOSInterval) {
      sipDebugConsoleLogger('SIP WebRTC MOS: CALCULATION START PREVENTED');
      return;
    }

    /**
     * Start Calculation
     *
     * Decreasing the Every Delay (Millisecond) on Interval will increase the MOS accuracy
     * for short calls (like 10 seconds of calls, etc...)
     * But, for long calls (5 minutes and more, like we have 1-2 hours of calls, etc...)
     * we will collect a significant amount of data (like thousands)
     * and accumulating (like thousands of times thousands => millions) this data
     * will cause serious hardware/performance issues.
     *
     * Conclusion,
     * less than 1000 ms is "definitely not recommended (by @furkan)"
     * (customers' hardware 99% will cause problems)
     */
    sipDebugConsoleLogger('SIP WebRTC MOS: CALCULATION STARTED');
    const intervalHandler = (): void => {
      sipDebugConsoleLogger('SIP WebRTC MOS: CALCULATION LOOPING');
      mosDataListAccumulator({ idWebRTC, track, connectionGetStats });
    };
    const mosInterval = window.setInterval(intervalHandler, SIP.CALL_MOS.CALCULATION_INTERVAL_MILLISECOND);
    intervalHandler();

    /**
     * Set the Interval to the REF Intervals
     * (for the cleanup purposes) => "webRTCMOSStopAndGetResults"
     */
    const mosIntervalsNew: WebRTCMOSIntervals = {
      ...mosIntervalsExisting,
      [idWebRTC]: mosInterval,
    };
    refMOSIntervals.current = mosIntervalsNew;
  };

  const webRTCMOSStopAndGetResults: WebRTCMOSStopAndGetResults = ({ idWebRTC }): WebRTCMOSResultsReturn => {
    sipDebugConsoleLogger('SIP WebRTC MOS: CALCULATION STOP TRIGGERED');

    /**
     * CHECK AVAILABILITY, and JUST RETURN
     */
    if (SIP.CALL_MOS.ENABLED === false) {
      sipDebugConsoleLogger('SIP WebRTC MOS: UNAVAILABLE');
      return webRTCMOSResults({
        isCodecPCMA: isCallsWebRTCHdCodecEnabled,
        mosData: undefined,
      });
    }

    /**
     * CHECK and JUST RETURN
     * If there is no associated Interval by WebRTC ID
     */
    const mosIntervalsExisting: WebRTCMOSIntervals = refMOSIntervals.current;
    const mosInterval: WebRTCMOSIntervals[0] = mosIntervalsExisting[idWebRTC];
    if (mosInterval === undefined) {
      sipDebugConsoleLogger('SIP WebRTC MOS: CALCULATION STOP PREVENTED');
      return webRTCMOSResults({
        isCodecPCMA: isCallsWebRTCHdCodecEnabled,
        mosData: undefined,
      });
    }

    /**
     * CLEAR and UPDATE INTERVALS
     * removing the interval key and value from the list by WebRTC ID
     */
    window.clearInterval(mosInterval);
    const mosIntervalsNew: WebRTCMOSIntervals = Object.fromEntries(
      Object.entries(mosIntervalsExisting).filter(([key]) => key !== idWebRTC),
    );
    refMOSIntervals.current = mosIntervalsNew;
    sipDebugConsoleLogger('SIP WebRTC MOS: CALCULATION STOP SUCCEEDED: Interval Cleared');

    /**
     * CLEAR and UPDATE MOS DATA
     * by WebRTC ID
     */
    const mosDataListExisting: WebRTCMOSData[] = refMOSDataList.current;
    const mosDataByWebRTCId: WebRTCMOSData | undefined = mosDataListExisting.find(
      ({ idWebRTC: id }) => id === idWebRTC,
    );
    const mosDataListNew: WebRTCMOSData[] = mosDataListExisting.filter(({ idWebRTC: id }) => id !== idWebRTC);
    refMOSDataList.current = mosDataListNew;
    sipDebugConsoleLogger('SIP WebRTC MOS: CALCULATION STOP SUCCEEDED: Data Cleared and Returned');

    /**
     * RETURN MOS
     */
    const mosResults = webRTCMOSResults({
      isCodecPCMA: isCallsWebRTCHdCodecEnabled,
      mosData: mosDataByWebRTCId,
    });
    sipDebugConsoleLogger('SIP WebRTC MOS: RESULTS: ', { mosResults });

    return mosResults;
  };

  return { webRTCMOSStart, webRTCMOSStopAndGetResults };
};
