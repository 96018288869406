import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast, showSuccessToast } from '@onoff/toast-notification';

import { Status } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  privacyBlockNumberHandler,
  privacyUnblockNumberHandler,
  privacySetStatusBlockNumber,
  privacySetStatusUnblockNumber,
  selectPrivacyIsNumberBlocked,
  selectPrivacyStatuses,
} from '@redux';
import { testIds } from '@testing';
import { IconCommonNames } from 'components/Icon';

import { InfoTopBarActionsDropdownFeaturesNumberBlockProps } from '../../types';
import InfoTopBarActionsDropdownItem from '../InfoTopBarActionsDropdownItem';

const InfoTopBarActionsDropdownFeaturesNumberBlock: React.FC<InfoTopBarActionsDropdownFeaturesNumberBlockProps> = ({
  phoneNumberOrEmpty,
}) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const { blockNumber: statusBlockNumber, unblockNumber: statusUnblockNumber } =
    useWebAppSelector(selectPrivacyStatuses);
  const isPhoneNumberBlocked = useWebAppSelector(selectPrivacyIsNumberBlocked(phoneNumberOrEmpty));

  const onClickHandlerPhoneNumberBlockUnblock = (): void => {
    if (isPhoneNumberBlocked) {
      dispatch(privacyUnblockNumberHandler(phoneNumberOrEmpty));
    } else {
      dispatch(privacyBlockNumberHandler(phoneNumberOrEmpty));
    }
  };

  const callbackPhoneNumberBlockHandler = (): void => {
    if ([Status.SUCCEEDED, Status.FAILED].includes(statusBlockNumber)) {
      dispatch(privacySetStatusBlockNumber(Status.IDLE));

      if (statusBlockNumber === Status.SUCCEEDED) {
        showSuccessToast({
          heading: intl.formatMessage({ id: 'Settings.Blocked.Notifications.block_success_title' }),
          message: intl.formatMessage({ id: 'Settings.Blocked.Notifications.block_success_description' }),
        });
      }

      if (statusBlockNumber === Status.FAILED) {
        showErrorToast({
          heading: intl.formatMessage({ id: 'Settings.Blocked.Notifications.block_error_title' }),
          message: intl.formatMessage({ id: 'Settings.Blocked.Notifications.block_error_description' }),
        });
      }
    }
  };

  const callbackPhoneNumberUnBlockHandler = (): void => {
    if ([Status.SUCCEEDED, Status.FAILED].includes(statusUnblockNumber)) {
      dispatch(privacySetStatusUnblockNumber(Status.IDLE));

      if (statusUnblockNumber === Status.SUCCEEDED) {
        showSuccessToast({
          heading: intl.formatMessage({ id: 'Settings.Blocked.Notifications.unblock_success_title' }),
          message: intl.formatMessage({ id: 'Settings.Blocked.Notifications.unblock_success_description' }),
        });
      }

      if (statusUnblockNumber === Status.FAILED) {
        showErrorToast({
          heading: intl.formatMessage({ id: 'Settings.Blocked.Notifications.unblock_error_title' }),
          message: intl.formatMessage({ id: 'Settings.Blocked.Notifications.unblock_error_description' }),
        });
      }
    }
  };

  useEffect(callbackPhoneNumberBlockHandler, [statusBlockNumber, dispatch, intl]);

  useEffect(callbackPhoneNumberUnBlockHandler, [statusUnblockNumber, dispatch, intl]);

  return (
    <InfoTopBarActionsDropdownItem
      iconName={isPhoneNumberBlocked ? IconCommonNames.REMOVE_OUTLINED : IconCommonNames.BLOCK}
      label={intl.formatMessage({
        id: `InfoTopBar.Actions.Dropdown.button_number_block_${isPhoneNumberBlocked ? 'remove' : 'add'}`,
      })}
      onClick={onClickHandlerPhoneNumberBlockUnblock}
      isDisabled={[statusBlockNumber, statusUnblockNumber].includes(Status.LOADING)}
      propTestId={
        isPhoneNumberBlocked
          ? testIds.infoTopBarActionsDropdownButtonPhoneNumberUnBlock
          : testIds.infoTopBarActionsDropdownButtonPhoneNumberBlock
      }
    />
  );
};

export default InfoTopBarActionsDropdownFeaturesNumberBlock;
