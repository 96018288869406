import React from 'react';

import { StyleableComponent } from 'types';

const BlockingEmpty: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.9871 61.0418L69.7588 63.7022L72.6866 64.9872L70.0261 66.7595L68.7412 69.6866L66.9695 67.0255L64.0417 65.7413L66.7021 63.9696L67.9871 61.0418Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.37823 11.0125L6.87987 13.2674L9.36144 14.3565L7.10649 15.8588L6.01737 18.3397L4.51573 16.0842L2.03417 14.9957L4.2891 13.494L5.37823 11.0125Z"
      fill="#E4E4E4"
    />
    <g>
      <path
        d="M39.9998 14.1667C25.7398 14.1667 14.1665 25.74 14.1665 40C14.1665 54.26 25.7398 65.8334 39.9998 65.8334C54.2598 65.8334 65.8332 54.26 65.8332 40C65.8332 25.74 54.2598 14.1667 39.9998 14.1667ZM19.3332 40C19.3332 28.5817 28.5815 19.3334 39.9998 19.3334C44.779 19.3334 49.1707 20.9609 52.6582 23.6992L23.699 52.6584C20.9607 49.1709 19.3332 44.7792 19.3332 40ZM39.9998 60.6667C35.2207 60.6667 30.829 59.0392 27.3415 56.3008L56.3007 27.3417C59.039 30.8292 60.6665 35.2209 60.6665 40C60.6665 51.4184 51.4182 60.6667 39.9998 60.6667Z"
        fill="#ECEDEE"
      />
    </g>
    <g>
      <circle
        cx="40"
        cy="40"
        r="19"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M39.9998 16.6667C27.1198 16.6667 16.6665 27.12 16.6665 40C16.6665 52.88 27.1198 63.3334 39.9998 63.3334C52.8798 63.3334 63.3332 52.88 63.3332 40C63.3332 27.12 52.8798 16.6667 39.9998 16.6667ZM21.3332 40C21.3332 29.6867 29.6865 21.3334 39.9998 21.3334C44.3165 21.3334 48.2832 22.8034 51.4332 25.2767L25.2765 51.4334C22.8032 48.2834 21.3332 44.3167 21.3332 40ZM39.9998 58.6667C35.6832 58.6667 31.7165 57.1967 28.5665 54.7234L54.7232 28.5667C57.1965 31.7167 58.6665 35.6834 58.6665 40C58.6665 50.3134 50.3132 58.6667 39.9998 58.6667Z"
        fill="#E1E1E1"
      />
    </g>
  </svg>
);

export default BlockingEmpty;
