import { isNumber, isString } from 'helpers';

import { SIP } from '@constants';

import { WebRTCMOSReportTypes, WebRTCMOSStatNames, WebRTCMOSResultDescriptions } from '../enums';
import { WebRTCMOSData, WebRTCMOSDataStats } from '../types';

import {
  WebRTCMOSIdentifyReportObjectProps,
  WebRTCMOSIdentifyReportObjectReturn,
  WebRTCMOSGetAccumulatedRawDataProps,
  WebRTCMOSGetAccumulatedRawDataReturn,
  WebRTCMOSProcessRawDataProps,
  WebRTCMOSProcessRawDataReturn,
  WebRTCMOSCalculateProps,
  WebRTCMOSCalculateReturn,
  WebRTCMOSDescriptionProps,
  WebRTCMOSDescriptionReturn,
  WebRTCMOSNetworkTypeProps,
  WebRTCMOSNetworkTypeReturn,
  WebRTCMOSResultsProps,
  WebRTCMOSResultsReturn,
} from './types';

export const webRTCMOSFallbackStatsFilter = (stat: number | string): boolean => {
  if (isNumber(stat)) {
    return stat > 0;
  }
  return stat.trim().length > 0;
};

export const webRTCMOSFormatter = (MOS: number): number => {
  /**
   * Formatted, because: 2.49999 should be 2.49, not 2.50
   */
  const mosFormatted = Math.floor(MOS * 10 ** 2) / 10 ** 2;
  return mosFormatted || 0;
};

export const webRTCMOSIdentifyReportObject = ({
  WebRTCReportObject = {},
}: WebRTCMOSIdentifyReportObjectProps): WebRTCMOSIdentifyReportObjectReturn => {
  /**
   * We are extracting only the MOS related stats
   * Otherwise, just return
   * https://www.w3.org/TR/webrtc-stats/#summary
   */
  const reportTypesRelated: string[] = [
    // packets sent || received
    WebRTCMOSReportTypes.CANDIDATE_PAIR,
    // packets sent || received
    WebRTCMOSReportTypes.TRANSPORT,
    // network type
    WebRTCMOSReportTypes.LOCAL_CANDIDATE,
  ];
  const { type } = WebRTCReportObject;
  const reportType = isString(type) ? type : '';
  const isReportTypeRelated = reportTypesRelated.includes(reportType) === true;

  const isPacketsSent: boolean = Object.prototype.hasOwnProperty.call(
    WebRTCReportObject,
    WebRTCMOSStatNames.PACKETS_SENT,
  );
  const isPacketsReceived: boolean = Object.prototype.hasOwnProperty.call(
    WebRTCReportObject,
    WebRTCMOSStatNames.PACKETS_RECEIVED,
  );
  const isNetworkType: boolean = Object.prototype.hasOwnProperty.call(
    WebRTCReportObject,
    WebRTCMOSStatNames.NETWORK_TYPE,
  );

  const isWebRTCReportObjectRelatedToMOS: boolean =
    isReportTypeRelated && [isPacketsSent, isPacketsReceived, isNetworkType].some((theseStats) => theseStats === true);

  return { isWebRTCReportObjectRelatedToMOS };
};

export const webRTCMOSGetAccumulatedRawData = ({
  WebRTCReportObject,
  mosDataListExisting,
  idWebRTC,
  isCallMuted,
  isCodecPCMA,
}: WebRTCMOSGetAccumulatedRawDataProps): WebRTCMOSGetAccumulatedRawDataReturn => {
  /**
   * Existing or Init MOS Data Stats by WebRTC ID
   */
  const mosDataStatsInit: WebRTCMOSDataStats = {
    diffPacketsSentPacketsReceivedOnMute: 0,
    listPacketsSent: [],
    listPacketsReceived: [],
    listNetworkType: [],
    networkChangedDuringTheCall: 'No',
  };
  const mosDataStatsExisting: WebRTCMOSDataStats =
    mosDataListExisting.find(({ idWebRTC: id }) => id === idWebRTC)?.stats || mosDataStatsInit;

  /**
   * Each Stat by WebRTCReportObject
   */
  const rawPacketSent = WebRTCReportObject[WebRTCMOSStatNames.PACKETS_SENT];
  const rawPacketReceived = isCodecPCMA ? rawPacketSent : WebRTCReportObject[WebRTCMOSStatNames.PACKETS_RECEIVED];
  const rawNetworkType = WebRTCReportObject[WebRTCMOSStatNames.NETWORK_TYPE];

  /**
   * Previous Stats (Lists)
   */
  const previousListPacketsSentAsFiltered: number[] =
    mosDataStatsExisting.listPacketsSent.filter(webRTCMOSFallbackStatsFilter);
  const previousListPacketsSentAsFilteredLastPacketsIndex: number = previousListPacketsSentAsFiltered.length - 1;
  const previousListPacketsReceivedAsFiltered: number[] =
    mosDataStatsExisting.listPacketsReceived.filter(webRTCMOSFallbackStatsFilter);
  const previousListPacketsReceivedAsFilteredLastPacketsIndex: number =
    previousListPacketsReceivedAsFiltered.length - 1;

  /**
   * Previous Stats (Packets)
   */
  const previousPacketSentLatest: number =
    previousListPacketsSentAsFiltered[previousListPacketsSentAsFilteredLastPacketsIndex] || 0;
  const previousPacketReceivedLatest: number =
    previousListPacketsReceivedAsFiltered[previousListPacketsReceivedAsFilteredLastPacketsIndex] || 0;

  /**
   * Packets Sent (New one)
   */
  const packetSentNew: number = isNumber(rawPacketSent) ? rawPacketSent : previousPacketSentLatest;

  /**
   * Packets Received (New one + The Diff By Packets Sent On Mute)
   */
  const packetReceivedNew: number = isNumber(rawPacketReceived) ? rawPacketReceived : previousPacketReceivedLatest;
  const diffPacketsSentPacketsReceivedOnMuteNew = isCallMuted
    ? Math.abs(packetSentNew - packetReceivedNew)
    : mosDataStatsExisting.diffPacketsSentPacketsReceivedOnMute;
  const packetReceivedNewWithMute: number = diffPacketsSentPacketsReceivedOnMuteNew + packetReceivedNew;

  /**
   * Network Type (New one)
   */
  const networkType: string = isString(rawNetworkType) && rawNetworkType.length > 0 ? rawNetworkType : '';

  /**
   * New and Accumulated MOS Data Stats
   */
  const listPacketsSentNew: number[] = [...mosDataStatsExisting.listPacketsSent, packetSentNew];
  const listPacketsReceivedNew: number[] = [...mosDataStatsExisting.listPacketsReceived, packetReceivedNewWithMute];
  const listNetworkTypeNew: string[] = [...mosDataStatsExisting.listNetworkType, networkType].filter(
    webRTCMOSFallbackStatsFilter,
  );
  const isNetworkChanged = Array.from(new Set(listNetworkTypeNew)).length > 1;
  const networkChangedDuringTheCall = isNetworkChanged ? 'Yes' : 'No';

  /**
   * New MOS Data
   */
  const mosDataNew: WebRTCMOSData = {
    idWebRTC,
    stats: {
      diffPacketsSentPacketsReceivedOnMute: diffPacketsSentPacketsReceivedOnMuteNew,
      listPacketsSent: listPacketsSentNew,
      listPacketsReceived: listPacketsReceivedNew,
      listNetworkType: listNetworkTypeNew,
      networkChangedDuringTheCall,
    },
  };

  /**
   * Updated MOS Data List
   */
  const mosDataListNew: WebRTCMOSData[] = [
    ...mosDataListExisting.filter(({ idWebRTC: id }) => id !== idWebRTC),
    mosDataNew,
  ];

  return { mosDataListNew };
};

export const webRTCMOSProcessRawData = ({
  listPacketsSentAsFiltered,
  listPacketsReceivedAsFiltered,
}: WebRTCMOSProcessRawDataProps): WebRTCMOSProcessRawDataReturn => {
  // All Packets Lost
  const packetsSentLast: number = listPacketsSentAsFiltered[listPacketsSentAsFiltered.length - 1] || 0;
  const packetsReceivedLast: number = listPacketsReceivedAsFiltered[listPacketsReceivedAsFiltered.length - 1] || 0;
  const packetsLostMin: number = Math.min(packetsSentLast, packetsReceivedLast);
  const packetsLostMax: number = Math.max(packetsSentLast, packetsReceivedLast);
  const packetsLost: number = Math.abs(100 - (packetsLostMin / packetsLostMax) * 100) || 0;

  // Latencies and Jitter: By Packets Sent
  const listPacketsSentAsNotAccumulated: number[] = listPacketsSentAsFiltered.map(
    (currentPacketSent: number, index: number, mapListPacketsSentAsFiltered: number[]) => {
      const previousPacketsSentSlice: number[] = mapListPacketsSentAsFiltered.slice(0, index);
      const previousPacketSentLast: number = previousPacketsSentSlice[previousPacketsSentSlice.length - 1] || 0;
      const packetSentByEach: number = currentPacketSent - previousPacketSentLast;
      return currentPacketSent < previousPacketSentLast ? 0 : packetSentByEach;
    },
  );
  const latenciesPacketsSent: number[] = listPacketsSentAsNotAccumulated
    .map((packetsSentCurrent: number, index: number, mapListPacketsSentAsNotAccumulated: number[]) => {
      const packetsSentPrevious: number = mapListPacketsSentAsNotAccumulated[index - 1] || 0;
      const latencyPacketSent: number = Math.abs(packetsSentCurrent - packetsSentPrevious);
      return latencyPacketSent;
    })
    .filter(webRTCMOSFallbackStatsFilter);

  const latenciesPacketsSentTotal: number = latenciesPacketsSent.reduce(
    (acc: number, latency: number) => acc + latency,
    0,
  );
  const jitterPacketsSentMin: number = Math.min(...latenciesPacketsSent);
  const jitterPacketsSentMax: number = Math.max(...latenciesPacketsSent);
  const jitterPacketsSentTotalCount: number = listPacketsSentAsFiltered.length - 1;
  const jitterPacketsSentAverage: number = latenciesPacketsSentTotal / jitterPacketsSentTotalCount;

  // Average Latency: Packets Sent and Received(Packets Lost)
  const latencyAverage: number = jitterPacketsSentAverage + (jitterPacketsSentAverage * packetsLost) / 100;

  return {
    jitterMin: jitterPacketsSentMin,
    jitterMax: jitterPacketsSentMax,
    jitterAverage: jitterPacketsSentAverage,
    latencyAverage,
    packetsLost,
  };
};

export const webRTCMOSCalculate = ({
  listPacketsSentAsFiltered,
  listPacketsReceivedAsFiltered,
  isCodecPCMA = false,
}: WebRTCMOSCalculateProps): WebRTCMOSCalculateReturn => {
  /**
   * Raw DATA Calculations/Processing
   */
  const { jitterMin, jitterMax, jitterAverage, latencyAverage, packetsLost } = webRTCMOSProcessRawData({
    listPacketsSentAsFiltered,
    listPacketsReceivedAsFiltered,
  });

  /**
   * MOS Calculation
   * Formula is provided by
   * https://onoffapp.atlassian.net/browse/WAP-2091
   */
  const latencyEffective = latencyAverage + jitterAverage * 2 + 10;
  const isLatencyLow = latencyEffective < 160;

  const rValueConstant = isCodecPCMA
    ? SIP.CALL_MOS.FORMULA.R_VALUE_CONSTANT_CODEC_PCMA
    : SIP.CALL_MOS.FORMULA.R_VALUE_CONSTANT_CODEC_DEFAULT;
  const rValueLow = rValueConstant - latencyEffective / 40;
  const rValueHigh = rValueConstant - (latencyEffective - 120) / 10;
  const rValue = isLatencyLow ? rValueLow : rValueHigh;

  const R = rValue - packetsLost * 2.5;
  const MOS = 1 + 0.035 * R + 0.000007 * R * (R - 60) * (100 - R);

  const mosFormatted = webRTCMOSFormatter(MOS);
  const mosJitterAverageFormatted = webRTCMOSFormatter(jitterAverage);
  const mosLatencyAverageFormatted = webRTCMOSFormatter(latencyAverage);
  const mosPacketsLostFormatted = webRTCMOSFormatter(packetsLost);

  return {
    MOS: mosFormatted,
    MOSJitterMin: jitterMin,
    MOSJitterMax: jitterMax,
    MOSJitterAverage: mosJitterAverageFormatted,
    MOSLatencyAverage: mosLatencyAverageFormatted,
    MOSPacketsLost: mosPacketsLostFormatted,
  };
};

export const webRTCMOSDescription = ({ MOS }: WebRTCMOSDescriptionProps): WebRTCMOSDescriptionReturn => {
  const mosFormatted = webRTCMOSFormatter(MOS);

  const mosDescriptionMap: Record<
    Exclude<WebRTCMOSResultDescriptions, WebRTCMOSResultDescriptions.ERROR | WebRTCMOSResultDescriptions.UNAVAILABLE>,
    [number, number]
  > = {
    [WebRTCMOSResultDescriptions.AWESOME]: [4.0, SIP.CALL_MOS.SCORE_MAX + 0.01],
    [WebRTCMOSResultDescriptions.GOOD]: [3.0, 4.0],
    [WebRTCMOSResultDescriptions.NORMAL]: [2.5, 3.0],
    [WebRTCMOSResultDescriptions.MEH]: [2.0, 2.5],
    [WebRTCMOSResultDescriptions.BAD]: [0.01, 2.0],
  };

  const mosDescription = (Object.entries(mosDescriptionMap).find(
    ([, range]) => mosFormatted >= range[0] && mosFormatted < range[1],
  ) || [WebRTCMOSResultDescriptions.ERROR])[0];

  return { mosDescription };
};

export const webRTCMOSNetworkType = ({ listNetworkType }: WebRTCMOSNetworkTypeProps): WebRTCMOSNetworkTypeReturn => {
  /**
   * https://udn.realityripple.com/docs/Web/API/RTCNetworkType
   * "wifi" "bluetooth" "ethernet" "wimax" "vpn" "cellular" "unknown"
   */
  const networkType = listNetworkType[listNetworkType.length - 1] || WebRTCMOSResultDescriptions.ERROR;
  return { networkType };
};

export const webRTCMOSResults = ({ mosData, isCodecPCMA }: WebRTCMOSResultsProps): WebRTCMOSResultsReturn => {
  /**
   * MOS DATA is not Available or not Valid
   * Check the Data and Return as "UNAVAILABLE" if Empty or Not Valid
   * (we are expecting at least 5(for now) samples)
   */
  const {
    stats: {
      listPacketsSent = [],
      listPacketsReceived = [],
      listNetworkType = [],
      networkChangedDuringTheCall = 'No',
    } = {},
  } = mosData || {};
  const listPacketsSentAsFiltered: number[] = listPacketsSent.filter(webRTCMOSFallbackStatsFilter);
  const listPacketsReceivedAsFiltered: number[] = listPacketsReceived.filter(webRTCMOSFallbackStatsFilter);
  if (
    listPacketsSentAsFiltered.length < SIP.CALL_MOS.CALCULATION_MINIMUM_SAMPLE_COUNT ||
    listPacketsReceivedAsFiltered.length < SIP.CALL_MOS.CALCULATION_MINIMUM_SAMPLE_COUNT
  ) {
    return {
      callMOS: 0,
      callMOSJitterMin: 0,
      callMOSJitterMax: 0,
      callMOSJitterAverage: 0,
      callMOSLatencyAverage: 0,
      callMOSPacketsLost: 0,
      callMOSDescription: WebRTCMOSResultDescriptions.UNAVAILABLE,
      callMOSNetworkType: WebRTCMOSResultDescriptions.UNAVAILABLE,
      callMOSNetworkChangedDuringTheCall: WebRTCMOSResultDescriptions.UNAVAILABLE,
    };
  }

  const { MOS, MOSJitterMin, MOSJitterMax, MOSJitterAverage, MOSLatencyAverage, MOSPacketsLost } = webRTCMOSCalculate({
    listPacketsSentAsFiltered,
    listPacketsReceivedAsFiltered,
    isCodecPCMA,
  });
  const { mosDescription } = webRTCMOSDescription({ MOS });
  const { networkType } = webRTCMOSNetworkType({ listNetworkType });

  return {
    callMOS: MOS,
    callMOSJitterMin: MOSJitterMin,
    callMOSJitterMax: MOSJitterMax,
    callMOSJitterAverage: MOSJitterAverage,
    callMOSLatencyAverage: MOSLatencyAverage,
    callMOSPacketsLost: MOSPacketsLost,
    callMOSDescription: mosDescription,
    callMOSNetworkType: networkType,
    callMOSNetworkChangedDuringTheCall: networkChangedDuringTheCall,
  };
};
