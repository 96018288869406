import { Category, StyleableComponent } from 'types';

export enum SMSAttachmentTypes {
  // the values should be lowercased
  IMAGE = 'image',
  AUDIO = 'audio',
}

export interface SMSCoreProps extends StyleableComponent {
  activeThreadCategory?: Category;
  hasFocus?: boolean;
  isNewThread?: boolean;
  isDisabled?: boolean;
  isSending?: boolean;
  onBlur?: () => void;
  onChange?: (value: string | File) => void;
  onChangeCategory?: (category: Category) => void;
  onFocus?: () => void;
  onSend?: (messageValue: string | File, sourceCategory: Category) => void;
  value?: string | File;
}

export interface SMSButtonSendProps extends StyleableComponent {
  isUserB2B: boolean;
  isDisabled?: boolean;
  isSending: boolean;
  onSend?: () => void;
}

export interface SMSButtonSendMultiCategoryProps extends StyleableComponent {
  isUserB2B: boolean;
  categoryList?: Array<Category>;
  currentCategory?: Category | null;
  defaultCategory?: Category | null;
  isDisabled?: boolean;
  isSending?: boolean;
  onSelectCategory?: (category: Category) => void;
  onSend?: () => void;
  isSelectCategoryPopUpOpen?: boolean;
  setIsSelectCategoryPopUpOpen?: (isOpen: boolean) => void;
}

export interface SMSButtonAttachmentProps extends StyleableComponent {
  onClickToRemove: () => void;
  attachmentType: SMSAttachmentTypes;
  fileName: string;
  isDisabled?: boolean;
}
