import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { GAEventKey, DataLayerVariablesSIP } from 'types';

import { normalizePhoneNumber, getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage } from 'helpers';

import { SIPStatisticsScopes, SIPWebRTCDirections, SIPWebRTCOriginators } from '@redux/types';
import { analyticsService, sentryService } from 'services';

import { sipStatisticsMaskPhoneNumber } from '../../helpers';
import {
  UseSIPStatisticsReturn,
  SIPSendErrorToSentryProps,
  SIPSendLogToGTMCallInitOutgoingProps,
  SIPSendLogToGTMCallEstablishedProps,
  SIPSendLogToGTMCallBusyOutgoingProps,
  SIPSendLogToGTMCallNotFoundOutgoingProps,
  SIPSendLogToGTMCallUnavailableOutgoingProps,
  SIPSendLogToGTMCallAddressIncompleteOutgoingProps,
  SIPSendLogToGTMCallEndedProps,
} from '../../types';

export const useSIPStatistics = (): UseSIPStatisticsReturn => {
  const sipSendErrorToSentry = ({ scope, error, extras }: SIPSendErrorToSentryProps): void => {
    sentryService.captureException({
      error,
      scope: {
        scopeKey: SIPStatisticsScopes.SIP,
        scopeValue: scope,
      },
      extras,
    });
  };

  const sendToGTM = ({
    event,
    variables: { sipCallCase, sipCallOriginator, sipCallTitle, sipCallDescription, sipCallTransactionId },
  }: {
    event: GAEventKey;
    variables: DataLayerVariablesSIP;
  }): void => {
    analyticsService.pushToDataLayer({
      event,
      variables: {
        sipCallCase,
        sipCallOriginator,
        sipCallTitle,
        sipCallDescription,
        sipCallTransactionId,
      },
    });
  };

  const sipSendErrorToGTM = ({
    sipCallCase,
    sipCallOriginator,
    sipCallTitle,
    sipCallDescription,
    sipCallTransactionId,
  }: DataLayerVariablesSIP): void => {
    sendToGTM({
      event: GAEventKey.CALL_ERRORS,
      variables: {
        sipCallCase,
        sipCallOriginator,
        sipCallTitle,
        sipCallDescription,
        sipCallTransactionId,
      },
    });
  };

  const sipSendLogToGTM = ({
    sipCallCase,
    sipCallOriginator,
    sipCallTitle,
    sipCallDescription,
    sipCallTransactionId,
  }: DataLayerVariablesSIP): void => {
    sendToGTM({
      event: GAEventKey.CALL_LOGS,
      variables: {
        sipCallCase,
        sipCallOriginator,
        sipCallTitle,
        sipCallDescription,
        sipCallTransactionId,
      },
    });
  };

  const sipSendLogToGTMCallInitOutgoing = ({
    propPhoneNumberLocal,
    propPhoneNumberRemote,
    propCTA,
    propHasContact,
  }: SIPSendLogToGTMCallInitOutgoingProps): void => {
    const normalizedPhoneNumberLocal = normalizePhoneNumber(propPhoneNumberLocal);
    const normalizedPhoneNumberRemote = normalizePhoneNumber(propPhoneNumberRemote);
    const { country: countryCodeLocal } = parsePhoneNumberFromString(`+${normalizedPhoneNumberLocal}`) || {};
    const { country: countryCodeRemote } = parsePhoneNumberFromString(`+${normalizedPhoneNumberRemote}`) || {};
    const isCallInternational = countryCodeLocal !== countryCodeRemote;
    const isCallCodecPCMA = getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage();

    analyticsService.pushToDataLayer({
      event: GAEventKey.CALL_INIT_OUTGOING,
      variables: {
        callIsContact: `${propHasContact}`,
        callIsInternational: `${isCallInternational}`,
        callCTA: propCTA,
        callIsCodecPCMA: `${isCallCodecPCMA}`,
      },
    });
  };

  const sipSendLogToGTMCallBusyOutgoing = ({
    propTransactionId,
    propPhoneNumberRemote,
  }: SIPSendLogToGTMCallBusyOutgoingProps): void => {
    const callRemoteNumberAsMasked = sipStatisticsMaskPhoneNumber(propPhoneNumberRemote);
    const isCallCodecPCMA = getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage();

    analyticsService.pushToDataLayer({
      event: GAEventKey.CALL_BUSY_OUTGOING,
      variables: {
        callTransactionId: propTransactionId,
        callRemoteNumber: callRemoteNumberAsMasked,
        callIsCodecPCMA: `${isCallCodecPCMA}`,
      },
    });
  };

  const sipSendLogToGTMCallNotFoundOutgoing = ({
    propTransactionId,
    propPhoneNumberRemote,
  }: SIPSendLogToGTMCallNotFoundOutgoingProps): void => {
    const callRemoteNumberAsMasked = sipStatisticsMaskPhoneNumber(propPhoneNumberRemote);
    const isCallCodecPCMA = getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage();

    analyticsService.pushToDataLayer({
      event: GAEventKey.CALL_NOTFOUND_OUTGOING,
      variables: {
        callTransactionId: propTransactionId,
        callRemoteNumber: callRemoteNumberAsMasked,
        callIsCodecPCMA: `${isCallCodecPCMA}`,
      },
    });
  };

  const sipSendLogToGTMCallUnavailableOutgoing = ({
    propTransactionId,
    propPhoneNumberRemote,
  }: SIPSendLogToGTMCallUnavailableOutgoingProps): void => {
    const callRemoteNumberAsMasked = sipStatisticsMaskPhoneNumber(propPhoneNumberRemote);
    const isCallCodecPCMA = getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage();

    analyticsService.pushToDataLayer({
      event: GAEventKey.CALL_UNAVAILABLE_OUTGOING,
      variables: {
        callTransactionId: propTransactionId,
        callRemoteNumber: callRemoteNumberAsMasked,
        callIsCodecPCMA: `${isCallCodecPCMA}`,
      },
    });
  };

  const sipSendLogToGTMCallAddressIncompleteOutgoing = ({
    propTransactionId,
    propPhoneNumberRemote,
  }: SIPSendLogToGTMCallAddressIncompleteOutgoingProps): void => {
    const callRemoteNumberAsMasked = sipStatisticsMaskPhoneNumber(propPhoneNumberRemote);
    const isCallCodecPCMA = getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage();

    analyticsService.pushToDataLayer({
      event: GAEventKey.CALL_ADDRESS_INCOMPLETE_OUTGOING,
      variables: {
        callTransactionId: propTransactionId,
        callRemoteNumber: callRemoteNumberAsMasked,
        callIsCodecPCMA: `${isCallCodecPCMA}`,
      },
    });
  };

  const sipSendLogToGTMCallEstablished = ({
    propOriginator,
    propTransactionId,
    propPhoneNumberRemote,
    propTimestampCreated,
    propTimestampRang,
  }: SIPSendLogToGTMCallEstablishedProps): void => {
    const event =
      propOriginator === SIPWebRTCOriginators.LOCAL
        ? GAEventKey.CALL_ESTABLISHED_OUTGOING
        : GAEventKey.CALL_ESTABLISHED_INCOMING;
    const callSetupTimeAsSeconds = Math.round((propTimestampRang - propTimestampCreated) / 1000) || 0;
    const callRemoteNumberAsMasked = sipStatisticsMaskPhoneNumber(propPhoneNumberRemote);
    const isCallCodecPCMA = getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage();

    analyticsService.pushToDataLayer({
      event,
      variables: {
        callTransactionId: propTransactionId,
        callRemoteNumber: callRemoteNumberAsMasked,
        callSetupTime: callSetupTimeAsSeconds,
        callIsCodecPCMA: `${isCallCodecPCMA}`,
      },
    });
  };

  const sipSendLogToGTMCallEnded = ({
    propTransactionId,
    propOriginatorInit,
    propOriginatorEvent,
    propStatus,
    propTimeStart,
    propTimeEnd,
    propCallMOS,
    propCallMOSJitterMin,
    propCallMOSJitterMax,
    propCallMOSJitterAverage,
    propCallMOSLatencyAverage,
    propCallMOSPacketsLost,
    propCallMOSDescription,
    propCallMOSNetworkType,
    propCallMOSNetworkChangedDuringTheCall,
  }: SIPSendLogToGTMCallEndedProps): void => {
    const callDirection =
      propOriginatorInit === SIPWebRTCOriginators.LOCAL ? SIPWebRTCDirections.OUTGOING : SIPWebRTCDirections.INCOMING;
    const timeFallback = new Date().getTime();
    const timeStart = new Date(propTimeStart || timeFallback).getTime();
    const timeEnd = new Date(propTimeEnd || timeFallback).getTime();
    const callDuration = Math.round((timeEnd - timeStart) / 1000) || 0;
    const isCallCodecPCMA = getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage();

    analyticsService.pushToDataLayer({
      event: GAEventKey.CALL_ENDED,
      variables: {
        callTransactionId: propTransactionId,
        callDirection,
        callEventOriginator: propOriginatorEvent,
        callEventStatus: propStatus,
        callDuration,
        callMOS: propCallMOS,
        callMOSJitterMin: propCallMOSJitterMin,
        callMOSJitterMax: propCallMOSJitterMax,
        callMOSJitterAverage: propCallMOSJitterAverage,
        callMOSLatencyAverage: propCallMOSLatencyAverage,
        callMOSPacketsLost: propCallMOSPacketsLost,
        callMOSDescription: propCallMOSDescription,
        callMOSNetworkType: propCallMOSNetworkType,
        callMOSNetworkChangedDuringTheCall: propCallMOSNetworkChangedDuringTheCall,
        callIsCodecPCMA: `${isCallCodecPCMA}`,
      },
    });
  };

  return {
    sipSendErrorToSentry,
    sipSendErrorToGTM,
    sipSendLogToGTM,
    sipSendLogToGTMCallInitOutgoing,
    sipSendLogToGTMCallBusyOutgoing,
    sipSendLogToGTMCallNotFoundOutgoing,
    sipSendLogToGTMCallUnavailableOutgoing,
    sipSendLogToGTMCallAddressIncompleteOutgoing,
    sipSendLogToGTMCallEstablished,
    sipSendLogToGTMCallEnded,
  };
};
