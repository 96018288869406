import classNames from 'classnames';
import React from 'react';

import styles from './Banner.module.scss';

type BannerColorScheme = 'brand-b2c' | 'brand-b2b' | 'destructive' | 'warning';

export interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  colorScheme: BannerColorScheme;
}

const Banner: React.FC<BannerProps> = ({ children, colorScheme, ...props }) => (
  <div
    className={classNames(styles.root, styles[`colorScheme-${colorScheme}`])}
    role="alert"
    {...props}
  >
    {children}
  </div>
);

export default Banner;
