import { StyleableComponent } from 'types/components';

const LogoB2B: React.FC<StyleableComponent> = ({ className = '' }) => (
  <svg
    className={className}
    width="182"
    height="25"
    viewBox="0 0 204 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.0042 7.3501C44.0462 7.3501 47.4385 10.0238 47.4385 14.6527C47.4385 19.2247 44.0462 21.9273 40.0042 21.9273C35.9919 21.9273 32.5698 19.2531 32.5698 14.6527C32.5698 10.0238 35.9919 7.3501 40.0042 7.3501ZM40.0042 18.7647C42.2168 18.7647 43.6916 17.0969 43.6916 14.6527C43.6916 12.1513 42.2168 10.541 40.0042 10.541C37.8508 10.541 36.287 12.1513 36.287 14.6527C36.287 17.0969 37.8508 18.7647 40.0042 18.7647Z"
      fill="#273348"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.9682 21.5958H58.3217V13.4306C58.3217 11.4904 57.4818 10.564 56.0061 10.564C54.4142 10.564 53.1116 11.8094 53.1116 13.6622V21.5958H49.4651V7.78429H52.8512V9.52159H52.9096C53.6908 8.27676 55.2538 7.3501 57.1348 7.3501C60.1159 7.3501 61.9682 9.31894 61.9682 12.7641V21.5958Z"
      fill="#273348"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.4307 7.3501C75.4719 7.3501 78.8648 10.0238 78.8648 14.6527C78.8648 19.2247 75.4719 21.9273 71.4307 21.9273C67.4184 21.9273 63.9963 19.2531 63.9963 14.6527C63.9963 10.0238 67.4184 7.3501 71.4307 7.3501ZM71.4307 18.7647C73.643 18.7647 75.1182 17.0969 75.1182 14.6527C75.1182 12.1513 73.643 10.541 71.4307 10.541C69.2773 10.541 67.7135 12.1513 67.7135 14.6527C67.7135 17.0969 69.2773 18.7647 71.4307 18.7647Z"
      fill="#273348"
    />
    <mask
      id="logo-b2b-mask-1"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="80"
      y="1"
      width="8"
      height="21"
    >
      <path
        d="M80.8918 1.05566H87.9881V21.5958H80.8918V1.05566Z"
        fill="white"
      />
    </mask>
    <g mask="url(#logo-b2b-mask-1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.8918 7.89244V6.22593C80.8918 2.92281 82.7173 1.05518 86.0451 1.05518C86.8698 1.05518 87.6352 1.16992 87.9881 1.28518V4.32993C87.5465 4.24431 87.1639 4.18656 86.7815 4.18656C85.279 4.18656 84.6017 5.04831 84.6017 6.5418V7.89244H87.9881V10.9661H84.6017V21.5953H80.8918V10.9661V7.89244Z"
        fill="#273348"
      />
    </g>
    <mask
      id="logo-b2b-mask-2"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="90"
      y="1"
      width="8"
      height="21"
    >
      <path
        d="M90.0161 1.05566H97.1127V21.5958H90.0161V1.05566Z"
        fill="white"
      />
    </mask>
    <g mask="url(#logo-b2b-mask-2)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.0161 7.89244V6.22593C90.0161 2.92281 91.8419 1.05518 95.1691 1.05518C95.9938 1.05518 96.7589 1.16992 97.1127 1.28518V4.32993C96.6706 4.24431 96.2879 4.18656 95.9055 4.18656C94.4035 4.18656 93.726 5.04831 93.726 6.5418V7.89244H97.1127V10.9661H93.726V21.5953H90.0161V10.9661V7.89244Z"
        fill="#273348"
      />
    </g>
    <mask
      id="logo-b2b-mask-3"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="26"
      height="26"
    >
      <path
        d="M0 1.42627H25.9391V26.8568H0V1.42627Z"
        fill="white"
      />
    </mask>
    <g mask="url(#logo-b2b-mask-3)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9695 1.42704C5.81039 1.42704 -0.000244141 7.12424 -0.000244141 14.1422C-0.000244141 21.1591 5.81039 26.8568 12.9684 26.8568C20.1275 26.8568 25.9389 21.1591 25.9389 14.1422C25.9389 7.12373 20.1275 1.42627 12.9695 1.42627V1.42704ZM20.4909 14.3351C19.9886 15.5209 19.0098 16.8463 17.3829 18.4458C15.7847 20.0185 14.4089 20.983 13.2278 21.5072C12.4971 21.8392 11.7646 21.9764 11.0043 21.9496C9.40766 21.9787 7.86865 21.371 6.74229 20.2675C5.60315 19.1581 4.97598 17.6441 5.00595 16.074C4.97285 15.3566 5.1389 14.639 5.44623 13.9503C5.94828 12.7632 6.92867 11.4115 8.55422 9.83932C10.1803 8.23926 11.5282 7.274 12.7372 6.77668C13.4316 6.45672 14.1949 6.3062 14.9618 6.33584C16.5475 6.31489 18.0745 6.92184 19.1938 8.0182C20.328 9.12017 20.9549 10.6234 20.9309 12.1849C20.9632 12.9293 20.7972 13.6459 20.4899 14.3359"
        fill="url(#logo-b2b-gradient-1)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1619 16.1115C12.8425 16.1115 10.9607 14.2666 10.9607 11.9937C10.9607 9.71945 12.8425 7.87354 15.1619 7.87354C17.4804 7.87354 19.3634 9.71894 19.3634 11.9937C19.3634 14.2666 17.4798 16.1115 15.1619 16.1115Z"
      fill="url(#logo-b2b-gradient-2)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.824 9.01796C111.448 9.01796 114.104 11.0635 114.104 14.974C114.104 18.8458 111.448 20.8719 108.824 20.8719C105.772 20.8719 103.721 18.5018 103.721 14.974C103.721 11.4113 105.772 9.01796 108.824 9.01796ZM108.912 7.73326C106.638 7.73326 104.76 8.80508 103.75 10.6372V1.20752H102.322V21.752H103.691V19.2641C104.671 21.0822 106.611 22.1849 108.912 22.1849C112.792 22.1849 115.502 19.2197 115.502 14.974C115.502 10.7108 112.792 7.73326 108.912 7.73326Z"
      fill="#273348"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M127.978 16.0996C127.978 18.7756 125.983 20.872 123.435 20.872C121.127 20.872 119.805 19.2067 119.805 16.302V8.16602H118.406V16.7347C118.406 20.0969 120.287 22.185 123.317 22.185C125.321 22.185 127.117 21.1301 128.037 19.4559V21.7521H129.377V8.16602H127.978V16.0996Z"
      fill="#273348"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M138.385 14.1321C136.429 13.6614 134.741 13.2551 134.741 11.3642C134.741 10.1531 135.708 8.93098 137.87 8.93098C139.702 8.93098 141.187 10.0455 141.324 11.5229L141.341 11.7015L142.71 11.4718L142.691 11.3156C142.446 9.27343 140.374 7.73291 137.87 7.73291C135.161 7.73291 133.341 9.21516 133.341 11.4217C133.341 14.24 135.719 14.8086 137.816 15.3095C139.777 15.7787 141.471 16.184 141.471 18.0921C141.471 19.8779 140.204 20.9873 138.165 20.9873C136.161 20.9873 134.714 19.987 134.294 18.3121L134.254 18.1529L132.932 18.4573L132.962 18.6124C133.361 20.7494 135.44 22.1849 138.135 22.1849C140.967 22.1849 142.87 20.5163 142.87 18.034C142.87 15.2116 140.487 14.6379 138.385 14.1321Z"
      fill="#273348"
    />
    <mask
      id="logo-b2b-mask-4"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="204"
      height="26"
    >
      <path
        d="M0 26.857H203.613V1.05566H0V26.857Z"
        fill="white"
      />
    </mask>
    <g mask="url(#logo-b2b-mask-4)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.303 21.7516H147.702V8.16553H146.303V21.7516Z"
        fill="#273348"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.492 7.7334C156.454 7.7334 154.71 8.72855 153.801 10.3672V8.16606H152.432V21.7522H153.831V13.7897C153.831 11.114 155.813 9.0181 158.344 9.0181C160.67 9.0181 162.004 10.6833 162.004 13.5883V21.7522H163.403V13.1546C163.403 9.81034 161.521 7.7334 158.492 7.7334Z"
        fill="#273348"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M167.982 14.0856C168.254 10.9008 170.135 8.93147 172.926 8.93147C175.12 8.93147 177.544 10.29 177.84 14.0856H167.982ZM172.926 7.7334C169.107 7.7334 166.541 10.6315 166.541 14.9451C166.541 19.2075 169.288 22.1851 173.22 22.1851C175.739 22.1851 177.718 20.9408 178.793 18.6824L178.875 18.5099L177.609 18.1224L177.545 18.2512C176.667 20.0279 175.212 20.9293 173.22 20.9293C170.172 20.9293 168.07 18.6453 167.944 15.2267H179.309V14.4475C179.125 9.80957 175.971 7.7334 172.926 7.7334Z"
        fill="#273348"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.933 14.1321C184.977 13.6614 183.289 13.2551 183.289 11.3642C183.289 10.1531 184.256 8.93098 186.418 8.93098C188.251 8.93098 189.735 10.0455 189.872 11.5229L189.889 11.7015L191.259 11.4718L191.24 11.3156C190.995 9.27343 188.922 7.73291 186.418 7.73291C183.71 7.73291 181.89 9.21516 181.89 11.4217C181.89 14.24 184.267 14.8086 186.365 15.3095C188.326 15.7787 190.019 16.184 190.019 18.0921C190.019 19.8779 188.752 20.9873 186.713 20.9873C184.709 20.9873 183.262 19.987 182.842 18.3121L182.803 18.1529L181.48 18.4573L181.51 18.6124C181.909 20.7494 183.988 22.1849 186.683 22.1849C189.515 22.1849 191.418 20.5163 191.418 18.034C191.418 15.2116 189.035 14.6379 186.933 14.1321Z"
        fill="#273348"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.128 14.1321C197.172 13.6614 195.484 13.2551 195.484 11.3642C195.484 10.1531 196.451 8.93098 198.613 8.93098C200.445 8.93098 201.93 10.0455 202.067 11.5229L202.084 11.7015L203.453 11.4718L203.434 11.3156C203.19 9.27343 201.117 7.73291 198.613 7.73291C195.904 7.73291 194.085 9.21516 194.085 11.4217C194.085 14.24 196.462 14.8086 198.559 15.3095C200.52 15.7787 202.214 16.184 202.214 18.0921C202.214 19.8779 200.947 20.9873 198.908 20.9873C196.904 20.9873 195.457 19.987 195.037 18.3121L194.997 18.1529L193.675 18.4573L193.705 18.6124C194.104 20.7494 196.182 22.1849 198.878 22.1849C201.709 22.1849 203.613 20.5163 203.613 18.034C203.613 15.2116 201.23 14.6379 199.128 14.1321Z"
        fill="#273348"
      />
      <path
        d="M147.149 3.86656C147.653 3.86656 148.061 3.4661 148.061 2.9721C148.061 2.4781 147.653 2.07764 147.149 2.07764C146.645 2.07764 146.237 2.4781 146.237 2.9721C146.237 3.4661 146.645 3.86656 147.149 3.86656Z"
        fill="#273348"
      />
    </g>
    <defs>
      <linearGradient
        id="logo-b2b-gradient-1"
        x1="3.32997"
        y1="23.0075"
        x2="22.0015"
        y2="4.966"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#273348" />
        <stop
          offset="1"
          stopColor="#515D72"
        />
      </linearGradient>
      <linearGradient
        id="logo-b2b-gradient-2"
        x1="3.20016"
        y1="22.4088"
        x2="22.2251"
        y2="5.14513"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#273348" />
        <stop
          offset="1"
          stopColor="#515D72"
        />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoB2B;
