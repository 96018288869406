import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePopper } from 'react-popper';

import { DropUp } from '@onoff/icons';

import { Category } from 'types';

import CategoryIconSmall from 'components/CategoryIconSmall';
import CategorySelectPopUp from 'components/CategorySelectPopUp';
import { IconButton, IconCommonNames } from 'components/Icon';
import { useClickAway } from 'hooks';

import { SMSButtonSendMultiCategoryProps } from '../../types';

import styles from './SMSButtonSendMultiCategory.module.scss';

const SMSButtonSendMultiCategory: React.FC<SMSButtonSendMultiCategoryProps> = ({
  isUserB2B,
  categoryList = [],
  currentCategory,
  className,
  defaultCategory,
  isDisabled = false,
  isSending = false,
  onSend,
  onSelectCategory,
  isSelectCategoryPopUpOpen = false,
  setIsSelectCategoryPopUpOpen,
}) => {
  const intl = useIntl();

  const categorySelectPopUpContainerRef = useRef<HTMLDivElement>(null);

  const [isCategoryPopUpOpen, setIsCategoryPopUpOpen] = useState(isSelectCategoryPopUpOpen);
  const [popUpAnchorRef, setPopUpAnchorRef] = useState<HTMLButtonElement | null>(null);
  const [popUpRef, setPopUpRef] = useState<HTMLDivElement | null>(null);

  const {
    styles: { popper: popperStyles },
    attributes: { popper: popperAttributes },
  } = usePopper(popUpAnchorRef, popUpRef, {
    placement: 'top-end',
  });

  const handleSetIsCategoryPopUpOpen = useCallback(
    (value: boolean) => {
      if (setIsSelectCategoryPopUpOpen !== undefined) {
        setIsSelectCategoryPopUpOpen(value);
      }
      setIsCategoryPopUpOpen(value);
    },
    [setIsSelectCategoryPopUpOpen, setIsCategoryPopUpOpen],
  );

  const handleSelectCategory = (category: Category) => {
    handleSetIsCategoryPopUpOpen(false);
    if (onSelectCategory !== undefined) {
      onSelectCategory(category);
    }
  };

  useClickAway(categorySelectPopUpContainerRef, () => handleSetIsCategoryPopUpOpen(false));

  useEffect(() => {
    handleSetIsCategoryPopUpOpen(isSelectCategoryPopUpOpen);
  }, [handleSetIsCategoryPopUpOpen, isSelectCategoryPopUpOpen]);

  const otherCategoriesList = categoryList.filter(({ id: categoryId }) => categoryId !== defaultCategory?.id);

  return (
    <div
      className={classNames(className, styles.root, {
        [styles.isB2B]: isUserB2B,
        [styles.isSending]: isSending,
      })}
    >
      <div className={styles.buttonsWrapper}>
        <IconButton
          className={classNames(styles.buttonSend, {
            [styles.isB2B]: isUserB2B,
          })}
          iconName={isSending ? IconCommonNames.SPINNER : IconCommonNames.SEND}
          isDisabled={isDisabled}
          onClick={currentCategory ? onSend : () => handleSetIsCategoryPopUpOpen(true)}
        />
        <div className={styles.separator} />
        <div
          className={styles.categorySelectPopUpWrapper}
          ref={categorySelectPopUpContainerRef}
        >
          {isCategoryPopUpOpen && (
            <CategorySelectPopUp
              categoryList={otherCategoriesList}
              categoryListTitle={
                defaultCategory
                  ? intl.formatMessage({ id: 'Category.CategorySelectPopUp.title_other_numbers' })
                  : intl.formatMessage({ id: 'Category.CategorySelectPopUp.title_sending_number' })
              }
              defaultCategory={defaultCategory}
              defaultCategoryTitle={intl.formatMessage({ id: 'Category.CategorySelectPopUp.title_default' })}
              onSelectCategory={handleSelectCategory}
              ref={setPopUpRef}
              style={popperStyles}
              {...popperAttributes}
            />
          )}
          <button
            ref={setPopUpAnchorRef}
            className={styles.button}
            onClick={() => handleSetIsCategoryPopUpOpen(!isCategoryPopUpOpen)}
            type="button"
          >
            {currentCategory ? (
              <CategoryIconSmall
                className={styles.categorySmallIcon}
                color={currentCategory?.color}
              />
            ) : (
              <DropUp />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SMSButtonSendMultiCategory;
