import { PurchaseCreditCard } from 'types';

export interface IsCreditCardExpiredProps {
  year?: number;
  month?: number;
}

export const isCreditCardExpired = ({ year = 2014, month = 1 }: IsCreditCardExpiredProps): boolean => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (year > currentYear) {
    return false;
  }

  return !(year === currentYear && currentMonth <= month);
};

export const getNonExpiredCreditCards = (cards: PurchaseCreditCard[]) =>
  cards.filter(
    (card) =>
      !isCreditCardExpired({
        year: card?.expiryYear,
        month: card?.expiryMonth,
      }),
  );

export const getNonExpiredAndDefaultOrFirstCreditCard = (cards: PurchaseCreditCard[]) => {
  const nonExpiredOnes = getNonExpiredCreditCards(cards);
  return nonExpiredOnes.find((card) => card.default === true) || nonExpiredOnes.find((card) => card);
};

export const getDefaultCreditCard = (cards: PurchaseCreditCard[]) => cards.find((card) => card.default === true);

const getCardSortScore = (card: PurchaseCreditCard): number => {
  const isDefault = card.default === true;
  const isExpired = isCreditCardExpired({
    month: card.expiryMonth,
    year: card.expiryYear,
  });

  if (!isExpired) {
    if (isDefault) {
      // first - default && !expired
      return 1000;
    }

    // second - !default && !expired
    return 100;
  }

  // third:
  // default && expired
  // !default && expired
  return 0;
};

export const getSortedCreditCards = (cards: PurchaseCreditCard[]) =>
  [...cards].sort((cardA, cardB) => getCardSortScore(cardB) - getCardSortScore(cardA));
