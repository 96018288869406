import { SIPStatisticsCallCTAs } from '@redux/types';
import { testIds, TestIds } from '@testing';
import { ModalsConfirmationType } from 'components/Modals';

import { InfoTopBarActionsTypes } from '../types';

export const getInfoTopBarActionsCallsAnalyticsCTA = (actionType: InfoTopBarActionsTypes): SIPStatisticsCallCTAs => {
  switch (actionType) {
    case InfoTopBarActionsTypes.PAGE_CALLS:
      return SIPStatisticsCallCTAs.CTA_CALLLOGS;

    case InfoTopBarActionsTypes.PAGE_MESSAGES:
    case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
      return SIPStatisticsCallCTAs.CTA_MESSAGES;

    case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
      return SIPStatisticsCallCTAs.CTA_VOICEMAILS;

    case InfoTopBarActionsTypes.PAGE_CONTACTS:
      return SIPStatisticsCallCTAs.CTA_CONTACTS;

    default:
      return SIPStatisticsCallCTAs.CTA_DEBUG;
  }
};

export const getInfoTopBarActionsDropdownDeleteItemTestId = (
  actionType: InfoTopBarActionsTypes,
): TestIds[keyof TestIds] => {
  switch (actionType) {
    case InfoTopBarActionsTypes.PAGE_CALLS:
      return testIds.infoTopBarActionsDropdownButtonDeleteCallLog;

    case InfoTopBarActionsTypes.PAGE_MESSAGES:
    case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
      return testIds.infoTopBarActionsDropdownButtonDeleteConversation;

    case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
      return testIds.infoTopBarActionsDropdownButtonDeleteVoicemail;

    case InfoTopBarActionsTypes.PAGE_CONTACTS:
      return testIds.infoTopBarActionsDropdownButtonDeleteContact;

    default:
      return '';
  }
};

export const getInfoTopBarActionsDropdownDeleteItemIntlId = (actionType: InfoTopBarActionsTypes): string => {
  switch (actionType) {
    case InfoTopBarActionsTypes.PAGE_CALLS:
      return 'InfoTopBar.Actions.Dropdown.button_delete_call_log';

    case InfoTopBarActionsTypes.PAGE_MESSAGES:
    case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
      return 'InfoTopBar.Actions.Dropdown.button_delete_conversation';

    case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
      return 'InfoTopBar.Actions.Dropdown.button_delete_voicemail';

    case InfoTopBarActionsTypes.PAGE_CONTACTS:
      return 'InfoTopBar.Actions.Dropdown.button_delete_contact';

    default:
      return 'ERROR';
  }
};

export const getInfoTopBarActionsDropdownDeleteItemModalType = (
  actionType: InfoTopBarActionsTypes,
): ModalsConfirmationType => {
  switch (actionType) {
    case InfoTopBarActionsTypes.PAGE_CALLS:
      return ModalsConfirmationType.DELETE_CALL_HISTORY;

    case InfoTopBarActionsTypes.PAGE_MESSAGES:
    case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
      return ModalsConfirmationType.DELETE_CONVERSATION;

    case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
      return ModalsConfirmationType.DELETE_VOICEMAIL;

    case InfoTopBarActionsTypes.PAGE_CONTACTS:
      return ModalsConfirmationType.DELETE_CONTACT;

    default:
      return ModalsConfirmationType.DEFAULT;
  }
};

export const getInfoTopBarActionsDropdownDeleteItemModalTitleIntlId = (actionType: InfoTopBarActionsTypes): string => {
  switch (actionType) {
    case InfoTopBarActionsTypes.PAGE_CALLS:
      return 'CallsPage.popup_delete_call_log_title';

    case InfoTopBarActionsTypes.PAGE_MESSAGES:
    case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
      return 'Messages.delete_conversation';

    case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
      return 'VoicemailsPage.popup_delete_voicemail_title';

    case InfoTopBarActionsTypes.PAGE_CONTACTS:
      return 'ContactInfo.modal_title_delete_contact';

    default:
      return 'ERROR';
  }
};

export const getInfoTopBarActionsDropdownDeleteItemModalDescriptionIntlId = (
  actionType: InfoTopBarActionsTypes,
): string => {
  switch (actionType) {
    case InfoTopBarActionsTypes.PAGE_CALLS:
      return 'CallsPage.popup_delete_call_log_description';

    case InfoTopBarActionsTypes.PAGE_MESSAGES:
    case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
      return 'Messages.q_delete_conversation';

    case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
      return 'VoicemailsPage.popup_delete_voicemail_description';

    case InfoTopBarActionsTypes.PAGE_CONTACTS:
      return 'ContactInfo.q_delete_contact';

    default:
      return 'ERROR';
  }
};

export const getInfoTopBarActionsDropdownDeleteItemModalButtonConfirmIntlId = (
  actionType: InfoTopBarActionsTypes,
): string => {
  switch (actionType) {
    case InfoTopBarActionsTypes.PAGE_CALLS:
      return 'CallsPage.popup_delete_call_log_confirm';

    case InfoTopBarActionsTypes.PAGE_MESSAGES:
    case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
      return 'Messages.delete';

    case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
      return 'VoicemailsPage.popup_delete_voicemail_confirm';

    case InfoTopBarActionsTypes.PAGE_CONTACTS:
      return 'ContactInfo.a_delete_contact_yes';

    default:
      return 'ERROR';
  }
};

export const getInfoTopBarActionsDropdownDeleteItemModalButtonCancelIntlId = (
  actionType: InfoTopBarActionsTypes,
): string => {
  switch (actionType) {
    case InfoTopBarActionsTypes.PAGE_CALLS:
      return 'CallsPage.popup_delete_call_log_cancel';

    case InfoTopBarActionsTypes.PAGE_MESSAGES:
    case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
      return 'Messages.cancel';

    case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
      return 'VoicemailsPage.popup_delete_voicemail_cancel';

    case InfoTopBarActionsTypes.PAGE_CONTACTS:
      return 'ContactInfo.a_delete_contact_no';

    default:
      return 'ERROR';
  }
};
