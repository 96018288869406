import classNames from 'classnames';
import React from 'react';

import { Close } from '@onoff/icons';

import { StyleableComponent } from 'types';

import { AvatarCommon, AvatarSize, AvatarCommonProps } from 'components/Avatar';

import styles from './ModalsNewThreadContactFieldThumb.module.scss';

interface ModalsNewThreadContactFieldThumbProps extends StyleableComponent {
  avatarProps?: AvatarCommonProps;
  id: string;
  isValid?: boolean;
  label?: string;
  onRemove?: (id: string) => void;
}

const ModalsNewThreadContactFieldThumb: React.FC<ModalsNewThreadContactFieldThumbProps> = ({
  avatarProps = null,
  className,
  id,
  isValid = true,
  label = '',
  onRemove = () => undefined,
}) => (
  <span
    className={classNames(className, styles.root, {
      [styles.invalid]: !isValid,
    })}
  >
    {avatarProps !== null && (
      <AvatarCommon
        size={AvatarSize.TINY}
        {...avatarProps}
      />
    )}
    <p className={styles.label}>{label}</p>
    <Close
      className={styles.removeIcon}
      onClick={() => onRemove(id)}
    />
  </span>
);

export default ModalsNewThreadContactFieldThumb;
