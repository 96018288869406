import React from 'react';

import { getContactFullName } from 'helpers';

import { useWebAppSelector, selectPrivacyIsNumberBlocked } from '@redux';
import { AvatarCommon, AvatarSize } from 'components/Avatar';

import { InfoTopBarAvatarProps } from '../../types';

const InfoTopBarAvatar: React.FC<InfoTopBarAvatarProps> = ({
  phoneNumberOrEmpty,
  contact = null,
  category = null,
  thread = false,
  size = AvatarSize.DEFAULT,
}) => {
  const isPhoneNumberBlocked = useWebAppSelector(selectPrivacyIsNumberBlocked(phoneNumberOrEmpty));

  return (
    <AvatarCommon
      imageSrc={contact?.imageUrl}
      fullName={getContactFullName(contact)}
      backgroundColor={category?.color}
      hasStarIcon={contact?.favorite}
      hasBlockedIcon={isPhoneNumberBlocked || contact?.blocked}
      isGroup={thread !== false ? thread?.group : false}
      size={size}
    />
  );
};

export default InfoTopBarAvatar;
