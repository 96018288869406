import { Reducer } from '@reduxjs/toolkit';

import { REDUX_ACTION_TYPES, StealthReduxState, StealthActionTypes } from '../../types';

export const stealthInitialState: StealthReduxState = {
  isStealthModalOpen: false,
  categoryId: '',
};

export const stealthReducer: Reducer<StealthReduxState, StealthActionTypes> = (state, action) => {
  const reducerState: StealthReduxState = state ?? stealthInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.OPEN_STEALTH_MODAL: {
      return {
        ...reducerState,
        isStealthModalOpen: true,
        categoryId: action.categoryId,
      };
    }

    case REDUX_ACTION_TYPES.CLOSE_STEALTH_MODAL: {
      return {
        ...reducerState,
        isStealthModalOpen: false,
        categoryId: '',
      };
    }

    default:
      return reducerState;
  }
};
