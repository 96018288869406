import { createSelector } from '@reduxjs/toolkit';

import { SelectPrivacyIsNumberBlocked, SelectPrivacyState } from '../../types';

export const selectPrivacyState: SelectPrivacyState = (state) => state.privacy;

export const selectPrivacyStatuses = createSelector([selectPrivacyState], (privacyState) => privacyState.statuses);

export const selectPrivacyBlockedNumbers = createSelector(
  [selectPrivacyState],
  (privacyState) => privacyState.blockedNumbers,
);

export const selectPrivacyIsNumberBlocked: SelectPrivacyIsNumberBlocked = (phoneNumber: string) =>
  createSelector([selectPrivacyBlockedNumbers], (blockedNumbers) => blockedNumbers.includes(phoneNumber));
