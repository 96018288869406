import { CountryCode } from 'libphonenumber-js';

import { Contact, Thread, ThreadDisplay, ContactsSimpleContactsMap } from 'types';

import { matchPhoneNumberWithContact, getDisplayName } from './contact';
import { formatNumberAsLocal } from './country_codes';
import { isPhoneNumberAndOrContactIncludesSearchTerm } from './search';

// TODO: Technical Depth => Thread or false
export const getThreadContactNumberList = (thread: Thread | false): string[] => {
  if (thread === false) {
    return [];
  }

  const { group, incoming, targetPhoneNumbers, sourcePhoneNumber, targetPhoneNumber } = thread;

  const isGroup = group;
  const isIncoming = incoming;

  if (isGroup && targetPhoneNumbers) {
    return targetPhoneNumbers;
  }
  if (isIncoming) {
    return [sourcePhoneNumber];
  }
  return [targetPhoneNumber];
};

export const getThreadContactNumberListAsLocal = (thread: Thread | false): string[] => {
  if (thread === false) {
    return [];
  }

  return getThreadContactNumberList(thread).map((contactNumber) => formatNumberAsLocal(contactNumber));
};

export const mapThreadToThreadDisplay = (
  thread: Thread,
  phoneNumberContactMap: ContactsSimpleContactsMap,
  countryCode: CountryCode,
): ThreadDisplay => {
  const displayName = getThreadContactNumberListAsLocal(thread)
    .map((contactNumber) => {
      const foundContact = matchPhoneNumberWithContact(contactNumber, phoneNumberContactMap, countryCode);
      if (foundContact) {
        return getDisplayName(foundContact);
      }
      return contactNumber;
    })
    .join(', ');

  return {
    ...thread,
    displayName,
  };
};

export const matchThreadWithContact = (
  thread: Pick<Thread, 'incoming' | 'targetPhoneNumber' | 'sourcePhoneNumber'> | false | null,
  phoneNumberContactMap: ContactsSimpleContactsMap,
  countryCode: CountryCode,
): Contact | null => {
  if (thread !== false && thread !== null) {
    const threadPhoneNumber = (thread.incoming ? thread.sourcePhoneNumber : thread.targetPhoneNumber) || '';
    return matchPhoneNumberWithContact(threadPhoneNumber, phoneNumberContactMap, countryCode) ?? null;
  }
  return null;
};

export const isThreadFilteredByUnreadMessages = (
  isUnreadMessage: boolean,
  thread: Thread,
  threadActiveId: string,
): boolean => {
  const isThreadActive = threadActiveId.length > 0 && threadActiveId === thread.threadId;
  const { unreadMessages = 0 } = thread;
  const hasUnreadMessages = unreadMessages > 0;
  return !isUnreadMessage || isThreadActive || hasUnreadMessages;
};

export const isThreadFilteredBySearchString = (
  searchString: string,
  thread: Thread,
  phoneNumberContactMap: ContactsSimpleContactsMap,
  countryCode: CountryCode,
): boolean => {
  if (searchString.trim().length === 0) {
    return true;
  }

  const contactNumberList = getThreadContactNumberListAsLocal(thread);

  return contactNumberList.some((phoneNumber) => {
    const contact = matchPhoneNumberWithContact(phoneNumber, phoneNumberContactMap, countryCode);
    return isPhoneNumberAndOrContactIncludesSearchTerm({
      contact,
      phoneNumber,
      summary: thread.body,
      searchTerm: searchString,
    });
  });
};
