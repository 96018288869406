import { useEffect, useState } from 'react';

type UseRenewalPeriodModalProps = {
  isModalOpenByDefault: boolean;
};

type UseRenewalPeriodModalReturn = {
  isModalOpen: boolean;
  onModalOpenHandler: () => void;
  onModalCloseHandler: () => void;
};

export const useRenewalPeriodModal = ({
  isModalOpenByDefault = false,
}: UseRenewalPeriodModalProps): UseRenewalPeriodModalReturn => {
  const [isModalOpen, setIsModalOpen] = useState(isModalOpenByDefault);

  const onModalOpenHandler = () => {
    setIsModalOpen(true);
  };

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpenByDefault) {
      onModalOpenHandler();
    }
  }, [isModalOpenByDefault]);

  return {
    isModalOpen,
    onModalOpenHandler,
    onModalCloseHandler,
  };
};
