import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast } from '@onoff/toast-notification';

import {
  useWebAppDispatch,
  useWebAppSelector,
  sipSetDataCallStatusFeatureHoldByWebRTCIdHandler,
  sipSetDataCallStatusFeatureUnHoldByWebRTCIdHandler,
  selectSIPDataCallByStatusFeatureHold,
  selectSIPDataCallByStatusFeatureUnHold,
} from '@redux';
import { SIPStatisticsScopes, SIPDataCallStatusesFeatureHold, SIPDataCallStatusesFeatureUnHold } from '@redux/types';

import {
  sipAudioManagementAudioPause,
  sipAudioManagementAudioPlay,
  sipDebugConsoleLogger,
} from '../../../../../../../../helpers';
import { useSIPStatistics } from '../../../../../../../useSIPStatistics';

import { UseWebRTCSessionsHoldOrUnHoldProps } from './types';

export const useWebRTCSessionsHoldOrUnHold = ({ propWebRTCSessions }: UseWebRTCSessionsHoldOrUnHoldProps): void => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { idWebRTCForHold, idTransactionForHold, originatorForHold } = useWebAppSelector(
    selectSIPDataCallByStatusFeatureHold,
  );
  const { idWebRTCForUnHold, idTransactionForUnHold, originatorForUnHold } = useWebAppSelector(
    selectSIPDataCallByStatusFeatureUnHold,
  );

  const { sipSendErrorToSentry, sipSendErrorToGTM } = useSIPStatistics();

  const propWebRTCSessionForHold = propWebRTCSessions.find(({ id }) => id === idWebRTCForHold);
  const propWebRTCSessionForUnHold = propWebRTCSessions.find(({ id }) => id === idWebRTCForUnHold);

  const callbackWebRTCSessionsHoldHandler = (): void => {
    if (idWebRTCForHold && idTransactionForHold && originatorForHold && propWebRTCSessionForHold) {
      try {
        propWebRTCSessionForHold.hold(undefined, () => {
          sipAudioManagementAudioPause({ idWebRTC: idWebRTCForHold });
        });

        dispatch(
          sipSetDataCallStatusFeatureHoldByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForHold,
            propCallStatusFeatureHold: SIPDataCallStatusesFeatureHold.HELD,
          }),
        );
      } catch (error) {
        sipDebugConsoleLogger('SIP WEBRTC: CALL HOLD IS FAILED!', { error });

        const errorMessage = error instanceof Error ? error.message : error;

        dispatch(
          sipSetDataCallStatusFeatureHoldByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForHold,
            propCallStatusFeatureHold: SIPDataCallStatusesFeatureHold.FAILED,
          }),
        );

        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_WEBRTC,
          error: `CALL HOLD IS FAILED! "${errorMessage}"`,
        });

        sipSendErrorToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_WEBRTC,
          sipCallOriginator: originatorForHold,
          sipCallTitle: 'CALL HOLD IS FAILED!',
          sipCallDescription: `${errorMessage}`,
          sipCallTransactionId: idTransactionForHold,
        });

        showErrorToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.CallUnHoldFailure.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.CallUnHoldFailure.description' }),
        });
      }
    }
  };

  const callbackWebRTCSessionsUnHoldHandler = (): void => {
    if (idWebRTCForUnHold && idTransactionForUnHold && originatorForUnHold && propWebRTCSessionForUnHold) {
      try {
        propWebRTCSessionForUnHold.unhold(undefined, () => {
          sipAudioManagementAudioPlay({ idWebRTC: idWebRTCForUnHold });
        });

        dispatch(
          sipSetDataCallStatusFeatureUnHoldByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForUnHold,
            propCallStatusFeatureUnHold: SIPDataCallStatusesFeatureUnHold.UNHELD,
          }),
        );
      } catch (error) {
        sipDebugConsoleLogger('SIP WEBRTC: CALL UNHOLD IS FAILED!', { error });

        const errorMessage = error instanceof Error ? error.message : error;

        dispatch(
          sipSetDataCallStatusFeatureUnHoldByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForUnHold,
            propCallStatusFeatureUnHold: SIPDataCallStatusesFeatureUnHold.FAILED,
          }),
        );

        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_WEBRTC,
          error: `CALL UNHOLD IS FAILED! "${errorMessage}"`,
        });

        sipSendErrorToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_WEBRTC,
          sipCallOriginator: originatorForUnHold,
          sipCallTitle: 'CALL UNHOLD IS FAILED!',
          sipCallDescription: `${errorMessage}`,
          sipCallTransactionId: idTransactionForUnHold,
        });

        showErrorToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.CallUnHoldFailure.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.CallUnHoldFailure.description' }),
        });
      }
    }
  };

  useEffect(callbackWebRTCSessionsHoldHandler, [
    idWebRTCForHold,
    idTransactionForHold,
    originatorForHold,
    propWebRTCSessionForHold,
    intl,
    dispatch,
    sipSendErrorToSentry,
    sipSendErrorToGTM,
  ]);

  useEffect(callbackWebRTCSessionsUnHoldHandler, [
    idWebRTCForUnHold,
    idTransactionForUnHold,
    originatorForUnHold,
    propWebRTCSessionForUnHold,
    intl,
    dispatch,
    sipSendErrorToSentry,
    sipSendErrorToGTM,
  ]);
};
