import parsePhoneNumberFromString from 'libphonenumber-js';
import React from 'react';

import { Select } from '@onoff/ui';

import { Contact, ContactPhoneNumberCard } from 'types';

import { AvatarCommon, AvatarSize } from 'components/Avatar';

import styles from './DropdownPhoneNumberOption.module.scss';

export type DropdownPhoneNumberOptionProps = ContactPhoneNumberCard & {
  onClick?: (phoneNumber: string, contactId: Contact['id']) => void;
};

const DropdownPhoneNumberOption: React.FC<DropdownPhoneNumberOptionProps> = ({
  avatarProps,
  contactId,
  contactFullName,
  onClick = () => undefined,
  phoneNumber = '',
}) => (
  <Select.Item
    id={contactId}
    className={styles.root}
    onClick={() => onClick(phoneNumber, contactId)}
  >
    <AvatarCommon
      className={styles.avatar}
      size={AvatarSize.SMALL}
      {...avatarProps}
    />
    <div className={styles.details}>
      <p className={styles.title}>{contactFullName}</p>
      {phoneNumber.length > 0 && (
        <p className={styles.caption}>
          {parsePhoneNumberFromString(phoneNumber)?.formatInternational() || phoneNumber}
        </p>
      )}
    </div>
  </Select.Item>
);

export default DropdownPhoneNumberOption;
