import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { useIntl } from 'react-intl';

export const useDateTimeFormatted = (time: string | undefined | null): string => {
  const intl = useIntl();

  if (!time) {
    return '';
  }

  dayjs.extend(isToday);
  dayjs.extend(isYesterday);

  const date = dayjs(new Date(time));

  if (date.isToday()) {
    return `${intl.formatMessage({ id: 'DateTime.today' })}, ${date.format('HH:mm')}`;
  }

  if (date.isYesterday()) {
    return `${intl.formatMessage({ id: 'DateTime.yesterday' })}, ${date.format('HH:mm')}`;
  }

  return date.format('DD/MM/YY, HH:mm');
};
