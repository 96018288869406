import { useEffect } from 'react';

import { Status } from 'types';

import {
  checkoutFetchAndSetPublicKeyDataHandler,
  selectCheckoutPublicKeyState,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';

type UseGetCheckoutPublicKeyReturn = {
  publicKey: string;
  status: Status;
};

export const useGetCheckoutPublicKey = (): UseGetCheckoutPublicKeyReturn => {
  const dispatch = useWebAppDispatch();

  const { isLoading, isFailure, data: publicKey } = useWebAppSelector(selectCheckoutPublicKeyState);

  useEffect(() => {
    dispatch(checkoutFetchAndSetPublicKeyDataHandler());
  }, [dispatch]);

  let checkoutPublicKeyStatus = Status.IDLE;

  if (isLoading) {
    checkoutPublicKeyStatus = Status.LOADING;
  }
  if (isFailure) {
    checkoutPublicKeyStatus = Status.FAILED;
  }

  return {
    publicKey,
    status: checkoutPublicKeyStatus,
  };
};
