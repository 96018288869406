import classNames from 'classnames';
import React from 'react';

import { StyleableComponent } from 'types';

import { isString } from 'helpers';

import { PhoneKeyboardSize } from 'components/PhoneKeyboard/enums';

import PhoneKeyboardButton from '../PhoneKeyboardButton';

import styles from './PhoneKeyboardCommon.module.scss';

interface PhoneKeyboardCommonProps extends StyleableComponent {
  onClickKey: (number: string) => void;
  longPressThreshold?: number;
  isDisabled?: boolean;
  size?: PhoneKeyboardSize;
}

const PhoneKeyboardCommon: React.FC<PhoneKeyboardCommonProps> = ({
  onClickKey,
  longPressThreshold,
  isDisabled = false,
  size = PhoneKeyboardSize.M,
  className = '',
}) => {
  const buttonLabels: Array<string | [string, string]> = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '*',
    ['0', '+'],
    '#',
  ];

  return (
    <div className={classNames(styles.root, styles[`size-${size}`], className)}>
      {buttonLabels.map((buttonLabel, i) => (
        <PhoneKeyboardButton
          key={i}
          longPressValue={isString(buttonLabel) ? undefined : buttonLabel[1]}
          longPressThreshold={longPressThreshold}
          onClick={onClickKey}
          value={isString(buttonLabel) ? buttonLabel : buttonLabel[0]}
          disabled={isDisabled}
          size={size}
        />
      ))}
    </div>
  );
};

export default PhoneKeyboardCommon;
