import classNames from 'classnames';
import React, { useRef, useEffect, useState, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';

import { StyleableComponent, TestableComponent } from 'types';

import { testGetTestingAttributes } from '@testing';

import { ModalKeycode, ModalSize } from '../../enums';

import styles from './ModalCore.module.scss';

export interface ModalProps extends StyleableComponent, Pick<TestableComponent, 'propTestIds'> {
  isOpen: boolean;
  onRequestClose?: () => void;
  onCloseAnimationEnd?: () => void;
  size?: ModalSize;
  zIndex?: number;
  className?: string;
  classNameOverlay?: string;
  canOverlayClose?: boolean;
  canEscClose?: boolean;
}

const Modal: React.FC<ModalProps & PropsWithChildren> = ({
  isOpen,
  className,
  classNameOverlay,
  children,
  onRequestClose = () => null,
  onCloseAnimationEnd = () => null,
  size = ModalSize.M,
  zIndex = 19,
  canOverlayClose = true,
  canEscClose = true,
  propTestIds = {
    modalOverlay: '',
    modal: '',
  },
}) => {
  const [renderPortal, setRenderPortal] = useState(isOpen);

  const overlayRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const openModal = () => {
    setRenderPortal(true);
  };

  const closeModal = () => {
    if (!modalRef.current || !overlayRef.current) return;

    const onAnimationEnd = () => {
      modalRef.current?.removeEventListener('animationend', onAnimationEnd);
      onCloseAnimationEnd();
      setRenderPortal(false);
    };

    modalRef.current.addEventListener('animationend', onAnimationEnd);
    overlayRef.current.classList.add(styles.fadeOutAnimation);
    modalRef.current.classList.add(styles.fadeOutAndMoveAnimation);
  };

  const handleIsOpenStateChange = () => {
    if (isOpen) {
      openModal();
    } else {
      closeModal();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeydown = (event: KeyboardEvent) => {
    if (event.code === ModalKeycode.ESCAPE) {
      onRequestClose();
    }
  };

  const handleEscEventHandler = () => {
    if (isOpen && canEscClose) {
      window.document.addEventListener('keydown', handleKeydown);
    } else {
      window.document.removeEventListener('keydown', handleKeydown);
    }

    return () => {
      if (!isOpen) {
        window.document.removeEventListener('keydown', handleKeydown);
      }
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleIsOpenStateChange, [isOpen]);
  useEffect(handleEscEventHandler, [isOpen, canEscClose, handleKeydown]);

  if (!renderPortal) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={styles.root}
      style={{ zIndex }}
    >
      <div
        ref={overlayRef}
        className={classNames(styles.overlay, classNameOverlay)}
        onClick={canOverlayClose ? onRequestClose : undefined}
        {...testGetTestingAttributes(propTestIds.modalOverlay)}
      />
      <div
        ref={modalRef}
        className={classNames(styles.modal, styles[`size-${size}`], className)}
        {...testGetTestingAttributes(propTestIds.modal)}
      >
        {children}
      </div>
    </div>,
    window.document.body,
  );
};

export default Modal;
