import { Reducer } from '@reduxjs/toolkit';

import { ContactsSimpleContactsMap, Status } from 'types';

import {
  addPhoneNumberContactMapElement,
  addSimpleContact,
  constructPhoneNumberContactMap,
  constructSimpleContacts,
  constructSimpleContactsMap,
  filteredPhoneNumberContactMapElementByDeletion,
  filteredSimpleContactsByRemaining,
  filteredSimpleContactsMapElementsByRemaining,
  mapSimpleContactToContact,
  updatePhoneNumberContactMap,
  updateSimpleContact,
} from 'helpers';

import { ContactsActionTypes, ContactsReduxState, REDUX_ACTION_TYPES } from '../../types';

export const contactsInitialState: ContactsReduxState = {
  simpleContacts: [],
  simpleContactsMap: {},
  phoneNumberContactMap: {},
  simpleContactsTotal: 0,
  activeId: '',
  searchString: '',
  loading: true,
  statuses: {
    fetchContacts: Status.IDLE,
    deleteContact: Status.IDLE,
    saveContact: Status.IDLE,
  },
};

export const contactsReducer: Reducer<ContactsReduxState, ContactsActionTypes> = (state, action) => {
  const reducerState: ContactsReduxState = state ?? contactsInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.SET_SIMPLE_CONTACTS: {
      const simpleContactsMap = constructSimpleContactsMap(action.contacts);
      const phoneNumberContactMap = constructPhoneNumberContactMap(action.contacts);
      const simpleContacts = constructSimpleContacts(action.contacts);

      return {
        ...reducerState,
        simpleContacts,
        simpleContactsMap,
        phoneNumberContactMap,
        simpleContactsTotal: action.contacts.length,
        loading: false,
      };
    }

    case REDUX_ACTION_TYPES.ADD_SIMPLE_CONTACT: {
      const mappedSimpleContact = addSimpleContact(action.contact);
      const simpleContactsMap: ContactsSimpleContactsMap = {
        ...reducerState.simpleContactsMap,
        [action.contact.id]: mapSimpleContactToContact(action.contact, action.contact.id),
      };

      return {
        ...reducerState,
        simpleContacts: [...reducerState.simpleContacts, mappedSimpleContact],
        simpleContactsMap,
        phoneNumberContactMap: addPhoneNumberContactMapElement(reducerState.phoneNumberContactMap, action.contact),
      };
    }

    case REDUX_ACTION_TYPES.DELETE_SIMPLE_CONTACTS: {
      return {
        ...reducerState,
        simpleContacts: filteredSimpleContactsByRemaining(reducerState.simpleContacts, action.contactIds),
        simpleContactsMap: filteredSimpleContactsMapElementsByRemaining(
          reducerState.simpleContactsMap,
          action.contactIds,
        ),
        phoneNumberContactMap: filteredPhoneNumberContactMapElementByDeletion(
          reducerState.phoneNumberContactMap,
          action.contactIds,
        ),
      };
    }

    case REDUX_ACTION_TYPES.UPDATE_SIMPLE_CONTACT: {
      const simpleContactsMap: ContactsSimpleContactsMap = {
        ...reducerState.simpleContactsMap,
        [action.contact.id]: mapSimpleContactToContact(action.contact, action.contact.id),
      };

      return {
        ...reducerState,
        simpleContactsMap: reducerState.simpleContactsMap[action.contact.id]
          ? simpleContactsMap
          : reducerState.simpleContactsMap,
        simpleContacts: updateSimpleContact(reducerState.simpleContacts, action.contact),
        phoneNumberContactMap: updatePhoneNumberContactMap(reducerState.phoneNumberContactMap, action.contact),
      };
    }

    case REDUX_ACTION_TYPES.CONTACTS_SET_SEARCH_STRING: {
      return {
        ...reducerState,
        searchString: action.searchString,
      };
    }

    case REDUX_ACTION_TYPES.CONTACTS_CLEAR_SEARCH_STRING: {
      return {
        ...reducerState,
        searchString: '',
      };
    }

    case REDUX_ACTION_TYPES.CONTACTS_SET_ACTIVE_ID: {
      return {
        ...reducerState,
        activeId: action.contactId,
      };
    }

    case REDUX_ACTION_TYPES.CONTACTS_SET_STATUS: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          [action.request]: action.status,
        },
      };
    }

    default: {
      return reducerState;
    }
  }
};
