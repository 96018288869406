import { API_ERRORS_LIST } from '@constants';

import { ApiError, ApiWebSiteError, RequestAborted } from '../../api';
import { ApiErrorValue } from '../../types';

import isArray from './isArray';
import isObject from './isObject';
import isString from './isString';

export const isApiError = (input: unknown): input is ApiError => isObject(input) && isString(input.code);

export const isKnownApiErrorAsString = (value: unknown): value is ApiErrorValue =>
  isString(value) && Object.values<string>(API_ERRORS_LIST).includes(value);

export const isKnownApiErrorAsArray = (value: unknown): value is [ApiErrorValue] =>
  isArray<string>(value) && Object.values<string>(API_ERRORS_LIST).includes(value[0]);

export const isApiWebSiteError = (error: unknown): error is ApiWebSiteError =>
  isObject(error) && isString(error.status);

export const isErrorObject = (value: unknown): value is Error =>
  !!(value && value !== typeof 'string' && value instanceof Error && value.message);

export const isRequestAborted = (error: unknown): error is RequestAborted =>
  (error as RequestAborted).code === API_ERRORS_LIST.REQUEST_ABORTED;
