import {
  PlansAndOffersService,
  PlansAndOffersServiceFetchUserOfferInfoReturn,
  PlansAndOffersServiceCancelUserOfferSubscriptionReturn,
  PlansAndOffersAvailableUserOfferReturn,
  PlansAndOfferProductPricesResponse,
  PlansAndOfferProductPricesRequest,
  PlansStatisticsResponse,
  PlansStatisticsRequest,
  PlansAndOffersBuyUserOfferRequest,
  FetchAvailablePlansRequest,
  FetchAvailablePlansResponse,
  ChangeDurationsRequest,
  ChangeDurationsResponse,
  ChangeDurationsEndpointRequest,
  CancelChangeDurationRequest,
} from 'types';
import { CancelUserOfferSubscriptionRequest, EmptyResponse, UserOfferInfoResponse } from 'types/server/main';

import { apiMobile, apiPurchase } from 'api';

const plansAndOffersService: PlansAndOffersService = {
  fetchUserOfferInfo: async (): Promise<PlansAndOffersServiceFetchUserOfferInfoReturn> => {
    try {
      const response = await apiMobile.get<UserOfferInfoResponse>('/authenticated/user-offer/info', {});

      const { subscriptionId, status, source, expirationDate, planDuration, nextPlanDuration } = response;

      if (
        subscriptionId === undefined ||
        status === undefined ||
        source === undefined ||
        expirationDate === undefined
      ) {
        throw new Error('Dev Error: API Error');
      }

      return {
        isFailure: false,
        userOffer: {
          subscriptionId,
          status,
          source,
          expirationDate,
          planDuration,
          nextPlanDuration,
        },
      };
    } catch {
      return {
        isFailure: true,
        userOffer: {},
      };
    }
  },

  cancelUserOfferSubscription: async ({
    subscriptionId,
  }: CancelUserOfferSubscriptionRequest): Promise<PlansAndOffersServiceCancelUserOfferSubscriptionReturn> => {
    try {
      await apiMobile.post<void, CancelUserOfferSubscriptionRequest>('/authenticated/user-offer/cancel-subscription', {
        subscriptionId,
      });

      return {
        isFailure: false,
      };
    } catch {
      return {
        isFailure: true,
      };
    }
  },

  fetchAvailableUserOffer: () =>
    apiMobile.get<PlansAndOffersAvailableUserOfferReturn>('/authenticated/user-offer/available', {}),

  fetchProductPrices: (args) =>
    apiPurchase.post<PlansAndOfferProductPricesResponse, PlansAndOfferProductPricesRequest>(
      '/authenticated/products/get-prices',
      args,
    ),

  buyUserOffer: (args: PlansAndOffersBuyUserOfferRequest) =>
    apiPurchase.post<EmptyResponse, PlansAndOffersBuyUserOfferRequest>(
      '/authenticated/credit-card/buy-user-offer',
      args,
    ),

  fetchPlansStatistics: (categoryIds) =>
    apiMobile.post<PlansStatisticsResponse, PlansStatisticsRequest>('/plans/v2/statistics', { categoryIds }),

  fetchAvailablePlans: (args: FetchAvailablePlansRequest) =>
    apiMobile.post<FetchAvailablePlansResponse, FetchAvailablePlansRequest>(
      '/authenticated/onboarding/plans-available',
      args,
    ),

  changeDurations: (subscriptions: ChangeDurationsRequest) =>
    apiMobile.post<ChangeDurationsResponse, ChangeDurationsEndpointRequest>('/purchase/subscription/change-durations', {
      subscriptions,
    }),

  cancelChangeDuration: (args: CancelChangeDurationRequest) =>
    apiMobile.post<void, CancelChangeDurationRequest>('/purchase/subscription/cancel-change-duration', args),
};

export default plansAndOffersService;
