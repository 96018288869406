import React from 'react';

import { StyleableComponent } from 'types';

const DeviceHub: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17 16.0002L13 12.0002V8.82023C14.35 8.33023 15.26 6.93023 14.93 5.36023C14.68 4.18023 13.7 3.24023 12.51 3.04023C10.63 2.73023 9 4.17023 9 6.00023C9 7.30023 9.84 8.40023 11 8.82023V12.0002L7 16.0002H4C3.45 16.0002 3 16.4502 3 17.0002V20.0002C3 20.5502 3.45 21.0002 4 21.0002H7C7.55 21.0002 8 20.5502 8 20.0002V17.9502L12 13.7502L16 17.9502V20.0002C16 20.5502 16.45 21.0002 17 21.0002H20C20.55 21.0002 21 20.5502 21 20.0002V17.0002C21 16.4502 20.55 16.0002 20 16.0002H17Z"
      fill="currentColor"
    />
  </svg>
);

export default DeviceHub;
