import React, { useEffect } from 'react';

import { useWebAppSelector, selectSIPDataCallsByLocalAndRinging } from '@redux';
import { Modal, ModalHeader, ModalBody } from 'components/Modal';

import ModalsDialpadContent from '../ModalsDialpadContent';

import styles from './ModalsDialpadCore.module.scss';

interface ModalsDialpadCoreProps {
  isOpen: boolean;
  onRequestClose: () => void;
  prefilledPhoneNumberRemote?: string;
}

const ModalsDialpadCore: React.FC<ModalsDialpadCoreProps> = ({
  isOpen,
  onRequestClose,
  prefilledPhoneNumberRemote = '',
}) => {
  const { dataSIPCallsByLocalAndRinging } = useWebAppSelector(selectSIPDataCallsByLocalAndRinging);

  const callbackDialpadCloseHandler = (): void => {
    if (dataSIPCallsByLocalAndRinging.length > 0) {
      onRequestClose();
    }
  };

  useEffect(callbackDialpadCloseHandler, [onRequestClose, dataSIPCallsByLocalAndRinging]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.dialPadModal}
    >
      <ModalHeader
        onCloseButtonClick={onRequestClose}
        className={styles.header}
      />
      <ModalBody className={styles.content}>
        <ModalsDialpadContent prefilledPhoneNumberRemote={prefilledPhoneNumberRemote} />
      </ModalBody>
    </Modal>
  );
};

export default ModalsDialpadCore;
