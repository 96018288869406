import { CountryCode } from 'libphonenumber-js';

import { SIPStatisticsCallCTAs, SIPWebRTCDirections, SIPWebRTCOriginators } from '@redux/types';

import {
  AccountType,
  CallNotesOrigin,
  CallTransferType,
  DurationThreshold,
  PaymentMethod,
  SupportedLanguage,
  UserTypes,
} from '../../core';

export enum GAEventKey {
  RESEND_DATALAYER_VARIABLES = 'resend_datalayer_variables',
  INIT_USER = 'initUser',
  INIT_OUTGOING_CALL = 'init_outgoingCall',
  ESTABLISHED_OUTGOING_CALL = 'established_outgoingCall',
  DISPLAY_CALL_POPUP_CALL_SUMMARY = 'display_callpopup_callSummary',
  DISPLAY_CALL_INCOMING_POPUP = 'display_callpopup_incomingCall',
  CALL_ERRORS = 'call_errors',
  CALL_LOGS = 'call_logs',
  CALL_RECORDING_LOADED = 'call_recording_loaded',
  CALL_INIT_OUTGOING = 'call_init_outgoing',
  CALL_ESTABLISHED_OUTGOING = 'call_established_outgoing',
  CALL_ESTABLISHED_INCOMING = 'call_established_incoming',
  CALL_BUSY_OUTGOING = 'call_busy_outgoing',
  CALL_NOTFOUND_OUTGOING = 'call_notfound_outgoing',
  CALL_UNAVAILABLE_OUTGOING = 'call_unavailable_outgoing',
  CALL_ADDRESS_INCOMPLETE_OUTGOING = 'call_address_incomplete_outgoing',
  CALL_ENDED = 'call_ended',
  CALL_NOTE_SAVED = 'call_note_saved',
  CALL_NOTE_UPDATED = 'call_note_updated',
  CALL_TRANSFER_INITIATE_SUCCESS = 'call_transfer_initiate_success',
  CALL_TRANSFER_INITIATE_FAIL = 'call_transfer_initiate_fail',
  PREMIUM_UPGRADE_PROMO = 'premium_upgrade_promo',
  PREMIUM_UPGRADE_CHECKOUT = 'premium_upgrade_checkout',
  PREMIUM_UPGRADE_SUCCESS = 'premium_upgrade_success',
  PREMIUM_UPGRADE_ERROR = 'premium_upgrade_error',
  PREMIUM_UNSUBSCRIBE_SUCCESS = 'premium_unsubscribe_success',
  ADD_CREDIT_CARD_SUCCESS = 'add_cc_success',
  ADD_CREDIT_CARD_FAILURE = 'add_cc_failure',
  REMOVE_CREDIT_CARD_SUCCESS = 'remove_cc_success',
  USER_EMAIL_CHANGE_SUCCESS = 'user_email_change_success',
  USER_CHANGE_PASSWORD_SUCCESS = 'user_change_password_success',
  USER_ACCOUNT_DELETED = 'user_account_deleted',
  USER_LANGUAGE_CHANGED = 'user_language_changed',
  CATEGORIES_UNSUBSCRIBE_SUCCESS = 'categories_unsubscribe_success',
  CATEGORIES_DELETE_NUMBER_SUCCESS = 'categories_deleteNumber_success',
  SURVEY_SENT = 'survey_sent',
  SURVEY_EXPIRED = 'survey_expired',
  SURVEY_DISPLAY = 'survey_display',
  RENEWAL_PERIOD_OFFER_UPDATE = 'renewal_period_offer_update',
  RENEWAL_PERIOD_OFFER_UPDATE_CANCELLED = 'renewal_period_offer_update_cancelled',
  RENEWAL_PERIOD_PLAN_UPDATE = 'renewal_period_plan_update',
  RENEWAL_PERIOD_PLAN_UPDATE_CANCELLED = 'renewal_period_plan_update_cancelled',
  RENEW_SUBSCRIPTION_SUCCESS = 'renew_subscription_success',
  RENEW_SUBSCRIPTION_ERROR = 'renew_subscription_error',
}

/* eslint-disable camelcase */
export interface DataLayerVariablesSIP {
  sipCallCase: string;
  sipCallOriginator: SIPWebRTCOriginators;
  sipCallTitle: string;
  sipCallDescription: string;
  sipCallTransactionId: string;
}

export interface DataLayerVariablesSIPCallGlobal {
  callIsCodecPCMA: string;
}

export interface DataLayerVariablesSIPCallInitOutgoing {
  callIsContact: string;
  callIsInternational: string;
  callCTA: SIPStatisticsCallCTAs;
}

export interface DataLayerVariablesSIPCallEstablished {
  callTransactionId: string;
  callRemoteNumber: string;
  callSetupTime: number;
}

export interface DataLayerVariablesSIPCallEnded {
  callTransactionId: string;
  callDirection: SIPWebRTCDirections;
  callEventOriginator: SIPWebRTCOriginators;
  callEventStatus: string;
  callDuration: number;
  callMOS: number;
  callMOSJitterMin: number;
  callMOSJitterMax: number;
  callMOSJitterAverage: number;
  callMOSLatencyAverage: number;
  callMOSPacketsLost: number;
  callMOSDescription: string;
  callMOSNetworkType: string;
  callMOSNetworkChangedDuringTheCall: string;
}

export interface DataLayerVariablesUser {
  user_account_deletion_reason: string;
  user_selected_language: SupportedLanguage;
}

export interface DataLayerVariablesCategories {
  categories_number_countryIsoCode: CountryCode;
}

export interface DataLayerVariablesCallRecording {
  load_time: DurationThreshold;
}

export interface DataLayerVariablesCallQualitySurvey {
  transaction_id: string;
  app_version: string;
  rating: number;
  reasons: string;
  free_text: string;
  internet_connection_type: string;
}

export interface DataLayerVariablesRenewalPeriod {
  current_renewal_period: number;
  future_renewal_period: number;
  additionally_apply_to_premium_offer: 'Yes' | 'No';
  additionally_apply_to_all_numbers: 'Yes' | 'No';
}

export interface DataLayerVariablesCallNotes {
  origin: CallNotesOrigin;
  call_in_progress: 'Yes' | 'No';
}

export interface DataLayerVariablesCallTransfer {
  transferType: CallTransferType;
}

export interface DataLayerVariablesPaymentMethod {
  payment_method: PaymentMethod;
}

export interface DataLayerVariables
  extends DataLayerVariablesSIP,
    DataLayerVariablesSIPCallGlobal,
    DataLayerVariablesSIPCallInitOutgoing,
    DataLayerVariablesSIPCallEstablished,
    DataLayerVariablesSIPCallEnded,
    DataLayerVariablesUser,
    DataLayerVariablesCategories,
    DataLayerVariablesCallQualitySurvey,
    DataLayerVariablesRenewalPeriod,
    DataLayerVariablesPaymentMethod,
    DataLayerVariablesCallNotes,
    DataLayerVariablesCallTransfer,
    DataLayerVariablesCallRecording {
  // common
  error_title: string;
  error_code: string;
  error_description: string;

  // user/init
  userID: string;
  userType: UserTypes;
  accountType: AccountType;
  countryIsoCode: CountryCode;
  lifetimeDays: number; // (date now - oldest category creation date) as days
  syncContacts: string;
  callAnswerMethod: string;
  callRecording_status: string;
  nb_activeCategories: number;
  webAppVersion: string;
  browserName: string;
  browserVersion: string;
  nb_multidevice: number; // connected devices count + self
  cc_expired_count: number; // The count of expired credit cards
  cc_active_count: number; // The count of active credit cards
  nb_activeIntegrations: number; // The count of active integrations
  list_activeIntegrations: string; // The name of active integrations by sorted + joined with comma

  // premium upgrade
  click_source: PremiumUpgradePromoClickSource;
  price: number;
  trial: string;
  currency: string;
}
/* eslint-enable camelcase */

interface DataLayerArguments {
  event: GAEventKey;
  variables?: Partial<DataLayerVariables>;
}

export interface AnalyticsService {
  init: () => void;
  pushToDataLayer: (args: DataLayerArguments) => void;
  fetchGoogleTagManagerClientId: () => Promise<string | undefined>;
}

export enum PremiumUpgradePromoClickSource {
  EMAIL = 'email',
  SPLASH = 'splash',
  BANNER = 'banner',
  SETTINGS = 'settings',
}
