import {
  AuthService,
  AuthLoginRequest,
  AuthLoginResponse,
  AuthRenewAuthKeyResponse,
  AuthCaptchaCheckStatusRequest,
  AuthCaptchaCheckStatusResponse,
  AuthCaptchaVerifyRegisterTokenRequest,
  AuthCaptchaVerifyRegisterTokenResponse,
  AuthPasswordRecoveryRequest,
  AuthPasswordRecoveryVerifyRecoveryTokenRequest,
  AuthPasswordRecoveryVerifyRecoveryTokenResponse,
  AuthPasswordRecoveryChangePasswordRequest,
  AuthPasswordRecoveryChangePasswordResponse,
} from 'types';

import { apiMobile } from 'api';

const authService: AuthService = {
  login: (props: AuthLoginRequest) => apiMobile.post<AuthLoginResponse, AuthLoginRequest>('/onboarding/login', props),

  logout: () => apiMobile.post<void>('/logout', {}),

  renewAuthKey: () => apiMobile.post<AuthRenewAuthKeyResponse>('/renew-auth-key', {}),

  captchaCheckStatus: (props: AuthCaptchaCheckStatusRequest) =>
    apiMobile.post<AuthCaptchaCheckStatusResponse, AuthCaptchaCheckStatusRequest>('/captcha/status', props),

  captchaVerifyAndRegisterToken: (props: AuthCaptchaVerifyRegisterTokenRequest) =>
    apiMobile.post<AuthCaptchaVerifyRegisterTokenResponse, AuthCaptchaVerifyRegisterTokenRequest>(
      '/captcha/verify-and-register-token',
      props,
    ),

  passwordRecoveryRequestRecovery: (props) =>
    apiMobile.post<void, AuthPasswordRecoveryRequest>('/password-recovery', props),

  passwordRecoveryVerifyRecoveryToken: (props: AuthPasswordRecoveryVerifyRecoveryTokenRequest) =>
    apiMobile.get<AuthPasswordRecoveryVerifyRecoveryTokenResponse, AuthPasswordRecoveryVerifyRecoveryTokenRequest>(
      '/is-password-recovery-token-ok',
      props,
    ),

  passwordRecoveryChangePassword: (props: AuthPasswordRecoveryChangePasswordRequest) =>
    apiMobile.post<AuthPasswordRecoveryChangePasswordResponse, AuthPasswordRecoveryChangePasswordRequest>(
      '/choose-new-password',
      props,
    ),
};

export default authService;
