import React from 'react';

import styles from './SIPCallsListItemNotification.module.scss';

type SIPCallsListItemNotificationProps = {
  content: string;
  icon?: React.ReactNode;
  button?: React.ReactNode;
};

export const SIPCallsListItemNotification: React.FC<SIPCallsListItemNotificationProps> = ({
  content,
  icon,
  button,
}) => (
  <div className={styles.root}>
    <div className={styles.content}>
      {icon} {content}
    </div>
    {button}
  </div>
);
