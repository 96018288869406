import { showErrorToast, showSuccessToast } from '@onoff/toast-notification';

import {
  CancelChangeDurationRequest,
  GAEventKey,
  GeneralBackendError,
  PremiumPlanPriceConfig,
  RenewalPeriod,
  Status,
} from 'types';

import {
  determineSelectedPremiumPlan,
  getPremiumOffersConfig,
  getProductPricesConfigWithOffers,
  handleApplicationError,
  isOfferAutoRenewable,
  isOfferOrCategoryPossibleToChangeDuration,
  isPlanAutoRenewable,
  isQuickRenewalPeriodChangeFlow,
  multiDeviceGetToken,
  sessionStoragePersistenceGetPaymentMethod,
  sessionStoragePersistenceSetSelectedPremiumPlan,
} from 'helpers';

import { getIntl } from '@intl';
import {
  selectCategories,
  selectCategoriesStatistics,
  selectCategoryBySubscriptionId,
  selectPlansAndOffersAvailableOffers,
  selectPlansAndOffersAvailablePlans,
  selectPlansAndOffersPremiumState,
  selectPlansAndOffersSelectedPremium,
  selectPlansAndOffersStatuses,
  selectPlansAndOffersSubscriptionIdForRenewalPeriodChange,
  selectPlansAndOffersUserOffer,
  selectUserLanguage,
  selectUserNationalCountryIsoCode,
} from '@redux/selectors';

import {
  PlansAndOfferResetPremiumOfferPriceConfig,
  PlansAndOfferSetPremiumOfferPriceConfig,
  PlansAndOfferSetSelectedPremiumOffer,
  PlansAndOffersActionAddPlans,
  PlansAndOffersActionSetAvailableOffers,
  PlansAndOffersActionSetStatusCancelUserOffer,
  PlansAndOffersActionSetStatusChangeDuration,
  PlansAndOffersActionSetStatusFetchAvailableOffersAndPlans,
  PlansAndOffersActionSetStatusFetchUserOffer,
  PlansAndOffersActionSetSubscriptionIdForRenewalPeriodChange,
  PlansAndOffersActionSetUserOffer,
  PlansAndOffersActionSetUserOfferNextPlanDuration,
  PlansAndOffersBuyUserPlanPremiumOfferHandlerProps,
  PlansAndOffersChangeDurationProps,
  PlansAndOffersPlan,
  PlansAndOffersProductIdAndDuration,
  PlansAndOffersSetFetchUserPlanPremiumPriceStatus,
  PlansAndOffersSetUserPlanPremiumSubmitStatus,
  PlansAndOffersUserOffer,
  REDUX_ACTION_TYPES,
  ThunkResult,
  PlansAndOffersActionRemoveUserOfferNextPlanDuration,
  PlansAndOffersActionSetStatusCancelChangeDuration,
} from '../../types';
import { categoriesStatisticsRemoveNextPlanDuration, categoriesStatisticsUpdateNextPlanDuration } from '../categories';

import {
  filterProductIdsAndDurations,
  getPricesAndDurations,
  getProductIdsAndDurationsFromAvailablePlans,
  getProductIdsAndDurationsFromUserOffers,
} from './helpers';

export const plansAndOffersSetStatusFetchUserOffer = (status: Status): PlansAndOffersActionSetStatusFetchUserOffer => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_FETCH_USEROFFER,
  status,
});

export const plansAndOffersSetStatusCancelUserOffer = (
  status: Status,
): PlansAndOffersActionSetStatusCancelUserOffer => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_CANCEL_USEROFFER,
  status,
});

export const plansAndOffersSetUserOffer = (userOffer: PlansAndOffersUserOffer): PlansAndOffersActionSetUserOffer => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_USEROFFER,
  userOffer,
});

export const plansAndOffersSetSubscriptionIdForRenewalPeriodChange = (
  subscriptionIdForRenewalPeriodChange: string,
): PlansAndOffersActionSetSubscriptionIdForRenewalPeriodChange => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_SUBSCRIPTION_ID_FOR_RENEWAL_PERIOD_CHANGE,
  subscriptionIdForRenewalPeriodChange,
});

export const plansAndOffersFetchUserOfferHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(plansAndOffersSetStatusFetchUserOffer(Status.LOADING));

      const { isFailure, userOffer } = await services.plansAndOffersService.fetchUserOfferInfo();
      const subscriptionIdForRenewalPeriodChange = selectPlansAndOffersSubscriptionIdForRenewalPeriodChange(getState());

      if (isFailure) {
        throw new Error('Dev Error: Something went wrong during the fetch user offer.');
      }

      dispatch(plansAndOffersSetUserOffer(userOffer));

      if (
        isQuickRenewalPeriodChangeFlow() &&
        !subscriptionIdForRenewalPeriodChange &&
        userOffer.subscriptionId &&
        isOfferAutoRenewable({ offer: userOffer })
      ) {
        dispatch(plansAndOffersSetSubscriptionIdForRenewalPeriodChange(userOffer.subscriptionId));
      }

      dispatch(plansAndOffersSetStatusFetchUserOffer(Status.SUCCEEDED));
    } catch {
      dispatch(plansAndOffersSetStatusFetchUserOffer(Status.FAILED));
    }
  };

export const plansAndOffersCancelUserOfferHandler =
  ({ subscriptionId }: { subscriptionId: string }): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(plansAndOffersSetStatusCancelUserOffer(Status.LOADING));
      const { isFailure } = await services.plansAndOffersService.cancelUserOfferSubscription({
        subscriptionId,
      });

      if (isFailure) {
        throw new Error('Dev Error: Something went wrong during the canceling user offer.');
      }

      services.analyticsService.pushToDataLayer({ event: GAEventKey.PREMIUM_UNSUBSCRIBE_SUCCESS });

      dispatch(plansAndOffersFetchUserOfferHandler());
      dispatch(plansAndOffersSetStatusCancelUserOffer(Status.SUCCEEDED));
    } catch (error) {
      dispatch(plansAndOffersSetStatusCancelUserOffer(Status.FAILED));
      throw error;
    }
  };

export const plansAndOffersSetFetchUserPlanPremiumPriceStatus = (
  status: Status,
): PlansAndOffersSetFetchUserPlanPremiumPriceStatus => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_PREMIUM_FETCH_PRICE_STATUS,
  status,
});

export const plansAndOffersSetSubmitUserPlanPremiumStatus = (
  status: Status,
): PlansAndOffersSetUserPlanPremiumSubmitStatus => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_PREMIUM_SUBMIT_OFFER_STATUS,
  status,
});

export const plansAndOfferSetUserPlanPremiumOfferPriceConfig = (
  priceConfig: PremiumPlanPriceConfig[],
): PlansAndOfferSetPremiumOfferPriceConfig => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_PREMIUM_OFFER_PRICE_CONFIG,
  priceConfig,
});

export const plansAndOfferSetSelectedPremiumOffer = (
  premiumPlan: PremiumPlanPriceConfig,
): PlansAndOfferSetSelectedPremiumOffer => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_SELECTED_PREMIUM_OFFER,
  premiumPlan,
});

export const plansAndOfferSetSelectedPremiumOfferWithPersistence =
  (period: RenewalPeriod): ThunkResult<void> =>
  (dispatch, getState) => {
    const premiumPlans = selectPlansAndOffersPremiumState(getState());
    const foundPlan = premiumPlans.find((plan) => plan.month === period);

    if (foundPlan) {
      sessionStoragePersistenceSetSelectedPremiumPlan(foundPlan);
      dispatch(plansAndOfferSetSelectedPremiumOffer(foundPlan));
    }
  };

export const plansAndOfferResetUserPlanPremiumOfferPriceConfig = (): PlansAndOfferResetPremiumOfferPriceConfig => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_RESET_PREMIUM_OFFER_PRICE_CONFIG,
});

export const plansAndOffersFetchUserPlanPremiumOfferPrice =
  (discountCode?: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services) => {
    const locationCountryCode = selectUserNationalCountryIsoCode(getState());
    const currentSelectedPlan = selectPlansAndOffersSelectedPremium(getState());

    try {
      dispatch(plansAndOffersSetFetchUserPlanPremiumPriceStatus(Status.LOADING));

      const { offers } = await services.plansAndOffersService.fetchAvailableUserOffer();

      const premiumOffers = getPremiumOffersConfig(offers);
      const offerProductIds = premiumOffers.reduce((productIds, offer) => {
        if (offer.offerProductId) {
          productIds.push(offer.offerProductId);
        }

        return productIds;
      }, [] as string[]);

      if (offerProductIds.length === 0) {
        throw new Error('Dev Error: premium offer id is not represented in the available user offers array');
      }

      const { productPrices } = await services.plansAndOffersService.fetchProductPrices({
        productIds: offerProductIds,
        discountCode,
        locationCountryCode,
      });

      if (productPrices.length === 0) {
        throw new Error('Dev Error: premium offer price config is not in the available productPrices array');
      }

      const productPricesConfigWithOffers = getProductPricesConfigWithOffers(productPrices, offers);

      dispatch(plansAndOfferSetUserPlanPremiumOfferPriceConfig(productPricesConfigWithOffers));

      const selectedPremiumPlan = determineSelectedPremiumPlan(productPricesConfigWithOffers, currentSelectedPlan);

      if (selectedPremiumPlan) {
        dispatch(plansAndOfferSetSelectedPremiumOfferWithPersistence(selectedPremiumPlan.month));
      }

      dispatch(plansAndOffersSetFetchUserPlanPremiumPriceStatus(Status.SUCCEEDED));
    } catch (error) {
      dispatch(plansAndOffersSetFetchUserPlanPremiumPriceStatus(Status.FAILED));
      handleApplicationError({ error, showToast: true, reportToSentry: true });
    }
  };

export const plansAndOffersBuyUserPlanPremiumOfferHandler =
  ({
    productId,
    paymentMethodId,
    price = 0,
    currencyCode = '',
    discountCode = undefined,
    trial = false,
  }: PlansAndOffersBuyUserPlanPremiumOfferHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      const { submitUserPlanPremium } = selectPlansAndOffersStatuses(getState());

      if (submitUserPlanPremium === Status.LOADING) {
        return;
      }

      const locationCountryCode = selectUserNationalCountryIsoCode(getState());

      dispatch(plansAndOffersSetSubmitUserPlanPremiumStatus(Status.LOADING));

      await services.plansAndOffersService.buyUserOffer({
        offerProductId: productId,
        paymentMethodId,
        discountCode,
        locationCountryCode,
      });

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.PREMIUM_UPGRADE_SUCCESS,
        variables: {
          price,
          currency: currencyCode,
          trial: `${trial}`,
          payment_method: sessionStoragePersistenceGetPaymentMethod(),
        },
      });

      dispatch(plansAndOffersSetSubmitUserPlanPremiumStatus(Status.SUCCEEDED));
    } catch (error) {
      const { code = '' } = error as GeneralBackendError;
      services.analyticsService.pushToDataLayer({
        event: GAEventKey.PREMIUM_UPGRADE_ERROR,
        variables: { error_code: code, trial: `${trial}`, payment_method: sessionStoragePersistenceGetPaymentMethod() },
      });

      dispatch(plansAndOffersSetSubmitUserPlanPremiumStatus(Status.FAILED));
    }
  };

export const plansAndOffersSetStatusFetchAvailableOffersAndPlans = (
  status: Status,
): PlansAndOffersActionSetStatusFetchAvailableOffersAndPlans => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_FETCH_AVAILABLE_OFFERS_AND_PLANS,
  status,
});

export const plansAndOffersSetAvailableOffers = (
  availableOffers: PlansAndOffersPlan[],
): PlansAndOffersActionSetAvailableOffers => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_AVAILABLE_OFFERS,
  availableOffers,
});

export const plansAndOffersAddPlans = (plans: Record<string, PlansAndOffersPlan[]>): PlansAndOffersActionAddPlans => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_ADD_PLANS,
  plans,
});

export const plansAndOffersFetchAvailableOffersAndPlans =
  (): ThunkResult<Promise<void>> => async (dispatch, getState, services) => {
    const locationCountryCode = selectUserNationalCountryIsoCode(getState());
    const categories = selectCategories(getState());
    const categoryStatistics = selectCategoriesStatistics(getState());
    const userOffer = selectPlansAndOffersUserOffer(getState());
    const userLanguage = selectUserLanguage(getState());
    const mobileToken = multiDeviceGetToken();
    const durationToExcludeForNewUsers = 3;

    try {
      dispatch(plansAndOffersSetStatusFetchAvailableOffersAndPlans(Status.LOADING));

      const offerProductIdsAndDurations: PlansAndOffersProductIdAndDuration[] = [];
      const planProductIdsAndDurationsByNumber: Record<string, PlansAndOffersProductIdAndDuration[]> = {};
      const productIdsToFetchPrices: string[] = [];

      // Offers

      const { offers } = await services.plansAndOffersService.fetchAvailableUserOffer();
      const offerDurationToExclude =
        userOffer.planDuration === durationToExcludeForNewUsers ? undefined : durationToExcludeForNewUsers;

      offerProductIdsAndDurations.push(
        ...filterProductIdsAndDurations(getProductIdsAndDurationsFromUserOffers(offers), offerDurationToExclude),
      );
      productIdsToFetchPrices.push(...offerProductIdsAndDurations.map((product) => product.productId));

      // Plans

      await Promise.all(
        categories.map(async (category) => {
          const virtualPhoneNumber = category.virtualPhoneNumber.number;
          const categoryStatistic = categoryStatistics.find((statistic) => statistic.categoryId === category.id);
          const categoryDurationToExclude =
            !!categoryStatistic && categoryStatistic.planDuration === durationToExcludeForNewUsers
              ? undefined
              : durationToExcludeForNewUsers;

          const { plans } = await services.plansAndOffersService.fetchAvailablePlans({
            virtualPhoneNumber,
            locale: userLanguage,
            simCountryIsoCodes: [locationCountryCode],
            mobileToken,
          });

          planProductIdsAndDurationsByNumber[virtualPhoneNumber] = filterProductIdsAndDurations(
            getProductIdsAndDurationsFromAvailablePlans(plans),
            categoryDurationToExclude,
          );
          productIdsToFetchPrices.push(
            ...planProductIdsAndDurationsByNumber[virtualPhoneNumber].map((product) => product.productId),
          );
        }),
      );

      // Prices for collected products

      const { productPrices } = await services.plansAndOffersService.fetchProductPrices({
        productIds: productIdsToFetchPrices,
        locationCountryCode,
      });

      // Add information to the store

      dispatch(plansAndOffersSetAvailableOffers(getPricesAndDurations(offerProductIdsAndDurations, productPrices)));

      Object.keys(planProductIdsAndDurationsByNumber).forEach((planProduct) => {
        dispatch(
          plansAndOffersAddPlans({
            [planProduct]: getPricesAndDurations(planProductIdsAndDurationsByNumber[planProduct], productPrices),
          }),
        );
      });

      dispatch(plansAndOffersSetStatusFetchAvailableOffersAndPlans(Status.SUCCEEDED));
    } catch (error) {
      dispatch(plansAndOffersSetStatusFetchAvailableOffersAndPlans(Status.FAILED));
    }
  };

export const plansAndOffersSetUserOfferNextPlanDuration = (
  nextPlanDuration: number,
): PlansAndOffersActionSetUserOfferNextPlanDuration => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_USEROFFER_NEXT_PLAN_DURATION,
  nextPlanDuration,
});

export const plansAndOffersRemoveUserOfferNextPlanDuration =
  (): PlansAndOffersActionRemoveUserOfferNextPlanDuration => ({
    type: REDUX_ACTION_TYPES.PLANSANDOFFERS_REMOVE_USEROFFER_NEXT_PLAN_DURATION,
  });

export const plansAndOffersSetStatusChangeDuration = (status: Status): PlansAndOffersActionSetStatusChangeDuration => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_CHANGE_DURATION,
  status,
});

export const plansAndOffersChangeDuration =
  ({
    subscriptionId,
    productId,
    currentPlanDuration,
    nextPlanDuration,
    additionallyApplyToPremiumOffer,
    additionallyApplyToAllNumbers,
  }: PlansAndOffersChangeDurationProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services) => {
    try {
      dispatch(plansAndOffersSetStatusChangeDuration(Status.LOADING));

      const requestData = [{ subscriptionId, productId }];
      const userOffer = selectPlansAndOffersUserOffer(getState());
      const categories = selectCategories(getState());
      const statistics = selectCategoriesStatistics(getState());
      const availableOffers = selectPlansAndOffersAvailableOffers(getState());
      const availablePlans = selectPlansAndOffersAvailablePlans(getState());
      const isCurrentProductUserOffer = userOffer.subscriptionId === subscriptionId;
      const isIndividualDurationChange = !additionallyApplyToPremiumOffer && !additionallyApplyToAllNumbers;
      let errorsCount = 0;

      if (additionallyApplyToPremiumOffer) {
        const targetOffer = availableOffers.find((offer) => offer.duration === nextPlanDuration);

        if (
          targetOffer &&
          userOffer.subscriptionId &&
          isOfferAutoRenewable({ offer: userOffer }) &&
          isOfferOrCategoryPossibleToChangeDuration({ product: userOffer })
        ) {
          requestData.push({ subscriptionId: userOffer.subscriptionId, productId: targetOffer.productId });
        }
      }

      if (additionallyApplyToAllNumbers) {
        categories.forEach((category) => {
          const categoryStatistics = statistics.find((statistic) => statistic.categoryId === category.id);
          const categoryAvailablePlans = availablePlans[category.virtualPhoneNumber.number];
          const isCategoryAlreadyInRequestData = requestData.find(
            (data) => data.subscriptionId === category.subscriptionId,
          );

          if (!categoryStatistics || !categoryAvailablePlans || isCategoryAlreadyInRequestData) {
            return;
          }

          const categoryTargetPlan = categoryAvailablePlans.find((plan) => plan.duration === nextPlanDuration);

          if (
            categoryTargetPlan &&
            category.subscriptionId &&
            isPlanAutoRenewable({ category, categoryStatistics }) &&
            isOfferOrCategoryPossibleToChangeDuration({ product: category })
          ) {
            requestData.push({ subscriptionId: category.subscriptionId, productId: categoryTargetPlan.productId });
          }
        });
      }

      const changeDurationsResponse = await services.plansAndOffersService.changeDurations(requestData);

      services.analyticsService.pushToDataLayer({
        event: isCurrentProductUserOffer
          ? GAEventKey.RENEWAL_PERIOD_OFFER_UPDATE
          : GAEventKey.RENEWAL_PERIOD_PLAN_UPDATE,
        variables: {
          current_renewal_period: currentPlanDuration,
          future_renewal_period: nextPlanDuration,
          additionally_apply_to_premium_offer: additionallyApplyToPremiumOffer ? 'Yes' : 'No',
          additionally_apply_to_all_numbers: additionallyApplyToAllNumbers ? 'Yes' : 'No',
        },
      });

      changeDurationsResponse.forEach((product) => {
        const productSubscriptionId = product.subscriptionId;

        if (userOffer.subscriptionId === productSubscriptionId) {
          dispatch(plansAndOffersSetUserOfferNextPlanDuration(nextPlanDuration));
        } else {
          categories.forEach((category) => {
            if (category.subscriptionId === productSubscriptionId) {
              dispatch(categoriesStatisticsUpdateNextPlanDuration({ categoryId: category.id, nextPlanDuration }));
            }
          });
        }

        if (product.error) {
          errorsCount += 1;
        }
      });

      if ((errorsCount && isIndividualDurationChange) || errorsCount === changeDurationsResponse.length) {
        throw new Error();
      } else if (errorsCount) {
        showSuccessToast({
          heading: getIntl().formatMessage({
            id: 'RenewalPeriod.Notifications.renewal_period_changed_partially_title',
          }),
          message: getIntl().formatMessage({
            id: 'RenewalPeriod.Notifications.renewal_period_changed_partially_description',
          }),
        });
      } else {
        showSuccessToast({
          heading: getIntl().formatMessage({ id: 'RenewalPeriod.Notifications.renewal_period_changed_title' }),
          message: getIntl().formatMessage({ id: 'RenewalPeriod.Notifications.renewal_period_changed_description' }),
        });
      }

      dispatch(plansAndOffersSetStatusChangeDuration(Status.SUCCEEDED));
    } catch (error) {
      dispatch(plansAndOffersSetStatusChangeDuration(Status.FAILED));

      showErrorToast({
        heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_error' }),
        message: getIntl().formatMessage({ id: 'Errors.oops' }),
      });
    }
  };

export const plansAndOffersSetStatusCancelChangeDuration = (
  status: Status,
): PlansAndOffersActionSetStatusCancelChangeDuration => ({
  type: REDUX_ACTION_TYPES.PLANSANDOFFERS_SET_STATUS_CANCEL_CHANGE_DURATION,
  status,
});

export const plansAndOffersCancelChangeDuration =
  ({ subscriptionId }: CancelChangeDurationRequest): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services) => {
    const possiblyRelatedCategory = selectCategoryBySubscriptionId(subscriptionId)(getState());

    try {
      dispatch(plansAndOffersSetStatusCancelChangeDuration(Status.LOADING));

      await services.plansAndOffersService.cancelChangeDuration({ subscriptionId });

      // Just two options here - we are either updating duration for categories, or for user offer
      if (possiblyRelatedCategory) {
        dispatch(categoriesStatisticsRemoveNextPlanDuration(possiblyRelatedCategory.id));

        services.analyticsService.pushToDataLayer({
          event: GAEventKey.RENEWAL_PERIOD_PLAN_UPDATE_CANCELLED,
        });
      } else {
        dispatch(plansAndOffersRemoveUserOfferNextPlanDuration());

        services.analyticsService.pushToDataLayer({
          event: GAEventKey.RENEWAL_PERIOD_OFFER_UPDATE_CANCELLED,
        });
      }

      showSuccessToast({
        heading: getIntl().formatMessage({ id: 'RenewalPeriod.Notifications.renewal_period_change_cancelled_title' }),
        message: getIntl().formatMessage({
          id: 'RenewalPeriod.Notifications.renewal_period_change_cancelled_description',
        }),
      });

      dispatch(plansAndOffersSetStatusCancelChangeDuration(Status.SUCCEEDED));
    } catch (error) {
      dispatch(plansAndOffersSetStatusCancelChangeDuration(Status.FAILED));

      showErrorToast({
        heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_error' }),
        message: getIntl().formatMessage({ id: 'Errors.oops' }),
      });
    }
  };
