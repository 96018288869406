import assetsCallsBackground from 'assets/images/feature-block-background/calls.png';
import assetsContactsBackground from 'assets/images/feature-block-background/contacts.png';
import assetsMessagesBackground from 'assets/images/feature-block-background/messages.png';
import assetsVoicemailsBackground from 'assets/images/feature-block-background/voicemails.png';

import { FeatureBlockSplashFeature } from '../enums';

export const getHeaderTextTranslationKey = (feature: FeatureBlockSplashFeature): string | undefined => {
  const featureToTextMap = new Map([
    [FeatureBlockSplashFeature.CALLS, 'FeatureBlockSplash.calls'],
    [FeatureBlockSplashFeature.MESSAGES, 'FeatureBlockSplash.messages'],
    [FeatureBlockSplashFeature.VOICEMAILS, 'FeatureBlockSplash.voicemails'],
    [FeatureBlockSplashFeature.CONTACTS, 'FeatureBlockSplash.contacts'],
  ]);

  return featureToTextMap.get(feature);
};

export const getBackgroundImageSrc = (feature: FeatureBlockSplashFeature): string | undefined => {
  const featureToAssetPathMap = new Map([
    [FeatureBlockSplashFeature.CALLS, assetsCallsBackground],
    [FeatureBlockSplashFeature.MESSAGES, assetsMessagesBackground],
    [FeatureBlockSplashFeature.VOICEMAILS, assetsVoicemailsBackground],
    [FeatureBlockSplashFeature.CONTACTS, assetsContactsBackground],
  ]);

  return featureToAssetPathMap.get(feature);
};

export const getBackgroundImageAltText = (feature: FeatureBlockSplashFeature): string =>
  `Feature illustration ${feature.toLowerCase()}`;
