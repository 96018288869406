import { CallQualitySurveyReasonOption } from '../../types';

type HasValidSurveyReasonProps = {
  reasons: string[];
  customReason: string;
};

export const hasValidSurveyReason = ({ reasons, customReason }: HasValidSurveyReasonProps) => {
  const isProvidingCustomReason = reasons.includes(CallQualitySurveyReasonOption.OTHER);

  if (isProvidingCustomReason) {
    return customReason.trim().length > 0;
  }

  return reasons.length > 0;
};
