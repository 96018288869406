import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { selectHasCategories, selectTrialAvailable, useWebAppSelector } from '@redux';
import { IconCommonNames } from 'components/Icon';
import { useUserCapabilities } from 'hooks';
import { ApplicationPaths } from 'routes';

import { NavElement } from '../../types';

const useNavElements = (): NavElement[] => {
  const intl = useIntl();
  const { pathname: currentPath } = useLocation();
  const { isUserB2B, isUserPremium, isUserStarter } = useUserCapabilities();
  const isTrialAvailable = useWebAppSelector(selectTrialAvailable);
  const hasCategories = useWebAppSelector(selectHasCategories);

  const navElementList = useMemo<NavElement[]>(
    () =>
      [
        {
          path: ApplicationPaths.PURCHASE_PREMIUM_UPGRADE,
          icon: IconCommonNames.PREMIUM_CROWN,
          label: intl.formatMessage({
            id: isTrialAvailable ? 'Header.Actions.button_trial_available' : 'Header.Actions.button_upgrade_to_premium',
          }),
          isVisibleToUserB2B: false,
          isVisibleToUserB2CStarter: hasCategories,
          isVisibleToUserB2CPremium: false,
          isVisibleToSettingsPage: false,
        },
        {
          path: ApplicationPaths.SETTINGS_ACCOUNT,
          icon: IconCommonNames.SETTINGS,
          label: intl.formatMessage({ id: 'Settings.account_settings' }),
          isVisibleToUserB2B: true,
          isVisibleToUserB2CStarter: true,
          isVisibleToUserB2CPremium: true,
          isVisibleToSettingsPage: true,
        },
        {
          path: ApplicationPaths.SETTINGS_PLANS,
          label: intl.formatMessage({ id: 'Settings.account_plans' }),
          icon: IconCommonNames.GLOBE,
          isVisibleToUserB2B: true,
          isVisibleToUserB2CStarter: true,
          isVisibleToUserB2CPremium: true,
          isVisibleToSettingsPage: true,
        },
        /**
         * Placeholder for "CREDITS"
         */
        {
          path: ApplicationPaths.SETTINGS_BILLING,
          icon: IconCommonNames.CREDIT_CARD,
          label: intl.formatMessage({ id: 'Settings.billing' }),
          isVisibleToUserB2B: false,
          isVisibleToUserB2CStarter: true,
          isVisibleToUserB2CPremium: true,
          isVisibleToSettingsPage: true,
        },
        {
          path: ApplicationPaths.SETTINGS_PREFERENCES,
          icon: IconCommonNames.MENU,
          label: intl.formatMessage({ id: 'Settings.preferences' }),
          isVisibleToUserB2B: true,
          isVisibleToUserB2CStarter: true,
          isVisibleToUserB2CPremium: true,
          isVisibleToSettingsPage: true,
        },
        /**
         * Placeholder for "HELP AND SUPPORT"
         */
      ].map((element) => ({ ...element, isCurrentPath: currentPath === element.path })),
    [intl, currentPath, isTrialAvailable, hasCategories],
  );

  return useMemo(() => {
    if (isUserB2B) {
      return navElementList.filter(({ isVisibleToUserB2B }) => isVisibleToUserB2B);
    }

    if (isUserPremium) {
      return navElementList.filter(({ isVisibleToUserB2CPremium }) => isVisibleToUserB2CPremium);
    }

    if (isUserStarter) {
      return navElementList.filter(({ isVisibleToUserB2CStarter }) => isVisibleToUserB2CStarter);
    }

    return [];
  }, [isUserB2B, isUserPremium, isUserStarter, navElementList]);
};

export default useNavElements;
