import classNames from 'classnames';
import React from 'react';

import { FlagRound } from 'components/Flag';

import { DropdownCountryOptionProps } from '../../types';
import DropdownCommonOption from '../DropdownCommonOption';

import styles from './DropdownCountryOption.module.scss';

const DropdownCountryOption: React.FC<DropdownCountryOptionProps> = ({
  code,
  phoneCode,
  name,
  onSelect,
  className = '',
}) => (
  <DropdownCommonOption
    className={classNames(styles.root, className)}
    onClick={() => onSelect(code)}
  >
    <div className={styles.leftCol}>
      <FlagRound
        className={styles.flag}
        country={code}
      />
      {name}
    </div>
    {`+${phoneCode}`}
  </DropdownCommonOption>
);

export default DropdownCountryOption;
