import classNames from 'classnames';
import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast, showWarningToast } from '@onoff/toast-notification';

import { isString } from 'helpers';

import { MESSAGES } from '@constants';
import { useWebAppSelector, selectCategoriesByCanSendMessages, selectCategoriesByActiveLandlineNumbers } from '@redux';
import { IconButton, IconCommonNames } from 'components/Icon';
import { useUserCapabilities, useCalculateRows, useCategoriesCapabilities } from 'hooks';

import { isSMSValueAnImage, isSMSValueAnAudio } from '../../helpers';
import { SMSAttachmentTypes, SMSCoreProps } from '../../types';
import SMSButtonAttachment from '../SMSButtonAttachment';
import SMSButtonSend from '../SMSButtonSend';
import SMSButtonSendMultiCategory from '../SMSButtonSendMultiCategory';

import styles from './SMSCore.module.scss';

const SMSCore: React.FC<SMSCoreProps> = ({
  activeThreadCategory,
  className = '',
  hasFocus = false,
  isNewThread = false,
  isDisabled = false,
  isSending = false,
  onBlur = () => undefined,
  onChange = () => undefined,
  onChangeCategory = () => undefined,
  onFocus = () => undefined,
  onSend = () => undefined,
  value = '',
}) => {
  const intl = useIntl();
  const categoryListByActiveLandlineNumbers = useWebAppSelector(selectCategoriesByActiveLandlineNumbers);
  const categoryListByCanSendMessages = useWebAppSelector(selectCategoriesByCanSendMessages);
  const { calculateRows, rows } = useCalculateRows(5);
  const { isUserB2B } = useUserCapabilities();
  const { id: selectedCategoryId = '' } = activeThreadCategory || {};
  const { canCategorySendMMS } = useCategoriesCapabilities(selectedCategoryId);

  const refTextarea = useRef<HTMLTextAreaElement>(null);
  const refInputFile = useRef<HTMLInputElement>(null);
  const [isCategoryPopUpOpen, setIsCategoryPopUpOpen] = useState<boolean>(false);
  const isValueFile = !isString(value);
  const isValueFileAnImage = isSMSValueAnImage(value);
  const isValueFileAnAudio = isSMSValueAnAudio(value);

  const onClickHandlerSelectFile = useCallback((): void => {
    if (canCategorySendMMS) {
      refInputFile.current?.click();
    } else if (selectedCategoryId.length > 0) {
      showWarningToast({
        heading: intl.formatMessage({ id: 'Messages.Notifications.title_mms_not_supported' }),
        message: intl.formatMessage({ id: 'Messages.Notifications.description_mms_not_supported' }),
      });
    }
  }, [canCategorySendMMS, selectedCategoryId, intl]);

  const onChangeHandlerAddFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files || [];

    if (files.length > 0) {
      onChange(files[0]);
      onFocus();
    }
  };

  const onClickHandlerRemoveFile = useCallback((): void => {
    if (refInputFile.current && !isSending) {
      refInputFile.current.value = '';
      onChange('');
      onFocus();
    }
  }, [isSending, onChange, onFocus]);

  const onChangeHandlerTextarea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  const onSendHandler = () => {
    const theMessage = isValueFile ? value : value.trim();

    if (activeThreadCategory) {
      onSend(theMessage, activeThreadCategory);
    } else if (categoryListByCanSendMessages.length === 1) {
      onSend(theMessage, categoryListByCanSendMessages[0]);
    } else if (categoryListByCanSendMessages.length > 1) {
      setIsCategoryPopUpOpen(true);
    } else if (categoryListByActiveLandlineNumbers.length > 0) {
      showWarningToast({
        heading: intl.formatMessage({ id: 'Notifications.LandlineNumbers.title' }),
        message: intl.formatMessage({ id: 'Notifications.LandlineNumbers.description' }),
      });
    } else {
      showErrorToast({
        heading: intl.formatMessage({ id: 'Notifications.Toast.title_error' }),
        message: intl.formatMessage({ id: 'Messages.no_active_category' }),
      });
    }
  };

  const onKeyDownHandlerTextarea = (event: React.KeyboardEvent) => {
    const { key, shiftKey } = event;

    switch (key) {
      case 'Enter':
        if (shiftKey) {
          return;
        }

        event.preventDefault();
        if (!isDisabled && !isSending) {
          onSendHandler();
        }
        break;
      default:
    }
  };

  const callbackInputFocusHandler = (): void => {
    if (hasFocus) {
      refTextarea.current?.focus();
    } else {
      refTextarea.current?.blur();
    }
  };

  const callbackValueChangedForTextareaHandler = () => {
    if (refTextarea.current !== null) {
      const target = refTextarea.current;
      target.classList.remove(styles.heightAuto);
      target.classList.add(styles.heightInitial);
      calculateRows(target, value !== '');
      target.classList.remove(styles.heightInitial);
      target.classList.add(styles.heightAuto);
    }
  };

  const callbackValueChangedForFileHandler = (): void => {
    if (refInputFile.current && value === '') {
      refInputFile.current.value = '';
    }
  };

  useEffect(callbackInputFocusHandler, [hasFocus]);

  useEffect(callbackValueChangedForTextareaHandler, [calculateRows, value]);

  useEffect(callbackValueChangedForFileHandler, [value]);

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.isMultiline]: rows > 1 || isValueFile,
      })}
    >
      <div className={styles.colAttachments}>
        <div className={styles.attachments}>
          <input
            ref={refInputFile}
            type="file"
            accept={MESSAGES.MMS_ACCEPTED_FILE_FORMATS}
            onChange={onChangeHandlerAddFile}
            disabled={isSending}
            hidden
          />
          <IconButton
            iconName={IconCommonNames.PICTURE}
            className={classNames(styles.attachmentsButton, {
              [styles.isB2B]: isUserB2B,
              [styles.isNotSupported]: !canCategorySendMMS,
              [styles.isNotAvailable]: selectedCategoryId.length === 0,
            })}
            isDisabled={isSending}
            onClick={onClickHandlerSelectFile}
          />
        </div>
      </div>

      <div className={styles.colContent}>
        {isValueFile ? (
          <div className={styles.previewArea}>
            {isValueFileAnImage || isValueFileAnAudio ? (
              <SMSButtonAttachment
                attachmentType={isValueFileAnImage ? SMSAttachmentTypes.IMAGE : SMSAttachmentTypes.AUDIO}
                fileName={value.name}
                isDisabled={isSending}
                onClickToRemove={onClickHandlerRemoveFile}
              />
            ) : null}
          </div>
        ) : (
          <textarea
            className={styles.textarea}
            placeholder={intl.formatMessage({ id: 'MessageForm.ph_send_message' })}
            onBlur={onBlur}
            onChange={onChangeHandlerTextarea}
            onFocus={onFocus}
            onKeyDown={onKeyDownHandlerTextarea}
            ref={refTextarea}
            rows={isString(value) && value.length === 0 ? 1 : rows}
            value={isString(value) ? value : ''}
            disabled={isSending}
          />
        )}
      </div>

      <div className={styles.colSend}>
        {isNewThread && categoryListByCanSendMessages.length > 1 ? (
          <SMSButtonSendMultiCategory
            isUserB2B={isUserB2B}
            categoryList={categoryListByCanSendMessages}
            currentCategory={activeThreadCategory}
            defaultCategory={categoryListByCanSendMessages[0]}
            isDisabled={isDisabled}
            isSending={isSending}
            isSelectCategoryPopUpOpen={isCategoryPopUpOpen}
            className={styles.sendButtonMulti}
            onSelectCategory={onChangeCategory}
            onSend={onSendHandler}
            setIsSelectCategoryPopUpOpen={setIsCategoryPopUpOpen}
          />
        ) : (
          <SMSButtonSend
            isUserB2B={isUserB2B}
            isDisabled={isDisabled}
            isSending={isSending}
            className={styles.sendButton}
            onSend={onSendHandler}
          />
        )}
      </div>
    </div>
  );
};

export default SMSCore;
