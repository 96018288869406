import React from 'react';

import { StyleableComponent } from 'types';

const PhonePlay: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2291 15.2598L16.6891 14.9698C16.0791 14.8998 15.4791 15.1098 15.0491 15.5398L13.2091 17.3798C10.3791 15.9398 8.05906 13.6298 6.61906 10.7898L8.46906 8.93976C8.89906 8.50977 9.10906 7.90977 9.03906 7.29977L8.74906 4.77977C8.62906 3.76977 7.77906 3.00977 6.75906 3.00977H5.02906C3.89906 3.00977 2.95906 3.94977 3.02906 5.07977C3.55906 13.6198 10.3891 20.4398 18.9191 20.9698C20.0491 21.0398 20.9891 20.0998 20.9891 18.9698V17.2398C20.9991 16.2298 20.2391 15.3798 19.2291 15.2598Z"
      fill="currentColor"
    />
    <path
      d="M15 3.56701V9.43299C15 9.8803 15.5145 10.1521 15.9108 9.90861L20.725 6.97562C21.0917 6.7548 21.0917 6.2452 20.725 6.01872L15.9108 3.09139C15.5145 2.84792 15 3.1197 15 3.56701Z"
      fill="currentColor"
    />
  </svg>
);

export default PhonePlay;
