import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SIPCallsListItemUIDefaultFooterDetailContactItemProps } from '../../types';

import styles from './SIPCallsListItemUIDefaultFooterDetailContactItem.module.scss';

const SIPCallsListItemUIDefaultFooterDetailContactItem: React.FC<
  SIPCallsListItemUIDefaultFooterDetailContactItemProps
> = ({ label, value = '', valueColor = undefined }) => {
  const hasValue = value.length > 0;
  return (
    <div className={styles.root}>
      <div className={styles.label}>{label}</div>
      <div
        style={{ color: valueColor }}
        className={classNames(styles.value, {
          [styles.empty]: !hasValue,
        })}
      >
        {hasValue ? value : <FormattedMessage id={'SIP.Calls.UI.Contacts.value_empty'} />}
      </div>
    </div>
  );
};

export default SIPCallsListItemUIDefaultFooterDetailContactItem;
