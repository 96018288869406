import {
  AppProductType,
  AppStore,
  CategoryPlanStatusTypes,
  PremiumPlanPriceConfig,
  ProductPriceConfig,
  RenewalPeriod,
  SubscriptionStatus,
  UserOfferInfo,
} from 'types';

import { QUERY_STRINGS, SESSIONSTORAGE_KEYS } from '@constants';

import { getQueryStringParams } from '../queryString';

import {
  IsOfferAutoRenewableProps,
  IsOfferOrCategoryPossibleToChangePeriodProps,
  IsPlanAutoRenewableProps,
} from './types';

export const sessionStoragePersistenceSetSelectedPremiumPlan = (plan: PremiumPlanPriceConfig) => {
  window.sessionStorage.setItem(SESSIONSTORAGE_KEYS.SELECTED_PREMIUM_PLAN, JSON.stringify(plan));
};

export const sessionStoragePersistenceGetSelectedPremiumPlan = (): PremiumPlanPriceConfig | undefined => {
  const planInStorage = window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.SELECTED_PREMIUM_PLAN);

  return planInStorage ? JSON.parse(planInStorage) : undefined;
};

export const sessionStoragePersistenceRemoveSelectedPremiumPlan = () => {
  window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.SELECTED_PREMIUM_PLAN);
};

export const getPremiumOffersConfig = (offers: UserOfferInfo[] = []) =>
  offers.filter((offer) => offer.appProductType === AppProductType.PREMIUM);

export const getProductPricesConfigWithOffers = (
  productPrices: ProductPriceConfig[] = [],
  offers: UserOfferInfo[] = [],
): PremiumPlanPriceConfig[] =>
  productPrices.map((productPrice) => {
    const associatedOffer = offers.find((offer) => offer.offerProductId === productPrice.productId);

    if (!associatedOffer) {
      throw new Error(
        `Dev Error: ProductPrices and Offers doesn't match: No matching offer found for productId: ${productPrice.productId}`,
      );
    }

    const { offerProductId: _offerProductId, ...offer } = associatedOffer;
    const { month } = offer;

    const monthlyPrice = productPrice.price / month;
    const yearlyPrice = (productPrice.price / month) * 12;

    return {
      ...offer,
      ...productPrice,
      month: month as RenewalPeriod,
      monthlyPrice,
      yearlyPrice,
    };
  });

export const isOfferAutoRenewable = ({ offer }: IsOfferAutoRenewableProps): boolean =>
  offer.status === SubscriptionStatus.ACTIVE && !!offer.planDuration && !!offer.subscriptionId;

export const isPlanAutoRenewable = ({ category, categoryStatistics }: IsPlanAutoRenewableProps): boolean =>
  categoryStatistics.planStatus === CategoryPlanStatusTypes.SUBSCRIPTION_ACTIVE &&
  !!categoryStatistics.planDuration &&
  !!category.subscriptionId;

export const isOfferOrCategoryPossibleToChangeDuration = ({
  product,
}: IsOfferOrCategoryPossibleToChangePeriodProps): boolean => product.source === AppStore.CREDIT_CARD;

export const isQuickRenewalPeriodChangeFlow = (): boolean =>
  getQueryStringParams().has(QUERY_STRINGS.SUBSCRIPTION_UPDATE);

export const determineSelectedPremiumPlan = (
  priceConfig: PremiumPlanPriceConfig[],
  currentSelectedPlan: PremiumPlanPriceConfig,
) => {
  const planInStorage = sessionStoragePersistenceGetSelectedPremiumPlan();

  // If there is storage data and it matches with priceConfig data
  if (planInStorage) {
    const storedPlan = priceConfig.find((premiumPlan) => premiumPlan.productId === planInStorage.productId);

    if (storedPlan) {
      return storedPlan;
    }
  }

  const trialAvailableAndMatchingMonthConfig = priceConfig.find(
    (premiumPlan) => premiumPlan.trialAvailable && premiumPlan.month === currentSelectedPlan.month,
  );

  if (trialAvailableAndMatchingMonthConfig) {
    return trialAvailableAndMatchingMonthConfig;
  }

  const trialAvailableConfig = priceConfig.find((premiumPlan) => premiumPlan.trialAvailable);

  if (trialAvailableConfig) {
    return trialAvailableConfig;
  }

  const matchingMonthConfig = priceConfig.find((premiumPlan) => premiumPlan.month === currentSelectedPlan.month);

  if (matchingMonthConfig) {
    return matchingMonthConfig;
  }

  // Default to the first available premium plan
  return priceConfig.find((premiumPlan) => premiumPlan.appProductType === AppProductType.PREMIUM);
};
