import { SESSIONSTORAGE_KEYS, LOCALSTORAGE_KEYS } from '@constants';

import { multiDeviceGenerateToken } from '../multiDevice';

export const browserTabsInitializeMultiTabManagement = (): void => {
  const instanceIdNew = multiDeviceGenerateToken();
  window.localStorage.setItem(LOCALSTORAGE_KEYS.BROWSER_TABS_ACTIVE_ID, instanceIdNew);
  window.sessionStorage.setItem(SESSIONSTORAGE_KEYS.BROWSER_TABS_CURRENT_ID, instanceIdNew);
};

export const browserTabsGetActiveBrowserTabId = () =>
  window.localStorage.getItem(LOCALSTORAGE_KEYS.BROWSER_TABS_ACTIVE_ID) || '';

export const browserTabsGetCurrentBrowserTabId = () =>
  window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.BROWSER_TABS_CURRENT_ID) || '';

export const browserTabsIsCurrentBrowserTabActive = () => {
  const activeBrowserTabId = browserTabsGetActiveBrowserTabId();
  const currentBrowserTabId = browserTabsGetCurrentBrowserTabId();
  return activeBrowserTabId === currentBrowserTabId;
};
