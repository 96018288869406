import React from 'react';

import { Alert, AlertColorScheme, AlertVariant } from '@onoff/ui';

import styles from './ErrorMessage.module.scss';

type ErrorMessageProps = {
  children: React.ReactNode;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => (
  <Alert
    variant={AlertVariant.TRANSLUCENT}
    colorScheme={AlertColorScheme.DANGER}
    className={styles.root}
  >
    {children}
  </Alert>
);
