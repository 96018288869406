import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PurchaseReceiptsListResponseReceipt } from 'types';

import { Modal, ModalBody, ModalHeader } from 'components/Modal';

import ModalsReceiptsListMobileList from '../ModalsReceiptsListMobileList';

import styles from './ModalsReceiptsListMobileCore.module.scss';

export interface ModalsReceiptsListMobileCoreProps {
  title: string;
  isOpen: boolean;
  receipts: PurchaseReceiptsListResponseReceipt[];
  onRequestClose: () => void;
}

const ModalsReceiptsListMobileCore: React.FC<ModalsReceiptsListMobileCoreProps> = ({
  title,
  isOpen,
  receipts,
  onRequestClose,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
  >
    <ModalHeader title={title} />
    <ModalBody>
      {receipts.length === 0 ? (
        <div className={styles.noDataInfo}>
          <FormattedMessage id="Settings.Receipts.no_receipts_info" />
        </div>
      ) : (
        <ModalsReceiptsListMobileList receipts={receipts} />
      )}
    </ModalBody>
  </Modal>
);

export default ModalsReceiptsListMobileCore;
