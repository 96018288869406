import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { SideMenuStyle as s1 } from 'components/UI/styleRoutes';
import sha1 from 'helpers/sha1';

import * as categoriesActions from '@redux/actions/categories';

class StealthLock extends Component {
  static contextTypes = {
    serverData: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.refInputPassword = React.createRef();
    this.handleEnter = this.handleEnter.bind(this);
    this.requestUnlock = this.requestUnlock.bind(this);
  }

  handleEnter(e) {
    e.preventDefault();

    const inputPassword = this.refInputPassword.current;
    const categories = this.props.categories.data;
    const ids = Object.keys(categories);

    if (ids.length && inputPassword) {
      ids.map((id) => {
        const category = categories[id];
        if (
          !category.isVisible &&
          /*
           *  "/mobile/v3/get-categories" returns "privatePass" but "/mobile/switch-category-privacy" returns "privatePassword"
           */
          [category.privatePass, category.privatePassword].includes(sha1.hash(inputPassword.value))
        ) {
          this.requestUnlock(id);
        }
      });
    }
  }

  async requestUnlock(categoryId) {
    await this.props.categoriesActions.switchCategoryVisibility({
      categoryId,
      switchTo: true,
    });

    const inputPassword = this.refInputPassword.current;
    if (inputPassword) {
      inputPassword.value = '';
    }
  }

  render() {
    const text = window.serverData.text.SideMenu;

    return (
      <div
        className={s1['stealth-box-container']}
        id="stealth-box"
      >
        <div className={s1['stealth-box']}>
          <div className={s1.header}>
            <div className={s1.l1}>{text.stealth_title}</div>
            <div className={s1.l2}>{text.stealth_enter_pin}</div>
          </div>

          <form
            data-form-type="other"
            className="form-group-newui"
            onSubmit={this.handleEnter}
          >
            <input
              ref={this.refInputPassword}
              type="password"
              maxLength="4"
              className="form-control-newui full"
              autoComplete="off"
              data-lpignore="true"
              data-form-type="other"
            />
            <button
              className={`${s1.send} material-icons`}
              type="submit"
            >
              keyboard_arrow_right
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  categoriesActions: bindActionCreators(categoriesActions, dispatch),
});

const mapStateToProps = (state) => ({
  categories: state.categories,
});

export default connect(mapStateToProps, mapDispatchToProps)(StealthLock);
