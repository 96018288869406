import { CallQualitySurveyService } from 'types';

import { apiMobile } from 'api';

const callQualitySurveyService: CallQualitySurveyService = {
  getSurveyData: () => apiMobile.get('/call-quality-survey/start', {}),
  submitSurvey: (args) => apiMobile.post('/call-quality-survey/submit', args),
};

export default callQualitySurveyService;
