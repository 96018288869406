import React from 'react';

import { Category } from 'types';

import CategorySelectPopUpItem from './CategorySelectPopUpItem';
import CategorySelectPopUpList from './CategorySelectPopUpList';

import styles from './CategorySelectPopUp.module.scss';

interface CategorySelectPopUpProps
  extends React.RefAttributes<HTMLDivElement>,
    Pick<React.HtmlHTMLAttributes<HTMLDivElement>, 'style'> {
  categoryList?: Array<Category>;
  categoryListTitle?: string;
  defaultCategory?: Category | null;
  defaultCategoryTitle?: string;
  onSelectCategory?: (category: Category) => void;
}

const CategorySelectPopUp = React.forwardRef<HTMLDivElement, CategorySelectPopUpProps>(
  (
    { categoryList = [], categoryListTitle = '', defaultCategory, defaultCategoryTitle = '', onSelectCategory, style },
    ref,
  ) => (
    <div
      style={style}
      ref={ref}
      className={styles.root}
    >
      {defaultCategory && (
        <CategorySelectPopUpList title={defaultCategoryTitle}>
          <CategorySelectPopUpItem
            category={defaultCategory}
            onSelect={onSelectCategory}
          />
        </CategorySelectPopUpList>
      )}
      <CategorySelectPopUpList title={categoryListTitle}>
        {categoryList.map((category) => (
          <CategorySelectPopUpItem
            key={category.id}
            category={category}
            onSelect={onSelectCategory}
          />
        ))}
      </CategorySelectPopUpList>
    </div>
  ),
);

export default CategorySelectPopUp;
