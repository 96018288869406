import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  useWebAppDispatch,
  useWebAppSelector,
  integrationsExportContact,
  integrationsResetExportContactStatuses,
  selectContactsActiveId,
  selectIntegrationsActiveIntegrations,
  selectIntegrationsExportContactStatuses,
} from '@redux';
import { Modal, ModalBody, ModalHeader, ModalSize } from 'components/Modal';

import { isAtLeastOneStatusLoading } from '../../helpers';
import ModalsExportContactDisclaimer from '../ModalsExportContactDisclaimer';
import ModalsExportContactListItem from '../ModalsExportContactListItem';

import styles from './ModalsExportContact.module.scss';

export interface IntegrationCRMExportContactModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalsExportContact: React.FC<IntegrationCRMExportContactModalProps> = ({ isOpen, onClose }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const activeIntegrations = useWebAppSelector(selectIntegrationsActiveIntegrations);
  const exportContactStatuses = useWebAppSelector(selectIntegrationsExportContactStatuses);
  const activeContactId = useWebAppSelector(selectContactsActiveId);
  const closingDisabled = isAtLeastOneStatusLoading(exportContactStatuses);
  const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);

  const onDisclaimerButtonClickHandler = () => {
    setIsDisclaimerVisible(!isDisclaimerVisible);
  };

  const onDisclaimerBackButtonClickHandler = () => {
    setIsDisclaimerVisible(false);
  };

  const onExportOrRetryClick = (integrationId: string) => {
    dispatch(integrationsExportContact(integrationId, activeContactId));
  };

  const requestClose = () => {
    if (!closingDisabled) {
      onClose();
    }
  };

  const handleCloseAnimationEnd = () => {
    dispatch(integrationsResetExportContactStatuses());
  };

  return (
    <Modal
      isOpen={isOpen}
      size={ModalSize.L}
      onRequestClose={requestClose}
      onCloseAnimationEnd={handleCloseAnimationEnd}
    >
      {!isDisclaimerVisible ? (
        <>
          <ModalHeader
            title={intl.formatMessage({ id: 'Integrations.ExportContactModal.title' })}
            onCloseButtonClick={requestClose}
            isCloseButtonDisabled={closingDisabled}
          />
          <ModalBody>
            <p className={styles.contentDescription}>
              <FormattedMessage
                id={'Integrations.ExportContactModal.description'}
                values={{
                  button: (
                    <button
                      className={styles.contentDisclaimerButton}
                      onClick={onDisclaimerButtonClickHandler}
                    >
                      <FormattedMessage id="Integrations.ExportContactModal.description_button_title" />
                    </button>
                  ),
                }}
              />
            </p>
            <ul className={styles.contentIntegrationsList}>
              {activeIntegrations.map((integration) => {
                const { id } = integration;
                const status = exportContactStatuses[id];

                if (status === undefined) {
                  return null;
                }

                return (
                  <ModalsExportContactListItem
                    key={id}
                    integration={integration}
                    status={status}
                    onExportOrRetryClick={() => onExportOrRetryClick(id)}
                  />
                );
              })}
            </ul>
          </ModalBody>
        </>
      ) : (
        <ModalsExportContactDisclaimer onBackButtonHandler={onDisclaimerBackButtonClickHandler} />
      )}
    </Modal>
  );
};

export default ModalsExportContact;
