import classNames from 'classnames';
import React from 'react';

import { Close as CloseIcon } from '@onoff/icons';

import { ButtonCloseProps } from '../../types';

import styles from './ButtonClose.module.scss';

const ButtonClose: React.FC<ButtonCloseProps> = ({ className, onClick }) => (
  <button
    type={'button'}
    className={classNames(styles.root, className)}
    onClick={onClick}
  >
    <CloseIcon />
  </button>
);

export default ButtonClose;
