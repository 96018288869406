import React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatSimpleDate } from 'helpers';

import styles from './CategorySubscriptionEndInfo.module.scss';

interface CategorySubscriptionEndInfoProps {
  expirationDate: string;
}

const CategorySubscriptionEndInfo: React.FC<CategorySubscriptionEndInfoProps> = ({ expirationDate }) => (
  <div className={styles.root}>
    <FormattedMessage
      id={'Categories.unsubscribe_end_info'}
      values={{ date: formatSimpleDate(expirationDate) }}
    />
  </div>
);

export default CategorySubscriptionEndInfo;
