import UserAgentParser from 'ua-parser-js';

export const isNumeric = (n: string): boolean => !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

/**
 * Alphabetical sort
 * @param {array} data This is an Array of Objects
 * @param {string} [sortKey=id] Name of the key (in Objects) used for sorting
 * @param {boolean} sortOrder TRUE - ascending, FALSE - descending
 * @return {array} Sorted array
 */

const alphaSortByKeyCallback = (a: string, b: string, sortOrder: boolean): number => {
  const alphabeticRegexp = /[a-zA-Z]/;

  const stringAMatchIndex: number = a.charAt(0).search(alphabeticRegexp);
  const stringBMatchIndex: number = b.charAt(0).search(alphabeticRegexp);

  // first does not match, second matches
  if (stringAMatchIndex < stringBMatchIndex) {
    return sortOrder ? -1 : 1;
  }

  // first matches, second does not match
  if (stringAMatchIndex > stringBMatchIndex) {
    return sortOrder ? 1 : -1;
  }

  return sortOrder ? b.localeCompare(a) : a.localeCompare(b);
};

export const alphaSortByKey = <T = Record<string, unknown>>(data: T[], sortKey: keyof T, sortOrder: boolean): T[] => {
  const result = data ? [...data] : [];

  result.sort((a, b) => {
    if (!a[sortKey] || !b[sortKey]) {
      return 0;
    }
    const stringA = a[sortKey];
    const stringB = b[sortKey];

    if (typeof stringA !== 'string' || typeof stringB !== 'string') {
      throw new Error('key for should be string');
    }

    return alphaSortByKeyCallback(stringA, stringB, sortOrder);
  });

  return result;
};

export const isPhoneNumber = (str: string): boolean => str === str.match(/\+?\s?(\d\s?)+/g)?.[0];

export const isValidEmail = (email: string): boolean => {
  const re = /^(([\w-/=$!%\\]+(\.?[\w-+])*)|(".+"))@(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,})$/;
  return re.test(String(email).toLowerCase());
};

export const removeWhiteSpaces = (value = ''): string => value.replace(/\s/g, '');

export const normalizeString = (value: string, noSpaces = false): string => {
  const normalized = value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
  return noSpaces ? removeWhiteSpaces(normalized) : normalized;
};

export const removeNewLines = (value = ''): string => value.replace(/(\r\n|\n|\r)/gm, '');

export const removePlusses = (value = ''): string => value.replace(/\+/g, '');

export const escapeRegExp = (value = ''): string => value.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');

export const getUserAgentData = (): UserAgentParser.IResult => {
  if (!('navigator' in window)) {
    return UserAgentParser(undefined);
  }
  return UserAgentParser(window.navigator.userAgent);
};

export const getUserAgentDataBrowserName = (): string => getUserAgentData()?.browser?.name || '';

export const isB2BProject = (): boolean => process.env.REACT_APP_PROJECT === 'b2b';

export const clampNumber = ({ value, min, max }: { value: number; min: number; max: number }): number =>
  Math.min(Math.max(min, value), max);

export async function sleep(delay = 1000): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

export const startsWithHttpOrHttps = (url = ''): boolean => /(?:https?:)+\/\//i.test(url);
export const containsCallRecordings = (url = ''): boolean => /call-recordings/i.test(url);

export const getDurationThreshold = (startMs: number, endMs: number) => {
  const durationSecs = (endMs - startMs) / 1000;

  if (durationSecs <= 2) {
    return '<= 2 secs';
  }

  if (durationSecs <= 5) {
    return '<= 5 secs';
  }

  if (durationSecs <= 10) {
    return '<= 10 secs';
  }

  return '10+ secs';
};

// TODO: Use big.js to calculate floats
export const naiveRound = (number: number, decimals = 2) => parseFloat(number.toFixed(decimals));
