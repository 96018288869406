import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast } from '@onoff/toast-notification';

import {
  useWebAppDispatch,
  useWebAppSelector,
  sipSetDataCallStatusWebRTCByWebRTCIdHandler,
  selectSIPDataCallByStatusWebRTCAnswer,
  selectSIPDataStunRecords,
} from '@redux';
import { SIPStatisticsScopes, SIPDataCallStatusesWebRTC } from '@redux/types';

import { sipDebugConsoleLogger } from '../../../../../../../../helpers';
import { JsSIPMediaConstraints } from '../../../../../../../../types';
import { useSIPMediaConstraints } from '../../../../../../../useSIPMedia';
import { useSIPStatistics } from '../../../../../../../useSIPStatistics';

import { UseWebRTCSessionsAnswerProps } from './types';

export const useWebRTCSessionsAnswer = ({ propWebRTCSessions }: UseWebRTCSessionsAnswerProps): void => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { idWebRTCForAnswer, idTransactionForAnswer, originatorForAnswer } = useWebAppSelector(
    selectSIPDataCallByStatusWebRTCAnswer,
  );
  const { dataSIPStunRecords } = useWebAppSelector(selectSIPDataStunRecords);

  const { constraints } = useSIPMediaConstraints();
  const { sipSendErrorToSentry, sipSendErrorToGTM } = useSIPStatistics();

  const propWebRTCSessionForAnswer = propWebRTCSessions.find(({ id }) => id === idWebRTCForAnswer);

  const callbackWebRTCSessionsAnswerHandler = (): void => {
    if (
      dataSIPStunRecords.length > 0 &&
      idWebRTCForAnswer &&
      idTransactionForAnswer &&
      originatorForAnswer &&
      propWebRTCSessionForAnswer
    ) {
      try {
        propWebRTCSessionForAnswer.answer({
          mediaConstraints: constraints as JsSIPMediaConstraints,
          pcConfig: { iceServers: [{ urls: dataSIPStunRecords }] },
        });

        dispatch(
          sipSetDataCallStatusWebRTCByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForAnswer,
            propCallStatusWebRTC: SIPDataCallStatusesWebRTC.ANSWERED,
          }),
        );
      } catch (error) {
        sipDebugConsoleLogger('SIP WEBRTC: CALL ANSWER IS FAILED!', { error });

        const errorMessage = error instanceof Error ? error.message : error;

        dispatch(
          sipSetDataCallStatusWebRTCByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForAnswer,
            propCallStatusWebRTC: SIPDataCallStatusesWebRTC.REJECT,
          }),
        );

        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_WEBRTC,
          error: `CALL ANSWER IS FAILED! "${errorMessage}"`,
        });

        sipSendErrorToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_WEBRTC,
          sipCallOriginator: originatorForAnswer,
          sipCallTitle: 'CALL ANSWER IS FAILED!',
          sipCallDescription: `${errorMessage}`,
          sipCallTransactionId: idTransactionForAnswer,
        });

        showErrorToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.CallAnswerFailure.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.CallAnswerFailure.description' }),
        });
      }
    }
  };

  useEffect(callbackWebRTCSessionsAnswerHandler, [
    dataSIPStunRecords,
    idWebRTCForAnswer,
    idTransactionForAnswer,
    originatorForAnswer,
    constraints,
    propWebRTCSessionForAnswer,
    intl,
    dispatch,
    sipSendErrorToSentry,
    sipSendErrorToGTM,
  ]);
};
