import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@onoff/ui';

import { Status } from 'types';

import { selectCallQualitySurveyStatuses, useWebAppSelector } from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';
import { useUserCapabilities } from 'hooks';

import styles from './ModalsCallQualitySurveyActions.module.scss';

type ModalsCallQualitySurveyActionsProps = {
  canSubmit: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const ModalsCallQualitySurveyActions: React.FC<ModalsCallQualitySurveyActionsProps> = ({
  onCancel,
  onSubmit,
  canSubmit,
}) => {
  const { isUserB2B } = useUserCapabilities();
  const { submitSurvey: submitSurveyStatus } = useWebAppSelector(selectCallQualitySurveyStatuses);
  const isSubmittingSurvey = submitSurveyStatus === Status.LOADING;

  return (
    <div className={styles.root}>
      <Button
        {...testGetTestingAttributes(testIds.modalCallQualitySurveySubmitButton)}
        onClick={onSubmit}
        colorScheme={isUserB2B ? 'brand-b2b' : 'brand-b2c'}
        size="large"
        disabled={!canSubmit}
        loading={isSubmittingSurvey}
      >
        <FormattedMessage id="ModalCallQualitySurvey.Actions.main" />
      </Button>
      <Button
        onClick={onCancel}
        variant="ghost"
        colorScheme="black"
        size="large"
      >
        <FormattedMessage id="ModalCallQualitySurvey.Actions.secondary" />
      </Button>
    </div>
  );
};

export default ModalsCallQualitySurveyActions;
