import classNames from 'classnames';
import React from 'react';

import { Radio } from '@onoff/ui';

import { ContactPhoneNumberCard } from 'types';

import { AvatarCommon } from 'components/Avatar';

import styles from './PhoneNumberCard.module.scss';

type PhoneNumberCardProps = Required<Pick<ContactPhoneNumberCard, 'avatarProps' | 'phoneNumber'>> &
  Partial<Pick<ContactPhoneNumberCard, 'contactFullName'>> & {
    isSelected: boolean;
    onSelect: (phoneNumber: ContactPhoneNumberCard['phoneNumber']) => void;
  };

export const PhoneNumberCard: React.FC<PhoneNumberCardProps> = ({
  avatarProps,
  contactFullName,
  phoneNumber,
  isSelected,
  onSelect,
}) => {
  const isContact = !!contactFullName;

  return (
    <Radio
      className={classNames(styles.root, { [styles.selected]: isSelected })}
      checked={isSelected}
      onChange={() => {
        onSelect(phoneNumber);
      }}
    >
      <div className={styles.content}>
        <AvatarCommon {...avatarProps} />
        <div className={styles.contact}>
          {isContact ? (
            <>
              <div className={styles.title}>{contactFullName}</div>
              <div className={styles.subtitle}>{phoneNumber}</div>
            </>
          ) : (
            <div className={styles.title}>{phoneNumber}</div>
          )}
        </div>
      </div>
    </Radio>
  );
};
