import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { InfoOutlined as IconInfoOutlined } from '@onoff/icons';
import { Button } from '@onoff/ui';

import { Category } from 'types';

import { calculateCategoryExpiredRemainingDays } from 'helpers/category';
import { CategoryColorsStatic } from 'helpers/category/colors';

import { useWebAppDispatch, useWebAppSelector, selectUserEmail, purchaseSetDataHandler } from '@redux';
import { useUserCapabilities } from 'hooks';
import { ApplicationPaths } from 'routes/types';

import CategoryCommonName from '../CategoryCommonName';
import CategoryCommonNumber from '../CategoryCommonNumber';
import CategoryMinimized from '../CategoryMinimized';

import styles from './CategoryExpired.module.scss';

export interface CategoryExpiredProps {
  category: Category;
  isMinimized: boolean;
}

const CategoryExpired: React.FC<CategoryExpiredProps> = ({ category, isMinimized }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useWebAppDispatch();
  const { isUserB2B } = useUserCapabilities();
  const userEmail = useWebAppSelector(selectUserEmail);

  const onClickHandlerButtonExpired = (): void => {
    dispatch(
      purchaseSetDataHandler({
        categoryId: category.id,
        virtualPhoneNumber: category.virtualPhoneNumber.number,
        email: userEmail,
      }),
    );
    navigate(ApplicationPaths.PURCHASE_PHONE_NUMBER_RENEWAL);
  };

  if (isMinimized) {
    return (
      <CategoryMinimized
        categoryName={category.name}
        categoryColor={CategoryColorsStatic.EXPIRED}
        isActive={category.active}
      />
    );
  }

  const days = calculateCategoryExpiredRemainingDays(category.virtualPhoneNumber?.expirationDate || '');

  return (
    <div
      className={styles.root}
      style={{
        background: CategoryColorsStatic.EXPIRED,
        boxShadow: `0 2px 6px ${CategoryColorsStatic.EXPIRED}`,
      }}
    >
      <div className={styles.head}>
        <CategoryCommonName
          categoryId={category.id}
          categoryName={category.name}
        />
        <CategoryCommonNumber number={category.virtualPhoneNumber?.number} />
        <span className={styles.badge}>
          <FormattedMessage id={'Categories.expired'} />
        </span>
      </div>

      <div className={styles.body}>
        <IconInfoOutlined className={styles.iconInfo} />
        <p className={styles.description}>
          {days > 0
            ? intl.formatMessage({ id: 'Categories.expired_description' }, { days })
            : intl.formatMessage({ id: 'Categories.expired_description_delete_soon' })}
        </p>
      </div>

      {!isUserB2B && (
        <div className={styles.footer}>
          <Button
            className={styles.buttonResubscribe}
            colorScheme="brand-b2c"
            variant="solid"
            onClick={onClickHandlerButtonExpired}
          >
            <FormattedMessage id={'Categories.expired_button_b2c'} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default CategoryExpired;
