import React from 'react';

import { StyleableComponent } from 'types';

const PhoneLoupe: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="225"
    height="225"
    viewBox="0 0 225 225"
    fill="none"
    className={className}
  >
    <g clipPath="url(#clip0_5653_584)">
      <path
        opacity="0.0279018"
        d="M225 112.5C225 50.368 174.632 0 112.5 0C50.368 0 0 50.368 0 112.5C0 174.632 50.368 225 112.5 225C174.632 225 225 174.632 225 112.5Z"
        fill="#273348"
      />
      <path
        opacity="0.0279018"
        d="M208 112.5C208 59.7566 165.243 16.9998 112.5 16.9998C59.7568 16.9998 17 59.7566 17 112.5C17 165.243 59.7568 208 112.5 208C165.243 208 208 165.243 208 112.5Z"
        fill="#273348"
      />
      <g filter="url(#filter0_d_5653_584)">
        <path
          d="M148.06 14.0002H79.3C72.3412 14.0002 66.7 19.6414 66.7 26.6002V199.04C66.7 205.999 72.3412 211.64 79.3 211.64H148.06C155.019 211.64 160.66 205.999 160.66 199.04V26.6002C160.66 19.6414 155.019 14.0002 148.06 14.0002Z"
          fill="#273348"
        />
      </g>
      <path
        d="M148.06 14.0002H79.3C72.3412 14.0002 66.7 19.6414 66.7 26.6002V199.04C66.7 205.999 72.3412 211.64 79.3 211.64H148.06C155.019 211.64 160.66 205.999 160.66 199.04V26.6002C160.66 19.6414 155.019 14.0002 148.06 14.0002Z"
        fill="white"
      />
      <g opacity="0.0501535">
        <g
          opacity="0.0501535"
          filter="url(#filter1_d_5653_584)"
        >
          <path
            d="M148.06 14.0002H79.3C72.3412 14.0002 66.7 19.6414 66.7 26.6002V199.04C66.7 205.999 72.3412 211.64 79.3 211.64H148.06C155.019 211.64 160.66 205.999 160.66 199.04V26.6002C160.66 19.6414 155.019 14.0002 148.06 14.0002Z"
            fill="#273348"
          />
        </g>
        <path
          opacity="0.0501535"
          d="M148.06 14.0002H79.3C72.3412 14.0002 66.7 19.6414 66.7 26.6002V199.04C66.7 205.999 72.3412 211.64 79.3 211.64H148.06C155.019 211.64 160.66 205.999 160.66 199.04V26.6002C160.66 19.6414 155.019 14.0002 148.06 14.0002Z"
          fill="#273348"
          stroke="white"
          strokeWidth="2.4"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.5701 14.8105H138.79V16.4305C138.79 20.0094 135.889 22.9105 132.31 22.9105H95.0501C91.4713 22.9105 88.5701 20.0094 88.5701 16.4305V14.8105Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.15 45.0502V61.2502C65.6589 61.2502 64.45 60.0414 64.45 58.5502V47.7502C64.45 46.259 65.6589 45.0502 67.15 45.0502Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.15 63.0502V79.2502C65.6589 79.2502 64.45 78.0414 64.45 76.5502V65.7502C64.45 64.259 65.6589 63.0502 67.15 63.0502Z"
        fill="white"
      />
      <path
        d="M128.26 203.18H99.0999C98.6526 203.18 98.2899 203.543 98.2899 203.99C98.2899 204.438 98.6526 204.8 99.0999 204.8H128.26C128.707 204.8 129.07 204.438 129.07 203.99C129.07 203.543 128.707 203.18 128.26 203.18Z"
        fill="#D8D8D8"
      />
      <path
        d="M94.0765 128.082L90.2845 124.901L84.7887 131.45L88.5806 134.632L94.0765 128.082Z"
        fill="#C8CBCE"
      />
      <path
        opacity="0.405001"
        d="M93.189 117.939C104.231 127.205 120.694 125.764 129.96 114.722C139.225 103.68 137.785 87.217 126.743 77.9515C115.7 68.6859 99.2376 70.1262 89.972 81.1685C80.7065 92.2107 82.1468 108.673 93.189 117.939Z"
        fill="white"
      />
      <path
        opacity="0.405001"
        d="M92.654 122.777C105.41 133.481 124.427 131.817 135.13 119.061C145.834 106.305 144.17 87.288 131.414 76.5847C118.658 65.8814 99.6411 67.5452 88.9378 80.3009C78.2345 93.0566 79.8983 112.074 92.654 122.777Z"
        fill="white"
      />
      <path
        opacity="0.524982"
        d="M87.428 92.2516C89.6174 94.0887 94.3708 92.0283 98.0451 87.6495C101.719 83.2707 102.923 78.2316 100.734 76.3945C98.5443 74.5574 93.7908 76.6178 90.1166 80.9966C86.4423 85.3755 85.2386 90.4145 87.428 92.2516Z"
        fill="white"
      />
      <path
        opacity="0.524982"
        d="M135.85 113.327C138.634 113.97 141.889 110.173 143.119 104.845C144.349 99.5177 143.088 94.6778 140.304 94.0348C137.519 93.3919 134.264 97.1895 133.034 102.517C131.804 107.844 133.065 112.684 135.85 113.327Z"
        fill="white"
      />
      <path
        d="M90.3161 132.564L86.5242 129.382C83.2877 126.666 78.4624 127.088 75.7466 130.325L50.003 161.005C47.2872 164.242 47.7094 169.067 50.9459 171.783L54.7378 174.964C57.9743 177.68 62.7996 177.258 65.5154 174.022L91.259 143.341C93.9748 140.105 93.5526 135.28 90.3161 132.564Z"
        fill="#D8D8D8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.147 74.0944C151.092 86.6348 153.042 108.916 140.501 123.861C127.961 138.806 105.679 140.756 90.7342 128.215C75.7891 115.675 73.8398 93.3935 86.3802 78.4484C98.9206 63.5033 121.202 61.5539 136.147 74.0944ZM133.255 77.5416C120.213 66.5986 100.77 68.2997 89.8274 81.341C78.8845 94.3822 80.5855 113.825 93.6268 124.768C106.668 135.711 126.111 134.01 137.054 120.969C147.997 107.928 146.296 88.4845 133.255 77.5416Z"
        fill="#C8CBCE"
      />
      <g filter="url(#filter2_d_5653_584)">
        <path
          d="M161.5 183C173.926 183 184 172.926 184 160.5C184 148.073 173.926 138 161.5 138C149.074 138 139 148.073 139 160.5C139 172.926 149.074 183 161.5 183Z"
          fill="#273348"
        />
      </g>
      <path
        d="M161.5 183C173.926 183 184 172.926 184 160.5C184 148.073 173.926 138 161.5 138C149.074 138 139 148.073 139 160.5C139 172.926 149.074 183 161.5 183Z"
        fill="currentColor"
      />
      <path
        d="M158.1 163.58L154.32 159.8L153.06 161.06L158.1 166.1L168.9 155.3L167.64 154.04L158.1 163.58Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5653_584"
        x="45.7"
        y="1.00018"
        width="135.96"
        height="239.64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="10.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.152941 0 0 0 0 0.2 0 0 0 0 0.282353 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5653_584"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5653_584"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_5653_584"
        x="45.7"
        y="1.00018"
        width="135.96"
        height="239.64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="10.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.152941 0 0 0 0 0.2 0 0 0 0 0.282353 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5653_584"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5653_584"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_5653_584"
        x="118"
        y="124"
        width="87"
        height="87"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="7" />
        <feGaussianBlur stdDeviation="10.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.152941 0 0 0 0 0.2 0 0 0 0 0.282353 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5653_584"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5653_584"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_5653_584">
        <rect
          width="225"
          height="225"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneLoupe;
