import { defineMessages } from 'react-intl';
import * as yup from 'yup';
import { BooleanSchema, DateSchema, NumberSchema, StringSchema } from 'yup';

import { getIntl } from '@intl';

type SchemaCallback<FormData> = (
  y: typeof yup,
) => Record<keyof FormData, StringSchema | NumberSchema | BooleanSchema | DateSchema>;

export const applyDefaultValidationSchema = <FormData>(schemaCallback: SchemaCallback<FormData>) => {
  const intl = getIntl();

  const messages = defineMessages({
    presence: {
      id: 'InputErrors.General.required',
    },
    minLength: {
      id: 'InputErrors.General.MinLength',
    },
    maxLength: {
      id: 'InputErrors.General.MaxLength',
    },
    email: {
      id: 'InputErrors.General.EmailInvalid',
    },
  });

  const inputNameId = (attribute: string): string => `InputErrors.InputName.${attribute}`;
  const inputName = (attribute: string): string => intl.formatMessage({ id: inputNameId(attribute) });

  yup.setLocale({
    mixed: {
      required: () => intl.formatMessage(messages.presence),
    },
    string: {
      email: intl.formatMessage(messages.email),
    },
    number: {
      min: ({ min, path = '' }) =>
        intl.formatMessage(messages.minLength, {
          inputName: inputName(path),
          length: min,
        }),
      max: ({ max, path = '' }) =>
        intl.formatMessage(messages.maxLength, {
          inputName: inputName(path),
          length: max,
        }),
    },
  });

  const callback = (cb: SchemaCallback<FormData>) => yup.object().shape(cb(yup));

  return callback(schemaCallback);
};
