import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { isB2BProject } from 'helpers';

import {
  selectCallLogsUnseenCount,
  selectSIPDataCallsRingtoneAvailability,
  selectThreadsUnseenCount,
  selectVoicemailsByVisibleUnseenCount,
  useWebAppSelector,
} from '@redux';
import { useFavicon, useIsPageVisible } from 'hooks';
import { ApplicationPaths } from 'routes';

import { alternateValues } from '../helpers';

const appLocationTranslationIds = {
  [ApplicationPaths.ROOT]: 'PageTitles.login',
  [ApplicationPaths.CALLS]: 'PageTitles.calls',
  [ApplicationPaths.SETTINGS_PLANS]: 'PageTitles.settings',
  [ApplicationPaths.SETTINGS_ACCOUNT]: 'PageTitles.settings',
  [ApplicationPaths.SETTINGS_PREFERENCES]: 'PageTitles.settings',
  [ApplicationPaths.SETTINGS_BILLING]: 'PageTitles.settings',
  [ApplicationPaths.MESSAGES]: 'PageTitles.messages',
  [ApplicationPaths.VOICEMAILS]: 'PageTitles.voicemails',
  [ApplicationPaths.CONTACTS]: 'PageTitles.contacts',
  [ApplicationPaths.PURCHASE_PHONE_NUMBER_RENEWAL]: 'PageTitles.purchase',
  [ApplicationPaths.PURCHASE_PREMIUM_UPGRADE]: 'PageTitles.premium_upgrade',
};

const appName = isB2BProject() ? 'Onoff Business' : 'Onoff';

export const useAppTitle = (): void => {
  const intl = useIntl();
  const { pathname = '' } = useLocation();
  const isPageVisible = useIsPageVisible();
  const { element: faviconElement, initialHref: initialFaviconHref } = useFavicon();
  const callIconHref = '/favicons/call.ico';
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const intervalDelay = 1000;

  const unseenCalls = useWebAppSelector(selectCallLogsUnseenCount);
  const unseenMessages = useWebAppSelector(selectThreadsUnseenCount);
  const unseenVoicemails = useWebAppSelector(selectVoicemailsByVisibleUnseenCount);
  const { canRingtonePlay: isRingtonePlaying } = useWebAppSelector(selectSIPDataCallsRingtoneAvailability);

  const totalUnseenEvents = unseenCalls + unseenMessages + unseenVoicemails;
  const appLocationTranslationId = appLocationTranslationIds[pathname];

  const unseenEventsText = totalUnseenEvents > 0 ? `(${totalUnseenEvents})` : '';
  const appLocationText = appLocationTranslationIds[pathname]
    ? `- ${intl.formatMessage({ id: appLocationTranslationId })}`
    : '';
  const incomingCallText = intl.formatMessage({ id: 'PageTitles.incoming_call' }).toUpperCase();

  const documentTitle = `${unseenEventsText} ${appName} ${appLocationText}`.trim();

  useEffect(() => {
    const restoreOriginalState = () => {
      faviconElement.href = initialFaviconHref;
      document.title = documentTitle;

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };

    document.title = documentTitle;

    if (!isPageVisible && isRingtonePlaying) {
      intervalRef.current = setInterval(() => {
        faviconElement.href = alternateValues(initialFaviconHref, callIconHref, faviconElement.href);

        document.title = alternateValues(documentTitle, incomingCallText, document.title);
      }, intervalDelay);
    } else {
      restoreOriginalState();
    }

    return () => {
      restoreOriginalState();
    };
  }, [documentTitle, faviconElement, incomingCallText, initialFaviconHref, isPageVisible, isRingtonePlaying]);
};
