import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useWebAppSelector, selectPushHasMissingPushes } from '@redux';
import Banner from 'components/Banner';

import styles from './InfoBannerPubNub.module.scss';

const InfoBannerPubNub: React.FC = () => {
  const { hasMissingPushes } = useWebAppSelector(selectPushHasMissingPushes);

  const onClickHandlerButtonReload = (): void => {
    window.location.reload();
  };

  if (hasMissingPushes === false) {
    return null;
  }

  return (
    <Banner colorScheme="destructive">
      <div>
        <FormattedMessage
          id="Layouts.InfoBar.pubnub_has_missing_pushes"
          values={{
            buttonText: (
              <button
                className={styles.link}
                onClick={onClickHandlerButtonReload}
              >
                <FormattedMessage id="Layouts.InfoBar.pubnub_has_missing_pushes_button_text" />
              </button>
            ),
          }}
        />
      </div>
    </Banner>
  );
};

export default InfoBannerPubNub;
