import {
  AssignSimpleContactsToCategoryRequest,
  HomePhoneBookSortOrderRequest,
  HomePhoneBookDisplayOrderRequest,
  ContactsService,
  DeleteContactRequest,
  SaveContactImageResponse,
  SaveSimpleContactRequest,
  ToggleContactIsBlockedRequest,
  ToggleContactIsFavoriteRequest,
  GetSimpleContactsNewRequest,
  SimpleContactsResponse,
  SaveSimpleContactResponse,
  DeleteContactImageRequest,
} from 'types';

import { apiMobile } from 'api';

const contactsService: ContactsService = {
  assignContactsToCategory: (categoryId, contactIds) =>
    apiMobile.post<void, AssignSimpleContactsToCategoryRequest>('/assign-simple-contacts-to-category', {
      categoryId,
      simpleContactIds: contactIds,
    }),

  deleteContact: (contactId) =>
    apiMobile.post<void, DeleteContactRequest>('/delete-simple-contact', {
      contactId,
    }),

  fetchAllContacts: async () => {
    let offset = 0;
    const limit = 500;
    return contactsService.fetchContacts(offset, limit).then(async ({ data = [], totalCount }) => {
      const contacts = [...data];
      const promises: Array<Promise<void>> = [];
      if (totalCount && contacts.length < totalCount) {
        const requestsNbr = Math.ceil(totalCount / limit);
        for (let i = 0; i < requestsNbr; i += 1) {
          offset += limit;
          promises.push(
            contactsService.fetchContacts(offset, limit).then(({ data: newContacts = [] }) => {
              if (newContacts.length > 0) {
                contacts.push(...newContacts);
              }
            }),
          );
        }
      }

      await Promise.all(promises);

      return contacts;
    });
  },

  fetchContacts: (offset = 0, limit = 500, inclCounter = true) =>
    apiMobile.get<SimpleContactsResponse, GetSimpleContactsNewRequest>('/v2/get-simple-contacts', {
      offset,
      limit,
      inclCounter,
    }),

  saveContact: (contactData) =>
    apiMobile.post<SaveSimpleContactResponse, SaveSimpleContactRequest>('/save-simple-contact', contactData),

  saveContactImage: (contactId, contactImage) => {
    const data = new FormData();
    data.append('contactId', contactId);
    data.append('contactImage', contactImage);
    return apiMobile.post<SaveContactImageResponse>('/save-simple-contact-image', data);
  },

  deleteContactImage: (args) => apiMobile.post<void, DeleteContactImageRequest>('delete-contact-pic', args),

  toggleContactIsBlocked: (contactId, toBlocked) =>
    apiMobile.post<void, ToggleContactIsBlockedRequest>('/block-simple-contact', {
      simpleContactId: contactId,
      blocked: toBlocked,
    }),

  toggleContactIsFavorite: (contactId, toFavorite) =>
    apiMobile.post<void, ToggleContactIsFavoriteRequest>('/mark-simple-contact-favorite', {
      simpleContactId: contactId,
      favorite: toFavorite,
    }),

  updatePhonebookDisplayOrder: (displayOrder) =>
    apiMobile.post<void, HomePhoneBookDisplayOrderRequest>('/change-phonebook-display-order', {
      displayOrder,
    }),

  updatePhonebookSortOrder: (sortOrder) =>
    apiMobile.post<void, HomePhoneBookSortOrderRequest>('/change-phonebook-sort-order', {
      sortOrder,
    }),
};

export default contactsService;
