import classNames from 'classnames';
import { forwardRef } from 'react';

import { DropdownDefaultProps } from '../../types';
import DropdownCommon from '../DropdownCommon';
import DropdownCommonOption from '../DropdownCommonOption';

const DropdownDefault = forwardRef<HTMLUListElement, DropdownDefaultProps>(
  ({ onSelect, isOpen, options, style, selectedValue = '', className = '', propTestId = '', propTestIds }, ref) => (
    <DropdownCommon
      className={classNames(className)}
      selectedValue={selectedValue}
      isOpen={isOpen}
      ref={ref}
      options={options}
      onSelect={onSelect}
      style={style}
      renderOption={({ label }) => <DropdownCommonOption>{label}</DropdownCommonOption>}
      propTestId={propTestId}
      propTestIds={propTestIds}
    />
  ),
);

export default DropdownDefault;
