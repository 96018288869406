import React from 'react';

import { StyleableComponent } from 'types';

const Refresh: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_53_1181)">
      <path
        d="M17.6493 6.34999C16.0193 4.71999 13.7093 3.77999 11.1693 4.03999C7.49929 4.40999 4.47929 7.38999 4.06929 11.06C3.51929 15.91 7.26929 20 11.9993 20C15.1893 20 17.9293 18.13 19.2093 15.44C19.5293 14.77 19.0493 14 18.3093 14C17.9393 14 17.5893 14.2 17.4293 14.53C16.2993 16.96 13.5893 18.5 10.6293 17.84C8.40929 17.35 6.61929 15.54 6.14929 13.32C5.30929 9.43999 8.25929 5.99999 11.9993 5.99999C13.6593 5.99999 15.1393 6.68999 16.2193 7.77999L14.7093 9.28999C14.0793 9.91999 14.5193 11 15.4093 11H18.9993C19.5493 11 19.9993 10.55 19.9993 9.99999V6.40999C19.9993 5.51999 18.9193 5.06999 18.2893 5.69999L17.6493 6.34999Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_1181">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Refresh;
