import React from 'react';

import styles from './ErrorLayout.module.scss';

type ErrorLayoutProps = {
  info: React.ReactNode;
  illustration: React.ReactNode;
};

const ErrorLayout: React.FC<ErrorLayoutProps> = ({ info, illustration }) => (
  <div className={styles.root}>
    <div className={styles.container}>
      <div className={styles.info}>{info}</div>
      <div className={styles.illustration}>{illustration}</div>
    </div>
  </div>
);

export default ErrorLayout;
