import React from 'react';

import { SIP } from '@constants';
import { SIPDataCallStatusesWebRTC } from '@redux/types';
import { testGetTestingAttributes, testIds } from '@testing';

import { SIPCallsListItemAudioRingbackProps } from '../../types';

import styles from './SIPCallsListItemAudioRingback.module.scss';

const SIPCallsListItemAudioRingback: React.FC<SIPCallsListItemAudioRingbackProps> = ({
  isCallLocal,
  statusWebRTC,
  timestampCallRang,
}) => {
  const ringbackStatusesPartially: SIPDataCallStatusesWebRTC[] = [SIPDataCallStatusesWebRTC.RINGING_180];
  const ringbackStatusesFully: SIPDataCallStatusesWebRTC[] = [
    ...ringbackStatusesPartially,
    SIPDataCallStatusesWebRTC.RINGING_183,
  ];
  const ringbackStatuses: SIPDataCallStatusesWebRTC[] =
    SIP.CONFIGURATIONS.AUDIO_MANAGEMENT.CALLS_OUTGOING_ARTIFICIAL_RINGBACK_TYPE === 'fully'
      ? ringbackStatusesFully
      : ringbackStatusesPartially;

  const isRingbackAvailable = isCallLocal && timestampCallRang > 0 && ringbackStatuses.includes(statusWebRTC);

  if (isRingbackAvailable === false) {
    return null;
  }

  return (
    <audio
      className={styles.root}
      preload="auto"
      autoPlay
      loop
      key={timestampCallRang}
      {...testGetTestingAttributes(testIds.sipCallsListItemAudioRingbackRoot)}
    >
      <source
        src="/assets/audios/calls_outgoing_ringback_128kbps.mp3"
        type="audio/mpeg"
      />
    </audio>
  );
};

export default SIPCallsListItemAudioRingback;
