import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Call, Message } from '@onoff/icons';
import { showWarningToast } from '@onoff/toast-notification';
import { IconButton } from '@onoff/ui';
import { debounce } from '@onoff/utils';

import {
  formatNumberAsLocal,
  getCategoryPhoneNumber,
  getErrorDataFromUnknownError,
  handleApplicationError,
  isCategoryExpired,
  normalizePhoneNumber,
  checkServiceContactPhoneNumber,
} from 'helpers';

import { API_ERRORS } from '@constants';
import { testGetTestingAttributes, testIds } from '@testing';
import { ModalsDialpad, ModalsNewThread } from 'components/Modals';
import { useCategoriesCapabilities, useUserCapabilities } from 'hooks';
import { useSIPCallMethodsOutgoing } from 'sip';

import { getInfoTopBarActionsCallsAnalyticsCTA } from '../../helpers';
import { InfoTopBarActionsFeaturesCallsAndSMSProps, InfoTopBarActionsTypes } from '../../types';

const InfoTopBarActionsFeaturesCallsAndSMS: React.FC<InfoTopBarActionsFeaturesCallsAndSMSProps> = ({
  type,
  phoneNumberOrEmpty,
  contact,
  category,
}) => {
  const intl = useIntl();
  const { canUserSendSMS, canUserMakeCalls } = useUserCapabilities();
  const { sipCallMake, canUserMakeCallsBySIP } = useSIPCallMethodsOutgoing();
  const { canCategorySendSMS } = useCategoriesCapabilities(category?.id);

  const [isModalDialPadOpen, setIsModalDialPadOpen] = useState(false);
  const [stateModalMessageVisible, setStateModalMessageVisible] = useState<boolean>(false);

  const { isServiceContactNumber } = checkServiceContactPhoneNumber({ phoneNumber: phoneNumberOrEmpty });
  const isPhoneNumberAvailable = phoneNumberOrEmpty.length > 0;
  const isCategoryExist = category !== undefined;
  const isCategoryAvailable = isCategoryExist && isCategoryExpired(category) === false;
  const isButtonCallAvailable =
    !isServiceContactNumber && canUserMakeCalls && isPhoneNumberAvailable && isCategoryAvailable;
  const isButtonMessageAvailable =
    !isServiceContactNumber && canUserSendSMS && isPhoneNumberAvailable && isCategoryAvailable;
  const isPageMessages = type === InfoTopBarActionsTypes.PAGE_MESSAGES;

  const onClickHandlerCallsMakeCall = debounce(async () => {
    const phoneNumberLocal = getCategoryPhoneNumber(category);
    const phoneNumberRemote = phoneNumberOrEmpty;

    try {
      await sipCallMake({
        phoneNumberLocal,
        phoneNumberRemote: `+${normalizePhoneNumber(phoneNumberRemote)}`,
        analyticsCTA: getInfoTopBarActionsCallsAnalyticsCTA(type),
        analyticsHasContact: contact !== undefined,
      });
    } catch (error) {
      const { errorCode } = getErrorDataFromUnknownError(error);

      if (errorCode === API_ERRORS.CALLING.INVALID_CALLEE_NUMBER) {
        setIsModalDialPadOpen(true);
      }

      handleApplicationError({ error });
    }
  }, 300);

  const onClickHandlerMessagesSendSMS = (): void => {
    if (!canCategorySendSMS) {
      showWarningToast({
        heading: intl.formatMessage({ id: 'Notifications.LandlineNumbers.title' }),
        message: intl.formatMessage({ id: 'Notifications.LandlineNumbers.description' }),
      });
      return;
    }
    setStateModalMessageVisible(!stateModalMessageVisible);
  };

  return (
    <>
      {isButtonCallAvailable ? (
        <IconButton
          onClick={onClickHandlerCallsMakeCall}
          disabled={!canUserMakeCallsBySIP}
          {...testGetTestingAttributes(testIds.infoTopBarActionsButtonCallsMakeCall)}
        >
          <Call />
        </IconButton>
      ) : null}
      {isButtonMessageAvailable && !isPageMessages ? (
        <>
          <IconButton
            onClick={onClickHandlerMessagesSendSMS}
            {...testGetTestingAttributes(testIds.infoTopBarActionsButtonMessagesSendSMS)}
          >
            <Message />
          </IconButton>
          <ModalsNewThread
            isOpen={stateModalMessageVisible}
            onClose={onClickHandlerMessagesSendSMS}
            selectedPhoneNumberList={[
              {
                phoneNumber: formatNumberAsLocal(phoneNumberOrEmpty),
                contactId: contact?.id,
              },
            ]}
            selectedCategory={category}
          />
          <ModalsDialpad
            isOpen={isModalDialPadOpen}
            onRequestClose={() => setIsModalDialPadOpen(false)}
            prefilledPhoneNumberRemote={phoneNumberOrEmpty}
          />
        </>
      ) : null}
    </>
  );
};

export default InfoTopBarActionsFeaturesCallsAndSMS;
