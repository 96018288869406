import classnames from 'classnames';
import React from 'react';

import { ButtonDashboardRoundProps } from '../../types';
import ButtonDashboard from '../ButtonDashboard';

import styles from './ButtonDashboardRound.module.scss';

const ButtonDashboardRound: React.FC<ButtonDashboardRoundProps> = ({ className = '', ...rest }) => (
  <ButtonDashboard
    className={classnames(styles.root, className)}
    {...rest}
  />
);

export default ButtonDashboardRound;
