export enum ModalsPremiumPlanTypes {
  /**
   * showing the premium features only
   */
  INFO = 'INFO',
  /**
   * offers free trial with periods to select
   */
  TRIAL = 'TRIAL',
  /**
   * upgrading from STARTER to PREMIUM & showing price info
   */
  UPGRADE = 'UPGRADE',
  /**
   * unsubscribe from PREMIUM
   */
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

export enum ModalsPremiumPlansFeatureIconType {
  LIMIT_CALLS_SMS = 'LIMIT_CALLS_SMS',
  MULTI_DEVICE_SUPPORT = 'MULTI_DEVICE_SUPPORT',
  WEB_ACCESS = 'WEB_ACCESS',
  SMS_SCHEDULING = 'SMS_SCHEDULING',
  ON_OFF_SCHEDULING = 'ON_OFF_SCHEDULING',
  CALLS_OVER_INTERNET = 'CALLS_OVER_INTERNET',
  VOICEMAIL_TRANSCRIPTION = 'VOICEMAIL_TRANSCRIPTION',
  PRIVATE_MODE = 'PRIVATE_MODE',
  MULTI_LANG_VOICEMAIL = 'MULTI_LANG_VOICEMAIL',
  ON_OFF_CALLS = 'ON_OFF_CALLS',
  CALL_NOTES = 'CALL_NOTES',
}
