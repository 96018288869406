import { PurchaseGetCheckoutConfirmationPageUrlRequest } from 'types';

import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  CheckoutActionSetPublicKeyIsLoading,
  CheckoutActionSetPublicKeyIsFailure,
  CheckoutActionSetPublicKeyData,
  CheckoutActionSetRedirectConfirmationUrlIsLoading,
  CheckoutActionSetRedirectConfirmationUrlIsFailure,
} from '../../types';

export const checkoutSetPublicKeyIsLoading = (isLoading: boolean): CheckoutActionSetPublicKeyIsLoading => ({
  type: REDUX_ACTION_TYPES.CHECKOUT_SET_PUBLICKEY_IS_LOADING,
  isLoading,
});

export const checkoutSetPublicKeyIsFailure = (isFailure: boolean): CheckoutActionSetPublicKeyIsFailure => ({
  type: REDUX_ACTION_TYPES.CHECKOUT_SET_PUBLICKEY_IS_FAILURE,
  isFailure,
});

export const checkoutSetPublicKeyData = (data: string): CheckoutActionSetPublicKeyData => ({
  type: REDUX_ACTION_TYPES.CHECKOUT_SET_PUBLICKEY_DATA,
  data,
});

export const checkoutFetchAndSetPublicKeyDataHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(checkoutSetPublicKeyIsLoading(true));

      const apiReturn = await services.purchaseService.getCheckoutPublicKey();

      if (apiReturn.isFailure) {
        throw new Error('Dev Error: Something went wrong during the fetch the Checkout JS public key');
      }

      const { checkoutPublicKey } = apiReturn;

      dispatch(checkoutSetPublicKeyData(checkoutPublicKey));
    } catch (err) {
      dispatch(checkoutSetPublicKeyIsLoading(false));
      dispatch(checkoutSetPublicKeyIsFailure(true));
    }
  };

export const checkoutSetRedirectConfirmationUrlIsLoading = (
  isLoading: boolean,
): CheckoutActionSetRedirectConfirmationUrlIsLoading => ({
  type: REDUX_ACTION_TYPES.CHECKOUT_SET_REDIRECTCONFIRMATIONURL_IS_LOADING,
  isLoading,
});

export const checkoutSetRedirectConfirmationUrlIsFailure = (
  isFailure: boolean,
): CheckoutActionSetRedirectConfirmationUrlIsFailure => ({
  type: REDUX_ACTION_TYPES.CHECKOUT_SET_REDIRECTCONFIRMATIONURL_IS_FAILURE,
  isFailure,
});

export const checkoutGetRedirectConfirmationUrlDataHandler =
  (args: PurchaseGetCheckoutConfirmationPageUrlRequest): ThunkResult<Promise<string>> =>
  async (dispatch, _, services): Promise<string> => {
    try {
      dispatch(checkoutSetRedirectConfirmationUrlIsLoading(true));

      const apiReturn = await services.purchaseService.getCheckoutConfirmationPageUrl(args);

      if (apiReturn.isFailure || !apiReturn.redirectConfirmationURL) {
        throw new Error('Dev Error: Something went wrong during the fetch the Checkout JS redirection URL');
      }

      const { redirectConfirmationURL } = apiReturn;

      return redirectConfirmationURL;
    } catch (error) {
      dispatch(checkoutSetRedirectConfirmationUrlIsLoading(false));
      dispatch(checkoutSetRedirectConfirmationUrlIsFailure(true));

      throw error;
    }
  };
