import { createSelector } from '@reduxjs/toolkit';

import {
  UserReduxState,
  SelectLanguage,
  SelectUser,
  SelectUserAllowedCountries,
  SelectUserId,
  SelectUserNationalCountryIsoCode,
  SelectUserState,
  SelectUserStatuses,
  SelectUserEmail,
  SelectUserCountry,
  SelectUserName,
  SelectUserCountryIsoCode,
  SelectUserIsCallQualitySurveyPermissionEnabled,
} from '../../types';

export const selectUserState: SelectUserState = (state) => state.user;

export const selectUserData: SelectUser = createSelector(
  [selectUserState],
  (userState: UserReduxState) => userState.data,
);

export const selectUserAllowedCountries: SelectUserAllowedCountries = createSelector(
  [selectUserData],
  (user) => user.allowedCountries.supported,
);

export const selectUserCountry: SelectUserCountry = createSelector([selectUserData], (user) => {
  const { nationalCountryIsoCode, allowedCountries } = user;

  return allowedCountries.supported.find(({ code }) => code.toUpperCase() === nationalCountryIsoCode.toUpperCase());
});

export const selectUserId: SelectUserId = createSelector([selectUserData], (user) => user.userId);

export const selectUserName: SelectUserName = createSelector([selectUserData], ({ firstName, lastName }) => ({
  firstName,
  lastName,
}));

export const selectUserEmail: SelectUserEmail = createSelector([selectUserData], (user) => user.email);

export const selectUserCountryIsoCode: SelectUserCountryIsoCode = createSelector(
  [selectUserData],
  (user) => user.countryIsoCode,
);

export const selectUserNationalCountryIsoCode: SelectUserNationalCountryIsoCode = createSelector(
  [selectUserData],
  (user) => user.nationalCountryIsoCode,
);

export const selectUserLanguage: SelectLanguage = (state) => state.user.language;

export const selectUserStatuses: SelectUserStatuses = createSelector(
  [selectUserState],
  (userState) => userState.statuses,
);

export const selectUserIsCallQualitySurveyPermissionEnabled: SelectUserIsCallQualitySurveyPermissionEnabled =
  createSelector([selectUserData], (user) => !!user.callQualitySurvey);

export const selectUserKycStatus = createSelector([selectUserData], (user) => user.kycStatus);

export const selectUserKycRequired = createSelector([selectUserKycStatus], (kycStatus) => kycStatus.kycRequired);
