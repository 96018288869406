import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PurchaseReceiptsListResponseReceipt } from 'types';

import { formatPurchasePrice, isNumber, formatSimpleDate } from 'helpers';

import { IconCommon, IconCommonNames } from 'components/Icon';
import { OpenNewTab } from 'components/OpenNewTab';

import styles from './ModalsReceiptsListMobileItem.module.scss';

export interface ModalsReceiptsListMobileItemProps {
  receipt: PurchaseReceiptsListResponseReceipt;
}

const ModalsReceiptsListMobileItem: React.FC<ModalsReceiptsListMobileItemProps> = ({
  receipt: { price, currencyCode, invoiceNumber, paymentMadeAt, presignedUrl },
}) => {
  const formattedDate = formatSimpleDate(paymentMadeAt);
  const formattedPrice = isNumber(price) && currencyCode ? formatPurchasePrice({ price, currencyCode }) : '';

  return (
    <div
      key={invoiceNumber}
      className={styles.root}
    >
      <IconCommon
        name={IconCommonNames.RECEIPT}
        className={styles.receiptIcon}
      />

      <span className={styles.receiptInvoiceNumber}>{invoiceNumber}</span>
      <span className={styles.receiptPrice}>{formattedPrice}</span>
      <span className={styles.receiptDate}>{formattedDate}</span>

      {presignedUrl ? (
        <OpenNewTab
          href={presignedUrl}
          className={styles.receiptLink}
        >
          <FormattedMessage id="Settings.Receipts.open_receipt" />
        </OpenNewTab>
      ) : null}
    </div>
  );
};

export default ModalsReceiptsListMobileItem;
