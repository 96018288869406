import developmentConfig from 'config/development';
import productionConfig from 'config/production';
import stagingConfig from 'config/staging';

const getEnvSpecificConfig = (appEnv): typeof productionConfig => {
  switch (appEnv) {
    case 'production':
      return productionConfig;
    case 'staging':
      return stagingConfig;
    case 'development':
    default:
      return developmentConfig;
  }
};

export default getEnvSpecificConfig(process.env.REACT_APP_ENV);
