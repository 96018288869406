import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useWebAppSelector } from '@redux';
import { selectBrowserPermissionMicrophoneStatus } from '@redux/selectors/browserPermissions';
import Banner from 'components/Banner';

const InfoBannerMicrophonePermission: React.FC = () => {
  const microphonePermission = useWebAppSelector(selectBrowserPermissionMicrophoneStatus);

  if (microphonePermission !== 'denied') {
    return null;
  }

  return (
    <Banner colorScheme="warning">
      <FormattedMessage id="Layouts.InfoBar.microphone_permission" />
    </Banner>
  );
};

export default InfoBannerMicrophonePermission;
