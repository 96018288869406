import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, ReceiptsReduxState, ReceiptsActionTypes } from '../../types';

export const receiptsInitialState: ReceiptsReduxState = {
  statuses: {
    getReceiptsList: Status.IDLE,
  },
  data: {
    categories: [],
    userOffer: {},
  },
};

export const receiptsReducer: Reducer<ReceiptsReduxState, ReceiptsActionTypes> = (state, action) => {
  const reducerState: ReceiptsReduxState = state || receiptsInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.RECEIPTS_SET_STATUS_GET_RECEIPTS_LIST: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          getReceiptsList: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.RECEIPTS_SET_DATA_RECEIPTS_LIST: {
      return {
        ...reducerState,
        data: action.data,
      };
    }

    default: {
      return reducerState;
    }
  }
};
