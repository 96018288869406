import classNames from 'classnames';
import React from 'react';

import { testGetTestingAttributes } from '@testing';

import styles from './ExpandingCardCore.module.scss';

interface ExpandingCardCoreProps {
  header: React.ReactNode;
  content: React.ReactNode;
  isExpanded: boolean;
  className?: string;
  rootTestingId?: string;
  headerTestingId?: string;
  contentTestingId?: string;
}

const ExpandingCardCore: React.FC<ExpandingCardCoreProps> = ({
  header,
  content,
  className,
  isExpanded = false,
  rootTestingId = '',
  headerTestingId = '',
  contentTestingId = '',
}) => (
  <div
    className={classNames(className, styles.root, {
      [styles.expanded]: isExpanded,
    })}
    {...testGetTestingAttributes(rootTestingId)}
  >
    <div
      className={styles.header}
      {...testGetTestingAttributes(headerTestingId)}
    >
      {header}
    </div>
    {isExpanded ? (
      <div
        className={styles.content}
        {...testGetTestingAttributes(contentTestingId)}
      >
        {content}
      </div>
    ) : null}
  </div>
);

export default ExpandingCardCore;
