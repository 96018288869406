import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { ActiveIntegration, IntegrationDetailedSearchContactsData, Status, StyleableComponent } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  integrationsSetFetchIntegrationContactStatus,
  selectContactsActiveId,
} from '@redux';
import { testIds } from '@testing';
import { ExpandingCardCore } from 'components/ExpandingCard';

import { IntegrationCRMCardType } from '../../types';
import IntegrationCRMCardContent from '../IntegrationCRMCardContent';
import IntegrationCRMCardHeader from '../IntegrationCRMCardHeader';

import styles from './IntegrationCRMCard.module.scss';

export interface IntegrationCRMCoreProps extends StyleableComponent {
  integration: ActiveIntegration;
  status: Status;
  details: IntegrationDetailedSearchContactsData | null;
  fetchIntegrationDetail: (integrationId: string) => void;
  reloadIntegrationDetail: (integrationId: string) => void;
  integrationCrmCardType: IntegrationCRMCardType;
  onContactModalOpenClick: (details: IntegrationDetailedSearchContactsData | null, isEdit: boolean) => void;
  isLocalContact?: boolean;
  isCRMContact?: boolean;
}

const IntegrationCRMCard: React.FC<IntegrationCRMCoreProps> = ({
  integration,
  status,
  details,
  fetchIntegrationDetail,
  reloadIntegrationDetail,
  onContactModalOpenClick,
  className = '',
  integrationCrmCardType,
  isLocalContact = false,
  isCRMContact = false,
}) => {
  const dispatch = useWebAppDispatch();

  const contactActiveId = useWebAppSelector(selectContactsActiveId);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const onHeaderClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(
    () => () => {
      setIsExpanded(false);
      dispatch(integrationsSetFetchIntegrationContactStatus(Status.IDLE, integration.id));
    },
    [dispatch, contactActiveId, integration],
  );

  useEffect(() => {
    if (isExpanded && !details) {
      fetchIntegrationDetail(integration.id);
    }
  }, [fetchIntegrationDetail, isExpanded, integration, details]);

  const isOpenedFromContactPage = integrationCrmCardType === IntegrationCRMCardType.CONTACT_PAGE;
  const isOpenedFromCallPopup = integrationCrmCardType === IntegrationCRMCardType.CALL_POPUP;
  const isReloadButtonVisible = status !== Status.LOADING && isExpanded;
  const isContactSyncButtonVisible = isExpanded && !!details && isOpenedFromContactPage && isLocalContact;
  const isContactAddButtonVisible = isExpanded && !!details && isOpenedFromContactPage && isCRMContact;

  return (
    <ExpandingCardCore
      className={classNames(styles.root, className, {
        [styles.calls]: isOpenedFromCallPopup,
        [styles.contacts]: isOpenedFromContactPage,
      })}
      header={
        <IntegrationCRMCardHeader
          integrationCrmCardType={integrationCrmCardType}
          className={classNames({
            [styles.callPopupCardHeader]: isOpenedFromCallPopup,
            [styles.contactPageCardHeader]: isOpenedFromContactPage,
          })}
          integration={integration}
          isExpanded={isExpanded}
          fetchStatus={status}
          isReloadButtonVisible={isReloadButtonVisible}
          isContactSyncButtonVisible={isContactSyncButtonVisible}
          isContactAddButtonVisible={isContactAddButtonVisible}
          onClick={onHeaderClick}
          onReloadClick={() => reloadIntegrationDetail(integration.id)}
          onContactSyncClick={() => onContactModalOpenClick(details, true)}
          onContactAddClick={() => onContactModalOpenClick(details, false)}
        />
      }
      content={
        <IntegrationCRMCardContent
          integrationCrmCardType={integrationCrmCardType}
          status={status}
          details={details}
          className={classNames({
            [styles.callPopupCardContent]: isOpenedFromCallPopup,
            [styles.contactPageCardContent]: isOpenedFromContactPage,
          })}
        />
      }
      isExpanded={isExpanded}
      headerTestingId={testIds.integrationCrmCardHeader}
      contentTestingId={testIds.integrationCrmCardContent}
    />
  );
};

export default IntegrationCRMCard;
