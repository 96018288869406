import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast, showWarningToast } from '@onoff/toast-notification';

import {
  useWebAppDispatch,
  useWebAppSelector,
  sipSetDataCallStatusFeatureDTMFByWebRTCIdHandler,
  sipSetDataCallValueWebRTCDTMFByWebRTCIdHandler,
  selectSIPDataCallByStatusFeatureDTMFSend,
} from '@redux';
import { SIPStatisticsScopes, SIPDataCallStatusesFeatureDTMF } from '@redux/types';

import { checkSIPDTMFValueValidity, checkSIPDTMFSupport, sipDebugConsoleLogger } from '../../../../../../../../helpers';
import { RTCSessionDTMFTransports } from '../../../../../../../../types';
import { useSIPStatistics } from '../../../../../../../useSIPStatistics';

import { UseWebRTCSessionsDTMFProps } from './types';

export const useWebRTCSessionsDTMF = ({ propWebRTCSessions }: UseWebRTCSessionsDTMFProps): void => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { idWebRTCForDTMF, idTransactionForDTMF, originatorForDTMF, valueWebRTCForDTMF } = useWebAppSelector(
    selectSIPDataCallByStatusFeatureDTMFSend,
  );

  const { sipSendErrorToSentry, sipSendErrorToGTM } = useSIPStatistics();

  const propWebRTCSessionForDTMFSend = propWebRTCSessions.find(({ id }) => id === idWebRTCForDTMF);

  const callbackWebRTCSessionsDTMFHandler = (): void => {
    const { isDTMFValueValid } = checkSIPDTMFValueValidity(valueWebRTCForDTMF);

    if (
      idWebRTCForDTMF &&
      idTransactionForDTMF &&
      originatorForDTMF &&
      propWebRTCSessionForDTMFSend &&
      isDTMFValueValid
    ) {
      try {
        dispatch(
          sipSetDataCallValueWebRTCDTMFByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForDTMF,
            propCallValueWebRTCDTMF: '',
          }),
        );

        propWebRTCSessionForDTMFSend.sendDTMF(valueWebRTCForDTMF, {
          transportType: RTCSessionDTMFTransports.RFC2833,
          duration: 70,
          interToneGap: 50,
        });

        dispatch(
          sipSetDataCallStatusFeatureDTMFByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForDTMF,
            propCallStatusFeatureDTMF: SIPDataCallStatusesFeatureDTMF.SENT,
          }),
        );
      } catch (error) {
        dispatch(
          sipSetDataCallStatusFeatureDTMFByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForDTMF,
            propCallStatusFeatureDTMF: SIPDataCallStatusesFeatureDTMF.FAILED,
          }),
        );

        const { isDTMFNotSupported } = checkSIPDTMFSupport(error);
        if (isDTMFNotSupported) {
          sipDebugConsoleLogger('SIP WEBRTC: CALL SEND DTMF IS NOT SUPPORTED!', { error });

          showWarningToast({
            heading: intl.formatMessage({ id: 'SIP.Notifications.CallDTMFNotSupported.title' }),
            message: intl.formatMessage({ id: 'SIP.Notifications.CallDTMFNotSupported.description' }),
          });
        } else {
          sipDebugConsoleLogger('SIP WEBRTC: CALL SEND DTMF IS FAILED!', { error });

          const errorMessage = error instanceof Error ? error.message : error;

          sipSendErrorToSentry({
            scope: SIPStatisticsScopes.SIP_WEBRTC,
            error: `CALL SEND DTMF IS FAILED! "${errorMessage}"`,
          });

          sipSendErrorToGTM({
            sipCallCase: SIPStatisticsScopes.SIP_WEBRTC,
            sipCallOriginator: originatorForDTMF,
            sipCallTitle: 'CALL SEND DTMF IS FAILED!',
            sipCallDescription: `${errorMessage}`,
            sipCallTransactionId: idTransactionForDTMF,
          });

          showErrorToast({
            heading: intl.formatMessage({ id: 'SIP.Notifications.CallDTMFFailure.title' }),
            message: intl.formatMessage({ id: 'SIP.Notifications.CallDTMFFailure.description' }),
          });
        }
      }
    }
  };

  useEffect(callbackWebRTCSessionsDTMFHandler, [
    idWebRTCForDTMF,
    idTransactionForDTMF,
    originatorForDTMF,
    valueWebRTCForDTMF,
    propWebRTCSessionForDTMFSend,
    intl,
    dispatch,
    sipSendErrorToSentry,
    sipSendErrorToGTM,
  ]);
};
