import React from 'react';

import { Modal, ModalSize } from 'components/Modal';

import ModalsDeleteCategoryContent, { ModalsDeleteCategoryContentProps } from '../ModalsDeleteCategoryContent';

import styles from './ModalsDeleteCategory.module.scss';

export interface ModalsDeleteCategoryProps extends ModalsDeleteCategoryContentProps {
  isOpen: boolean;
}

const ModalsDeleteCategory: React.FC<ModalsDeleteCategoryProps> = ({ isOpen, onRequestClose, categoryId }) => (
  <Modal
    isOpen={isOpen}
    className={styles.root}
    onRequestClose={onRequestClose}
    size={ModalSize.L}
  >
    <ModalsDeleteCategoryContent
      onRequestClose={onRequestClose}
      categoryId={categoryId}
    />
  </Modal>
);

export default ModalsDeleteCategory;
