import React from 'react';

import { Message } from 'types';

import { IconButton, IconCommon, IconCommonNames } from 'components/Icon';
import { Modal, ModalSize } from 'components/Modal';

import styles from './ModalsFullscreenMessageAttachment.module.scss';

export interface ModalsFullscreenMessageAttachmentProps {
  isOpen: boolean;
  src: string;
  type: Message['type'];
  onRequestClose: () => void;
  alt?: string;
}

const ModalsFullscreenMessageAttachment: React.FC<ModalsFullscreenMessageAttachmentProps> = ({
  isOpen,
  src,
  type,
  onRequestClose,
  alt = '',
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    size={ModalSize.XXL}
    className={styles.root}
  >
    <IconCommon
      className={styles.spinner}
      name={IconCommonNames.SPINNER}
    />

    {type === 'IMAGE' && (
      <img
        onClick={onRequestClose}
        className={styles.image}
        src={src}
        alt={alt}
      />
    )}

    {['VIDEO', 'INSTA_VIDEO'].includes(type) && (
      <div className={styles.videoWrapper}>
        <IconButton
          iconName={IconCommonNames.CLOSE}
          className={styles.videoButtonClose}
          onClick={onRequestClose}
        />

        <video
          controls
          width="100%"
          height="100%"
          className={styles.video}
        >
          <source src={src} />
        </video>
      </div>
    )}
  </Modal>
);

export default ModalsFullscreenMessageAttachment;
