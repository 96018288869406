import { Action } from '@reduxjs/toolkit';

import { Category, Status, PlansStatistics } from 'types';

import { REDUX_ACTION_TYPES } from './actionTypes';

export interface CategoriesIsLoadingAction extends Action {
  type: typeof REDUX_ACTION_TYPES.CATEGORY_IS_LOADING;
  isLoading: boolean;
}

export interface CategoriesSetAllAction extends Action {
  type: typeof REDUX_ACTION_TYPES.SET_ALL_CATEGORIES;
  categories: Category[];
}

export interface CategoryAddAction extends Action {
  type: typeof REDUX_ACTION_TYPES.ADD_CATEGORY;
  category: Category;
}

export interface CategoryRemoveAction extends Action {
  type: typeof REDUX_ACTION_TYPES.REMOVE_CATEGORY;
  categoryId: string;
}

export interface CategoryUpdateAction extends Action {
  type: typeof REDUX_ACTION_TYPES.UPDATE_CATEGORY;
  categoryId: string;
  data: Partial<Category>;
}

export interface CategoriesSetStatusCancelSubscriptionAction extends Action {
  type: typeof REDUX_ACTION_TYPES.CATEGORIES_SET_STATUS_CANCEL_SUBSCRIPTION;
  status: Status;
}

export interface CategoriesSetStatusFetchStatistics extends Action {
  type: typeof REDUX_ACTION_TYPES.CATEGORIES_SET_STATUS_FETCH_STATISTICS;
  status: Status;
}

export interface CategoriesSetStatisticsData extends Action {
  type: typeof REDUX_ACTION_TYPES.CATEGORIES_SET_STATISTICS_DATA;
  data: PlansStatistics[];
}

export interface CategoriesStatisticsUpdateNextPlanDuration extends Action {
  type: typeof REDUX_ACTION_TYPES.CATEGORIES_STATISTICS_UPDATE_NEXT_PLAN_DURATION;
  categoryId: string;
  nextPlanDuration: number;
}

export interface CategoriesStatisticsRemoveNextPlanDuration extends Action {
  type: typeof REDUX_ACTION_TYPES.CATEGORIES_STATISTICS_REMOVE_NEXT_PLAN_DURATION;
  categoryId: string;
}

export type CategoryActionTypes =
  | CategoriesIsLoadingAction
  | CategoriesSetAllAction
  | CategoryAddAction
  | CategoryRemoveAction
  | CategoryUpdateAction
  | CategoriesSetStatusCancelSubscriptionAction
  | CategoriesSetStatusFetchStatistics
  | CategoriesSetStatisticsData
  | CategoriesStatisticsUpdateNextPlanDuration
  | CategoriesStatisticsRemoveNextPlanDuration;

export enum CategorySwitchStatusTypes {
  CATEGORY = 'category',
  CALLS = 'calls',
  MESSAGES = 'messages',
  VOICEMAILS = 'voicemails',
}

interface CategorySwitchStatusPropsBase {
  categoryId: string;
  switchTo: boolean;
}

export interface CategorySwitchStatusProps extends CategorySwitchStatusPropsBase {
  switchFor?: CategorySwitchStatusTypes;
}

export type CategorySwitchStatusCallsProps = CategorySwitchStatusPropsBase;

export type CategorySwitchStatusMessagesProps = CategorySwitchStatusPropsBase;

export type CategorySwitchStatusVoicemailsProps = CategorySwitchStatusPropsBase;

export interface CategoryChangeColorProps {
  categoryId: string;
  newColor: string;
}

export interface CategoryChangeNameProps {
  categoryId: string;
  newName: string;
}

export interface CategoryChangeVisibilityProps {
  categoryId: string;
  switchTo: boolean;
}

export interface CategoriesUpdateCategoryHandlerProps {
  category: Category;
}

export interface CategoriesRemoveCategoriesAndRelatedDataHandlerProps {
  categoryIds: string[];
}

export interface CategoriesStatisticsUpdateNextPlanDurationProps {
  categoryId: string;
  nextPlanDuration: number;
}
