import { createSelector } from '@reduxjs/toolkit';

import {
  SelectPurchaseState,
  SelectPurchasePhoneNumber,
  SelectPurchaseCategoryId,
  SelectPurchaseUserEmail,
  SelectPurchaseProductId,
} from '../../types';
import { selectUserData } from '../user';

export const selectPurchaseState: SelectPurchaseState = (state) => state.purchase;

export const selectPurchasePhoneNumber: SelectPurchasePhoneNumber = createSelector(
  [selectPurchaseState],
  (purchase) => purchase.virtualPhoneNumber,
);

export const selectPurchaseCategoryId: SelectPurchaseCategoryId = createSelector(
  [selectPurchaseState],
  (purchase) => purchase.categoryId,
);

export const selectPurchaseProductId: SelectPurchaseProductId = createSelector(
  [selectPurchaseState],
  (purchase) => purchase.summary.data.productId,
);

export const selectPurchaseUserEmail: SelectPurchaseUserEmail = createSelector([selectUserData], (user) => user.email);
