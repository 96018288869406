import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { StyleableComponent, TestableComponent } from 'types';

import { testGetTestingAttributes } from '@testing';

import styles from './ModalBody.module.scss';

export type ModalBodyProps = StyleableComponent & Pick<TestableComponent, 'propTestId'>;

const ModalBody: React.FC<ModalBodyProps & PropsWithChildren> = ({ children, className, propTestId }) => (
  <div
    className={classNames(styles.root, className)}
    {...testGetTestingAttributes(propTestId)}
  >
    {children}
  </div>
);

export default ModalBody;
