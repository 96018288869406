import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { NotificationsBrowserPermissionTypes } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  notificationsBrowserRemoveData,
  selectNotificationsBrowserDataList,
  selectUserLanguage,
} from '@redux';

import {
  getNotificationsBrowserTitleTranslationIdByType,
  getNotificationsBrowserBodyTranslationIdByType,
  createNotificationsBrowserBody,
  createNotificationsBrowserTitle,
  isNotificationsBrowserSupported,
  getNotificationsBrowserRedirectionPathByType,
} from '../../helpers';
import {
  useNotificationsBrowserWindowName,
  useNotificationsBrowserPermission,
  useNotificationsBrowserIcon,
} from '../../hooks';

const NotificationsBrowser: React.FC = () => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const navigate = useNavigate();

  const windowName = useNotificationsBrowserWindowName();
  const permission = useNotificationsBrowserPermission();
  const notificationIcon = useNotificationsBrowserIcon();
  const notificationLanguage = useWebAppSelector(selectUserLanguage);
  const dataList = useWebAppSelector(selectNotificationsBrowserDataList);

  const notificationsBrowserRemoveDataHandler = useCallback((): void => {
    dataList.forEach((data) => dispatch(notificationsBrowserRemoveData(data.id)));
  }, [dataList, dispatch]);

  const notificationsBrowserGrantedHandler = useCallback((): void => {
    const isNotificationSupported = 'Notification' in window;
    const isWindowSame = window.name === windowName;
    const isWindowUnFocused = !window.document.hasFocus();
    const hasNotifications = dataList.length > 0;

    if (isNotificationSupported && isWindowSame && isWindowUnFocused && hasNotifications) {
      dataList.forEach((data) => {
        const title = createNotificationsBrowserTitle({
          translation: intl.formatMessage({
            id: getNotificationsBrowserTitleTranslationIdByType({ type: data.type }),
          }),
          contactNameOrNumber: data.contactNameOrNumber,
        });

        const body = createNotificationsBrowserBody({
          type: data.type,
          body: data.body,
          translation: intl.formatMessage({
            id: getNotificationsBrowserBodyTranslationIdByType({ type: data.type }),
          }),
        });

        const redirectionPath = getNotificationsBrowserRedirectionPathByType({
          type: data.type,
        });

        try {
          const notification = new window.Notification(title, {
            body,
            icon: notificationIcon,
            lang: notificationLanguage,
            tag: data.type,
            requireInteraction: true,
          });

          notification.onclick = (event): void => {
            event.preventDefault();

            window.open('', windowName)?.focus();

            navigate(redirectionPath);

            notification.close();
          };
        } catch {
          // ignore
        }
      });
    }
  }, [windowName, dataList, notificationIcon, notificationLanguage, intl, navigate]);

  const notificationsBrowserHandler = useCallback((): void => {
    if (isNotificationsBrowserSupported()) {
      if (permission === NotificationsBrowserPermissionTypes.GRANTED) {
        notificationsBrowserGrantedHandler();
      }
    }
    notificationsBrowserRemoveDataHandler();
  }, [permission, notificationsBrowserGrantedHandler, notificationsBrowserRemoveDataHandler]);

  useEffect(() => {
    notificationsBrowserHandler();
  }, [notificationsBrowserHandler]);

  return null;
};

export default NotificationsBrowser;
