import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { isValidEmail } from 'helpers';

export interface UseFormValidationEmailProps {
  email: string;
}

export type UseFormValidationEmail = (props: UseFormValidationEmailProps) => {
  emailErrorMessage: string;
  emailCheckValidity: () => { isEmailValid: boolean };
  emailResetValidity: () => void;
};

export const useFormValidationEmail: UseFormValidationEmail = ({ email }) => {
  const intl = useIntl();
  const [stateIsEmailValid, setStateIsEmailValid] = useState<boolean>(true);

  const emailErrorMessage = stateIsEmailValid
    ? ''
    : intl.formatMessage({
        id: 'General.Validations.email_invalid',
      });

  const emailCheckValidity = useCallback(() => {
    const isEmailValid = isValidEmail(email);
    setStateIsEmailValid(isEmailValid);
    return { isEmailValid };
  }, [email]);

  const emailResetValidity = useCallback((): void => {
    setStateIsEmailValid(true);
  }, []);

  return useMemo(
    () => ({
      emailErrorMessage,
      emailCheckValidity,
      emailResetValidity,
    }),
    [emailErrorMessage, emailCheckValidity, emailResetValidity],
  );
};
