import { useCallback, useEffect, useMemo, useState } from 'react';

import { isFunction } from 'helpers';

import { BREAKPOINTS } from '@constants';

interface UseResponsiveBreakpointsReturn {
  isMobile: boolean;
  isTablet: boolean;
}

export const useResponsiveBreakpoints = (): UseResponsiveBreakpointsReturn => {
  const mobileBreakpoint = window.matchMedia(`(max-width: ${BREAKPOINTS.MAX_WIDTH_MOBILE}px)`);
  const tabletBreakpoint = window.matchMedia(`(max-width: ${BREAKPOINTS.MAX_WIDTH_TABLET}px)`);

  const [isMobile, setIsMobile] = useState<boolean>(mobileBreakpoint.matches);
  const [isTablet, setIsTablet] = useState<boolean>(tabletBreakpoint.matches);

  const eventListenerMobile = useCallback(
    (event: MediaQueryListEvent): void => {
      if (event.matches !== isMobile) {
        setIsMobile(event.matches);
      }
    },
    [isMobile],
  );

  const eventListenerTablet = useCallback(
    (event: MediaQueryListEvent): void => {
      if (event.matches !== isTablet) {
        setIsTablet(event.matches);
      }
    },
    [isTablet],
  );

  const callbackListenersHandler = () => {
    if (isFunction(mobileBreakpoint?.addEventListener) && isFunction(tabletBreakpoint?.addEventListener)) {
      mobileBreakpoint.addEventListener('change', eventListenerMobile);
      tabletBreakpoint.addEventListener('change', eventListenerTablet);
    } else {
      /**
       * @deprecated
       * "addListener" is Deprecated, this is for old browsers/compatibility
       * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
       */
      mobileBreakpoint.addListener(eventListenerMobile);
      tabletBreakpoint.addListener(eventListenerTablet);
    }
    return () => {
      if (isFunction(mobileBreakpoint?.removeEventListener) && isFunction(tabletBreakpoint?.removeEventListener)) {
        mobileBreakpoint.removeEventListener('change', eventListenerMobile);
        tabletBreakpoint.removeEventListener('change', eventListenerTablet);
      } else {
        /**
         * @deprecated
         * "removeListener" is Deprecated, this is for old browsers/compatibility
         * https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/removeListener
         */
        mobileBreakpoint.removeListener(eventListenerMobile);
        tabletBreakpoint.removeListener(eventListenerTablet);
      }
    };
  };

  useEffect(callbackListenersHandler, [mobileBreakpoint, tabletBreakpoint, eventListenerMobile, eventListenerTablet]);

  return useMemo(
    () => ({
      isMobile,
      isTablet,
    }),
    [isMobile, isTablet],
  );
};
