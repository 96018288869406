import classNames from 'classnames';
import React from 'react';

import { IconButton, IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { SMSButtonAttachmentProps, SMSAttachmentTypes } from '../../types';

import styles from './SMSAttachment.module.scss';

const SMSButtonAttachment: React.FC<SMSButtonAttachmentProps> = ({
  onClickToRemove,
  attachmentType,
  fileName,
  isDisabled = false,
  className = '',
}) => (
  <div
    className={classNames(styles.root, className, {
      [styles.isDisabled]: isDisabled,
    })}
  >
    <IconCommon
      className={styles.icon}
      name={attachmentType === SMSAttachmentTypes.IMAGE ? IconCommonNames.PICTURE : IconCommonNames.MUSIC_NOTE}
      size={IconCommonSizes.TINY}
    />
    <span className={styles.name}>{fileName}</span>
    <IconButton
      iconName={IconCommonNames.CLOSE}
      iconSize={IconCommonSizes.TINY}
      className={styles.button}
      onClick={onClickToRemove}
      isDisabled={isDisabled}
    />
  </div>
);

export default SMSButtonAttachment;
