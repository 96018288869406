import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Category } from 'types';

import { getCategoryColors } from 'helpers/category/colors';

import { useWebAppDispatch, updateCategoryColor } from '@redux';
import { testIds } from '@testing';

import CategoryCommonButtonColor from '../CategoryCommonButtonColor';

import styles from './CategoryCommonColors.module.scss';

export interface CategoryCommonColorsProps {
  categoryId: Category['id'];
  categoryColor: string;
}

const CategoryCommonColors: React.FC<CategoryCommonColorsProps> = ({ categoryId, categoryColor }) => {
  const dispatch = useWebAppDispatch();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);

  const onClickHandlerButtonOpenPicker = (): void => {
    setIsColorPickerOpen(!isColorPickerOpen);
  };

  const onClickHandlerButtonPickColor = (color: string): void => {
    dispatch(
      updateCategoryColor({
        categoryId,
        newColor: color,
      }),
    );
  };

  const closeColorPicker = useCallback(() => {
    if (categoryColor) {
      setIsColorPickerOpen(false);
    }
  }, [categoryColor]);

  useEffect(() => {
    closeColorPicker();
  }, [closeColorPicker]);

  return (
    <div className={styles.root}>
      <span className={styles.text}>
        <FormattedMessage id={'Categories.color'} />
      </span>

      <CategoryCommonButtonColor
        className={styles.buttonOpenPicker}
        color={categoryColor}
        onClick={onClickHandlerButtonOpenPicker}
        propTestId={testIds.categoryColorPicker}
      />

      <div
        className={classnames(styles.colorPicker, {
          [styles.isOpen]: isColorPickerOpen,
        })}
        style={{
          background: categoryColor,
        }}
      >
        {getCategoryColors().map((color) => {
          const isActive = color?.toLocaleLowerCase('en') === categoryColor?.toLocaleLowerCase('en');
          const onClickHandler = isActive ? closeColorPicker : () => onClickHandlerButtonPickColor(color);

          return (
            <CategoryCommonButtonColor
              key={color}
              color={color}
              onClick={onClickHandler}
              className={classnames(styles.buttonPickColor, {
                [styles.isActive]: isActive,
              })}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CategoryCommonColors;
