import classNames from 'classnames';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Search } from '@onoff/ui';

import { ContactPhoneNumberCard } from 'types';

import { filterContactsBySearchString, getContactListPhoneNumberCards } from 'helpers';

import {
  selectCallLogsMostRecentContact,
  selectCategoriesWithDynamicColor,
  selectSimpleContacts,
  selectUserCountryIsoCode,
  useWebAppSelector,
} from '@redux';

import { prepareContactPhoneNumberCards } from '../../helpers';
import { CallTransferError } from '../../types';

import { ErrorMessage, ListHeading, PhoneNumberCard } from './components';

import styles from './PhoneNumberSelector.module.scss';

export type PhoneNumberSelectorProps = {
  categoryId: string;
  onPoneNumberSelect: (phoneNumber: ContactPhoneNumberCard['phoneNumber'] | null) => void;
  selectedPhoneNumber: ContactPhoneNumberCard['phoneNumber'] | null;
  error: CallTransferError | null;
  setError: (error: CallTransferError | null) => void;
  className?: string;
};

export const PhoneNumberSelector: React.FC<PhoneNumberSelectorProps> = ({
  categoryId,
  onPoneNumberSelect,
  selectedPhoneNumber,
  error,
  setError,
  className,
}) => {
  const contactList = useWebAppSelector(selectSimpleContacts);
  const categoryList = useWebAppSelector(selectCategoriesWithDynamicColor);
  const contactMostRecentList = useWebAppSelector(selectCallLogsMostRecentContact);
  const userCountryIsoCode = useWebAppSelector(selectUserCountryIsoCode);

  const [inputValue, setInputValue] = useState('');

  const currentCategoryColor = categoryList.filter((category) => category.id === categoryId)?.[0]?.color;

  const currentContactList = !inputValue
    ? contactMostRecentList
    : filterContactsBySearchString(contactList, inputValue);

  const contactPhoneNumberCards = prepareContactPhoneNumberCards({
    cards: getContactListPhoneNumberCards(currentContactList, categoryList),
    countryCode: userCountryIsoCode,
  });

  const phoneNumberObjectFromInputValue = parsePhoneNumberFromString(inputValue, userCountryIsoCode);
  const formattedPhoneNumberFromInputValue = phoneNumberObjectFromInputValue?.formatInternational();

  const isRecentNumbers = !inputValue && !error;
  const isListingContacts = isRecentNumbers || (!!contactPhoneNumberCards.length && !error);
  const isNewNumber =
    !contactPhoneNumberCards.length &&
    !!formattedPhoneNumberFromInputValue &&
    !!phoneNumberObjectFromInputValue &&
    phoneNumberObjectFromInputValue.isPossible();
  const isNoResults = !isListingContacts && !isNewNumber && !error;

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setError(null);
    onPoneNumberSelect(null);
  };

  useEffect(() => {
    if (error) {
      setInputValue('');
      onPoneNumberSelect(null);
    }
  }, [error, onPoneNumberSelect]);

  return (
    <div className={classNames(styles.root, className)}>
      <Search
        className={styles.search}
        variant="variant-02"
        value={inputValue}
        onChange={inputChangeHandler}
      />

      {isRecentNumbers && (
        <ListHeading>
          <FormattedMessage id="Modals.CallTransferring.heading_recent_contacts" />
        </ListHeading>
      )}
      {isNewNumber && (
        <ListHeading>
          <FormattedMessage id="Modals.CallTransferring.heading_new_number" />
        </ListHeading>
      )}

      {error === CallTransferError.GENERIC && (
        <ErrorMessage>
          <FormattedMessage id="Modals.CallTransferring.error_generic" />
        </ErrorMessage>
      )}
      {error === CallTransferError.INVALID_NUMBER && (
        <ErrorMessage>
          <FormattedMessage id="Modals.CallTransferring.error_invalid_number" />
        </ErrorMessage>
      )}

      <div className={styles.scrollable}>
        <div
          role="radiogroup"
          className={styles.list}
        >
          {isListingContacts &&
            contactPhoneNumberCards.map((card, index) => (
              <PhoneNumberCard
                key={`${card.contactFullName}${card.phoneNumber}${index}`}
                avatarProps={card.avatarProps}
                contactFullName={card.contactFullName}
                phoneNumber={card.phoneNumber}
                isSelected={selectedPhoneNumber === card.phoneNumber}
                onSelect={onPoneNumberSelect}
              />
            ))}

          {isNewNumber && (
            <PhoneNumberCard
              avatarProps={{ backgroundColor: currentCategoryColor }}
              phoneNumber={formattedPhoneNumberFromInputValue}
              isSelected={selectedPhoneNumber === formattedPhoneNumberFromInputValue}
              onSelect={onPoneNumberSelect}
            />
          )}

          {isNoResults && (
            <div className={styles.noResults}>
              <FormattedMessage id="Modals.CallTransferring.no_results" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
