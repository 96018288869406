import classNames from 'classnames';
import React, { useEffect } from 'react';

import { useWebAppDispatch, multiDeviceFetchDeviceListHandler } from '@redux';

import { MultiDeviceListType } from '../../enums';
import { useDeviceList } from '../../hooks';
import MultiDeviceListItem from '../MultiDeviceListItem';

import styles from './MultiDeviceListCore.module.scss';

export interface MultiDeviceListCoreProps {
  type: MultiDeviceListType;
}

const MultiDeviceListCore: React.FC<MultiDeviceListCoreProps> = ({ type }) => {
  const dispatch = useWebAppDispatch();
  const deviceList = useDeviceList(type);

  useEffect(() => {
    if (type === MultiDeviceListType.SETTINGS) {
      dispatch(multiDeviceFetchDeviceListHandler());
    }
  }, [dispatch, type]);

  return (
    <ul
      className={classNames(styles.root, {
        [styles.typeModal]: type === MultiDeviceListType.MODAL,
        [styles.typeSettings]: type === MultiDeviceListType.SETTINGS,
      })}
    >
      {deviceList.map((device) => (
        <MultiDeviceListItem
          key={device.deviceId}
          device={device}
        />
      ))}
    </ul>
  );
};

export default MultiDeviceListCore;
