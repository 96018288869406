import classNames from 'classnames';
import { CountryCode } from 'libphonenumber-js';
import React, { forwardRef, HTMLProps, useEffect, useImperativeHandle, useRef } from 'react';

import { Backspace } from '@onoff/icons';
import { IconButton } from '@onoff/ui';

import { formatPhoneNumber, removeWhiteSpaces } from 'helpers';

import { cleanFromNonNumeric, compareCharacterCounts, getCharacterCount } from './helpers';

import styles from './PhoneNumberInput.module.scss';

export interface PhoneNumberInputProps extends HTMLProps<HTMLInputElement> {
  countryCode?: CountryCode;
  isNational?: boolean;
  onBackspaceClick?: () => void;
  contactName?: string;
  hasError?: boolean;
}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  ({ value, countryCode, isNational, onBackspaceClick, contactName, hasError, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, []);

    const memoizedCountryCode = useRef(countryCode);
    const memoizedValue = useRef<string>(value?.toString() || '');
    const hasInit = useRef<boolean>(false);

    const handleBackspaceClick = () => {
      if (!onBackspaceClick) {
        return;
      }

      if (inputRef.current) {
        inputRef.current.selectionStart = inputRef.current?.value.length;
        inputRef.current.selectionEnd = inputRef.current?.value.length;
      }

      onBackspaceClick();
    };

    useEffect(() => {
      if (inputRef.current === null) {
        return;
      }

      const isContactSelected = contactName && contactName.length > 0;
      const isNewCountryCodeSelected = memoizedCountryCode.current !== countryCode;

      const formattedValue = formatPhoneNumber({
        phoneNumber: value?.toString() || '',
        countryCode,
        isNational,
      });

      if (isContactSelected) {
        inputRef.current.value = formattedValue;
        memoizedValue.current = formattedValue;
        return;
      }

      if (isNewCountryCodeSelected) {
        inputRef.current.value = formattedValue;
        memoizedValue.current = formattedValue;
        memoizedCountryCode.current = countryCode;
        return;
      }

      if (value !== undefined && memoizedValue) {
        const cursorLocation = inputRef.current.selectionStart || 0;

        const valueCleaned = cleanFromNonNumeric(removeWhiteSpaces(value.toString() || inputRef.current?.value));
        const memodValueCleaned = cleanFromNonNumeric(removeWhiteSpaces(memoizedValue.current));

        const { hasChangesToValue, hasDeletedValue } = compareCharacterCounts(memodValueCleaned, valueCleaned);

        const isCursorAtEnd = inputRef.current.value.length === inputRef.current.selectionStart;

        inputRef.current.value = hasChangesToValue || !hasInit.current ? formattedValue : value.toString();

        const numberOfCharsBefore = getCharacterCount(memoizedValue.current);
        const numberOfCharsNow = getCharacterCount(inputRef.current.value);
        const numberOfSpacesBefore = memoizedValue.current.split('').filter((char, i) =>
          // if cursor at end, just take all formatted spaces into account
          isCursorAtEnd ? char === ' ' : char === ' ' && i < cursorLocation + (hasDeletedValue ? 0 : -1),
        ).length;

        const numberOfSpacesNow = inputRef.current.value
          .split('')
          .filter((char, i) => (isCursorAtEnd ? char === ' ' : char === ' ' && i < cursorLocation)).length;

        let indexSetter = 0; // formatting changes
        if (hasChangesToValue && numberOfCharsNow && valueCleaned.length > 1) {
          indexSetter = numberOfSpacesNow - numberOfSpacesBefore;

          // formatter adds characters
          if (numberOfCharsNow - numberOfCharsBefore >= 2) {
            indexSetter += numberOfCharsNow - numberOfCharsBefore - 1;
          }
        }

        // get new cursor location from the old location and add/subtract the changes from formatting
        const newIdx = Math.max(0, cursorLocation + indexSetter);

        memoizedValue.current = formattedValue;
        hasInit.current = true;

        inputRef.current.selectionStart = newIdx;
        inputRef.current.selectionEnd = newIdx;
      }
    }, [countryCode, isNational, value, contactName]);

    return (
      <div
        className={classNames(styles.root, {
          [styles.error]: hasError,
        })}
      >
        {contactName && contactName.length > 0 && (
          <span className={classNames(styles.contactName, { [styles.muted]: props.disabled })}>{contactName}</span>
        )}
        <input
          className={styles.input}
          ref={inputRef}
          type="tel"
          autoFocus
          {...props}
        />
        {value && onBackspaceClick && (
          <IconButton
            colorScheme="black"
            onClick={handleBackspaceClick}
            disabled={props.disabled}
            className={styles.backspaceButton}
          >
            <Backspace
              width={22}
              height={22}
            />
          </IconButton>
        )}
      </div>
    );
  },
);

export default PhoneNumberInput;
