import classNames from 'classnames';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { EmailOptionType } from 'types';

import { DropdownEmailTypesProps } from '../../types';
import DropdownDefaultForm from '../DropdownDefaultForm';

const DropdownEmailTypes = forwardRef<HTMLUListElement, DropdownEmailTypesProps>(
  ({ onSelect, selectedValue = '', className = '', propTestIds }, ref) => {
    const intl = useIntl();
    const options = Object.values(EmailOptionType).map((value) => {
      const translation = intl.formatMessage({ id: `Universal.EmailTypes.${value}` });
      return {
        label: translation,
        value: translation,
      };
    });

    return (
      <DropdownDefaultForm
        className={classNames(className)}
        selectedValue={selectedValue}
        ref={ref}
        options={options}
        onSelect={onSelect}
        propTestIds={propTestIds}
      />
    );
  },
);

export default DropdownEmailTypes;
