import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { getCurrentTimeWithMilliseconds } from 'helpers';

import { REDUX_ACTION_TYPES, VoicemailsActionTypes, VoicemailsReduxState } from '../../types';

export const voicemailsInitialState: VoicemailsReduxState = {
  allLoaded: false,
  data: [],
  searchString: '',
  // { categoryId: [voicemail, voicemail, ...] }
  queue: {},
  activeVoicemailId: '',
  statuses: {
    fetchVoicemails: Status.IDLE,
    deleteVoicemails: Status.IDLE,
  },
};

export const voicemailsReducer: Reducer<VoicemailsReduxState, VoicemailsActionTypes> = (state, action) => {
  const reducerState: VoicemailsReduxState = state || voicemailsInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.VOICEMAILS_PREPEND: {
      const { voicemail } = action;
      return {
        ...reducerState,
        data: [voicemail, ...reducerState.data],
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_ADD_TO_QUEUE: {
      const { voicemail } = action;
      const { categoryId } = voicemail;

      if (!categoryId) {
        return reducerState;
      }

      return {
        ...reducerState,
        queue: {
          ...reducerState.queue,
          [categoryId]: [...(reducerState.queue[categoryId] || []), voicemail],
        },
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_APPEND: {
      const { voicemailList, isAllLoaded } = action;
      const lastVoicemail = voicemailList[voicemailList.length - 1];
      const offset = voicemailList.length ? lastVoicemail.id : reducerState.offset;

      return {
        ...reducerState,
        offset,
        allLoaded: isAllLoaded ?? reducerState.allLoaded,
        data: [...reducerState.data, ...voicemailList],
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_CLEAR_SEARCH_STRING: {
      const { searchString } = voicemailsInitialState;

      return {
        ...reducerState,
        searchString,
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_MARK_AS_LISTENED: {
      const { voicemailIdList } = action;
      return {
        ...reducerState,
        data: reducerState.data.map((voicemail) => {
          if (voicemailIdList.includes(voicemail.id)) {
            return { ...voicemail, listened: getCurrentTimeWithMilliseconds() };
          }
          return voicemail;
        }),
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_RELEASE_FROM_QUEUE: {
      const { categoryId } = action;

      if (!reducerState.queue[categoryId]) {
        return reducerState;
      }

      return {
        ...reducerState,
        data: [...reducerState.queue[categoryId], ...reducerState.data],
        queue: Object.keys(reducerState.queue)
          .filter((queuedCategoryId) => queuedCategoryId !== categoryId)
          .reduce(
            (queue, queuedCategoryId) => ({
              ...queue,
              [queuedCategoryId]: reducerState.queue[queuedCategoryId],
            }),
            {},
          ),
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_SET_SEARCH_STRING: {
      const { searchString } = action;
      return {
        ...reducerState,
        searchString,
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_DELETE: {
      return {
        ...reducerState,
        data: reducerState.data.filter(({ id }) => id && !action.voicemailIds.includes(id)),
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_SET_ACTIVE_VOICEMAIL_ID: {
      const { activeVoicemailId } = action;
      return {
        ...reducerState,
        activeVoicemailId,
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_SET_STATUS_FETCH_VOICEMAILS: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchVoicemails: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.VOICEMAILS_SET_STATUS_DELETE_VOICEMAILS: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          deleteVoicemails: action.status,
        },
      };
    }

    default: {
      return reducerState;
    }
  }
};
