import { useCallback } from 'react';

import {
  useWebAppDispatch,
  sipSetDataCallStatusWebRTCByWebRTCIdHandler,
  sipSetDataCallStatusFeatureMuteByWebRTCIdHandler,
  sipSetDataCallStatusFeatureUnMuteByWebRTCIdHandler,
  sipSetDataCallStatusFeatureHoldByWebRTCIdHandler,
  sipSetDataCallStatusFeatureUnHoldByWebRTCIdHandler,
  sipSetDataCallStatusFeatureDTMFByWebRTCIdHandler,
  sipSetDataCallStatusViewByWebRTCIdHandler,
  sipSetDataCallStatusRingtoneByWebRTCIdHandler,
  sipSetDataCallValueWebRTCDTMFByWebRTCIdHandler,
  sipRemoveDataCallHandler,
} from '@redux';
import {
  SIPDataCallStatusesWebRTC,
  SIPDataCallStatusesFeatureMute,
  SIPDataCallStatusesFeatureUnMute,
  SIPDataCallStatusesFeatureHold,
  SIPDataCallStatusesFeatureUnHold,
  SIPDataCallStatusesFeatureDTMF,
} from '@redux/types';

import {
  UseSIPCallMethodsOngoingReturn,
  SIPCallMethodAnswerProps,
  SIPCallMethodRejectProps,
  SIPCallMethodTerminateProps,
  SIPCallMethodMuteProps,
  SIPCallMethodUnMuteProps,
  SIPCallMethodHoldProps,
  SIPCallMethodUnHoldProps,
  SIPCallMethodSendDTMFProps,
  SIPCallMethodChangeViewProps,
  SIPCallMethodChangeRingtoneProps,
  SIPCallMethodDismissProps,
} from '../../../types';

export const useSIPCallMethodsOngoing = (): UseSIPCallMethodsOngoingReturn => {
  const dispatch = useWebAppDispatch();

  const sipCallAnswer = useCallback(
    ({ idWebRTC }: SIPCallMethodAnswerProps): void => {
      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusWebRTC: SIPDataCallStatusesWebRTC.ANSWER,
        }),
      );
    },
    [dispatch],
  );

  const sipCallReject = useCallback(
    ({ idWebRTC }: SIPCallMethodRejectProps): void => {
      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusWebRTC: SIPDataCallStatusesWebRTC.REJECT,
        }),
      );
    },
    [dispatch],
  );

  const sipCallTerminate = useCallback(
    ({ idWebRTC }: SIPCallMethodTerminateProps): void => {
      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusWebRTC: SIPDataCallStatusesWebRTC.TERMINATE,
        }),
      );
    },
    [dispatch],
  );

  const sipCallMute = useCallback(
    ({ idWebRTC }: SIPCallMethodMuteProps): void => {
      dispatch(
        sipSetDataCallStatusFeatureMuteByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusFeatureMute: SIPDataCallStatusesFeatureMute.MUTE,
        }),
      );
    },
    [dispatch],
  );

  const sipCallUnMute = useCallback(
    ({ idWebRTC }: SIPCallMethodUnMuteProps): void => {
      dispatch(
        sipSetDataCallStatusFeatureUnMuteByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusFeatureUnMute: SIPDataCallStatusesFeatureUnMute.UNMUTE,
        }),
      );
    },
    [dispatch],
  );

  const sipCallHold = useCallback(
    ({ idWebRTC }: SIPCallMethodHoldProps): void => {
      dispatch(
        sipSetDataCallStatusFeatureHoldByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusFeatureHold: SIPDataCallStatusesFeatureHold.HOLD,
        }),
      );
    },
    [dispatch],
  );

  const sipCallUnHold = useCallback(
    ({ idWebRTC }: SIPCallMethodUnHoldProps): void => {
      dispatch(
        sipSetDataCallStatusFeatureUnHoldByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusFeatureUnHold: SIPDataCallStatusesFeatureUnHold.UNHOLD,
        }),
      );
    },
    [dispatch],
  );

  const sipCallSendDTMF = useCallback(
    ({ idWebRTC, valueWebRTCDTMF }: SIPCallMethodSendDTMFProps): void => {
      dispatch(
        sipSetDataCallStatusFeatureDTMFByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusFeatureDTMF: SIPDataCallStatusesFeatureDTMF.SEND,
        }),
      );
      dispatch(
        sipSetDataCallValueWebRTCDTMFByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallValueWebRTCDTMF: valueWebRTCDTMF,
        }),
      );
    },
    [dispatch],
  );

  const sipCallChangeView = useCallback(
    ({ idWebRTC, statusView }: SIPCallMethodChangeViewProps): void => {
      dispatch(
        sipSetDataCallStatusViewByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusView: statusView,
        }),
      );
    },
    [dispatch],
  );

  const sipCallChangeRingtone = useCallback(
    ({ idWebRTC, statusRingtone }: SIPCallMethodChangeRingtoneProps): void => {
      dispatch(
        sipSetDataCallStatusRingtoneByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusRingtone: statusRingtone,
        }),
      );
    },
    [dispatch],
  );

  const sipCallDismiss = useCallback(
    ({ idWebRTC }: SIPCallMethodDismissProps): void => {
      dispatch(
        sipRemoveDataCallHandler({
          propCallIdWebRTC: idWebRTC,
        }),
      );
    },
    [dispatch],
  );

  return {
    sipCallAnswer,
    sipCallReject,
    sipCallTerminate,
    sipCallMute,
    sipCallUnMute,
    sipCallHold,
    sipCallUnHold,
    sipCallSendDTMF,
    sipCallChangeView,
    sipCallChangeRingtone,
    sipCallDismiss,
  };
};
