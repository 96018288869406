import React, { useCallback, useEffect, useRef } from 'react';

import { BrowserPermissionCanPlayAudioSource, BrowserPermissionCanPlayAudioStatus } from 'types';

import { isErrorObject } from 'helpers';

import {
  browserPermissionsSetPlayAudioPermission,
  selectBrowserPermissionCanPlayAudioStatus,
  selectSIPDataCallsCapabilities,
  selectSIPDataCallsRingtoneAvailability,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { SIPStatisticsScopes } from '@redux/types';

import { useSIPStatistics } from '../../../../hooks/useSIPStatistics';

import styles from './SIPCallsIncomingRingtone.module.scss';

const SIPCallsIncomingRingtone: React.FC = () => {
  const dispatch = useWebAppDispatch();
  const refRingtoneAudioElement = useRef<HTMLAudioElement>(null);

  const { sipSendErrorToSentry } = useSIPStatistics();

  const { canRingtonePlay } = useWebAppSelector(selectSIPDataCallsRingtoneAvailability);
  const { isCallsIncomingRingtoneDisabled } = useWebAppSelector(selectSIPDataCallsCapabilities);
  const { status } = useWebAppSelector(selectBrowserPermissionCanPlayAudioStatus);

  const isRingtoneAvailable = canRingtonePlay && !isCallsIncomingRingtoneDisabled;

  const callbackSIPConnectRingtoneHandler = useCallback(async () => {
    if (isRingtoneAvailable) {
      try {
        await refRingtoneAudioElement?.current?.play();
      } catch (err) {
        // https://developer.chrome.com/blog/autoplay/

        const showPrompt =
          isErrorObject(err) && err.name === 'NotAllowedError' && status === BrowserPermissionCanPlayAudioStatus.IDLE;

        if (showPrompt) {
          dispatch(
            browserPermissionsSetPlayAudioPermission({
              status: BrowserPermissionCanPlayAudioStatus.PROMPT,
              source: BrowserPermissionCanPlayAudioSource.INCOMING_CALL,
            }),
          );
        }

        let errorDescription = 'Playing incoming call ringtone failed';

        if (isErrorObject(err) && (err.message || err.name)) {
          errorDescription = err.message || err.name;
        }

        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_RINGTONE,
          error: errorDescription,
          extras: {
            showPrompt,
            errorName: isErrorObject(err) ? err.name : undefined,
          },
        });
      }
    }
  }, [dispatch, isRingtoneAvailable, sipSendErrorToSentry, status]);

  useEffect(() => {
    callbackSIPConnectRingtoneHandler();
  }, [callbackSIPConnectRingtoneHandler, isRingtoneAvailable]);

  if (!isRingtoneAvailable) {
    return null;
  }

  return (
    <audio
      ref={refRingtoneAudioElement}
      className={styles.root}
      preload="auto"
      autoPlay
      loop
    >
      <source
        src="/assets/audios/calls_incoming_ringtone_192kbps.mp3"
        type="audio/mpeg"
      />
    </audio>
  );
};

export default SIPCallsIncomingRingtone;
