import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import {
  addIntegrationDefaultStatuses,
  appendExtraPropsToIntegrationContacts,
  mapIntegrationContactsDefaultValues,
  removeIntegrationsDetailsByIntegrationId,
} from 'helpers';

import { REDUX_ACTION_TYPES, IntegrationsReduxState, IntegrationsActionTypes } from '../../types';

export const integrationsInitialState: IntegrationsReduxState = {
  statuses: {
    fetchActiveIntegrations: Status.IDLE,
    fetchIntegrationContactDetails: {},
    fetchIntegrationBasicSearch: Status.IDLE,
    fetchIntegrationBasicSearchByEach: {},
    exportContact: {},
  },
  activeIntegrations: [],
  details: {},
  contacts: {},
  activeId: '',
};

export const integrationsReducer: Reducer<IntegrationsReduxState, IntegrationsActionTypes> = (state, action) => {
  const reducerState: IntegrationsReduxState = state ?? integrationsInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.INTEGRATIONS_SET_ACTIVE_INTEGRATIONS: {
      const activeIntegrationsDefaultStatusesMap = addIntegrationDefaultStatuses(action.integrations);
      const integrationsDefaultContacts = mapIntegrationContactsDefaultValues(action.integrations);

      return {
        ...reducerState,
        activeIntegrations: action.integrations,
        statuses: {
          ...reducerState.statuses,
          fetchIntegrationContactDetails: activeIntegrationsDefaultStatusesMap,
          exportContact: activeIntegrationsDefaultStatusesMap,
        },
        contacts: { ...integrationsDefaultContacts },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_FETCH_ACTIVE_INTEGRATIONS: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchActiveIntegrations: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_SET_CONTACT_DETAILS: {
      const contactIntegrationsDetails = {
        [action.contactId]: { ...reducerState.details[action.contactId], ...action.detail },
      };
      return {
        ...reducerState,
        details: {
          ...reducerState.details,
          ...contactIntegrationsDetails,
        },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_FETCH_INTEGRATION_CONTACT_DETAILS: {
      const initialFetchIntegrationContactDetails = { ...reducerState.statuses.fetchIntegrationContactDetails };
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchIntegrationContactDetails: {
            ...initialFetchIntegrationContactDetails,
            [action.integrationName]: action.status,
          },
        },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_EXPORT_CONTACT: {
      const initialExportContactStatus = { ...reducerState.statuses.exportContact };
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          exportContact: {
            ...initialExportContactStatus,
            [action.integrationName]: action.status,
          },
        },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_RESET_STATUSES_EXPORT_CONTACT: {
      const exportContactNewStatuses = addIntegrationDefaultStatuses(reducerState.activeIntegrations);

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          exportContact: exportContactNewStatuses,
        },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_RESET_DETAILS: {
      const details = removeIntegrationsDetailsByIntegrationId(action.integrationIds, reducerState.details);
      return {
        ...reducerState,
        details,
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_FETCH_BASIC_SEARCH: {
      const { fetchIntegrationBasicSearch } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchIntegrationBasicSearch,
        },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_FETCH_BASIC_SEARCH_BY_EACH: {
      const initialBasicSearchContactState = { ...reducerState.statuses.fetchIntegrationBasicSearchByEach };
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchIntegrationBasicSearchByEach: {
            ...initialBasicSearchContactState,
            [action.integrationName]: action.status,
          },
        },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_SET_BASIC_SEARCH_CONTACTS: {
      const integrationContacts = appendExtraPropsToIntegrationContacts(action.integrationContacts);
      return {
        ...reducerState,
        contacts: {
          ...reducerState.contacts,
          [action.integrationName]: integrationContacts,
        },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_RESET_BASIC_SEARCH_CONTACTS: {
      const integrationsDefaultContacts = mapIntegrationContactsDefaultValues(reducerState.activeIntegrations);

      return {
        ...reducerState,
        contacts: { ...integrationsDefaultContacts },
      };
    }

    case REDUX_ACTION_TYPES.INTEGRATIONS_SET_ACTIVE_ID: {
      return {
        ...reducerState,
        activeId: action.activeId,
      };
    }

    default: {
      return reducerState;
    }
  }
};
