import { v4 as uuidv4 } from 'uuid';

import { showErrorToast } from '@onoff/toast-notification';

import { GAEventKey, Status } from 'types';

import {
  getErrorDataFromUnknownError,
  getSIPCallsCapabilitiesAdvancedMediaConstraintsEnabledFromLocalStorage,
  getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage,
  getSIPCallsCapabilitiesIncomingRingtoneDisabledFromLocalStorage,
  getSIPConfigStunRecordsByPriority,
  getSIPConfigWSSRecordsUrlsByPortAndPriority,
  getSIPWSSAvailableRecordUrl,
  isApiError,
  mapSIPWSSRecordsByUnavailableUrl,
  normalizePhoneNumber,
  removeWhiteSpaces,
} from 'helpers';

import { SIP } from '@constants';
import { getIntl } from '@intl';

import { checkSIPAvailability } from '../../../sip/helpers';
import {
  selectCategoryByPhoneNumber,
  selectSIPDataCalls,
  selectSIPDataCallsIncomingCallPubNubPushCapabilities,
  selectSIPDataConnect,
  selectSIPDataWssRecords,
  selectUserId,
} from '../../selectors';
import {
  SIPCallInitializationErrorHandlerProps,
  SIPCallInitializationUserDataHandlerProps,
  SIPCallInitializationWssAndStunHandlerProps,
  SIPCancelCallRecordingHandlerProps,
  SIPDataCall,
  SIPDataCallStatusesCallInitialising,
  SIPDataCallStatusesCallRecording,
  SIPDataCallStatusesFeatureDTMF,
  SIPDataCallStatusesFeatureHold,
  SIPDataCallStatusesFeatureMute,
  SIPDataCallStatusesFeatureUnHold,
  SIPDataCallStatusesFeatureUnMute,
  SIPDataCallStatusesRingtone,
  SIPDataCallStatusesView,
  SIPDataCallStatusesWebRTC,
  SIPDataConfigWssSrvRecord,
  SIPDataWssRecord,
  SIPInitiateCallTransferHandlerProps,
  SIPPrepareOutgoingCallHandlerProps,
  SIPPrepareOutgoingCallRequestHandlerProps,
  SIPPrepareOutgoingCallRequestHandlerReturn,
  SipPubNubInitiateIncomingCallHandlerProps,
  SIPRemoveDataCallHandlerProps,
  SIPSetCallInitializationDataHandlerProps,
  SIPSetDataCallHandlerProps,
  SipSetDataCallsStatisticsEstablishmentByWebRTCIdHandlerProps,
  SIPSetDataCallStatusCallRecordingByTransactionIdHandlerProps,
  SIPSetDataCallStatusFeatureDTMFByWebRTCIdHandlerProps,
  SIPSetDataCallStatusFeatureHoldByWebRTCIdHandlerProps,
  SIPSetDataCallStatusFeatureMuteByWebRTCIdHandlerProps,
  SIPSetDataCallStatusFeatureUnHoldByWebRTCIdHandlerProps,
  SIPSetDataCallStatusFeatureUnMuteByWebRTCIdHandlerProps,
  SIPSetDataCallStatusRingtoneByWebRTCIdHandlerProps,
  SIPSetDataCallStatusViewByWebRTCIdHandlerProps,
  SIPSetDataCallStatusWebRTCByWebRTCIdHandlerProps,
  SIPSetDataCallTimestampAnsweredByWebRTCIdHandlerProps,
  SIPSetDataCallTimestampCreatedByWebRTCIdHandlerProps,
  SIPSetDataCallTimestampRangByWebRTCIdHandlerProps,
  SIPSetDataCallTimestampTerminatedByWebRTCIdHandlerProps,
  SIPSetDataCallValueWebRTCDTMFByWebRTCIdHandlerProps,
  SIPSetDataCallWebRTCIdByNewWebRTCSessionHandlerProps,
  SIPStatisticsScopes,
  SIPWebRTCDirections,
  SIPWebRTCOriginators,
  ThunkResult,
} from '../../types';

import { getDataCallInitialisationDone, getDataCallInitialising } from './helpers';
import {
  sipClearDataStartCall,
  sipRemoveDataCallByInitialisingId,
  sipSetDataCall,
  sipSetDataCalls,
  sipSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabled,
  sipSetDataCallsCapabilitiesHdCodecEnabled,
  sipSetDataCallsCapabilitiesIncomingRingtoneDisabled,
  sipSetDataConfig,
  sipSetDataConnect,
  sipSetDataStartCallIncoming,
  sipSetDataStartCallOutgoing,
  sipSetDataStunRecords,
  sipSetDataWssRecords,
  sipSetDataWssUnavailableUrlOrEmpty,
  sipSetStatusCallInitialization,
  sipSetStatusCancelCallRecording,
  sipSetStatusInitiateCallTransfer,
  sipUpdateDataCallByInitialisingId,
} from './setters';

export const sipSetDataCallsCapabilitiesIncomingRingtoneDisabledHandler = (): ThunkResult<void> => (dispatch) => {
  const isDisabled = getSIPCallsCapabilitiesIncomingRingtoneDisabledFromLocalStorage();
  dispatch(sipSetDataCallsCapabilitiesIncomingRingtoneDisabled(isDisabled));
};

export const sipSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabledHandler =
  (): ThunkResult<void> => (dispatch) => {
    const isEnabled = getSIPCallsCapabilitiesAdvancedMediaConstraintsEnabledFromLocalStorage();
    dispatch(sipSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabled(isEnabled));
  };

export const sipSetDataCallsCapabilitiesHdCodecEnabledHandler = (): ThunkResult<void> => (dispatch) => {
  const isEnabled = getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage();
  dispatch(sipSetDataCallsCapabilitiesHdCodecEnabled(isEnabled));
};

export const sipResetDataStartCallIncomingHandler = (): ThunkResult<void> => async (dispatch) => {
  dispatch(
    sipSetDataStartCallIncoming({
      incomingPhoneNumberLocal: '',
      incomingPhoneNumberRemote: '',
      incomingTransactionID: '',
      incomingCategoryID: '',
      incomingCallIdentifier: '',
      incomingInstanceId: '',
    }),
  );
};

export const sipResetDataStartCallOutgoingHandler = (): ThunkResult<void> => async (dispatch) => {
  dispatch(
    sipSetDataStartCallOutgoing({
      outgoingPhoneNumberLocal: '',
      outgoingPhoneNumberRemote: '',
      outgoingTransactionID: '',
      outgoingCategoryID: '',
      outgoingInstanceId: '',
      outgoingCallIdentifier: '',
    }),
  );
};

export const sipSetDataCallHandler =
  ({ propDataCall }: SIPSetDataCallHandlerProps): ThunkResult<void> =>
  (dispatch, getState): void => {
    const { ids: propIds } = propDataCall;
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const isDataCallCanNotBeAdded =
      dataSIPCalls.find(
        ({ ids: dataIds }) =>
          (dataIds.call.length > 0 && dataIds.call === propIds.call) ||
          (dataIds.transaction.length > 0 && dataIds.transaction === propIds.transaction) ||
          (dataIds.webRTC.length > 0 && dataIds.webRTC === propIds.webRTC),
      ) !== undefined;

    if (isDataCallCanNotBeAdded) {
      return;
    }

    dispatch(sipSetDataCall(propDataCall));
  };

export const sipRemoveDataCallHandler =
  ({ propCallIdWebRTC }: SIPRemoveDataCallHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());
    const newDataSIPCalls = dataSIPCalls.filter(({ ids: { webRTC } }) => webRTC !== propCallIdWebRTC);

    dispatch(sipSetDataCalls(newDataSIPCalls));
  };

export const sipSetDataWssUnavailableUrlOrEmptyHandler =
  (wssUnavailableUrl: SIPDataWssRecord['wssUrl']): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPWssRecords } = selectSIPDataWssRecords(getState());
    const { wssRecordsByAvailability } = mapSIPWSSRecordsByUnavailableUrl({
      wssRecords: dataSIPWssRecords,
      wssUnavailableUrl,
      wssAllowedAttemptCount: SIP.CONFIGURATIONS.WSS_ALLOWED_CONNECT_ATTEMPT_COUNT,
    });
    const { wssAvailableUrlOrEmpty } = getSIPWSSAvailableRecordUrl({ wssRecords: wssRecordsByAvailability });
    dispatch(sipSetDataWssRecords(wssRecordsByAvailability));
    dispatch(sipSetDataWssUnavailableUrlOrEmpty(wssAvailableUrlOrEmpty));
  };

export const sipSetDataWssRecordsHandler =
  (wssRecords: SIPDataWssRecord[]): ThunkResult<void> =>
  (dispatch) => {
    const { wssAvailableUrlOrEmpty } = getSIPWSSAvailableRecordUrl({ wssRecords });
    dispatch(sipSetDataWssRecords(wssRecords));
    dispatch(sipSetDataWssUnavailableUrlOrEmpty(wssAvailableUrlOrEmpty));
  };

export const sipSetDataCallWebRTCIdByNewWebRTCSessionHandler =
  ({
    propCallIdWebRTC,
    propCallIdTransaction,
  }: SIPSetDataCallWebRTCIdByNewWebRTCSessionHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithWebRTCIds: SIPDataCall[] = dataSIPCalls.map((call) => {
      const {
        ids: { webRTC, transaction },
      } = call;

      if (webRTC.length === 0 && transaction === propCallIdTransaction) {
        return {
          ...call,
          ids: {
            ...call.ids,
            webRTC: propCallIdWebRTC,
          },
        };
      }
      return call;
    });
    dispatch(sipSetDataCalls(dataCallsWithWebRTCIds));
  };

export const sipSetDataCallStatusWebRTCByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallStatusWebRTC,
  }: SIPSetDataCallStatusWebRTCByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithNewStatuses: SIPDataCall[] = dataSIPCalls.map((call) => {
      const isThisCall = call.ids.webRTC === propCallIdWebRTC;
      const isThisCallActive = propCallStatusWebRTC === SIPDataCallStatusesWebRTC.ACTIVE;
      const isAnyOtherCallStatusActive = call.statuses.webRTC === SIPDataCallStatusesWebRTC.ACTIVE;

      if (isThisCall) {
        return {
          ...call,
          statuses: {
            ...call.statuses,
            webRTC: propCallStatusWebRTC,
          },
        };
      }

      if (isThisCallActive && isAnyOtherCallStatusActive) {
        return {
          ...call,
          statuses: {
            ...call.statuses,
            webRTC: SIPDataCallStatusesWebRTC.WAITING,
            featureHold: SIPDataCallStatusesFeatureHold.HOLD,
          },
        };
      }

      return call;
    });
    dispatch(sipSetDataCalls(dataCallsWithNewStatuses));
  };

export const sipSetDataCallStatusFeatureMuteByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallStatusFeatureMute,
  }: SIPSetDataCallStatusFeatureMuteByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithNewStatuses: SIPDataCall[] = dataSIPCalls.map((call) => {
      const isThisCall = call.ids.webRTC === propCallIdWebRTC;

      if (isThisCall) {
        const isThisCallMuted = propCallStatusFeatureMute === SIPDataCallStatusesFeatureMute.MUTED;
        const thisCallStatusFeatureUnMute = isThisCallMuted
          ? SIPDataCallStatusesFeatureUnMute.DEFAULT
          : call.statuses.featureUnMute;

        return {
          ...call,
          statuses: {
            ...call.statuses,
            featureMute: propCallStatusFeatureMute,
            featureUnMute: thisCallStatusFeatureUnMute,
          },
        };
      }

      return call;
    });

    dispatch(sipSetDataCalls(dataCallsWithNewStatuses));
  };

export const sipSetDataCallStatusFeatureUnMuteByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallStatusFeatureUnMute,
  }: SIPSetDataCallStatusFeatureUnMuteByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithNewStatuses: SIPDataCall[] = dataSIPCalls.map((call) => {
      const isThisCall = call.ids.webRTC === propCallIdWebRTC;

      if (isThisCall) {
        const isThisCallUnMuted = propCallStatusFeatureUnMute === SIPDataCallStatusesFeatureUnMute.UNMUTED;
        const thisCallStatusFeatureMute = isThisCallUnMuted
          ? SIPDataCallStatusesFeatureMute.DEFAULT
          : call.statuses.featureMute;

        return {
          ...call,
          statuses: {
            ...call.statuses,
            featureMute: thisCallStatusFeatureMute,
            featureUnMute: propCallStatusFeatureUnMute,
          },
        };
      }

      return call;
    });

    dispatch(sipSetDataCalls(dataCallsWithNewStatuses));
  };

export const sipSetDataCallStatusFeatureHoldByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallStatusFeatureHold,
  }: SIPSetDataCallStatusFeatureHoldByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithNewStatuses: SIPDataCall[] = dataSIPCalls.map((call) => {
      const isThisCall = call.ids.webRTC === propCallIdWebRTC;

      if (isThisCall) {
        const isThisCallHeld = propCallStatusFeatureHold === SIPDataCallStatusesFeatureHold.HELD;
        const thisCallStatusWebRTC = isThisCallHeld ? SIPDataCallStatusesWebRTC.WAITING : call.statuses.webRTC;
        const thisCallStatusFeatureUnHold = isThisCallHeld
          ? SIPDataCallStatusesFeatureUnHold.DEFAULT
          : call.statuses.featureUnHold;

        return {
          ...call,
          statuses: {
            ...call.statuses,
            webRTC: thisCallStatusWebRTC,
            featureHold: propCallStatusFeatureHold,
            featureUnHold: thisCallStatusFeatureUnHold,
          },
        };
      }

      return call;
    });

    dispatch(sipSetDataCalls(dataCallsWithNewStatuses));
  };

export const sipSetDataCallStatusFeatureUnHoldByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallStatusFeatureUnHold,
  }: SIPSetDataCallStatusFeatureUnHoldByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithNewStatuses: SIPDataCall[] = dataSIPCalls.map((call) => {
      const isThisCall = call.ids.webRTC === propCallIdWebRTC;
      const isThisCallUnHeld = propCallStatusFeatureUnHold === SIPDataCallStatusesFeatureUnHold.UNHELD;
      const isAnyOtherCallStatusActive = call.statuses.webRTC === SIPDataCallStatusesWebRTC.ACTIVE;

      if (isThisCall) {
        const thisCallStatusWebRTC = isThisCallUnHeld ? SIPDataCallStatusesWebRTC.ACTIVE : call.statuses.webRTC;
        const thisCallStatusFeatureHold = isThisCallUnHeld
          ? SIPDataCallStatusesFeatureHold.DEFAULT
          : call.statuses.featureHold;

        return {
          ...call,
          statuses: {
            ...call.statuses,
            webRTC: thisCallStatusWebRTC,
            featureHold: thisCallStatusFeatureHold,
            featureUnHold: propCallStatusFeatureUnHold,
          },
        };
      }

      if (isThisCallUnHeld && isAnyOtherCallStatusActive) {
        return {
          ...call,
          statuses: {
            ...call.statuses,
            featureHold: SIPDataCallStatusesFeatureHold.HOLD,
          },
        };
      }

      return call;
    });

    dispatch(sipSetDataCalls(dataCallsWithNewStatuses));
  };

export const sipSetDataCallStatusFeatureDTMFByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallStatusFeatureDTMF,
  }: SIPSetDataCallStatusFeatureDTMFByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithNewStatuses: SIPDataCall[] = dataSIPCalls.map((call) => {
      const isThisCall = call.ids.webRTC === propCallIdWebRTC;

      if (isThisCall) {
        return {
          ...call,
          statuses: {
            ...call.statuses,
            featureDTMF: propCallStatusFeatureDTMF,
          },
        };
      }

      return call;
    });

    dispatch(sipSetDataCalls(dataCallsWithNewStatuses));
  };

export const sipSetDataCallStatusViewByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallStatusView,
  }: SIPSetDataCallStatusViewByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithNewStatuses: SIPDataCall[] = dataSIPCalls.map((call) => {
      const isThisCall = call.ids.webRTC === propCallIdWebRTC;

      if (isThisCall) {
        return {
          ...call,
          statuses: {
            ...call.statuses,
            view: propCallStatusView,
          },
        };
      }

      return call;
    });

    dispatch(sipSetDataCalls(dataCallsWithNewStatuses));
  };

export const sipSetDataCallStatusRingtoneByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallStatusRingtone,
  }: SIPSetDataCallStatusRingtoneByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithNewStatuses: SIPDataCall[] = dataSIPCalls.map((call) => {
      const isThisCall = call.ids.webRTC === propCallIdWebRTC;

      if (isThisCall) {
        return {
          ...call,
          statuses: {
            ...call.statuses,
            ringtone: propCallStatusRingtone,
          },
        };
      }

      return call;
    });

    dispatch(sipSetDataCalls(dataCallsWithNewStatuses));
  };

export const sipSetDataCallStatusCallRecordingByTransactionIdHandler =
  ({
    propCallIdTransaction,
    propCallStatusCallRecording,
  }: SIPSetDataCallStatusCallRecordingByTransactionIdHandlerProps): ThunkResult<void> =>
  (dispatch, getState) => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());

    const dataCallsWithNewStatuses: SIPDataCall[] = dataSIPCalls.map((call) => {
      const isThisCall = call.ids.transaction === propCallIdTransaction;

      if (isThisCall) {
        return {
          ...call,
          statuses: {
            ...call.statuses,
            callRecording: propCallStatusCallRecording,
          },
        };
      }

      return call;
    });

    dispatch(sipSetDataCalls(dataCallsWithNewStatuses));
  };

export const sipSetDataCallTimestampCreatedByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallTimestampCallCreated,
  }: SIPSetDataCallTimestampCreatedByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());
    const newDataSIPCalls: SIPDataCall[] = dataSIPCalls.map((call) => {
      if (call.ids.webRTC === propCallIdWebRTC && call.timestamps.callCreated === 0) {
        return {
          ...call,
          timestamps: {
            ...call.timestamps,
            callCreated: propCallTimestampCallCreated,
          },
        };
      }
      return call;
    });
    dispatch(sipSetDataCalls(newDataSIPCalls));
  };

export const sipSetDataCallTimestampRangByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallTimestampCallRang,
  }: SIPSetDataCallTimestampRangByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());
    const newDataSIPCalls: SIPDataCall[] = dataSIPCalls.map((call) => {
      if (call.ids.webRTC === propCallIdWebRTC && call.timestamps.callRang === 0) {
        return {
          ...call,
          timestamps: {
            ...call.timestamps,
            callRang: propCallTimestampCallRang,
          },
        };
      }
      return call;
    });
    dispatch(sipSetDataCalls(newDataSIPCalls));
  };

export const sipSetDataCallTimestampAnsweredByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallTimestampCallAnswered,
  }: SIPSetDataCallTimestampAnsweredByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());
    const newDataSIPCalls: SIPDataCall[] = dataSIPCalls.map((call) => {
      if (call.ids.webRTC === propCallIdWebRTC && call.timestamps.callAnswered === 0) {
        return {
          ...call,
          timestamps: {
            ...call.timestamps,
            callAnswered: propCallTimestampCallAnswered,
          },
        };
      }
      return call;
    });
    dispatch(sipSetDataCalls(newDataSIPCalls));
  };

export const sipSetDataCallTimestampTerminatedByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallTimestampCallTerminated,
  }: SIPSetDataCallTimestampTerminatedByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());
    const newDataSIPCalls: SIPDataCall[] = dataSIPCalls.map((call) => {
      if (call.ids.webRTC === propCallIdWebRTC && call.timestamps.callTerminated === 0) {
        return {
          ...call,
          timestamps: {
            ...call.timestamps,
            callTerminated: propCallTimestampCallTerminated,
          },
        };
      }
      return call;
    });
    dispatch(sipSetDataCalls(newDataSIPCalls));
  };

export const sipSetDataCallValueWebRTCDTMFByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallValueWebRTCDTMF,
  }: SIPSetDataCallValueWebRTCDTMFByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());
    const newDataSIPCalls: SIPDataCall[] = dataSIPCalls.map((call) => {
      if (call.ids.webRTC === propCallIdWebRTC) {
        return {
          ...call,
          values: {
            ...call.values,
            webRTCDTMF: propCallValueWebRTCDTMF,
          },
        };
      }
      return call;
    });
    dispatch(sipSetDataCalls(newDataSIPCalls));
  };

export const sipSetDataCallsStatisticsEstablishmentByWebRTCIdHandler =
  ({
    propCallIdWebRTC,
    propCallIsEstablishmentSent,
  }: SipSetDataCallsStatisticsEstablishmentByWebRTCIdHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const { dataSIPCalls } = selectSIPDataCalls(getState());
    const newDataSIPCalls: SIPDataCall[] = dataSIPCalls.map((call) => {
      if (call.ids.webRTC === propCallIdWebRTC) {
        return {
          ...call,
          statistics: {
            ...call.statistics,
            isEstablishmentSent: propCallIsEstablishmentSent,
          },
        };
      }
      return call;
    });
    dispatch(sipSetDataCalls(newDataSIPCalls));
  };

export const sipCallInitializationErrorHandler =
  ({ error, scope, callOriginator, errorTitle }: SIPCallInitializationErrorHandlerProps): ThunkResult<void> =>
  (dispatch, _getState, services): void => {
    const isAPIError = isApiError(error);
    const errorMessageAPI = isAPIError ? error.description || error.code : '';
    const errorMessageUnknown = error instanceof Error ? error.message : error;

    if (!isAPIError) {
      services.sentryService.captureException({
        error: `${errorTitle}: "${errorMessageUnknown}"`,
        scope: {
          scopeKey: SIPStatisticsScopes.SIP,
          scopeValue: scope,
        },
      });
    }

    services.analyticsService.pushToDataLayer({
      event: GAEventKey.CALL_ERRORS,
      variables: {
        sipCallCase: scope,
        sipCallOriginator: callOriginator,
        sipCallTitle: errorTitle,
        sipCallDescription: `${errorMessageAPI || errorMessageUnknown}`,
        sipCallTransactionId: '',
      },
    });
  };

export const sipFilterDataCallsBySIPDisconnectionHandler = (): ThunkResult<void> => (dispatch, getState) => {
  const { dataSIPCalls } = selectSIPDataCalls(getState());
  const newDataSIPCalls: SIPDataCall[] = dataSIPCalls.filter(
    ({ statuses: { webRTC } }) =>
      ![SIPDataCallStatusesWebRTC.PUSH_INCOMING, SIPDataCallStatusesWebRTC.PUSH_OUTGOING].includes(webRTC),
  );

  dispatch(sipSetDataCalls(newDataSIPCalls));
};

export const sipCallInitializationUserDataHandler =
  ({
    phoneNumberLocalNormalized,
    phoneNumberRemoteNormalized,
    callOriginator,
  }: SIPCallInitializationUserDataHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      const { dataSIPConnect } = selectSIPDataConnect(getState());
      let userIpAddress = dataSIPConnect.ipAddress;

      const userId = selectUserId(getState()) || '';

      const { categoryByPhoneNumber: { id: categoryId = '' } = {} } = selectCategoryByPhoneNumber(
        phoneNumberLocalNormalized,
      )(getState());

      const hasDataError = [
        userId.length,
        phoneNumberLocalNormalized.length,
        phoneNumberRemoteNormalized.length,
        categoryId.length,
      ].includes(0);

      if (hasDataError) {
        throw new Error(
          `Dev Error: (User ID || Phone Numbers || Category ID) Something went wrong with the necessary Redux State Data.`,
        );
      }

      if (!userIpAddress) {
        const { ip: ipAddress = '' } = await services.userService.fetchUserGeoLocation();
        userIpAddress = ipAddress;
        if (ipAddress.length === 0) {
          throw new Error('Dev Error: (User IP Address) Something went wrong during the fetch User Geo Info.');
        }
      }

      dispatch(
        sipSetDataConnect({
          idUser: userId,
          idCategory: categoryId,
          ipAddress: userIpAddress,
        }),
      );
    } catch (error) {
      dispatch(
        sipCallInitializationErrorHandler({
          error,
          scope: SIPStatisticsScopes.SIP_INIT_USER_DATA,
          errorTitle: 'Failed to obtain user data',
          callOriginator,
        }),
      );

      throw error;
    }
  };

export const sipCheckCanIncomingCallBeInitialisedHandler =
  (): ThunkResult<boolean> =>
  (_, getState): boolean => {
    const { canHandleIncomingCallPubNubPush } = selectSIPDataCallsIncomingCallPubNubPushCapabilities(getState());
    // If TRUE => dismiss call, as another call is initialising
    return canHandleIncomingCallPubNubPush;
  };

export const sipPrepareOutgoingCallRequestHandler =
  ({
    categoryLocalId,
    phoneNumberRemoteNormalized,
  }: SIPPrepareOutgoingCallRequestHandlerProps): ThunkResult<Promise<SIPPrepareOutgoingCallRequestHandlerReturn>> =>
  async (dispatch, getState, services): Promise<SIPPrepareOutgoingCallRequestHandlerReturn> => {
    try {
      // SIP Data
      const {
        calleeNumber = '',
        categoryId = '',
        transactionId = '',
        sipConfig,
        sipCredentials,
        callId,
        tsan,
        recording,
      } = await services.voipService.prepareOutgoingCall({
        calleeNumber: phoneNumberRemoteNormalized,
        categoryId: categoryLocalId,
        isVoipCall: true,
      });

      if (!sipConfig || !sipCredentials) {
        throw new Error('Dev Error: (SIP Prepare) No config or credentials');
      }

      const hasSipPrepareError = [calleeNumber.length, categoryId.length, transactionId.length].includes(0);
      if (hasSipPrepareError) {
        throw new Error('Dev Error: (SIP Prepare) Something went wrong during the fetch Prepare Outgoing Call.');
      }

      return {
        calleeNumber,
        categoryId,
        transactionId,
        sipCredentials,
        sipConfig: {
          sipDomain: sipConfig?.domain || '',
          sipTechDomain: sipConfig?.techDomain || '',
          stunSrvRecord: sipConfig?.stun || [],
          wssSrvRecord: sipConfig?.wss || [],
        },
        callId: callId || '',
        tsan: tsan || '',
        recording: recording || false,
      };
    } catch (error) {
      dispatch(
        sipCallInitializationErrorHandler({
          error,
          scope: SIPStatisticsScopes.SIP_PREPARE_OUTGOING_CALL,
          errorTitle: 'Prepare outgoing call failed',
          callOriginator: SIPWebRTCOriginators.LOCAL,
        }),
      );
      throw error;
    }
  };

export const sipCallInitializationWssAndStunHandler =
  ({ wss, stun }: SIPCallInitializationWssAndStunHandlerProps): ThunkResult<void> =>
  (dispatch): void => {
    // throw new Error('Dev Error: No available WSS srv records');
    if (wss.length === 0) {
      throw new Error('Dev Error: No available WSS srv records');
    }

    if (stun.length === 0) {
      throw new Error('Dev Error: No available STUN srv records');
    }

    const { wssRecords } = getSIPConfigWSSRecordsUrlsByPortAndPriority({
      wssConfigRecords: wss as SIPDataConfigWssSrvRecord[],
    });
    dispatch(sipSetDataWssRecordsHandler(wssRecords));

    const { stunRecords } = getSIPConfigStunRecordsByPriority({
      stunConfigRecords: stun,
    });
    dispatch(sipSetDataStunRecords(stunRecords));
  };

export const sipSetCallInitializationDataHandler =
  ({
    direction,
    phoneNumberLocalNormalized,
    phoneNumberRemoteNormalized,
    transactionId,
    callId,
    categoryId,
    sipConfig,
    sipCredentials,
  }: SIPSetCallInitializationDataHandlerProps): ThunkResult<void> =>
  (dispatch): void => {
    const callOriginator =
      direction === SIPWebRTCDirections.INCOMING ? SIPWebRTCOriginators.REMOTE : SIPWebRTCOriginators.LOCAL;

    try {
      const { sipDomain, sipTechDomain, wssSrvRecord, stunSrvRecord } = sipConfig;
      const { sipUsername, sipSecret, instanceId } = sipCredentials;

      if (
        !sipUsername ||
        !sipTechDomain ||
        !wssSrvRecord ||
        !stunSrvRecord ||
        !sipUsername ||
        !instanceId ||
        !sipSecret ||
        !sipDomain
      ) {
        throw new Error('Dev Error: (SIP Config) Some data is missing');
      }

      if (direction === SIPWebRTCDirections.OUTGOING) {
        dispatch(
          sipSetDataStartCallOutgoing({
            outgoingPhoneNumberLocal: phoneNumberLocalNormalized,
            outgoingPhoneNumberRemote: phoneNumberRemoteNormalized,
            outgoingTransactionID: transactionId,
            outgoingCategoryID: categoryId,
            outgoingCallIdentifier: callId,
            outgoingInstanceId: instanceId || '',
          }),
        );
      }

      if (direction === SIPWebRTCDirections.INCOMING) {
        dispatch(
          sipSetDataStartCallIncoming({
            incomingPhoneNumberLocal: phoneNumberLocalNormalized,
            incomingPhoneNumberRemote: phoneNumberRemoteNormalized,
            incomingTransactionID: transactionId,
            incomingCategoryID: categoryId,
            incomingCallIdentifier: callId,
            incomingInstanceId: instanceId,
          }),
        );
      }

      /**
       * WSS and Stun Data to redux
       * * */
      dispatch(
        sipCallInitializationWssAndStunHandler({
          wss: wssSrvRecord as SIPDataConfigWssSrvRecord[],
          stun: stunSrvRecord,
        }),
      );

      /**
       * Set SIP credentials
       * * */

      if (!sipUsername || !sipSecret) {
        throw new Error('Dev Error: (SIP credentials) sipUserName or sipSecret missing.');
      }

      dispatch(
        sipSetDataConfig({
          sipUsername,
          sipSecret,
          sipDomain,
          sipTechDomain,
          wssSrvRecord,
          stunSrvRecord,
        }),
      );
    } catch (error) {
      dispatch(
        sipCallInitializationErrorHandler({
          error,
          scope: SIPStatisticsScopes.SIP_INIT_CALL_DATA,
          errorTitle: `Call initialization data missing`,
          callOriginator,
        }),
      );
      throw error;
    }
  };

export const sipPrepareOutgoingCallHandler =
  ({ propPhoneNumberLocal, propPhoneNumberRemote }: SIPPrepareOutgoingCallHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    dispatch(sipSetStatusCallInitialization(Status.LOADING));

    const initialisingUuid = uuidv4();

    try {
      const phoneNumberLocalNormalized = normalizePhoneNumber(propPhoneNumberLocal);
      const phoneNumberRemoteNormalized = removeWhiteSpaces(propPhoneNumberRemote);

      const { categoryByPhoneNumber: { id: categoryLocalId = '' } = {} } = selectCategoryByPhoneNumber(
        phoneNumberLocalNormalized,
      )(getState());

      const dataCallInitializingCall = getDataCallInitialising({
        categoryLocalId,
        initialisingId: initialisingUuid,
        phoneNumberLocalNormalized,
        formattedCalleeNumber: phoneNumberRemoteNormalized,
      });

      dispatch(sipSetDataCallHandler({ propDataCall: dataCallInitializingCall }));

      await dispatch(
        sipCallInitializationUserDataHandler({
          phoneNumberLocalNormalized,
          phoneNumberRemoteNormalized,
          callOriginator: SIPWebRTCOriginators.LOCAL,
        }),
      );

      const {
        calleeNumber: formattedCalleeNumberFromServer,
        categoryId,
        transactionId,
        sipCredentials,
        sipConfig,
        callId,
        recording: callRecording,
      } = await dispatch(
        sipPrepareOutgoingCallRequestHandler({
          categoryLocalId,
          phoneNumberRemoteNormalized,
        }),
      );

      dispatch(
        sipSetCallInitializationDataHandler({
          direction: SIPWebRTCDirections.OUTGOING,
          phoneNumberRemoteNormalized: formattedCalleeNumberFromServer,
          phoneNumberLocalNormalized,
          sipConfig,
          sipCredentials,
          callId,
          categoryId,
          transactionId,
        }),
      );

      const dataCallOutgoing = getDataCallInitialisationDone({
        callId,
        categoryId,
        transactionId,
        phoneNumberLocalNormalized,
        formattedCalleeNumber: formattedCalleeNumberFromServer,
        callRecording,
      });

      dispatch(sipUpdateDataCallByInitialisingId(initialisingUuid, dataCallOutgoing));

      dispatch(sipSetStatusCallInitialization(Status.SUCCEEDED));
    } catch (error) {
      dispatch(sipRemoveDataCallByInitialisingId(initialisingUuid));

      dispatch(sipSetStatusCallInitialization(Status.FAILED));

      throw error;
    }
  };

export const sipPubNubInitiateIncomingCallHandler =
  ({
    propCallIdentifier,
    propIdCall,
    propIdTransaction,
    propPhoneNumberLocal,
    propPhoneNumberRemote,
    propIsCallRecorded,
    propSipConfig,
    propSipCredentials,
  }: SipPubNubInitiateIncomingCallHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const canInitiateIncomingCall = dispatch(sipCheckCanIncomingCallBeInitialisedHandler());

    if (!canInitiateIncomingCall) {
      return;
    }

    dispatch(sipSetStatusCallInitialization(Status.LOADING));
    dispatch(sipClearDataStartCall());

    try {
      const phoneNumberLocalNormalized = normalizePhoneNumber(propPhoneNumberLocal);
      const phoneNumberRemoteNormalized = removeWhiteSpaces(propPhoneNumberRemote);

      const { categoryByPhoneNumber: { id: idCategoryLocal = '' } = {} } = selectCategoryByPhoneNumber(
        propPhoneNumberLocal,
      )(getState());
      const { isSIPAvailable } = checkSIPAvailability();

      const isSIPHaveIncomingPhoneNumberLocal = propPhoneNumberLocal.length > 0;
      const isSIPHaveIncomingTransactionID = propIdTransaction.length > 0;

      if (
        !idCategoryLocal ||
        !isSIPAvailable ||
        !isSIPHaveIncomingPhoneNumberLocal ||
        !isSIPHaveIncomingTransactionID ||
        !propCallIdentifier ||
        !propIdCall ||
        !propIdTransaction ||
        !propPhoneNumberLocal ||
        !propPhoneNumberRemote
      ) {
        throw new Error('Dev Error: (SIP Config) Some initial data is missing');
      }

      /**
       * Set SIP config & credentials
       * * */

      await dispatch(
        sipSetCallInitializationDataHandler({
          direction: SIPWebRTCDirections.INCOMING,
          phoneNumberRemoteNormalized,
          phoneNumberLocalNormalized,
          sipConfig: propSipConfig,
          sipCredentials: propSipCredentials,
          callId: propCallIdentifier,
          categoryId: idCategoryLocal,
          transactionId: propIdTransaction,
        }),
      );

      /**
       * Clear the Redux Call History if exist (They are on the PUSH state as stuck local data)
       */
      dispatch(sipFilterDataCallsBySIPDisconnectionHandler());

      await dispatch(
        sipCallInitializationUserDataHandler({
          phoneNumberLocalNormalized,
          phoneNumberRemoteNormalized,
          callOriginator: SIPWebRTCOriginators.REMOTE,
        }),
      );

      const dataCallIncoming: SIPDataCall = {
        ids: {
          initialising: '',
          callIdentifier: propCallIdentifier,
          categoryLocal: idCategoryLocal,
          call: propIdCall,
          transaction: propIdTransaction,
          webRTC: '',
        },
        statuses: {
          callInitialising: SIPDataCallStatusesCallInitialising.INITIALISED,
          webRTC: SIPDataCallStatusesWebRTC.PUSH_INCOMING,
          featureMute: SIPDataCallStatusesFeatureMute.DEFAULT,
          featureUnMute: SIPDataCallStatusesFeatureUnMute.UNMUTED,
          featureHold: SIPDataCallStatusesFeatureHold.DEFAULT,
          featureUnHold: SIPDataCallStatusesFeatureUnHold.UNHELD,
          featureDTMF: SIPDataCallStatusesFeatureDTMF.DEFAULT,
          view: SIPDataCallStatusesView.DEFAULT,
          ringtone: SIPDataCallStatusesRingtone.DEFAULT,
          callRecording: propIsCallRecorded
            ? SIPDataCallStatusesCallRecording.ON
            : SIPDataCallStatusesCallRecording.OFF,
        },
        originator: SIPWebRTCOriginators.REMOTE,
        phoneNumbers: {
          local: propPhoneNumberLocal,
          remote: propPhoneNumberRemote,
        },
        timestamps: {
          callCreated: new Date().getTime(),
          callRang: 0,
          callAnswered: 0,
          callTerminated: 0,
        },
        values: {
          webRTCDTMF: '',
        },
        statistics: {
          isEstablishmentSent: false,
        },
      };

      dispatch(sipSetDataCallHandler({ propDataCall: dataCallIncoming }));

      dispatch(sipSetStatusCallInitialization(Status.SUCCEEDED));
    } catch (error) {
      dispatch(sipSetStatusCallInitialization(Status.FAILED));
      const { errorDescription } = getErrorDataFromUnknownError(error);
      showErrorToast({
        message: errorDescription,
      });

      dispatch(sipSetDataCalls([]));
      dispatch(sipResetDataStartCallIncomingHandler());
    }
  };

export const sipInitiateCallTransferHandler =
  ({
    categoryId,
    calleeNumber,
    isWarmTransfer = false,
  }: SIPInitiateCallTransferHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(sipSetStatusInitiateCallTransfer(Status.LOADING));

      await services.voipService.initiateCallTransfer({ categoryId, calleeNumber, isWarmTransfer });

      dispatch(sipSetStatusInitiateCallTransfer(Status.SUCCEEDED));
    } catch (error) {
      dispatch(sipSetStatusInitiateCallTransfer(Status.FAILED));
      throw error;
    }
  };

export const sipCancelCallRecordingHandler =
  ({ transactionId }: SIPCancelCallRecordingHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services) => {
    try {
      dispatch(sipSetStatusCancelCallRecording(Status.LOADING));

      await services.voipService.cancelCallRecording({ transactionId });

      dispatch(
        sipSetDataCallStatusCallRecordingByTransactionIdHandler({
          propCallIdTransaction: transactionId,
          propCallStatusCallRecording: SIPDataCallStatusesCallRecording.CANCELLED,
        }),
      );

      dispatch(sipSetStatusCancelCallRecording(Status.SUCCEEDED));
    } catch {
      dispatch(sipSetStatusCancelCallRecording(Status.FAILED));

      showErrorToast({
        heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_error' }),
        message: getIntl().formatMessage({ id: 'Errors.oops' }),
      });
    }
  };

export * from './setters';
