import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast } from '@onoff/toast-notification';

import { SIP } from '@constants';
import {
  useWebAppDispatch,
  useWebAppSelector,
  sipSetDataWssUnavailableUrlOrEmptyHandler,
  sipSetStatusSIP,
  selectSIPStatuses,
  selectSIPDataStartCall,
  selectSIPDataWssRecords,
  selectSIPDataWssAvailableUrlOrEmpty,
} from '@redux';
import { SIPStatisticsScopes, SIPStatuses, SIPWebRTCOriginators } from '@redux/types';

import { sipDebugConsoleLogger, checkSIPAvailability } from '../../../helpers';
import { UseSIPTryAnotherWssProps } from '../../../types';
import { useSIPStatistics } from '../../useSIPStatistics';

export const useSIPTryAnotherWss = ({ sipStop }: UseSIPTryAnotherWssProps): void => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const { isSIPAvailable } = checkSIPAvailability();
  const { statusSIP } = useWebAppSelector(selectSIPStatuses);
  const { dataSIPWssRecords } = useWebAppSelector(selectSIPDataWssRecords);
  const { dataSIPWssAvailableUrlOrEmpty } = useWebAppSelector(selectSIPDataWssAvailableUrlOrEmpty);
  const {
    dataSIPStartCall: { outgoingTransactionID, incomingTransactionID },
  } = useWebAppSelector(selectSIPDataStartCall);

  const { sipSendErrorToSentry, sipSendErrorToGTM } = useSIPStatistics();

  const callbackSIPTryAnotherWssHandler = (): void => {
    const isSIPTryAnotherWSS = statusSIP === SIPStatuses.TRY_ANOTHER_WSS;
    const isSIPStillHaveAvailableWSS = dataSIPWssAvailableUrlOrEmpty.length > 0;

    if (isSIPAvailable && isSIPTryAnotherWSS && isSIPStillHaveAvailableWSS) {
      sipDebugConsoleLogger('SIP CONNECT: WSS SERVER IS FAILED!', dataSIPWssAvailableUrlOrEmpty);

      dispatch(sipSetStatusSIP(SIPStatuses.TRYING_ANOTHER_WSS));

      dispatch(sipSetDataWssUnavailableUrlOrEmptyHandler(dataSIPWssAvailableUrlOrEmpty));
    }
  };

  const callbackSIPTryingAnotherWssHandler = (): void => {
    const isSIPTryingAnotherWSS = statusSIP === SIPStatuses.TRYING_ANOTHER_WSS;
    const isSIPStillHaveAvailableWSS = dataSIPWssAvailableUrlOrEmpty.length > 0;

    if (isSIPAvailable && isSIPTryingAnotherWSS && isSIPStillHaveAvailableWSS) {
      sipDebugConsoleLogger('SIP CONNECT: TRYING A NEW WSS SERVER!', dataSIPWssAvailableUrlOrEmpty);
      dispatch(sipSetStatusSIP(SIPStatuses.DISCONNECTING));

      sipStop();
    }
  };

  const callbackSIPAllWssAreFailedHandler = (): void => {
    const isSIPTryingAnotherWSS = statusSIP === SIPStatuses.TRYING_ANOTHER_WSS;
    const isSIPHaveWSSRecords = dataSIPWssRecords.length > 0;
    const isSIPDoesNotHaveAnyAvailableWSS = dataSIPWssAvailableUrlOrEmpty.length === 0;

    if (isSIPAvailable && isSIPTryingAnotherWSS && isSIPHaveWSSRecords && isSIPDoesNotHaveAnyAvailableWSS) {
      sipDebugConsoleLogger('SIP CONNECT: ALL WSS SERVERS ARE FAILED!');

      const sipTransactionId = outgoingTransactionID || incomingTransactionID;
      const sipOriginator = outgoingTransactionID.length > 0 ? SIPWebRTCOriginators.LOCAL : SIPWebRTCOriginators.REMOTE;

      dispatch(sipSetStatusSIP(SIPStatuses.FAILED_ALL));

      sipSendErrorToSentry({
        scope: SIPStatisticsScopes.SIP_CONNECT,
        error: 'ALL WSS SERVERS ARE FAILED!',
      });

      sipSendErrorToGTM({
        sipCallCase: SIPStatisticsScopes.SIP_CONNECT,
        sipCallOriginator: sipOriginator,
        sipCallTitle: 'ALL WSS SERVERS ARE FAILED!',
        sipCallDescription: `We tried ${dataSIPWssRecords.length} different WSS URLs ${SIP.CONFIGURATIONS.WSS_ALLOWED_CONNECT_ATTEMPT_COUNT} times by each and all of them failed.`,
        sipCallTransactionId: sipTransactionId,
      });

      // Outgoing Calls => Toast Notification
      if (sipOriginator === SIPWebRTCOriginators.LOCAL) {
        showErrorToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.WssServersAllFailure.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.WssServersAllFailure.description' }),
        });
      }
    }
  };

  useEffect(callbackSIPTryAnotherWssHandler, [isSIPAvailable, statusSIP, dataSIPWssAvailableUrlOrEmpty, dispatch]);

  useEffect(callbackSIPTryingAnotherWssHandler, [
    isSIPAvailable,
    statusSIP,
    dataSIPWssAvailableUrlOrEmpty,
    dispatch,
    sipStop,
  ]);

  useEffect(callbackSIPAllWssAreFailedHandler, [
    isSIPAvailable,
    statusSIP,
    dataSIPWssRecords,
    dataSIPWssAvailableUrlOrEmpty,
    incomingTransactionID,
    outgoingTransactionID,
    intl,
    sipSendErrorToGTM,
    sipSendErrorToSentry,
    dispatch,
  ]);
};
