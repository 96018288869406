import classnames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Add } from '@onoff/icons';
import { showErrorToast } from '@onoff/toast-notification';

import { StyleableComponent } from 'types';

import { checkoutSetCallbackPathToStorage } from 'helpers';

import { PURCHASE } from '@constants';
import { useWebAppSelector, selectCreditCardsDataBySorted } from '@redux';
import { ModalAddCreditCard } from 'components/Modals';
import { ApplicationPaths } from 'routes';

import styles from './CreditCardAddNew.module.scss';

export interface CreditCardAddNewProps extends StyleableComponent {
  callbackRoute: ApplicationPaths;
  hasAddCreditCardLimit?: boolean;
}

const CreditCardAddNew: React.FC<CreditCardAddNewProps> = ({
  callbackRoute,
  hasAddCreditCardLimit = true,
  className = '',
}) => {
  const intl = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const sortedCreditCards = useWebAppSelector(selectCreditCardsDataBySorted);

  const onClickHandlerAddCreditCard = (): void => {
    if (hasAddCreditCardLimit && sortedCreditCards.length >= PURCHASE.CREDIT_CARDS_LIMIT) {
      showErrorToast({
        heading: intl.formatMessage({ id: 'Notifications.Toast.title_error' }),
        message: intl.formatMessage({
          id: 'Purchase.Notifications.error_description_too_many_cards',
        }),
      });
    } else {
      setIsModalOpen(true);
      checkoutSetCallbackPathToStorage(callbackRoute);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={onClickHandlerAddCreditCard}
        className={classnames(styles.root, className)}
      >
        <Add className={styles.icon} />
        <span>
          <FormattedMessage id={'Purchase.CreditCard.button_add_new_card'} />
        </span>
      </button>
      <ModalAddCreditCard
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default CreditCardAddNew;
