import classNames from 'classnames';
import React, { useState } from 'react';

import { PhoneKeyboardSize } from '../../enums';

import styles from './PhoneKeyboardButton.module.scss';

export interface PhoneKeyboardButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  value: string;
  longPressValue?: string;
  longPressThreshold?: number;
  onClick?: (value: string) => void;
  size: PhoneKeyboardSize;
}

const PhoneKeyboardButton: React.FC<PhoneKeyboardButtonProps> = ({
  longPressValue = '',
  longPressThreshold = 500,
  onClick,
  value,
  size,
  ...props
}) => {
  const [isLongPressTriggered, setIsLongPressTriggered] = useState(false);

  let timeout: number;

  const timeoutCallBack = () => {
    if (longPressValue.length > 0 && onClick !== undefined) {
      onClick(longPressValue);
    }
    setIsLongPressTriggered(true);
  };

  const handlePressStart = () => {
    timeout = window.setTimeout(timeoutCallBack, longPressThreshold);
  };

  const handlePressClear = () => {
    if (!isLongPressTriggered && value && onClick !== undefined) {
      onClick(value);
    } else {
      setIsLongPressTriggered(false);
    }
    window.clearTimeout(timeout);
  };

  const handleOnClick = (): void => {
    if (!longPressValue && onClick !== undefined) {
      onClick(value);
    }
  };

  return (
    <button
      type="button"
      className={classNames(styles.root, styles[`size-${size}`])}
      onClick={handleOnClick}
      onMouseDown={longPressValue ? handlePressStart : undefined}
      onMouseUp={longPressValue ? handlePressClear : undefined}
      {...props}
    >
      <div className={styles.content}>
        <span>{value}</span>
        <span className={classNames(styles.contentSubLabel, styles[`size-${size}`])}>{longPressValue}</span>
      </div>
    </button>
  );
};

export default PhoneKeyboardButton;
