import React from 'react';

import { StyleableComponent } from 'types';

const NewTab: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.1 21C4.5168 21 4.0208 20.796 3.612 20.388C3.204 19.9792 3 19.4832 3 18.9V5.1C3 4.5168 3.204 4.0208 3.612 3.612C4.0208 3.204 4.5168 3 5.1 3H11.0496C11.3328 3 11.5788 3.104 11.7876 3.312C11.9956 3.5208 12.0996 3.7668 12.0996 4.05C12.0996 4.3332 11.9956 4.5792 11.7876 4.788C11.5788 4.996 11.3328 5.1 11.0496 5.1H5.1V18.9H18.9V12.9504C18.9 12.6672 19.004 12.4212 19.212 12.2124C19.4208 12.0044 19.6668 11.9004 19.95 11.9004C20.2332 11.9004 20.4792 12.0044 20.688 12.2124C20.896 12.4212 21 12.6672 21 12.9504V18.9C21 19.4832 20.796 19.9792 20.388 20.388C19.9792 20.796 19.4832 21 18.9 21H5.1ZM9 15C8.8 14.8 8.7 14.554 8.7 14.262C8.7 13.9708 8.8 13.7252 9 13.5252L17.4252 5.1H15.2496C14.9664 5.1 14.7208 4.996 14.5128 4.788C14.304 4.5792 14.1996 4.3332 14.1996 4.05C14.1996 3.7668 14.304 3.5208 14.5128 3.312C14.7208 3.104 14.9664 3 15.2496 3H19.95C20.2332 3 20.4792 3.104 20.688 3.312C20.896 3.5208 21 3.7668 21 4.05V8.7504C21 9.0336 20.896 9.2792 20.688 9.4872C20.4792 9.696 20.2332 9.8004 19.95 9.8004C19.6668 9.8004 19.4208 9.696 19.212 9.4872C19.004 9.2792 18.9 9.0336 18.9 8.7504V6.5748L10.4496 15.0252C10.2496 15.2252 10.0124 15.3252 9.738 15.3252C9.4628 15.3252 9.2168 15.2168 9 15Z"
      fill="currentColor"
    />
  </svg>
);

export default NewTab;
