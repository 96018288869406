import {
  useWebAppDispatch,
  messagesPubNubNewMessageHandler,
  messagesPubNubRemoveMessageHandler,
  threadsPubNubDeleteThreadHandler,
  threadsPubNubReadThreadHandler,
} from '@redux';

import {
  UsePubNubMessagesHandlerPageMessagesReturn,
  PubNubPushMessagePageMessagesNewMessagePayload,
  PubNubPushMessagePageMessagesCreateMessagePayload,
  PubNubPushMessagePageMessagesChangeMessagePayload,
  PubNubPushMessagePageMessagesDeleteMessagePayload,
  PubNubPushMessagePageMessagesDeleteThreadPayload,
  PubNubPushMessagePageMessagesReadThreadPayload,
} from '../../types';

export const usePubNubMessagesHandlerPageMessages = (): UsePubNubMessagesHandlerPageMessagesReturn => {
  const dispatch = useWebAppDispatch();

  const pubNubMessageHandlerMessagesNewMessage = (message: PubNubPushMessagePageMessagesNewMessagePayload): void => {
    dispatch(messagesPubNubNewMessageHandler({ message }));
  };

  const pubNubMessageHandlerMessagesCreateMessage = (
    message: PubNubPushMessagePageMessagesCreateMessagePayload,
  ): void => {
    dispatch(messagesPubNubNewMessageHandler({ message }));
  };

  const pubNubMessageHandlerMessagesChangeMessage = (
    message: PubNubPushMessagePageMessagesChangeMessagePayload,
  ): void => {
    dispatch(messagesPubNubNewMessageHandler({ message }));
  };

  const pubNubMessageHandlerMessagesDeleteMessage = (
    messageIds: PubNubPushMessagePageMessagesDeleteMessagePayload,
  ): void => {
    dispatch(messagesPubNubRemoveMessageHandler({ messageIds }));
  };

  const pubNubMessageHandlerMessagesDeleteThread = (
    threadIds: PubNubPushMessagePageMessagesDeleteThreadPayload,
  ): void => {
    dispatch(threadsPubNubDeleteThreadHandler({ threadIds }));
  };

  const pubNubMessageHandlerMessagesReadThread = (threadIds: PubNubPushMessagePageMessagesReadThreadPayload): void => {
    dispatch(threadsPubNubReadThreadHandler({ threadIds }));
  };

  return {
    pubNubMessageHandlerMessagesNewMessage,
    pubNubMessageHandlerMessagesCreateMessage,
    pubNubMessageHandlerMessagesChangeMessage,
    pubNubMessageHandlerMessagesDeleteMessage,
    pubNubMessageHandlerMessagesDeleteThread,
    pubNubMessageHandlerMessagesReadThread,
  };
};
