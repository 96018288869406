import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { EmailOptionType, PhoneOptionType } from 'types';

import { validationSchemas } from 'helpers/validationSchemas';

import { createFormAddContactFormData } from '../helpers';
import { UseAddContactFormOptions, UseAddContactFormResult } from '../types';

export const useAddContactForm = (options: UseAddContactFormOptions): UseAddContactFormResult => {
  const intl = useIntl();
  const {
    register,
    setValue,
    watch,
    trigger,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchemas(intl).addContact),
    defaultValues: createFormAddContactFormData(options.formDataDefault, options.categoryList[0]?.id),
  });

  // for arrays register before
  register('phones');
  register('emails');

  const formData = watch();

  return {
    formData,
    errors,
    onAddPhone: () => {
      setValue(`phones.${formData.phones.length}`, {
        type: intl.formatMessage({ id: `Universal.PhoneTypes.${PhoneOptionType.MOBILE}` }),
        number: '',
        favorite: undefined,
        normalNumber: undefined,
        id: uuidv4(),
      });
    },
    onAddEmail: () => {
      setValue(`emails.${formData.emails.length}`, {
        type: intl.formatMessage({ id: `Universal.EmailTypes.${EmailOptionType.WORK}` }),
        email: '',
        id: uuidv4(),
      });
    },
    onRemovePhone: (phoneIndex) => {
      formData.phones.forEach((_, index) => {
        if (index === phoneIndex) {
          clearErrors(`phones.${index}.number`);
          return;
        }
        if (index > 0 && index > phoneIndex) {
          // move all errors to one index less
          const prevIndex = index - 1;
          const phoneErrorNumber = (errors.phones || [])[index]?.number;
          if (phoneErrorNumber !== undefined) {
            setError(`phones.${prevIndex}.number`, phoneErrorNumber);
            clearErrors(`phones.${index}.number`);
          }
        }
      });
      setValue(
        'phones',
        formData.phones.filter((_, index) => index !== phoneIndex),
      );
    },
    onRemoveEmail: (emailIndex) => {
      formData.emails.forEach((_, index) => {
        if (index === emailIndex) {
          clearErrors(`emails.${index}.email`);
          return;
        }
        if (index > 0 && index > emailIndex) {
          // move all errors to one index less
          const prevIndex = index - 1;
          const emailErrorNumber = (errors.emails || [])[index]?.email;
          if (emailErrorNumber !== undefined) {
            setError(`emails.${prevIndex}.email`, emailErrorNumber);
            clearErrors(`emails.${index}.email`);
          }
        }
      });
      setValue(
        'emails',
        formData.emails.filter((_, index) => index !== emailIndex),
      );
    },
    onChange: (name, value) => {
      register(name);
      setValue(name, value, {
        shouldValidate: true,
      });
    },
    trigger,
    isValid: Object.keys(errors).length === 0,
  };
};
