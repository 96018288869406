import React, { PropsWithChildren, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import { Status } from 'types';

import { intlMessages, intlMessagesFlatten } from '@intl';
import {
  useWebAppDispatch,
  useWebAppSelector,
  userSetLanguageHandler,
  selectUserLanguage,
  selectAuthenticationStatuses,
} from '@redux';

const LanguageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useWebAppDispatch();
  const { statusLogout } = useWebAppSelector(selectAuthenticationStatuses);
  const language = useWebAppSelector(selectUserLanguage);

  const callbackSetUserLanguageOnInitHandler = (): void => {
    dispatch(userSetLanguageHandler());
  };

  const callbackSetUserLanguageOnLogoutHandler = (): void => {
    if (statusLogout === Status.SUCCEEDED) {
      dispatch(userSetLanguageHandler());
    }
  };

  useEffect(callbackSetUserLanguageOnInitHandler, [dispatch]);
  useEffect(callbackSetUserLanguageOnLogoutHandler, [dispatch, statusLogout]);

  // TODO: Technical Depth => "window.serverData" are Legacy => StealthMode.js and so on...
  window.serverData = {
    text: intlMessages[language],
  };

  return (
    <IntlProvider
      locale={language}
      messages={intlMessagesFlatten[language]}
    >
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
