import React from 'react';

import { StyleableComponent } from 'types';

const Devices: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M150 75C150 116.421 116.421 150 75 150C33.5786 150 0 116.421 0 75C0 33.5786 33.5786 0 75 0C116.421 0 150 33.5786 150 75Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M140.526 74.9999C140.526 111.189 111.189 140.526 74.9999 140.526C38.8108 140.526 9.47363 111.189 9.47363 74.9999C9.47363 38.8108 38.8108 9.47363 74.9999 9.47363C111.189 9.47363 140.526 38.8108 140.526 74.9999Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M129.079 74.9998C129.079 104.867 104.867 129.079 75.0001 129.079C45.1331 129.079 20.9211 104.867 20.9211 74.9998C20.9211 45.1329 45.1331 20.9209 75.0001 20.9209C104.867 20.9209 129.079 45.1329 129.079 74.9998Z"
      fill="#F3F4F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M132.057 111.615L25.4054 130.421L13.8826 65.0716C13.333 61.9546 15.4146 58.9821 18.5317 58.4325L113.895 41.6174C117.012 41.0677 119.985 43.149 120.534 46.2661L132.057 111.615Z"
      fill="#E4E5E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7803 134.181L144.234 111.002L143.972 109.513L12.5178 132.692L12.7803 134.181Z"
      fill="#C8CBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1177 134.139C13.0702 134.148 13.0736 134.219 13.1218 134.222C17.846 134.534 22.6533 134.268 27.3877 133.433L130.418 115.266C135.153 114.431 139.761 113.037 144.094 111.128C144.138 111.108 144.117 111.041 144.07 111.049L13.1177 134.139Z"
      fill="#8E929B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5149 126.162L128.879 108.289L117.82 45.5711L16.4561 63.4443L27.5149 126.162Z"
      fill="white"
    />
    <path
      d="M28.0204 96.537C27.9577 96.1819 28.1949 95.8433 28.55 95.7807L118.567 79.9083C118.922 79.8457 119.26 80.0828 119.323 80.4379L120.23 85.5817C120.292 85.9368 120.055 86.2755 119.7 86.3381L29.6837 102.21C29.3286 102.273 28.99 102.036 28.9273 101.681L28.0204 96.537Z"
      fill="#F4F4F4"
    />
    <path
      d="M29.721 106.182C29.6584 105.826 29.8955 105.488 30.2506 105.425L120.267 89.5529C120.622 89.4902 120.961 89.7273 121.024 90.0825L121.931 95.2263C121.993 95.5814 121.756 95.92 121.401 95.9826L31.3844 111.855C31.0293 111.918 30.6906 111.68 30.628 111.325L29.721 106.182Z"
      fill="#F4F4F4"
    />
    <path
      d="M33.4918 25.346C32.9659 22.3636 34.9573 19.5195 37.9398 18.9936L95.9924 8.75734C98.9749 8.23145 101.819 10.2229 102.345 13.2054L115.676 88.8088C116.202 91.7913 114.21 94.6354 111.228 95.1613L53.1751 105.398C50.1927 105.923 47.3486 103.932 46.8227 100.949L33.4918 25.346Z"
      fill="#C8CBCE"
    />
    <path
      d="M35.7317 25.18C35.3814 23.1932 36.7081 21.2985 38.6949 20.9482L96.3029 10.7903C98.2897 10.44 100.184 11.7667 100.535 13.7535L113.787 88.9123C114.138 90.8991 112.811 92.7938 110.824 93.1441L53.2161 103.302C51.2292 103.652 49.3346 102.326 48.9843 100.339L35.7317 25.18Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.3742 79.4299C98.5056 80.1755 98.0078 80.8865 97.2622 81.018L61.18 87.3803C60.4214 87.514 59.7015 86.9967 59.5864 86.2351L58.9768 82.2008C58.8656 81.4652 59.3615 80.7751 60.0942 80.6459L96.0777 74.3011C96.8234 74.1696 97.5344 74.6674 97.6659 75.4131L98.3742 79.4299Z"
      fill="#F4F4F4"
    />
    <path
      d="M59.2793 16.623L75.48 13.7664L75.8182 15.6845C75.8944 16.1164 75.606 16.5283 75.1741 16.6044L60.5375 19.1853C60.1055 19.2614 59.6937 18.973 59.6175 18.5411L59.2793 16.623Z"
      fill="#C8CBCE"
    />
    <mask
      id="mask0_7747_89589"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x={59}
      y={34}
      width={28}
      height={28}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.6892 38.6846L82.6403 34.6377L86.6871 57.5887L63.7361 61.6356L59.6892 38.6846Z"
        fill="#2DD08C"
      />
    </mask>
    <g mask="url(#mask0_7747_89589)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.1615 36.6617C64.8309 37.778 60.5958 43.8262 61.7121 50.1569C62.8284 56.4875 68.8766 60.7226 75.2073 59.6063C81.5379 58.49 85.773 52.4418 84.6567 46.1111C83.5469 39.7793 77.4986 35.5443 71.1615 36.6617ZM79.8746 47.1399C79.6192 48.285 78.9635 49.6398 77.7777 51.3334C76.6142 53.0031 75.5524 54.0849 74.5887 54.7452C73.9928 55.1617 73.3717 55.3972 72.6923 55.4905C71.2829 55.7655 69.824 55.4595 68.6521 54.6389C67.4663 53.8142 66.6733 52.5491 66.4486 51.1242C66.302 50.4808 66.3365 49.812 66.5029 49.1398C66.7571 47.9883 67.4106 46.6206 68.5998 44.9464C69.7845 43.2464 70.8205 42.1691 71.8145 41.53C72.3805 41.132 73.0315 40.878 73.7109 40.7847C75.1086 40.5184 76.5546 40.8267 77.7253 41.6408C78.9036 42.4602 79.6955 43.7189 79.9244 45.1298C80.0679 45.7937 80.0346 46.4689 79.8746 47.1399Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.4391 49.5721C73.3889 49.9336 71.4308 48.5625 71.0692 46.5123C70.7077 44.4621 72.0777 42.4975 74.1279 42.136C76.1782 41.7745 78.1363 43.1456 78.4989 45.2022C78.8604 47.2525 77.4893 49.2106 75.4391 49.5721Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M107.245 20.0293C106.833 17.6969 108.391 15.4728 110.723 15.0615L123.862 12.7448C126.194 12.3336 128.418 13.8909 128.829 16.2233L135.255 52.6672C135.667 54.9995 134.109 57.2237 131.777 57.6349L118.638 59.9516C116.306 60.3629 114.082 58.8055 113.671 56.4731L107.245 20.0293Z"
      fill="#C8CBCE"
    />
    <path
      d="M108.054 20.0477C107.704 18.0608 109.031 16.1662 111.018 15.8159L123.843 13.5543C125.83 13.204 127.725 14.5307 128.075 16.5175L134.446 52.6485C134.796 54.6354 133.47 56.53 131.483 56.8803L118.657 59.1419C116.67 59.4922 114.776 58.1655 114.425 56.1787L108.054 20.0477Z"
      fill="white"
    />
    <path
      d="M112.894 15.4849L121.966 13.8852L122.104 14.6673C122.18 15.0992 121.892 15.5111 121.46 15.5873L113.952 16.9111C113.52 16.9872 113.108 16.6988 113.032 16.2669L112.894 15.4849Z"
      fill="#C8CBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.459 82.3458L142.241 80.5579L140.25 79.7095L142.038 78.4905L142.886 76.5L144.105 78.2883L146.096 79.1363L144.308 80.3548L143.459 82.3458Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2095 25.3458L16.991 23.5579L15 22.7095L16.7879 21.4905L17.6363 19.5L18.8548 21.2883L20.8458 22.1363L19.058 23.3548L18.2095 25.3458Z"
      fill="#E4E4E4"
    />
  </svg>
);

export default Devices;
