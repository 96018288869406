export enum ErrorType {
  ERROR_CHUNK = 'ERROR_CHUNK',
  ERROR_404 = 'ERROR_404',
  ERROR_500 = 'ERROR_500',
  ERROR_PAYMENT = 'ERROR_PAYMENT',
}

export interface ErrorCoreProps {
  type: ErrorType;
  onClick: () => void;
  title?: string;
  description?: string;
  buttonText?: string;
  classNameInfo?: string;
  classNameIllustration?: string;
}

export enum ErrorIllustrationType {
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  DISCONNECT = 'DISCONNECT',
}
