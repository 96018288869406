import classNames from 'classnames';
import React from 'react';

import { useWebAppSelector, selectSIPDataCallsByVisibility } from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';

import SIPCallsIncomingRingtone from '../SIPCallsIncomingRingtone';
import SIPCallsListItem from '../SIPCallsListItem';

import styles from './SIPCallsList.module.scss';

const SIPCallsList: React.FC = () => {
  const { dataSIPCallsByVisibility } = useWebAppSelector(selectSIPDataCallsByVisibility);
  return (
    <div
      className={classNames(styles.root, {
        [styles.visible]: dataSIPCallsByVisibility.length > 0,
      })}
      {...testGetTestingAttributes(testIds.sipCallsListRoot)}
    >
      <SIPCallsIncomingRingtone />

      {dataSIPCallsByVisibility.map((call) => (
        <SIPCallsListItem
          key={call.ids.transaction}
          data={call}
        />
      ))}
    </div>
  );
};

export default SIPCallsList;
