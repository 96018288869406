import {
  Contact,
  ContactsSimpleContactsMap,
  PhoneNumberContactsMap,
  PreparedContact,
  SimpleContact,
  SimpleContactPhone,
} from 'types';

import { normalizeNumber } from '../../parsing';
import { ContactId, getDisplayName } from '../index';

export const mapSimpleContactToContact = <T extends ContactId>(contact: T, key: string): Contact => ({
  ...contact,
  key,
  isFriend: false,
  displayName: getDisplayName(contact),
});

export const constructSimpleContactsMap = (contactsData: SimpleContact[]): ContactsSimpleContactsMap =>
  contactsData.reduce((simpleContactsMapAccumulator, entity) => {
    const simpleContactsMap = { ...simpleContactsMapAccumulator };
    const key = entity.id;

    simpleContactsMap[key] = mapSimpleContactToContact(entity, key);
    return simpleContactsMap;
  }, {});

/**
 * @description: Create a mapping for every phone number and contact, to achieve O(n) lookup
 */
export const constructPhoneNumberContactMap = (contacts: SimpleContact[]): ContactsSimpleContactsMap =>
  contacts.reduce((accumulator: ContactsSimpleContactsMap, contact: SimpleContact) => {
    if (contact.phones) {
      contact.phones.forEach((phone: SimpleContactPhone) => {
        const normalizedPhoneNumber = normalizeNumber(phone.normalNumber || phone.number);

        accumulator[normalizedPhoneNumber] = mapSimpleContactToContact(contact, contact.id);
      });
    }

    return accumulator;
  }, {});

export const constructSimpleContacts = (contactsData: SimpleContact[]): Contact[] =>
  contactsData.map((contact) => mapSimpleContactToContact(contact, contact.id), []);

export const addSimpleContact = (contact: PreparedContact): Contact => mapSimpleContactToContact(contact, contact.id);

export const addPhoneNumberContactMapElement = (
  phoneNumberContactMap: ContactsSimpleContactsMap,
  contact: PreparedContact,
): ContactsSimpleContactsMap => {
  const updatedPhoneNumberContactMap = { ...phoneNumberContactMap };
  const newContact: Contact = mapSimpleContactToContact(contact, contact.id);
  if (contact.phones) {
    contact.phones.forEach((contactPhone) => {
      const key = normalizeNumber(contactPhone.normalNumber || contactPhone.number);
      updatedPhoneNumberContactMap[normalizeNumber(key)] = newContact;
    });
  }

  return updatedPhoneNumberContactMap;
};

export const filteredSimpleContactsByRemaining = (
  simpleContacts: Contact[],
  deletedContactIds: Contact['id'][],
): Array<Contact> => simpleContacts.filter(({ id }) => !deletedContactIds.includes(id));

export const filteredSimpleContactsMapElementsByRemaining = (
  simpleContactsMap: ContactsSimpleContactsMap,
  deletedContactIds: Contact['id'][],
): ContactsSimpleContactsMap =>
  Object.keys(simpleContactsMap)
    .filter((key) => !deletedContactIds.includes(simpleContactsMap[key].id))
    .reduce((accumulator, key) => {
      accumulator[key] = simpleContactsMap[key];
      return accumulator;
    }, {});

export const filteredPhoneNumberContactMapElementByDeletion = (
  phoneNumberContactsMap: PhoneNumberContactsMap,
  deletedContactIds: Contact['id'][],
): ContactsSimpleContactsMap =>
  Object.keys(phoneNumberContactsMap)
    .filter((key) => !deletedContactIds.includes(phoneNumberContactsMap[key].id))
    .reduce((accumulator, key) => {
      accumulator[key] = phoneNumberContactsMap[key];
      return accumulator;
    }, {});

export const updateSimpleContact = (
  simpleContacts: Array<Contact>,
  preparedContact: PreparedContact,
): Array<Contact> => {
  const foundIndex = simpleContacts.findIndex((simpleContact) => simpleContact.id === preparedContact.id);
  if (foundIndex === -1) {
    return simpleContacts;
  }
  const foundContact = simpleContacts[foundIndex];

  const updatedContact = mapSimpleContactToContact(preparedContact, foundContact.key);

  return [...simpleContacts.filter((_, index) => index !== foundIndex), updatedContact];
};

export const updatePhoneNumberContactMap = (
  phoneNumberContactsMap: ContactsSimpleContactsMap,
  updatedContact: PreparedContact,
): PhoneNumberContactsMap => {
  const contactIncludedInMap = Object.values(phoneNumberContactsMap).some(
    (contact) => contact.id === updatedContact.id,
  );

  if (!contactIncludedInMap) {
    return phoneNumberContactsMap;
  }

  const newContact: Contact = mapSimpleContactToContact(updatedContact, updatedContact.id);

  const contactsMapWithoutUpdatedContact: ContactsSimpleContactsMap = Object.keys(phoneNumberContactsMap)
    .filter((key) => phoneNumberContactsMap[key].id !== updatedContact.id)
    .reduce((accumulator, key) => {
      accumulator[key] = phoneNumberContactsMap[key];
      return accumulator;
    }, {});

  if (updatedContact.phones) {
    updatedContact.phones.forEach((contactPhone) => {
      const key = normalizeNumber(contactPhone.normalNumber || contactPhone.number);
      contactsMapWithoutUpdatedContact[key] = newContact;
    });
  }

  return contactsMapWithoutUpdatedContact;
};
