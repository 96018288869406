import { Status } from 'types';

import { selectPushIdPushLast } from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  PushSetStatusGetPushesAction,
  PushSetStatusDeletePushesAction,
  PushSetIdLastPushAction,
  PushSetHasMissingPushesAction,
  PushGetPushesHandlerProps,
  PushDeletePushesHandlerProps,
} from '../../types';

export const pushSetStatusGetPushes = (status: Status): PushSetStatusGetPushesAction => ({
  type: REDUX_ACTION_TYPES.PUSH_SET_STATUS_GET_PUSHES,
  status,
});

export const pushSetStatusDeletePushes = (status: Status): PushSetStatusDeletePushesAction => ({
  type: REDUX_ACTION_TYPES.PUSH_SET_STATUS_DELETE_PUSHES,
  status,
});

export const pushSetIdLastPush = (idPushLast: string): PushSetIdLastPushAction => ({
  type: REDUX_ACTION_TYPES.PUSH_SET_ID_LAST_PUSH,
  idPushLast,
});

export const pushSetHasMissingPushes = (hasMissingPushes: boolean): PushSetHasMissingPushesAction => ({
  type: REDUX_ACTION_TYPES.PUSH_SET_HAS_MISSING_PUSHES,
  hasMissingPushes,
});

export const pushGetPushesHandler =
  ({ isWindowOnline = false }: PushGetPushesHandlerProps = {}): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(pushSetStatusGetPushes(Status.LOADING));

      const { idPushLast } = selectPushIdPushLast(getState());
      const { pushRecords = [], lastId = '' } = await services.pushService.getPushes({ lastId: idPushLast });

      dispatch(pushSetIdLastPush(lastId));

      if (isWindowOnline) {
        const hasMissingPushes = pushRecords.length > 0;
        dispatch(pushSetHasMissingPushes(hasMissingPushes));
      }

      dispatch(pushSetStatusGetPushes(Status.SUCCEEDED));
    } catch {
      dispatch(pushSetStatusGetPushes(Status.FAILED));
    }
  };

export const pushDeletePushesHandler =
  ({ propIdPushDelete }: PushDeletePushesHandlerProps = {}): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(pushSetStatusDeletePushes(Status.LOADING));

      const { idPushLast } = selectPushIdPushLast(getState());
      const idPushForDeleteAll = '999999999999999999999999999999';

      const lastPushId = propIdPushDelete || idPushLast || idPushForDeleteAll;
      await services.pushService.deletePushes({ lastPushId });

      dispatch(pushSetStatusDeletePushes(Status.SUCCEEDED));
    } catch {
      dispatch(pushSetStatusDeletePushes(Status.FAILED));
    }
  };
