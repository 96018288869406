import { formatNumberAsLocal } from 'helpers';

import { SIPDataCallTimestamps } from '@redux/types';

import {
  SIPCallsCreateTimeAsHhMmSsProps,
  SIPCallsCreateTimeAsHhMmSsReturn,
  SIPCallsExtractContactDataProps,
  SIPCallsExtractContactDataReturn,
} from '../types';

export const sipCallsCreateTimeAsHhMmSs = ({
  timestampStart,
  timestampEnd,
}: SIPCallsCreateTimeAsHhMmSsProps): SIPCallsCreateTimeAsHhMmSsReturn => {
  if (timestampStart < 1 || timestampEnd < 1) {
    return {
      timeAsHhMmSs: '00:00',
    };
  }

  const diffAsMS = timestampStart - timestampEnd;
  const diffMSAsHours = diffAsMS / 1000 / 60 / 60;

  const hours = Math.floor(diffMSAsHours);
  const minutes = Math.floor((diffMSAsHours - hours) * 60);
  const seconds = Math.floor(((diffMSAsHours - hours) * 60 - minutes) * 60);

  const hh = `${hours}`.padStart(2, '0');
  const mm = `${minutes}`.padStart(2, '0');
  const ss = `${seconds}`.padStart(2, '0');

  const timeAsHhMmSs = hours > 0 ? `${hh}:${mm}:${ss}` : `${mm}:${ss}`;

  return {
    timeAsHhMmSs,
  };
};

export const sipCallsExtractContactData = ({
  phoneNumberRemote,
  contact,
}: SIPCallsExtractContactDataProps): SIPCallsExtractContactDataReturn => {
  const {
    displayName: contactDisplayName = '',
    imageUrl: contactImageUrl = '',
    company: contactCompany = '',
    emails: contactEmails = [],
    phones: contactPhones = [],
    favorite: isContactFavorited = false,
    blocked: isContactBlocked = false,
  } = contact || {};

  const contactEmail = contactEmails.map((data) => data.email)[0] || '';
  const contactPhoneNumberType =
    contactPhones.find(({ normalNumber }) => normalNumber === phoneNumberRemote)?.type || '';

  const callDisplayPhoneNumber = formatNumberAsLocal(phoneNumberRemote);
  const callDisplayNameOrPhoneNumber = contactDisplayName || callDisplayPhoneNumber;
  const callDisplayPhoneNumberType = contactPhoneNumberType.length > 0 ? `(${contactPhoneNumberType})` : '';
  const callDisplayCompanyName = contactCompany.length > 0 ? `(${contactCompany})` : '';

  return {
    contactDisplayName,
    contactImageUrl,
    contactCompany,
    contactPhoneNumberType,
    contactEmail,
    isContactFavorited,
    isContactBlocked,
    callDisplayPhoneNumber,
    callDisplayNameOrPhoneNumber,
    callDisplayPhoneNumberType,
    callDisplayCompanyName,
  };
};

export const sipCallsIsCallOngoing = (timestamps: SIPDataCallTimestamps): boolean => {
  const { callAnswered, callTerminated } = timestamps;
  const isCallOngoing = callAnswered > 0 && callTerminated === 0;
  return isCallOngoing;
};
