import PubNub, { PubnubConfig, StatusEvent, PubnubStatus, MessageEvent, ListenerParameters } from 'pubnub';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { pubNubDecodeMessageAndParse } from './helpers';
import { usePubNubConfig, usePubNubMessagesHandler } from './hooks';
import { PubNubSDKStatusEventCategory, PubNubSDKStatusEventOperation } from './types';

export const usePubNub = (): void => {
  const refPubNub = useRef<PubNub | null>(null);

  const {
    shouldPubNubInit,
    pubNubSubscribeKey,
    pubNubAuthKey,
    pubNubUserId,
    pubNubChannel,
    callbackPubNubPushHistoryUpdate,
    callbackPubNubRenewAuthKey,
  } = usePubNubConfig();
  const { handlePubNubMessages, handlePubNubErrors } = usePubNubMessagesHandler();

  const pubNubSubscribe = useCallback(() => {
    if (refPubNub.current !== null) {
      refPubNub.current.subscribe({ channels: [pubNubChannel] });
    }
  }, [refPubNub, pubNubChannel]);

  const eventHandlerPubNubStatus = useCallback(
    ({ error: hasError, category, operation }: StatusEvent & PubnubStatus): void => {
      const isNetworkUpEvent = category === PubNubSDKStatusEventCategory.PNNetworkUpCategory;
      const isAccessDeniedEvent = category === PubNubSDKStatusEventCategory.PNAccessDeniedCategory;
      const isSubscribeOperation = operation === PubNubSDKStatusEventOperation.PNSubscribeOperation;

      if (hasError && isAccessDeniedEvent && isSubscribeOperation) {
        callbackPubNubRenewAuthKey();
      }

      if (isNetworkUpEvent && refPubNub.current !== null) {
        pubNubSubscribe();
        callbackPubNubPushHistoryUpdate();
      }
    },
    [callbackPubNubRenewAuthKey, callbackPubNubPushHistoryUpdate, pubNubSubscribe],
  );

  const eventHandlerPubNubMessage = useCallback(
    ({ message }: MessageEvent): void => {
      const messageParsed = pubNubDecodeMessageAndParse(message);

      if (messageParsed === null) {
        handlePubNubErrors(`Fatal Error while Decoding and Parsing the Payload: "${message}"`);
        return;
      }

      handlePubNubMessages(messageParsed);
    },
    [handlePubNubMessages, handlePubNubErrors],
  );

  const eventListenerPubNub: ListenerParameters = useMemo(
    () => ({
      status: eventHandlerPubNubStatus,
      message: eventHandlerPubNubMessage,
    }),
    [eventHandlerPubNubStatus, eventHandlerPubNubMessage],
  );

  const callbackAuthKeyChange = useCallback(() => {
    if (refPubNub.current !== null && pubNubAuthKey.length > 0) {
      refPubNub.current.setAuthKey(pubNubAuthKey);
      pubNubSubscribe();
    }
  }, [pubNubAuthKey, pubNubSubscribe]);

  const callbackPubNubTerminate = useCallback(() => {
    if (refPubNub.current !== null && !pubNubAuthKey && !pubNubUserId) {
      refPubNub.current.removeListener(eventListenerPubNub);
      refPubNub.current.unsubscribeAll();
      refPubNub.current.stop();
      refPubNub.current = null;
    }
  }, [eventListenerPubNub, pubNubAuthKey, pubNubUserId]);

  const callbackPubNubInit = useCallback((): void => {
    if (refPubNub.current === null && shouldPubNubInit) {
      /**
       * Config
       * https://www.pubnub.com/docs/sdks/javascript/api-reference/configuration
       */
      const pubNubConfig: PubnubConfig = {
        subscribeKey: pubNubSubscribeKey,
        authKey: pubNubAuthKey,
        userId: pubNubUserId,
        ssl: true,
        heartbeatInterval: 2000,
        keepAlive: true,
        autoNetworkDetection: true,
      };

      const pubNub = new PubNub(pubNubConfig);
      pubNub.addListener(eventListenerPubNub);
      refPubNub.current = pubNub;
      pubNubSubscribe();
    }
  }, [shouldPubNubInit, pubNubSubscribeKey, pubNubAuthKey, pubNubUserId, eventListenerPubNub, pubNubSubscribe]);

  useEffect(callbackAuthKeyChange, [callbackAuthKeyChange]);
  useEffect(callbackPubNubInit, [callbackPubNubInit]);
  useEffect(callbackPubNubTerminate, [callbackPubNubTerminate]);
};
