import classnames from 'classnames';
import React from 'react';

import LoadingSkeleton from 'components/LoadingSkeleton';

import styles from './CategoryListLoading.module.scss';

export interface CategoryListLoadingProps {
  isMinimized: boolean;
}

const CategoryListLoading: React.FC<CategoryListLoadingProps> = ({ isMinimized }) => (
  <>
    {[1, 2, 3].map((item) => (
      <LoadingSkeleton
        key={item}
        className={classnames(styles.root, {
          [styles.isMinimized]: isMinimized,
        })}
      />
    ))}
  </>
);

export default CategoryListLoading;
