import { Status, CallLog, CallLogResponse, DeleteCallLogRequest } from 'types';

import { addCallLogDefaultValue } from 'helpers';

import { selectCallLogs, selectCallLogsFetchCallLogsStatus, selectCallLogsState } from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  CallLogsAddUnseenAction,
  CallLogsSetAllAsSeenAction,
  CallLogsSetDataAction,
  CallLogsAddDataAction,
  CallLogsSetSearchStringAction,
  CallLogsSetActiveCallLogIdAction,
  CallLogsDeleteCallLogsAction,
  CallLogsSetStatusDeleteCallLogAction,
  CallLogsSetStatusFetchCallLogsAction,
  CallLogsSetStatusSaveCallNoteAction,
  CallLogsAddDataHandlerProps,
  CallLogsSaveCallNoteHandlerProps,
  CallLogsUpdateCallLogAction,
} from '../../types';

export const callLogsAddUnseen = (): CallLogsAddUnseenAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_ADD_UNSEEN,
});

export const callLogsSetAllAsSeen = (): CallLogsSetAllAsSeenAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_SET_ALL_AS_SEEN,
});

export const callLogsSetData = (data: CallLogResponse[], isAllLoaded?: boolean): CallLogsSetDataAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_SET_DATA,
  data: data.map(addCallLogDefaultValue),
  isAllLoaded,
});

export const callLogsAddData = (data: CallLog[]): CallLogsAddDataAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_ADD_DATA,
  data,
});

export const callLogsSetSearchString = (searchString: string): CallLogsSetSearchStringAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_SET_SEARCH_STRING,
  searchString,
});

export const callLogsSetActiveCallLogId = (callLogId: string): CallLogsSetActiveCallLogIdAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_SET_ACTIVE_CALL_LOG_ID,
  activeCallLogId: callLogId,
});

export const callLogsDeleteCallLogs = (callLogIds: string[]): CallLogsDeleteCallLogsAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_DELETE_CALL_LOGS,
  callLogIds,
});

export const callLogsUpdateCallLog = (
  callLogId: string,
  updatedFields: Partial<CallLog>,
): CallLogsUpdateCallLogAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_UPDATE_CALL_LOG,
  callLogId,
  updatedFields,
});

export const callLogsSetStatusFetchCallLogs = (status: Status): CallLogsSetStatusFetchCallLogsAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_SET_STATUS_FETCH_CALL_LOGS,
  status,
});

export const callLogsSetStatusDeleteCallLog = (status: Status): CallLogsSetStatusDeleteCallLogAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_SET_STATUS_DELETE_CALL_LOG,
  status,
});

export const callLogsSetStatusSaveCallNote = (status: Status): CallLogsSetStatusSaveCallNoteAction => ({
  type: REDUX_ACTION_TYPES.CALL_LOGS_SET_STATUS_SAVE_CALL_NOTE,
  status,
});

export const callLogsFetchDataHandler =
  (limit = 50): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      const { allLoaded, offset } = selectCallLogsState(getState());
      const isLoading = selectCallLogsFetchCallLogsStatus(getState()) === Status.LOADING;

      if (allLoaded || isLoading) {
        return;
      }

      dispatch(callLogsSetStatusFetchCallLogs(Status.LOADING));
      const callLogs = await services.callLogsService.fetchCallLogs(offset, limit);
      const isAllLoaded = callLogs.length < limit;
      dispatch(callLogsSetData(callLogs, isAllLoaded));
      dispatch(callLogsSetStatusFetchCallLogs(Status.SUCCEEDED));
    } catch {
      dispatch(callLogsSetStatusFetchCallLogs(Status.FAILED));
    }
  };

export const callLogsDeleteCallLogHandler =
  (data: DeleteCallLogRequest): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(callLogsSetStatusDeleteCallLog(Status.LOADING));
      await services.callLogsService.deleteCallLog(data);
      dispatch(callLogsDeleteCallLogs([data.id]));
      dispatch(callLogsSetStatusDeleteCallLog(Status.SUCCEEDED));
    } catch (error) {
      dispatch(callLogsSetStatusDeleteCallLog(Status.FAILED));
      throw error;
    }
  };

export const callLogsAddDataHandler =
  ({ callLog }: CallLogsAddDataHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const callLogs = selectCallLogs(getState());
    const isCallLogAlreadyExist = callLogs.find(({ id }) => id === callLog.id) !== undefined;

    if (isCallLogAlreadyExist) {
      const { id, ...rest } = callLog;
      dispatch(callLogsUpdateCallLog(id, rest));
      return;
    }

    const callLogWithDefaultValue: CallLog = addCallLogDefaultValue(callLog);

    dispatch(callLogsAddData([callLogWithDefaultValue]));

    const { incoming, status } = callLog;
    if (incoming === true && status !== 'ANSWER') {
      dispatch(callLogsAddUnseen());
    }
  };

export const callLogsSaveCallNoteHandler =
  ({
    callNote,
    transactionId,
    callLogId,
    isCallInProgress,
  }: CallLogsSaveCallNoteHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(callLogsSetStatusSaveCallNote(Status.LOADING));

      if (!transactionId && !callLogId) {
        throw new Error('Dev Error: One of these IDs mandatory');
      }

      await services.callLogsService.saveCallNote({
        value: callNote.trim(),
        transactionId,
        callLogId,
        isCallInProgress,
      });

      dispatch(callLogsSetStatusSaveCallNote(Status.SUCCEEDED));
    } catch (err) {
      dispatch(callLogsSetStatusSaveCallNote(Status.FAILED));
      throw err;
    }
  };
