import classNames from 'classnames';
import { forwardRef } from 'react';

import { testGetTestingAttributes } from '@testing';
import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { ButtonRoundFlatProps } from '../../types';

import styles from './ButtonRoundFlat.module.scss';

const ButtonRoundFlat = forwardRef<HTMLButtonElement, ButtonRoundFlatProps>(
  (
    { onClick, className = '', iconName = IconCommonNames.BASKET, iconSize = IconCommonSizes.TINY, propTestId = '' },
    ref,
  ) => (
    <button
      ref={ref}
      type="button"
      onClick={onClick}
      className={classNames(styles.root, className)}
      {...testGetTestingAttributes(propTestId)}
    >
      <IconCommon
        name={iconName}
        size={iconSize}
      />
    </button>
  ),
);

export default ButtonRoundFlat;
