import { Reducer } from '@reduxjs/toolkit';

import { BrowserPermissionCanPlayAudioStatus } from 'types';

import { BrowserPermissionsReduxState, REDUX_ACTION_TYPES, BrowserPermissionsActionTypes } from '../../types';

export const browserPermissionsInitialState: BrowserPermissionsReduxState = {
  microphone: 'prompt',
  playAudio: { status: BrowserPermissionCanPlayAudioStatus.IDLE },
};

export const browserPermissionsReducer: Reducer<BrowserPermissionsReduxState, BrowserPermissionsActionTypes> = (
  state,
  action,
) => {
  const reducerState: BrowserPermissionsReduxState = state || browserPermissionsInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.BROWSER_PERMISSIONS_SET_MICROPHONE_PERMISSION: {
      return {
        ...reducerState,
        microphone: action.permissionState,
      };
    }

    case REDUX_ACTION_TYPES.BROWSER_PERMISSIONS_SET_PLAY_AUDIO_PERMISSION: {
      return {
        ...reducerState,
        playAudio: action.permissionState,
      };
    }

    default:
      return reducerState;
  }
};
