import React from 'react';

import { Modal, ModalSize } from 'components/Modal';

import ModalsMultiDeviceLogoutContent from '../ModalsMultiDeviceLogoutContent';

interface ModalsMultiDeviceLogoutProps {
  isOpen: boolean;
}

const ModalsMultiDeviceLogout: React.FC<ModalsMultiDeviceLogoutProps> = ({ isOpen }) => (
  <Modal
    isOpen={isOpen}
    size={ModalSize.L}
    canEscClose={false}
    canOverlayClose={false}
  >
    <ModalsMultiDeviceLogoutContent />
  </Modal>
);

export default ModalsMultiDeviceLogout;
