import { captureException } from '@sentry/react';

import { showErrorToast } from '@onoff/toast-notification';

import { AppError } from 'types';

import { getIntl } from '@intl';

import { getErrorDataFromUnknownError } from '../getErrorDataFromUnknownError';

type HandleApplicationsProps = {
  error: unknown;
  showToast?: boolean;
  reportToSentry?: boolean;
};

type HandleApplicationReturn = AppError;

export const handleApplicationError = ({
  error,
  showToast = true,
  reportToSentry = false,
}: HandleApplicationsProps): HandleApplicationReturn => {
  const { errorCode, errorDescription } = getErrorDataFromUnknownError(error);

  if (showToast) {
    showErrorToast({
      heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_error' }),
      message: errorDescription,
    });
  }

  if (reportToSentry) {
    captureException(Error(JSON.stringify({ errorCode, errorDescription })));
  }

  return { errorCode, errorDescription };
};
