import { showSuccessToast } from '@onoff/toast-notification';

import { Category, PlansStatistics, Status, GAEventKey } from 'types';

import {
  isObject,
  normalizeCategoryName,
  createCategoryStatusBySwitchType,
  isString,
  isCategoryStatisticsArrayValid,
  handleApplicationError,
  isPlanAutoRenewable,
  isQuickRenewalPeriodChangeFlow,
} from 'helpers';

import { getIntl } from '@intl';

import {
  selectCallLogsByCategoryId,
  selectCategoryCountryIsoCodeById,
  selectCategoryWithDynamicColor,
  selectContactsByCategoryId,
  selectThreadsByCategoryId,
  selectVoicemailsByCategoryId,
} from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  CategoriesSetStatisticsData,
  CategoriesUpdateCategoryHandlerProps,
  CategoriesRemoveCategoriesAndRelatedDataHandlerProps,
  CategoriesIsLoadingAction,
  CategorySwitchStatusProps,
  CategorySwitchStatusTypes,
  CategorySwitchStatusCallsProps,
  CategorySwitchStatusMessagesProps,
  CategorySwitchStatusVoicemailsProps,
  CategoryChangeColorProps,
  CategoryChangeNameProps,
  CategoryChangeVisibilityProps,
  CategoriesSetStatusCancelSubscriptionAction,
  CategoriesSetStatusFetchStatistics,
  CategoryAddAction,
  CategoryRemoveAction,
  CategoriesSetAllAction,
  CategoryUpdateAction,
  CategoriesStatisticsUpdateNextPlanDuration,
  CategoriesStatisticsUpdateNextPlanDurationProps,
  CategoriesStatisticsRemoveNextPlanDuration,
} from '../../types';
import { callLogsDeleteCallLogs } from '../callLogs';
import { deleteSimpleContacts } from '../contacts';
import { plansAndOffersSetSubscriptionIdForRenewalPeriodChange } from '../plansAndOffers';
import { threadsRemove } from '../threads';
import { voicemailsReleaseFromQueue, voicemailsDelete } from '../voicemails';

export const categoryAdd = (category: Category): CategoryAddAction => ({
  type: REDUX_ACTION_TYPES.ADD_CATEGORY,
  category,
});

export const categoryRemove = (categoryId: string): CategoryRemoveAction => ({
  type: REDUX_ACTION_TYPES.REMOVE_CATEGORY,
  categoryId,
});

export const categoriesSetAll = (categories: Category[]): CategoriesSetAllAction => ({
  type: REDUX_ACTION_TYPES.SET_ALL_CATEGORIES,
  categories,
});

export const categoryUpdate = (categoryId: string, data: Partial<Category>): CategoryUpdateAction => ({
  type: REDUX_ACTION_TYPES.UPDATE_CATEGORY,
  categoryId,
  data,
});

export const categoriesIsLoading = (isLoading: boolean): CategoriesIsLoadingAction => ({
  type: REDUX_ACTION_TYPES.CATEGORY_IS_LOADING,
  isLoading,
});

export const categoriesUpdateCategoryHandler =
  ({ category }: CategoriesUpdateCategoryHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    const { id: categoryId, ...update } = category;
    dispatch(categoryUpdate(categoryId, update));
  };

export const categoriesRemoveCategoriesAndRelatedDataHandler =
  ({ categoryIds }: CategoriesRemoveCategoriesAndRelatedDataHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    categoryIds.forEach((categoryId: string) => {
      // delete the redux category
      dispatch(categoryRemove(categoryId));

      // delete the redux call logs
      const callLogsIds = selectCallLogsByCategoryId(categoryId)(getState())
        .map((callLog) => callLog.id)
        .filter((id): id is string => isString(id));
      dispatch(callLogsDeleteCallLogs(callLogsIds));

      // delete the redux threads/messages
      const threadIds = selectThreadsByCategoryId(categoryId)(getState())
        .map((thread) => thread.threadId)
        .filter((id): id is string => isString(id));
      dispatch(threadsRemove(threadIds));

      // delete the redux voicemails
      const voicemailsIds = selectVoicemailsByCategoryId(categoryId)(getState())
        .map((voicemail) => voicemail.categoryId)
        .filter((id): id is string => isString(id));
      dispatch(voicemailsDelete(voicemailsIds));

      // delete the redux contacts
      const contactIds = selectContactsByCategoryId(categoryId)(getState())
        .map((contact) => contact.categoryId)
        .filter((id): id is string => isString(id));
      dispatch(deleteSimpleContacts(contactIds));
    });
  };

export const deleteCategory =
  (categoryId: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      await services.categoriesService.deleteCategory(categoryId);

      const countryIsoCode = selectCategoryCountryIsoCodeById(categoryId)(getState());

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.CATEGORIES_DELETE_NUMBER_SUCCESS,
        variables: { categories_number_countryIsoCode: countryIsoCode },
      });

      dispatch(categoriesRemoveCategoriesAndRelatedDataHandler({ categoryIds: [categoryId] }));

      showSuccessToast({
        message: getIntl().formatMessage({ id: 'ModalDeleteCategory.success_notification_description' }),
      });
    } catch (error) {
      handleApplicationError({ error });
    }
  };

export const fetchCategories =
  (): ThunkResult<Promise<void>> =>
  (dispatch, _, services): Promise<void> =>
    services.categoriesService.fetchCategories().then((categories) => {
      const categoriesWithNumbers = categories.filter((category) => isObject(category.virtualPhoneNumber));

      dispatch(categoriesSetAll(categoriesWithNumbers));
      dispatch(categoriesIsLoading(false));
    });

export const switchCategoryPrivacy =
  (categoryId: string, toPrivate: boolean, privatePassword?: string): ThunkResult<Promise<void>> =>
  (dispatch, _, services): Promise<void> =>
    services.categoriesService.switchCategoryPrivacy(categoryId, toPrivate, privatePassword).then(() => {
      dispatch(
        categoryUpdate(categoryId, {
          isPrivate: toPrivate,
          privatePassword: privatePassword || '',
        }),
      );
    });

export const switchCategoryVisibility =
  ({ categoryId, switchTo }: CategoryChangeVisibilityProps): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(categoryUpdate(categoryId, { isLoading: true }));

      await services.categoriesService.switchCategoryVisibility(categoryId, switchTo);

      const categoryNewStateOfTheVisibility: Pick<Category, 'isLoading' | 'isVisible'> = {
        isLoading: false,
        isVisible: switchTo,
      };

      dispatch(categoryUpdate(categoryId, categoryNewStateOfTheVisibility));
    } catch (error) {
      dispatch(categoryUpdate(categoryId, { isLoading: false }));
      throw error;
    }
  };

export const updateCategory =
  (category: Category): ThunkResult<Promise<void>> =>
  (dispatch, _, services): Promise<void> =>
    services.categoriesService.updateCategory(category).then(() => {
      const { id: categoryId, ...rest } = category;
      dispatch(categoryUpdate(categoryId, rest));
    });

export const updateCategoryName =
  ({ categoryId, newName }: CategoryChangeNameProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(categoryUpdate(categoryId, { isLoading: true }));

      const category = selectCategoryWithDynamicColor(categoryId)(getState());
      if (!category) {
        throw new Error('Dev Error: This Category is not exist in the Categories Redux State');
      }

      const normalizedNewName = normalizeCategoryName(newName) || category.name;

      await services.categoriesService.updateCategory({
        ...category,
        name: normalizedNewName,
      });

      const categoryNewStateOfTheName: Pick<Category, 'isLoading' | 'name'> = {
        isLoading: false,
        name: normalizedNewName,
      };

      dispatch(categoryUpdate(categoryId, categoryNewStateOfTheName));
    } catch (error) {
      dispatch(categoryUpdate(categoryId, { isLoading: false }));
      throw error;
    }
  };

export const updateCategoryColor =
  ({ categoryId, newColor }: CategoryChangeColorProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(categoryUpdate(categoryId, { isLoading: true }));

      const category = selectCategoryWithDynamicColor(categoryId)(getState());
      if (!category) {
        throw new Error('Dev Error: This Category is not exist in the Categories Redux State');
      }

      await services.categoriesService.updateCategory({
        ...category,
        color: newColor,
      });

      const categoryNewStateOfTheColor: Pick<Category, 'isLoading' | 'color'> = {
        isLoading: false,
        color: newColor,
      };

      dispatch(categoryUpdate(categoryId, categoryNewStateOfTheColor));
    } catch (error) {
      dispatch(categoryUpdate(categoryId, { isLoading: false }));
      throw error;
    }
  };

export const switchCategoryStatusAndUpdate =
  ({
    categoryId,
    switchTo,
    switchFor = CategorySwitchStatusTypes.CATEGORY,
  }: CategorySwitchStatusProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(categoryUpdate(categoryId, { isLoading: true }));

      const category = selectCategoryWithDynamicColor(categoryId)(getState());
      if (!category) {
        throw new Error('Dev Error: This Category is not exist in the Categories Redux State');
      }

      const categoryStatus = createCategoryStatusBySwitchType({
        category,
        switchTo,
        switchFor,
      });

      await services.categoriesService.switchCategoryStatus(categoryStatus);

      const categoryNewStateOfTheStatus: Partial<Category> = {
        isLoading: false,
        active: categoryStatus.enable,
        callEnabled: categoryStatus.callEnable,
        messageEnabled: categoryStatus.messageEnable,
        voicemailEnabled: categoryStatus.voicemailEnable,
      };

      dispatch(categoryUpdate(categoryId, categoryNewStateOfTheStatus));

      // "check if voicemails are in queue for this category and notify" => from PhoneCard.js
      if (switchFor === CategorySwitchStatusTypes.CALLS && switchTo) {
        const { voicemails } = getState();
        if (voicemails.queue[categoryId]) {
          dispatch(voicemailsReleaseFromQueue(categoryId));
        }
      }
    } catch (error) {
      dispatch(categoryUpdate(categoryId, { isLoading: false }));
      throw error;
    }
  };

export const switchCategoryStatusAndUpdateForCalls = ({
  categoryId,
  switchTo,
}: CategorySwitchStatusCallsProps): ThunkResult<Promise<void>> =>
  switchCategoryStatusAndUpdate({
    categoryId,
    switchTo,
    switchFor: CategorySwitchStatusTypes.CALLS,
  });

export const switchCategoryStatusAndUpdateForMessages = ({
  categoryId,
  switchTo,
}: CategorySwitchStatusMessagesProps): ThunkResult<Promise<void>> =>
  switchCategoryStatusAndUpdate({
    categoryId,
    switchTo,
    switchFor: CategorySwitchStatusTypes.MESSAGES,
  });

export const switchCategoryStatusAndUpdateForVoicemails = ({
  categoryId,
  switchTo,
}: CategorySwitchStatusVoicemailsProps): ThunkResult<Promise<void>> =>
  switchCategoryStatusAndUpdate({
    categoryId,
    switchTo,
    switchFor: CategorySwitchStatusTypes.VOICEMAILS,
  });

export const categoriesSetStatusCancelSubscription = (status: Status): CategoriesSetStatusCancelSubscriptionAction => ({
  type: REDUX_ACTION_TYPES.CATEGORIES_SET_STATUS_CANCEL_SUBSCRIPTION,
  status,
});

export const categoriesCancelSubscriptionHandler =
  (categoryId: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(categoriesSetStatusCancelSubscription(Status.LOADING));
      await services.categoriesService.cancelSubscription({ categoryId });

      const countryIsoCode = selectCategoryCountryIsoCodeById(categoryId)(getState());

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.CATEGORIES_UNSUBSCRIBE_SUCCESS,
        variables: { categories_number_countryIsoCode: countryIsoCode },
      });

      dispatch(categoriesSetStatusCancelSubscription(Status.SUCCEEDED));
      dispatch(fetchCategories());
    } catch (error) {
      dispatch(categoriesSetStatusCancelSubscription(Status.FAILED));
    }
  };

export const categoriesSetStatusFetchStatistics = (status: Status): CategoriesSetStatusFetchStatistics => ({
  type: REDUX_ACTION_TYPES.CATEGORIES_SET_STATUS_FETCH_STATISTICS,
  status,
});

export const categoriesSetStatisticsData = (data: PlansStatistics[]): CategoriesSetStatisticsData => ({
  type: REDUX_ACTION_TYPES.CATEGORIES_SET_STATISTICS_DATA,
  data,
});

export const categoriesFetchStatisticsHandler =
  (categories: Category[]): ThunkResult<Promise<void>> =>
  async (dispatch, _, services) => {
    if (categories.length > 0) {
      try {
        dispatch(categoriesSetStatusFetchStatistics(Status.LOADING));

        const categoryIds = categories.map((category) => category.id);
        const { planStatistics = [] } = await services.plansAndOffersService.fetchPlansStatistics(categoryIds);
        const isResponseDataValid = isCategoryStatisticsArrayValid(planStatistics, categoryIds);

        if (!isResponseDataValid) {
          throw new Error('Dev Error: Response data invalid');
        }

        dispatch(categoriesSetStatisticsData(planStatistics));

        if (isQuickRenewalPeriodChangeFlow()) {
          categories.some((category) => {
            const categoryStatistics = planStatistics.find((statistics) => statistics.categoryId === category.id);

            if (
              category.subscriptionId &&
              categoryStatistics &&
              isPlanAutoRenewable({ category, categoryStatistics })
            ) {
              dispatch(plansAndOffersSetSubscriptionIdForRenewalPeriodChange(category.subscriptionId));

              return true;
            }

            return false;
          });
        }

        dispatch(categoriesSetStatusFetchStatistics(Status.SUCCEEDED));
      } catch (error) {
        dispatch(categoriesSetStatusFetchStatistics(Status.FAILED));
      }
    }
  };

export const categoriesStatisticsUpdateNextPlanDuration = ({
  categoryId,
  nextPlanDuration,
}: CategoriesStatisticsUpdateNextPlanDurationProps): CategoriesStatisticsUpdateNextPlanDuration => ({
  type: REDUX_ACTION_TYPES.CATEGORIES_STATISTICS_UPDATE_NEXT_PLAN_DURATION,
  categoryId,
  nextPlanDuration,
});

export const categoriesStatisticsRemoveNextPlanDuration = (
  categoryId: string,
): CategoriesStatisticsRemoveNextPlanDuration => ({
  type: REDUX_ACTION_TYPES.CATEGORIES_STATISTICS_REMOVE_NEXT_PLAN_DURATION,
  categoryId,
});
