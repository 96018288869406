import React from 'react';

import { StyleableComponent } from 'types';

const ErrorIllustrationPaymentDeclined: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M200 100C200 155.228 155.228 200 100 200C44.7715 200 0 155.228 0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 100C187.368 148.252 148.252 187.369 99.9999 187.369C51.7477 187.369 12.6315 148.252 12.6315 100C12.6315 51.7479 51.7477 12.6317 99.9999 12.6317C148.252 12.6317 187.368 51.7479 187.368 100Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 100C172.105 139.823 139.823 172.106 100 172.106C60.1775 172.106 27.8949 139.823 27.8949 100C27.8949 60.1776 60.1775 27.895 100 27.895C139.823 27.895 172.105 60.1776 172.105 100Z"
      fill="#F3F4F5"
    />
    <path
      d="M22.1741 139.548C17.8331 136.836 16.513 131.118 19.2256 126.777L92.2833 9.85991C94.9958 5.51898 100.714 4.19887 105.055 6.91139L169.899 47.4308C174.24 50.1433 175.56 55.8613 172.848 60.2022L99.7901 177.119C97.0776 181.46 91.3597 182.78 87.0187 180.068L22.1741 139.548Z"
      fill="#E4E5E7"
      fillOpacity="0.8"
    />
    <path
      d="M22.1741 137.549C17.8331 134.836 16.513 129.118 19.2256 124.777L92.2833 7.86027C94.9958 3.51934 100.714 2.19924 105.055 4.91176L169.899 45.4312C174.24 48.1437 175.56 53.8617 172.848 58.2026L99.7901 175.119C97.0776 179.46 91.3597 180.78 87.0187 178.068L22.1741 137.549Z"
      fill="white"
    />
    <path
      d="M148.348 58.6041C152.554 61.2319 153.832 66.7711 151.205 70.9764C148.577 75.1817 143.038 76.4605 138.832 73.8328C134.627 71.205 133.348 65.6658 135.976 61.4605C138.604 57.2552 144.143 55.9764 148.348 58.6041Z"
      fill="#8E929B"
    />
    <path
      d="M32.7347 130.486C31.3782 129.638 30.9656 127.851 31.8133 126.495L44.3989 106.354C45.2465 104.997 47.0334 104.585 48.3899 105.432L62.1449 114.027C63.5014 114.875 63.9139 116.662 63.0663 118.018L50.4807 138.16C49.633 139.516 47.8462 139.929 46.4896 139.081L32.7347 130.486Z"
      fill="#E3E4E8"
    />
    <path
      d="M60.6069 83.6947C59.7929 83.1861 59.5454 82.114 60.054 81.3001L99.9595 17.4379C100.468 16.624 101.54 16.3765 102.354 16.8851L106.284 19.3408C107.098 19.8494 107.346 20.9215 106.837 21.7354L66.9315 85.5976C66.4229 86.4115 65.3508 86.659 64.5368 86.1504L60.6069 83.6947Z"
      fill="#E3E4E8"
    />
    <path
      d="M76.3857 160.262C75.5717 159.753 75.3242 158.681 75.8328 157.867L115.738 94.0051C116.247 93.1911 117.319 92.9436 118.133 93.4522L118.624 93.7592C119.438 94.2678 119.686 95.3399 119.177 96.1538L79.2716 160.016C78.763 160.83 77.6908 161.077 76.8769 160.569L76.3857 160.262Z"
      fill="#E3E4E8"
    />
    <path
      d="M82.2805 163.945C81.4666 163.437 81.2191 162.365 81.7277 161.551L92.1645 144.848C92.6731 144.034 93.7452 143.787 94.5592 144.296L95.0504 144.602C95.8643 145.111 96.1118 146.183 95.6032 146.997L85.1664 163.7C84.6578 164.513 83.5857 164.761 82.7718 164.252L82.2805 163.945Z"
      fill="#E3E4E8"
    />
    <path
      d="M154.181 49.2699C158.386 51.8976 159.665 57.4369 157.037 61.6422C154.409 65.8475 148.87 67.1263 144.665 64.4986C140.459 61.8708 139.181 56.3315 141.808 52.1263C144.436 47.921 149.975 46.6421 154.181 49.2699Z"
      fill="#E3E4E8"
      fillOpacity="0.7"
    />
    <path
      d="M160.6 133.8C160.6 144.183 152.183 152.6 141.8 152.6C131.417 152.6 123 144.183 123 133.8C123 123.417 131.417 115 141.8 115C152.183 115 160.6 123.417 160.6 133.8Z"
      fill="white"
    />
    <path
      d="M141.8 115C138.082 115 134.447 116.103 131.355 118.168C128.264 120.234 125.854 123.17 124.431 126.606C123.008 130.041 122.636 133.821 123.361 137.468C124.087 141.115 125.877 144.464 128.506 147.094C131.136 149.723 134.485 151.513 138.132 152.239C141.779 152.964 145.559 152.592 148.994 151.169C152.43 149.746 155.366 147.336 157.432 144.245C159.497 141.153 160.6 137.518 160.6 133.8C160.6 128.814 158.619 124.032 155.094 120.506C151.568 116.981 146.786 115 141.8 115ZM140.155 122.872C140.155 122.436 140.328 122.018 140.637 121.709C140.945 121.401 141.364 121.227 141.8 121.227C142.236 121.227 142.655 121.401 142.963 121.709C143.272 122.018 143.445 122.436 143.445 122.872V136.972C143.445 137.409 143.272 137.827 142.963 138.136C142.655 138.444 142.236 138.617 141.8 138.617C141.364 138.617 140.945 138.444 140.637 138.136C140.328 137.827 140.155 137.409 140.155 136.972V122.872ZM141.8 146.137C141.382 146.137 140.973 146.013 140.625 145.781C140.277 145.549 140.006 145.218 139.846 144.832C139.686 144.445 139.644 144.02 139.726 143.61C139.807 143.2 140.009 142.823 140.304 142.527C140.6 142.231 140.977 142.03 141.387 141.948C141.798 141.867 142.223 141.908 142.609 142.068C142.996 142.229 143.326 142.5 143.559 142.847C143.791 143.195 143.915 143.604 143.915 144.022C143.915 144.583 143.692 145.121 143.296 145.518C142.899 145.915 142.361 146.137 141.8 146.137Z"
      fill="currentColor"
    />
  </svg>
);

export default ErrorIllustrationPaymentDeclined;
