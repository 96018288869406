import { configureStore as reduxConfigureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer as sentryCreateReduxEnhancer } from '@sentry/react';

import serviceProvider from 'services';

import { rootReducer } from '../reducers';

const sentryReduxEnhancer = sentryCreateReduxEnhancer({
  stateTransformer: () => null,
});

export const webAppStore = reduxConfigureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: serviceProvider,
      },
    }),
  enhancers: [sentryReduxEnhancer],
});

export type WebAppStore = typeof webAppStore;
export type WebAppStoreState = ReturnType<typeof webAppStore.getState>;
export type WebAppStoreDispatch = typeof webAppStore.dispatch;
