import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import { webAppStore } from '@redux';

const ReduxProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <Provider store={webAppStore}>{children}</Provider>
);

export default ReduxProvider;
