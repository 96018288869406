import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { FramesInitProps } from 'types';

import styles from './CheckoutForm.module.scss';

export type FrameValidationProps = {
  element: 'card-number' | 'expiry-date' | 'cvv';
  isEmpty: boolean;
  isValid: boolean;
};

export interface CheckoutFormProps extends FramesInitProps {
  children: React.ReactNode;
  onCardSubmitted?: () => void;
  onCardValidationChanged?: (isValid: boolean) => void;
  onFrameValidationChanged?: (frameValidationProps: FrameValidationProps) => void;
  onCardTokenized?: (token: string) => void;
  onCardTokenizationFailed?: () => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  publicKey,
  debug = false,
  namespace = 'Frames',
  onCardValidationChanged,
  onCardSubmitted,
  onCardTokenized,
  onCardTokenizationFailed,
  onFrameValidationChanged,
  children,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (publicKey) {
      window.requestAnimationFrame(() => {
        window.Frames.init({
          publicKey,
          debug,
          namespace,
          localization: {
            cardNumberPlaceholder: intl.formatMessage({ id: 'Settings.billing_card_number' }),
            expiryMonthPlaceholder: 'MM',
            expiryYearPlaceholder: 'YY',
            cvvPlaceholder: intl.formatMessage({ id: 'Settings.billing_CVV' }),
          },
          style: {
            base: {
              color: '#2b2b2b',
              fontFamily: 'Work Sans, work-sans, Helvetica, Arial, sans-serif',
              letterSpacing: '0.3px',
            },
            valid: {
              color: '#2b2b2b',
            },
            placeholder: {
              base: {
                color: '#9298a2',
                fontWeight: 400,
                fontSize: 16,
                fontFamily: 'Work Sans, work-sans, Helvetica, Arial, sans-serif',
              },
            },
          },
        });
      });

      if (onCardValidationChanged) {
        window.Frames.addEventHandler(window.Frames.Events.CARD_VALIDATION_CHANGED, (event) => {
          onCardValidationChanged(event.isValid);
        });
      }

      if (onFrameValidationChanged) {
        window.Frames.addEventHandler(window.Frames.Events.FRAME_VALIDATION_CHANGED, (event) => {
          onFrameValidationChanged(event);
        });
      }

      if (onCardSubmitted) {
        window.Frames.addEventHandler(window.Frames.Events.CARD_SUBMITTED, () => {
          onCardSubmitted();

          // Re-enables form after submit (helpful when submitting failed)
          setTimeout(() => window.Frames.enableSubmitForm(), 300);
        });
      }

      if (onCardTokenized) {
        window.Frames.addEventHandler(window.Frames.Events.CARD_TOKENIZED, (event) => {
          onCardTokenized(event.token);
        });
      }

      if (onCardTokenizationFailed) {
        window.Frames.addEventHandler(window.Frames.Events.CARD_TOKENIZATION_FAILED, () => {
          onCardTokenizationFailed();
        });
      }
    }

    return () => {
      window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_VALIDATION_CHANGED);
      window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_SUBMITTED);
      window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_TOKENIZED);
      window.Frames.removeAllEventHandlers(window.Frames.Events.CARD_TOKENIZATION_FAILED);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicKey]);

  if (!publicKey) {
    return null;
  }

  return <form className={styles.root}>{children}</form>;
};

export default CheckoutForm;
