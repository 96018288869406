import React from 'react';

const CreditCardIconMastercard: React.FC = () => (
  <svg
    width="50"
    height="35"
    viewBox="0 0 50 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5778 25.3545H20.6544V9.19824H29.5778V25.3545Z"
      fill="#FF5F00"
    />
    <path
      d="M21.2261 17.275C21.2261 13.9977 22.7492 11.0783 25.1212 9.19691C23.3866 7.82117 21.1976 7.00003 18.8185 7.00003C13.1863 7.00003 8.6207 11.6002 8.6207 17.275C8.6207 22.9498 13.1863 27.55 18.8185 27.55C21.1976 27.55 23.3866 26.7289 25.1212 25.3532C22.7492 23.4718 21.2261 20.5524 21.2261 17.275Z"
      fill="#EB001B"
    />
    <path
      d="M41.6126 17.275C41.6126 22.9498 37.047 27.55 31.4148 27.55C29.0358 27.55 26.8467 26.7289 25.1115 25.3532C27.4841 23.4718 29.0072 20.5524 29.0072 17.275C29.0072 13.9977 27.4841 11.0783 25.1115 9.19691C26.8467 7.82117 29.0358 7.00003 31.4148 7.00003C37.047 7.00003 41.6126 11.6002 41.6126 17.275Z"
      fill="#F79E1B"
    />
  </svg>
);

export default CreditCardIconMastercard;
