import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, CallLogsReduxState, CallLogsActionsTypes } from '../../types';

export const callLogsInitialState: CallLogsReduxState = {
  activeCallLogId: '',
  countUnseenCalls: 0,
  data: [],
  searchString: '',
  statuses: {
    fetchCallLogs: Status.IDLE,
    deleteCallLog: Status.IDLE,
    saveCallNote: Status.IDLE,
  },
  allLoaded: false,
  offset: undefined,
};

export const callLogsReducer: Reducer<CallLogsReduxState, CallLogsActionsTypes> = (state, action) => {
  const reducerState: CallLogsReduxState = state ?? callLogsInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.CALL_LOGS_SET_STATUS_FETCH_CALL_LOGS:
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchCallLogs: action.status,
        },
      };

    case REDUX_ACTION_TYPES.CALL_LOGS_SET_STATUS_DELETE_CALL_LOG: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          deleteCallLog: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.CALL_LOGS_SET_STATUS_SAVE_CALL_NOTE: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          saveCallNote: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.CALL_LOGS_UPDATE_CALL_LOG: {
      return {
        ...reducerState,
        data: reducerState.data.map((callLog) => {
          if (callLog.id === action.callLogId) {
            return {
              ...callLog,
              ...action.updatedFields,
            };
          }

          return callLog;
        }),
      };
    }

    case REDUX_ACTION_TYPES.CALL_LOGS_SET_DATA: {
      const { data: logs, isAllLoaded } = action;
      const lastLog = logs[logs.length - 1];
      const offset = logs.length ? lastLog.id : reducerState.offset;

      return {
        ...reducerState,
        data: [...reducerState.data, ...logs],
        allLoaded: isAllLoaded ?? reducerState.allLoaded,
        offset,
      };
    }

    case REDUX_ACTION_TYPES.CALL_LOGS_ADD_DATA:
      return {
        ...reducerState,
        data: [...action.data, ...reducerState.data],
      };

    case REDUX_ACTION_TYPES.CALL_LOGS_ADD_UNSEEN:
      return {
        ...reducerState,
        countUnseenCalls: reducerState.countUnseenCalls + 1,
      };

    case REDUX_ACTION_TYPES.CALL_LOGS_SET_ALL_AS_SEEN:
      return {
        ...reducerState,
        countUnseenCalls: 0,
      };

    case REDUX_ACTION_TYPES.CALL_LOGS_SET_ACTIVE_CALL_LOG_ID: {
      return {
        ...reducerState,
        activeCallLogId: action.activeCallLogId,
      };
    }

    case REDUX_ACTION_TYPES.CALL_LOGS_SET_SEARCH_STRING: {
      return {
        ...reducerState,
        searchString: action.searchString,
      };
    }

    case REDUX_ACTION_TYPES.CALL_LOGS_DELETE_CALL_LOGS: {
      return {
        ...reducerState,
        data: reducerState.data.filter(({ id }) => !action.callLogIds.includes(id)),
      };
    }

    default:
      return reducerState;
  }
};
