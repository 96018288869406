import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { Button } from '@onoff/ui';

import { PremiumUpgradePromoClickSource, Status } from 'types';

import { selectTrialAvailable, useWebAppSelector } from '@redux';
import { FeatureBlockSplashFeature } from 'components/FeatureBlockSplash/enums';
import { getHeaderTextTranslationKey } from 'components/FeatureBlockSplash/helpers';
import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';
import { ModalsPremiumPlan, ModalsPremiumPlanTypes } from 'components/Modals';
import { usePremiumOffer, usePremiumOfferAnalyticsTagging } from 'hooks';
import { ApplicationPaths } from 'routes';

import styles from './PremiumCTA.module.scss';

export interface PremiumCTAProps {
  feature: FeatureBlockSplashFeature;
}

const PremiumCTA: React.FC<PremiumCTAProps> = ({ feature }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    statuses: { fetchUserPlanPremiumPrice },
    hasPriceData,
  } = usePremiumOffer();
  const { sendPremiumOfferPromoTag } = usePremiumOfferAnalyticsTagging();
  const isTrialAvailable = useWebAppSelector(selectTrialAvailable);

  const headerTextTranslationKey = getHeaderTextTranslationKey(feature);

  const isLoadingPremiumOffer = fetchUserPlanPremiumPrice === Status.LOADING;

  useEffect(() => {
    if (isModalOpen) {
      sendPremiumOfferPromoTag(PremiumUpgradePromoClickSource.SPLASH);
    }
  }, [isModalOpen, sendPremiumOfferPromoTag]);

  const handlePremiumPlanModalSubmit = () => {
    navigate(ApplicationPaths.PURCHASE_PREMIUM_UPGRADE);
  };

  return (
    <>
      {headerTextTranslationKey && (
        <h2 className={styles.header}>
          <FormattedMessage id={headerTextTranslationKey} />
        </h2>
      )}
      <Button
        size="medium"
        onClick={() => setIsModalOpen(true)}
        loading={isLoadingPremiumOffer}
        disabled={isLoadingPremiumOffer || !hasPriceData}
      >
        <IconCommon
          name={IconCommonNames.PREMIUM_CROWN}
          size={IconCommonSizes.TINY}
          className={styles.buttonIcon}
        />
        <span>
          <FormattedMessage
            id={isTrialAvailable ? 'FeatureBlockSplash.button_trial_available' : 'FeatureBlockSplash.button_upgrade'}
          />
        </span>
      </Button>

      <ModalsPremiumPlan
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        type={isTrialAvailable ? ModalsPremiumPlanTypes.TRIAL : ModalsPremiumPlanTypes.UPGRADE}
        onSubmit={handlePremiumPlanModalSubmit}
      />
    </>
  );
};

export default PremiumCTA;
