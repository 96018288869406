import React from 'react';
import { useIntl } from 'react-intl';

import { IconCommonNames } from '../../../../Icon';
import { Info } from '../../../../Info';

import styles from './CardAddingInfo.module.scss';

const CardAddingInfo: React.FC = () => {
  const intl = useIntl();

  return (
    <section className={styles.root}>
      <Info
        iconName={IconCommonNames.WARNING}
        className={styles.info}
        classNameIcon={styles.warningIcon}
        classNameBody={styles.infoContent}
      >
        {intl.formatMessage({ id: 'Settings.billing_add_card_warning_2' })}
      </Info>
      <Info
        iconName={IconCommonNames.CHECK_CIRCLE_FILLED}
        className={styles.info}
        classNameIcon={styles.checkIcon}
        classNameBody={styles.infoContent}
      >
        {intl.formatMessage({ id: 'Settings.billing_add_card_warning_1' })}
      </Info>
      <Info
        iconName={IconCommonNames.CHECK_CIRCLE_FILLED}
        className={styles.info}
        classNameIcon={styles.checkIcon}
        classNameBody={styles.infoContent}
      >
        {intl.formatMessage({ id: 'Settings.billing_add_card_warning_3' })}
      </Info>
    </section>
  );
};

export default CardAddingInfo;
