import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconButton, IconCommonNames } from 'components/Icon';

import styles from './ModalsExportContactDisclaimer.module.scss';

interface ModalsExportContactDisclaimerProps {
  onBackButtonHandler: () => void;
}

const ModalsExportContactDisclaimer: React.FC<ModalsExportContactDisclaimerProps> = ({ onBackButtonHandler }) => (
  <div className={styles.root}>
    <h3 className={styles.header}>
      <FormattedMessage id="Integrations.ExportContactModal.disclaimer_title" />
      <IconButton
        iconName={IconCommonNames.CHEVRON_LEFT}
        onClick={onBackButtonHandler}
        className={styles.backButton}
      />
    </h3>
    <p className={styles.content}>
      <FormattedMessage id="Integrations.ExportContactModal.disclaimer_content" />
    </p>
  </div>
);

export default ModalsExportContactDisclaimer;
