import React from 'react';

import { formatNumber } from 'helpers/country_codes';

import styles from './CategoryCommonNumber.module.scss';

export interface CategoryCommonNumberProps {
  number?: string;
}

const CategoryCommonNumber: React.FC<CategoryCommonNumberProps> = ({ number = '' }) => (
  <h4 className={styles.root}>{formatNumber(number)}</h4>
);

export default CategoryCommonNumber;
