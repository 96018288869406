import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { PurchaseFlowReduxState, REDUX_ACTION_TYPES, PurchaseFlowActionTypes } from '../../types';

export const purchaseFlowInitialState: PurchaseFlowReduxState = {
  selectedPaymentMethod: null,
  statuses: {
    initialisePaymentMethod: Status.IDLE,
  },
};

export const purchaseFlowReducer: Reducer<PurchaseFlowReduxState, PurchaseFlowActionTypes> = (state, action) => {
  const reducerState: PurchaseFlowReduxState = state || purchaseFlowInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.PURCHASE_FLOW_SET_SELECTED_PAYMENT_METHOD: {
      const { selectedPaymentMethod } = action;

      return {
        ...reducerState,
        selectedPaymentMethod,
      };
    }

    case REDUX_ACTION_TYPES.PURCHASE_FLOW_SET_STATUS:
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          [action.id]: action.status,
        },
      };

    default:
      return reducerState;
  }
};
