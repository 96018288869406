import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { showErrorToast, showSuccessToast } from '@onoff/toast-notification';
import { Button } from '@onoff/ui';

import { CallNotesOrigin, GAEventKey, Status } from 'types';

import {
  callsGetCallNoteByTransactionIdFromSessionStorage,
  callsSetCallNoteToSessionStorage,
  getErrorDataFromUnknownError,
} from 'helpers';

import { API_ERRORS } from '@constants';
import {
  callLogsSaveCallNoteHandler,
  callLogsSetStatusSaveCallNote,
  selectCallLogsStatuses,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { Modal, ModalBody, ModalFooter, ModalSize } from 'components/Modal';
import { useUserCapabilities } from 'hooks';
import { analyticsService } from 'services';

import { ModalsCallDetailsProps } from '../../types';
import ModalsCallDetailsHeader from '../ModalsCallDetailsHeader';

import styles from './ModalsCallDetails.module.scss';

const ModalsCallDetails: React.FC<ModalsCallDetailsProps> = ({ isOpen, data, onCloseHandler }) => {
  const {
    transactionId,
    callDurationStart,
    callDurationEnd,
    callDirection,
    displayContactName,
    displayPhoneNumber,
    displayCompanyName,
    displayPhoneNumberType,
  } = data;

  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { saveCallNote: statusSaveCallNote } = useWebAppSelector(selectCallLogsStatuses);
  const { isUserB2B } = useUserCapabilities();

  const [stateCallNoteValue, setStateCallNoteValue] = useState<string>('');
  const [stateCallNoteIsDraft, setStateCallNoteIsDraft] = useState<boolean>(false);

  const storageCallNoteValue = callsGetCallNoteByTransactionIdFromSessionStorage(transactionId)?.note || '';

  const onChangeHandlerUpdateStateCallNote = ({ currentTarget }: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setStateCallNoteValue(currentTarget.value);
  };

  const onFocusHandlerUpdateSelectionState = ({ currentTarget }: React.ChangeEvent<HTMLTextAreaElement>): void => {
    currentTarget.setSelectionRange(stateCallNoteValue.length, stateCallNoteValue.length);
  };

  const onClickHandlerCancel = (): void => {
    setStateCallNoteValue(storageCallNoteValue);
    onCloseHandler();
  };

  const onClickHandlerSave = async () => {
    /**
     * For now, only call notes,
     * This modal will post more data in future
     */
    try {
      await dispatch(
        callLogsSaveCallNoteHandler({
          transactionId,
          callNote: stateCallNoteValue,
        }),
      );

      callsSetCallNoteToSessionStorage({
        transactionId,
        note: stateCallNoteValue,
        isDraft: false,
      });

      analyticsService.pushToDataLayer({
        event: storageCallNoteValue ? GAEventKey.CALL_NOTE_UPDATED : GAEventKey.CALL_NOTE_SAVED,
        variables: {
          origin: CallNotesOrigin.CALL_DETAIL,
          call_in_progress: 'No',
        },
      });

      showSuccessToast({
        message: intl.formatMessage({ id: 'Modals.CallNotes.notification_succeeded_description' }),
      });

      onCloseHandler();

      dispatch(callLogsSetStatusSaveCallNote(Status.IDLE));
    } catch (error) {
      const { errorCode, errorDescription } = getErrorDataFromUnknownError(error);

      showErrorToast({
        heading: intl.formatMessage({ id: 'Modals.CallNotes.notification_failed_title' }),
        message:
          errorCode !== API_ERRORS.HTTP.UNKNOWN_ERROR && errorDescription
            ? errorDescription
            : intl.formatMessage({ id: 'Modals.CallNotes.notification_failed_description' }),
      });

      dispatch(callLogsSetStatusSaveCallNote(Status.IDLE));
    }
  };

  const callbackGetAndSetStorageCallNoteOnInitHandler = (): void => {
    if (isOpen) {
      /**
       * This delay is needed because of our Modal system design
       * (remove the previous modal, close the previous portal,
       * open this one, creates a portal then render etc..)
       */
      window.setTimeout(() => {
        const storageCallNote = callsGetCallNoteByTransactionIdFromSessionStorage(transactionId);
        setStateCallNoteValue(storageCallNote?.note || '');
        setStateCallNoteIsDraft(storageCallNote?.isDraft === true);
      }, 200);
    }
  };

  useEffect(callbackGetAndSetStorageCallNoteOnInitHandler, [isOpen, transactionId]);

  return (
    <Modal
      isOpen={isOpen}
      size={ModalSize.XXL}
      zIndex={42}
      className={styles.root}
      onRequestClose={onCloseHandler}
    >
      <ModalsCallDetailsHeader
        callDurationStart={callDurationStart}
        callDurationEnd={callDurationEnd}
        callDirection={callDirection}
        displayContactName={displayContactName}
        displayCompanyName={displayCompanyName}
        displayPhoneNumber={displayPhoneNumber}
        displayPhoneNumberType={displayPhoneNumberType}
      />

      <ModalBody className={styles.body}>
        <div className={styles.bodyHead}>
          <h3 className={styles.bodyHeadTitle}>
            <FormattedMessage id="Modals.CallDetails.call_notes_title" />
          </h3>
          {stateCallNoteIsDraft ? (
            <span className={styles.bodyHeadDraftBadge}>
              <FormattedMessage id="Modals.CallDetails.call_notes_label_draft" />
            </span>
          ) : null}
        </div>

        <div className={styles.bodyContent}>
          <textarea
            className={styles.bodyContentCallNote}
            onChange={onChangeHandlerUpdateStateCallNote}
            value={stateCallNoteValue}
            placeholder={intl.formatMessage({ id: 'Modals.CallDetails.call_notes_placeholder' })}
            autoFocus
            onFocus={onFocusHandlerUpdateSelectionState}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          className={styles.footerButton}
          colorScheme="black"
          variant="ghost"
          size="large"
          onClick={onClickHandlerCancel}
        >
          <FormattedMessage id="Modals.CallDetails.button_cancel" />
        </Button>
        <Button
          className={styles.footerButton}
          colorScheme={isUserB2B ? 'brand-b2b' : 'brand-b2c'}
          variant="solid"
          size="large"
          loading={statusSaveCallNote === Status.LOADING}
          disabled={storageCallNoteValue === stateCallNoteValue}
          onClick={onClickHandlerSave}
        >
          <FormattedMessage id="Modals.CallDetails.button_save" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalsCallDetails;
