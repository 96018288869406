import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, PushReduxState, PushActionTypes } from '../../types';

export const pushInitialState: PushReduxState = {
  statuses: {
    statusGetPushes: Status.IDLE,
    statusDeletePushes: Status.IDLE,
  },
  idPushLast: '',
  hasMissingPushes: false,
};

export const pushReducer: Reducer<PushReduxState, PushActionTypes> = (state, action) => {
  const reducerState: PushReduxState = state || pushInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.PUSH_SET_STATUS_GET_PUSHES: {
      const { status } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusGetPushes: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.PUSH_SET_STATUS_DELETE_PUSHES: {
      const { status } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusDeletePushes: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.PUSH_SET_ID_LAST_PUSH: {
      const { idPushLast } = action;

      return {
        ...reducerState,
        idPushLast,
      };
    }

    case REDUX_ACTION_TYPES.PUSH_SET_HAS_MISSING_PUSHES: {
      const { hasMissingPushes } = action;

      return {
        ...reducerState,
        hasMissingPushes,
      };
    }

    default:
      return reducerState;
  }
};
