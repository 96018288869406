import { useEffect } from 'react';

import { LOCALSTORAGE_KEYS, SESSIONSTORAGE_KEYS } from '@constants';
import {
  browserTabsValidateActiveBrowserTabHandler,
  sipSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabledHandler,
  sipSetDataCallsCapabilitiesHdCodecEnabledHandler,
  sipSetDataCallsCapabilitiesIncomingRingtoneDisabledHandler,
  useWebAppDispatch,
} from '@redux';

export const useWindowStorageListener = (): void => {
  const dispatch = useWebAppDispatch();

  const listenerWindowStorageChange = ({ key: eventKey }: StorageEvent): void => {
    switch (eventKey) {
      /**
       * Browser tabs
       */
      case LOCALSTORAGE_KEYS.BROWSER_TABS_ACTIVE_ID:
      case SESSIONSTORAGE_KEYS.BROWSER_TABS_CURRENT_ID: {
        dispatch(browserTabsValidateActiveBrowserTabHandler());
        break;
      }

      /**
       * SIP
       */
      // Incoming Calls Ringtone Disabled
      case LOCALSTORAGE_KEYS.SIP_CALLS_INCOMING_RINGTONE_DISABLED: {
        dispatch(sipSetDataCallsCapabilitiesIncomingRingtoneDisabledHandler());
        break;
      }

      // WebRTC Special Media Constraints Enabled
      case LOCALSTORAGE_KEYS.SIP_CALLS_WEBRTC_ADVANCED_MEDIA_CONSTRAINTS_ENABLED: {
        dispatch(sipSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabledHandler());
        break;
      }

      // WebRTC HD Codec Enabled
      case LOCALSTORAGE_KEYS.SIP_CALLS_WEBRTC_HD_CODEC_ENABLED: {
        dispatch(sipSetDataCallsCapabilitiesHdCodecEnabledHandler());
        break;
      }

      default:
        break;
    }
  };

  const callbackWindowStorageChangeHandler = () => {
    window.addEventListener('storage', listenerWindowStorageChange);
    return () => {
      window.removeEventListener('storage', listenerWindowStorageChange);
    };
  };

  useEffect(callbackWindowStorageChangeHandler, [callbackWindowStorageChangeHandler]);
};
