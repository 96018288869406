import React, { useEffect } from 'react';

const LazyReload: React.FC = () => {
  useEffect(() => {
    window.location.reload();
  });

  return null;
};

export default LazyReload;
