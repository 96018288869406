export const SESSIONSTORAGE_KEYS = {
  // PURCHASE
  PURCHASE_CATEGORY_ID: 'purchaseCategoryId',
  PURCHASE_PHONE_NUMBER: 'purchasePhoneNumber',
  PURCHASE_EMAIL: 'purchaseEmail',
  CHECKOUT_CALLBACK_REDIRECTION_PATH: 'checkoutCallbackRedirectionPath',

  PAYMENT_METHOD: 'paymentMethod',

  // PREMIUM PLAN
  SELECTED_PREMIUM_PLAN: 'selectedPremiumPlan',

  // BROWSER
  BROWSER_TABS_CURRENT_ID: 'browserTabsCurrentId',

  // CALL NOTES
  SIP_CALLS_CALL_NOTES: 'sipCallsCallNotes',

  // CALL TRANSFER
  CALL_TRANSFER_ANNOUNCEMENT_CHECKED: 'callTransferAnnouncementChecked',
} as const;
