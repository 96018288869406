export enum PubNubPushTypes {
  // CATEGORIES
  CATEGORIES_PUSH_CATEGORY_NEW = 'CATEGORY_V3',
  CATEGORIES_PUSH_CATEGORY_UPDATE = 'CATEGORY_CHANGE_V3',
  CATEGORIES_PUSH_CATEGORY_DELETE = 'CATEGORY_DELETE',

  // INTEGRATIONS
  INTEGRATIONS_PUSH_INTEGRATIONS_STATUS_CHANGE = 'INTEGRATIONS_STATUS_CHANGE',

  // PAGE CALLS
  PAGE_CALLS_PUSH_CALLLOG_NEW = 'CALLLOG',
  PAGE_CALLS_PUSH_CALLLOG_CHANGE = 'CALLLOG_CHANGE',
  PAGE_CALLS_PUSH_CALLLOG_DELETE = 'CALLLOG_DELETE',
  PAGE_CALLS_PUSH_CALLLOG_READ = 'CALLLOG_READ',

  // PAGE MESSAGES
  PAGE_MESSAGES_PUSH_MESSAGE_NEW = 'MESSAGE',
  PAGE_MESSAGES_PUSH_MESSAGE_CREATE = 'MESSAGE_CREATE',
  PAGE_MESSAGES_PUSH_MESSAGE_CHANGE = 'MESSAGE_CHANGE',
  PAGE_MESSAGES_PUSH_MESSAGE_DELETE = 'MESSAGE_DELETE',
  PAGE_MESSAGES_PUSH_MESSAGE_THREAD_DELETE = 'MESSAGE_THREAD_DELETE',
  PAGE_MESSAGES_PUSH_MESSAGE_THREAD_READ = 'MESSAGE_THREAD_READ',

  // PAGE VOICEMAILS
  PAGE_VOICEMAILS_PUSH_VOICEMAIL_NEW = 'VOICEMAIL',
  PAGE_VOICEMAILS_PUSH_VOICEMAIL_DELETE = 'VOICEMAIL_DELETE',
  PAGE_VOICEMAILS_PUSH_VOICEMAIL_READ = 'VOICEMAIL_READ',

  // PAGE CONTACTS
  PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_NEW = 'SIMPLE_CONTACT',
  PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_UPDATE = 'SIMPLE_CONTACT_CHANGE',
  PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_DELETE = 'SIMPLE_CONTACT_DELETE',
  PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_DELETE_ALL = 'SIMPLE_CONTACT_DELETE_ALL',
  PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_SYNC_ALL = 'SIMPLE_CONTACT_SYNC_ALL',
  PAGE_CONTACTS_PUSH_PHONEBOOK_SYNC_OFF = 'PHONEBOOK_SYNC_OFF',

  // VOIP CALLS
  VOIP_CALLS_INCOMING_CALL = 'VOIP_CALLING',
  VOIP_CALLS_CALL_CANCELLED = 'CALLING_CANCELLED',

  // USER
  USER_PUSH_USER_CHANGE = 'USER_CHANGE',
  USER_PUSH_USER_ACCOUNT_TYPE_CHANGE = 'ACCOUNT_TYPE_CHANGE',
  USER_PUSH_FORCE_LOG_OUT = 'FORCE_LOG_OUT',

  // CALL_QUALITY
  CALL_QUALITY_SURVEY = 'CALL_QUALITY',

  // IGNORED => SIP/VOIP CALLS
  // JsSIP Handles Automatically
  WEBAPP_IGNORED_CALLING_PICKEDUP = 'CALLING_PICKEDUP',
  // ONOFF CALLS
  WEBAPP_IGNORED_CALLING = 'CALLING',
  // ONOFF CALLS
  WEBAPP_IGNORED_OFFLINE_CALLS_ENABLED = 'OFFLINE_CALLS_ENABLED',
  // ONOFF CALLS
  WEBAPP_IGNORED_OFFLINE_CALLS_DISABLED = 'OFFLINE_CALLS_DISABLED',
  // ONOFF CALLS
  WEBAPP_IGNORED_OFFLINE_CALLS_SYNC_TSANS = 'OFFLINE_CALLS_SYNC_TSANS',
  // ONOFF CALLS
  WEBAPP_IGNORED_OFFLINE_CALLS_INCOMING_CALL_FROM_UNKNOWN_NUMBER = 'OFFLINE_CALLS_INCOMING_CALL_FROM_UNKNOWN_NUMBER',

  // IGNORED => GENERAL
  WEBAPP_IGNORED_SYNC_PUSH_HISTORY = 'SYNC_PUSH_HISTORY',
  WEBAPP_IGNORED_REQUEST_DEVICE_LOGS = 'REQUEST_DEVICE_LOGS',
  WEBAPP_IGNORED_VALIDATION_SUCCEED = 'VALIDATION_SUCCEED',
  WEBAPP_IGNORED_VALIDATION_FAILED = 'VALIDATION_FAILED',
  WEBAPP_IGNORED_PORT_IN = 'PORT_IN',
  WEBAPP_IGNORED_PACKAGES_SYNC = 'PACKAGES_SYNC',
  WEBAPP_IGNORED_NOTIFICATION = 'NOTIFICATION',
  WEBAPP_IGNORED_ERROR_NOTIFICATION = 'ERROR_NOTIFICATION',
}

/**
 * https://www.pubnub.com/docs/sdks/javascript/status-events#subscription
 */
export enum PubNubSDKStatusEventCategory {
  PNNetworkUpCategory = 'PNNetworkUpCategory',
  PNNetworkDownCategory = 'PNNetworkDownCategory',
  PNNetworkIssuesCategory = 'PNNetworkIssuesCategory',
  PNReconnectedCategory = 'PNReconnectedCategory',
  PNConnectedCategory = 'PNConnectedCategory',
  PNAccessDeniedCategory = 'PNAccessDeniedCategory',
  PNMalformedResponseCategory = 'PNMalformedResponseCategory',
  PNBadRequestCategory = 'PNBadRequestCategory',
  PNDecryptionErrorCategory = 'PNDecryptionErrorCategory',
  PNTimeoutCategory = 'PNTimeoutCategory',
  PNRequestMessageCountExceededCategory = 'PNRequestMessageCountExceededCategory',
  PNUnknownCategory = 'PNUnknownCategory',
}

export enum PubNubSDKStatusEventOperation {
  PNSubscribeOperation = 'PNSubscribeOperation',
}
