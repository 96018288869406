import { createSelector } from '@reduxjs/toolkit';

import { Category, Voicemail } from 'types';

import { isPhoneNumberAndOrContactIncludesSearchTerm, isVoicemailFilteredByListened } from 'helpers';

import {
  SelectSearchVoicemailsVisible,
  SelectVoicemailActiveId,
  SelectVoicemailsActiveVoicemail,
  SelectVoicemailsByCategoryId,
  SelectVoicemailsDeleteVoicemailStatus,
  SelectVoicemailsFetchVoicemailsStatus,
  SelectVoicemailsSearchString,
  SelectVoicemailsState,
  SelectVoicemailsStatuses,
} from '../../types';
import { selectCategoriesByVisibilityWithDynamicColor } from '../categories';
import { selectContactsPhoneNumberContactMap } from '../contacts';

export const selectVoicemailsState: SelectVoicemailsState = (state) => state.voicemails;

export const selectVoicemails = createSelector([selectVoicemailsState], (voicemailsState) => voicemailsState.data);

export const selectVoicemailsQueue = createSelector(
  [selectVoicemailsState],
  (voicemailsState) => voicemailsState.queue,
);

export const selectVoicemailsQueuesAllData = createSelector([selectVoicemailsQueue], (voicemailsQueue) =>
  Object.values(voicemailsQueue).flatMap((vm) => vm),
);

export const selectVoicemailsByVisible = createSelector(
  [selectVoicemails, selectCategoriesByVisibilityWithDynamicColor],
  (voicemailList, visibleCategoryList) =>
    voicemailList.filter((voicemail) => visibleCategoryList.find((category) => category.id === voicemail.categoryId)),
);

export const selectVoicemailsByVisibleUnseen = createSelector([selectVoicemailsByVisible], (visibleVoicemailList) =>
  visibleVoicemailList.filter((voicemail) => !voicemail.listened),
);

export const selectVoicemailsByVisibleUnseenCount = createSelector(
  [selectVoicemailsByVisibleUnseen],
  (unseenVoicemailList) => unseenVoicemailList.length,
);

export const selectVoicemailsByCategoryId: SelectVoicemailsByCategoryId = (categoryId: Category['id']) =>
  createSelector([selectVoicemails], (voicemails): Voicemail[] =>
    voicemails.filter((voicemail) => voicemail.categoryId === categoryId),
  );

export const selectVoicemailsActiveVoicemailId: SelectVoicemailActiveId = createSelector(
  [selectVoicemailsState],
  (voicemailsReduxState) => voicemailsReduxState.activeVoicemailId,
);

export const selectVoicemailsActiveVoicemail: SelectVoicemailsActiveVoicemail = createSelector(
  [selectVoicemailsByVisible, selectVoicemailsActiveVoicemailId],
  (visibleVoicemails, activeVoicemailId) =>
    visibleVoicemails.find((visibleVoicemail) => visibleVoicemail.id === activeVoicemailId) || undefined,
);

export const selectVoicemailsSearchString: SelectVoicemailsSearchString = createSelector(
  [selectVoicemailsState],
  (voicemailsState) => voicemailsState.searchString,
);

export const selectSearchVoicemailsVisible: SelectSearchVoicemailsVisible = (isOnlyNewVoicemails: boolean) =>
  createSelector(
    [
      selectVoicemailsByVisible,
      selectVoicemailsSearchString,
      selectContactsPhoneNumberContactMap,
      selectVoicemailsActiveVoicemailId,
    ],
    (visibleVoicemails, searchTerm, phoneNumberContactsMap, activeVoicemailId) =>
      visibleVoicemails.filter(
        ({ id = '', callingPartyPhone, listened = '' }) =>
          isPhoneNumberAndOrContactIncludesSearchTerm({
            contact: phoneNumberContactsMap[callingPartyPhone || ''],
            phoneNumber: callingPartyPhone,
            searchTerm,
          }) && isVoicemailFilteredByListened(isOnlyNewVoicemails, listened, id, activeVoicemailId),
      ),
  );

export const selectVoicemailsStatuses: SelectVoicemailsStatuses = createSelector(
  [selectVoicemailsState],
  (voicemailsState) => voicemailsState.statuses,
);

export const selectVoicemailsFetchVoicemailsStatus: SelectVoicemailsFetchVoicemailsStatus = createSelector(
  [selectVoicemailsStatuses],
  (voicemailsStatuses) => voicemailsStatuses.fetchVoicemails,
);

export const selectVoicemailsDeleteVoicemailStatus: SelectVoicemailsDeleteVoicemailStatus = createSelector(
  [selectVoicemailsStatuses],
  (voicemailsStatuses) => voicemailsStatuses.deleteVoicemails,
);

export const selectVoicemailsAllLoaded = createSelector(
  [selectVoicemailsState],
  (voicemailsState) => voicemailsState.allLoaded,
);
