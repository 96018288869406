import { Purchase, PurchaseSummaryData, PurchaseSubmission, GAEventKey } from 'types';

import {
  formatNumber,
  formatPurchasePrice,
  getPurchaseSessionStorageData,
  setPurchaseSessionStorageData,
  removePurchaseSessionStorageData,
  isString,
  isNumber,
} from 'helpers';
import { sessionStoragePersistenceGetPaymentMethod } from 'helpers/paymentMethods';

import { API_ERRORS } from '@constants';
import { isApiPurchaseError } from 'api';

import {
  selectPurchaseCategoryId,
  selectPurchasePhoneNumber,
  selectPurchaseProductId,
  selectPurchaseUserEmail,
  selectUserNationalCountryIsoCode,
} from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  PurchaseActionSetIsFailureGeneral,
  PurchaseActionSetIsForbidden,
  PurchaseActionSetData,
  PurchaseActionRemoveData,
  PurchaseActionRestartAgain,
  PurchaseActionSetSummaryData,
  PurchaseActionSetSummaryIsLoading,
  PurchaseActionSetSummaryIsFailure,
  PurchaseActionSetSubmissionIsLoading,
  PurchaseActionSetSubmissionIsFailure,
  PurchaseActionSetSubmissionIsSuccess,
  PurchaseActionSetSubmissionErrorCodes,
} from '../../types';

export const purchaseSetIsForbidden = (isForbidden: boolean): PurchaseActionSetIsForbidden => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_IS_FORBIDDEN,
  isForbidden,
});

export const purchaseSetIsFailureGeneral = (isFailureGeneral: boolean): PurchaseActionSetIsFailureGeneral => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_IS_FAILURE_GENERAL,
  isFailureGeneral,
});

export const purchaseSetData = ({
  categoryId,
  virtualPhoneNumber,
  virtualPhoneNumberAsFormatted,
  email,
}: Pick<
  Purchase,
  'categoryId' | 'virtualPhoneNumber' | 'virtualPhoneNumberAsFormatted' | 'email'
>): PurchaseActionSetData => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_DATA,
  categoryId,
  virtualPhoneNumber,
  virtualPhoneNumberAsFormatted,
  email,
});

export const purchaseRestartAgain = (): PurchaseActionRestartAgain => ({
  type: REDUX_ACTION_TYPES.PURCHASE_RESTART_AGAIN,
});

export const purchaseRemoveData = (): PurchaseActionRemoveData => ({
  type: REDUX_ACTION_TYPES.PURCHASE_REMOVE_DATA,
});

export const purchaseGetDataHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const {
      virtualPhoneNumber: sessionVirtualPhoneNumber,
      categoryId: sessionCategoryId,
      email: sessionEmail,
    } = getPurchaseSessionStorageData();

    const virtualPhoneNumber = selectPurchasePhoneNumber(getState()) || sessionVirtualPhoneNumber;
    const categoryId = selectPurchaseCategoryId(getState()) || sessionCategoryId;
    const email = selectPurchaseUserEmail(getState()) || sessionEmail;

    if (categoryId && virtualPhoneNumber && email) {
      dispatch(purchaseSetIsForbidden(false));
      dispatch(
        purchaseSetData({
          categoryId,
          virtualPhoneNumber,
          virtualPhoneNumberAsFormatted: formatNumber(virtualPhoneNumber),
          email,
        }),
      );
    } else {
      dispatch(purchaseRemoveData());
      dispatch(purchaseSetIsForbidden(true));
    }
  };

export const purchaseSetDataHandler =
  ({
    categoryId,
    virtualPhoneNumber,
    email,
  }: Pick<Purchase, 'categoryId' | 'virtualPhoneNumber' | 'email'>): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    dispatch(purchaseRemoveData());
    setPurchaseSessionStorageData({
      virtualPhoneNumber,
      categoryId,
      email,
    });
    dispatch(
      purchaseSetData({
        categoryId,
        virtualPhoneNumber,
        virtualPhoneNumberAsFormatted: formatNumber(virtualPhoneNumber),
        email,
      }),
    );
  };

export const purchaseRemoveDataHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    removePurchaseSessionStorageData();
    dispatch(purchaseRemoveData());
    dispatch(purchaseSetIsForbidden(true));
  };

export const purchaseSetSummaryIsLoading = (isLoading: boolean): PurchaseActionSetSummaryIsLoading => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_SUMMARY_IS_LOADING,
  isLoading,
});

export const purchaseSetSummaryIsFailure = (isFailure: boolean): PurchaseActionSetSummaryIsFailure => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_SUMMARY_IS_FAILURE,
  isFailure,
});

export const purchaseSetSummaryData = (data: PurchaseSummaryData): PurchaseActionSetSummaryData => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_SUMMARY_DATA,
  data,
});

export const purchaseFetchAndSetSummaryDataHandler =
  ({ virtualPhoneNumber }: { virtualPhoneNumber: string }): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    const locationCountryCode = selectUserNationalCountryIsoCode(getState());

    try {
      dispatch(purchaseSetSummaryIsLoading(true));

      const apiReturn = await services.purchaseService.fetchProductPrices({
        virtualPhoneNumber,
        locationCountryCode,
      });

      if (
        apiReturn.isFailure ||
        !isNumber(apiReturn.productPriceFor1Month.price) ||
        !isString(apiReturn.productPriceFor1Month.currencyCode) ||
        !isString(apiReturn.productPriceFor1Month.productId)
      ) {
        throw new Error('Dev Error: Something went wrong during the fetch product prices.');
      }

      const summaryData: PurchaseSummaryData = {
        priceAsFormatted: formatPurchasePrice({
          price: apiReturn.productPriceFor1Month.price,
          currencyCode: apiReturn.productPriceFor1Month.currencyCode,
        }),
        productId: apiReturn.productPriceFor1Month.productId,
      };

      dispatch(purchaseSetSummaryData(summaryData));
    } catch {
      dispatch(purchaseSetSummaryIsLoading(false));
      dispatch(purchaseSetSummaryIsFailure(true));

      // we are considering this error as a "hard error" and the purchase flow will be prevented
      dispatch(purchaseSetIsFailureGeneral(true));
    }
  };

export const purchaseSetSubmissionIsLoading = (isLoading: boolean): PurchaseActionSetSubmissionIsLoading => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_SUBMISSION_IS_LOADING,
  isLoading,
});

export const purchaseSetSubmissionIsFailure = (isFailure: boolean): PurchaseActionSetSubmissionIsFailure => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_SUBMISSION_IS_FAILURE,
  isFailure,
});

export const purchaseSetSubmissionIsSuccess = (isSuccess: boolean): PurchaseActionSetSubmissionIsSuccess => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_SUBMISSION_IS_SUCCESS,
  isSuccess,
});

export const purchaseSetSubmissionErrorCodes = ({
  codeError,
  codePaymentResponse,
}: Pick<PurchaseSubmission, 'codeError' | 'codePaymentResponse'>): PurchaseActionSetSubmissionErrorCodes => ({
  type: REDUX_ACTION_TYPES.PURCHASE_SET_SUBMISSION_ERROR_CODES,
  codeError,
  codePaymentResponse,
});

export const purchaseSubmissionHandler =
  (paymentMethodId: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(purchaseSetSubmissionIsLoading(true));
      dispatch(purchaseSetSubmissionIsFailure(false));

      const virtualPhoneNumber = selectPurchasePhoneNumber(getState());
      const productId = selectPurchaseProductId(getState());
      const categoryId = selectPurchaseCategoryId(getState());

      if (!paymentMethodId) {
        throw Error('Dev Error: No payment method ID in purchase handler');
      }

      await services.purchaseService.buyProductForExistingCategory({
        virtualPhoneNumber,
        productId,
        categoryId,
        paymentMethodId,
      });

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.RENEW_SUBSCRIPTION_SUCCESS,
        variables: {
          payment_method: sessionStoragePersistenceGetPaymentMethod(),
        },
      });

      // success
      dispatch(purchaseSetSubmissionIsLoading(false));
      dispatch(purchaseSetSubmissionIsSuccess(true));
    } catch (error) {
      const isKnownErrorCode =
        isApiPurchaseError(error) &&
        (
          [
            API_ERRORS.PAYMENTS.PAYMENT_METHOD_EXPIRED,
            API_ERRORS.PAYMENTS.PAYMENT_PENDING,
            API_ERRORS.PAYMENTS.PAYMENT_DECLINED,
            API_ERRORS.PAYMENTS.PAYMENT_FAILED,
          ] as string[]
        ).includes(error.code);

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.RENEW_SUBSCRIPTION_ERROR,
        variables: {
          error_code: isKnownErrorCode ? error.code : '',
          payment_method: sessionStoragePersistenceGetPaymentMethod(),
        },
      });

      if (isKnownErrorCode) {
        // soft error
        dispatch(purchaseSetSubmissionIsLoading(false));
        dispatch(purchaseSetSubmissionIsFailure(true));
        dispatch(
          purchaseSetSubmissionErrorCodes({
            codeError: error.code,
            codePaymentResponse: error.additionalInfo?.paymentResponseCode || '',
          }),
        );
      } else {
        // hard error
        dispatch(purchaseSetSubmissionIsFailure(true));
        dispatch(purchaseSetIsFailureGeneral(true));
      }
    }
  };
