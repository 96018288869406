import { Reducer } from '@reduxjs/toolkit';

import { REDUX_ACTION_TYPES, BrowserTabsReduxState, BrowserTabsActionTypes } from '../../types';

export const browserTabsInitialState: BrowserTabsReduxState = {
  isActiveTab: true,
};

export const browserTabsReducer: Reducer<BrowserTabsReduxState, BrowserTabsActionTypes> = (state, action) => {
  const reducerState: BrowserTabsReduxState = state || browserTabsInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.BROWSER_TABS_SET_IS_CURRENT_ACTIVE: {
      return {
        isActiveTab: action.isActive,
      };
    }

    default:
      return reducerState;
  }
};
