import { useLocation } from 'react-router-dom';

import { isB2BProject } from 'helpers';

import { selectTrialAvailable, useWebAppSelector } from '@redux';
import { ApplicationPaths } from 'routes';

interface UseDetectPremiumOfferFlowReturn {
  isPremiumOfferFlow: boolean;
  isPremiumOfferFreeTrialFlow: boolean;
  isPremiumOfferTrialAvailable: boolean;
}

interface LocationState {
  redirectUri: string | undefined;
}

export const useDetectPremiumOfferFlow = (): UseDetectPremiumOfferFlowReturn => {
  const trialAvailable = useWebAppSelector(selectTrialAvailable);
  const { state, pathname } = useLocation();
  const { redirectUri }: LocationState = state || {};
  const isProjectB2B = isB2BProject();

  // TODO: isPremiumOfferFreeTrialFlow will be removed
  const isPremiumOfferFreeTrialFlow =
    [redirectUri, pathname].includes(ApplicationPaths.PURCHASE_PREMIUM_TRIAL) && !trialAvailable;

  /**
   * Premium offer and premium offer trial flow are mutually exclusive - logic wise
   * So, if you are on trial flow, that is not basic upgrade flow and vice-versa
   */
  const isPremiumOfferFlow =
    [redirectUri, pathname].includes(ApplicationPaths.PURCHASE_PREMIUM_UPGRADE) && !isPremiumOfferFreeTrialFlow;

  if (isProjectB2B) {
    return {
      isPremiumOfferFlow: false,
      isPremiumOfferFreeTrialFlow: false,
      isPremiumOfferTrialAvailable: false,
    };
  }

  return {
    isPremiumOfferFlow,
    isPremiumOfferFreeTrialFlow,
    isPremiumOfferTrialAvailable: trialAvailable,
  };
};
