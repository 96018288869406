import { Contact } from 'types';

import { formatNumber } from '../country_codes';

export interface CreateNotificationsBrowserContactNameProps {
  sourcePhoneNumber: string;
  contact?: Contact;
}

export const createNotificationsBrowserContactName = ({
  sourcePhoneNumber,
  contact,
}: CreateNotificationsBrowserContactNameProps): string => {
  if (contact !== undefined) {
    const { firstName = '', lastName = '' } = contact;

    const fullName = [firstName, lastName]
      .map((name) => name.trim())
      .filter((name) => name.length > 0)
      .join(' ');

    if (fullName.length > 0) {
      return fullName;
    }
  }

  return formatNumber(sourcePhoneNumber);
};
