import { Status, PrivacyFetchBlockedNumbersResponse } from 'types';

import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  PrivacyAddBlockedNumber,
  PrivacyRemoveBlockedNumber,
  PrivacySetBlockedNumbers,
  PrivacySetStatusFetchBlockedNumbers,
  PrivacySetStatusBlockNumber,
  PrivacySetStatusUnblockNumber,
} from '../../types';

export const privacySetBlockedNumbers = (data: PrivacyFetchBlockedNumbersResponse): PrivacySetBlockedNumbers => ({
  type: REDUX_ACTION_TYPES.PRIVACY_SET_BLOCKED_NUMBERS,
  data,
});

export const privacyAddBlockedNumber = (number: string): PrivacyAddBlockedNumber => ({
  type: REDUX_ACTION_TYPES.PRIVACY_ADD_BLOCKED_NUMBER,
  number,
});

export const privacyRemoveBlockedNumber = (number: string): PrivacyRemoveBlockedNumber => ({
  type: REDUX_ACTION_TYPES.PRIVACY_REMOVE_BLOCKED_NUMBER,
  number,
});

export const privacySetStatusFetchBlockedNumbers = (status: Status): PrivacySetStatusFetchBlockedNumbers => ({
  type: REDUX_ACTION_TYPES.PRIVACY_SET_STATUS_FETCH_BLOCKED_NUMBERS,
  status,
});

export const privacySetStatusBlockNumber = (status: Status): PrivacySetStatusBlockNumber => ({
  type: REDUX_ACTION_TYPES.PRIVACY_SET_STATUS_BLOCK_NUMBER,
  status,
});

export const privacySetStatusUnblockNumber = (status: Status): PrivacySetStatusUnblockNumber => ({
  type: REDUX_ACTION_TYPES.PRIVACY_SET_STATUS_UNBLOCK_NUMBER,
  status,
});

export const privacyFetchBlockedNumbersHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    dispatch(privacySetStatusFetchBlockedNumbers(Status.LOADING));

    try {
      const data = await services.privacyService.fetchBlockedNumbers();
      dispatch(privacySetBlockedNumbers(data));
      dispatch(privacySetStatusFetchBlockedNumbers(Status.SUCCEEDED));
    } catch (error) {
      dispatch(privacySetStatusFetchBlockedNumbers(Status.FAILED));
      throw error;
    }
  };

export const privacyBlockNumberHandler =
  (phoneNumber: string): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(privacySetStatusBlockNumber(Status.LOADING));
      await services.privacyService.blockNumber({ phoneNumber, blocked: true });
      dispatch(privacyAddBlockedNumber(phoneNumber));
      dispatch(privacySetStatusBlockNumber(Status.SUCCEEDED));
    } catch (error) {
      dispatch(privacySetStatusBlockNumber(Status.FAILED));
    }
  };

export const privacyUnblockNumberHandler =
  (phoneNumber: string): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(privacySetStatusUnblockNumber(Status.LOADING));
      await services.privacyService.blockNumber({ phoneNumber, blocked: false });
      dispatch(privacyRemoveBlockedNumber(phoneNumber));
      dispatch(privacySetStatusUnblockNumber(Status.SUCCEEDED));
    } catch (error) {
      dispatch(privacySetStatusUnblockNumber(Status.FAILED));
    }
  };
