import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { InfoBoxVariant } from '../../enums';
import { InfoBoxSimpleProps } from '../../types';

import styles from './InfoBoxSimple.module.scss';

const InfoBoxSimple: React.FC<InfoBoxSimpleProps & PropsWithChildren> = ({
  children,
  variant = InfoBoxVariant.WARNING,
  className = '',
}) => <div className={classnames(styles.root, styles[`variant_${variant}`], className)}>{children}</div>;

export default InfoBoxSimple;
