import {
  IntegrationExportContactData,
  IntegrationExportContactResponse,
  IntegrationsService,
  IntegrationDetailedSearchContactsRequiredResponse,
} from 'types';
import {
  IntegrationBasicSearchContactsRequest,
  IntegrationBasicSearchContactsResponse,
  IntegrationDetailedSearchContactsRequest,
} from 'types/server/contacts';
import { IntegrationStatusResponse, RequestParams } from 'types/server/integration';

import { apiIntegrations, apiContacts } from 'api';

const integrationsService: IntegrationsService = {
  fetchActiveIntegrations: (params: RequestParams = {}) =>
    apiIntegrations.get<IntegrationStatusResponse, RequestParams>('/status', params),

  fetchContactDetailedSearchInfo: (
    query: { request: IntegrationDetailedSearchContactsRequest },
    params: RequestParams = {},
  ) =>
    apiContacts.get<IntegrationDetailedSearchContactsRequiredResponse>('/authenticated/integrations/detailed-search', {
      ...query.request,
      ...params,
    }),

  fetchContactBasicSearchInfo: (
    query: { request: IntegrationBasicSearchContactsRequest },
    params: RequestParams = {},
  ) =>
    apiContacts.get<IntegrationBasicSearchContactsResponse>('/authenticated/integrations/basic-search', {
      ...query.request,
      ...params,
    }),

  exportContact: (data: IntegrationExportContactData) =>
    apiContacts.post<IntegrationExportContactResponse, IntegrationExportContactData>(
      '/authenticated/integrations/export',
      data,
    ),
};

export default integrationsService;
