import { useMemo } from 'react';

import { Category } from 'types';

import { canCategorySendMMS, canCategorySendSMS, isString } from 'helpers';

import { useWebAppSelector, selectCategories } from '@redux';

interface UseCategoriesCapabilities {
  canAnyOfCategoriesSendSMS: boolean;
  canAnyOfCategoriesSendMMS: boolean;
}

interface UseCategoryCapabilitiesById {
  canCategorySendSMS: boolean;
  canCategorySendMMS: boolean;
}

type UseCategoriesCapabilitiesReturn<ArgumentType> = ArgumentType extends Category['id']
  ? UseCategoryCapabilitiesById
  : UseCategoriesCapabilities;

export const useCategoriesCapabilities = <ArgumentType extends Category['id'] | unknown>(
  categoryId?: ArgumentType,
): UseCategoriesCapabilitiesReturn<ArgumentType> => {
  const categories = useWebAppSelector(selectCategories);

  // capabilities by category id
  const {
    virtualPhoneNumber: {
      smsSupported: categoryIdSmsSupported = false,
      mmsSupported: categoryIdMmsSupported = false,
    } = {},
  } = categories.find(({ id }) => id === categoryId) || {};
  const capabilitiesCategoryId: UseCategoryCapabilitiesById = {
    canCategorySendSMS: categoryIdSmsSupported === true,
    canCategorySendMMS: categoryIdMmsSupported === true,
  };

  // capabilities by all the categories
  const capabilitiesCategories: UseCategoriesCapabilities = {
    canAnyOfCategoriesSendSMS: categories.some(canCategorySendSMS),
    canAnyOfCategoriesSendMMS: categories.some(canCategorySendMMS),
  };

  const capabilities = isString(categoryId) && categoryId.length > 0 ? capabilitiesCategoryId : capabilitiesCategories;

  return useMemo(() => capabilities, [capabilities]) as UseCategoriesCapabilitiesReturn<ArgumentType>;
};
