import { createPopper, Instance, Modifier, OptionsGeneric, Placement, PositioningStrategy } from '@popperjs/core';
import { useCallback, useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type PopperModifiers = Array<Partial<Modifier<unknown, unknown>>>;
export type PopperPlacements = Placement;

export type PopperInstanceProps = {
  gutter?: number;
  strategy?: PositioningStrategy;
  popperModifiers?: PopperModifiers;
  placement?: PopperPlacements;
};

export type PopperProps<Ref extends HTMLElement, Dropdown extends HTMLElement> = {
  referenceElement: Ref | null;
  dropdownElement: Dropdown | null;
  isOpen: boolean;
  usePortal?: boolean;
  popperInstanceProps: PopperInstanceProps;
};

const usePopper = <Ref extends HTMLElement, Dropdown extends HTMLElement>({
  referenceElement,
  dropdownElement,
  isOpen,
  usePortal = false,
  popperInstanceProps: { placement = 'bottom', strategy = 'fixed', gutter = 12, popperModifiers },
}: PopperProps<Ref, Dropdown>): void => {
  const popperInstanceRef = useRef<Instance>();

  useEffect(() => {
    if (referenceElement && dropdownElement) {
      const initModifiers = [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top'],
          },
        },
        {
          name: 'offset',
          options: { offset: [0, gutter] },
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: referenceElement,
          },
        },
        {
          name: 'setDropdownWidth',
          enabled: strategy === 'fixed' || usePortal,
          phase: 'main',
          fn({ state }: Instance) {
            const { width } = state.rects.reference;
            // eslint-disable-next-line no-param-reassign
            state.elements.popper.style.width = `${width}px`;
          },
        },
      ];
      popperInstanceRef.current = createPopper(referenceElement, dropdownElement, {
        strategy,
        placement,
        modifiers: [...initModifiers, ...(popperModifiers || [])] as PopperModifiers,
      });
    }
  }, [referenceElement, dropdownElement, gutter, placement, popperModifiers, strategy, usePortal]);

  const setPopperListeners = useCallback(async (enabled: boolean) => {
    if (popperInstanceRef.current) {
      await popperInstanceRef.current.setOptions((popperOptions: Partial<OptionsGeneric<Modifier<string, never>>>) => ({
        ...popperOptions,
        modifiers: [
          ...(popperOptions.modifiers?.filter((modifier) => modifier.name !== 'eventListeners') || []),
          { name: 'eventListeners', enabled },
        ],
      }));

      // Update its position
      await popperInstanceRef.current.update();
    }
  }, []);

  useEffect(() => {
    // Turn event listeners off when popover closed
    setPopperListeners(isOpen);
  }, [isOpen, setPopperListeners]);
};

export default usePopper;
