import classNames from 'classnames';
import React from 'react';

import { SIPDataCallStatusesWebRTC } from '@redux/types';
import { testGetTestingAttributes, testIds } from '@testing';
import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { SIPCallsListItemIconStatesProps } from '../../types';

import styles from './SIPCallsListItemIconStates.module.scss';

const SIPCallsListItemIconStates: React.FC<SIPCallsListItemIconStatesProps> = ({
  isCallLocal,
  isCallRemote,
  isCallOngoing,
  isCallWaiting,
  statusWebRTC,
}) => {
  const isCallLocalPreparing = !isCallOngoing && isCallLocal;
  const isCallRemoteConnecting = !isCallOngoing && isCallRemote && statusWebRTC === SIPDataCallStatusesWebRTC.ANSWERED;
  const isCallLocalAndRemoteOnHold = isCallOngoing && isCallWaiting;
  const isCallLocalOngoing = isCallOngoing && isCallLocal;
  const isCallRemoteOngoing = isCallOngoing && isCallRemote;

  if (isCallLocalPreparing || isCallRemoteConnecting) {
    return (
      <span
        className={styles.root}
        {...testGetTestingAttributes(testIds.sipCallsListItemIconStatesLoading)}
      >
        <IconCommon
          name={IconCommonNames.SPINNER}
          size={IconCommonSizes.TINY}
          className={styles.root}
        />
      </span>
    );
  }

  if (isCallLocalAndRemoteOnHold) {
    return (
      <span
        className={classNames(styles.root, styles.waiting)}
        {...testGetTestingAttributes(testIds.sipCallsListItemIconStatesWaiting)}
      >
        <IconCommon
          name={IconCommonNames.PAUSE}
          size={IconCommonSizes.TINY}
        />
      </span>
    );
  }

  if (isCallLocalOngoing) {
    return (
      <span
        className={classNames(styles.root, styles.ongoing)}
        {...testGetTestingAttributes(testIds.sipCallsListItemIconStatesOutgoing)}
      >
        <IconCommon
          name={IconCommonNames.OUTGOING_CALL}
          size={IconCommonSizes.TINY}
        />
      </span>
    );
  }

  if (isCallRemoteOngoing) {
    return (
      <span
        className={classNames(styles.root, styles.ongoing)}
        {...testGetTestingAttributes(testIds.sipCallsListItemIconStatesIncoming)}
      >
        <IconCommon
          name={IconCommonNames.INCOMING_CALL}
          size={IconCommonSizes.TINY}
          className={classNames(styles.root, styles.ongoing)}
        />
      </span>
    );
  }

  return null;
};

export default SIPCallsListItemIconStates;
