import { SaveContactFormData, SaveSimpleContactRequest } from 'types';

import { normalizePhoneNumber } from '../index';

export const prepareContactFormDataForRequest = (formData: SaveContactFormData): SaveSimpleContactRequest => ({
  ...formData,
  // We are removing ids from phone objects because the backend does not want it
  phones: formData.phones.map(({ id: _, number, ...rest }) => ({
    ...rest,
    normalNumber: normalizePhoneNumber(number || ''),
    number,
  })),
  // We are removing ids from email objects because the backend does not want it
  emails: formData.emails.map(({ id: _, ...rest }) => ({
    ...rest,
  })),
});
