import { showSuccessToast, showWarningToast } from '@onoff/toast-notification';

import {
  NotificationsToastAutoRemovableHandlerProps,
  PreparedContact,
  SaveContactFormData,
  SaveSimpleContactRequest,
  SimpleContact,
  Status,
} from 'types';

import { isString, prepareContactFormDataForRequest } from 'helpers';
import ContactObject from 'helpers/classes/ContactObject';

import { getIntl } from '@intl';

import { selectContactsSimpleContactsMap, selectPrivacyBlockedNumbers, selectSimpleContacts } from '../../selectors';
import {
  ContactsAddSimpleContact,
  ContactsClearSearchString,
  ContactsDeleteSimpleContacts,
  ContactsPubNubAddSimpleContactHandlerProps,
  ContactsPubNubUpdateSimpleContactHandlerProps,
  ContactsReduxState,
  ContactsSetActiveId,
  ContactsSetSearchString,
  ContactsSetSimpleContacts,
  ContactsSetStatusAction,
  ContactsUnblockContactPhoneNumbersProps,
  ContactsUpdateSimpleContact,
  REDUX_ACTION_TYPES,
  ThunkResult,
  WebAppState,
} from '../../types';
import { privacyUnblockNumberHandler } from '../privacy';

export const contactsSet = (contacts: SimpleContact[]): ContactsSetSimpleContacts => ({
  type: REDUX_ACTION_TYPES.SET_SIMPLE_CONTACTS,
  contacts,
});

export const addSimpleContact = (contact: PreparedContact): ContactsAddSimpleContact => ({
  type: REDUX_ACTION_TYPES.ADD_SIMPLE_CONTACT,
  contact,
});

export const updateSimpleContact = (contact: PreparedContact): ContactsUpdateSimpleContact => ({
  type: REDUX_ACTION_TYPES.UPDATE_SIMPLE_CONTACT,
  contact,
});

export const deleteSimpleContacts = (contactIds: string[]): ContactsDeleteSimpleContacts => ({
  type: REDUX_ACTION_TYPES.DELETE_SIMPLE_CONTACTS,
  contactIds,
});

export const contactsSetSearchString = (searchString: string): ContactsSetSearchString => ({
  type: REDUX_ACTION_TYPES.CONTACTS_SET_SEARCH_STRING,
  searchString,
});

export const contactsClearSearchString = (): ContactsClearSearchString => ({
  type: REDUX_ACTION_TYPES.CONTACTS_CLEAR_SEARCH_STRING,
});

export const contactsSetActiveId = (contactId: string): ContactsSetActiveId => ({
  type: REDUX_ACTION_TYPES.CONTACTS_SET_ACTIVE_ID,
  contactId,
});

export const contactsSetStatus = (
  request: keyof ContactsReduxState['statuses'],
  status: Status,
): ContactsSetStatusAction => ({
  type: REDUX_ACTION_TYPES.CONTACTS_SET_STATUS,
  request,
  status,
});

const getNotificationMessage = (
  state: WebAppState,
  idList: [string, string],
): NotificationsToastAutoRemovableHandlerProps =>
  idList
    .map((id) => getIntl().formatMessage({ id }))
    .reduce(
      (result, message, index) => {
        // eslint-disable-next-line no-param-reassign
        result[index === 0 ? 'heading' : 'message'] = message;

        return result;
      },
      { heading: '', message: '' },
    );

export const createNewContact =
  (newContact: PreparedContact): ThunkResult<void> =>
  (dispatch, getState): void => {
    dispatch(addSimpleContact(newContact));

    const contacts = selectSimpleContacts(getState());
    const contact = contacts.find(({ id }) => id === newContact.id);

    if (contact === undefined) {
      throw new Error('contact should exist');
    }

    dispatch(contactsSetActiveId(contact.key));
  };

export const fetchContacts =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(contactsSetStatus('fetchContacts', Status.LOADING));
      const contacts = await services.contactsService.fetchAllContacts();

      dispatch(contactsSet(contacts));

      dispatch(contactsSetStatus('fetchContacts', Status.SUCCEEDED));
    } catch (error) {
      dispatch(contactsSetStatus('fetchContacts', Status.FAILED));
      throw error;
    }
  };

export const contactsToggleFavorite =
  (key: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    const contacts = selectContactsSimpleContactsMap(getState());
    const contact = contacts[key] ? { ...contacts[key] } : null;

    if (contact === null) {
      throw new Error('contact not found');
    }

    const updatedContact = {
      ...contact,
      favorite: !contact.favorite,
    };

    dispatch(updateSimpleContact(new ContactObject(updatedContact).prepareForSave()));

    try {
      await services.contactsService.toggleContactIsFavorite(updatedContact.id, updatedContact.favorite);

      const notificationMessage = getNotificationMessage(
        getState(),
        updatedContact.favorite
          ? [
              'Contacts.Notifications.add_contact_to_favorites_success_title',
              'Contacts.Notifications.add_contact_to_favorites_success_description',
            ]
          : [
              'Contacts.Notifications.remove_contact_from_favorites_success_title',
              'Contacts.Notifications.remove_contact_from_favorites_success_description',
            ],
      );

      showSuccessToast({
        ...notificationMessage,
      });
    } catch (error) {
      dispatch(updateSimpleContact(new ContactObject(contact).prepareForSave()));

      throw error;
    }
  };

export const contactsDeleteContact =
  (contactId?: string): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      if (!isString(contactId)) {
        throw new Error('contact not found');
      }

      dispatch(contactsSetStatus('deleteContact', Status.LOADING));
      await services.contactsService.deleteContact(contactId);
      dispatch(contactsSetStatus('deleteContact', Status.SUCCEEDED));
      dispatch(deleteSimpleContacts([contactId]));
    } catch (error) {
      dispatch(contactsSetStatus('deleteContact', Status.FAILED));
      throw error;
    }
  };

export const contactsUpdateNotes =
  (key: string, notes: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    const contacts = selectContactsSimpleContactsMap(getState());
    const contact = contacts[key] ? { ...contacts[key] } : null;

    if (contact === null) {
      throw new Error('contact not found');
    }

    const updatedContact: SaveSimpleContactRequest = {
      ...contact,
      notes,
    };

    dispatch(updateSimpleContact(new ContactObject(updatedContact).prepareForSave()));

    try {
      await services.contactsService.saveContact(updatedContact);

      showSuccessToast({
        heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_success' }),
        message: getIntl().formatMessage({ id: 'Notifications.Toast.description_success' }),
      });
    } catch (error) {
      dispatch(updateSimpleContact(new ContactObject(contact).prepareForSave()));

      throw error;
    }
  };

export const contactsUnblockContactPhoneNumbersHandler =
  ({ contactPhoneNumbers = [] }: ContactsUnblockContactPhoneNumbersProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    // data is optional, return if it is empty
    if (contactPhoneNumbers.length === 0) {
      return;
    }

    // state blocked phone numbers
    const blockedPhoneNumbers = selectPrivacyBlockedNumbers(getState());

    // contact phone numbers as international
    const contactPhoneNumbersAsInternational = contactPhoneNumbers
      .map(({ normalNumber }) => normalNumber)
      .filter((phoneNumber): phoneNumber is string => isString(phoneNumber) && phoneNumber.length > 0);

    // contacts' blocked phone numbers
    const blockedContactPhoneNumbers = contactPhoneNumbersAsInternational.filter((contactPhoneNumber) =>
      blockedPhoneNumbers.includes(contactPhoneNumber),
    );

    // contact has not blocked numbers
    if (blockedContactPhoneNumbers.length === 0) {
      return;
    }

    // contact has at least one blocked phone number, unblock them
    blockedContactPhoneNumbers.forEach((phoneNumber) => {
      dispatch(privacyUnblockNumberHandler(phoneNumber));
    });
  };

export const saveContact =
  (formData: SaveContactFormData): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    const { imageUrl } = formData;
    const preparedData = prepareContactFormDataForRequest(formData);
    const isUpdatingContact = typeof preparedData.id === 'string' && preparedData.id.length > 0;

    try {
      dispatch(contactsSetStatus('saveContact', Status.LOADING));

      // it is important to use prepareForSave, because to server we cannot send extra fields
      // we will get 400
      const preparedContactObj = new ContactObject(preparedData).prepareForSave();
      const { id: contactId = '' } = await services.contactsService.saveContact(preparedContactObj);

      const newContact = new ContactObject({
        ...preparedData,
        id: contactId,
      }).prepareForSave();

      if (isUpdatingContact) {
        const isNewContactImageAdded = imageUrl instanceof FileList;
        const isContactImageRemoved = formData.imageUrl === null;
        // empty string for no contact image, string url for existing image
        const isContactImageUntouched = typeof imageUrl === 'string';

        let newContactImageUrl: string | undefined = isContactImageUntouched ? imageUrl : '';

        if (isNewContactImageAdded) {
          const { imageUrl: newImageUrl } = await services.contactsService.saveContactImage(contactId, imageUrl[0]);
          newContactImageUrl = newImageUrl;
        } else if (isContactImageRemoved) {
          await services.contactsService.deleteContactImage({ contactId });
        }

        dispatch(
          updateSimpleContact({
            ...newContact,
            imageUrl: newContactImageUrl,
          }),
        );
      } else {
        dispatch(createNewContact(newContact));
      }

      showSuccessToast({
        heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_success' }),
        message: getIntl().formatMessage({
          id: isUpdatingContact ? 'Contacts.simple_contact_updated' : 'Contacts.Notifications.add_contact_title',
        }),
      });

      dispatch(contactsUnblockContactPhoneNumbersHandler({ contactPhoneNumbers: newContact.phones }));

      dispatch(contactsSetStatus('saveContact', Status.SUCCEEDED));
    } catch (error) {
      dispatch(contactsSetStatus('saveContact', Status.FAILED));
      throw error;
    }
  };

export const contactsToggleBlock =
  (key: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    const contacts = selectContactsSimpleContactsMap(getState());
    const contact = contacts[key] ? { ...contacts[key] } : null;

    if (contact === null) {
      throw new Error('contact not found');
    }

    const updatedContact = {
      ...contact,
      blocked: !contact.blocked,
    };

    dispatch(updateSimpleContact(new ContactObject(updatedContact).prepareForSave()));

    try {
      await services.contactsService.toggleContactIsBlocked(updatedContact.id, updatedContact.blocked);

      const notificationMessage = getNotificationMessage(
        getState(),
        updatedContact.blocked
          ? [
              'Contacts.Notifications.block_contact_success_title',
              'Contacts.Notifications.block_contact_success_description',
            ]
          : [
              'Contacts.Notifications.unblock_contact_success_title',
              'Contacts.Notifications.unblock_contact_success_description',
            ],
      );

      if (updatedContact.blocked) {
        showWarningToast({
          ...notificationMessage,
        });
      } else {
        showSuccessToast({
          ...notificationMessage,
        });
      }
    } catch (error) {
      dispatch(updateSimpleContact(new ContactObject(contact).prepareForSave()));

      throw error;
    }
  };

export const contactsPubNubAddSimpleContactHandler =
  ({ contact }: ContactsPubNubAddSimpleContactHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    dispatch(addSimpleContact(new ContactObject(contact).prepareForSave()));
  };

export const contactsPubNubUpdateSimpleContactHandler =
  ({ contact }: ContactsPubNubUpdateSimpleContactHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    dispatch(updateSimpleContact(new ContactObject(contact).prepareForSave()));
  };
