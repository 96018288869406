import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@onoff/ui';

import { useWebAppDispatch, userDeleteHandler } from '@redux';
import { IllustrationCommon, IllustrationCommonNames } from 'components/Illustration';
import { Info } from 'components/Info';
import { InputCheckbox, InputPassword } from 'components/Input';
import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal';

import { ModalsAccountDeletionSteps } from '../../enums';

import styles from './ModalsAccountDeletionContent.module.scss';

export interface ModalsAccountDeletionContentProps {
  onRequestClose: () => void;
  deletionReasonId: string;
}

const ModalsAccountDeletionContent: React.FC<ModalsAccountDeletionContentProps> = ({
  onRequestClose,
  deletionReasonId,
}) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const [step, setStep] = useState(ModalsAccountDeletionSteps.VALIDATION);
  const [password, setPassword] = useState('');
  const [checkboxStates, setCheckboxStates] = useState({
    information: false,
    contacts: false,
    numbers: false,
  });

  const checkboxList = [
    {
      id: 1,
      checked: checkboxStates.information,
      label: intl.formatMessage({ id: 'Settings.ModalAccountDeletion.confirmation_checkbox_1' }),
      onChange: (checked) => setCheckboxStates((state) => ({ ...state, information: checked })),
    },
    {
      id: 2,
      checked: checkboxStates.contacts,
      label: intl.formatMessage({ id: 'Settings.ModalAccountDeletion.confirmation_checkbox_2' }),
      onChange: (checked) => setCheckboxStates((state) => ({ ...state, contacts: checked })),
    },
    {
      id: 3,
      checked: checkboxStates.numbers,
      label: intl.formatMessage({ id: 'Settings.ModalAccountDeletion.confirmation_checkbox_3' }),
      onChange: (checked) => setCheckboxStates((state) => ({ ...state, numbers: checked })),
    },
  ];

  const allCheckboxesChecked = checkboxStates.contacts && checkboxStates.information && checkboxStates.numbers;

  const handleDeleteAccount = () => {
    dispatch(
      userDeleteHandler({
        password,
        errorNotification: intl.formatMessage({ id: 'ErrorCodes.password_invalid' }),
        deletionReasonId,
      }),
    );
  };

  return (
    <>
      <ModalHeader title={intl.formatMessage({ id: 'Settings.ModalAccountDeletion.title' })} />
      <ModalBody className={styles.body}>
        <IllustrationCommon
          name={IllustrationCommonNames.ACCOUNT_DELETE}
          className={styles.illustration}
        />
        <Info
          className={styles.info}
          classNameIcon={styles.infoIcon}
        >
          {intl.formatMessage({ id: 'Settings.ModalAccountDeletion.warning' })}
        </Info>
        <div className={styles.content}>
          {step === ModalsAccountDeletionSteps.VALIDATION && (
            <>
              <h3 className={styles.title}>
                {intl.formatMessage({ id: 'Settings.ModalAccountDeletion.confirmation_title' })}
              </h3>
              {checkboxList.map(({ id, label, checked, onChange }) => (
                <InputCheckbox
                  key={id}
                  checked={checked}
                  onChange={onChange}
                  className={styles.checkboxContainer}
                >
                  {label}
                </InputCheckbox>
              ))}
            </>
          )}
          {step === ModalsAccountDeletionSteps.CONFIRMATION && (
            <>
              <h3 className={styles.title}>
                {intl.formatMessage({ id: 'Settings.ModalAccountDeletion.confirmation_password_title' })}
              </h3>
              <InputPassword
                hasToggleVisibility
                className={styles.input}
                value={password}
                onChange={setPassword}
              />
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="ghost"
          colorScheme="black"
          size={'large'}
          onClick={onRequestClose}
        >
          {intl.formatMessage({ id: 'Settings.ModalAccountDeletion.button_cancel' })}
        </Button>
        {step === ModalsAccountDeletionSteps.VALIDATION && (
          <Button
            colorScheme="destructive"
            disabled={!allCheckboxesChecked}
            onClick={() => setStep(ModalsAccountDeletionSteps.CONFIRMATION)}
            size={'large'}
            className={styles.confirmButton}
          >
            {intl.formatMessage({ id: 'Settings.ModalAccountDeletion.button_continue' })}
          </Button>
        )}
        {step === ModalsAccountDeletionSteps.CONFIRMATION && (
          <Button
            colorScheme="destructive"
            disabled={password.length < 1}
            onClick={handleDeleteAccount}
            size={'large'}
            className={styles.confirmButton}
          >
            {intl.formatMessage({ id: 'Settings.ModalAccountDeletion.button_confirm' })}
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalsAccountDeletionContent;
