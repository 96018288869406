import { useEffect } from 'react';

import { isB2BProject } from 'helpers';

import { useUserCapabilities } from 'hooks';

const useNotificationsBrowserWindowName = (): string => {
  const { isUserB2B } = useUserCapabilities();
  const windowName = `Onoff${isUserB2B || isB2BProject() ? 'Business' : 'Web'}AppWindow`;

  useEffect(() => {
    if (windowName) {
      window.name = windowName;
    }
  }, [windowName]);

  return windowName;
};

export default useNotificationsBrowserWindowName;
