import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Radio } from '@onoff/ui';

import { useFormatPrice } from '../../hooks';
import { SelectionItemProps } from '../../types';

import styles from './SelectionItem.module.scss';

export const SelectionItem: React.FC<SelectionItemProps> = ({ plan, isSelected, onSelect }) => {
  const formatPrice = useFormatPrice();

  return (
    <Radio
      className={styles.root}
      checked={isSelected}
      onChange={() => {
        onSelect(plan.period);
      }}
    >
      <div className={styles.content}>
        <div className={styles.description}>
          <span className={styles.period}>
            <span>
              <FormattedMessage
                id="RenewalPeriod.period"
                values={{ period: plan.period }}
              />
            </span>
            {plan.savings > 0 && <span className={styles.savings}>{`-${plan.savings}%`}</span>}
          </span>
          <span className={styles.descriptionText}>
            {plan.period === 1 ? (
              <FormattedMessage id="Modals.RenewalPeriod.SelectionItem.description_one_month" />
            ) : (
              <FormattedMessage
                id="Modals.RenewalPeriod.SelectionItem.description"
                values={{
                  price: formatPrice(plan.price, plan.currencyCode),
                }}
              />
            )}
          </span>
        </div>
        <span className={styles.price}>
          <FormattedMessage
            id="Modals.RenewalPeriod.SelectionItem.price"
            values={{
              price: formatPrice(plan.pricePerMonth, plan.currencyCode),
            }}
          />
        </span>
      </div>
    </Radio>
  );
};
