import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast } from '@onoff/toast-notification';

import {
  useWebAppDispatch,
  useWebAppSelector,
  sipSetDataCallStatusFeatureMuteByWebRTCIdHandler,
  sipSetDataCallStatusFeatureUnMuteByWebRTCIdHandler,
  selectSIPDataCallByStatusFeatureMute,
  selectSIPDataCallByStatusFeatureUnMute,
} from '@redux';
import { SIPStatisticsScopes, SIPDataCallStatusesFeatureMute, SIPDataCallStatusesFeatureUnMute } from '@redux/types';

import { sipAudioManagementAudioPlay, sipDebugConsoleLogger } from '../../../../../../../../helpers';
import { JsSIPMediaConstraints } from '../../../../../../../../types';
import { useSIPMediaConstraints } from '../../../../../../../useSIPMedia';
import { useSIPStatistics } from '../../../../../../../useSIPStatistics';

import { UseWebRTCSessionMuteOrUnMuteProps } from './types';

export const useWebRTCSessionsMuteOrUnMute = ({ propWebRTCSessions }: UseWebRTCSessionMuteOrUnMuteProps): void => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { idWebRTCForMute, idTransactionForMute, originatorForMute } = useWebAppSelector(
    selectSIPDataCallByStatusFeatureMute,
  );
  const { idWebRTCForUnMute, idTransactionForUnMute, originatorForUnMute } = useWebAppSelector(
    selectSIPDataCallByStatusFeatureUnMute,
  );

  const { constraints } = useSIPMediaConstraints();
  const { sipSendErrorToSentry, sipSendErrorToGTM } = useSIPStatistics();

  const propWebRTCSessionForMute = propWebRTCSessions.find(({ id }) => id === idWebRTCForMute);
  const propWebRTCSessionForUnMute = propWebRTCSessions.find(({ id }) => id === idWebRTCForUnMute);

  const callbackWebRTCSessionsMuteHandler = (): void => {
    if (idWebRTCForMute && idTransactionForMute && originatorForMute && propWebRTCSessionForMute) {
      try {
        propWebRTCSessionForMute.mute();

        dispatch(
          sipSetDataCallStatusFeatureMuteByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForMute,
            propCallStatusFeatureMute: SIPDataCallStatusesFeatureMute.MUTED,
          }),
        );
      } catch (error) {
        sipDebugConsoleLogger('SIP WEBRTC: CALL MUTE IS FAILED!', { error });

        const errorMessage = error instanceof Error ? error.message : error;

        dispatch(
          sipSetDataCallStatusFeatureMuteByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForMute,
            propCallStatusFeatureMute: SIPDataCallStatusesFeatureMute.FAILED,
          }),
        );

        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_WEBRTC,
          error: `CALL MUTE IS FAILED! "${errorMessage}"`,
        });

        sipSendErrorToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_WEBRTC,
          sipCallOriginator: originatorForMute,
          sipCallTitle: 'CALL MUTE IS FAILED!',
          sipCallDescription: `${errorMessage}`,
          sipCallTransactionId: idTransactionForMute,
        });

        showErrorToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.CallMuteFailure.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.CallMuteFailure.description' }),
        });
      }
    }
  };

  const callbackWebRTCSessionsUnMuteHandler = (): void => {
    if (idWebRTCForUnMute && idTransactionForUnMute && originatorForUnMute && propWebRTCSessionForUnMute) {
      try {
        propWebRTCSessionForUnMute.unmute(constraints as JsSIPMediaConstraints);
        sipAudioManagementAudioPlay({ idWebRTC: idWebRTCForUnMute });

        dispatch(
          sipSetDataCallStatusFeatureUnMuteByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForUnMute,
            propCallStatusFeatureUnMute: SIPDataCallStatusesFeatureUnMute.UNMUTED,
          }),
        );
      } catch (error) {
        sipDebugConsoleLogger('SIP WEBRTC: CALL UNMUTE IS FAILED!', { error });

        const errorMessage = error instanceof Error ? error.message : error;

        dispatch(
          sipSetDataCallStatusFeatureUnMuteByWebRTCIdHandler({
            propCallIdWebRTC: idWebRTCForUnMute,
            propCallStatusFeatureUnMute: SIPDataCallStatusesFeatureUnMute.FAILED,
          }),
        );

        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_WEBRTC,
          error: `CALL UNMUTE IS FAILED! "${errorMessage}"`,
        });

        sipSendErrorToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_WEBRTC,
          sipCallOriginator: originatorForUnMute,
          sipCallTitle: 'CALL UNMUTE IS FAILED!',
          sipCallDescription: `${errorMessage}`,
          sipCallTransactionId: idTransactionForUnMute,
        });

        showErrorToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.CallUnMuteFailure.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.CallUnMuteFailure.description' }),
        });
      }
    }
  };

  useEffect(callbackWebRTCSessionsMuteHandler, [
    idWebRTCForMute,
    idTransactionForMute,
    originatorForMute,
    propWebRTCSessionForMute,
    intl,
    dispatch,
    sipSendErrorToSentry,
    sipSendErrorToGTM,
  ]);

  useEffect(callbackWebRTCSessionsUnMuteHandler, [
    idWebRTCForUnMute,
    idTransactionForUnMute,
    originatorForUnMute,
    propWebRTCSessionForUnMute,
    constraints,
    intl,
    dispatch,
    sipSendErrorToSentry,
    sipSendErrorToGTM,
  ]);
};
