import classNames from 'classnames';
import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePopper } from 'react-popper';

import { Category, Contact, StyleableComponent } from 'types';

import {
  filterContactsBySearchString,
  getContactFullName,
  getContactListPhoneNumberCards,
  scrollToBottom,
} from 'helpers';

import {
  useWebAppSelector,
  selectCategoriesByCanSendMessages,
  selectSimpleContacts,
  selectThreadsMostRecentContact,
  selectUserCountryIsoCode,
} from '@redux';
import { DropdownPhoneNumber, DropdownPhoneNumberOptionProps } from 'components/Dropdowns';
import { useClickAway, useUserCapabilities } from 'hooks';

import { getSelectedPhoneNumber } from '../../helpers';
import { SelectedPhoneNumber, SelectedPhoneNumberWithId } from '../../types';
import ModalsNewThreadContactFieldThumb from '../ModalsNewThreadContactFieldThumb';

import styles from './ModalsNewThreadContactField.module.scss';

interface ModalsNewThreadContactFieldProps extends StyleableComponent {
  isInputDisabled?: boolean;
  onRemoveContact?: (value: SelectedPhoneNumberWithId['id']) => void;
  onSelectContact?: (value: SelectedPhoneNumber) => void;
  selectedPhoneNumberWithIdList?: Array<SelectedPhoneNumberWithId>;
  hasFocusInitially?: boolean;
}

const ModalsNewThreadContactField: React.FC<ModalsNewThreadContactFieldProps> = ({
  className,
  isInputDisabled = false,
  onRemoveContact = () => undefined,
  onSelectContact = () => undefined,
  selectedPhoneNumberWithIdList = [],
  hasFocusInitially = true,
}) => {
  const intl = useIntl();

  const listContact = useWebAppSelector(selectSimpleContacts);
  const listContactMostRecent = useWebAppSelector(selectThreadsMostRecentContact);
  const listCategory = useWebAppSelector(selectCategoriesByCanSendMessages);
  const countryIsoCode = useWebAppSelector(selectUserCountryIsoCode);
  const { isUserB2B } = useUserCapabilities();

  const refInput = useRef<HTMLInputElement>(null);
  const refField = useRef<HTMLDivElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const refObserver = useRef<IntersectionObserver | null>(null);
  const [refDropdown, setRefDropdown] = useState<HTMLDivElement | null>(null);

  const [isContactSearchFocused, setIsContactSearchFocused] = useState<boolean>(hasFocusInitially);
  const [inputValue, setInputValue] = useState<string>('');
  const [isFieldCollapsed, setIsFieldCollapsed] = useState<boolean>(false);
  const [listContactsMostRecent, setListContactsMostRecent] = useState<Array<DropdownPhoneNumberOptionProps>>([]);
  const [listContactsSearch, setContactsListSearch] = useState<Array<DropdownPhoneNumberOptionProps>>([]);
  const [countContactsSelectedAndVisible, setCountContactsSelectedAndVisible] = useState<number>(0);
  const [countContactsUnVisible, setCountContactsUnVisible] = useState<number>(0);
  const countContactsSelected = selectedPhoneNumberWithIdList.length;

  const isCounterVisible = isFieldCollapsed && countContactsUnVisible > 0;
  const canDropdownBeVisible = isContactSearchFocused && !isInputDisabled;
  const isDropdownVisible =
    inputValue.length === 0 ? listContactsMostRecent.length > 0 && canDropdownBeVisible : canDropdownBeVisible;

  const {
    styles: { popper: popperStyles },
    attributes: { popper: popperAttributes },
  } = usePopper(refInput.current, refDropdown, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: { offset: [0, 18] },
      },
    ],
  });

  const setContactFieldThumbAndCleanInput = (input: string, contactId?: string) => {
    onSelectContact(getSelectedPhoneNumber(input, contactId, listContact, listCategory, countryIsoCode));
    setInputValue('');
  };

  const onFocusInput = () => {
    refInput.current?.focus();
    setIsContactSearchFocused(true);
    setIsFieldCollapsed(false);
    scrollToBottom(refInput.current);
  };

  const handlerSelectPhoneNumber = (input: string, contactId?: string) => {
    setContactFieldThumbAndCleanInput(input, contactId);
    onFocusInput();
  };

  const handlerInputBlur = () => {
    setIsContactSearchFocused(false);
    refField.current?.scroll(0, 0);
    if (inputValue) {
      setContactFieldThumbAndCleanInput(inputValue);
    }
    setIsFieldCollapsed(true);
  };

  const handlerKeyboardEvent = (event: KeyboardEvent) => {
    if (isContactSearchFocused && !isInputDisabled) {
      const { key } = event;
      if (inputValue.length > 0 && (key === 'Enter' || key === 'Tab')) {
        handlerSelectPhoneNumber(inputValue);
        onFocusInput();
      }
      if (inputValue.length <= 0 && countContactsSelected > 0 && key === 'Backspace') {
        const lastContact = selectedPhoneNumberWithIdList[countContactsSelected - 1];
        onRemoveContact(lastContact.id);
      }
    }
  };

  const handlerRemoveContact = (selectedPhoneNumberWithId: SelectedPhoneNumberWithId['id']) => {
    onFocusInput();
    onRemoveContact(selectedPhoneNumberWithId);
  };

  const callbackInputFocusHandler = (): void => {
    if (isContactSearchFocused && !isInputDisabled) {
      onFocusInput();
    } else {
      refInput.current?.blur();
    }
  };

  const callbackFilterAndPrepareContactsBySelectionsHandler = useCallback(
    (contacts: Contact[], categories: Category[]): DropdownPhoneNumberOptionProps[] => {
      const filteredContacts = contacts.filter(
        (displayedContact) =>
          !selectedPhoneNumberWithIdList.map((selected) => selected.contact?.id).includes(displayedContact.id),
      );
      return getContactListPhoneNumberCards(filteredContacts, categories);
    },
    [selectedPhoneNumberWithIdList],
  );

  const callbackContactsListHandler = (): void => {
    const isSearch = inputValue.length > 0;
    if (isSearch) {
      setContactsListSearch(
        callbackFilterAndPrepareContactsBySelectionsHandler(
          filterContactsBySearchString(listContact, inputValue),
          listCategory,
        ),
      );
    } else {
      setListContactsMostRecent(
        callbackFilterAndPrepareContactsBySelectionsHandler(listContactMostRecent, listCategory),
      );
    }
  };

  const callbackContactsVisibilityObserverInitHandler = () => {
    if (refObserver.current === null) {
      refObserver.current = new IntersectionObserver((entries) => {
        const countOfVisibleElements = entries.filter(
          (entry) => entry.target.classList.contains(styles.thumb) && entry.intersectionRatio > 0.1,
        ).length;

        setCountContactsSelectedAndVisible(countOfVisibleElements);
      });
    }

    return () => {
      refObserver.current?.disconnect();
      refObserver.current = null;
    };
  };

  const callbackContactsVisibilityObserverUpdateHandler = (): void => {
    if (selectedPhoneNumberWithIdList.length > 0) {
      const selectedPhoneNumberElements: Element[] = Array.from(refContainer.current?.children || []);
      if (selectedPhoneNumberElements.length > 0) {
        selectedPhoneNumberElements.forEach((element) => {
          if (isFieldCollapsed) {
            refObserver.current?.observe(element);
          } else {
            refObserver.current?.unobserve(element);
          }
        });
      }
    }
  };

  const callbackContactsVisibilityCountUnVisibleHandler = (): void => {
    setCountContactsUnVisible(countContactsSelected - countContactsSelectedAndVisible);
  };

  useClickAway(refField, handlerInputBlur);

  useEffect(callbackInputFocusHandler, [isContactSearchFocused, isInputDisabled]);

  useEffect(callbackContactsListHandler, [
    inputValue,
    listContact,
    listCategory,
    listContactMostRecent,
    callbackFilterAndPrepareContactsBySelectionsHandler,
  ]);

  useEffect(callbackContactsVisibilityObserverInitHandler, []);

  useEffect(callbackContactsVisibilityObserverUpdateHandler, [selectedPhoneNumberWithIdList, isFieldCollapsed]);

  useEffect(callbackContactsVisibilityCountUnVisibleHandler, [countContactsSelectedAndVisible, countContactsSelected]);

  return (
    <div
      ref={refField}
      onClick={onFocusInput}
      className={classNames(className, styles.root, {
        [styles.collapsed]: isFieldCollapsed,
      })}
    >
      <div
        ref={refContainer}
        className={styles.container}
      >
        {selectedPhoneNumberWithIdList.map(({ category, contact, id, isValid, label }) => (
          <ModalsNewThreadContactFieldThumb
            key={id}
            avatarProps={
              contact && {
                backgroundColor: category?.color,
                fullName: getContactFullName(contact),
                imageSrc: contact?.imageUrl,
              }
            }
            id={id}
            isValid={isValid}
            label={label}
            onRemove={handlerRemoveContact}
            className={styles.thumb}
          />
        ))}
        <input
          ref={refInput}
          autoFocus={isContactSearchFocused}
          className={styles.input}
          onBlur={(event) => {
            if (refContainer.current && !refContainer.current.contains(event.target)) {
              setIsContactSearchFocused(false);
            }
          }}
          onFocus={onFocusInput}
          onInput={(e) => setInputValue(e.currentTarget.value)}
          placeholder={
            countContactsSelected < 1 ? intl.formatMessage({ id: 'ModalNewThread.contact_field_placeholder' }) : ''
          }
          size={inputValue.length > 0 ? inputValue.length : 1}
          type="text"
          value={inputValue}
          onKeyDown={handlerKeyboardEvent}
        />
      </div>

      {isCounterVisible ? (
        <div
          className={classNames(styles.counter, {
            [styles.isB2B]: isUserB2B,
          })}
        >
          <FormattedMessage
            id="ModalNewThread.selected_remaining_contacts_count"
            values={{
              count: countContactsUnVisible,
            }}
          />
        </div>
      ) : null}

      {isDropdownVisible ? (
        <DropdownPhoneNumber
          ref={setRefDropdown}
          className={styles.dropdown}
          optionList={inputValue.length > 0 ? listContactsSearch : listContactsMostRecent}
          onClickItem={handlerSelectPhoneNumber}
          title={
            inputValue.length > 0
              ? intl.formatMessage({ id: 'DialPad.search_results' })
              : intl.formatMessage({ id: 'DialPad.most_recent_contacts' })
          }
          style={popperStyles}
          {...popperAttributes}
        />
      ) : null}
    </div>
  );
};

export default ModalsNewThreadContactField;
