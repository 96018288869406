import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Radio } from '@onoff/ui';

import { RenewalPeriod } from 'types';

import { PlanPriceToDisplay } from '../../types';

import styles from './PremiumPlanSelector.module.scss';

type PremiumPlanSelectorProps = {
  plans: PlanPriceToDisplay[];
  selectedPeriod?: RenewalPeriod;
  setSelectedPeriod: React.Dispatch<React.SetStateAction<RenewalPeriod>>;
};

const PremiumPlanSelector: React.FC<PremiumPlanSelectorProps> = ({ plans, selectedPeriod, setSelectedPeriod }) => (
  <div className={styles.plans}>
    {plans.map((plan) => (
      <Radio
        key={plan.productId}
        className={classNames(styles.label, { [styles.checked]: plan.month === selectedPeriod })}
        onChange={() => {
          if (plan.month) {
            setSelectedPeriod(plan.month);
          }
        }}
        checked={plan.month === selectedPeriod}
        colorScheme="b2c"
      >
        <div className={styles.labelText}>
          <span className={styles.planDetails}>
            <span className={styles.planType}>
              <span className={styles.planTitle}>
                <FormattedMessage
                  id={`${plan.month === 12 ? 'ModalPremiumPlan.Plans.yearly' : 'ModalPremiumPlan.Plans.monthly'}`}
                />
              </span>
              {plan.percentageSaved && <span className={styles.tag}>{plan.percentageSaved}</span>}
            </span>
            {plan.hasPriceAdvantage && (
              <span className={styles.discount}>
                <span className={styles.prevPrice}>{plan.priceInDisadvantage}</span>
                <span>
                  {' '}
                  <FormattedMessage
                    id={`${
                      plan.month === 12 ? 'ModalPremiumPlan.Plans.yearly_price' : 'ModalPremiumPlan.Plans.monthly_price'
                    }`}
                    values={{ price: plan.priceInAdvantage }}
                  />
                </span>
              </span>
            )}
          </span>
          <span className={styles.planDetails}>
            <span className={styles.planType}>
              <FormattedMessage
                id="ModalPremiumPlan.Plans.monthly_price"
                values={{
                  price: plan.monthlyPrice,
                }}
              />
            </span>
          </span>
        </div>
      </Radio>
    ))}
  </div>
);

export default PremiumPlanSelector;
