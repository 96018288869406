import React from 'react';

import { StyleableComponent } from 'types';

const DropLeft: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53_1071)">
      <path
        d="M12.2907 8.70956L9.7007 11.2996C9.3107 11.6896 9.3107 12.3196 9.7007 12.7096L12.2907 15.2996C12.9207 15.9296 14.0007 15.4796 14.0007 14.5896V9.40956C14.0007 8.51956 12.9207 8.07956 12.2907 8.70956Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_1071">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DropLeft;
