import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@onoff/ui';

import { Status } from 'types';

import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { getButtonColorScheme, getButtonIntlKey, getButtonVariant } from '../../helpers';

import styles from './ModalsExportContactButton.module.scss';

export interface IntegrationCRMExportContactButtonProps {
  status: Status;
  onClick?: () => void;
}

const ModalsExportContactButton: React.FC<IntegrationCRMExportContactButtonProps> = ({ status, onClick }) => {
  const buttonIntlKey = getButtonIntlKey(status);
  const buttonVariant = getButtonVariant(status);
  const buttonColorScheme = getButtonColorScheme(status);

  const onClickHandler = (): void => {
    if (onClick !== undefined && [Status.IDLE, Status.FAILED].includes(status)) {
      onClick();
    }
  };

  return (
    <Button
      className={classNames(styles.root, styles[status.toLowerCase()])}
      onClick={onClickHandler}
      loading={status === Status.LOADING}
      variant={buttonVariant}
      colorScheme={buttonColorScheme}
      size="small"
    >
      {status === Status.SUCCEEDED && (
        <IconCommon
          name={IconCommonNames.CHECK}
          size={IconCommonSizes.MINI}
          className={styles.check}
        />
      )}
      {buttonIntlKey && <FormattedMessage id={buttonIntlKey} />}
    </Button>
  );
};

export default ModalsExportContactButton;
