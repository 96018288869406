import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast } from '@onoff/toast-notification';

import { ActiveIntegration, Status } from 'types';

import { useWebAppDispatch } from '@redux';
import { IntegrationCRMLogo } from 'components/IntegrationCRM';

import ModalsExportContactButton from '../ModalsExportContactButton';

import styles from './ModalsExportContactListItem.module.scss';

export interface IntegrationCRMExportContactModalRowProps {
  integration: ActiveIntegration;
  status: Status;
  onExportOrRetryClick?: () => void;
}

const ModalsExportContactListItem: React.FC<IntegrationCRMExportContactModalRowProps> = ({
  integration: { name, titleLabel, iconUrl },
  status,
  onExportOrRetryClick,
}) => {
  const dispatch = useWebAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (status === Status.FAILED) {
      showErrorToast({
        heading: intl.formatMessage({ id: 'Integrations.ExportContactModal.ErrorToast.title' }),
        message: intl.formatMessage({ id: 'Integrations.ExportContactModal.ErrorToast.description' }),
      });
    }
  }, [status, dispatch, intl]);

  return (
    <li className={styles.root}>
      <IntegrationCRMLogo
        url={iconUrl}
        className={styles.logo}
      />
      <span className={styles.name}>{titleLabel || name}</span>
      <ModalsExportContactButton
        status={status}
        onClick={onExportOrRetryClick}
      />
    </li>
  );
};

export default ModalsExportContactListItem;
