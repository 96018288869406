import classNames from 'classnames';
import React from 'react';

import { testGetTestingAttributes } from '@testing';

import { InfoTopBarContentProps } from '../../types';

import styles from './InfoTopBarContent.module.scss';

const InfoTopBarContent: React.FC<InfoTopBarContentProps> = ({
  mainRow = '',
  additionalRow = '',
  propTestIds = {},
  className = '',
}) => (
  <div
    className={classNames(
      styles.root,
      {
        [styles.hasAdditionalRow]: additionalRow.length > 0,
      },
      className,
    )}
  >
    {mainRow.length > 0 && (
      <div
        className={classNames(styles.mainRow)}
        {...testGetTestingAttributes(propTestIds.mainRow)}
      >
        {mainRow}
      </div>
    )}
    {additionalRow.length > 0 && (
      <div
        className={classNames(styles.additionalRow)}
        {...testGetTestingAttributes(propTestIds.additionalRow)}
      >
        {additionalRow}
      </div>
    )}
  </div>
);

export default InfoTopBarContent;
