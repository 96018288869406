import { Status, ProvisionSipConfigResponse, DomainSrvRecord } from 'types';

export enum SIPStatisticsScopes {
  SIP = 'SIP',
  SIP_CONNECT = 'SIP CONNECT',
  SIP_CONNECTED = 'SIP CONNECTED',
  SIP_FAILED = 'SIP FAILED',
  SIP_ICE_GATHERING_TIMEOUT = 'SIP ICE GATHERING TIMEOUT',
  SIP_WEBRTC = 'SIP WEBRTC',
  SIP_HOOKS = 'SIP HOOKS',
  SIP_RINGTONE = 'SIP RINGTONE',
  SIP_MEDIA_PERMISSION = 'SIP MEDIA PERMISSION',
  SIP_INIT_USER_DATA = 'SIP INIT USER DATA',
  SIP_PREPARE_OUTGOING_CALL = 'SIP PREPARE OUTGOING CALL',
  SIP_INIT_CALL_DATA = 'SIP INIT CALL DATA',
}

export enum SIPStatisticsCallCTAs {
  CTA_DEBUG = 'DEBUG',
  CTA_DIALPAD = 'dialpad',
  CTA_CALLLOGS = 'callLogs_quickAction',
  CTA_VOICEMAILS = 'vm_quickAction',
  CTA_MESSAGES = 'msg_quickAction',
  CTA_CONTACTS = 'contacts_quickAction',
}

/**
 * SIP RESPONSE CODES:
 * https://en.wikipedia.org/wiki/List_of_SIP_response_codes
 */
export enum SIPWebRTCStatusCodes {
  BUSY_486 = 486,
  TERMINATE_487 = 487,
}

export enum SIPWebRTCStatusReasons {
  BUSY_486 = 'Busy Here',
  TERMINATE_487 = 'Request Terminated',
}

export enum SIPWebRTCDirections {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export enum SIPWebRTCOriginators {
  LOCAL = 'local',
  REMOTE = 'remote',
  SYSTEM = 'system',
}

export enum SIPWebRTCSDPTypes {
  OFFER = 'offer',
  ANSWER = 'answer',
}

export enum SIPErrorCodesDisconnect {
  /**
   * 1006 => WSS Not Available
   */
  ERROR_1006 = 1006,
}

export enum SIPWebRTCRTPAudioCodecs {
  /**
   * https://www.iana.org/assignments/rtp-parameters/rtp-parameters.xhtml
   * https://en.wikipedia.org/wiki/RTP_payload_formats
   */
  // Audio Codec => Onoff VoIP Default
  AUDIO_CODEC_OPUS = 'opus',
  // Audio Codec
  AUDIO_CODEC_RED = 'red',
  // Audio Codec
  AUDIO_CODEC_G722 = 'G722',
  // Audio Codec
  AUDIO_CODEC_PCMU = 'PCMU',
  // Audio Codec => Onoff VoIP HD Audio Codec
  AUDIO_CODEC_PCMA = 'PCMA',
  // Audio Filtering Codec => Onoff VoIP Noise cancellation
  AUDIO_CODEC_CN = 'CN',
  // Audio DTMF Codec => Onoff VoIP DTMF Codec
  AUDIO_CODEC_TELEPHONE_EVENT = 'telephone-event',
}

export enum SIPWebRTCRTPAudioCodecPayloads {
  // Audio Codec => Onoff VoIP Default
  AUDIO_PAYLOAD_OPUS = 111,
  // Audio Codec
  AUDIO_PAYLOAD_RED = 63,
  // Audio Codec
  AUDIO_PAYLOAD_G722 = 9,
  // Audio Codec
  AUDIO_PAYLOAD_PCMU = 0,
  // Audio Codec => Onoff VoIP HD Audio Codec
  AUDIO_PAYLOAD_PCMA = 8,
  // Audio Filtering Codec => Onoff VoIP Noise cancellation
  AUDIO_PAYLOAD_CN = 13,
  // Audio DTMF Codec => Onoff VoIP DTMF Codec
  AUDIO_PAYLOAD_TELEPHONE_EVENT_48000 = 110,
  AUDIO_PAYLOAD_TELEPHONE_EVENT_8000 = 126,
}

export enum SIPStatuses {
  IDLE = 'idle',
  LOADING = 'loading',
  TRY_ANOTHER_WSS = 'try_another_wss',
  TRYING_ANOTHER_WSS = 'trying_another_wss',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  DISCONNECTING = 'disconnecting',
  DISCONNECTED = 'disconnected',
  REGISTERED = 'registered',
  UNREGISTERED = 'unregistered',
  FAILED = 'failed',
  FAILED_ALL = 'failed_all',
  TIMEOUT = 'timeout',
}

export enum SIPEvent {
  CREATING_USER_AGENT = 'CREATING_USER_AGENT',
  USER_AGENT_DISCONNECTED = 'USER_AGENT_DISCONNECTED',
  UNHANDLED_RTC_TIMEOUT = 'UNHANDLED_RTC_TIMEOUT',
  WSS_CONNECTION_TIMEOUT = 'WSS_CONNECTION_TIMEOUT',
  ICE_GATHERING_TIMEOUT = 'ICE_GATHERING_TIMEOUT',
  PUSH_RECEIVED_INCOMING_CALL = 'PUSH_RECEIVED_INCOMING_CALL',
  PUSH_RECEIVED_CALL_CANCELLED = 'PUSH_RECEIVED_CALL_CANCELLED',
}

export interface SIPDataStatuses {
  statusSIP: SIPStatuses;
  statusInitializeCall: Status;
  statusInitiateCallTransfer: Status;
  statusCancelCallRecording: Status;
}

export interface SIPDataStartCall {
  incomingPhoneNumberLocal: string;
  incomingPhoneNumberRemote: string;
  incomingTransactionID: string;
  incomingCategoryID: string;
  incomingCallIdentifier: string;
  incomingInstanceId: string;
  outgoingPhoneNumberLocal: string;
  outgoingPhoneNumberRemote: string;
  outgoingTransactionID: string;
  outgoingCategoryID: string;
  outgoingCallIdentifier: string;
  outgoingInstanceId: string;
}

export interface SIPDataConnect {
  idUser: string;
  idCategory: string;
  ipAddress: string;
}

export type SIPDataConfigWssSrvRecord = Required<DomainSrvRecord>;

export type SIPDataConfigStunSrvRecord = DomainSrvRecord;

export type SIPDataConfig = Required<ProvisionSipConfigResponse>;

export interface SIPDataWssRecord {
  wssUrl: string;
  attemptCount: number;
  isAvailable: boolean;
}

export interface SIPDataWss {
  records: SIPDataWssRecord[];
  availableUrlOrEmpty: SIPDataWssRecord['wssUrl'];
}

export interface SIPDataStun {
  records: string[];
}

export enum SIPDataCallStatusesCallInitialising {
  DEFAULT = 'default',
  INITIALISING = 'initialising',
  INITIALISED = 'initialised',
}

export enum SIPDataCallStatusesWebRTC {
  DEFAULT = 'default',
  PUSH_INCOMING = 'push_incoming',
  PUSH_OUTGOING = 'push_outgoing',
  BUSY = 'busy',
  FAILED = 'failed',
  RINGING_180 = 'ringing_180',
  RINGING_183 = 'ringing_183',
  ANSWER = 'answer',
  ANSWERED = 'answered',
  REJECT = 'reject',
  REJECTED = 'rejected',
  TERMINATE = 'terminate',
  TERMINATED = 'terminated',
  ACTIVE = 'active',
  WAITING = 'waiting',
}

export enum SIPDataCallStatusesFeatureMute {
  DEFAULT = 'default',
  MUTE = 'mute',
  MUTED = 'muted',
  FAILED = 'failed',
}

export enum SIPDataCallStatusesFeatureUnMute {
  DEFAULT = 'default',
  UNMUTE = 'unmute',
  UNMUTED = 'unmuted',
  FAILED = 'failed',
}

export enum SIPDataCallStatusesFeatureHold {
  DEFAULT = 'default',
  HOLD = 'hold',
  HELD = 'held',
  FAILED = 'failed',
}

export enum SIPDataCallStatusesFeatureUnHold {
  DEFAULT = 'default',
  UNHOLD = 'unhold',
  UNHELD = 'unheld',
  FAILED = 'failed',
}

export enum SIPDataCallStatusesFeatureDTMF {
  DEFAULT = 'default',
  SEND = 'send',
  SENT = 'sent',
  FAILED = 'failed',
}

export enum SIPDataCallStatusesView {
  DEFAULT = 'default',
  MINIMIZED = 'minimized',
  COMPLETED = 'completed',
}

export enum SIPDataCallStatusesRingtone {
  DEFAULT = 'default',
  MUTE = 'mute',
}

export enum SIPDataCallStatusesCallRecording {
  ON = 'on',
  OFF = 'off',
  CANCELLED = 'cancelled',
}

export interface SIPDataCallIds {
  initialising: string;
  callIdentifier: string;
  categoryLocal: string;
  call: string;
  transaction: string;
  webRTC: string;
}

export interface SIPDataCallStatuses {
  callInitialising: SIPDataCallStatusesCallInitialising;
  webRTC: SIPDataCallStatusesWebRTC;
  featureMute: SIPDataCallStatusesFeatureMute;
  featureUnMute: SIPDataCallStatusesFeatureUnMute;
  featureHold: SIPDataCallStatusesFeatureHold;
  featureUnHold: SIPDataCallStatusesFeatureUnHold;
  featureDTMF: SIPDataCallStatusesFeatureDTMF;
  view: SIPDataCallStatusesView;
  ringtone: SIPDataCallStatusesRingtone;
  callRecording: SIPDataCallStatusesCallRecording;
}

export interface SIPDataCallPhoneNumbers {
  local: string;
  remote: string;
}

export interface SIPDataCallTimestamps {
  callCreated: number;
  callRang: number;
  callAnswered: number;
  callTerminated: number;
}

export interface SIPDataCallValues {
  webRTCDTMF: string | number;
}

export interface SIPDataCallStatistics {
  isEstablishmentSent: boolean;
}

export interface SIPDataCall {
  ids: SIPDataCallIds;
  statuses: SIPDataCallStatuses;
  originator: SIPWebRTCOriginators;
  phoneNumbers: SIPDataCallPhoneNumbers;
  timestamps: SIPDataCallTimestamps;
  values: SIPDataCallValues;
  statistics: SIPDataCallStatistics;
}

export interface SIPDataCallsCapabilities {
  isCallsIncomingRingtoneDisabled: boolean;
  isCallsWebRTCAdvancedMediaConstraintsEnabled: boolean;
  isCallsWebRTCHdCodecEnabled: boolean;
}

export interface SIPReduxState {
  statuses: SIPDataStatuses;
  dataSIPStartCall: SIPDataStartCall;
  dataSIPConnect: SIPDataConnect;
  dataSIPConfig: SIPDataConfig;
  dataSIPWss: SIPDataWss;
  dataSIPStun: SIPDataStun;
  dataCalls: SIPDataCall[];
  dataCallsCapabilities: SIPDataCallsCapabilities;
}
