import classnames from 'classnames';
import React from 'react';

import { IconBaseProps } from '@onoff/icons';
import * as icons from '@onoff/icons';

import {
  Add,
  Backspace,
  Basket,
  MicrophoneOff,
  IncomingCall,
  Microphone,
  EndCall,
  OutgoingCall,
  DeviceHub,
  Dialpad,
  OpenInFull,
  CloseFull,
  VolumeOff,
  VolumeOn,
  AnswerCall,
  ExpandMore,
  ExpandLess,
  Eye,
  EyeOff,
  Close,
  Message,
  Person,
  AddPerson,
  DropUp,
  DropDown,
  DropLeft,
  DropRight,
  MissedIncomingCall,
  Camera,
  Edit,
  Download,
  UnFavourite,
  Favourite,
  PremiumCrown,
  Refresh,
  Picture,
  Send,
  MusicNote,
  AccountInfoReplace,
  Check,
  Voicemail,
  Play,
  Pause,
  InfoFilled,
  InfoOutlined,
  PhoneForward,
  PhonePaused,
  PhonePlay,
  Warning,
  CheckCircleFilled,
  ChevronLeft,
  ChevronRight,
  Receipt,
  NewTab,
  Globe,
  Settings,
  Menu,
  CreditCard,
  Logout,
  Laptop,
  Smartphone,
  Spinner,
  FileUpload,
} from '../../assets';
import { IconCommonNames, IconCommonSizes } from '../../enums';
import { IconCommonProps } from '../../types';

import styles from './IconCommon.module.scss';

const IconCommon: React.FC<IconCommonProps> = ({ name, size = IconCommonSizes.DEFAULT, className = '' }) => {
  const props: IconBaseProps = {
    className: classnames(
      styles.root,
      {
        [styles[`size-${size}`]]: true,
        [styles.isDelete]: name === IconCommonNames.DELETE,
      },
      className,
    ),
  };

  switch (name) {
    case IconCommonNames.CALL:
      return <icons.Call {...props} />;

    case IconCommonNames.MESSAGE:
      return <Message {...props} />;

    case IconCommonNames.ADD_PERSON:
      return <AddPerson {...props} />;

    case IconCommonNames.HELP:
      return <icons.Help {...props} />;

    case IconCommonNames.SEARCH:
      return <icons.Search {...props} />;

    case IconCommonNames.MORE_VERTICAL:
      return <icons.MoreVertical {...props} />;

    case IconCommonNames.MORE_HORIZONTAL:
      return <icons.MoreHorizontal {...props} />;

    case IconCommonNames.USER:
      return <icons.User {...props} />;

    case IconCommonNames.DELETE:
    case IconCommonNames.BASKET:
      return <Basket {...props} />;

    case IconCommonNames.BLOCK:
      return <icons.Block {...props} />;

    case IconCommonNames.PEOPLE:
      return <icons.People {...props} />;

    case IconCommonNames.REMOVE_OUTLINED:
      return <icons.RemoveOutlined {...props} />;

    case IconCommonNames.SPINNER:
      return <Spinner {...props} />;

    case IconCommonNames.CALL_END:
      return <icons.CallEnd {...props} />;

    case IconCommonNames.DIALPAD:
      return <Dialpad {...props} />;

    case IconCommonNames.MICROPHONE_OFF:
      return <MicrophoneOff {...props} />;

    case IconCommonNames.MICROPHONE:
      return <Microphone {...props} />;

    case IconCommonNames.INCOMING_CALL:
      return <IncomingCall {...props} />;

    case IconCommonNames.OUTGOING_CALL:
      return <OutgoingCall {...props} />;

    case IconCommonNames.END_CALL:
      return <EndCall {...props} />;

    case IconCommonNames.OPEN_IN_FULL:
      return <OpenInFull {...props} />;

    case IconCommonNames.CLOSE_FULL:
      return <CloseFull {...props} />;

    case IconCommonNames.VOLUME_OFF:
      return <VolumeOff {...props} />;

    case IconCommonNames.VOLUME_ON:
      return <VolumeOn {...props} />;

    case IconCommonNames.ANSWER_CALL:
      return <AnswerCall {...props} />;

    case IconCommonNames.EYE:
      return <Eye {...props} />;

    case IconCommonNames.EYE_OFF:
      return <EyeOff {...props} />;

    case IconCommonNames.EXPAND_MORE:
      return <ExpandMore {...props} />;

    case IconCommonNames.EXPAND_LESS:
      return <ExpandLess {...props} />;

    case IconCommonNames.CLOSE:
      return <Close {...props} />;

    case IconCommonNames.PERSON:
      return <Person {...props} />;

    case IconCommonNames.DROP_UP:
      return <DropUp {...props} />;

    case IconCommonNames.DROP_DOWN:
      return <DropDown {...props} />;

    case IconCommonNames.DROP_LEFT:
      return <DropLeft {...props} />;

    case IconCommonNames.DROP_RIGHT:
      return <DropRight {...props} />;

    case IconCommonNames.MISSED_INCOMING_CALL:
      return <MissedIncomingCall {...props} />;

    case IconCommonNames.ADD:
      return <Add {...props} />;

    case IconCommonNames.CAMERA:
      return <Camera {...props} />;

    case IconCommonNames.EDIT:
      return <Edit {...props} />;

    case IconCommonNames.DOWNLOAD:
      return <Download {...props} />;

    case IconCommonNames.UN_FAVORITE:
      return <UnFavourite {...props} />;

    case IconCommonNames.FAVORITE:
      return <Favourite {...props} />;

    case IconCommonNames.FILE_UPLOAD:
      return <FileUpload {...props} />;

    case IconCommonNames.PREMIUM_CROWN:
      return <PremiumCrown {...props} />;

    case IconCommonNames.REFRESH:
      return <Refresh {...props} />;

    case IconCommonNames.BACKSPACE:
      return <Backspace {...props} />;

    case IconCommonNames.ACCOUNT_INFO_REPLACE:
      return <AccountInfoReplace {...props} />;

    case IconCommonNames.DEVICE_HUB:
      return <DeviceHub {...props} />;

    case IconCommonNames.CHECK:
      return <Check {...props} />;

    case IconCommonNames.PICTURE:
      return <Picture {...props} />;

    case IconCommonNames.SEND:
      return <Send {...props} />;

    case IconCommonNames.MUSIC_NOTE:
      return <MusicNote {...props} />;

    case IconCommonNames.VOICEMAIL:
      return <Voicemail {...props} />;

    case IconCommonNames.PLAY:
      return <Play {...props} />;

    case IconCommonNames.PAUSE:
      return <Pause {...props} />;

    case IconCommonNames.INFO_FILLED:
      return <InfoFilled {...props} />;

    case IconCommonNames.INFO_OUTLINED:
      return <InfoOutlined {...props} />;

    case IconCommonNames.PHONE_FORWARD:
      return <PhoneForward {...props} />;

    case IconCommonNames.PHONE_PAUSED:
      return <PhonePaused {...props} />;

    case IconCommonNames.PHONE_PLAY:
      return <PhonePlay {...props} />;

    case IconCommonNames.WARNING:
      return <Warning {...props} />;

    case IconCommonNames.CHECK_CIRCLE_FILLED:
      return <CheckCircleFilled {...props} />;

    case IconCommonNames.CHEVRON_LEFT:
      return <ChevronLeft {...props} />;

    case IconCommonNames.CHEVRON_RIGHT:
      return <ChevronRight {...props} />;

    case IconCommonNames.RECEIPT:
      return <Receipt {...props} />;

    case IconCommonNames.NEW_TAB:
      return <NewTab {...props} />;

    case IconCommonNames.GLOBE:
      return <Globe {...props} />;

    case IconCommonNames.SETTINGS:
      return <Settings {...props} />;

    case IconCommonNames.MENU:
      return <Menu {...props} />;

    case IconCommonNames.CREDIT_CARD:
      return <CreditCard {...props} />;

    case IconCommonNames.LOGOUT:
      return <Logout {...props} />;

    case IconCommonNames.LAPTOP:
      return <Laptop {...props} />;

    case IconCommonNames.SMARTPHONE:
      return <Smartphone {...props} />;

    default:
      return null;
  }
};

export default IconCommon;
