export enum ApplicationPaths {
  ROOT = '/',
  PASSWORD_RECOVERY = '/password-recovery',
  CALLS = '/calls',
  CONTACTS = '/contacts',
  MESSAGES = '/messages',
  SETTINGS = '/settings',
  SETTINGS_PLANS = '/settings/plans',
  SETTINGS_ACCOUNT = '/settings/account',
  SETTINGS_BILLING = '/settings/billing',
  SETTINGS_PREFERENCES = '/settings/preferences',
  VOICEMAILS = '/voicemails',
  CHECKOUT_SUCCESS = '/checkout/success',
  CHECKOUT_FAILURE = '/checkout/failure',
  PURCHASE_PREMIUM_UPGRADE = '/premium/upgrade',
  PURCHASE_PREMIUM_TRIAL = '/premium/trial',
  PURCHASE_PHONE_NUMBER_RENEWAL = '/purchase',
  EMAIL_UNSUBSCRIBE = '/email-unsubscribe',
  RESET = '/reset',
  DEFAULT = '*',
}

export enum ProtectedCheckoutSuccessUrlSearchKeys {
  CHECKOUT_SESSION_ID = 'cko-session-id',
}

export enum ProtectedCheckoutErrorUrlSearchKeys {
  ERROR = 'error',
}

export enum ProtectedCheckoutErrorUrlSearchValues {
  ADDING_CREDIT_CARD_FAILED = 'adding_credit_card_failed',
}

export interface ProtectedSettingsRoutesVisibility {
  isVisibleToSettingsPage: boolean;
  isVisibleToUserB2B: boolean;
  isVisibleToUserB2CStarter: boolean;
  isVisibleToUserB2CPremium: boolean;
}
