import { Navigate, RouteProps } from 'react-router-dom';

import { LayoutAuth, LayoutCommon, LayoutFullWidth, LayoutSettings } from 'layouts';
import { PageCheckoutFailure, PageCheckoutSuccess } from 'pages/checkout';

import { lazyWithFallback } from '../api';
import { RoutesWrapper } from '../components';
import { ApplicationPaths } from '../types';

const PageLogin = lazyWithFallback(() => import('pages/login'));
const PagePasswordRecovery = lazyWithFallback(() => import('pages/passwordRecovery'));
const PageReset = lazyWithFallback(() => import('pages/reset'));

const PageCalls = lazyWithFallback(() => import('pages/calls'));
const PageMessages = lazyWithFallback(() => import('pages/messages'));
const PageVoicemails = lazyWithFallback(() => import('pages/voicemails'));
const PageContacts = lazyWithFallback(() => import('pages/contacts'));

const PagePurchasePhoneNumberRenewal = lazyWithFallback(() => import('pages/purchase/purchase-phone-number-renewal'));
const PagePurchasePremiumUpgrade = lazyWithFallback(() => import('pages/purchase/purchase-premium-upgrade'));

const PageSettingsAccountSettings = lazyWithFallback(() => import('pages/settings-account'));
const PageSettingsBilling = lazyWithFallback(() => import('pages/settings-billing'));
const PageSettingsPlans = lazyWithFallback(() => import('pages/settings-plans'));
const PageSettingsPreferences = lazyWithFallback(() => import('pages/settings-preferences'));

const PageEmailUnsubscribe = lazyWithFallback(() => import('pages/emailUnsubscribe'));

export const applicationRouteList: RouteProps[] = [
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutAuth
            isAvailableOnMobile
            children={<PagePasswordRecovery />}
          />
        }
      />
    ),
    path: ApplicationPaths.PASSWORD_RECOVERY,
  },
  {
    children: <RoutesWrapper children={<LayoutCommon children={<PageCalls />} />} />,
    path: ApplicationPaths.CALLS,
  },
  {
    children: <RoutesWrapper children={<LayoutCommon children={<PageContacts />} />} />,
    path: ApplicationPaths.CONTACTS,
  },
  {
    children: <RoutesWrapper children={<LayoutCommon children={<PageMessages />} />} />,
    path: ApplicationPaths.MESSAGES,
  },
  {
    children: <RoutesWrapper children={<LayoutCommon children={<PageVoicemails />} />} />,
    path: ApplicationPaths.VOICEMAILS,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutSettings
            isAvailableOnMobile
            children={
              <Navigate
                to={ApplicationPaths.SETTINGS_ACCOUNT}
                replace
              />
            }
          />
        }
      />
    ),
    path: ApplicationPaths.SETTINGS,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutSettings
            isAvailableOnMobile
            children={<PageSettingsPlans />}
          />
        }
      />
    ),
    path: ApplicationPaths.SETTINGS_PLANS,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutSettings
            isAvailableOnMobile
            children={<PageSettingsAccountSettings />}
          />
        }
      />
    ),
    path: ApplicationPaths.SETTINGS_ACCOUNT,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutSettings
            isAvailableOnMobile
            children={<PageSettingsPreferences />}
          />
        }
      />
    ),
    path: ApplicationPaths.SETTINGS_PREFERENCES,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutSettings
            isAvailableOnMobile
            children={<PageSettingsBilling />}
          />
        }
      />
    ),
    path: ApplicationPaths.SETTINGS_BILLING,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutFullWidth
            isAvailableOnMobile
            children={<PageCheckoutSuccess />}
          />
        }
      />
    ),
    path: ApplicationPaths.CHECKOUT_SUCCESS,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutFullWidth
            isAvailableOnMobile
            children={<PageCheckoutFailure />}
          />
        }
      />
    ),
    path: ApplicationPaths.CHECKOUT_FAILURE,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutFullWidth
            isAvailableOnMobile
            children={<PagePurchasePremiumUpgrade />}
          />
        }
      />
    ),
    path: ApplicationPaths.PURCHASE_PREMIUM_UPGRADE,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutFullWidth
            isAvailableOnMobile
            children={<PagePurchasePremiumUpgrade />}
          />
        }
      />
    ),
    path: ApplicationPaths.PURCHASE_PREMIUM_TRIAL,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutFullWidth
            isAvailableOnMobile
            children={<PagePurchasePhoneNumberRenewal />}
          />
        }
      />
    ),
    path: ApplicationPaths.PURCHASE_PHONE_NUMBER_RENEWAL,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutAuth
            isAvailableOnMobile
            children={<PageEmailUnsubscribe />}
          />
        }
      />
    ),
    path: ApplicationPaths.EMAIL_UNSUBSCRIBE,
  },
  {
    children: <RoutesWrapper children={<PageReset />} />,
    path: ApplicationPaths.RESET,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutAuth
            isAvailableOnMobile
            children={<PageLogin />}
          />
        }
      />
    ),
    path: ApplicationPaths.ROOT,
  },
  {
    children: (
      <RoutesWrapper
        children={
          <LayoutAuth
            children={
              <Navigate
                to={ApplicationPaths.ROOT}
                state={{
                  redirectUri: window.location.pathname,
                  hash: window.location.hash,
                }}
              />
            }
          />
        }
      />
    ),
    path: ApplicationPaths.DEFAULT,
  },
];
