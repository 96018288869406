import React from 'react';

import { StyleableComponent } from 'types';

const OutgoingCall: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.0007 6C9.0007 6.56 9.4507 7 10.0007 7H15.5907L4.7007 17.89C4.3107 18.28 4.3107 18.91 4.7007 19.3C5.0907 19.69 5.7207 19.69 6.1107 19.3L17.0007 8.41V14C17.0007 14.55 17.4507 15 18.0007 15C18.5507 15 19.0007 14.55 19.0007 14V6C19.0007 5.45 18.5507 5 18.0007 5H10.0007C9.4507 5 9.0007 5.45 9.0007 6Z"
      fill="currentColor"
    />
  </svg>
);

export default OutgoingCall;
