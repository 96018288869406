export enum ClientConfigurationEnv {
  STAGE = 'STAGE',
  DEV = 'DEV',
  PROD = 'PROD',
}

export interface LegacyClientConfiguration {
  env: ClientConfigurationEnv;
  universal: LegacyUniversalConfiguration;
  API_CONTROLLER: string;
  auth: {
    facebook: {
      id: string;
    };
    google: {
      id: string;
      accessKeys: {
        fname: string;
        lname: string;
        email: string;
      };
    };
  };
}

interface ValidationRules {
  required: boolean;
  min: number;
  max: number;
  regex: string;
}

export interface LegacyUniversalConfiguration {
  apiController: string;
  validationRules: {
    category: {
      id: Omit<ValidationRules, 'regex'>;
      name: Omit<ValidationRules, 'regex'>;
      company: Omit<ValidationRules, 'required' | 'regex'>;
      color: ValidationRules;
      notes: Omit<ValidationRules, 'required' | 'regex'>;
      emails: {
        email: Omit<ValidationRules, 'required' | 'regex'>;
        type: Omit<ValidationRules, 'required' | 'regex'>;
      };
      phones: {
        number: Omit<ValidationRules, 'required' | 'regex'>;
        normalNumber: Omit<ValidationRules, 'required' | 'regex'>;
        favorite: { boolean: boolean };
        type: Omit<ValidationRules, 'required' | 'regex'>;
      };
    };
    messageV2: {
      body: Omit<ValidationRules, 'required' | 'regex'>;
      delayInMinutes: Omit<ValidationRules, 'required' | 'regex'>;
      mediaLength: Omit<ValidationRules, 'required' | 'regex'>;
      sourceCategoryId: Omit<ValidationRules, 'regex'>;
      targetPhoneNumber: Omit<ValidationRules, 'required' | 'regex'>;
      threadId: Omit<ValidationRules, 'required' | 'regex'>;
      type: Omit<ValidationRules, 'regex'> & { oneOf: string[] };
    };
    messageV4: {
      content: Omit<ValidationRules, 'regex'>;
      contentType: Omit<ValidationRules, 'regex' | 'required'>;
      delayInMinutes: Omit<ValidationRules, 'regex' | 'required'>;
      mediaLength: Omit<ValidationRules, 'regex' | 'required'>;
      messageType: Omit<ValidationRules, 'regex'> & { oneOf: string[] };
      originalFilename: Omit<ValidationRules, 'regex' | 'required'>;
      threadId: Omit<ValidationRules, 'regex'>;
    };
    contact: {
      id: Omit<ValidationRules, 'regex' | 'required'>;
      categoryId: Omit<ValidationRules, 'regex' | 'required'>;
      firstName: Omit<ValidationRules, 'regex' | 'required'>;
      lastName: Omit<ValidationRules, 'regex' | 'required'>;
      jobTitle: Omit<ValidationRules, 'regex' | 'required'>;
      company: Omit<ValidationRules, 'regex' | 'required'>;
      notes: Omit<ValidationRules, 'regex' | 'required'>;
      blocked: { boolean: boolean };
      favorite: { boolean: boolean };
      birthday: Omit<ValidationRules, 'regex' | 'required'>;
      urls: Omit<ValidationRules, 'regex' | 'required'>;
      phones: {
        number: Omit<ValidationRules, 'regex' | 'required'>;
        normalNumber: Omit<ValidationRules, 'regex' | 'required'>;
        favorite: { boolean: boolean };
        type: Omit<ValidationRules, 'regex' | 'required'>;
      };
      emails: {
        email: Omit<ValidationRules, 'regex' | 'required'>;
        type: Omit<ValidationRules, 'regex' | 'required'>;
      };
      addresses: {
        street1: Omit<ValidationRules, 'regex' | 'required'>;
        street2: Omit<ValidationRules, 'regex' | 'required'>;
        state: Omit<ValidationRules, 'regex' | 'required'>;
        country: Omit<ValidationRules, 'regex' | 'required'>;
        postalCode: Omit<ValidationRules, 'regex' | 'required'>;
        pobox: Omit<ValidationRules, 'regex' | 'required'>;
        city: Omit<ValidationRules, 'regex' | 'required'>;
        type: Omit<ValidationRules, 'regex' | 'required'>;
      };
      imProfiles: {
        profile: Omit<ValidationRules, 'regex' | 'required'>;
        type: Omit<ValidationRules, 'regex' | 'required'>;
      };
      socialProfiles: {
        profile: Omit<ValidationRules, 'regex' | 'required'>;
        type: Omit<ValidationRules, 'regex' | 'required'>;
      };
      phoneBookId: Omit<ValidationRules, 'regex' | 'required'>;
    };
  };
}
