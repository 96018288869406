import { AnalyticsService } from 'types';

const shouldPreventScriptInjection = (
  googleTagManagerTag: string,
  googleTagManagerMeasurementId: string,
  scriptId: string,
): boolean => {
  const scriptInjected = document.querySelector(`#${scriptId}`);

  return googleTagManagerTag === '' || googleTagManagerMeasurementId === '' || scriptInjected !== null;
};

const analyticsService: AnalyticsService = {
  init: (): void => {
    const gtmTag = process.env.REACT_APP_GOOGLE_TAG_MANAGER_TAG || '';
    const gtagMeasurementId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_MEASUREMENT_ID || '';

    const scriptId = 'WEBAPP-SCRIPT-GTM';

    if (shouldPreventScriptInjection(gtmTag, gtagMeasurementId, scriptId)) {
      return;
    }

    const scripts = `
        <!-- Global site tag (gtag.js) - Google Analytics -->
        <script id="${scriptId}" async src="https://www.googletagmanager.com/gtag/js?id=${gtagMeasurementId}"></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtagMeasurementId}');
        </script>

        <!-- Google Tag Manager -->
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmTag}');</script>
        <!-- End Google Tag Manager -->
        `;

    const scriptsRange = window.document.createRange();
    scriptsRange.selectNode(window.document.head);
    const scriptsFragment = scriptsRange.createContextualFragment(scripts);
    window.document.head.appendChild(scriptsFragment);
  },

  fetchGoogleTagManagerClientId: () => {
    /**
     * After calling window.gtag('get' , we pass a callback (resolve) to it,
     * as after GTM has received the client ID, this callback would be called.
     *
     * In some cases this callback will never be called,
     * as the ID is connected to cookies and some extensions and browsers might block it.
     * That's why the race between 2 promises is done, as one of them resolves 100% of time, but only after N seconds.
     */

    const getGTMClientId = new Promise<string | undefined>((resolve) => {
      const gtagMeasurementId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_MEASUREMENT_ID || '';
      if (window.gtag !== undefined) {
        window.gtag('get', gtagMeasurementId, 'client_id', resolve);
        return;
      }

      resolve(undefined);
    });

    const timer = new Promise<undefined>((resolve) => {
      setTimeout(() => resolve(undefined), 2000);
    });

    return Promise.race([getGTMClientId, timer]);
  },

  pushToDataLayer: ({ event, variables = {} }): void => {
    window.dataLayer?.push({ event, ...variables });
  },
};
export default analyticsService;
