import React from 'react';

import { StyleableComponent } from 'types';

const DropRight: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53_1090)">
      <path
        d="M11.71 15.2897L14.3 12.6997C14.69 12.3097 14.69 11.6797 14.3 11.2897L11.71 8.69973C11.08 8.07973 10 8.51973 10 9.40973V14.5797C10 15.4797 11.08 15.9197 11.71 15.2897Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_1090">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DropRight;
