import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { usePopoverContext } from '@onoff/ui';

import DropdownPhoneNumberOption, { DropdownPhoneNumberOptionProps } from '../DropdownPhoneNumberOption';

import styles from './DropdownPhoneNumber.module.scss';

interface PhoneNumberInputDropDownProps
  extends React.RefAttributes<HTMLDivElement>,
    Pick<React.HtmlHTMLAttributes<HTMLDivElement>, 'style' | 'className'> {
  onClickItem?: DropdownPhoneNumberOptionProps['onClick'];
  optionList: Array<DropdownPhoneNumberOptionProps>;
  title: string;
}

const DropdownPhoneNumber = forwardRef<HTMLDivElement, PhoneNumberInputDropDownProps>(
  ({ className, onClickItem = () => undefined, optionList, style, title }, ref) => {
    const { togglePopover } = usePopoverContext();

    return (
      <div
        className={classNames(className, styles.root)}
        ref={ref}
        style={style}
      >
        <p className={styles.title}>{title}</p>
        {optionList.length > 0 ? (
          <ul className={styles.list}>
            {optionList.map((item, i) => (
              <DropdownPhoneNumberOption
                key={i}
                onClick={(phoneNumber, contactId) => {
                  onClickItem(phoneNumber, contactId);
                  togglePopover();
                }}
                {...item}
              />
            ))}
          </ul>
        ) : (
          <p className={styles.noResultsPlaceHolder}>
            <FormattedMessage id={'DropdownPhoneNumber.search_no_results'} />
          </p>
        )}
      </div>
    );
  },
);

export default DropdownPhoneNumber;
