import React from 'react';

import { StyleableComponent } from 'types';

const CreditCardLocation: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="201"
    viewBox="0 0 200 201"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M200 100.5C200 155.728 155.228 200.5 100 200.5C44.7715 200.5 0 155.728 0 100.5C0 45.2715 44.7715 0.5 100 0.5C155.228 0.5 200 45.2715 200 100.5Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 100.5C187.368 148.752 148.252 187.868 100 187.868C51.7478 187.868 12.6316 148.752 12.6316 100.5C12.6316 52.2475 51.7478 13.1313 100 13.1313C148.252 13.1313 187.368 52.2475 187.368 100.5Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 100.5C172.105 140.322 139.823 172.605 100 172.605C60.1773 172.605 27.8947 140.322 27.8947 100.5C27.8947 60.6772 60.1773 28.3945 100 28.3945C139.823 28.3945 172.105 60.6772 172.105 100.5Z"
      fill="#F3F4F5"
    />
    <path
      d="M19.1743 140.048C14.8334 137.336 13.5133 131.618 16.2258 127.277L89.2835 10.3599C91.9961 6.01896 97.714 4.69886 102.055 7.41138L166.9 47.9308C171.241 50.6433 172.561 56.3613 169.848 60.7022L96.7903 177.619C94.0778 181.96 88.3599 183.28 84.0189 180.568L19.1743 140.048Z"
      fill="#E4E5E7"
      fillOpacity="0.8"
    />
    <path
      d="M19.1743 138.048C14.8334 135.336 13.5133 129.618 16.2258 125.277L89.2835 8.35989C91.9961 4.01896 97.714 2.69886 102.055 5.41138L166.9 45.9308C171.241 48.6433 172.561 54.3613 169.848 58.7022L96.7903 175.619C94.0778 179.96 88.3599 181.28 84.0189 178.568L19.1743 138.048Z"
      fill="white"
    />
    <path
      d="M145.349 59.104C149.554 61.7318 150.833 67.271 148.205 71.4763C145.577 75.6816 140.038 76.9604 135.833 74.3327C131.627 71.7049 130.349 66.1657 132.976 61.9604C135.604 57.7551 141.143 56.4763 145.349 59.104Z"
      fill="#8E929B"
    />
    <path
      d="M29.7349 130.986C28.3783 130.138 27.9658 128.351 28.8135 126.995L41.399 106.854C42.2467 105.497 44.0336 105.085 45.3901 105.932L59.145 114.527C60.5016 115.375 60.9141 117.162 60.0664 118.518L47.4809 138.66C46.6332 140.016 44.8463 140.429 43.4898 139.581L29.7349 130.986Z"
      fill="#E3E4E8"
    />
    <path
      d="M57.6071 84.1948C56.7932 83.6862 56.5457 82.6141 57.0543 81.8002L96.9598 17.938C97.4684 17.1241 98.5405 16.8766 99.3544 17.3852L103.284 19.8409C104.098 20.3495 104.346 21.4216 103.837 22.2355L63.9317 86.0977C63.4231 86.9116 62.351 87.1591 61.5371 86.6505L57.6071 84.1948Z"
      fill="#E3E4E8"
    />
    <path
      d="M73.3859 160.762C72.5719 160.253 72.3244 159.181 72.833 158.367L112.739 94.5049C113.247 93.691 114.319 93.4435 115.133 93.9521L115.624 94.259C116.438 94.7676 116.686 95.8398 116.177 96.6537L76.2717 160.516C75.7631 161.33 74.691 161.577 73.8771 161.069L73.3859 160.762Z"
      fill="#E3E4E8"
    />
    <path
      d="M79.2808 164.445C78.4669 163.937 78.2194 162.865 78.728 162.051L89.1648 145.348C89.6734 144.534 90.7455 144.287 91.5594 144.795L92.0507 145.102C92.8646 145.611 93.1121 146.683 92.6035 147.497L82.1667 164.199C81.6581 165.013 80.586 165.261 79.7721 164.752L79.2808 164.445Z"
      fill="#E3E4E8"
    />
    <path
      d="M151.181 49.77C155.386 52.3978 156.665 57.937 154.037 62.1423C151.41 66.3476 145.87 67.6264 141.665 64.9987C137.46 62.3709 136.181 56.8317 138.809 52.6264C141.436 48.4211 146.976 47.1423 151.181 49.77Z"
      fill="#E3E4E8"
      fillOpacity="0.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.441 162.958C131.594 162.958 124.423 155.787 124.423 146.941C124.423 138.094 131.594 130.923 140.441 130.923C149.287 130.923 156.458 138.094 156.458 146.941C156.458 155.787 149.287 162.958 140.441 162.958ZM166.208 121.173H166.208C151.977 106.942 128.904 106.942 114.673 121.173C100.442 135.404 100.442 158.477 114.673 172.708L140.441 198.475L166.208 172.708C180.439 158.477 180.439 135.404 166.208 121.173Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M140.441 198.475L114.673 172.708C107.558 165.592 104 156.266 104 146.941C104 156.266 107.558 165.592 114.673 172.708L140.441 198.475L166.208 172.708C166.223 172.692 166.239 172.677 166.254 172.661L166.208 172.708L140.441 198.475ZM136.492 162.468C129.928 160.803 124.98 155.086 124.467 148.136C124.98 155.083 129.923 160.797 136.482 162.464C136.485 162.465 136.489 162.467 136.492 162.468ZM124.467 148.134C124.464 148.099 124.462 148.063 124.459 148.028C124.462 148.063 124.464 148.098 124.467 148.134ZM124.455 147.958C124.454 147.94 124.453 147.92 124.451 147.902C124.453 147.92 124.454 147.939 124.455 147.958ZM124.431 146.429C124.432 146.409 124.432 146.389 124.433 146.368C124.432 146.389 124.432 146.409 124.431 146.429ZM124.457 145.891C124.458 145.871 124.459 145.85 124.461 145.83C124.459 145.85 124.458 145.871 124.457 145.891ZM129.686 135.07C129.7 135.057 129.714 135.045 129.728 135.032C129.714 135.045 129.7 135.057 129.686 135.07ZM129.755 135.007C129.77 134.994 129.785 134.981 129.8 134.968C129.785 134.981 129.77 134.994 129.755 135.007ZM129.826 134.945C129.841 134.931 129.856 134.918 129.871 134.905C129.856 134.918 129.841 134.931 129.826 134.945ZM129.896 134.883C129.919 134.863 129.943 134.842 129.966 134.822C129.943 134.842 129.919 134.863 129.896 134.883ZM129.967 134.821C130.643 134.237 131.368 133.708 132.136 133.241C132.135 133.242 132.135 133.242 132.135 133.242C131.368 133.708 130.643 134.237 129.967 134.821Z"
      fill="#FADD8B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.279 104.294L171.655 101.911L169 100.779L171.384 99.154L172.515 96.5L174.14 98.8844L176.794 100.015L174.411 101.64L173.279 104.294Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2793 76.2944L10.6547 73.9106L8 72.7793L10.3838 71.154L11.5151 68.5L13.1397 70.8844L15.7944 72.0151L13.4106 73.6397L12.2793 76.2944Z"
      fill="#E4E4E4"
    />
  </svg>
);

export default CreditCardLocation;
