import { Category, Status, Voicemail } from 'types';

import {
  selectCategoryById,
  selectVoicemails,
  selectVoicemailsQueuesAllData,
  selectVoicemailsState,
} from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  VoicemailsAddToQueueAction,
  VoicemailsAppendAction,
  VoicemailsClearSearchStringAction,
  VoicemailsDeleteAction,
  VoicemailsMarkAsListenedAction,
  VoicemailsPrependAction,
  VoicemailsPubNubNewVoicemailHandlerProps,
  VoicemailsReleaseFromQueueAction,
  VoicemailsSetActiveVoicemailIdAction,
  VoicemailsSetSearchStringAction,
  VoicemailsSetStatusDeleteVoicemailsAction,
  VoicemailsSetStatusFetchVoicemailsAction,
} from '../../types';
import { notificationsBrowserSetVoicemailHandler } from '../notificationsBrowser';

export const voicemailsPrepend = (voicemail: Voicemail): VoicemailsPrependAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_PREPEND,
  voicemail,
});

export const voicemailsAddToQueue = (voicemail: Voicemail): VoicemailsAddToQueueAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_ADD_TO_QUEUE,
  voicemail,
});

export const voicemailsAppend = (voicemailList: Voicemail[], isAllLoaded?: boolean): VoicemailsAppendAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_APPEND,
  voicemailList,
  isAllLoaded,
});

export const voicemailsDelete = (voicemailIds: string[]): VoicemailsDeleteAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_DELETE,
  voicemailIds,
});

export const voicemailsClearSearchString = (): VoicemailsClearSearchStringAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_CLEAR_SEARCH_STRING,
});

export const voicemailsMarkAsListened = (
  voicemailIdList: Required<Voicemail['id'][]>,
): VoicemailsMarkAsListenedAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_MARK_AS_LISTENED,
  voicemailIdList,
});

export const voicemailsReleaseFromQueue = (categoryId: Category['id']): VoicemailsReleaseFromQueueAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_RELEASE_FROM_QUEUE,
  categoryId,
});

export const voicemailsSetSearchString = (searchString: string): VoicemailsSetSearchStringAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_SET_SEARCH_STRING,
  searchString,
});

export const voicemailsMarkVoicemailsAsListenedHandler =
  (voicemailIdList: Required<Voicemail['id'][]>): ThunkResult<Promise<void>> =>
  async (dispatch, _, services) => {
    await services.voicemailsService.markVoicemailsAsListened(voicemailIdList);
    dispatch(voicemailsMarkAsListened(voicemailIdList));
  };

export const voicemailsSetStatusFetchVoicemails = (status: Status): VoicemailsSetStatusFetchVoicemailsAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_SET_STATUS_FETCH_VOICEMAILS,
  status,
});

export const voicemailsSetActiveVoicemailId = (voicemailId: string): VoicemailsSetActiveVoicemailIdAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_SET_ACTIVE_VOICEMAIL_ID,
  activeVoicemailId: voicemailId,
});

export const voicemailsSetStatusDeleteVoicemails = (status: Status): VoicemailsSetStatusDeleteVoicemailsAction => ({
  type: REDUX_ACTION_TYPES.VOICEMAILS_SET_STATUS_DELETE_VOICEMAILS,
  status,
});

export const voicemailsFetchVoicemailsHandler =
  (limit = 50): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      const { allLoaded, statuses, offset, data } = selectVoicemailsState(getState());

      if (allLoaded || statuses.fetchVoicemails === Status.LOADING) {
        return;
      }

      dispatch(voicemailsSetStatusFetchVoicemails(Status.LOADING));

      const { data: voicemails, totalCount } = await services.voicemailsService.fetchVoicemails(offset, limit);
      const isAllLoaded = voicemails.length < limit || data.length + voicemails.length === totalCount;
      dispatch(voicemailsAppend(voicemails, isAllLoaded));
      dispatch(voicemailsSetStatusFetchVoicemails(Status.SUCCEEDED));
    } catch (error) {
      dispatch(voicemailsSetStatusFetchVoicemails(Status.FAILED));
      throw error;
    }
  };

export const voicemailsDeleteVoicemailHandler =
  (voicemailId: string): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(voicemailsSetStatusDeleteVoicemails(Status.LOADING));
      await services.voicemailsService.deleteVoicemail(voicemailId);
      dispatch(voicemailsDelete([voicemailId]));
      dispatch(voicemailsSetStatusDeleteVoicemails(Status.SUCCEEDED));
    } catch (error) {
      dispatch(voicemailsSetStatusDeleteVoicemails(Status.FAILED));
      throw error;
    }
  };

export const voicemailsPubNubNewVoicemailHandler =
  ({ voicemail }: VoicemailsPubNubNewVoicemailHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const voicemailsData = selectVoicemails(getState());
    const voicemailsQueueData = selectVoicemailsQueuesAllData(getState());
    const isVoicemailAlreadyExistInData = voicemailsData.find(({ id }) => id === voicemail.id) !== undefined;
    const isVoicemailAlreadyExistInQueue = voicemailsQueueData.find(({ id }) => id === voicemail.id) !== undefined;
    if (isVoicemailAlreadyExistInData || isVoicemailAlreadyExistInQueue) {
      return;
    }

    const { categoryId = '', callingPartyPhone: sourcePhoneNumber = '' } = voicemail;
    const { callEnabled: isRelatedCategoryCallsEnabled } = selectCategoryById(categoryId)(getState()) || {};

    if (isRelatedCategoryCallsEnabled) {
      dispatch(voicemailsPrepend(voicemail));

      dispatch(
        notificationsBrowserSetVoicemailHandler({
          sourcePhoneNumber,
        }),
      );
    } else {
      dispatch(voicemailsAddToQueue(voicemail));
    }
  };
