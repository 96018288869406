import {
  SimpleContact,
  PreparedContact,
  Contact,
  ContactsSimpleContactsMap,
  ContactPhone,
  ContactSimpleContactPhone,
  SimpleContactsResponse,
  SaveSimpleContactResponse,
  SaveContactImageResponse,
} from 'types';

import { mapSimpleContactToContact } from 'helpers';

import { Fixture } from '.';

export const contactPhoneNumbersFixture: Fixture<ContactSimpleContactPhone> = (extend = {}) => ({
  id: 'phone-id',
  number: '+33 7 80 90 90 90',
  normalNumber: '33780909090',
  type: 'Mobile',
  favorite: false,
  tsanCategories: [],
  ...extend,
});

export const simpleContactFixture: Fixture<SimpleContact> = (contact = {}) => ({
  created: '2021-03-22T11:28:03+0000',
  blocked: false,
  deleted: false,
  firstName: 'Lorem',
  lastName: 'Ipsum',
  id: '161641-2483409-02429a4c4bb8-0001',
  imageUrl: 'https://onoff-image-staging.s3.amazonaws.com/F4FTMJC3UAAICIJNL4JIBGMX64',
  key: '161641-2483409-02429a4c4bb8-0001',
  manual: true,
  notes: 'vraiment trop mimi!',
  phones: [contactPhoneNumbersFixture()],
  updated: '2021-03-22T11:28:15+0000',
  userId: ' 1606827727565-0242fd34afcf-0001',
  ...contact,
});

export const phoneNumberContactFixture: Fixture<Contact> = (contact) => ({
  ...simpleContactFixture(),
  displayName: 'Lorem Ipsum',
  key: '161641-2483409-02429a4c4bb8-0001',
  isFriend: false,
  ...contact,
});

export const phoneNumberContactsMapFixture: Fixture<ContactsSimpleContactsMap> = () => ({
  '33780909090': {
    ...phoneNumberContactFixture(),
  },
});

export const minimumContactFixture: Fixture<SimpleContact> = (contact = {}) => ({
  id: '161641 2483409-02429a4c4bb8-0001',
  userId: ' 1606827727565-0242fd34afcf-0001',
  ...contact,
});

export const simpleContactArrayFixture: Fixture<SimpleContact[]> = () => [
  simpleContactFixture(),
  {
    ...simpleContactFixture(),
    id: '161641-2483409-02429a4c4bb8-0002',
    firstName: 'Zzzz',
    lastName: 'Bbbbb',
  },
];

export const contactPreparedForSaveFixture: Fixture<PreparedContact> = (contact = {}) => ({
  blocked: false,
  firstName: 'Lorem',
  lastName: 'Ipsum',
  id: '161641-2483409-02429a4c4bb8-0001',
  imageUrl: 'https://onoff-image-staging.s3.amazonaws.com/F4FTMJC3UAAICIJNL4JIBGMX64',
  notes: 'vraiment trop mimi!',
  phones: [contactPhoneNumbersFixture()],
  favorite: false,
  emails: undefined,
  ...contact,
});

export const contactFixture = <T extends ContactPhone>(contact: T): Contact =>
  mapSimpleContactToContact(contact, contact.id);

export const fetchContactsFixture: Fixture<SimpleContactsResponse> = (response) => ({
  data: [simpleContactFixture()],
  totalCount: 1,
  ...response,
});

export const saveContactFixture: Fixture<SaveSimpleContactResponse> = (saveContactResponse = {}) => ({
  id: 'string',
  phones: [
    {
      normalNumber: 'string',
      tsans: [
        {
          tsan: 'string',
          categoryId: 'string',
        },
      ],
    },
  ],
  ...saveContactResponse,
});

export const saveContactImageFixture: Fixture<SaveContactImageResponse> = (saveContactImageResponse = {}) => ({
  imageUrl: 'string',
  ...saveContactImageResponse,
});
