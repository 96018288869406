import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { Status } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  callLogsDeleteCallLogHandler,
  threadsRemoveHandler,
  voicemailsDeleteVoicemailHandler,
  contactsDeleteContact,
  selectCallLogsActiveCallLogId,
  selectCallLogsStatuses,
  selectContactsActiveId,
  selectContactsStatuses,
  selectMessagesStatuses,
  selectThreadsActiveId,
  selectVoicemailsActiveVoicemailId,
  selectVoicemailsStatuses,
} from '@redux';
import { IconCommonNames } from 'components/Icon';
import { ModalsConfirmation } from 'components/Modals';

import {
  getInfoTopBarActionsDropdownDeleteItemIntlId,
  getInfoTopBarActionsDropdownDeleteItemModalType,
  getInfoTopBarActionsDropdownDeleteItemModalTitleIntlId,
  getInfoTopBarActionsDropdownDeleteItemModalDescriptionIntlId,
  getInfoTopBarActionsDropdownDeleteItemModalButtonConfirmIntlId,
  getInfoTopBarActionsDropdownDeleteItemModalButtonCancelIntlId,
  getInfoTopBarActionsDropdownDeleteItemTestId,
} from '../../helpers';
import { InfoTopBarActionsDropdownFeaturesDeleteProps, InfoTopBarActionsTypes } from '../../types';
import InfoTopBarActionsDropdownItem from '../InfoTopBarActionsDropdownItem';

const InfoTopBarActionsDropdownFeaturesDelete: React.FC<InfoTopBarActionsDropdownFeaturesDeleteProps> = ({ type }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const activeCallLogId = useWebAppSelector(selectCallLogsActiveCallLogId);
  const activeThreadId = useWebAppSelector(selectThreadsActiveId);
  const activeVoicemailId = useWebAppSelector(selectVoicemailsActiveVoicemailId);
  const activeContactId = useWebAppSelector(selectContactsActiveId);

  const { deleteCallLog: statusDeleteCallLog } = useWebAppSelector(selectCallLogsStatuses);
  const { deleteThreadMessages: statusDeleteThread } = useWebAppSelector(selectMessagesStatuses);
  const { deleteVoicemails: statusDeleteVoicemail } = useWebAppSelector(selectVoicemailsStatuses);
  const { deleteContact: statusDeleteContact } = useWebAppSelector(selectContactsStatuses);

  const [stateModalDeleteCallLogVisible, setStateModalDeleteCallLogVisible] = useState<boolean>(false);
  const [stateModalDeleteThreadVisible, setStateModalDeleteThreadVisible] = useState<boolean>(false);
  const [stateModalDeleteVoicemailVisible, setStateModalDeleteVoicemailVisible] = useState<boolean>(false);
  const [stateModalDeleteContactVisible, setStateModalDeleteContactVisible] = useState<boolean>(false);

  const onClickHandlerModalDeleteCallLogOpen = (): void => {
    setStateModalDeleteCallLogVisible(true);
  };

  const onClickHandlerModalDeleteCallLogClose = (): void => {
    setStateModalDeleteCallLogVisible(false);
  };

  const onClickHandlerModalDeleteThreadOpen = (): void => {
    setStateModalDeleteThreadVisible(true);
  };

  const onClickHandlerModalDeleteThreadClose = (): void => {
    setStateModalDeleteThreadVisible(false);
  };

  const onClickHandlerModalDeleteVoicemailOpen = (): void => {
    setStateModalDeleteVoicemailVisible(true);
  };

  const onClickHandlerModalDeleteVoicemailClose = (): void => {
    setStateModalDeleteVoicemailVisible(false);
  };

  const onClickHandlerModalDeleteContactOpen = (): void => {
    setStateModalDeleteContactVisible(true);
  };

  const onClickHandlerModalDeleteContactClose = (): void => {
    setStateModalDeleteContactVisible(false);
  };

  const onClickHandlerModalDeleteItemsOpen = (): void => {
    switch (type) {
      case InfoTopBarActionsTypes.PAGE_CALLS:
        onClickHandlerModalDeleteCallLogOpen();
        break;

      case InfoTopBarActionsTypes.PAGE_MESSAGES:
      case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
        onClickHandlerModalDeleteThreadOpen();
        break;

      case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
        onClickHandlerModalDeleteVoicemailOpen();
        break;

      case InfoTopBarActionsTypes.PAGE_CONTACTS:
        onClickHandlerModalDeleteContactOpen();
        break;

      default:
        break;
    }
  };

  const onClickHandlerModalDeleteItemsClose = (): void => {
    switch (type) {
      case InfoTopBarActionsTypes.PAGE_CALLS:
        onClickHandlerModalDeleteCallLogClose();
        break;

      case InfoTopBarActionsTypes.PAGE_MESSAGES:
      case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
        onClickHandlerModalDeleteThreadClose();
        break;

      case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
        onClickHandlerModalDeleteVoicemailClose();
        break;

      case InfoTopBarActionsTypes.PAGE_CONTACTS:
        onClickHandlerModalDeleteContactClose();
        break;

      default:
        break;
    }
  };

  const onClickHandlerModalDeleteItemsHandler = (): void => {
    switch (type) {
      case InfoTopBarActionsTypes.PAGE_CALLS:
        dispatch(callLogsDeleteCallLogHandler({ id: activeCallLogId }));
        break;

      case InfoTopBarActionsTypes.PAGE_MESSAGES:
      case InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP:
        dispatch(threadsRemoveHandler([activeThreadId]));
        break;

      case InfoTopBarActionsTypes.PAGE_VOICEMAILS:
        dispatch(voicemailsDeleteVoicemailHandler(activeVoicemailId));
        break;

      case InfoTopBarActionsTypes.PAGE_CONTACTS:
        dispatch(contactsDeleteContact(activeContactId));
        break;

      default:
        break;
    }
  };

  const callbackDeleteCallLogSucceededHandler = (): void => {
    if (statusDeleteCallLog === Status.SUCCEEDED) {
      onClickHandlerModalDeleteCallLogClose();
    }
  };

  const callbackDeleteThreadSucceededHandler = (): void => {
    if (statusDeleteThread === Status.SUCCEEDED) {
      onClickHandlerModalDeleteThreadClose();
    }
  };

  const callbackDeleteVoicemailSucceededHandler = (): void => {
    if (statusDeleteVoicemail === Status.SUCCEEDED) {
      onClickHandlerModalDeleteVoicemailClose();
    }
  };

  const callbackDeleteContactSucceededHandler = (): void => {
    if (statusDeleteContact === Status.SUCCEEDED) {
      onClickHandlerModalDeleteContactClose();
    }
  };

  useEffect(callbackDeleteCallLogSucceededHandler, [statusDeleteCallLog]);
  useEffect(callbackDeleteThreadSucceededHandler, [statusDeleteThread]);
  useEffect(callbackDeleteVoicemailSucceededHandler, [statusDeleteVoicemail]);
  useEffect(callbackDeleteContactSucceededHandler, [statusDeleteContact]);

  return (
    <>
      <InfoTopBarActionsDropdownItem
        iconName={IconCommonNames.DELETE}
        label={intl.formatMessage({ id: getInfoTopBarActionsDropdownDeleteItemIntlId(type) })}
        onClick={onClickHandlerModalDeleteItemsOpen}
        propTestId={getInfoTopBarActionsDropdownDeleteItemTestId(type)}
      />

      <ModalsConfirmation
        type={getInfoTopBarActionsDropdownDeleteItemModalType(type)}
        isOpen={[
          stateModalDeleteCallLogVisible,
          stateModalDeleteThreadVisible,
          stateModalDeleteVoicemailVisible,
          stateModalDeleteContactVisible,
        ].includes(true)}
        isLoading={[statusDeleteCallLog, statusDeleteThread, statusDeleteVoicemail, statusDeleteContact].includes(
          Status.LOADING,
        )}
        title={intl.formatMessage({
          id: getInfoTopBarActionsDropdownDeleteItemModalTitleIntlId(type),
        })}
        content={intl.formatMessage({
          id: getInfoTopBarActionsDropdownDeleteItemModalDescriptionIntlId(type),
        })}
        confirmButtonText={intl.formatMessage({
          id: getInfoTopBarActionsDropdownDeleteItemModalButtonConfirmIntlId(type),
        })}
        cancelButtonText={intl.formatMessage({
          id: getInfoTopBarActionsDropdownDeleteItemModalButtonCancelIntlId(type),
        })}
        onConfirm={onClickHandlerModalDeleteItemsHandler}
        onCancel={onClickHandlerModalDeleteItemsClose}
      />
    </>
  );
};

export default InfoTopBarActionsDropdownFeaturesDelete;
