import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { checkoutGetCallbackPathFromStorage } from 'helpers/checkout';

import { ApplicationPaths } from 'routes/types';

const PageCheckoutSuccess: React.FC = () => {
  const { search } = useLocation();
  const targetUrl = checkoutGetCallbackPathFromStorage();

  return (
    <Navigate
      to={{
        // The Home is the fallback path
        pathname: targetUrl || ApplicationPaths.ROOT,
        search: targetUrl ? search : '',
      }}
    />
  );
};

export default PageCheckoutSuccess;
