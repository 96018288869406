import { useCallback } from 'react';

import { SIP } from '@constants';
import {
  sipSetDataCallStatusWebRTCByWebRTCIdHandler,
  sipSetDataCallTimestampAnsweredByWebRTCIdHandler,
  useWebAppDispatch,
} from '@redux';
import { SIPDataCallStatusesWebRTC, SIPWebRTCOriginators } from '@redux/types';

import { sipAudioManagementAudioPlay, sipDebugConsoleLogger } from '../../../../../../../helpers';
import { useSIPReportCallState } from '../../../../../../useSIPReportCallState';

import { OnAccepted, UseWebRTCAccepted } from './types';

export const useWebRTCAccepted: UseWebRTCAccepted = () => {
  const dispatch = useWebAppDispatch();

  const { sipReportCallStateWebRTCIncomingAnswer } = useSIPReportCallState();

  const onAccepted: OnAccepted = useCallback(
    ({ event, idWebRTC, idTransaction, originatorWebRTC }) => {
      sipDebugConsoleLogger('SIP WebRTC: SESSION ACCEPTED: ', { event });

      /**
       * Set WebRTC Session Status as ACTIVE (Call is started)
       */
      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusWebRTC: SIPDataCallStatusesWebRTC.ACTIVE,
        }),
      );

      /**
       * Set WebRTC Session Answered Timestamp
       */
      dispatch(
        sipSetDataCallTimestampAnsweredByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallTimestampCallAnswered: new Date().getTime(),
        }),
      );

      /**
       * Send ACCEPTED event to server side only for incoming call
       */
      if (originatorWebRTC === SIPWebRTCOriginators.REMOTE) {
        sipReportCallStateWebRTCIncomingAnswer({
          transactionId: idTransaction,
        });
      }

      /**
       * Start to Play The Existing Audio Element by the WebRTC Id
       * only if the the config is "fully"
       * More info => @constants/SIP.CONFIGURATIONS.AUDIO_MANAGEMENT
       *
       * The element is already appended to the DOM with => "useWebRTCConnections"
       */
      if (SIP.CONFIGURATIONS.AUDIO_MANAGEMENT.CALLS_OUTGOING_ARTIFICIAL_RINGBACK_TYPE === 'fully') {
        sipAudioManagementAudioPlay({ idWebRTC });
      }
    },
    [dispatch, sipReportCallStateWebRTCIncomingAnswer],
  );

  return { onAccepted };
};
