import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { SIPDataCallStatusesView, SIPDataCallStatusesWebRTC, SIPWebRTCOriginators } from '@redux/types';
import { testGetTestingAttributes, testIds } from '@testing';
import { ModalsCallNotes, ModalsCallTransfer } from 'components/Modals';

import { sipCallsIsCallOngoing } from '../../helpers';
import { useSIPCallsAnalytics } from '../../hooks';
import { SIPCallsListItemProps } from '../../types';
import SIPCallsListItemAudioRingback from '../SIPCallsListItemAudioRingback';
import SIPCallsListItemUICompleted from '../SIPCallsListItemUICompleted';
import SIPCallsListItemUIDefault from '../SIPCallsListItemUIDefault';
import SIPCallsListItemUIMinimized from '../SIPCallsListItemUIMinimized';

import styles from './SIPCallsListItem.module.scss';

const SIPCallsListItem: React.FC<SIPCallsListItemProps> = ({ data }) => {
  const { statuses, originator, timestamps } = data;
  const [stateIsCallNotesModalOpen, setStateIsCallNotesModalOpen] = useState<boolean>(false);
  const [stateIsCallNotesModalCloseAndSaveAsDraft, setStateIsCallNotesModalCloseAndSaveAsDraft] =
    useState<boolean>(false);
  const [stateIsCallTransferModalOpen, setStateIsCallTransferModalOpen] = useState<boolean>(false);

  useSIPCallsAnalytics({ call: data });

  const isCallLocal = originator === SIPWebRTCOriginators.LOCAL;
  const isCallRemote = originator === SIPWebRTCOriginators.REMOTE;
  const isCallOngoing = sipCallsIsCallOngoing(timestamps);
  const isCallWaiting = statuses.webRTC === SIPDataCallStatusesWebRTC.WAITING;

  const isCallViewDefault = statuses.view === SIPDataCallStatusesView.DEFAULT;
  const isCallViewMinimized = statuses.view === SIPDataCallStatusesView.MINIMIZED;
  const isCallViewCompleted = statuses.view === SIPDataCallStatusesView.COMPLETED;

  const onClickHandlerCallNotesModalOpen = (): void => {
    setStateIsCallNotesModalOpen(true);
  };

  const onClickHandlerCallNotesModalClose = (): void => {
    setStateIsCallNotesModalCloseAndSaveAsDraft(false);
    setStateIsCallNotesModalOpen(false);
  };

  const onClickHandlerCallNotesModalCloseAndSaveAsDraft = (): void => {
    setStateIsCallNotesModalCloseAndSaveAsDraft(true);
    setStateIsCallNotesModalOpen(false);
  };

  const onClickHandlerCallTransferModalOpen = (): void => {
    setStateIsCallTransferModalOpen(true);
  };

  const onClickHandlerCallTransferModalClose = (): void => {
    setStateIsCallTransferModalOpen(false);
  };

  useEffect(() => {
    if (stateIsCallTransferModalOpen && isCallViewCompleted) {
      onClickHandlerCallTransferModalClose();
    }
  }, [isCallViewCompleted, stateIsCallTransferModalOpen]);

  return (
    <div
      className={classNames(styles.root, {
        [styles.ongoing]: isCallOngoing,
        [styles.waiting]: isCallWaiting,
      })}
      {...testGetTestingAttributes(testIds.sipCallsListItemRoot)}
    >
      <div className={styles.content}>
        <SIPCallsListItemAudioRingback
          isCallLocal={isCallLocal}
          statusWebRTC={statuses.webRTC}
          timestampCallRang={timestamps.callRang}
        />

        {isCallViewDefault ? (
          <SIPCallsListItemUIDefault
            data={data}
            isCallLocal={isCallLocal}
            isCallRemote={isCallRemote}
            isCallOngoing={isCallOngoing}
            isCallWaiting={isCallWaiting}
            onClickHandlerCallNotesModalOpen={onClickHandlerCallNotesModalOpen}
            onClickHandlerCallTransferModalOpen={onClickHandlerCallTransferModalOpen}
          />
        ) : null}

        {isCallViewMinimized ? (
          <SIPCallsListItemUIMinimized
            data={data}
            isCallLocal={isCallLocal}
            isCallRemote={isCallRemote}
            isCallOngoing={isCallOngoing}
            isCallWaiting={isCallWaiting}
          />
        ) : null}

        {isCallViewCompleted ? (
          <SIPCallsListItemUICompleted
            data={data}
            onClickHandlerCallNotesModalCloseAndSaveAsDraft={onClickHandlerCallNotesModalCloseAndSaveAsDraft}
          />
        ) : null}

        <ModalsCallNotes
          data={data}
          isOpen={stateIsCallNotesModalOpen}
          isCloseAndSaveAsDraft={stateIsCallNotesModalCloseAndSaveAsDraft}
          onCloseHandler={onClickHandlerCallNotesModalClose}
          isCallInProgress={isCallOngoing}
        />

        <ModalsCallTransfer
          callData={data}
          isOpen={stateIsCallTransferModalOpen}
          onClose={onClickHandlerCallTransferModalClose}
        />
      </div>
    </div>
  );
};

export default SIPCallsListItem;
