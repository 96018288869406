import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@onoff/ui';

import { PaymentMethod } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  selectCreditCardsDataByNonExpired,
  selectPurchaseFlowSelectedPaymentMethod,
  purchaseFlowSetSelectedPaymentMethod,
} from '@redux';
import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal';

import ModalsSelectCreditCardItem from '../ModalsSelectCreditCardItem';

import styles from './ModalsSelectCreditCardContent.module.scss';

export type ModalsSelectCreditCardContentProps = {
  onClose: () => void;
};

const ModalsSelectCreditCardContent: React.FC<ModalsSelectCreditCardContentProps> = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const nonExpiredCreditCards = useWebAppSelector(selectCreditCardsDataByNonExpired);
  const selectedPaymentMethod = useWebAppSelector(selectPurchaseFlowSelectedPaymentMethod);

  const [paymentMethodId, setPaymentMethodId] = useState<string | undefined>(
    selectedPaymentMethod?.type === PaymentMethod.CREDIT_CARD ? selectedPaymentMethod?.id : undefined,
  );

  const onClickHandlerButtonCardSelect = useCallback((modalPaymentMethodId: string): void => {
    setPaymentMethodId(modalPaymentMethodId);
  }, []);

  const onClickHandlerButtonModalCancel = useCallback((): void => {
    onClose();
  }, [onClose]);

  const onClickHandlerButtonModalContinue = useCallback((): void => {
    dispatch(
      purchaseFlowSetSelectedPaymentMethod({
        type: PaymentMethod.CREDIT_CARD,
        id: paymentMethodId,
      }),
    );
    onClose();
  }, [paymentMethodId, dispatch, onClose]);

  return (
    <>
      <ModalHeader title={intl.formatMessage({ id: 'Purchase.CreditCardManagement.Modal.title' })} />
      <ModalBody className={styles.body}>
        {nonExpiredCreditCards.map((card) => (
          <ModalsSelectCreditCardItem
            key={card.methodId}
            card={card}
            isSelected={
              paymentMethodId
                ? card.methodId === paymentMethodId
                : selectedPaymentMethod?.type === PaymentMethod.CREDIT_CARD &&
                  card.methodId === selectedPaymentMethod.id
            }
            onSelect={onClickHandlerButtonCardSelect}
          />
        ))}
      </ModalBody>
      <ModalFooter>
        <Button
          className={styles.actionButton}
          colorScheme="black"
          variant="ghost"
          size="large"
          onClick={onClickHandlerButtonModalCancel}
        >
          <FormattedMessage id="Purchase.CreditCardManagement.Modal.button_cancel" />
        </Button>
        <Button
          className={classNames(styles.actionButton, styles.submitButton)}
          colorScheme="brand-b2c"
          variant="solid"
          size="large"
          onClick={onClickHandlerButtonModalContinue}
          disabled={selectedPaymentMethod?.type === PaymentMethod.CREDIT_CARD && !selectedPaymentMethod.id}
        >
          <FormattedMessage id="Purchase.CreditCardManagement.Modal.button_continue" />
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalsSelectCreditCardContent;
