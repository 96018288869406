import classNames from 'classnames';
import React from 'react';

import { CreditCardProps, CreditCardThemes } from '../../types';

import CreditCardIcon from './CreditCardIcon';

import styles from './CreditCardBrandOnlyCard.module.scss';

type CreditCardBrandProps = Partial<Pick<CreditCardProps, 'brand' | 'theme'>>;

const CreditCardBrandOnlyCard: React.FC<CreditCardBrandProps> = ({ theme, brand }) => (
  <div
    className={classNames(styles.root, {
      [styles.B2B]: theme === CreditCardThemes.B2B,
      [styles.B2C]: theme === CreditCardThemes.B2C,
    })}
  >
    <CreditCardIcon brand={brand} />
  </div>
);

export default CreditCardBrandOnlyCard;
