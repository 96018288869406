import classNames from 'classnames';
import React, { useRef } from 'react';

import { ButtonDropdownCore } from 'components/Button';

import { useDropdownPopper } from '../../hooks';
import { DropdownDefaultFormProps, DropdownDefaultProps } from '../../types';
import DropdownDefault from '../DropdownDefault';

import styles from './DropdownDefaultForm.module.scss';

const DropdownDefaultForm: React.FC<DropdownDefaultFormProps> = ({
  onSelect,
  selectedValue,
  options,
  className,
  propTestIds = {
    button: '',
    dropdown: '',
  },
}) => {
  const dropdownRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isDropdownOpen, openDropdown, closeDropdown, popper } = useDropdownPopper({
    dropdownRef,
    buttonRef,
  });

  const onSelectHanlder: DropdownDefaultProps['onSelect'] = (selectedType) => {
    onSelect(selectedType);
    closeDropdown();
  };

  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.dropdownOpen]: isDropdownOpen,
        },
        className,
      )}
    >
      <ButtonDropdownCore
        ref={buttonRef}
        className={styles.dropdownButton}
        onClick={openDropdown}
        propTestId={propTestIds.button}
      >
        {selectedValue}
      </ButtonDropdownCore>
      <DropdownDefault
        className={styles.dropdown}
        selectedValue={selectedValue}
        isOpen={isDropdownOpen}
        ref={dropdownRef}
        options={options}
        onSelect={onSelectHanlder}
        style={popper.styles.popper}
        propTestId={propTestIds.dropdown}
      />
    </div>
  );
};

export default DropdownDefaultForm;
