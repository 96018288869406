export enum WebRTCMOSReportTypes {
  CANDIDATE_PAIR = 'candidate-pair',
  TRANSPORT = 'transport',
  LOCAL_CANDIDATE = 'local-candidate',
}

export enum WebRTCMOSStatNames {
  PACKETS_SENT = 'packetsSent',
  PACKETS_RECEIVED = 'packetsReceived',
  NETWORK_TYPE = 'networkType',
}

export enum WebRTCMOSResultDescriptions {
  ERROR = 'ERROR',
  UNAVAILABLE = 'UNAVAILABLE',
  AWESOME = 'Awesome! 😎',
  GOOD = 'Good! 😏',
  NORMAL = 'Normal 😌',
  MEH = 'Meh... 😢',
  BAD = 'Bad... 😱',
}
