import { CountryCode } from 'libphonenumber-js';

import { SupportedLanguage } from 'types';

import { KycStatusResponse, UserFeaturesResponse, UserResponse } from '../server/main';

export { AccountType, AppProductType } from '../server/main';

export interface AllowedCountry {
  code: CountryCode;
  phoneCode: string;
  name: string;
  supportedFunctionality?: string;
}

export interface AvailableCountry {
  code: CountryCode;
  phoneCode: string;
  name: string;
  supportedFunctionality: string;
}

export interface AllowedCountries {
  supported: AllowedCountry[];
}

export interface AvailableCountries {
  supported: AvailableCountry[];
}

export type UserFeatures = UserFeaturesResponse;

export enum UserCallAnswerMethods {
  CALLBACK = 'CALLBACK',
  VOIP = 'VOIP',
  OFFLINE = 'OFFLINE',
}

export enum UserTypes {
  B2B = 'B2B',
  B2C = 'B2C',
}

export interface User extends UserResponse {
  userId: string;
  email: string;
  b2bUser: boolean;
  firstName: string;
  lastName: string;
  showEmergencyCallsWarning: boolean;
  kycStatus: KycStatusResponse;
  nationalCountryIsoCode: CountryCode;
  countryIsoCode: CountryCode;
  communicationLocale: SupportedLanguage;
  callAnswerMethod: UserCallAnswerMethods;
  allowedCountries: AllowedCountries;
  availableCountries: AvailableCountries;
}

export interface UserAuth {
  authKey: string;
  userDeviceId: string;
}

export interface UserCredits {
  expirationDate: string;
  totalCredits: string;
}
