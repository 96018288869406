import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorCore, ErrorType } from 'components/Error';

import styles from './LazyErrorBoundary.module.scss';

const LazyErrorBoundary: React.FC = () => {
  const intl = useIntl();

  const onClickHandlerReloadPage = (): void => {
    window.location.reload();
  };

  return (
    <div className={styles.root}>
      <ErrorCore
        title={intl.formatMessage({ id: 'Sentry.react_error_boundary_title' })}
        description={intl.formatMessage({ id: 'Sentry.react_error_boundary_description' })}
        buttonText={intl.formatMessage({ id: 'Sentry.react_error_boundary_button_text' })}
        type={ErrorType.ERROR_CHUNK}
        onClick={onClickHandlerReloadPage}
        classNameIllustration={styles.illustration}
      />
    </div>
  );
};

export default LazyErrorBoundary;
