import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { SIPDataCallStatusesCallInitialising, SIPDataCallStatusesView, SIPDataCallStatusesWebRTC } from '@redux/types';
import { testGetTestingAttributes, testIds } from '@testing';

import { sipCallsCreateTimeAsHhMmSs } from '../../helpers';
import { SIPCallsListItemInfoStatesProps } from '../../types';

import styles from './SIPCallsListItemInfoStates.module.scss';

const SIPCallsListItemInfoStates: React.FC<SIPCallsListItemInfoStatesProps> = ({
  isCallLocal,
  isCallRemote,
  isCallOngoing,
  isCallWaiting,
  statusCallInitializing,
  statusWebRTC,
  statusView,
  timestampCallAnswered,
  timestampCallRang,
}) => {
  const { timeAsHhMmSs: initialTimeAsHhMmSs } = sipCallsCreateTimeAsHhMmSs({
    timestampStart: new Date().getTime(),
    timestampEnd: timestampCallAnswered,
  });
  const [stateTimerData, setStateTimerData] = useState<string>(initialTimeAsHhMmSs);
  const refTimerInterval = useRef<number>(0);

  const isUIDefault = statusView === SIPDataCallStatusesView.DEFAULT;
  const isUIMinimized = statusView === SIPDataCallStatusesView.MINIMIZED;

  const isCallLocalAndRemoteOnHold = isCallOngoing && isCallWaiting && (isUIDefault || isUIMinimized);
  const isCallLocalAndRemoteTimer = isCallOngoing && (isUIDefault || isUIMinimized);
  const isCallLocalPreparing = !isCallOngoing && isCallLocal && isUIDefault;
  const isCallLocalPreparingAndRinging =
    isCallLocalPreparing &&
    [SIPDataCallStatusesWebRTC.RINGING_180, SIPDataCallStatusesWebRTC.RINGING_183].includes(statusWebRTC) &&
    timestampCallRang > 0;
  const isCallRemoteRinging = !isCallOngoing && isCallRemote && isUIDefault;
  const isCallRemoteRingingAndConnecting = isCallRemoteRinging && statusWebRTC === SIPDataCallStatusesWebRTC.ANSWERED;

  const callbackTimerHandler: () => () => void = () => {
    if (timestampCallAnswered > 0 && refTimerInterval.current === 0) {
      refTimerInterval.current = window.setInterval(() => {
        const { timeAsHhMmSs: liveTimeAsHhMmSs } = sipCallsCreateTimeAsHhMmSs({
          timestampStart: new Date().getTime(),
          timestampEnd: timestampCallAnswered,
        });

        setStateTimerData(liveTimeAsHhMmSs);
      }, 400);
    }

    return () => {
      window.clearInterval(refTimerInterval.current);
      refTimerInterval.current = 0;
    };
  };

  useEffect(callbackTimerHandler, [timestampCallAnswered]);

  if (statusCallInitializing === SIPDataCallStatusesCallInitialising.INITIALISING) {
    return (
      <span
        key="sipCallsListItemInfoStatesInitializing"
        className={styles.root}
        {...testGetTestingAttributes(testIds.sipCallsListItemInfoStatesInitializing)}
      >
        <FormattedMessage id="SIP.Calls.UI.state_call_initializing" />
      </span>
    );
  }
  if (isCallLocalAndRemoteOnHold) {
    return (
      <span
        key="sipCallsListItemInfoStatesWaiting"
        className={styles.root}
        {...testGetTestingAttributes(testIds.sipCallsListItemInfoStatesWaiting)}
      >
        <FormattedMessage id="SIP.Calls.UI.state_call_on_hold" />
      </span>
    );
  }

  if (isCallLocalAndRemoteTimer) {
    return (
      <span
        key="sipCallsListItemInfoStatesTimer"
        className={styles.root}
        {...testGetTestingAttributes(testIds.sipCallsListItemInfoStatesTimer)}
      >
        {stateTimerData}
      </span>
    );
  }

  if (isCallLocalPreparing) {
    return (
      <span
        key="sipCallsListItemInfoStatesCallLocalPreparing"
        className={styles.root}
        {...testGetTestingAttributes(
          isCallLocalPreparingAndRinging
            ? testIds.sipCallsListItemInfoStatesOutgoingRinging
            : testIds.sipCallsListItemInfoStatesOutgoingPreparing,
        )}
      >
        {isCallLocalPreparingAndRinging ? (
          <FormattedMessage id="SIP.Calls.UI.state_call_ringing" />
        ) : (
          <FormattedMessage id="SIP.Calls.UI.state_call_in_progress" />
        )}
      </span>
    );
  }

  if (isCallRemoteRinging) {
    return (
      <span
        key="sipCallsListItemInfoStatesCallRemoteRinging"
        className={styles.root}
        {...testGetTestingAttributes(
          isCallRemoteRingingAndConnecting
            ? testIds.sipCallsListItemInfoStatesIncomingConnecting
            : testIds.sipCallsListItemInfoStatesIncomingRinging,
        )}
      >
        {isCallRemoteRingingAndConnecting ? (
          <FormattedMessage id="SIP.Calls.UI.state_call_connecting" />
        ) : (
          <FormattedMessage id="SIP.Calls.UI.state_call_incoming" />
        )}
      </span>
    );
  }

  return null;
};

export default SIPCallsListItemInfoStates;
