import { v4 as uuidv4 } from 'uuid';

import { NotificationsBrowserPermission, NotificationsBrowserData, NotificationsBrowserTypes } from 'types';

import { createNotificationsBrowserContactName } from 'helpers';

import { selectContactByPhoneNumber } from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  NotificationsBrowserActionSetPermission,
  NotificationsBrowserActionSetData,
  NotificationsBrowserActionRemoveData,
  NotificationsBrowserSetDataHandlerProps,
  NotificationsBrowserSetCallHandlerProps,
  NotificationsBrowserSetMessageHandlerProps,
  NotificationsBrowserSetVoicemailHandlerProps,
} from '../../types';

export const notificationsBrowserSetPermission = (
  permission: NotificationsBrowserPermission,
): NotificationsBrowserActionSetPermission => ({
  type: REDUX_ACTION_TYPES.NOTIFICATIONS_BROWSER_SET_PERMISSION,
  permission,
});

export const notificationsBrowserSetData = (data: NotificationsBrowserData): NotificationsBrowserActionSetData => ({
  type: REDUX_ACTION_TYPES.NOTIFICATIONS_BROWSER_SET_DATA,
  data,
});

export const notificationsBrowserRemoveData = (dataId: string): NotificationsBrowserActionRemoveData => ({
  type: REDUX_ACTION_TYPES.NOTIFICATIONS_BROWSER_REMOVE_DATA,
  dataId,
});

export const notificationsBrowserSetDataHandler =
  ({ type, sourcePhoneNumber, body = '' }: NotificationsBrowserSetDataHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const contact = selectContactByPhoneNumber(sourcePhoneNumber)(getState());
    const contactNameOrNumber = createNotificationsBrowserContactName({
      sourcePhoneNumber,
      contact,
    });

    dispatch(
      notificationsBrowserSetData({
        id: uuidv4(),
        type,
        contactNameOrNumber,
        body,
      }),
    );
  };

export const notificationsBrowserSetCallHandler = ({
  sourcePhoneNumber,
}: NotificationsBrowserSetCallHandlerProps): ThunkResult<Promise<void>> =>
  notificationsBrowserSetDataHandler({
    type: NotificationsBrowserTypes.CALLS,
    sourcePhoneNumber,
  });

export const notificationsBrowserSetMessageHandler = ({
  sourcePhoneNumber,
  body,
}: NotificationsBrowserSetMessageHandlerProps): ThunkResult<Promise<void>> =>
  notificationsBrowserSetDataHandler({
    type: NotificationsBrowserTypes.MESSAGES,
    sourcePhoneNumber,
    body,
  });

export const notificationsBrowserSetVoicemailHandler = ({
  sourcePhoneNumber,
}: NotificationsBrowserSetVoicemailHandlerProps): ThunkResult<Promise<void>> =>
  notificationsBrowserSetDataHandler({
    type: NotificationsBrowserTypes.VOICEMAILS,
    sourcePhoneNumber,
  });
