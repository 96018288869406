import { FieldError } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { SaveSimpleContactRequest } from 'types';

import { ModalsAddEditContactFormData, ModalsAddEditContactFormProps } from '../types';

export const createFormAddContactFormData = (
  formData: SaveSimpleContactRequest | null = {},
  categoryIdDefault = '',
): ModalsAddEditContactFormData => {
  const defaultProps = {
    categoryId: categoryIdDefault,
    firstName: '',
    lastName: '',
    company: '',
    notes: '',
    emails: [],
    phones: [],
    imageUrl: '',
  };

  const allProps = {
    ...defaultProps,
    ...formData,
  };

  const createDefaultPhone = () => ({
    type: 'Mobile',
    number: '',
    favorite: undefined,
    normalNumber: undefined,
    id: uuidv4(),
  });

  const createDefaultEmail = () => ({
    type: 'Home',
    email: '',
    id: uuidv4(),
  });

  const phones =
    allProps?.phones?.length > 0
      ? allProps.phones.map((phone) => ({
          ...createDefaultPhone(),
          ...phone,
        }))
      : [createDefaultPhone()];

  const emails =
    allProps?.emails?.length > 0
      ? allProps.emails.map((email) => ({
          ...createDefaultEmail(),
          ...email,
        }))
      : [createDefaultEmail()];

  return {
    ...allProps,
    phones,
    emails,
  };
};

export const hasErrorText = (error: FieldError | undefined): boolean => {
  if (error === undefined) {
    return false;
  }

  const { message = '' } = error;

  return message.length > 0;
};

export const hasFullNameError = (errors: ModalsAddEditContactFormProps['errors']): boolean => {
  const { firstName, lastName } = errors;
  return hasErrorText(firstName) || hasErrorText(lastName);
};

export const getPhoneErrorMessage = (
  errors: ModalsAddEditContactFormProps['errors'],
  index: number,
): string | undefined => {
  const { phones = [] } = errors;
  const error = phones[index];
  if (error === undefined) {
    return '';
  }

  return error?.number?.message;
};

export const getEmailErrorMessage = (
  errors: ModalsAddEditContactFormProps['errors'],
  index: number,
): string | undefined => {
  const { emails = [] } = errors;
  const error = emails[index];
  if (error === undefined) {
    return '';
  }

  return error?.email?.message;
};
