import { isString } from 'helpers';

import { SMSAttachmentTypes } from '../types';

export const isSMSValueValid = (value: string | File): boolean => {
  // Text
  if (isString(value)) {
    return value.length > 0;
  }

  // File
  return true;
};

export const isSMSValueAnImage = (value: string | File): boolean =>
  !isString(value) && value.type.startsWith(SMSAttachmentTypes.IMAGE);

export const isSMSValueAnAudio = (value: string | File): boolean =>
  !isString(value) && value.type.startsWith(SMSAttachmentTypes.AUDIO);
