import React from 'react';
import { useIntl } from 'react-intl';

import { SIPDataCallStatusesWebRTC } from '@redux/types';
import { testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { useSIPCallMethodsOngoing } from '../../../../hooks/useSIPCallMethods';
import { SIPCallsListItemButtonFeaturesRejectProps } from '../../types';
import SIPCallsListItemButtonTooltip from '../SIPCallsListItemButtonTooltip';

import styles from './SIPCallsListItemButtonFeaturesReject.module.scss';

const SIPCallsListItemButtonFeaturesReject: React.FC<SIPCallsListItemButtonFeaturesRejectProps> = ({
  statusWebRTC,
  idWebRTC,
}) => {
  const intl = useIntl();
  const { sipCallReject } = useSIPCallMethodsOngoing();

  const isFeatureDisabled = [SIPDataCallStatusesWebRTC.DEFAULT, SIPDataCallStatusesWebRTC.PUSH_OUTGOING].includes(
    statusWebRTC,
  );

  const isFeatureNotAvailable =
    !isFeatureDisabled &&
    ![
      SIPDataCallStatusesWebRTC.RINGING_180,
      SIPDataCallStatusesWebRTC.RINGING_183,
      SIPDataCallStatusesWebRTC.ANSWER,
      SIPDataCallStatusesWebRTC.ANSWERED,
    ].includes(statusWebRTC);

  if (isFeatureNotAvailable) {
    return null;
  }

  const onClickHandlerButtonReject = (): void => {
    sipCallReject({
      idWebRTC,
    });
  };

  if (isFeatureNotAvailable) {
    return null;
  }

  return (
    <SIPCallsListItemButtonTooltip
      content={intl.formatMessage({ id: 'SIP.Calls.UI.ButtonTooltips.end_call' })}
      placement="bottom-end"
    >
      <IconButton
        className={styles.root}
        onClick={onClickHandlerButtonReject}
        iconName={IconCommonNames.CALL_END}
        iconSize={IconCommonSizes.TINY}
        propTestId={testIds.sipCallsListItemButtonFeatureRejectRoot}
        isDisabled={isFeatureDisabled}
      />
    </SIPCallsListItemButtonTooltip>
  );
};

export default SIPCallsListItemButtonFeaturesReject;
