import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { testGetTestingAttributes } from '@testing';

import { DropdownCommonOptionProps } from '../../types';

import styles from './DropdownCommonOption.module.scss';

export const DropdownCommonOption: React.FC<DropdownCommonOptionProps & PropsWithChildren> = ({
  children,
  onClick,
  className,
  propTestId,
}) => (
  <div
    className={classNames(styles.root, className)}
    onClick={onClick}
    {...testGetTestingAttributes(propTestId)}
  >
    {children}
  </div>
);

export default DropdownCommonOption;
