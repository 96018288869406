import { createIntl, createIntlCache, IntlConfig, IntlShape } from 'react-intl';

import { SUPPORTED_LANGUAGES } from '../@constants';

import { intlMessagesFlatten, intlMessages } from './helpers';

const intlCache = createIntlCache();

let intl: IntlShape = createIntl(
  {
    locale: SUPPORTED_LANGUAGES.EN,
    messages: intlMessagesFlatten[SUPPORTED_LANGUAGES.EN],
  },
  intlCache,
);

const getIntl = () => intl;

const setIntl = (props: IntlConfig) => {
  intl = createIntl(props, intlCache);

  return intl;
};

const setIntlLanguage = (language: keyof typeof SUPPORTED_LANGUAGES) => {
  if (intl.locale === language) {
    return;
  }

  setIntl({ locale: language, messages: intlMessagesFlatten[language] });
};

export { intlMessages, intlMessagesFlatten, setIntl, getIntl, setIntlLanguage };
