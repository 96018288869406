import { Reducer } from '@reduxjs/toolkit';

import { REDUX_ACTION_TYPES, NotificationsBrowserReduxState, NotificationsBrowserActionTypes } from '../../types';

export const notificationsBrowserInitialState: NotificationsBrowserReduxState = {
  permission: 'default',
  dataList: [],
};

export const notificationsBrowserReducer: Reducer<NotificationsBrowserReduxState, NotificationsBrowserActionTypes> = (
  state,
  action,
) => {
  const reducerState: NotificationsBrowserReduxState = state || notificationsBrowserInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.NOTIFICATIONS_BROWSER_SET_PERMISSION: {
      const { permission } = action;

      return {
        ...reducerState,
        permission,
      };
    }

    case REDUX_ACTION_TYPES.NOTIFICATIONS_BROWSER_SET_DATA: {
      const { data } = action;

      return {
        ...reducerState,
        dataList: [...reducerState.dataList, data],
      };
    }

    case REDUX_ACTION_TYPES.NOTIFICATIONS_BROWSER_REMOVE_DATA: {
      const { dataId } = action;

      return {
        ...reducerState,
        dataList: reducerState.dataList.filter((data) => data.id !== dataId),
      };
    }

    default:
      return reducerState;
  }
};
