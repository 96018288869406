import classNames from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';

import styles from './InputSelectItem.module.scss';

export interface InputSelectItemProps extends HTMLAttributes<HTMLDivElement> {
  id: string;
  'data-value'?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  icon?: React.ReactNode;
}

const InputSelectItem = forwardRef<HTMLDivElement, InputSelectItemProps>(
  ({ children, isSelected, isDisabled, isHidden, icon, className, ...rest }, ref) => (
    <div
      role="button"
      ref={ref}
      aria-disabled={isDisabled}
      aria-hidden={isHidden}
      className={classNames(className, styles.root, {
        [styles.disabled]: isDisabled,
        [styles.selected]: isSelected,
        [styles.hidden]: isHidden,
      })}
      {...rest}
    >
      {icon && <span className={styles.iconWrapper}>{icon}</span>}

      {children}
    </div>
  ),
);

export default InputSelectItem;

InputSelectItem.displayName = 'SelectItem';
