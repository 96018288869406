import classNames from 'classnames';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Input, InputLabel } from '@onoff/ui';

import { FrameValidationProps } from 'types';

import { testGetTestingAttributes, testIds } from '@testing';

import { AddCreditCardFormData } from '../..';

import CheckoutForm, { CheckoutFormProps } from './CheckoutForm';

import styles from './CardDetails.module.scss';

export type AddCreditCardFormProps = Omit<
  CheckoutFormProps,
  'children' | 'onFrameValidationChanged' | 'onCardSubmitted'
>;

const CardDetails: React.FC<AddCreditCardFormProps> = (props) => {
  const intl = useIntl();
  const { register, formState } = useFormContext<AddCreditCardFormData>();
  const { errors } = formState;

  const [fieldErrors, setFieldErrors] = useState({ 'card-number': false, 'expiry-date': false, cvv: false });

  const handleCardSubmit = (): void => {
    setTimeout(() => window.Frames.enableSubmitForm(), 300);
  };

  const handleFrameValidationChanged = ({ element, isValid }: FrameValidationProps): void => {
    setFieldErrors((prevState) => ({
      ...prevState,
      [element]: !isValid,
    }));
  };

  return (
    <fieldset className={styles.root}>
      <legend>
        <InputLabel.Text>
          <FormattedMessage id="Modals.ModalAddCreditCard.CardDetails.title" />
        </InputLabel.Text>
      </legend>
      <CheckoutForm
        onFrameValidationChanged={handleFrameValidationChanged}
        onCardSubmitted={handleCardSubmit}
        {...props}
      >
        <Input
          {...testGetTestingAttributes(testIds.addCreditCardInputFirstname)}
          placeholder={intl.formatMessage({ id: 'Modals.ModalAddCreditCard.Inputs.firstName_placeholder' })}
          errorMessage={errors.firstName?.message}
          {...register('firstName')}
        />
        <Input
          {...testGetTestingAttributes(testIds.addCreditCardInputLastname)}
          placeholder={intl.formatMessage({ id: 'Modals.ModalAddCreditCard.Inputs.lastName_placeholder' })}
          errorMessage={errors.lastName?.message}
          {...register('lastName')}
        />
        <Input
          customInput={
            <div
              {...testGetTestingAttributes(testIds.addCreditCardInputWrapperCardNumber)}
              className={classNames(
                styles.input,
                {
                  [styles.error]: fieldErrors['card-number'],
                },
                'card-number-frame',
              )}
            />
          }
          errorMessage={
            fieldErrors['card-number'] ? intl.formatMessage({ id: 'Settings.billing_card_number_error' }) : ''
          }
        />
        <Input
          customInput={
            <div
              {...testGetTestingAttributes(testIds.addCreditCardInputWrapperCardExpiryDate)}
              className={classNames(
                styles.input,
                {
                  [styles.error]: fieldErrors['expiry-date'],
                },
                'expiry-date-frame',
              )}
            />
          }
          errorMessage={
            fieldErrors['expiry-date'] ? intl.formatMessage({ id: 'Settings.billing_expiry_date_error' }) : ''
          }
        />

        <Input
          customInput={
            <div
              {...testGetTestingAttributes(testIds.addCreditCardInputWrapperCardCvv)}
              className={classNames(
                styles.input,
                {
                  [styles.error]: fieldErrors.cvv,
                },
                'cvv-frame',
              )}
            />
          }
          errorMessage={fieldErrors.cvv ? intl.formatMessage({ id: 'Settings.billing_cvv_error' }) : ''}
        />
      </CheckoutForm>
    </fieldset>
  );
};

export default CardDetails;
