import React from 'react';

import { StyleableComponent } from 'types';

const VoicemailTranscription: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3238_10119)">
      <rect
        width="50"
        height="50"
        fill="#65A364"
      />
      <path
        d="M0 8.87784C0 3.97475 3.97474 0 8.87784 0L41.1222 0C46.0253 0 50 3.97474 50 8.87784V41.1222C50 46.0253 46.0253 50 41.1222 50H8.87784C3.97475 50 0 46.0253 0 41.1222L0 8.87784Z"
        fill="#65A364"
      />
      <path
        d="M25.0001 27.3437C26.9454 27.3437 28.5157 25.7734 28.5157 23.8281V16.7969C28.5157 14.8516 26.9454 13.2812 25.0001 13.2812C23.0547 13.2812 21.4844 14.8516 21.4844 16.7969V23.8281C21.4844 25.7734 23.0547 27.3437 25.0001 27.3437ZM31.9258 23.8281C31.3516 23.8281 30.8712 24.25 30.7774 24.8242C30.2969 27.5781 27.8946 29.6875 25.0001 29.6875C22.1055 29.6875 19.7032 27.5781 19.2227 24.8242C19.129 24.25 18.6485 23.8281 18.0743 23.8281C17.3594 23.8281 16.7969 24.4609 16.9024 25.1641C17.4766 28.6797 20.2891 31.4336 23.8282 31.9375V34.375C23.8282 35.0195 24.3555 35.5469 25.0001 35.5469C25.6446 35.5469 26.1719 35.0195 26.1719 34.375V31.9375C29.711 31.4336 32.5235 28.6797 33.0977 25.1641C33.2149 24.4609 32.6407 23.8281 31.9258 23.8281Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3238_10119">
        <rect
          width="50"
          height="50"
          rx="10"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default VoicemailTranscription;
