import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ModalUniStyle as s1 } from 'components/UI/styleRoutes';

class ModalUniBody extends Component {
  static defaultProps = {
    className: '',
    style: {},
  };

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
  };

  render() {
    return (
      <div className={`${s1.content} ${this.props.className}`} style={this.props.style}>
        { this.props.children}
      </div>
    );
  }
}

export default ModalUniBody;
