import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { PageSettingsPlansHashes } from 'pages/settings-plans/enums';
import { ApplicationPaths } from 'routes';

import CategoryCommonButton from '../CategoryCommonButton';

const CategoryCommonButtonNumberSettings: React.FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const onClickHandler = (): void => {
    navigate(`${ApplicationPaths.SETTINGS_PLANS}${PageSettingsPlansHashes.USER_NUMBERS}`);
  };

  return (
    <CategoryCommonButton
      title={intl.formatMessage({ id: 'Categories.number_settings' })}
      onClick={onClickHandler}
    />
  );
};

export default CategoryCommonButtonNumberSettings;
