import { ActionCreator } from '@reduxjs/toolkit';

import { BalanceSetAction, ThunkResult, REDUX_ACTION_TYPES } from '../../types';

export const setBalance: ActionCreator<BalanceSetAction> = (balance: number) => ({
  type: REDUX_ACTION_TYPES.SET_BALANCE,
  balance,
});

export const fetchUserCredits =
  (): ThunkResult<Promise<void>> =>
  (dispatch, _getState, services): Promise<void> =>
    services.userService.fetchUserCredits().then((userCredits) => {
      const balance = parseFloat(userCredits.totalCredits);
      dispatch(setBalance(balance));
    });
