import { PaymentMethod } from 'types';

import { SESSIONSTORAGE_KEYS } from '@constants';

export const sessionStoragePersistenceAddPaymentMethod = (paymentMethod: PaymentMethod) => {
  window.sessionStorage.setItem(SESSIONSTORAGE_KEYS.PAYMENT_METHOD, paymentMethod);
};

export const sessionStoragePersistenceGetPaymentMethod = () =>
  window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.PAYMENT_METHOD) as PaymentMethod | undefined;

export const sessionStoragePersistenceRemovePaymentMethod = () => {
  window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.PAYMENT_METHOD);
};
