import classNames from 'classnames';
import { CountryCode } from 'libphonenumber-js';
import { useCallback } from 'react';

import { Modal, ModalSize } from 'components/Modal';

import { ModalsSelectCountryProps } from '../../types';
import ModalsSelectCountryContent from '../ModalsSelectCountryContent';

import styles from './ModalsSelectCountry.module.scss';

const ModalsSelectCountry: React.FC<ModalsSelectCountryProps> = ({
  isOpen,
  onClose,
  className = '',
  countryList = [],
  onSelectCountry = () => undefined,
}) => {
  const handleSelectCountry = useCallback(
    (country: CountryCode) => {
      onSelectCountry(country);
      onClose();
    },
    [onSelectCountry, onClose],
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      size={ModalSize.XS}
      className={classNames(styles.root, className)}
    >
      <ModalsSelectCountryContent
        countryList={countryList}
        onSelectCountry={handleSelectCountry}
      />
    </Modal>
  );
};

export default ModalsSelectCountry;
