import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

import styles from './ToggleButton.module.scss';

type ToggleButtonProps = { isSelected: boolean } & HTMLAttributes<HTMLButtonElement>;

export const ToggleButton: React.FC<ToggleButtonProps> = ({ isSelected, children, ...props }) => (
  <button
    className={classNames(styles.root, { [styles.selected]: isSelected })}
    {...props}
  >
    {children}
  </button>
);

export default ToggleButton;
