import { useWebAppDispatch, pushGetPushesHandler } from '@redux';

import { UsePubNubMessagesHandlerPushHistoryReturn } from '../../types';

export const usePubNubMessagesHandlerPushHistory = (): UsePubNubMessagesHandlerPushHistoryReturn => {
  const dispatch = useWebAppDispatch();

  const pubNubMessageHandlerPushHistoryUpdate = (): void => {
    dispatch(pushGetPushesHandler({ isWindowOnline: false }));
  };

  return {
    pubNubMessageHandlerPushHistoryUpdate,
  };
};
