import classNames from 'classnames';
import React from 'react';

import { BorderWrapperCore } from 'components/BorderWrapper';

import { useInputState } from '../../hooks';
import { InputTextProps } from '../../types';
import InputCommonLayout from '../InputCommonLayout';
import InputCore from '../InputCore';

const InputText: React.FC<InputTextProps> = ({
  onChange,
  placeholder,
  isDisabled,
  isErrorMessageHidden,
  value,
  error = '',
  className = '',
  label = '',
  hint = '',
  propTestId = '',
  type,
  autoComplete,
}) => {
  const { hasFocus, isEmpty, hasHover, onBlur, onFocus, onMouseOver, onMouseLeave, onChangeHandler } = useInputState({
    value,
    onChange,
  });
  const hasError = error.length > 0;

  return (
    <InputCommonLayout
      className={classNames(className)}
      label={label}
      error={error}
      hint={hint}
      isErrorMessageHidden={isErrorMessageHidden}
    >
      <BorderWrapperCore
        hasFocus={hasFocus}
        isDisabled={isDisabled}
        isEmpty={isEmpty}
        hasError={hasError}
        hasHover={hasHover}
      >
        <InputCore
          value={value}
          onChange={onChangeHandler}
          placeholder={placeholder}
          isDisabled={isDisabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          propTestId={propTestId}
          type={type}
          autoComplete={autoComplete}
        />
      </BorderWrapperCore>
    </InputCommonLayout>
  );
};

export default InputText;
