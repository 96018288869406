export type NotificationsBrowserPermission = NotificationPermission;

export enum NotificationsBrowserPermissionTypes {
  DEFAULT = 'default',
  DENIED = 'denied',
  GRANTED = 'granted',
}

export enum NotificationsBrowserTypes {
  CALLS = 'calls',
  MESSAGES = 'messages',
  VOICEMAILS = 'voicemails',
}

export interface NotificationsBrowserData {
  id: string;
  type: NotificationsBrowserTypes;
  contactNameOrNumber: string;
  body: string;
}
