import { useState } from 'react';

import { isString } from 'helpers';

import { UseFilePhotoOptions, UseFilePhotoResult } from '../types';

const useFilePhoto = (options: UseFilePhotoOptions): UseFilePhotoResult => {
  const { onChange, imageUrl, fileRef } = options;
  const { current } = fileRef;
  const [imageSrc, setImageSrc] = useState(() => {
    if (isString(imageUrl)) {
      return imageUrl;
    }
    return '';
  });

  return {
    imageSrc,
    onChangeHandler: (event) => {
      const { target } = event;
      const { files } = target;

      if (files === null || files.length === 0) {
        return;
      }

      const file = files[0];
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          setImageSrc(`${reader.result}`);
        },
        false,
      );
      reader.readAsDataURL(file);

      onChange(files);
    },
    clear: () => {
      if (current !== null) {
        current.value = '';
      }
      setImageSrc('');
      onChange(null);
    },
  };
};

export default useFilePhoto;
