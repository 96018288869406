import { ButtonProps } from '@onoff/ui';

import { Status } from 'types';

import { IntegrationStatusMap } from '@redux/types';

export const isAtLeastOneStatusLoading = (statusMap: IntegrationStatusMap): boolean =>
  Object.values(statusMap).some((status) => status === Status.LOADING);

export const getButtonIntlKey = (status: Status): string | undefined => {
  const buttonText = new Map<Status, string>([
    [Status.IDLE, 'Integrations.ExportContactModal.export'],
    [Status.SUCCEEDED, 'Integrations.ExportContactModal.exported'],
    [Status.FAILED, 'Integrations.ExportContactModal.retry'],
  ]);

  return buttonText.get(status);
};

export const getButtonColorScheme = (status: Status): ButtonProps['colorScheme'] =>
  status === Status.FAILED ? 'destructive' : 'brand-b2b';

export const getButtonVariant = (status: Status): ButtonProps['variant'] => {
  const buttonVariant = new Map<Status, ButtonProps['variant']>([
    [Status.IDLE, 'solid'],
    [Status.LOADING, 'solid'],
    [Status.SUCCEEDED, 'ghost'],
    [Status.FAILED, 'outline'],
  ]);

  return buttonVariant.get(status) ?? 'solid';
};
