import React from 'react';

import { StyleableComponent } from 'types';

const ErrorIllustrationDisconnect: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M200 100C200 155.228 155.228 200 100 200C44.7715 200 0 155.228 0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 100C187.368 148.252 148.252 187.368 100 187.368C51.7478 187.368 12.6316 148.252 12.6316 100C12.6316 51.7478 51.7478 12.6316 100 12.6316C148.252 12.6316 187.368 51.7478 187.368 100Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 100C172.105 139.823 139.823 172.105 100 172.105C60.1774 172.105 27.8948 139.823 27.8948 100C27.8948 60.1774 60.1774 27.8948 100 27.8948C139.823 27.8948 172.105 60.1774 172.105 100Z"
      fill="#F3F4F5"
    />
    <mask
      id="mask0_2493_22032"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="200"
      height="200"
    >
      <path
        d="M0 0H200V200H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2493_22032)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.889 75.4154L86.5258 104.231L72.1179 112.55C64.1612 117.143 53.9855 114.417 49.3917 106.46C44.7973 98.5024 47.5244 88.3276 55.4812 83.7338L69.889 75.4154Z"
        fill="#C8CBCE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.9357 79.4299L79.5725 108.246L86.5258 104.231L69.889 75.4154L62.9357 79.4299Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0477 84.3506L72.3087 79.6064L78.2166 76.1955C79.5256 75.4397 81.2016 75.8888 81.9579 77.1989C82.7137 78.508 82.2647 80.1839 80.9556 80.9397L75.0477 84.3506Z"
        fill="#8E929B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.1057 100.039L81.3673 95.2963L87.2752 91.8853C88.5842 91.1295 90.2596 91.5776 91.016 92.8877C91.7718 94.1968 91.3227 95.8727 90.0136 96.6285L84.1057 100.039Z"
        fill="#8E929B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63434 89.23C10.9277 95.0587 13.4626 100.158 18.4045 103.93C26.6012 110.185 33.9317 110.586 40.1318 108.808C46.5034 106.98 51.848 102.802 55.7995 99.5252L58.7548 103.089C54.7973 106.371 48.7999 111.138 41.4084 113.258C33.8455 115.428 24.992 114.781 15.5958 107.61C9.54228 102.99 6.56849 96.7853 5.11457 90.2328C3.67558 83.7476 3.69332 76.7572 3.71835 70.4533L3.71835 70.4525C3.74403 63.9855 3.76626 58.3864 2.57045 54.0935C1.98765 52.0013 1.14935 50.3666 -0.00862026 49.1476C-1.14606 47.9502 -2.71376 47.0244 -4.98901 46.5324L-4.01061 42.0073C-0.932785 42.6728 1.49899 44.0125 3.34806 45.9591C5.1766 47.884 6.31187 50.2719 7.03034 52.8512C8.40048 57.7699 8.37509 63.9119 8.34998 69.9856C8.34931 70.1477 8.34864 70.3097 8.348 70.4717C8.32261 76.8663 8.3261 83.334 9.63434 89.23Z"
        fill="#C8CBCE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.254 101.324L123.635 70.0569L139.268 75.747C147.903 78.8898 152.354 88.436 149.211 97.0706C146.069 105.704 136.523 110.157 127.888 107.014L112.254 101.324Z"
        fill="#C8CBCE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.635 70.0569L112.254 101.324L119.8 104.07L131.18 72.8033L123.635 70.0569Z"
        fill="#8E929B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.748 105.778C152.687 101.4 144.827 99.4378 139.823 98.9829L140.242 94.3722C145.848 94.8818 154.423 97.029 162.187 101.843C169.992 106.682 177.104 114.308 179.342 125.747C181.92 138.924 185.603 149.416 190.693 156.777C195.726 164.053 202.097 168.235 210.28 169.027L209.834 173.635C200.076 172.691 192.559 167.614 186.885 159.41C181.269 151.29 177.429 140.08 174.798 126.636C172.87 116.779 166.768 110.13 159.748 105.778Z"
        fill="#C8CBCE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 29.3346L79.2723 28.9454L66.4361 9.20103L83.2543 8.76978L104 40.7244L87.1981 41.1538L96.0523 61.7698L64 29.3346Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.43 165.009L130.018 151.595L142.745 170.77L150 156.001L129.394 124.996L122.145 139.75L107 123.77L123.43 165.009Z"
        fill="currentColor"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.279 84.7944L170.655 82.4106L168 81.2793L170.384 79.654L171.515 77L173.14 79.3844L175.794 80.5151L173.411 82.1397L172.279 84.7944Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.2793 35.7944L33.6547 33.4106L31 32.2793L33.3838 30.654L34.5151 28L36.1397 30.3844L38.7944 31.5151L36.4106 33.1397L35.2793 35.7944Z"
      fill="#E4E4E4"
    />
  </svg>
);

export default ErrorIllustrationDisconnect;
