import { MultiDeviceDeviceFromServer } from 'types';

import { LOCALSTORAGE_KEYS, SUPPORTED_LANGUAGES } from '@constants';

export const multiDeviceGenerateToken = (): string => {
  const s4 = (): string =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const multiDeviceGetToken = (): string => {
  const tokenExisting = window.localStorage.getItem(LOCALSTORAGE_KEYS.MULTI_DEVICE_TOKEN_AKA_MOBILE_TOKEN) || '';
  if (tokenExisting.length > 0) {
    return tokenExisting;
  }

  const tokenNew = multiDeviceGenerateToken();
  window.localStorage.setItem(LOCALSTORAGE_KEYS.MULTI_DEVICE_TOKEN_AKA_MOBILE_TOKEN, tokenNew);

  return tokenNew;
};

export const multiDeviceIsDesktop = (device: MultiDeviceDeviceFromServer): boolean => {
  const { os = '' } = device;
  const deviceOsName = os.toLocaleLowerCase(SUPPORTED_LANGUAGES.EN);

  if (deviceOsName.includes('mac') || deviceOsName.includes('windows') || deviceOsName.includes('ubuntu')) {
    return true;
  }

  return false;
};
