export const isVoicemailFilteredByListened = (
  isOnlyNewVoicemails: boolean,
  listened: string,
  voicemailId: string,
  activeVoicemailId: string,
): boolean => {
  const isVoicemailActive = activeVoicemailId.length > 0 && activeVoicemailId === voicemailId;
  const isVoicemailListened = listened.length === 0;
  return !isOnlyNewVoicemails || isVoicemailActive || isVoicemailListened;
};
