import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { normalizeNumber } from './parsing';

export const formatNumber = (number?: string): string => {
  if (!number) {
    return '';
  }

  const normalNumber = normalizeNumber(number);

  // returns undefined if can't parse the number
  const parsedNumber = parsePhoneNumberFromString(`+${normalNumber}`);

  return parsedNumber?.formatInternational() || number;
};

export const formatNumberAsLocal = formatNumber;
