import { NotificationsBrowserTypes, NotificationsBrowserData } from 'types';

import { ApplicationPaths } from 'routes';

export const isNotificationsBrowserSupported = (): boolean => 'Notification' in window;

export const getNotificationsBrowserTitleTranslationIdByType = ({
  type,
}: Pick<NotificationsBrowserData, 'type'>): string => {
  switch (type) {
    case NotificationsBrowserTypes.CALLS:
      return 'Notifications.Browser.title_calls';

    case NotificationsBrowserTypes.MESSAGES:
      return 'Notifications.Browser.title_messages';

    case NotificationsBrowserTypes.VOICEMAILS:
      return 'Notifications.Browser.title_voicemails';

    default:
      return '';
  }
};

export const getNotificationsBrowserBodyTranslationIdByType = ({
  type,
}: Pick<NotificationsBrowserData, 'type'>): string => {
  switch (type) {
    case NotificationsBrowserTypes.CALLS:
      return 'Notifications.Browser.body_calls';

    case NotificationsBrowserTypes.MESSAGES:
      return 'Notifications.Browser.body_messages';

    case NotificationsBrowserTypes.VOICEMAILS:
      return 'Notifications.Browser.body_voicemails';

    default:
      return '';
  }
};

export const createNotificationsBrowserTitle = ({
  translation,
  contactNameOrNumber,
}: {
  translation: string;
} & Pick<NotificationsBrowserData, 'contactNameOrNumber'>): string => `${translation} ${contactNameOrNumber}`;

export const createNotificationsBrowserBody = ({
  type,
  body,
  translation,
}: Pick<NotificationsBrowserData, 'type' | 'body'> & {
  translation: string;
}): string => {
  switch (type) {
    case NotificationsBrowserTypes.CALLS:
      return translation;

    case NotificationsBrowserTypes.MESSAGES:
      return body;

    case NotificationsBrowserTypes.VOICEMAILS:
      return translation;

    default:
      return body;
  }
};

export const getNotificationsBrowserRedirectionPathByType = ({
  type,
}: Pick<NotificationsBrowserData, 'type'>): ApplicationPaths => {
  switch (type) {
    case NotificationsBrowserTypes.CALLS:
      return ApplicationPaths.CALLS;

    case NotificationsBrowserTypes.MESSAGES:
      return ApplicationPaths.MESSAGES;

    case NotificationsBrowserTypes.VOICEMAILS:
      return ApplicationPaths.VOICEMAILS;

    default:
      return ApplicationPaths.CALLS;
  }
};
