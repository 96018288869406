import { Placement } from '@popperjs/core';
import React, { PropsWithChildren } from 'react';

import { Tooltip, TooltipTheme, TooltipSize } from '@onoff/ui';

interface SIPCallsListItemButtonTooltipProps extends PropsWithChildren {
  content: string;
  placement?: Placement;
}

const SIPCallsListItemButtonTooltip: React.FC<SIPCallsListItemButtonTooltipProps> = ({
  children,
  content,
  placement = 'bottom',
}) => (
  <Tooltip
    content={content}
    theme={TooltipTheme.DARK}
    showArrow={false}
    placement={placement}
    size={TooltipSize.SMALL}
    gutter={5}
  >
    {children}
  </Tooltip>
);

export default SIPCallsListItemButtonTooltip;
