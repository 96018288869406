import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { callsRemoveCallNoteByTransactionIdFromSessionStorage } from 'helpers';

import { useWebAppSelector, selectContactByPhoneNumber } from '@redux';
import { SIPWebRTCOriginators } from '@redux/types';
import { testGetTestingAttributes, testIds } from '@testing';
import { ModalsCallDetails, ModalsCallDetailsDataProps, ModalsCallDetailsDirection } from 'components/Modals';

import { useSIPCallMethodsOngoing } from '../../../../hooks/useSIPCallMethods';
import { sipCallsExtractContactData } from '../../helpers';
import { SIPCallsListItemUICompletedProps } from '../../types';
import SIPCallsListItemInfoContact from '../SIPCallsListItemInfoContact';

import styles from './SIPCallsListItemUICompleted.module.scss';

const SIPCallsListItemUICompleted: React.FC<SIPCallsListItemUICompletedProps> = ({
  data: { ids, statuses, phoneNumbers, timestamps, originator },
  onClickHandlerCallNotesModalCloseAndSaveAsDraft,
}) => {
  const [stateIsModalCallDetailsOpen, setStateIsModalCallDetailsOpen] = useState<boolean>(false);
  const contact = useWebAppSelector(selectContactByPhoneNumber(phoneNumbers.remote));
  const { sipCallDismiss } = useSIPCallMethodsOngoing();

  const { contactDisplayName, callDisplayCompanyName, callDisplayPhoneNumber, callDisplayPhoneNumberType } =
    sipCallsExtractContactData({
      phoneNumberRemote: phoneNumbers.remote,
      contact,
    });

  const modalsCallDetailsData: ModalsCallDetailsDataProps = {
    transactionId: ids.transaction,
    callDurationStart: timestamps.callTerminated,
    callDurationEnd: timestamps.callAnswered,
    callDirection:
      originator === SIPWebRTCOriginators.LOCAL
        ? ModalsCallDetailsDirection.OUTGOING
        : ModalsCallDetailsDirection.INCOMING,
    displayContactName: contactDisplayName,
    displayCompanyName: callDisplayCompanyName,
    displayPhoneNumber: callDisplayPhoneNumber,
    displayPhoneNumberType: callDisplayPhoneNumberType,
  };

  const onClickHandlerCallDetailsModalOpen = (): void => {
    onClickHandlerCallNotesModalCloseAndSaveAsDraft();
    setStateIsModalCallDetailsOpen(true);
  };

  const onClickHandlerCallDetailsModalClose = (): void => {
    setStateIsModalCallDetailsOpen(false);
  };

  const onClickHandlerDismiss = (): void => {
    sipCallDismiss({
      idWebRTC: ids.webRTC,
    });

    callsRemoveCallNoteByTransactionIdFromSessionStorage(ids.transaction);
  };

  return (
    <div
      className={styles.root}
      {...testGetTestingAttributes(testIds.sipCallsListItemUICompletedRoot)}
    >
      <div className={styles.content}>
        <span className={styles.contentLabel}>
          <FormattedMessage id="SIP.Calls.UI.state_call_ended" />
        </span>
        <SIPCallsListItemInfoContact
          statusView={statuses.view}
          idCategoryLocal={ids.categoryLocal}
          phoneNumberRemote={phoneNumbers.remote}
        />
      </div>

      <div className={styles.actions}>
        <button
          type="button"
          className={styles.actionsButton}
          onClick={onClickHandlerCallDetailsModalOpen}
          {...testGetTestingAttributes(testIds.sipCallsListItemUICompletedButtonMore)}
        >
          <FormattedMessage id="SIP.Calls.UI.button_more_info" />
        </button>

        <span className={styles.actionsSeparator} />

        <button
          type="button"
          className={styles.actionsButton}
          onClick={onClickHandlerDismiss}
          {...testGetTestingAttributes(testIds.sipCallsListItemUICompletedButtonDismiss)}
        >
          <FormattedMessage id="SIP.Calls.UI.button_dismiss" />
        </button>
      </div>

      <ModalsCallDetails
        isOpen={stateIsModalCallDetailsOpen}
        onCloseHandler={onClickHandlerCallDetailsModalClose}
        data={modalsCallDetailsData}
        propTestId={testIds.sipCallsListItemUICompletedModalCallOutcomes}
      />
    </div>
  );
};

export default SIPCallsListItemUICompleted;
