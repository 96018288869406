import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { normalizePhoneNumber } from 'helpers';

import { useWebAppSelector, selectCategoriesDataWithDynamicColor, selectContactsPhoneNumberContactMap } from '@redux';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';

import { ModalsRecipientInfoProps } from '../../types';
import ModalsRecipientInfoListItem from '../ModalsRecipientInfoListItem';

import styles from './ModalsRecipientInfo.module.scss';

const ModalsRecipientInfo: React.FC<ModalsRecipientInfoProps> = ({ isOpen, onClose, thread, className = '' }) => {
  const intl = useIntl();

  // TODO: Technical Depth
  const contactsMap = useWebAppSelector(selectContactsPhoneNumberContactMap);
  const categories = useWebAppSelector(selectCategoriesDataWithDynamicColor);

  const { targetPhoneNumbers = [], sourceCategoryId = '' } = thread;

  return (
    <Modal
      isOpen={isOpen}
      className={classNames(styles.root, className)}
      onRequestClose={onClose}
    >
      <ModalHeader
        title={intl.formatMessage({ id: 'ModalRecipientsInfo.modal_title' })}
        onCloseButtonClick={onClose}
      />
      <ModalBody>
        <div className={styles.title}>
          {`${targetPhoneNumbers.length} ${intl.formatMessage({ id: 'ModalRecipientsInfo.list_title' })}`}
        </div>
        <div className={styles.list}>
          {targetPhoneNumbers.map((phone) => (
            <ModalsRecipientInfoListItem
              key={phone}
              phoneNumber={phone}
              contact={contactsMap[normalizePhoneNumber(phone)]}
              category={categories[sourceCategoryId]}
              className={styles.listItem}
            />
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalsRecipientInfo;
