import React from 'react';

import { StyleableComponent } from 'types';

const PhoneForward: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6491 5.64954L18.8591 1.85954C18.5391 1.53954 17.9991 1.75954 17.9991 2.20954V3.99954H14.4991C14.2191 3.99954 13.9991 4.21954 13.9991 4.49954V7.49954C13.9991 7.77954 14.2191 7.99954 14.4991 7.99954H17.9991V9.78954C17.9991 10.2395 18.5391 10.4595 18.8491 10.1395L22.6391 6.34954C22.8391 6.15954 22.8391 5.83954 22.6491 5.64954ZM19.2291 15.2595L16.6891 14.9695C16.0791 14.8995 15.4791 15.1095 15.0491 15.5395L13.2091 17.3795C10.3791 15.9395 8.05906 13.6295 6.61906 10.7895L8.46906 8.93954C8.89906 8.50954 9.10906 7.90954 9.03906 7.29954L8.74906 4.77954C8.62906 3.76954 7.77906 3.00954 6.75906 3.00954H5.02906C3.89906 3.00954 2.95906 3.94954 3.02906 5.07954C3.55906 13.6195 10.3891 20.4395 18.9191 20.9695C20.0491 21.0395 20.9891 20.0995 20.9891 18.9695V17.2395C20.9991 16.2295 20.2391 15.3795 19.2291 15.2595Z"
      fill="currentColor"
    />
  </svg>
);

export default PhoneForward;
