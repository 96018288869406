import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { getSIPBrowserRestriction } from 'helpers';

import { SIP } from '@constants';
import { SIPDataCallStatusesFeatureHold } from '@redux/types';
import { testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { useSIPCallMethodsOngoing } from '../../../../hooks/useSIPCallMethods';
import { SIPCallsListItemButtonFeaturesHoldProps } from '../../types';
import SIPCallsListItemButtonTooltip from '../SIPCallsListItemButtonTooltip';

import styles from './SIPCallsListItemButtonFeaturesHold.module.scss';

const SIPCallsListItemButtonFeaturesHold: React.FC<SIPCallsListItemButtonFeaturesHoldProps> = ({
  isCallOngoing,
  statusFeatureHold,
  idWebRTC,
}) => {
  const intl = useIntl();
  const { sipCallHold, sipCallUnHold } = useSIPCallMethodsOngoing();

  const isBrowserRestricted = getSIPBrowserRestriction({ feature: 'CALL_HOLD' });
  const isFeatureNotAvailable = !SIP.CALL_FEATURES.HOLD_ENABLED || !isCallOngoing || isBrowserRestricted;

  if (isFeatureNotAvailable) {
    return null;
  }

  const isCallHeld = statusFeatureHold === SIPDataCallStatusesFeatureHold.HELD;

  const onClickHandlerButtonHold = (): void => {
    sipCallHold({
      idWebRTC,
    });
  };

  const onClickHandlerButtonUnHold = (): void => {
    sipCallUnHold({
      idWebRTC,
    });
  };

  return (
    <SIPCallsListItemButtonTooltip
      content={intl.formatMessage({
        id: isCallHeld ? 'SIP.Calls.UI.ButtonTooltips.resume' : 'SIP.Calls.UI.ButtonTooltips.hold',
      })}
    >
      <IconButton
        className={classNames(styles.root, { [styles.held]: isCallHeld })}
        onClick={isCallHeld ? onClickHandlerButtonUnHold : onClickHandlerButtonHold}
        iconName={isCallHeld ? IconCommonNames.PHONE_PLAY : IconCommonNames.PHONE_PAUSED}
        iconSize={IconCommonSizes.TINY}
        propTestId={
          isCallHeld ? testIds.sipCallsListItemButtonFeatureUnHoldRoot : testIds.sipCallsListItemButtonFeatureHoldRoot
        }
      />
    </SIPCallsListItemButtonTooltip>
  );
};

export default SIPCallsListItemButtonFeaturesHold;
