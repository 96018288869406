import React from 'react';
import { useIntl } from 'react-intl';

import { ErrorType } from '../../types';
import ErrorCore from '../ErrorCore';

import styles from './ErrorPaymentError.module.scss';

interface ErrorPurchaseErrorProps {
  onClick: () => void;
}

const ErrorPaymentError: React.FC<ErrorPurchaseErrorProps> = ({ onClick }) => {
  const intl = useIntl();

  return (
    <ErrorCore
      title={intl.formatMessage({ id: 'Purchase.Error.title' })}
      description={intl.formatMessage({ id: 'Purchase.Error.description_global' })}
      buttonText={intl.formatMessage({ id: 'Purchase.Error.button_return' })}
      classNameIllustration={styles.illustration}
      type={ErrorType.ERROR_PAYMENT}
      onClick={onClick}
    />
  );
};

export default ErrorPaymentError;
