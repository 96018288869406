import { ApiErrorValue } from 'types';

import { API_ERRORS_LIST } from '@constants';

export interface ApiBaseConfig {
  useAuthorizationToken?: boolean;
}

export enum ApiHTTPResponseCodes {
  SUCCESS = 200,
  EXCEPTION_ERROR = 209,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  REQUEST_FORBIDDEN = 403,
  USER_BLOCKED = 423,
}

export type ApiError = {
  code: ApiErrorValue | string;
  description?: string;
};

export interface ApiWebSiteError {
  status: string;
}

export interface ApiErrorPurchase extends ApiError {
  additionalInfo?: {
    paymentResponseCode?: string;
  };
}

export type QueryParamKey =
  | string
  | number
  | boolean
  | Array<string | number | boolean>
  | Record<string, string | number | boolean>;

export type RequestAborted = { code: typeof API_ERRORS_LIST.REQUEST_ABORTED };
