import { useEffect } from 'react';

import { useWebAppSelector, selectSIPStatuses } from '@redux';
import { SIPStatuses } from '@redux/types';

import { checkSIPAvailability } from '../../../helpers';
import { UseSIPTimeoutProps } from '../../../types';

export const useSIPTimeout = ({ sipDisconnect }: UseSIPTimeoutProps): void => {
  const { statusSIP } = useWebAppSelector(selectSIPStatuses);
  const { isSIPAvailable } = checkSIPAvailability();

  const callbackSIPTimeoutHandler = (): void => {
    if (isSIPAvailable && statusSIP === SIPStatuses.TIMEOUT) {
      sipDisconnect();
    }
  };

  useEffect(callbackSIPTimeoutHandler, [isSIPAvailable, statusSIP, sipDisconnect]);
};
