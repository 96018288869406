import { ActiveIntegration, IntegrationStatusResponseIntegration } from 'types';

export const transformActiveIntegrations = (
  integrationsEnabled: Array<IntegrationStatusResponseIntegration> = [],
): Array<ActiveIntegration> =>
  integrationsEnabled
    .map((integration) => ({
      id: integration.name || '',
      name: integration.name || '',
      titleLabel: integration.titleLabel || '',
      iconUrl: integration.iconUrl,
    }))
    .filter(({ id }) => id.length > 0);
