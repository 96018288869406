import { AppError } from 'types';

import { API_ERRORS } from '@constants';
import { getIntl } from '@intl';

import { isApiError, isApiWebSiteError, isKnownApiErrorAsArray, isKnownApiErrorAsString } from '../../typeCheckers';
import { getErrorDescription } from '../getErrorDescription';

export const getErrorDataFromUnknownError = (error: unknown): AppError => {
  const genericErrorDescription = getIntl().formatMessage({ id: 'Errors.oops' });

  if (isApiError(error)) {
    return {
      errorCode: error.code,
      errorDescription: error.description || genericErrorDescription,
    };
  }

  if (isKnownApiErrorAsString(error)) {
    return {
      errorCode: error,
      errorDescription: getErrorDescription(error),
    };
  }

  if (isKnownApiErrorAsArray(error)) {
    return {
      errorCode: error[0],
      errorDescription: getErrorDescription(error[0]),
    };
  }

  if (isApiWebSiteError(error)) {
    return {
      errorCode: error.status,
    };
  }

  return {
    errorCode: API_ERRORS.HTTP.UNKNOWN_ERROR,
    errorDescription: genericErrorDescription,
  };
};
