import classNames from 'classnames';
import React from 'react';

import styles from './Column.module.scss';

type ColumnProps = {
  children: React.ReactNode;
  heading: string;
  className?: string;
};

export const Column: React.FC<ColumnProps> = ({ heading, children, className }) => (
  <div className={classNames(styles.root, className)}>
    <h2 className={styles.heading}>{heading}</h2>
    {children}
  </div>
);
