import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { InfoFilled, Recording } from '@onoff/icons';

import { Status } from 'types';

import { selectSIPStatuses, sipCancelCallRecordingHandler, useWebAppDispatch, useWebAppSelector } from '@redux';
import { SIPDataCallStatusesCallRecording } from '@redux/types';
import { useUserCapabilities } from 'hooks';

import { SIPCallsListItemNotificationCallRecordingProps } from '../../types';
import { SIPCallsListItemNotification } from '../SIPCallsListItemNotification';
import { SIPCallsListItemNotificationButton } from '../SIPCallsListItemNotificationButton';

export const SIPCallsListItemNotificationCallRecording: React.FC<SIPCallsListItemNotificationCallRecordingProps> = ({
  data,
  isCallOngoing,
}) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { isUserB2B } = useUserCapabilities();
  const { statusCancelCallRecording } = useWebAppSelector(selectSIPStatuses);
  const { ids, statuses } = data;
  const transactionId = ids.transaction;
  const callRecordingStatus = statuses.callRecording;
  const isNotificationAllowed = isUserB2B && isCallOngoing;
  const isLoading = statusCancelCallRecording === Status.LOADING;

  const onCancelClickHandler = () => {
    dispatch(sipCancelCallRecordingHandler({ transactionId }));
  };

  if (isNotificationAllowed && callRecordingStatus === SIPDataCallStatusesCallRecording.ON) {
    return (
      <SIPCallsListItemNotification
        key="SIPCallsListItemNotification_CallRecording_ON"
        icon={
          <Recording
            color="danger"
            size="x-small"
          />
        }
        content={intl.formatMessage({ id: 'SIP.Calls.UI.CallRecording.cancellation_title' })}
        button={
          <SIPCallsListItemNotificationButton
            onClick={onCancelClickHandler}
            isLoading={isLoading}
          >
            <span>
              <FormattedMessage id="SIP.Calls.UI.CallRecording.cancellation_button" />
            </span>
          </SIPCallsListItemNotificationButton>
        }
      />
    );
  }

  if (isNotificationAllowed && callRecordingStatus === SIPDataCallStatusesCallRecording.CANCELLED) {
    return (
      <SIPCallsListItemNotification
        key="SIPCallsListItemNotification_CallRecording_CANCELLED"
        icon={
          <InfoFilled
            color="white"
            size="x-small"
          />
        }
        content={intl.formatMessage({ id: 'SIP.Calls.UI.CallRecording.cancellation_success_title' })}
      />
    );
  }

  return null;
};
