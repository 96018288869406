import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { MULTI_DEVICE } from '@constants';
import { IllustrationCommon, IllustrationCommonNames } from 'components/Illustration';
import { Info } from 'components/Info';
import { ModalBody, ModalHeader } from 'components/Modal';
import { MultiDeviceList, MultiDeviceListType } from 'components/MultiDeviceList';
import { useUserCapabilities } from 'hooks';

import styles from './ModalsMultiDeviceLogoutContent.module.scss';

const ModalsMultiDeviceLogoutContent: React.FC = () => {
  const intl = useIntl();
  const { isUserB2B } = useUserCapabilities();

  return (
    <>
      <ModalHeader title={intl.formatMessage({ id: 'Devices.modal_device_logout_title' })} />
      <ModalBody className={styles.body}>
        <IllustrationCommon
          name={IllustrationCommonNames.DEVICES}
          className={classNames(styles.illustration, {
            [styles.isUserB2B]: isUserB2B,
          })}
        />
        <Info
          className={styles.info}
          classNameIcon={styles.infoIcon}
        >
          <FormattedMessage
            id="Devices.modal_device_logout_body"
            values={{ maxConnectedDevices: MULTI_DEVICE.MAX_CONNECTED_DEVICES }}
          />
        </Info>
        <MultiDeviceList type={MultiDeviceListType.MODAL} />
      </ModalBody>
    </>
  );
};

export default ModalsMultiDeviceLogoutContent;
