import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ModalUniStyle as s1 } from 'components/UI/styleRoutes';

class ModalUniWrap extends Component {
  static defaultProps = {
    customClass: '',
  };

  static propTypes = {
    customClass: PropTypes.string,
  };

  render() {
    return (
      <div className={`${s1.wrapper} ${this.props.customClass}`}>
        { this.props.children}
      </div>
    );
  }
}

export default ModalUniWrap;
