import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { Loading } from '@onoff/ui';

import { LOCALSTORAGE_KEYS } from '@constants';
import {
  selectCallLogsUnseenCount,
  selectThreadsUnseenCount,
  selectVoicemailsByVisibleUnseenCount,
  useWebAppSelector,
} from '@redux';
import { FeatureBlockSplash } from 'components/FeatureBlockSplash';
import { PageBase } from 'components/PageBase';
import { SubHeader, SubHeaderItemLink } from 'components/SubHeader';
import TopPageInfoBanner from 'components/TopPageInfoBanner';
import SideMenuCategories from 'layouts/components/SideMenuCategories';
import { useApplicationPathBlock } from 'layouts/hooks';
import { ApplicationPaths } from 'routes';

import { LayoutProps } from '../../types';
import Header from '../Header';

import styles from './LayoutCommon.module.scss';

const LayoutCommon: React.FC<LayoutProps & PropsWithChildren> = ({ children, isAvailableOnMobile = false }) => {
  const { pathname: currentPath } = useLocation();
  const { isCurrentPathBlocked, featureBlockFeatureType } = useApplicationPathBlock();
  const intl = useIntl();

  const subheaderItemUnseenCalls = useWebAppSelector(selectCallLogsUnseenCount);
  const subheaderItemUnseenMessages = useWebAppSelector(selectThreadsUnseenCount);
  const subheaderItemUnseenVoicemails = useWebAppSelector(selectVoicemailsByVisibleUnseenCount);

  const [isSideMenuMinimized, setIsSideMenuMinimized] = useState<boolean>(false);

  useEffect(() => {
    setIsSideMenuMinimized(localStorage.getItem(LOCALSTORAGE_KEYS.SIDEMENU_CATEGORIES_MINIMIZED) === 'true');
  }, [isSideMenuMinimized]);

  const onClickHandlerToggleSideMenu = (): void => {
    localStorage.setItem(LOCALSTORAGE_KEYS.SIDEMENU_CATEGORIES_MINIMIZED, `${!isSideMenuMinimized}`);
    setIsSideMenuMinimized(!isSideMenuMinimized);
  };

  return (
    <PageBase isAvailableOnMobile={isAvailableOnMobile}>
      <div className={styles.root}>
        <TopPageInfoBanner />
        <Header
          hasMenu
          onClickMenu={onClickHandlerToggleSideMenu}
        />
        <div className={styles.page}>
          <SideMenuCategories isMinimized={isSideMenuMinimized} />
          <div className={styles.wrapper}>
            <SubHeader hasActions>
              <SubHeaderItemLink
                to={ApplicationPaths.CALLS}
                isActive={currentPath === ApplicationPaths.CALLS}
                text={intl.formatMessage({ id: 'SubHeader.Common.calls' })}
                unseenContentCount={subheaderItemUnseenCalls}
              />
              <SubHeaderItemLink
                to={ApplicationPaths.MESSAGES}
                isActive={currentPath === ApplicationPaths.MESSAGES}
                text={intl.formatMessage({ id: 'SubHeader.Common.messages' })}
                unseenContentCount={subheaderItemUnseenMessages}
              />
              <SubHeaderItemLink
                to={ApplicationPaths.VOICEMAILS}
                isActive={currentPath === ApplicationPaths.VOICEMAILS}
                text={intl.formatMessage({ id: 'SubHeader.Common.voicemails' })}
                unseenContentCount={subheaderItemUnseenVoicemails}
              />
              <SubHeaderItemLink
                to={ApplicationPaths.CONTACTS}
                isActive={currentPath === ApplicationPaths.CONTACTS}
                text={intl.formatMessage({ id: 'SubHeader.Common.contacts' })}
              />
            </SubHeader>
            <div className={styles.content}>
              {isCurrentPathBlocked && featureBlockFeatureType ? (
                <FeatureBlockSplash feature={featureBlockFeatureType} />
              ) : (
                <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageBase>
  );
};

export default LayoutCommon;
