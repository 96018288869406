import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { handleApplicationError, isApiError } from 'helpers';

import { useWebAppDispatch } from '@redux';

export const useAppErrorHandlers = (): void => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  useEffect(() => {
    const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
      const { reason } = event;

      if (!isApiError(reason)) {
        const errorMessage = reason instanceof Error ? reason.message : new Error(JSON.stringify(reason));
        Sentry.captureException(errorMessage);
      }

      handleApplicationError({ error: reason });
    };

    const errorHandler = ({ error, message }: ErrorEvent) => {
      Sentry.captureException(error !== null ? error : message);
    };

    window.addEventListener('unhandledrejection', unhandledRejectionHandler);
    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('unhandledrejection', unhandledRejectionHandler);
      window.addEventListener('error', errorHandler);
    };
  }, [dispatch, intl]);
};
