import parsePhoneNumberFromString from 'libphonenumber-js';
import { useState, useEffect } from 'react';

import { UseInputPhoneNumberState, UseInputPhoneNumberOptions } from '../types';

const usePhoneNumberState = (options: UseInputPhoneNumberOptions): UseInputPhoneNumberState => {
  const { value = '', onChange, countryCode = 'FR', ref } = options;

  const defaultValue = parsePhoneNumberFromString(value, countryCode)?.number.toString() ?? '';

  const [inputValue, setInputValue] = useState(value);
  const [currentCountryCode, setCurrentCountryCode] = useState(countryCode);
  const [hasFocus, setHasFocus] = useState(false);
  const [isEmpty, setIsEmpty] = useState(value.length === 0);
  const [hasHover, setHasHover] = useState(false);

  const { current } = ref;

  useEffect(() => {
    if (countryCode !== currentCountryCode) {
      setCurrentCountryCode(countryCode);
    }

    if (current !== null && onChange !== undefined) {
      current.value = '';
      setInputValue('');
      onChange('');
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [countryCode]);

  return {
    hasHover,
    hasFocus,
    isEmpty,
    defaultValue,
    currentCountryCode,
    onChangeHandler: (newValue) => {
      if (!/^\+?(\d|\s){0,16}$/.test(newValue) && current !== null) {
        current.value = inputValue;
        return;
      }

      const currentCountry = parsePhoneNumberFromString(newValue)?.country || countryCode;
      if (currentCountry !== currentCountryCode) {
        setCurrentCountryCode(currentCountry);
      }
      const parsedValue = parsePhoneNumberFromString(newValue, currentCountry)?.number.toString() || '';
      setInputValue(parsedValue);
      setIsEmpty(parsedValue.length === 0);
      if (onChange !== undefined && parsedValue !== inputValue) {
        onChange(parsedValue);
      }
    },
    onBlur: () => setHasFocus(false),
    onFocus: () => setHasFocus(true),
    onMouseOver: () => setHasHover(true),
    onMouseLeave: () => setHasHover(false),
  };
};

export default usePhoneNumberState;
