import { createSelector } from '@reduxjs/toolkit';

import { MessagesStatuses } from 'types';

import { isString } from 'helpers';

import {
  SelectActiveThreadMessageList,
  SelectMessages,
  SelectMessagesSendMessageErrorList,
  SelectMessagesSendMessageLastMessageSent,
  SelectMessagesState,
  SelectMessagesMessageOffsets,
  SelectMessagesMessageOffsetForThread,
  SelectMessagesStatuses,
  SelectMessagesSendMessageState,
  SelectMessagesDeleteThreadStatus,
} from '../../types';
import { selectThreadsActiveId } from '../threads';

export const selectMessagesState: SelectMessagesState = (state) => state.messages;

export const selectMessages: SelectMessages = (state) => state.messages.data;

export const selectMessagesSendMessageState: SelectMessagesSendMessageState = createSelector(
  [selectMessagesState],
  (messagesState) => messagesState.sendMessage,
);
export const selectMessagesSendLastMessageSent: SelectMessagesSendMessageLastMessageSent = createSelector(
  [selectMessagesSendMessageState],
  (sendMessageState) => sendMessageState.lastMessageSent,
);

export const selectMessagesSendMessageErrorList: SelectMessagesSendMessageErrorList = createSelector(
  [selectMessagesSendMessageState],
  (sendMessageState) => sendMessageState.errorList,
);

export const selectMessagesActiveThreadMessageList: SelectActiveThreadMessageList = createSelector(
  [selectMessages, selectThreadsActiveId],
  (messages, activeThreadId) => messages[activeThreadId] || [],
);

export const selectMessagesMessageOffsets: SelectMessagesMessageOffsets = createSelector(
  [selectMessagesState],
  (messagesState) => messagesState.messageOffsets,
);

export const selectMessagesStatuses: SelectMessagesStatuses = createSelector(
  [selectMessagesState],
  (messagesState) => messagesState.statuses,
);

export const selectMessagesMessageOffsetForThread: SelectMessagesMessageOffsetForThread = (
  threadId: string | boolean,
) =>
  createSelector([selectMessagesMessageOffsets], (messagesOffset) => {
    if (isString(threadId)) {
      return messagesOffset[threadId];
    }
    return undefined;
  });

export const selectMessagesDeleteThreadStatus: SelectMessagesDeleteThreadStatus = createSelector(
  [selectMessagesStatuses],
  (messagesStatuses: MessagesStatuses) => messagesStatuses.deleteThreadMessages,
);
