import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getUserAgentDataBrowserName } from 'helpers';

import { LOCALSTORAGE_KEYS } from '@constants';
import Banner from 'components/Banner';
import { useUserCapabilities } from 'hooks';

import styles from './InfoBannerBrowserRecommendation.module.scss';

const InfoBannerBrowserRecommendation: React.FC = () => {
  const isDismissedByUserInitialValue =
    window.localStorage.getItem(LOCALSTORAGE_KEYS.INFO_BAR_BROWSER_RECOMMENDATION_DISMISSED) === 'true';
  const [isDismissedByUser, setIsDismissedByUser] = useState(isDismissedByUserInitialValue);
  const { isUserB2B } = useUserCapabilities();
  const browserName = getUserAgentDataBrowserName().toLocaleLowerCase();

  const onClickHandlerButtonDismiss = () => {
    window.localStorage.setItem(LOCALSTORAGE_KEYS.INFO_BAR_BROWSER_RECOMMENDATION_DISMISSED, 'true');
    setIsDismissedByUser(true);
  };

  if (browserName !== 'firefox' || isDismissedByUser) {
    return null;
  }

  return (
    <Banner colorScheme={isUserB2B ? 'brand-b2b' : 'brand-b2c'}>
      <div>
        <FormattedMessage
          id="Layouts.InfoBar.browser_recommendation"
          values={{
            buttonText: (
              <button
                className={styles.link}
                onClick={onClickHandlerButtonDismiss}
              >
                <FormattedMessage id="Layouts.InfoBar.browser_recommendation_button_text" />
              </button>
            ),
          }}
        />
      </div>
    </Banner>
  );
};

export default InfoBannerBrowserRecommendation;
