import classNames from 'classnames';
import React from 'react';

import { StyleableComponent } from 'types';

import {
  CallNotes,
  CallsOverInternet,
  MultiDeviceSupport,
  MultiLangVoicemail,
  OnOffCalls,
  OnOffScheduling,
  PrivateMode,
  SmsScheduling,
  VoicemailTranscription,
  WebAccess,
} from '../../assets';
import { ModalsPremiumPlansFeatureIconType } from '../../enums';

import styles from './ModalsPremiumPlanFeatureIcon.module.scss';

interface ModalsPremiumPlanFeatureIconProps extends StyleableComponent {
  iconType: ModalsPremiumPlansFeatureIconType;
}

const ModalsPremiumPlanFeatureIcon: React.FC<ModalsPremiumPlanFeatureIconProps> = ({ iconType, className }) => {
  const props = {
    className: classNames(styles.root, className),
  };

  switch (iconType) {
    case ModalsPremiumPlansFeatureIconType.SMS_SCHEDULING:
      return <SmsScheduling {...props} />;

    case ModalsPremiumPlansFeatureIconType.CALLS_OVER_INTERNET:
      return <CallsOverInternet {...props} />;

    case ModalsPremiumPlansFeatureIconType.VOICEMAIL_TRANSCRIPTION:
      return <VoicemailTranscription {...props} />;

    case ModalsPremiumPlansFeatureIconType.PRIVATE_MODE:
      return <PrivateMode {...props} />;

    case ModalsPremiumPlansFeatureIconType.MULTI_LANG_VOICEMAIL:
      return <MultiLangVoicemail {...props} />;

    case ModalsPremiumPlansFeatureIconType.MULTI_DEVICE_SUPPORT:
      return <MultiDeviceSupport {...props} />;

    case ModalsPremiumPlansFeatureIconType.ON_OFF_CALLS:
      return <OnOffCalls {...props} />;

    case ModalsPremiumPlansFeatureIconType.ON_OFF_SCHEDULING:
      return <OnOffScheduling {...props} />;

    case ModalsPremiumPlansFeatureIconType.WEB_ACCESS:
      return <WebAccess {...props} />;

    case ModalsPremiumPlansFeatureIconType.CALL_NOTES:
      return <CallNotes {...props} />;

    default:
      return <></>;
  }
};

export default ModalsPremiumPlanFeatureIcon;
