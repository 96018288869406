import { useCallback, useEffect } from 'react';

import { Status } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  sipSetStatusSIP,
  sipFilterDataCallsBySIPDisconnectionHandler,
  selectSIPStatuses,
  selectSIPDataCalls,
  sipSetStatusCallInitialization,
} from '@redux';
import { SIPDataCallStatusesWebRTC, SIPStatuses } from '@redux/types';

import { checkSIPAvailability } from '../../../helpers';
import { UseSIPDisconnectProps, UseSIPDisconnectReturn } from '../../../types';

export const useSIPDisconnect = ({ sipStop }: UseSIPDisconnectProps): UseSIPDisconnectReturn => {
  const dispatch = useWebAppDispatch();
  const { statusSIP } = useWebAppSelector(selectSIPStatuses);
  const { dataSIPCalls } = useWebAppSelector(selectSIPDataCalls);
  const { isSIPAvailable } = checkSIPAvailability();

  const sipDisconnect = useCallback(() => {
    // BY TERMINATIONS: begin
    const statusesSIPForDisconnectByTerminations = [SIPStatuses.REGISTERED];
    const statusesWebRTCForDisconnectByTerminations = [
      SIPDataCallStatusesWebRTC.DEFAULT,
      SIPDataCallStatusesWebRTC.BUSY,
      SIPDataCallStatusesWebRTC.FAILED,
      SIPDataCallStatusesWebRTC.REJECTED,
      SIPDataCallStatusesWebRTC.TERMINATED,
    ];

    const canDisconnectBySIPTerminations = statusesSIPForDisconnectByTerminations.includes(statusSIP);
    const canDisconnectByWebRTCTerminations = dataSIPCalls.every(({ statuses: { webRTC } }) =>
      statusesWebRTCForDisconnectByTerminations.includes(webRTC),
    );
    const canDisconnectByTerminations = canDisconnectBySIPTerminations && canDisconnectByWebRTCTerminations;
    // BY TERMINATIONS: end

    // BY TIMEOUT AND FAILURE: begin
    const statusesSIPForDisconnectByTimeoutAndFailure = [
      SIPStatuses.TIMEOUT,
      SIPStatuses.FAILED,
      SIPStatuses.FAILED_ALL,
    ];
    const statusesWebRTCForDisconnectByTimeoutAndFailure = [
      ...statusesWebRTCForDisconnectByTerminations,
      SIPDataCallStatusesWebRTC.PUSH_INCOMING,
      SIPDataCallStatusesWebRTC.PUSH_OUTGOING,
    ];
    const canDisconnectBySIPTimeoutAndFailure = statusesSIPForDisconnectByTimeoutAndFailure.includes(statusSIP);
    const canDisconnectByWebRTCTimeoutAndFailure = dataSIPCalls.every(({ statuses: { webRTC } }) =>
      statusesWebRTCForDisconnectByTimeoutAndFailure.includes(webRTC),
    );
    const canSIPDisconnectByTimeoutAndFailure =
      canDisconnectBySIPTimeoutAndFailure && canDisconnectByWebRTCTimeoutAndFailure;
    // BY TIMEOUT AND FAILURE: end

    const canSIPDisconnect = canDisconnectByTerminations || canSIPDisconnectByTimeoutAndFailure;
    if (canSIPDisconnect) {
      dispatch(sipSetStatusSIP(SIPStatuses.DISCONNECTING));

      dispatch(sipFilterDataCallsBySIPDisconnectionHandler());

      dispatch(sipSetStatusCallInitialization(Status.IDLE));

      sipStop();
    }
  }, [statusSIP, dataSIPCalls, sipStop, dispatch]);

  const callbackSIPDisconnectHandler = (): void => {
    if (isSIPAvailable) {
      sipDisconnect();
    }
  };

  useEffect(callbackSIPDisconnectHandler, [isSIPAvailable, sipDisconnect]);

  return {
    sipDisconnect,
  };
};
