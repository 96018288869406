import classNames from 'classnames';
import React from 'react';

import { useWebAppSelector, selectCategoryById, selectContactByPhoneNumber } from '@redux';
import { SIPDataCallStatusesView } from '@redux/types';
import { testGetTestingAttributes, testIds } from '@testing';
import { AvatarCommon, AvatarSize } from 'components/Avatar';

import { sipCallsExtractContactData } from '../../helpers';
import { SIPCallsListItemInfoContactProps } from '../../types';

import styles from './SIPCallsListItemInfoContact.module.scss';

const SIPCallsListItemInfoContact: React.FC<SIPCallsListItemInfoContactProps> = ({
  statusView,
  idCategoryLocal,
  phoneNumberRemote,
  isCallOngoing = false,
}) => {
  const contact = useWebAppSelector(selectContactByPhoneNumber(phoneNumberRemote));
  const { color: categoryColor } = useWebAppSelector(selectCategoryById(idCategoryLocal)) || {};
  const {
    contactDisplayName,
    contactImageUrl,
    isContactFavorited,
    isContactBlocked,
    callDisplayPhoneNumber,
    callDisplayNameOrPhoneNumber,
    callDisplayPhoneNumberType,
    callDisplayCompanyName,
  } = sipCallsExtractContactData({ phoneNumberRemote, contact });

  const isUIDefault = statusView === SIPDataCallStatusesView.DEFAULT;
  const isUIMinimized = statusView === SIPDataCallStatusesView.MINIMIZED;
  const isUICompleted = statusView === SIPDataCallStatusesView.COMPLETED;
  const isUIHasAvatar = isUIDefault || (isUIMinimized && !isCallOngoing);

  return (
    <div
      className={classNames({
        [styles.default]: isUIDefault,
        [styles.minimized]: isUIMinimized,
        [styles.completed]: isUICompleted,
      })}
      {...testGetTestingAttributes(testIds.sipCallsListItemInfoContactRoot)}
    >
      {isUIHasAvatar ? (
        <div className={styles.avatar}>
          <AvatarCommon
            size={AvatarSize.SMALL}
            fullName={contactDisplayName}
            imageSrc={contactImageUrl}
            backgroundColor={categoryColor}
            hasStarIcon={isContactFavorited}
            hasBlockedIcon={isContactBlocked}
          />
        </div>
      ) : null}
      {isUIDefault && contactDisplayName.length > 0 ? (
        <div className={styles.contact}>
          <span className={classNames(styles.elipsis, styles.text)}>
            {`${contactDisplayName} ${callDisplayCompanyName}`}
          </span>
          <span className={classNames(styles.elipsis, styles.phone)}>
            {`${callDisplayPhoneNumber} ${callDisplayPhoneNumberType}`}
          </span>
        </div>
      ) : (
        <div className={classNames(styles.elipsis, styles.text)}>
          {`${callDisplayNameOrPhoneNumber} ${callDisplayCompanyName}`}
        </div>
      )}
    </div>
  );
};

export default SIPCallsListItemInfoContact;
