import React from 'react';

import { StyleableComponent } from 'types';

const FileUpload: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4 9.99973H8.99V14.9997C8.99 15.5497 9.44 15.9997 9.99 15.9997H13.99C14.54 15.9997 14.99 15.5497 14.99 14.9997V9.99973H16.58C17.47 9.99973 17.92 8.91973 17.29 8.28973L12.7 3.69973C12.31 3.30973 11.68 3.30973 11.29 3.69973L6.7 8.28973C6.07 8.91973 6.51 9.99973 7.4 9.99973ZM5 18.9997C5 19.5497 5.45 19.9997 6 19.9997H18C18.55 19.9997 19 19.5497 19 18.9997C19 18.4497 18.55 17.9997 18 17.9997H6C5.45 17.9997 5 18.4497 5 18.9997Z"
      fill="currentColor"
    />
  </svg>
);

export default FileUpload;
