import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, CategoriesReduxState, CategoryActionTypes } from '../../types';

export const categoriesInitialState: CategoriesReduxState = {
  data: {},
  isLoading: true,
  statistics: [],
  statuses: {
    unsubscribeNumber: Status.IDLE,
    fetchStatistics: Status.IDLE,
  },
};

export const categoriesReducer: Reducer<CategoriesReduxState, CategoryActionTypes> = (state, action) => {
  const reducerState: CategoriesReduxState = state || categoriesInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.ADD_CATEGORY: {
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          [action.category.id]: action.category,
        },
      };
    }

    case REDUX_ACTION_TYPES.CATEGORY_IS_LOADING: {
      const { isLoading } = action;
      return { ...reducerState, isLoading };
    }

    case REDUX_ACTION_TYPES.REMOVE_CATEGORY: {
      const newCategoriesData = Object.keys(reducerState.data).reduce((acc, categoryId) => {
        if (categoryId !== action.categoryId) {
          acc[categoryId] = reducerState.data[categoryId];
        }
        return acc;
      }, {});

      return {
        ...reducerState,
        data: newCategoriesData,
      };
    }

    case REDUX_ACTION_TYPES.SET_ALL_CATEGORIES: {
      const newCategoriesData = action.categories.reduce((acc, category) => {
        acc[category.id] = category;
        return acc;
      }, {});

      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          ...newCategoriesData,
        },
      };
    }

    case REDUX_ACTION_TYPES.UPDATE_CATEGORY: {
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          [action.categoryId]: {
            ...reducerState.data[action.categoryId],
            ...action.data,
          },
        },
      };
    }

    case REDUX_ACTION_TYPES.CATEGORIES_SET_STATUS_CANCEL_SUBSCRIPTION: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          unsubscribeNumber: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.CATEGORIES_SET_STATUS_FETCH_STATISTICS: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchStatistics: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.CATEGORIES_SET_STATISTICS_DATA: {
      return {
        ...reducerState,
        statistics: action.data,
      };
    }

    case REDUX_ACTION_TYPES.CATEGORIES_STATISTICS_UPDATE_NEXT_PLAN_DURATION: {
      const updatedStatistics = reducerState.statistics.map((category) => {
        if (category.categoryId === action.categoryId) {
          return { ...category, nextPlanDuration: action.nextPlanDuration };
        }

        return category;
      });

      return {
        ...reducerState,
        statistics: updatedStatistics,
      };
    }

    case REDUX_ACTION_TYPES.CATEGORIES_STATISTICS_REMOVE_NEXT_PLAN_DURATION: {
      const updatedStatistics = reducerState.statistics.map((category) => {
        if (category.categoryId === action.categoryId) {
          const { nextPlanDuration: _propertyToRemove, ...updatedCategory } = category;

          return updatedCategory;
        }

        return category;
      });

      return {
        ...reducerState,
        statistics: updatedStatistics,
      };
    }

    default:
      return reducerState;
  }
};
