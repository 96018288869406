import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { v4 as uuidv4 } from 'uuid';

import { Contact, Category } from 'types';

import { getContactByPhoneNumber, getContactFullName, isNumberEqual } from 'helpers';

import { SelectedPhoneNumber, SelectedPhoneNumberWithId } from '../types';

export const getSelectedPhoneNumber = (
  input: string,
  contactId = '',
  contactList: Contact[] = [],
  categoryList: Category[] = [],
  countryIsoCode: CountryCode = 'FR',
): SelectedPhoneNumber => {
  const parsedPhoneNumberObject = parsePhoneNumberFromString(input, countryIsoCode);
  const phoneNumber = parsedPhoneNumberObject?.formatInternational();
  const isValid = !!parsedPhoneNumberObject?.isValid();

  const contact = contactId
    ? contactList.find(({ id }) => contactId === id)
    : getContactByPhoneNumber(input, contactList, countryIsoCode);

  if (contact) {
    const { phones, categoryId } = contact;

    const contactPhone = phones?.find((phone) =>
      isNumberEqual(input, phone.normalNumber || phone.number, countryIsoCode),
    );

    const category = categoryList.find((categoryFromList) => categoryId === categoryFromList.id);

    const contactPhoneSuffix = contactPhone?.type ? ` (${contactPhone.type})` : '';
    const contactDisplayNameOrPhoneNumber = getContactFullName(contact) || phoneNumber;
    const label = `${contactDisplayNameOrPhoneNumber}${contactPhoneSuffix}`;

    return {
      category,
      contact,
      phoneNumber,
      label,
      isValid,
    };
  }

  const label = parsedPhoneNumberObject?.isValid() && phoneNumber ? phoneNumber : input;

  return {
    phoneNumber,
    label,
    isValid,
  };
};

export const createSelectedPhoneNumberWithId = (phone: SelectedPhoneNumber): SelectedPhoneNumberWithId => ({
  id: uuidv4(),
  ...phone,
});

export const getPhoneNumberList = (phoneList: SelectedPhoneNumberWithId[]): string[] =>
  phoneList.reduce<string[]>((result, { phoneNumber = '' }) => {
    if (phoneNumber.length > 0) {
      result.push(phoneNumber);
    }
    return result;
  }, []);
