import { CountryCode } from 'libphonenumber-js';
import React, { ChangeEvent, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Popover } from '@onoff/ui';

import { Contact, SimpleContactPhone } from 'types';

import {
  filterContactsBySearchString,
  getContactFullName,
  getContactListPhoneNumberCards,
  isNumberEqual,
} from 'helpers';

import {
  useWebAppSelector,
  selectCallLogsMostRecentContact,
  selectCategoriesWithDynamicColor,
  selectSimpleContacts,
} from '@redux';
import { DropdownPhoneNumber } from 'components/Dropdowns';
import PhoneNumberInput from 'components/PhoneNumberInput';

import styles from './ModalsDialpadSelectPhoneNumberInput.module.scss';

interface ModalsDialpadSelectPhoneNumberInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  country: CountryCode;
  onChange: (value: string) => void;
  onSelectContactPhone: (contact: Contact, phone: SimpleContactPhone) => unknown;
  value?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  currentContactName: string;
  setCurrentContactName: React.Dispatch<React.SetStateAction<string>>;
}

const ModalsDialpadSelectPhoneNumberInput = forwardRef<HTMLInputElement, ModalsDialpadSelectPhoneNumberInputProps>(
  (
    {
      country,
      onChange,
      onSelectContactPhone,
      placeholder = '',
      value = '',
      isDisabled = false,
      hasError = false,
      currentContactName,
      setCurrentContactName,
    },
    ref,
  ) => {
    const intl = useIntl();

    const contactList = useWebAppSelector(selectSimpleContacts);
    const categoryList = useWebAppSelector(selectCategoriesWithDynamicColor);
    const contactMostRecentList = useWebAppSelector(selectCallLogsMostRecentContact);

    const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);

    const refInput = useRef<HTMLInputElement>(null);

    const displayedContactList =
      value.length > 0 ? filterContactsBySearchString(contactList, value) : contactMostRecentList;
    const dropdownItems = getContactListPhoneNumberCards(displayedContactList, categoryList);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => refInput.current);

    const onChangeHandlerInput = (e: ChangeEvent<HTMLInputElement>) => {
      setCurrentContactName('');
      onChange(e.target.value);
    };

    const onFocusHandlerInput = (): void => {
      setIsDropDownOpen(true);
    };

    const onBlurHandlerInput = (): void => {
      setIsDropDownOpen(false);
    };

    const onClickHandlerButtonBackspace = useCallback((): void => {
      const newValue = currentContactName ? '' : value.substring(0, value.length - 1);
      setCurrentContactName('');
      onChange(newValue);
      refInput.current?.focus();
    }, [currentContactName, value, setCurrentContactName, onChange]);

    const onClickHandlerSelectItem = (phoneNumber: string, contactId: string): void => {
      const contact = contactList.find(({ id }) => id === contactId);
      const contactPhoneNumber = contact?.phones?.find(({ number }) => isNumberEqual(number, phoneNumber));

      if (contact !== undefined) {
        setCurrentContactName(getContactFullName(contact));

        if (contactPhoneNumber !== undefined) {
          onSelectContactPhone(contact, contactPhoneNumber);
        }
      }

      refInput.current?.focus();
      if (refInput.current?.selectionStart) {
        refInput.current.selectionStart = value.length;
      }
    };

    const isPhoneNumberDropdownOpen =
      document.activeElement === refInput.current &&
      isDropDownOpen &&
      !currentContactName &&
      (value.length > 0 || dropdownItems.length > 0);

    const selectedContact = currentContactName.length > 0 ? currentContactName : '';

    return (
      <div className={styles.root}>
        <Popover
          placement="bottom-start"
          isOpen={isPhoneNumberDropdownOpen}
          onClickedOutside={() => setIsDropDownOpen(false)}
        >
          <PhoneNumberInput
            ref={refInput}
            onChange={onChangeHandlerInput}
            onFocus={onFocusHandlerInput}
            onBlur={onBlurHandlerInput}
            placeholder={placeholder}
            disabled={isDisabled}
            autoFocus
            countryCode={country}
            value={value}
            onBackspaceClick={onClickHandlerButtonBackspace}
            contactName={selectedContact}
            hasError={hasError}
          />
          <Popover.Content>
            <DropdownPhoneNumber
              onClickItem={onClickHandlerSelectItem}
              optionList={dropdownItems}
              title={
                value.length === 0
                  ? intl.formatMessage({ id: 'DialPad.most_recent_contacts' })
                  : intl.formatMessage({ id: 'DialPad.search_results' })
              }
            />
          </Popover.Content>
        </Popover>
      </div>
    );
  },
);

export default ModalsDialpadSelectPhoneNumberInput;
