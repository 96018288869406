import { createSelector } from '@reduxjs/toolkit';

import { SelectNotificationsBrowserState } from '../../types';

export const selectNotificationsBrowserState: SelectNotificationsBrowserState = (state) => state.notificationsBrowser;

export const selectNotificationsBrowserPermission = createSelector(
  [selectNotificationsBrowserState],
  (notificationsBrowser) => notificationsBrowser.permission,
);

export const selectNotificationsBrowserDataList = createSelector(
  [selectNotificationsBrowserState],
  (notificationsBrowser) => notificationsBrowser.dataList,
);
