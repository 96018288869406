import { useEffect } from 'react';

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  nonTargetElements: (T | null)[],
  callbackEventHandler: (event: MouseEvent | TouchEvent) => void,
  callbackCanBeCalled?: boolean,
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const isOutside = nonTargetElements.every(
        (element) => element && !element.contains((event?.target as Node) || null),
      );
      if (isOutside && callbackCanBeCalled !== false) {
        callbackEventHandler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [nonTargetElements, callbackEventHandler, callbackCanBeCalled]);
};
