import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './ModalsCallQualitySurveyHeader.module.scss';

const ModalsCallQualitySurveyHeader: React.FC = () => (
  <div>
    <h1 className={styles.title}>
      <FormattedMessage id="ModalCallQualitySurvey.Header.title" />
    </h1>
    <p className={styles.subTitle}>
      <FormattedMessage id="ModalCallQualitySurvey.Header.subtitle" />
    </p>
  </div>
);

export default ModalsCallQualitySurveyHeader;
