type ServiceContact = {
  PHONE_NUMBERS: string[];
  DISPLAY_NAME: string;
};

export const SERVICE_CONTACTS = {
  SERVICE: {
    /**
     * 33780909090 => DEV/STAGING
     * 33644666666 => PROD
     */
    PHONE_NUMBERS: ['33780909090', '33644666666'],
    DISPLAY_NAME: 'Onoff Service',
  } as ServiceContact,
  ALEXIA: {
    PHONE_NUMBERS: ['33744311130'],
    DISPLAY_NAME: 'Alexia - Onoff',
  } as ServiceContact,
  LAURA: {
    PHONE_NUMBERS: ['33744319192'],
    DISPLAY_NAME: 'Laura - Onoff Business',
  } as ServiceContact,
} as const;
