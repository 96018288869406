import { LOCALSTORAGE_KEYS } from '@constants';
import { ApplicationPaths } from 'routes';

export const requestMediaAccess = async ({
  audio = true,
  video = false,
}: MediaStreamConstraints = {}): Promise<void> => {
  if (!('navigator' in window)) {
    throw new Error('Dev Error: User Media Not Supported!');
  }

  const mediaStream = await window.navigator.mediaDevices.getUserMedia({ audio, video });
  if (!mediaStream) {
    throw new Error('Dev Error: User Media Permission Denied!');
  }

  const mediaTracks = mediaStream.getTracks();
  if (mediaTracks.length > 0) {
    mediaTracks.forEach((mediaTrack: MediaStreamTrack) => {
      mediaTrack.stop();
    });
  }
};

export const handleUnauthorized = (): void => {
  // All Session Storage
  window.sessionStorage.clear();

  // USER
  window.localStorage.removeItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN);
  window.localStorage.removeItem(LOCALSTORAGE_KEYS.AUTH_KEY);
  window.localStorage.removeItem(LOCALSTORAGE_KEYS.USER_DEVICE_ID);

  // BROWSER TABS
  window.localStorage.removeItem(LOCALSTORAGE_KEYS.BROWSER_TABS_ACTIVE_ID);

  // SIP/VoIP
  window.localStorage.removeItem(LOCALSTORAGE_KEYS.SIP_INSTANCE_ID);

  window.location.replace(ApplicationPaths.ROOT);
};

export const alternateValues = <T>(firstValue: T, secondValue: T, currentValue: T): T =>
  currentValue === firstValue ? secondValue : firstValue;
