import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { useIntl } from 'react-intl';

export const useSideBarListItemDate = (callTime: string): string => {
  const intl = useIntl();
  dayjs.extend(isToday);
  dayjs.extend(isYesterday);

  const date = dayjs(new Date(callTime));

  if (date.isToday()) {
    return date.format('HH:mm');
  }

  if (date.isYesterday()) {
    return intl.formatMessage({ id: 'DateTime.yesterday' });
  }

  return date.format('DD/MM/YY');
};
