import { useState } from 'react';

import {
  useWebAppSelector,
  selectCategoriesByCanSendMessages,
  selectSimpleContacts,
  selectUserCountryIsoCode,
} from '@redux';

import { getSelectedPhoneNumber, createSelectedPhoneNumberWithId } from '../helpers';
import { SelectedPhoneNumberWithId, ModalsNewThreadPhoneInput } from '../types';

type UseSelectPhoneNumberSetter = {
  (phoneList: SelectedPhoneNumberWithId[]): void;
};

type UseSelectPhoneNumber = {
  (phoneList: ModalsNewThreadPhoneInput[]): [SelectedPhoneNumberWithId[], UseSelectPhoneNumberSetter];
};

export const useSelectedPhoneNumber: UseSelectPhoneNumber = (phoneList) => {
  const contactList = useWebAppSelector(selectSimpleContacts);
  const categoryList = useWebAppSelector(selectCategoriesByCanSendMessages);
  const countryIsoCode = useWebAppSelector(selectUserCountryIsoCode);

  const defaultList = phoneList.map(({ phoneNumber, contactId }) =>
    createSelectedPhoneNumberWithId(
      getSelectedPhoneNumber(phoneNumber, contactId, contactList, categoryList, countryIsoCode),
    ),
  );

  const [selectedPhoneNumberWithIdList, setSelectedPhoneNumberWithIdList] =
    useState<SelectedPhoneNumberWithId[]>(defaultList);

  return [selectedPhoneNumberWithIdList, setSelectedPhoneNumberWithIdList];
};
