import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { getCurrentTimeWithMilliseconds } from 'helpers';

import { REDUX_ACTION_TYPES, MessagesReduxState, MessagesActionTypes } from '../../types';

export const messagesInitialState: MessagesReduxState = {
  data: {
    /**
     * [threadId]: Message[]
     */
  },
  sendMessage: {
    errorList: [],
  },
  messageOffsets: {
    /**
     * [threadId]: [messageOffsetId] | true
     * if true: all messages have been loaded for thread
     */
  },
  statuses: {
    fetchThreadMessages: Status.IDLE,
    sendMessage: Status.IDLE,
    deleteThreadMessages: Status.IDLE,
    pubNubMessageAdd: Status.IDLE,
  },
};

export const messagesReducer: Reducer<MessagesReduxState, MessagesActionTypes> = (state, action) => {
  const reducerState: MessagesReduxState = state || messagesInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.MESSAGES_ADD_THREAD: {
      const { message } = action;
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          [message.threadId]: [message],
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_APPEND: {
      const { threadId, messages } = action;
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          [threadId]: [...messages, ...(reducerState.data[threadId] || [])],
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_MARK_ALL_AS_READ: {
      const { threadId } = action;
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          [threadId]: (reducerState.data[threadId] || []).map((message) => ({
            ...message,
            seenAt: getCurrentTimeWithMilliseconds(),
          })),
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_OFFSET: {
      const { threadId, messageId } = action;
      return {
        ...reducerState,
        messageOffsets: {
          ...reducerState.messageOffsets,
          [threadId]: messageId,
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_PREPEND: {
      const { threadId, messages } = action;
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          [threadId]: [...(reducerState.data[threadId] || []), ...messages],
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_REMOVE: {
      const { messageIdList, threadId } = action;
      const filteredMessages = reducerState.data[threadId].filter((message) => !messageIdList.includes(message.id));

      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          [threadId]: filteredMessages,
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_REMOVE_THREAD: {
      const { threadIdList } = action;
      return {
        ...reducerState,
        data: Object.keys(reducerState.data).reduce((data, threadId) => {
          if (threadIdList.includes(threadId)) {
            return data;
          }
          return {
            ...data,
            [threadId]: reducerState.data[threadId],
          };
        }, {}),
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_SEND_MESSAGE_ADD_ERRORS: {
      const { errorList } = action;
      return {
        ...reducerState,
        sendMessage: {
          ...reducerState.sendMessage,
          errorList: [...reducerState.sendMessage.errorList, ...errorList],
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_SEND_MESSAGE_CLEAR_ERROR_LIST: {
      return {
        ...reducerState,
        sendMessage: {
          ...reducerState.sendMessage,
          errorList: [],
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_SEND_MESSAGE_SET_LAST_MESSAGE_SENT: {
      const { lastMessageSent } = action;
      return {
        ...reducerState,
        sendMessage: {
          ...reducerState.sendMessage,
          lastMessageSent,
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_SET_STATUS_SEND_MESSAGE_UPDATE: {
      const { status } = action;
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          sendMessage: status,
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_SET_THREAD: {
      const { threadId, messages } = action;
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          [threadId]: messages,
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_SET_STATUS_THREAD_MESSAGE_UPDATE: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchThreadMessages: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_SET_STATUS_DELETE_THREAD: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          deleteThreadMessages: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.MESSAGES_SET_STATUS_PUBNUB_MESSAGE_ADD: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          pubNubMessageAdd: action.status,
        },
      };
    }

    default: {
      return reducerState;
    }
  }
};
