import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { StyleableComponent } from 'types';

import styles from './ModalsNewThreadNotification.module.scss';

export enum ModalsNewThreadNotificationVariant {
  WARNING = 'warning',
  ERROR = 'error',
}

interface ModalsNewThreadNotificationProps extends StyleableComponent {
  variant?: ModalsNewThreadNotificationVariant;
}

const ModalsNewThreadNotification: React.FC<ModalsNewThreadNotificationProps & PropsWithChildren> = ({
  children = '',
  className = '',
  variant = ModalsNewThreadNotificationVariant.WARNING,
}) => (
  <div
    className={classNames(className, styles.root, {
      [styles.warning]: variant === ModalsNewThreadNotificationVariant.WARNING,
      [styles.error]: variant === ModalsNewThreadNotificationVariant.ERROR,
    })}
  >
    <p className={styles.text}>{children}</p>
  </div>
);

export default ModalsNewThreadNotification;
