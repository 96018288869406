import dayjs from 'dayjs';
import fecha from 'fecha';

export const getCurrentTime = (): string => {
  let result: string;
  const currentDate = new Date();
  result = `${currentDate.getFullYear()}-`;

  if (currentDate.getUTCMonth() > 9) {
    result += `${currentDate.getUTCMonth() + 1}-`;
  } else {
    result += `0${currentDate.getUTCMonth() + 1}-`;
  }

  if (currentDate.getUTCDay() > 9) {
    result += `${currentDate.getUTCDay() + 1}T`;
  } else {
    result += `0${currentDate.getUTCDay() + 1}T`;
  }

  if (currentDate.getHours() > 9) {
    result += `${currentDate.getHours()}:`;
  } else {
    result += `0${currentDate.getHours()}:`;
  }

  if (currentDate.getMinutes() > 9) {
    result += `${currentDate.getMinutes()}:`;
  } else {
    result += `0${currentDate.getMinutes()}:`;
  }

  if (currentDate.getSeconds() > 9) {
    result += currentDate.getSeconds();
  } else {
    result += `0${currentDate.getSeconds()}`;
  }
  return result;
};

export const getCurrentTimeWithMilliseconds = (): string => {
  let result: string;
  result = getCurrentTime();
  result += '+0000';
  return result;
};

export const secondsToTime = (seconds: number): string => {
  const date = new Date(0);
  date.setSeconds(seconds);
  return dayjs(date).format('mm:ss');
};

export const getYesterday = (): Date => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today;
};

export const getUTCDateTime = (dateString: string): string => {
  const date: Date = new Date(`${dateString.split('+')[0]}+0000`);
  try {
    return fecha.format(date, 'HH:mm') || '';
  } catch (e) {
    // TODO: this is a temporary workaround, cause of the problem probably lies in MessageForm component
    return fecha.format(new Date(), 'HH:mm') || '';
  }
};

export const formatSimpleDate = (date?: string | Date | number): string =>
  new Date(date || new Date().getTime()).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
