import React from 'react';

import { StyleableComponent } from 'types';

const PhonePaused: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9991 3C15.4491 3 14.9991 3.45 14.9991 4V9C14.9991 9.55 15.4491 10 15.9991 10C16.5491 10 16.9991 9.55 16.9991 9V4C16.9991 3.45 16.5491 3 15.9991 3ZM18.9991 4V9C18.9991 9.55 19.4491 10 19.9991 10C20.5491 10 20.9991 9.55 20.9991 9V4C20.9991 3.45 20.5491 3 19.9991 3C19.4491 3 18.9991 3.45 18.9991 4ZM19.2291 15.26L16.6891 14.97C16.0791 14.9 15.4791 15.11 15.0491 15.54L13.2091 17.38C10.3791 15.94 8.05906 13.63 6.61906 10.79L8.46906 8.94C8.89906 8.51 9.10906 7.91 9.03906 7.3L8.74906 4.78C8.62906 3.77 7.77906 3.01 6.75906 3.01H5.02906C3.89906 3.01 2.95906 3.95 3.02906 5.08C3.55906 13.62 10.3891 20.44 18.9191 20.97C20.0491 21.04 20.9891 20.1 20.9891 18.97V17.24C20.9991 16.23 20.2391 15.38 19.2291 15.26Z"
      fill="currentColor"
    />
  </svg>
);

export default PhonePaused;
