import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';

import {
  Category,
  Contact,
  ContactPhoneNumberCard,
  ContactsSimpleContactsMap,
  IntegrationContact,
  SimpleContactPhone,
} from 'types';

import { removePlusses, removeWhiteSpaces } from '../common';
import { formatNumber, formatNumberAsLocal } from '../country_codes';
import { normalizeNumber } from '../parsing';
import { isPhoneNumberAndOrContactIncludesSearchTerm } from '../search';
import { checkServiceContactPhoneNumber } from '../serviceContacts';

export * from './reducers';
export * from './selectors';
export * from './actions';

export type ContactId = {
  id: string;
};

export const normalizePhoneNumber = (number: string): string => removePlusses(removeWhiteSpaces(number));

export const isNumberEqual = (phone1 = '', phone2 = '', countryCode: CountryCode = 'FR'): boolean => {
  if (phone1.length === 0 || phone2.length === 0) {
    return false;
  }

  if (normalizePhoneNumber(phone1) === normalizePhoneNumber(phone2)) {
    return true;
  }

  const phone1Parsed = parsePhoneNumberFromString(phone1, countryCode);
  const phone2Parsed = parsePhoneNumberFromString(phone2, countryCode);

  if (phone1Parsed === undefined || phone2Parsed === undefined) {
    return false;
  }

  return phone1Parsed.isEqual(phone2Parsed);
};

export const isContactFilteredBySearchString = (searchString: string, contact: Partial<Contact>): boolean => {
  const { phones = [], emails = [], company = '', notes = '' } = contact;

  const phonesAsString: string = phones.flatMap((item) => item.number || item.normalNumber).join(' ');
  const emailsAsString: string = emails.flatMap((item) => item.email).join('');
  const summary: string = [phonesAsString, emailsAsString, company, notes].join('').trim();

  return isPhoneNumberAndOrContactIncludesSearchTerm({
    contact,
    searchTerm: searchString,
    phoneNumber: phonesAsString,
    summary,
  });
};

export const filterContactsBySearchString = (contacts: Contact[], searchString: string): Contact[] => {
  const filteredContacts = contacts.filter((contact) => isContactFilteredBySearchString(searchString, contact));
  return filteredContacts;
};

export const getContactByPhoneNumber = (
  phoneNumber: string,
  contacts: Array<Contact>,
  countryCode: CountryCode = 'FR',
): Contact | undefined =>
  contacts.find((contact) =>
    contact.phones?.find((phone) => isNumberEqual(phone.normalNumber || phone.number, phoneNumber, countryCode)),
  );

export const getContactByPhoneNumberId = (phoneNumberId: string, contacts: Array<Contact>): Contact | undefined =>
  contacts.find((contact) => contact.phones?.find((phone) => phone.id === phoneNumberId));

export const getContactFullName = (contact?: Pick<Contact, 'firstName' | 'lastName'> | null): string => {
  if (contact) {
    const { firstName = '', lastName = '' } = contact;
    return `${firstName.trim()} ${lastName.trim()}`.trim();
  }
  return '';
};

export const getDisplayNameOrPhoneNumber = (callingContact: Contact | undefined, callPartyPhone: string): string =>
  getContactFullName(callingContact) || formatNumber(callPartyPhone) || '';

export const getDisplayName = (contact: Partial<Contact>): string => {
  const fullName = getContactFullName(contact);
  const { firstName = '', lastName = '', company = '', phones = [], emails = [] } = contact;
  if (fullName.length > 0) return fullName;
  if (firstName.length > 0) return firstName;
  if (lastName.length > 0) return lastName;
  if (company.length > 0) return company;
  if (phones.length > 0) return phones[0].number || '';
  if (emails.length > 0) return emails[0].email || '';

  return '';
};

export const getMostRecentContactListByPhoneNumberList = (
  contactList: Array<Contact>,
  phoneNumberList: Array<string>,
  limit = 3,
): Array<Contact> => {
  const recentNumberList: Array<string> = phoneNumberList.reduce<Array<string>>((numberList, phoneNumber) => {
    if (!numberList.includes(phoneNumber)) {
      numberList.push(phoneNumber);
    }
    return numberList;
  }, []);

  const recentContacts: Array<Contact> = [];
  let i = 0;
  while (recentContacts.length < limit && i < recentNumberList.length) {
    const num = recentNumberList[i];
    const contactFound = getContactByPhoneNumber(num, contactList);

    if (contactFound) {
      recentContacts.push({
        ...contactFound,
        phones: contactFound.phones?.filter((phone) => isNumberEqual(phone.number, num)),
      });
    }
    i += 1;
  }

  return recentContacts;
};

export const matchPhoneNumberWithContact = (
  phoneNumber: string,
  phoneNumberContactMap: ContactsSimpleContactsMap,
  userCountryIsoCode: CountryCode = 'FR',
): Contact | undefined => {
  if (Object.keys(phoneNumberContactMap).length > 0) {
    const normalizedPhoneNumber = normalizePhoneNumber(normalizeNumber(phoneNumber));
    const parsedPhoneNumber = parsePhoneNumberFromString(normalizedPhoneNumber, userCountryIsoCode);

    if (parsedPhoneNumber === undefined) {
      return phoneNumberContactMap[normalizedPhoneNumber];
    }

    const parsedNationalNumber = parsedPhoneNumber.nationalNumber.toString();
    const parsedNumber = normalizePhoneNumber(parsedPhoneNumber.number.toString());
    return phoneNumberContactMap[parsedNationalNumber] || phoneNumberContactMap[parsedNumber];
  }

  return undefined;

  /**
   * This is commented because with the current setup we cannot have any loops in this function,
   * as this is run too many times on every step (navigation, clicking on call logs etc.)
   * Later on if necessary changes are implemented, this functionality can be used.
   * * */

  // let foundContact: Contact | undefined;
  //
  // if (Object.keys(phoneNumberContactMap).length > 0) {
  //   const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
  //   const parsedPhoneNumberWithUserCountry = parsePhoneNumberFromString(normalizedPhoneNumber, userCountryIsoCode);
  //   const parsedPhoneNumber = parsePhoneNumberFromString(`+${normalizedPhoneNumber}`);
  //
  //   Object.keys(phoneNumberContactMap).forEach((contactPhoneNumber) => {
  //     if (foundContact) {
  //       return;
  //     }
  //
  //     const parsedContactPhoneNumberWithUserCountry = parsePhoneNumberFromString(
  //       contactPhoneNumber,
  //       userCountryIsoCode,
  //     );
  //
  //     if (
  //       parsedPhoneNumberWithUserCountry &&
  //       parsedContactPhoneNumberWithUserCountry?.isEqual(parsedPhoneNumberWithUserCountry)
  //     ) {
  //       foundContact = phoneNumberContactMap[contactPhoneNumber];
  //       return;
  //     }
  //
  //     const parsedContactPhoneNumberWithNumberCountry = parsePhoneNumberFromString(
  //       contactPhoneNumber,
  //       parsedPhoneNumber?.country,
  //     );
  //
  //     if (
  //       parsedPhoneNumber &&
  //       parsedContactPhoneNumberWithNumberCountry &&
  //       parsedContactPhoneNumberWithNumberCountry.isEqual(parsedPhoneNumber)
  //     ) {
  //       foundContact = phoneNumberContactMap[contactPhoneNumber];
  //     }
  //   });
  // }
  //
  // return foundContact;
};

export const getContactOrNumberDisplayText = ({
  phoneNumberOrEmpty = '',
  clirText = '',
  contact = undefined,
  showContactPhoneNumberAndType = false,
  showPhoneNumberAfterContactName = false,
}: {
  phoneNumberOrEmpty?: string;
  clirText?: string;
  contact?: Partial<Contact> | IntegrationContact | null;
  showContactPhoneNumberAndType?: boolean;
  showPhoneNumberAfterContactName?: boolean;
}): string => {
  const { isServiceContactNumber, serviceContactDisplayName } = checkServiceContactPhoneNumber({
    phoneNumber: phoneNumberOrEmpty,
  });

  if (isServiceContactNumber) {
    return serviceContactDisplayName;
  }

  if (phoneNumberOrEmpty.length < 1) {
    return clirText;
  }

  const formattedPhoneNumber: string = formatNumberAsLocal(phoneNumberOrEmpty);
  const contactFullName: string = getContactFullName(contact);

  if (showContactPhoneNumberAndType) {
    const contactPhones: SimpleContactPhone[] = contact && 'phones' in contact ? contact.phones || [] : [];
    const contactPhoneType: string =
      contactPhones.find(({ normalNumber }) => normalNumber === phoneNumberOrEmpty)?.type || '';
    return `${formattedPhoneNumber}${contactPhoneType ? ` (${contactPhoneType})` : ''}`;
  }

  if (contact && contactFullName.length > 0) {
    if (showPhoneNumberAfterContactName) {
      return `${contactFullName} ${formattedPhoneNumber}`;
    }

    return contactFullName;
  }

  return formattedPhoneNumber;
};

export const getContactListPhoneNumberCards = (
  contactList: Contact[],
  categoryList: Category[],
): ContactPhoneNumberCard[] =>
  contactList.reduce((items: ContactPhoneNumberCard[], contact) => {
    const { categoryId, id: contactId, imageUrl, phones = [] } = contact;

    if (phones.length > 0) {
      const preparedItems: ContactPhoneNumberCard[] = phones.map(({ normalNumber, number, type = '' }) => {
        const { color } = categoryList.find((category) => category.id === categoryId) || {};
        const phoneNumber = normalNumber ? `+${normalNumber}` : number;
        const phoneNumberType = type.length > 0 ? ` (${type})` : '';
        const contactFullName = `${getContactFullName(contact) || phoneNumber}${phoneNumberType}`;

        return {
          avatarProps: {
            imageSrc: imageUrl,
            backgroundColor: color,
            fullName: contactFullName,
          },
          contactFullName,
          phoneNumber,
          contactId,
        };
      });
      items.push(...preparedItems);
    }
    return items;
  }, []);
