import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { InputCommonLayoutProps } from '../../types';

import styles from './InputCommonLayout.module.scss';

const InputCommonLayout: React.FC<InputCommonLayoutProps & PropsWithChildren> = ({
  children,
  error = '',
  className = '',
  label = '',
  hint = '',
  isWrappedByDiv = false,
  isErrorMessageHidden = false,
}) => {
  const hasError = error.length > 0;
  const hasHint = hint.length > 0;
  const hasLabel = label.length > 0;

  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.hasError]: hasError,
        },
        className,
      )}
    >
      {isWrappedByDiv ? (
        <div>
          {hasLabel && <span className={styles.label}>{label}</span>}
          {children}
        </div>
      ) : (
        <label>
          {hasLabel && <span className={styles.label}>{label}</span>}
          {children}
        </label>
      )}
      {((hasError && !isErrorMessageHidden) || hasHint) && <div className={styles.hint}>{error || hint}</div>}
    </div>
  );
};

export default InputCommonLayout;
