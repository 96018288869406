import { ServiceProvider } from 'types';

import analyticsService from './AnalyticsService';
import authService from './AuthService';
import callLogsService from './CallLogsService';
import callQualitySurveyService from './CallQualitySurveyService';
import callStateReportingService from './CallStateReportingService';
import categoriesService from './CategoriesService';
import contactsService from './ContactsService';
import integrationsService from './IntegrationsService';
import localizationService from './LocalizationService';
import messagesService from './MessagesService';
import multiDeviceService from './MultiDeviceService';
import plansAndOffersService from './PlansAndOffersService';
import privacyService from './PrivacyService';
import purchaseService from './PurchaseService';
import pushService from './PushService';
import sentryService from './SentryService';
import threadsService from './ThreadsService';
import userService from './UserService';
import voicemailsService from './VoicemailsService';
import voipService from './VoipService';

const serviceProvider: ServiceProvider = {
  analyticsService,
  authService,
  callLogsService,
  callQualitySurveyService,
  callStateReportingService,
  categoriesService,
  contactsService,
  integrationsService,
  localizationService,
  messagesService,
  multiDeviceService,
  plansAndOffersService,
  privacyService,
  purchaseService,
  pushService,
  sentryService,
  threadsService,
  userService,
  voicemailsService,
  voipService,
};

export { default as analyticsService } from './AnalyticsService';
export { default as authService } from './AuthService';
export { default as callLogsService } from './CallLogsService';
export { default as callQualitySurveyService } from './CallQualitySurveyService';
export { default as callStateReportingService } from './CallStateReportingService';
export { default as categoriesService } from './CategoriesService';
export { default as contactsService } from './ContactsService';
export { default as integrationsService } from './IntegrationsService';
export { default as localizationService } from './LocalizationService';
export { default as messagesService } from './MessagesService';
export { default as multiDeviceService } from './MultiDeviceService';
export { default as plansAndOffersService } from './PlansAndOffersService';
export { default as privacyService } from './PrivacyService';
export { default as purchaseService } from './PurchaseService';
export { default as pushService } from './PushService';
export { default as sentryService } from './SentryService';
export { default as threadsService } from './ThreadsService';
export { default as userService } from './UserService';
export { default as voicemailsService } from './VoicemailsService';
export { default as voipService } from './VoipService';

export { default as emailSubscriptionService } from './EmailSubscriptionService';

export default serviceProvider;
