import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface TooltipPortalProps {
  id?: string;
}

const TooltipPortal: React.FC<TooltipPortalProps & PropsWithChildren> = ({ children, id }) => {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  const createPopoverPortal = useCallback((): void => {
    if (!portalElement) {
      let portalInstance: HTMLElement = document.createElement('div');

      const existingPortalElem = document.getElementById(id || '');

      if (existingPortalElem) {
        portalInstance = existingPortalElem;
      }

      if (id) {
        portalInstance.setAttribute('id', id);
        portalInstance.setAttribute('data-testid', id);
      }
      setPortalElement(portalInstance);
      document.body.appendChild(portalInstance);
    }
  }, [id, portalElement]);

  const removePopoverPortal = useCallback((): void => {
    if (portalElement) {
      portalElement.parentNode?.removeChild(portalElement);
    }
  }, [portalElement]);

  useEffect(() => {
    createPopoverPortal();
    return () => {
      removePopoverPortal();
    };
  }, [createPopoverPortal, removePopoverPortal]);

  if (portalElement) {
    return createPortal(children, portalElement);
  }

  return null;
};

export default TooltipPortal;
