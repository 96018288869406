import React, { useCallback } from 'react';

import { CallQualitySurveyDataReason } from 'types';

import ToggleButton from './ToggleButton';

import styles from './ModalsCallQualitySurveyReasons.module.scss';

type ReasonsProps = {
  allReasons: CallQualitySurveyDataReason[];
  selectedReasons: string[];
  setReasons: (reasons: string[]) => void;
};

export const Reasons: React.FC<ReasonsProps> = ({ allReasons, selectedReasons, setReasons }) => {
  if (!allReasons || allReasons.length === 0) {
    return null;
  }

  const handleSelectReason = useCallback(
    (reason: string) => {
      const isSelected = selectedReasons.includes(reason);

      const withoutCurrentReason = selectedReasons.filter((value) => value !== reason);
      const withCurrentReason = [...selectedReasons, reason];

      const newValue = isSelected ? withoutCurrentReason : withCurrentReason;

      setReasons(newValue);
    },
    [selectedReasons, setReasons],
  );

  return (
    <div className={styles.root}>
      {allReasons.map((currentReason) => (
        <ToggleButton
          key={currentReason.key}
          isSelected={selectedReasons.includes(currentReason?.key || '')}
          onClick={() => handleSelectReason(currentReason?.key || '')}
        >
          {currentReason.value}
        </ToggleButton>
      ))}
    </div>
  );
};

export default Reasons;
