import React from 'react';

import { StyleableComponent } from 'types';

const WebAccess: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3238_10119)">
      <rect
        width="50"
        height="50"
        fill="#FFC53F"
      />
      <path
        d="M0 8.87784C0 3.97475 3.97474 0 8.87784 0L41.1222 0C46.0253 0 50 3.97474 50 8.87784V41.1222C50 46.0253 46.0253 50 41.1222 50H8.87784C3.97475 50 0 46.0253 0 41.1222L0 8.87784Z"
        fill="#FFC53F"
      />
      <g clipPath="url(#clip1_3238_10119)">
        <path
          d="M27.3438 32.0312V34.375L29.6875 35.5469V36.7188H20.3125L20.3079 35.5516L22.6563 34.375V32.0312H14.4438C14.2899 32.0303 14.1377 31.9991 13.996 31.9392C13.8542 31.8794 13.7256 31.7921 13.6176 31.6825C13.5096 31.5729 13.4243 31.443 13.3666 31.3003C13.3089 31.1577 13.2799 31.0051 13.2813 30.8512V15.6332C13.2813 14.9816 13.8145 14.4531 14.4438 14.4531H35.5563C36.1985 14.4531 36.7188 14.9793 36.7188 15.6332V30.8512C36.7188 31.5027 36.1856 32.0312 35.5563 32.0312H27.3438V32.0312ZM15.625 27.3438V29.6875H34.375V27.3438H15.625Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3238_10119">
        <rect
          width="50"
          height="50"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3238_10119">
        <rect
          width="28.125"
          height="28.125"
          fill="white"
          transform="translate(10.9375 10.9375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default WebAccess;
