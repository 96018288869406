import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import JsSIP from '@onoff/jssip';
import { showErrorToast } from '@onoff/toast-notification';

import { Status } from 'types';

import { getSIPInstanceIdFromLocalStorage, isIPv4Address } from 'helpers';

import { SIP } from '@constants';
import {
  selectSIPDataCallsByActiveAndWaiting,
  selectSIPDataConfig,
  selectSIPDataConnect,
  selectSIPDataStartCall,
  selectSIPDataStunRecords,
  selectSIPDataWssAvailableUrlOrEmpty,
  selectSIPStatuses,
  sipRemoveDataCallByCallIdentifier,
  sipResetDataStartCallOutgoingHandler,
  sipSetStatusSIP,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { SIPEvent, SIPStatisticsScopes, SIPStatuses, SIPWebRTCOriginators } from '@redux/types';
import { sentryService } from 'services';

import packageJson from '../../../../../package.json';
import {
  checkSIPAvailability,
  checkSIPDebugMode,
  checkSIPLogsMode,
  sipAudioManagementWrapperRemove,
  sipDebugConsoleLogger,
} from '../../../helpers';
import {
  CallbackSIPExistingUserAgentHeaderSetterHandlerProps,
  CallbackSIPExistingUserAgentHeaderSetterHandlerReturn,
  JsSIPMediaConstraints,
  JsSIPUserAgent,
  JsSIPUserAgentCallOptions,
  JsSIPUserAgentURI,
  UseSIPUserAgentReturn,
} from '../../../types';
import { useSIPMediaConstraints } from '../../useSIPMedia';
import { useSIPStatistics } from '../../useSIPStatistics';

import { useSIPUserAgentEventListenersOthers, useSIPUserAgentEventListenersWebRTC } from './eventListeners';

export const useSIPUserAgent = (): UseSIPUserAgentReturn => {
  const refSIPUserAgent = useRef<JsSIPUserAgent | undefined>(undefined);
  const unhandledRTCSessionTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const wssConnectionTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { isSIPAvailable } = checkSIPAvailability();
  const sipInstanceIdFromLocalStorage = getSIPInstanceIdFromLocalStorage();
  const { isSIPLogsMode } = checkSIPLogsMode();
  const { isSIPDebugMode } = checkSIPDebugMode();

  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { statusSIP, statusInitializeCall } = useWebAppSelector(selectSIPStatuses);
  const {
    dataSIPStartCall: {
      outgoingPhoneNumberLocal,
      outgoingPhoneNumberRemote,
      outgoingTransactionID,
      outgoingCategoryID,
      outgoingCallIdentifier,
      outgoingInstanceId,
      incomingTransactionID,
      incomingInstanceId,
      incomingCallIdentifier,
    },
  } = useWebAppSelector(selectSIPDataStartCall);
  const { dataSIPConnect } = useWebAppSelector(selectSIPDataConnect);
  const { dataSIPConfig } = useWebAppSelector(selectSIPDataConfig);
  const { dataSIPWssAvailableUrlOrEmpty } = useWebAppSelector(selectSIPDataWssAvailableUrlOrEmpty);
  const { dataSIPStunRecords } = useWebAppSelector(selectSIPDataStunRecords);
  const { dataSIPCallsByActiveAndWaiting } = useWebAppSelector(selectSIPDataCallsByActiveAndWaiting);

  const {
    onConnectingEventHandler,
    onConnectedEventHandler,
    onDisconnectedEventHandler,
    onRegisteredEventHandler,
    onUnRegisteredEventHandler,
    onRegistrationFailedEventHandler,
    onRegistrationExpiringEventHandler,
  } = useSIPUserAgentEventListenersOthers();
  const { onNewRTCSessionEventHandler } = useSIPUserAgentEventListenersWebRTC();

  const { constraints } = useSIPMediaConstraints();
  const { sipSendErrorToSentry, sipSendErrorToGTM } = useSIPStatistics();

  const clearWssConnectionTimeout = () => {
    if (wssConnectionTimeout.current) {
      clearTimeout(wssConnectionTimeout.current);
    }
  };

  const clearUnhandledRTCSessionTimeout = () => {
    if (unhandledRTCSessionTimeout.current) {
      clearTimeout(unhandledRTCSessionTimeout.current);
    }
  };

  const callbackSIPUserAgentHandler = (): void => {
    const canSIPAuthenticate = sipInstanceIdFromLocalStorage.length > 0;
    const isSIPDoesNotHaveAUserAgent = refSIPUserAgent.current === undefined;
    const isSIPStatusIdleOrDisconnected = [SIPStatuses.IDLE, SIPStatuses.DISCONNECTED].includes(statusSIP);
    const isSIPStatusCallInitializationSucceeded = statusInitializeCall === Status.SUCCEEDED;

    if (
      isSIPAvailable &&
      canSIPAuthenticate &&
      isSIPDoesNotHaveAUserAgent &&
      isSIPStatusCallInitializationSucceeded &&
      isSIPStatusIdleOrDisconnected
    ) {
      sipDebugConsoleLogger('SIP CONNECT: USER AGENT CREATION IS STARTED!');

      dispatch(sipSetStatusSIP(SIPStatuses.LOADING));

      const sipTransactionId = outgoingTransactionID || incomingTransactionID;
      const sipOriginator = outgoingTransactionID.length > 0 ? SIPWebRTCOriginators.LOCAL : SIPWebRTCOriginators.REMOTE;
      const callIdentifier =
        sipOriginator === SIPWebRTCOriginators.REMOTE ? incomingCallIdentifier : outgoingCallIdentifier;
      const instanceId = sipOriginator === SIPWebRTCOriginators.REMOTE ? incomingInstanceId : outgoingInstanceId;

      try {
        const { idUser, idCategory, ipAddress } = dataSIPConnect;
        const { sipDomain, sipSecret, sipUsername } = dataSIPConfig;

        // WSS
        const sipSocket = new JsSIP.WebSocketInterface(dataSIPWssAvailableUrlOrEmpty);

        // URI
        const sipURI = `sip:${idUser}@${sipDomain}`;

        const isIPv4 = isIPv4Address(ipAddress);
        // Contact
        const sipContactURIScheme = 'sip';
        const sipContactURIUser = `${idCategory}`;
        const sipContactURIHost = isIPv4 ? ipAddress : `[${ipAddress}]`;
        const sipContactURIPort = undefined;
        const sipContactURIParameters = { transport: 'ws' };
        const sipContactURI = new JsSIP.URI(
          sipContactURIScheme,
          sipContactURIUser,
          sipContactURIHost,
          sipContactURIPort,
          sipContactURIParameters,
        ).toString();

        // Transaction ID
        const headerTransactionId = `${SIP.CONFIGURATIONS.REQUEST.HEADER_TRANSACTION_ID_KEY}: ${sipTransactionId}`;
        const headerCallIdentifier = `${SIP.CONFIGURATIONS.REQUEST.HEADER_CALL_ID}: ${callIdentifier}`;
        const headerCallInstanceId = `${SIP.CONFIGURATIONS.REQUEST.HEADER_INSTANCE_ID}: ${instanceId}`;

        // SIP UserAgent
        const sipWebAppUserAgent = `${SIP.CONFIGURATIONS.USER_AGENT_PREFIX}${packageJson.version || ''}`;

        const sipUserAgentConfig = {
          register: SIP.CONFIGURATIONS.REGISTER,
          use_preloaded_route: SIP.CONFIGURATIONS.USE_PRELOADED_ROUTE,
          sockets: sipSocket,
          uri: sipURI,
          display_name: idCategory,
          contact_uri: sipContactURI,
          password: sipSecret,
          instance_id: sipInstanceIdFromLocalStorage,
          authorization_user: sipUsername,
          user_agent: sipWebAppUserAgent,
          extra_headers: [headerTransactionId, headerCallIdentifier, headerCallInstanceId],
          connection_recovery_min_interval: SIP.CONFIGURATIONS.CONNECTION_RECOVERY_MIN_INTERVAL,
          connection_recovery_max_interval: SIP.CONFIGURATIONS.CONNECTION_RECOVERY_MAX_INTERVAL,
          no_answer_timeout: SIP.CONFIGURATIONS.NO_ANSWER_TIMEOUT,
          register_expires: SIP.CONFIGURATIONS.REGISTER_EXPIRES,
          session_timers: SIP.CONFIGURATIONS.SESSION_TIMERS,
          session_timers_refresh_method: SIP.CONFIGURATIONS.SESSION_TIMERS_REFRESH_METHOD,
        };

        sentryService.addBreadcrumb({
          message: SIPEvent.CREATING_USER_AGENT,
          data: sipUserAgentConfig,
        });

        const sipUserAgent = new JsSIP.UA(sipUserAgentConfig);

        // Setting Event Listeners
        sipUserAgent.on('connecting', (event) => {
          onConnectingEventHandler({
            event,
            originator: sipOriginator,
          });

          wssConnectionTimeout.current = setTimeout(() => {
            dispatch(sipSetStatusSIP(SIPStatuses.TRY_ANOTHER_WSS));

            sentryService.addBreadcrumb({
              message: SIPEvent.WSS_CONNECTION_TIMEOUT,
              data: { callIdentifier },
            });
            wssConnectionTimeout.current = null;
          }, SIP.TIMEOUTS.WSS_CONNECTION_TIMEOUT_MS);
        });

        sipUserAgent.on('connected', (event) => {
          clearWssConnectionTimeout();
          onConnectedEventHandler(event);
        });

        sipUserAgent.on('registered', (event) => {
          onRegisteredEventHandler({
            event,
            originator: sipOriginator,
            transactionId: sipTransactionId,
          });

          unhandledRTCSessionTimeout.current = setTimeout(() => {
            sipUserAgent.unregister();

            sentryService.addBreadcrumb({
              message: SIPEvent.UNHANDLED_RTC_TIMEOUT,
              data: { callIdentifier },
            });

            dispatch(sipRemoveDataCallByCallIdentifier(callIdentifier));
            unhandledRTCSessionTimeout.current = null;
          }, SIP.TIMEOUTS.UNHANDLED_RTC_SESSION_MS);
        });

        sipUserAgent.on('newRTCSession', (event) => {
          clearUnhandledRTCSessionTimeout();
          onNewRTCSessionEventHandler(event);
        });

        sipUserAgent.on('unregistered', onUnRegisteredEventHandler);

        sipUserAgent.on('disconnected', (event) => {
          clearWssConnectionTimeout();
          clearUnhandledRTCSessionTimeout();
          wssConnectionTimeout.current = null;
          unhandledRTCSessionTimeout.current = null;
          onDisconnectedEventHandler(event);
        });

        sipUserAgent.on('registrationExpiring', onRegistrationExpiringEventHandler);

        sipUserAgent.on('registrationFailed', onRegistrationFailedEventHandler);

        sipUserAgent.start();

        refSIPUserAgent.current = sipUserAgent;

        sipDebugConsoleLogger('SIP CONNECT: USER AGENT CREATION IS SUCCEEDED!');
      } catch (error) {
        sipDebugConsoleLogger('SIP CONNECT: USER AGENT CREATION IS FAILED!', { error });

        const errorMessage = error instanceof Error ? error.message : error;

        dispatch(sipSetStatusSIP(SIPStatuses.FAILED));

        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_CONNECT,
          error: `USER AGENT CREATION IS FAILED! "${errorMessage}"`,
        });

        sipSendErrorToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_CONNECT,
          sipCallOriginator: sipOriginator,
          sipCallTitle: 'USER AGENT CREATION IS FAILED!',
          sipCallDescription: `${errorMessage}`,
          sipCallTransactionId: sipTransactionId,
        });

        // Outgoing Calls
        if (sipOriginator === SIPWebRTCOriginators.LOCAL) {
          showErrorToast({
            heading: intl.formatMessage({ id: 'SIP.Notifications.UserAgentCreationFailure.title' }),
            message: intl.formatMessage({ id: 'SIP.Notifications.UserAgentCreationFailure.description' }),
          });
        }
      }
    }
  };

  const callbackSIPExistingUserAgentHeaderSetterHandler = useCallback(
    ({
      categoryId,
    }: CallbackSIPExistingUserAgentHeaderSetterHandlerProps): CallbackSIPExistingUserAgentHeaderSetterHandlerReturn => {
      const sipUserAgent = refSIPUserAgent.current;

      if (sipUserAgent === undefined) {
        return {
          headerOnoffContact: '',
        };
      }

      const contactURIExisting = sipUserAgent?.contact?.uri as unknown as JsSIPUserAgentURI | undefined;

      const contactURIScheme = `${contactURIExisting?.scheme}`;
      const contactURIUser = `${categoryId}`;
      const contactURIHost = `${contactURIExisting?.host}`;
      const contactURIPort = contactURIExisting?.port;
      const contactURIParameters = { transport: 'ws' };

      const contactURINew = new JsSIP.URI(
        contactURIScheme,
        contactURIUser,
        contactURIHost,
        contactURIPort,
        contactURIParameters,
      ).toString();

      sipUserAgent.set('display_name', categoryId);
      sipUserAgent.set('contact_uri', contactURINew);

      const headerOnoffContactValue = `<${contactURINew}>;+sip.ice;reg-id=1;+sip.instance="<urn:uuid:${sipInstanceIdFromLocalStorage}>"`;
      const headerOnoffContact = `${SIP.CONFIGURATIONS.REQUEST.HEADER_ONOFF_CONTACT}: ${headerOnoffContactValue}`;
      return { headerOnoffContact };
    },
    [sipInstanceIdFromLocalStorage],
  );

  const callbackSIPExistingUserAgentMakeCallHandler = (): void => {
    const canSIPAuthenticate = sipInstanceIdFromLocalStorage.length > 0;
    const isSIPHaveAUserAgent = refSIPUserAgent.current !== undefined;
    const isSIPRegistered = statusSIP === SIPStatuses.REGISTERED;
    const isSIPHaveInitializedCall = statusInitializeCall === Status.SUCCEEDED;
    const isSIPHaveStunRecords = dataSIPStunRecords.length > 0;
    const isSIPHaveOutgoingPhoneNumberLocal = outgoingPhoneNumberLocal.length > 0;
    const isSIPHaveOutgoingPhoneNumberRemote = outgoingPhoneNumberRemote.length > 0;
    const isSIPHaveOutgoingTransactionID = outgoingTransactionID.length > 0;
    const isSIPHaveOutgoingCategoryID = outgoingCategoryID.length > 0;

    if (
      isSIPAvailable &&
      canSIPAuthenticate &&
      isSIPHaveAUserAgent &&
      isSIPRegistered &&
      isSIPHaveInitializedCall &&
      isSIPHaveStunRecords &&
      isSIPHaveOutgoingPhoneNumberLocal &&
      isSIPHaveOutgoingPhoneNumberRemote &&
      isSIPHaveOutgoingTransactionID &&
      isSIPHaveOutgoingCategoryID
    ) {
      dispatch(sipResetDataStartCallOutgoingHandler());

      try {
        const sipUserAgent = refSIPUserAgent.current;
        const { sipDomain } = dataSIPConfig;

        // Set and Get Headers
        const { headerOnoffContact } = callbackSIPExistingUserAgentHeaderSetterHandler({
          categoryId: outgoingCategoryID,
        });

        // Transaction ID
        const headerTransactionId = `${SIP.CONFIGURATIONS.REQUEST.HEADER_TRANSACTION_ID_KEY}: ${outgoingTransactionID}`;

        // Call Options
        const rtcSessionCallTarget = `sip:${outgoingPhoneNumberRemote}@${sipDomain}`;
        const rtcSessionCallOptions: JsSIPUserAgentCallOptions = {
          mediaConstraints: constraints as JsSIPMediaConstraints,
          pcConfig: { iceServers: [{ urls: dataSIPStunRecords }] },
          extraHeaders: [headerOnoffContact, headerTransactionId],
        };

        // Make the Call
        sipUserAgent?.call(rtcSessionCallTarget, rtcSessionCallOptions);
      } catch (error) {
        sipDebugConsoleLogger('SIP CONNECTED: MAKE OUTGOING CALL FAILED!', { error });

        const errorMessage = error instanceof Error ? error.message : error;

        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_CONNECTED,
          error: `MAKE OUTGOING CALL FAILED! "${errorMessage}"`,
        });

        sipSendErrorToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_CONNECTED,
          sipCallOriginator: SIPWebRTCOriginators.LOCAL,
          sipCallTitle: 'MAKE OUTGOING CALL FAILED!',
          sipCallDescription: `${errorMessage}`,
          sipCallTransactionId: outgoingTransactionID,
        });

        showErrorToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.MakeOutgoingCallFailure.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.MakeOutgoingCallFailure.description' }),
        });
      }
    }
  };

  const callbackSIPExistingUserAgentReRegisterForOngoingCallsHandler = (): void => {
    const isSIPHaveAUserAgent = refSIPUserAgent.current !== undefined;
    const isSIPExpiring = statusSIP === SIPStatuses.TIMEOUT;
    const hasAtLeastOneOngoingCall = dataSIPCallsByActiveAndWaiting.length > 0;

    if (isSIPAvailable && isSIPHaveAUserAgent && isSIPExpiring && hasAtLeastOneOngoingCall) {
      /**
       * Because of that we are using a custom event listener for "registrationExpiring"
       * JsSIP stops to re-register automatically when the user agent is expiring
       * We should re-register the user agent manually
       * https://jssip.net/documentation/3.10.x/api/ua/#event_registrationExpiring
       */
      try {
        refSIPUserAgent.current?.register();

        if (refSIPUserAgent.current?.isRegistered()) {
          dispatch(sipSetStatusSIP(SIPStatuses.REGISTERED));
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : error;

        dispatch(sipSetStatusSIP(SIPStatuses.FAILED));

        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_CONNECTED,
          error: `USER AGENT RE-REGISTRATION IS FAILED! "${errorMessage}"`,
        });
      }
    }
  };

  const callbackSIPExistingUserAgentDisconnectedHandler = (): void => {
    const isSIPDisconnected = statusSIP === SIPStatuses.DISCONNECTED;

    if (isSIPAvailable && isSIPDisconnected) {
      sipDebugConsoleLogger('SIP CONNECT: SIP DISCONNECTED!');

      // Remove the DOM Audio Elements
      sipAudioManagementWrapperRemove();

      /**
       * "removeAllListeners()"
       * Removes the all event listeners
       */
      refSIPUserAgent.current?.removeAllListeners();

      // Clear the Ref
      refSIPUserAgent.current = undefined;

      // Change SIP Status as IDLE
      dispatch(sipSetStatusSIP(SIPStatuses.IDLE));
    }
  };

  const callbackJsSIPDebugModeHandler = (): void => {
    if (!isSIPAvailable) {
      return;
    }

    if (isSIPLogsMode || isSIPDebugMode) {
      JsSIP.debug.enable(SIP.CONFIGURATIONS.DEBUG_MODE.NAMESPACES);
    } else {
      JsSIP.debug.disable();
    }
  };

  useEffect(callbackSIPUserAgentHandler, [
    isSIPAvailable,
    sipInstanceIdFromLocalStorage,
    dataSIPConnect,
    sipSendErrorToSentry,
    sipSendErrorToGTM,
    onConnectingEventHandler,
    onConnectedEventHandler,
    onDisconnectedEventHandler,
    onRegisteredEventHandler,
    onUnRegisteredEventHandler,
    onRegistrationExpiringEventHandler,
    onNewRTCSessionEventHandler,
    dispatch,
    intl,
    statusSIP,
    dataSIPConfig,
    outgoingTransactionID,
    incomingTransactionID,
    dataSIPWssAvailableUrlOrEmpty,
    onRegistrationFailedEventHandler,
    incomingInstanceId,
    outgoingInstanceId,
    incomingCallIdentifier,
    outgoingCallIdentifier,
    statusInitializeCall,
  ]);

  useEffect(callbackSIPExistingUserAgentMakeCallHandler, [
    isSIPAvailable,
    sipInstanceIdFromLocalStorage,
    statusSIP,
    statusInitializeCall,
    dataSIPConfig,
    dataSIPStunRecords,
    outgoingPhoneNumberLocal,
    outgoingPhoneNumberRemote,
    outgoingTransactionID,
    outgoingCategoryID,
    constraints,
    callbackSIPExistingUserAgentHeaderSetterHandler,
    sipSendErrorToSentry,
    sipSendErrorToGTM,
    dispatch,
    intl,
  ]);

  useEffect(callbackSIPExistingUserAgentReRegisterForOngoingCallsHandler, [
    isSIPAvailable,
    statusSIP,
    dataSIPCallsByActiveAndWaiting,
    sipSendErrorToSentry,
    dispatch,
  ]);

  useEffect(callbackSIPExistingUserAgentDisconnectedHandler, [isSIPAvailable, statusSIP, dispatch]);

  useEffect(callbackJsSIPDebugModeHandler, [isSIPAvailable, isSIPLogsMode, isSIPDebugMode]);

  const sipStop = (): void => {
    sipDebugConsoleLogger('SIP CONNECT: SIP STOPPING!');

    /**
     * SIP "stop()"
     * Saves the current registration state and disconnects from the signaling server
     * after gracefully "unregistering" and terminating active sessions if any.
     */
    refSIPUserAgent.current?.stop();
  };

  return {
    sipStop,
  };
};
