import React from 'react';

import { isB2BProject } from 'helpers';

import { ErrorCoreProps, ErrorIllustrationType, ErrorType } from '../../types';
import ErrorIllustration from '../ErrorIllustration';
import ErrorInfo from '../ErrorInfo';
import ErrorLayout from '../ErrorLayout';

const errorIllustrationMap = {
  [ErrorType.ERROR_CHUNK]: undefined,
  [ErrorType.ERROR_404]: ErrorIllustrationType.DISCONNECT,
  [ErrorType.ERROR_500]: ErrorIllustrationType.DISCONNECT,
  [ErrorType.ERROR_PAYMENT]: ErrorIllustrationType.PAYMENT_DECLINED,
};

const ErrorCore: React.FC<ErrorCoreProps> = ({
  type,
  title,
  description,
  onClick,
  buttonText,
  classNameInfo,
  classNameIllustration,
}) => {
  const illustrationType: ErrorIllustrationType | undefined = errorIllustrationMap[type];
  const b2BProject = isB2BProject();

  return (
    <ErrorLayout
      info={
        <ErrorInfo
          onClick={onClick}
          className={classNameInfo}
          title={title}
          description={description}
          buttonText={buttonText}
          isProjectB2B={b2BProject}
        />
      }
      illustration={
        <ErrorIllustration
          type={illustrationType}
          isB2BProject={b2BProject}
          className={classNameIllustration}
        />
      }
    />
  );
};

export default ErrorCore;
