import React from 'react';
import { Navigate } from 'react-router-dom';

import { checkoutGetCallbackPathFromStorage } from 'helpers/checkout';

import {
  ApplicationPaths,
  ProtectedCheckoutErrorUrlSearchKeys,
  ProtectedCheckoutErrorUrlSearchValues,
} from 'routes/types';

const PageCheckoutFailure: React.FC = () => {
  const targetUrl = checkoutGetCallbackPathFromStorage();

  const search = new URLSearchParams();

  search.append(
    ProtectedCheckoutErrorUrlSearchKeys.ERROR,
    ProtectedCheckoutErrorUrlSearchValues.ADDING_CREDIT_CARD_FAILED,
  );

  return (
    <Navigate
      to={{
        // The Home is the fallback path
        pathname: targetUrl || ApplicationPaths.ROOT,
        search: targetUrl ? search.toString() : '',
      }}
    />
  );
};

export default PageCheckoutFailure;
