import classNames from 'classnames';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

import { BorderWrapperCore } from 'components/BorderWrapper';
import { ButtonRoundFlat } from 'components/Button';
import { DropdownEmailTypes } from 'components/Dropdowns';

import { useInputState } from '../../hooks';
import { InputContactEmailProps } from '../../types';
import InputCommonLayout from '../InputCommonLayout';
import InputCore from '../InputCore';

import styles from './InputContactEmail.module.scss';

const InputContactEmail: React.FC<InputContactEmailProps> = ({
  onChange,
  email,
  error = '',
  type = '',
  className = '',
  label = '',
  onRemoveEmail,
  onSelectEmailType = () => undefined,
  propTestIds = {
    input: '',
    categoryButton: '',
    categoryDropdown: '',
  },
}) => {
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const { hasFocus, isEmpty, hasHover, onBlur, onFocus, onMouseOver, onMouseLeave, onChangeHandler } = useInputState({
    value: email,
    onChange,
  });
  const hasError = error.length > 0;

  return (
    <InputCommonLayout
      className={classNames(styles.root, className)}
      label={label}
      error={error}
    >
      <div className={styles.wrapper}>
        <BorderWrapperCore
          className={styles.borderWrapper}
          hasFocus={hasFocus}
          isEmpty={isEmpty}
          hasError={hasError}
          hasHover={hasHover}
        >
          <InputCore
            className={styles.input}
            ref={inputRef}
            value={email}
            onChange={onChangeHandler}
            placeholder={intl.formatMessage({ id: 'InputContactEmail.placeholder' })}
            onBlur={onBlur}
            onFocus={onFocus}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            propTestId={propTestIds.input}
          />
        </BorderWrapperCore>
        <DropdownEmailTypes
          className={styles.dropdown}
          selectedValue={type}
          onSelect={(selectedType) => {
            onSelectEmailType(selectedType);
          }}
          propTestIds={{
            button: propTestIds.categoryButton,
            dropdown: propTestIds.categoryDropdown,
          }}
        />
        {onRemoveEmail !== undefined && <ButtonRoundFlat onClick={onRemoveEmail} />}
      </div>
    </InputCommonLayout>
  );
};

export default InputContactEmail;
