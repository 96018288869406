import classNames from 'classnames';
import React from 'react';

import { StyleableComponent } from 'types';

import styles from './Spinner.module.scss';

const Spinner: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(styles.root, className)}
  >
    <path
      opacity="0.083334"
      d="M12.6654 6.00065V2.00065C12.6654 1.63246 12.3669 1.33398 11.9987 1.33398C11.6305 1.33398 11.332 1.63246 11.332 2.00065V6.00065C11.332 6.36884 11.6305 6.66732 11.9987 6.66732C12.3669 6.66732 12.6654 6.36884 12.6654 6.00065Z"
      fill="currentColor"
    />
    <path
      opacity="0.166667"
      d="M15.5772 7.13683L17.5772 3.67273C17.7613 3.35387 17.6521 2.94614 17.3332 2.76204C17.0143 2.57795 16.6066 2.6872 16.4225 3.00606L14.4225 6.47016C14.2384 6.78902 14.3477 7.19675 14.6665 7.38085C14.9854 7.56494 15.3931 7.45569 15.5772 7.13683Z"
      fill="currentColor"
    />
    <path
      opacity="0.25"
      d="M17.5276 9.57885L20.9917 7.57885C21.3105 7.39476 21.4198 6.98703 21.2357 6.66817C21.0516 6.34931 20.6439 6.24006 20.325 6.42415L16.8609 8.42415C16.542 8.60825 16.4328 9.01598 16.6169 9.33484C16.801 9.6537 17.2087 9.76295 17.5276 9.57885Z"
      fill="currentColor"
    />
    <path
      opacity="0.333334"
      d="M17.9993 12.6673H21.9993C22.3675 12.6673 22.666 12.3688 22.666 12.0007C22.666 11.6325 22.3675 11.334 21.9993 11.334H17.9993C17.6312 11.334 17.3327 11.6325 17.3327 12.0007C17.3327 12.3688 17.6312 12.6673 17.9993 12.6673Z"
      fill="currentColor"
    />
    <path
      opacity="0.416667"
      d="M16.8602 15.5782L20.3243 17.5782C20.6432 17.7623 21.0509 17.653 21.235 17.3342C21.4191 17.0153 21.3099 16.6076 20.991 16.4235L17.5269 14.4235C17.208 14.2394 16.8003 14.3487 16.6162 14.6675C16.4321 14.9864 16.5414 15.3941 16.8602 15.5782Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      d="M14.4202 17.5305L16.4202 20.9946C16.6043 21.3135 17.012 21.4227 17.3309 21.2386C17.6497 21.0545 17.759 20.6468 17.5749 20.3279L15.5749 16.8638C15.3908 16.545 14.983 16.4357 14.6642 16.6198C14.3453 16.8039 14.2361 17.2116 14.4202 17.5305Z"
      fill="currentColor"
    />
    <path
      opacity="0.583334"
      d="M11.3327 18.0003V22.0003C11.3327 22.3685 11.6312 22.667 11.9993 22.667C12.3675 22.667 12.666 22.3685 12.666 22.0003V18.0003C12.666 17.6321 12.3675 17.3337 11.9993 17.3337C11.6312 17.3337 11.3327 17.6321 11.3327 18.0003Z"
      fill="currentColor"
    />
    <path
      opacity="0.666667"
      d="M8.41887 16.8641L6.41887 20.3282C6.23477 20.6471 6.34402 21.0548 6.66288 21.2389C6.98175 21.423 7.38947 21.3138 7.57357 20.9949L9.57357 17.5308C9.75766 17.212 9.64841 16.8042 9.32955 16.6201C9.01069 16.436 8.60296 16.5453 8.41887 16.8641Z"
      fill="currentColor"
    />
    <path
      opacity="0.75"
      d="M6.47049 14.4231L3.00639 16.4231C2.68752 16.6072 2.57827 17.0149 2.76237 17.3338C2.94646 17.6526 3.35419 17.7619 3.67305 17.5778L7.13716 15.5778C7.45602 15.3937 7.56527 14.986 7.38117 14.6671C7.19708 14.3483 6.78935 14.239 6.47049 14.4231Z"
      fill="currentColor"
    />
    <path
      opacity="0.833334"
      d="M5.9987 11.3337H1.9987C1.63051 11.3337 1.33203 11.6321 1.33203 12.0003C1.33203 12.3685 1.63051 12.667 1.9987 12.667H5.9987C6.36689 12.667 6.66536 12.3685 6.66536 12.0003C6.66536 11.6321 6.36689 11.3337 5.9987 11.3337Z"
      fill="currentColor"
    />
    <path
      opacity="0.916667"
      d="M7.13585 8.42277L3.67175 6.42277C3.35289 6.23868 2.94516 6.34793 2.76107 6.66679C2.57697 6.98565 2.68622 7.39338 3.00509 7.57747L6.46919 9.57747C6.78805 9.76157 7.19578 9.65232 7.37987 9.33346C7.56396 9.0146 7.45471 8.60687 7.13585 8.42277Z"
      fill="currentColor"
    />
    <path
      d="M9.57788 6.47049L7.57788 3.00639C7.39378 2.68752 6.98606 2.57827 6.66719 2.76237C6.34833 2.94646 6.23908 3.35419 6.42318 3.67305L8.42318 7.13716C8.60727 7.45602 9.015 7.56527 9.33386 7.38117C9.65272 7.19708 9.76197 6.78935 9.57788 6.47049Z"
      fill="currentColor"
    />
  </svg>
);

export default Spinner;
