import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { StyleableComponent } from 'types';

import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import styles from './OpenNewTabCore.module.scss';

export interface OpenNewTabCoreProps extends StyleableComponent {
  href: string;
}

const OpenNewTabCore: React.FC<OpenNewTabCoreProps & PropsWithChildren> = ({ href, className, children }) => (
  <a
    href={href}
    className={classNames(styles.root, className)}
    target="_blank"
    rel="noreferrer"
  >
    <span>{children}</span>
    <IconCommon
      name={IconCommonNames.NEW_TAB}
      size={IconCommonSizes.TINY}
    />
  </a>
);

export default OpenNewTabCore;
