import classNames from 'classnames';
import React from 'react';

import { testGetTestingAttributes } from '@testing';
import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { IconButtonProps } from '../../types';

import styles from './IconButton.module.scss';

const IconButton: React.FC<IconButtonProps> = ({
  iconName = IconCommonNames.CALL,
  iconSize = IconCommonSizes.DEFAULT,
  isDisabled = false,
  propTestId = '',
  className = '',
  type = 'button',
  onClick = () => undefined,
}) => (
  <button
    type={type}
    className={classNames(
      styles.root,
      {
        [styles.disabled]: isDisabled,
      },
      className,
    )}
    onClick={onClick}
    disabled={isDisabled}
    {...testGetTestingAttributes(propTestId)}
  >
    <IconCommon
      className={classNames(styles.icon)}
      size={iconSize}
      name={iconName}
    />
  </button>
);

export default IconButton;
