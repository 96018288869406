export const SIP = {
  CONFIGURATIONS: {
    REQUEST: {
      HEADER_TRANSACTION_ID_KEY: 'X-onoff-uuid',
      HEADER_ONOFF_CONTACT: 'X-Onoff-Contact',
      HEADER_CALL_ID: 'X-CallId',
      HEADER_INSTANCE_ID: 'X-InstanceId',
    },
    DEBUG_MODE: {
      NAMESPACES: 'JsSIP:*',
    },
    AUDIO_MANAGEMENT: {
      ELEMENT_WRAPPER_ID: 'SIPAudioWrapper',
      ELEMENT_AUDIO_ID_PREFIX: 'SIPAudio-',
      /**
       * "partially" => Artificial ringback tone will be used partially between SIP/180 to SIP/183
       * Upstream Provider ringback tone will be used between SIP/183 to SIP/200
       * But of course, this is creating an audio tone inconsistency
       *
       * "fully" => Only artificial ringback tone will be used between SIP/180 to SIP/200
       * Upstream Provider also sending ringback tone between SIP/183 to SIP/200
       * This part will be muted on the client-side with this config
       */
      CALLS_OUTGOING_ARTIFICIAL_RINGBACK_TYPE: 'partially' as 'partially' | 'fully',
    },
    USER_AGENT_PREFIX: 'Onoff_SIP_WebApp_',
    /**
     * MEDIA CONSTRAINTS
     * https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
     * We have Default and Advanced Media Constraints
     * We are applying the Advanced Ones by Settings/Preferences/Calling
     */
    MEDIA_CONSTRAINTS: {
      audio: true,
      video: false,
    } as MediaStreamConstraints,
    MEDIA_CONSTRAINTS_ADVANCED: {
      audio: {
        echoCancellation: {
          exact: false,
          ideal: true,
        },
        noiseSuppression: {
          exact: false,
          ideal: true,
        },
        channelCount: {
          exact: 1,
          ideal: 2,
          min: 1,
          max: 2,
        },
      } as MediaTrackConstraints,
      video: false,
    } as MediaStreamConstraints,
    /**
     * If the first WSS failed, we will try another one.
     * We can also try to the same server more than 1 if we want,
     * But, we are trying each only once.
     * (we tried as 2 by each and the results are bad)
     */
    WSS_ALLOWED_CONNECT_ATTEMPT_COUNT: 1,
    /**
     * Allowed Sessions Limit is => If we have "N" active calls,
     * the "N + 1" Call will be declined by the WebRTC behind the scene
     * The current limit is "2"
     */
    WEBRTC_ALLOWED_SESSIONS_LIMIT: 2,
    /**
     * Active Outgoing Session Limit is => If we have "N" active calls,
     * the "N + 1" Outgoing Call attempt will be prevented by Outgoing Calls Hook
     * The current limit is "1", We don't have Server Support
     * for making 2 or more outgoing calls at the same time.
     */
    WEBRTC_ACTIVE_OUTGOING_SESSION_LIMIT: 1,
    /**
     * Indicate if JsSIP User Agent should register automatically when starting.
     * Valid values are true and false (Boolean). Default value is "true".
     */
    REGISTER: true as boolean,
    /**
     * If set to true every SIP initial request sent by JsSIP includes a Route header
     * with the SIP URI associated to the WebSocket server as value.
     * Some SIP Outbound Proxies require such a header. Valid values are true and false (Boolean).
     * Default value is "false".
     */
    USE_PRELOADED_ROUTE: true as boolean,
    /**
     * "linphone, google, and so on..." is a STUN Server that for the JsSIP itself to understand
     * the public IP Address of the clients/users
     */
    STUN_SERVER_FALLBACK_URL: 'stun:stun.l.google.com:19302',
    /**
     * Minimum interval (Number) in seconds between WebSocket reconnection attempts.
     * Default value is "2".
     */
    CONNECTION_RECOVERY_MIN_INTERVAL: 4,
    /**
     * Maximum interval (Number) in seconds between WebSocket reconnection attempts.
     * Default value is "30".
     */
    CONNECTION_RECOVERY_MAX_INTERVAL: 30,
    /**
     * Time (in seconds) (Integer) after which an incoming call is rejected if not answered.
     * Default value is "60".
     */
    NO_ANSWER_TIMEOUT: 60,
    /**
     * Registration expiry time (in seconds) (Integer). Default value is "600".
     *
     * No matter the value that we are setting, ONOFF VoIP will override this value.
     * The current value is 120 seconds on the VoIP Servers (Last check: 29 May 2023).
     */
    REGISTER_EXPIRES: 600,
    /**
     * Enable Session Timers (as per RFC 4028). Default value is "true".
     */
    SESSION_TIMERS: true as boolean,
    /**
     * SIP method (String) to use for Session Timers refresh.
     * Valid values are "update" or "invite". Default value is "update".
     */
    SESSION_TIMERS_REFRESH_METHOD: 'invite' as 'invite' | 'update',
  },
  TIMEOUTS: {
    WSS_CONNECTION_TIMEOUT_MS: 5000,
    UNHANDLED_RTC_SESSION_MS: 10000,
    ICE_CANDIDATE_GATHERING_MS: 5000,
    PLAY_AUDIO_CHECK_MS: 10000,
  },
  CALL_FEATURES: {
    MUTE_ENABLED: true as boolean,
    HOLD_ENABLED: true as boolean,
    DTMF_ENABLED: true as boolean,
  },
  CALL_FEATURES_BROWSER_RESTRICTIONS: {
    CALL_HOLD: ['firefox'] as string[],
  },
  CALL_MOS: {
    ENABLED: true as boolean,
    CALCULATION_INTERVAL_MILLISECOND: 999,
    CALCULATION_MINIMUM_SAMPLE_COUNT: 5,
    SCORE_MAX: 4.5,
    FORMULA: {
      R_VALUE_CONSTANT_CODEC_DEFAULT: 93.2,
      R_VALUE_CONSTANT_CODEC_PCMA: 95,
    },
  },
} as const;
