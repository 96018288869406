import React, { PropsWithChildren } from 'react';

import styles from './SubHeaderItems.module.scss';

const SubHeaderItems: React.FC<PropsWithChildren> = ({ children }) => (
  <>
    <ul className={styles.root}>{children}</ul>
    <span className={styles.shadow}></span>
  </>
);

export default SubHeaderItems;
