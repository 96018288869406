import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { testIds } from '@testing';
import { IconCommonNames } from 'components/Icon';
import { ModalsExportContact } from 'components/Modals';

import InfoTopBarActionsDropdownItem from '../InfoTopBarActionsDropdownItem';

const InfoTopBarActionsDropdownFeaturesCRMExport: React.FC = () => {
  const intl = useIntl();

  const [stateModalContactCRMExportVisible, setStateModalContactCRMExportVisible] = useState<boolean>(false);

  const onClickHandlerContactsCRMExport = (): void => {
    setStateModalContactCRMExportVisible(!stateModalContactCRMExportVisible);
  };

  return (
    <>
      <InfoTopBarActionsDropdownItem
        iconName={IconCommonNames.DEVICE_HUB}
        label={intl.formatMessage({ id: 'InfoTopBar.Actions.Dropdown.button_contact_export_crm' })}
        onClick={onClickHandlerContactsCRMExport}
        propTestId={testIds.infoTopBarActionsDropdownButtonContactsCRM}
      />
      <ModalsExportContact
        isOpen={stateModalContactCRMExportVisible}
        onClose={onClickHandlerContactsCRMExport}
      />
    </>
  );
};

export default InfoTopBarActionsDropdownFeaturesCRMExport;
