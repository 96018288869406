import React from 'react';

import { StyleableComponent } from 'types';

const Success: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      opacity="0.1"
      cx="101"
      cy="100"
      r="58"
      fill="currentColor"
    />
    <circle
      opacity="0.1"
      cx="101"
      cy="100"
      r="47"
      fill="currentColor"
    />
    <circle
      cx="101"
      cy="99.9997"
      r="33.9997"
      fill="currentColor"
    />
    <path
      d="M94.3231 107.263L87.3648 100.305C86.5895 99.5298 85.3569 99.5298 84.5815 100.305C83.8062 101.081 83.8062 102.313 84.5815 103.088L92.9115 111.418C93.6869 112.194 94.9394 112.194 95.7147 111.418L116.788 90.3648C117.564 89.5895 117.564 88.3569 116.788 87.5815C116.013 86.8062 114.78 86.8062 114.005 87.5815L94.3231 107.263Z"
      fill="white"
    />
    <path
      d="M137.927 14L139.199 20.5834C139.794 23.6578 142.197 26.0611 145.272 26.6555L151.855 27.9275L145.272 29.1994C142.197 29.7938 139.794 32.1971 139.199 35.2715L137.927 41.8549L136.656 35.2715C136.061 32.1971 133.658 29.7938 130.583 29.1994L124 27.9275L130.583 26.6555C133.658 26.0611 136.061 23.6578 136.656 20.5834L137.927 14Z"
      fill="currentColor"
    />
    <path
      d="M51.4597 37.6353L52.3226 42.1094C52.7252 44.1931 54.3615 45.8294 56.4452 46.2321L60.9194 47.0949L56.4452 47.9578C54.3551 48.3605 52.7252 49.9968 52.3226 52.0805L51.4597 56.5546L50.5968 52.0805C50.1941 49.9968 48.5579 48.3605 46.4742 47.9578L42 47.0949L46.4742 46.2321C48.5579 45.8294 50.1941 44.1931 50.5968 42.1094L51.4597 37.6353Z"
      fill="currentColor"
    />
    <path
      d="M161.46 87L162.323 91.4742C162.725 93.5579 164.362 95.1941 166.445 95.5968L170.919 96.4597L166.445 97.3226C164.355 97.7252 162.725 99.3615 162.323 101.445L161.46 105.919L160.597 101.445C160.194 99.3615 158.558 97.7252 156.474 97.3226L152 96.4597L156.474 95.5968C158.564 95.1941 160.194 93.5579 160.597 91.4742L161.46 87Z"
      fill="currentColor"
    />
    <path
      d="M49.9065 141.992L50.5393 145.258C50.8333 146.78 52.0221 147.975 53.5497 148.269L56.8159 148.902L53.5497 149.534C52.0285 149.828 50.8333 151.017 50.5393 152.545L49.9065 155.811L49.2737 152.545C48.9797 151.024 47.7908 149.828 46.2632 149.534L42.9971 148.902L46.2632 148.269C47.7844 147.975 48.9797 146.786 49.2737 145.258L49.9065 141.992Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2793 72.7944L13.6547 70.4106L11 69.2793L13.3838 67.654L14.5151 65L16.1397 67.3844L18.7944 68.5151L16.4106 70.1397L15.2793 72.7944Z"
      fill="white"
    />
  </svg>
);

export default Success;
