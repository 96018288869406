import { createSelector } from '@reduxjs/toolkit';

import { Status } from 'types';

import {
  SelectSIPState,
  SIPDataCallStatusesCallInitialising,
  SIPDataCallStatusesFeatureDTMF,
  SIPDataCallStatusesFeatureHold,
  SIPDataCallStatusesFeatureMute,
  SIPDataCallStatusesFeatureUnHold,
  SIPDataCallStatusesFeatureUnMute,
  SIPDataCallStatusesRingtone,
  SIPDataCallStatusesView,
  SIPDataCallStatusesWebRTC,
  SIPStatuses,
  SIPWebRTCOriginators,
} from '../../types';

export const selectSIPState: SelectSIPState = (state) => state.sip;

export const selectSIPStatuses = createSelector([selectSIPState], (sipState) => sipState.statuses);

export const selectSIPDataStartCall = createSelector([selectSIPState], (sipState) => ({
  dataSIPStartCall: sipState.dataSIPStartCall,
}));

export const selectSIPDataConnect = createSelector([selectSIPState], (sipState) => ({
  dataSIPConnect: sipState.dataSIPConnect,
}));

export const selectSIPDataConfig = createSelector([selectSIPState], (sipState) => ({
  dataSIPConfig: sipState.dataSIPConfig,
}));

export const selectSIPDataWss = createSelector([selectSIPState], (sipState) => sipState.dataSIPWss);

export const selectSIPDataWssRecords = createSelector([selectSIPDataWss], (sipStateDataWss) => ({
  dataSIPWssRecords: sipStateDataWss.records,
}));

export const selectSIPDataWssAvailableUrlOrEmpty = createSelector([selectSIPDataWss], (sipStateDataWss) => ({
  dataSIPWssAvailableUrlOrEmpty: sipStateDataWss.availableUrlOrEmpty,
}));

export const selectSIPDataStun = createSelector([selectSIPState], (sipState) => sipState.dataSIPStun);

export const selectSIPDataStunRecords = createSelector([selectSIPDataStun], (sipStateDataStun) => ({
  dataSIPStunRecords: sipStateDataStun.records,
}));

export const selectSIPDataCalls = createSelector([selectSIPState], (sipState) => ({
  dataSIPCalls: sipState.dataCalls,
}));

export const selectSIPDataCallsByActiveAndWaiting = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => ({
  dataSIPCallsByActiveAndWaiting: dataSIPCalls.filter(({ statuses: { webRTC } }) =>
    [SIPDataCallStatusesWebRTC.ACTIVE, SIPDataCallStatusesWebRTC.WAITING].includes(webRTC),
  ),
}));

export const selectSIPDataCallsByLocalAndRinging = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => ({
  dataSIPCallsByLocalAndRinging: dataSIPCalls.filter(
    ({ originator, statuses }) =>
      originator === SIPWebRTCOriginators.LOCAL &&
      [SIPDataCallStatusesWebRTC.RINGING_180, SIPDataCallStatusesWebRTC.RINGING_183].includes(statuses.webRTC),
  ),
}));

export const selectSIPDataCallsByVisibility = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => {
  const statusesWebRTCForVisibility = [
    SIPDataCallStatusesWebRTC.PUSH_OUTGOING,
    SIPDataCallStatusesWebRTC.RINGING_180,
    SIPDataCallStatusesWebRTC.RINGING_183,
    SIPDataCallStatusesWebRTC.ANSWER,
    SIPDataCallStatusesWebRTC.ANSWERED,
    SIPDataCallStatusesWebRTC.ACTIVE,
    SIPDataCallStatusesWebRTC.WAITING,
    SIPDataCallStatusesWebRTC.TERMINATE,
  ];
  const statusesViewForVisibility = [SIPDataCallStatusesView.COMPLETED];
  const dataSIPCallsByVisibility = dataSIPCalls.filter(
    ({
      statuses: { callInitialising: statusCallInitializing, webRTC: statusWebRTC, view: statusView },
      timestamps: { callAnswered: timestampCallAnswered },
    }) =>
      statusesWebRTCForVisibility.includes(statusWebRTC) ||
      statusCallInitializing === SIPDataCallStatusesCallInitialising.INITIALISING ||
      (statusWebRTC === SIPDataCallStatusesWebRTC.TERMINATED &&
        timestampCallAnswered > 0 &&
        statusesViewForVisibility.includes(statusView)),
  );

  return {
    dataSIPCallsByVisibility,
  };
});

export const selectSIPDataCallByStatusWebRTCAnswer = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => {
  const {
    ids: { webRTC: idWebRTCForAnswer = '', transaction: idTransactionForAnswer = '' } = {},
    originator: originatorForAnswer,
  } = dataSIPCalls.find(({ statuses }) => statuses.webRTC === SIPDataCallStatusesWebRTC.ANSWER) || {};

  return {
    idWebRTCForAnswer,
    idTransactionForAnswer,
    originatorForAnswer,
  };
});

export const selectSIPDataCallByStatusWebRTCTerminate = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => {
  const { ids: { webRTC: idWebRTCForTerminate = '' } = {} } =
    dataSIPCalls.find(({ statuses }) => statuses.webRTC === SIPDataCallStatusesWebRTC.TERMINATE) || {};

  return {
    idWebRTCForTerminate,
  };
});

export const selectSIPDataCallByStatusWebRTCReject = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => {
  const { ids: { webRTC: idWebRTCForReject = '' } = {} } =
    dataSIPCalls.find(({ statuses }) => statuses.webRTC === SIPDataCallStatusesWebRTC.REJECT) || {};

  return {
    idWebRTCForReject,
  };
});

export const selectSIPDataCallByStatusFeatureMute = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => {
  const {
    ids: { webRTC: idWebRTCForMute = '', transaction: idTransactionForMute = '' } = {},
    originator: originatorForMute,
  } = dataSIPCalls.find(({ statuses }) => statuses.featureMute === SIPDataCallStatusesFeatureMute.MUTE) || {};

  return {
    idWebRTCForMute,
    idTransactionForMute,
    originatorForMute,
  };
});

export const selectSIPDataCallByStatusFeatureUnMute = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => {
  const {
    ids: { webRTC: idWebRTCForUnMute = '', transaction: idTransactionForUnMute = '' } = {},
    originator: originatorForUnMute,
  } = dataSIPCalls.find(({ statuses }) => statuses.featureUnMute === SIPDataCallStatusesFeatureUnMute.UNMUTE) || {};

  return {
    idWebRTCForUnMute,
    idTransactionForUnMute,
    originatorForUnMute,
  };
});

export const selectSIPDataCallByStatusFeatureHold = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => {
  const {
    ids: { webRTC: idWebRTCForHold = '', transaction: idTransactionForHold = '' } = {},
    originator: originatorForHold,
  } = dataSIPCalls.find(({ statuses }) => statuses.featureHold === SIPDataCallStatusesFeatureHold.HOLD) || {};

  return {
    idWebRTCForHold,
    idTransactionForHold,
    originatorForHold,
  };
});

export const selectSIPDataCallByStatusFeatureUnHold = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => {
  const {
    ids: { webRTC: idWebRTCForUnHold = '', transaction: idTransactionForUnHold = '' } = {},
    originator: originatorForUnHold,
  } = dataSIPCalls.find(({ statuses }) => statuses.featureUnHold === SIPDataCallStatusesFeatureUnHold.UNHOLD) || {};

  return {
    idWebRTCForUnHold,
    idTransactionForUnHold,
    originatorForUnHold,
  };
});

export const selectSIPDataCallByStatusFeatureDTMFSend = createSelector([selectSIPDataCalls], ({ dataSIPCalls }) => {
  const {
    ids: { webRTC: idWebRTCForDTMF = '', transaction: idTransactionForDTMF = '' } = {},
    originator: originatorForDTMF,
    values: { webRTCDTMF: valueWebRTCForDTMF = '' } = {},
  } = dataSIPCalls.find(({ statuses }) => statuses.featureDTMF === SIPDataCallStatusesFeatureDTMF.SEND) || {};

  return {
    idWebRTCForDTMF,
    idTransactionForDTMF,
    originatorForDTMF,
    valueWebRTCForDTMF,
  };
});

export const selectSIPDataCallsRingtoneAvailability = createSelector(
  [selectSIPDataCallsByVisibility],
  ({ dataSIPCallsByVisibility }) => {
    const hasActiveCalls = dataSIPCallsByVisibility.some(
      ({ statuses: { webRTC } }) => webRTC === SIPDataCallStatusesWebRTC.ACTIVE,
    );

    const hasIncomingCallsAndTheyAreRinging = dataSIPCallsByVisibility.filter(
      ({ statuses: { webRTC }, originator }) =>
        originator === SIPWebRTCOriginators.REMOTE &&
        [SIPDataCallStatusesWebRTC.RINGING_180, SIPDataCallStatusesWebRTC.RINGING_183].includes(webRTC),
    );

    const canRingtonePlay =
      !hasActiveCalls &&
      hasIncomingCallsAndTheyAreRinging.length > 0 &&
      hasIncomingCallsAndTheyAreRinging.every(
        ({ statuses: { ringtone } }) => ringtone !== SIPDataCallStatusesRingtone.MUTE,
      );

    return { canRingtonePlay };
  },
);

export const selectSIPDataCallsOutgoingCapabilities = createSelector(
  [selectSIPStatuses, selectSIPDataCalls],
  ({ statusInitializeCall, statusSIP }, { dataSIPCalls }) => {
    const canUserMakeCallByAPI = ![statusInitializeCall].includes(Status.LOADING);
    const canUserMakeCallBySIPUserAgent = [SIPStatuses.IDLE, SIPStatuses.REGISTERED, SIPStatuses.DISCONNECTED].includes(
      statusSIP,
    );

    const canUserMakeCallByWebRTCStatus = dataSIPCalls.every(
      ({ statuses: { webRTC } }) =>
        ![
          SIPDataCallStatusesWebRTC.PUSH_INCOMING,
          SIPDataCallStatusesWebRTC.PUSH_OUTGOING,
          SIPDataCallStatusesWebRTC.RINGING_180,
          SIPDataCallStatusesWebRTC.RINGING_183,
          SIPDataCallStatusesWebRTC.ANSWER,
          SIPDataCallStatusesWebRTC.ANSWERED,
        ].includes(webRTC),
    );

    const canUserMakeCallsBySIPStates =
      canUserMakeCallByAPI && canUserMakeCallBySIPUserAgent && canUserMakeCallByWebRTCStatus;

    return { canUserMakeCallsBySIPStates };
  },
);

export const selectSIPDataCallsIncomingCallPubNubPushCapabilities = createSelector(
  [selectSIPStatuses, selectSIPDataCalls],
  ({ statusInitializeCall, statusSIP }, { dataSIPCalls }) => {
    const canHandlePushByAPI = statusInitializeCall !== Status.LOADING;

    const canHandlePushBySIPUserAgent = ![
      SIPStatuses.FAILED,
      SIPStatuses.FAILED_ALL,
      SIPStatuses.TRY_ANOTHER_WSS,
      SIPStatuses.TRYING_ANOTHER_WSS,
      SIPStatuses.CONNECTING,
    ].includes(statusSIP);

    const canHandlePushByCallStatus = dataSIPCalls.every(
      ({ statuses: { webRTC, callInitialising } }) =>
        ![SIPDataCallStatusesWebRTC.PUSH_INCOMING, SIPDataCallStatusesWebRTC.PUSH_OUTGOING].includes(webRTC) &&
        callInitialising !== 'initialising',
    );

    const canHandleIncomingCallPubNubPush =
      canHandlePushByAPI && canHandlePushBySIPUserAgent && canHandlePushByCallStatus;

    return { canHandleIncomingCallPubNubPush };
  },
);

export const selectSIPDataCallsCapabilities = createSelector(
  [selectSIPState],
  (sipState) => sipState.dataCallsCapabilities,
);
