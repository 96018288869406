export const API_ERRORS = {
  HTTP: {
    UNKNOWN_ERROR: 'unknown_error',
    UNAUTHORIZED: 'unauthorized',
    REQUEST_FORBIDDEN: 'request_forbidden',
  },

  GENERAL: {
    EMAIL_TAKEN: 'email.taken',
    EMAIL_INVALID: 'email.invalid',
    CREDENTIALS_INVALID: 'credentials.invalid',
    VAT_NUMBER_INVALID: 'vatNumber.invalid',
    CHECKOUT_CHARGE_FAILED: 'checkout.charge-failed',
    NO_FREE_NUMBERS_FOR_COUNTRY: 'no-free-numbers-for-country',
    LOGIN_TOO_MANY: 'login.too-many-attempts',
    RECAPTCHA_INVALID: 'recaptcha.invalid',
    PLAN_NOT_READY: 'plan.not-ready',
    REQUEST_INVALID: 'request.invalid',
    INTERNAL_ERROR: 'internal.error',
    PLAN_USED_IN_GREETINGS: 'plan.used-in-greetings',
    EMPLOYEE_USED_IN_GREETINGS: 'employee.used-in-greetings',
    CALL_IN_PROGRESS: 'call-in-progress',
    MS_INTEGRATION_ALREADY_EXISTS: 'ms-integration.already-exists',
    OUT_OF_USAGE: 'b2b.out-of-usage',
    REQUEST_ABORTED: 'request-aborted'
  },

  USER: {
    USER_BLOCKED: 'user-blocked',
    USER_BLOCKED_INVALID_PASSWORD: 'user-blocked-invalid-password',
    USER_VALIDATION_INVALID: 'validation.invalid',
    USER_B2C_LOGIN_B2B: 'regular-user-with-b2b-client-login-attempt',
    USER_B2B_LOGIN_B2C: 'b2b-user-with-regular-client-login-attempt',
  },

  PAYMENTS: {
    PAYMENT_METHOD_EXPIRED: 'payment-method-expired',
    PAYMENT_PENDING: 'payment-pending',
    PAYMENT_DECLINED: 'payment-declined',
    PAYMENT_FAILED: 'payment-failed',
  },

  CALL_QUALITY_SURVEY: {
    CALL_QUALITY_SURVEY_EXPIRED: 'call-quality-survey.expired',
  },

  CALLING: {
    INVALID_CALLEE_NUMBER: 'invalid-callee-number',
  },

  EMAIL_UNSUBSCRIPTION: {
    USED_TOKEN: 'used-token',
    EXPIRED_TOKEN: 'expired-token',
  },

  CALL_TRANSFER: {
    B2B_ONLY: 'call-transfer.b2b-only',
    LOOP_DETECTED: 'call-transfer.loop-detected',
    ONGOING_CALL_NOT_FOUND: 'call-transfer.ongoing-call-not-found',
  },
} as const;

export const API_ERRORS_LIST = {
  ...API_ERRORS.HTTP,
  ...API_ERRORS.GENERAL,
  ...API_ERRORS.USER,
  ...API_ERRORS.PAYMENTS,
  ...API_ERRORS.CALL_QUALITY_SURVEY,
  ...API_ERRORS.CALLING,
};
