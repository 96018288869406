import { CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit';

import { WebAppState, ActionTypes, REDUX_ACTION_TYPES } from '../types';

import { authenticationReducer, authenticationInitialState } from './authentication';
import { balanceReducer, balanceInitialState } from './balance';
import { browserPermissionsReducer, browserPermissionsInitialState } from './browserPermissions';
import { browserTabsReducer, browserTabsInitialState } from './browserTabs';
import { callLogsReducer, callLogsInitialState } from './callLogs';
import { callQualitySurveyReducer, callQualitySurveyInitialState } from './callQualitySurvey';
import { categoriesReducer, categoriesInitialState } from './categories';
import { checkoutReducer, checkoutInitialState } from './checkout';
import { contactsReducer, contactsInitialState } from './contacts';
import { creditCardsReducer, creditCardsInitialState } from './creditCards';
import { integrationsReducer, integrationsInitialState } from './integrations';
import { messagesReducer, messagesInitialState } from './messages';
import { multiDeviceReducer, multiDeviceInitialState } from './multiDevice';
import { notificationsBrowserReducer, notificationsBrowserInitialState } from './notificationsBrowser';
import { notificationsModalReducer, notificationsModalInitialState } from './notificationsModal';
import { plansAndOffersReducer, plansAndOffersInitialState } from './plansAndOffers';
import { privacyReducer, privacyInitialState } from './privacy';
import { purchaseReducer, purchaseInitialState } from './purchase';
import { purchaseFlowReducer, purchaseFlowInitialState } from './purchaseFlow';
import { pushReducer, pushInitialState } from './push';
import { receiptsReducer, receiptsInitialState } from './receipts';
import { sipReducer, sipInitialState } from './sip';
import { stealthReducer, stealthInitialState } from './stealth';
import { threadsReducer, threadsInitialState } from './threads';
import { userReducer, userInitialState } from './user';
import { userGeoInfoReducer, userGeoInfoInitialState } from './userGeoInfo';
import { voicemailsReducer, voicemailsInitialState } from './voicemails';

export const initialState: WebAppState = {
  authentication: authenticationInitialState,
  balance: balanceInitialState,
  browserTabs: browserTabsInitialState,
  browserPermissions: browserPermissionsInitialState,
  callLogs: callLogsInitialState,
  callQualitySurvey: callQualitySurveyInitialState,
  categories: categoriesInitialState,
  checkout: checkoutInitialState,
  contacts: contactsInitialState,
  creditCards: creditCardsInitialState,
  integrations: integrationsInitialState,
  messages: messagesInitialState,
  multiDevice: multiDeviceInitialState,
  notificationsBrowser: notificationsBrowserInitialState,
  notificationsModal: notificationsModalInitialState,
  plansAndOffers: plansAndOffersInitialState,
  privacy: privacyInitialState,
  purchase: purchaseInitialState,
  purchaseFlow: purchaseFlowInitialState,
  push: pushInitialState,
  receipts: receiptsInitialState,
  sip: sipInitialState,
  stealth: stealthInitialState,
  threads: threadsInitialState,
  user: userInitialState,
  userGeoInfo: userGeoInfoInitialState,
  voicemails: voicemailsInitialState,
};

const mainReducer: Reducer<CombinedState<WebAppState>, ActionTypes> = combineReducers({
  authentication: authenticationReducer,
  balance: balanceReducer,
  browserTabs: browserTabsReducer,
  browserPermissions: browserPermissionsReducer,
  callLogs: callLogsReducer,
  callQualitySurvey: callQualitySurveyReducer,
  categories: categoriesReducer,
  checkout: checkoutReducer,
  contacts: contactsReducer,
  creditCards: creditCardsReducer,
  integrations: integrationsReducer,
  messages: messagesReducer,
  multiDevice: multiDeviceReducer,
  notificationsBrowser: notificationsBrowserReducer,
  notificationsModal: notificationsModalReducer,
  plansAndOffers: plansAndOffersReducer,
  privacy: privacyReducer,
  purchase: purchaseReducer,
  purchaseFlow: purchaseFlowReducer,
  push: pushReducer,
  receipts: receiptsReducer,
  sip: sipReducer,
  stealth: stealthReducer,
  threads: threadsReducer,
  user: userReducer,
  userGeoInfo: userGeoInfoReducer,
  voicemails: voicemailsReducer,
});

export const rootReducer: Reducer<WebAppState, ActionTypes> = (state, action) => {
  const reducerState: WebAppState = state ?? initialState;

  /**
   * @reference: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
   */
  if (action.type === REDUX_ACTION_TYPES.USER_RESET_STORE) {
    return mainReducer(undefined, action);
  }

  return mainReducer(reducerState, action);
};
