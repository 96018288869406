import { useCallback, useEffect } from 'react';

import { DataLayerVariables, GAEventKey, Status } from 'types';

import { sessionStoragePersistenceGetPaymentMethod } from 'helpers/paymentMethods';

import {
  useWebAppDispatch,
  useWebAppSelector,
  analyticsResendDataLayerVariables,
  selectAuthenticationStatuses,
} from '@redux';
import { analyticsService } from 'services';

interface UseCreditCardAnalyticsReturn {
  sendCreditCardAddSuccessEvent: () => void;
  sendCreditCardAddFailureEvent: (variables: Partial<DataLayerVariables>) => void;
}

export const useCreditCardAnalytics = (): UseCreditCardAnalyticsReturn => {
  const dispatch = useWebAppDispatch();
  const { statusLogin } = useWebAppSelector(selectAuthenticationStatuses);

  const isUserLoginLoading = [Status.IDLE, Status.LOADING].includes(statusLogin);

  useEffect(() => {
    if (!isUserLoginLoading) {
      dispatch(analyticsResendDataLayerVariables());
    }
  }, [isUserLoginLoading, dispatch]);

  const sendCreditCardAddSuccessEvent = useCallback((): void => {
    analyticsService.pushToDataLayer({
      event: GAEventKey.ADD_CREDIT_CARD_SUCCESS,
      variables: { payment_method: sessionStoragePersistenceGetPaymentMethod() },
    });
  }, []);

  const sendCreditCardAddFailureEvent = useCallback((variables): void => {
    analyticsService.pushToDataLayer({
      event: GAEventKey.ADD_CREDIT_CARD_FAILURE,
      variables: { payment_method: sessionStoragePersistenceGetPaymentMethod(), ...variables },
    });
  }, []);

  return {
    sendCreditCardAddSuccessEvent,
    sendCreditCardAddFailureEvent,
  };
};
