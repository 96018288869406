import {
  ComOnoffPurchaseControllerMobileResponseReceiptListResponse,
  ComOnoffPurchaseControllerMobileResponseReceiptListResponseCategory,
  ComOnoffPurchaseControllerMobileResponseReceiptListResponseReceipt,
  ComOnoffPurchaseControllerMobileResponseReceiptListResponseSubscription,
  ComOnoffPurchaseControllerMobileResponseReceiptListResponseUserOffer,
} from '../../server/purchase';

export enum PurchaseReceiptsListResponseSubscriptionAppStore {
  CREDIT_CARD = 'CREDIT_CARD',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

export type PurchaseReceiptsListResponseCategory = Required<
  Pick<
    ComOnoffPurchaseControllerMobileResponseReceiptListResponseCategory,
    'receipts' | 'subscription' | 'virtualPhoneNumber'
  >
>;

export type PurchaseReceiptsListResponseReceipt = ComOnoffPurchaseControllerMobileResponseReceiptListResponseReceipt;

export type PurchaseReceiptsListResponseSubscription =
  ComOnoffPurchaseControllerMobileResponseReceiptListResponseSubscription;

export type PurchaseReceiptsListResponseUserOffer = Omit<
  ComOnoffPurchaseControllerMobileResponseReceiptListResponseUserOffer,
  'userId'
>;

export type PurchaseReceiptsListResponseRaw = ComOnoffPurchaseControllerMobileResponseReceiptListResponse;

export type PurchaseReceiptsListResponse = {
  categories: PurchaseReceiptsListResponseCategory[];
  userOffer: PurchaseReceiptsListResponseUserOffer;
};
