import { sentryService } from 'services';

import { UsePubNubMessagesHandlerErrorsReturn } from '../../types';

export const usePubNubMessagesHandlerErrors = (): UsePubNubMessagesHandlerErrorsReturn => {
  const pubNubMessageHandlerErrors = (errorMessage: string): void => {
    sentryService.captureException({
      scope: {
        scopeKey: 'PubNub',
        scopeValue: 'Message Handler',
      },
      error: errorMessage,
    });
  };

  return {
    pubNubMessageHandlerErrors,
  };
};
