import classnames from 'classnames';
import React from 'react';

import { testGetTestingAttributes } from '@testing';
import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { ButtonSize, ButtonColorScheme } from '../../enums';
import { ButtonDashboardProps } from '../../types';

import styles from './ButtonDashboard.module.scss';

const ButtonDashboard: React.FC<ButtonDashboardProps> = ({
  colorScheme,
  onClick,
  text = '',
  iconName,
  propTestId = '',
  size = ButtonSize.DEFAULT,
  className = '',
  isDisabled = false,
  isLoading = false,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={classnames(
      styles.root,
      {
        [styles.isLarge]: size === ButtonSize.LARGE,
        [styles.isB2B]: colorScheme === ButtonColorScheme.B2B,
        [styles.hasText]: text.length > 0,
      },
      className,
    )}
    disabled={isLoading || isDisabled}
    {...testGetTestingAttributes(propTestId)}
  >
    <IconCommon
      name={isLoading ? IconCommonNames.SPINNER : iconName}
      size={IconCommonSizes.TINY}
    />
    {text.length > 0 ? <span className={styles.text}>{text}</span> : null}
  </button>
);

export default ButtonDashboard;
