import React from 'react';

import { Modal, ModalSize } from 'components/Modal';

import ModalsSelectCreditCardContent from '../ModalsSelectCreditCardContent';

import styles from './ModalsSelectCreditCard.module.scss';

export interface ModalsSelectCreditCardProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalsSelectCreditCard: React.FC<ModalsSelectCreditCardProps> = ({ isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    size={ModalSize.L}
    className={styles.root}
    onRequestClose={onClose}
  >
    <ModalsSelectCreditCardContent onClose={onClose} />
  </Modal>
);

export default ModalsSelectCreditCard;
