import { useRef } from 'react';

import { pubNubDebugConsoleLogger } from '../../helpers';
import { PubNubPushMessages, PubNubPushTypes, UsePubNubMessagesHandlerReturn } from '../../types';
import { usePubNubMessagesHandlerCallQualitySurvey } from '../usePubNubMessagesHandlerCallQualitySurvey';
import { usePubNubMessagesHandlerCategories } from '../usePubNubMessagesHandlerCategories';
import { usePubNubMessagesHandlerErrors } from '../usePubNubMessagesHandlerErrors';
import { usePubNubMessagesHandlerIntegrations } from '../usePubNubMessagesHandlerIntegrations';
import { usePubNubMessagesHandlerPageCalls } from '../usePubNubMessagesHandlerPageCalls';
import { usePubNubMessagesHandlerPageContacts } from '../usePubNubMessagesHandlerPageContacts';
import { usePubNubMessagesHandlerPageMessages } from '../usePubNubMessagesHandlerPageMessages';
import { usePubNubMessagesHandlerPageVoicemails } from '../usePubNubMessagesHandlerPageVoicemails';
import { usePubNubMessagesHandlerPushHistory } from '../usePubNubMessagesHandlerPushHistory';
import { usePubNubMessagesHandlerUser } from '../usePubNubMessagesHandlerUser';
import { usePubNubMessagesHandlerVoipCalls } from '../usePubNubMessagesHandlerVoipCalls';

export const usePubNubMessagesHandler = (): UsePubNubMessagesHandlerReturn => {
  const { pubNubMessageHandlerPushHistoryUpdate } = usePubNubMessagesHandlerPushHistory();
  const { pubNubMessageHandlerErrors } = usePubNubMessagesHandlerErrors();
  const {
    pubNubMessageHandlerCategoriesNewCategory,
    pubNubMessageHandlerCategoriesUpdateCategory,
    pubNubMessageHandlerCategoriesRemoveCategory,
  } = usePubNubMessagesHandlerCategories();
  const { pubNubMessageHandlerIntegrationsChangeStatus } = usePubNubMessagesHandlerIntegrations();
  const {
    pubNubMessageHandlerPageCallsNewCallLog,
    pubNubMessageHandlerPageCallsChangeCallLog,
    pubNubMessageHandlerPageCallsDeleteCallLog,
    pubNubMessageHandlerPageCallsReadCallLog,
  } = usePubNubMessagesHandlerPageCalls();
  const {
    pubNubMessageHandlerMessagesNewMessage,
    pubNubMessageHandlerMessagesCreateMessage,
    pubNubMessageHandlerMessagesChangeMessage,
    pubNubMessageHandlerMessagesDeleteMessage,
    pubNubMessageHandlerMessagesDeleteThread,
    pubNubMessageHandlerMessagesReadThread,
  } = usePubNubMessagesHandlerPageMessages();
  const {
    pubNubMessageHandlerPageVoicemailsNewVoicemail,
    pubNubMessageHandlerPageVoicemailsDeleteVoicemail,
    pubNubMessageHandlerPageVoicemailsReadVoicemail,
  } = usePubNubMessagesHandlerPageVoicemails();
  const {
    pubNubMessageHandlerPageContactsNewSimpleContact,
    pubNubMessageHandlerPageContactsUpdateSimpleContact,
    pubNubMessageHandlerPageContactsDeleteSimpleContact,
    pubNubMessageHandlerPageContactsDeleteAllSimpleContacts,
    pubNubMessageHandlerPageContactsSyncAllSimpleContacts,
  } = usePubNubMessagesHandlerPageContacts();
  const { pubNubMessageHandlerVoipCallCancelled, pubNubMessageHandlerVoipIncomingCall } =
    usePubNubMessagesHandlerVoipCalls();
  const {
    pubNubMessageHandlerUserChangeUser,
    pubNubMessageHandlerUserChangeUserAccountType,
    pubNubMessageHandlerUserForceLogout,
  } = usePubNubMessagesHandlerUser();

  const { pubNubMessageHandlerCallQualitySurveyOpenPopup } = usePubNubMessagesHandlerCallQualitySurvey();

  const previousPushId = useRef('');

  const handlePubNubMessages = ({ type, payload, uniqueId }: PubNubPushMessages): void => {
    /**
     * DEBUG MODE: LOG THE TYPE AND PAYLOAD
     */
    pubNubDebugConsoleLogger('PubNub Push: ', { type }, { payload }, { uniqueId });

    /**
     * DUPLICATE PUSH: IGNORE
     */
    if (previousPushId.current === uniqueId) {
      pubNubDebugConsoleLogger('Ignoring duplicate push', { type });
      return;
    }

    previousPushId.current = uniqueId;

    /**
     * ALL CASES: UPDATE PUSH HISTORY EXCEPT FORCE LOGOUT
     */
    if (type !== PubNubPushTypes.USER_PUSH_FORCE_LOG_OUT) {
      pubNubMessageHandlerPushHistoryUpdate();
    }

    switch (type) {
      /**
       * CATEGORIES
       */
      case PubNubPushTypes.CATEGORIES_PUSH_CATEGORY_NEW:
        pubNubMessageHandlerCategoriesNewCategory(payload);
        break;

      case PubNubPushTypes.CATEGORIES_PUSH_CATEGORY_UPDATE:
        pubNubMessageHandlerCategoriesUpdateCategory(payload);
        break;

      case PubNubPushTypes.CATEGORIES_PUSH_CATEGORY_DELETE:
        pubNubMessageHandlerCategoriesRemoveCategory(payload);
        break;

      /**
       * INTEGRATIONS
       */
      case PubNubPushTypes.INTEGRATIONS_PUSH_INTEGRATIONS_STATUS_CHANGE:
        pubNubMessageHandlerIntegrationsChangeStatus(payload);
        break;

      /**
       * PAGE: CALLS
       */
      case PubNubPushTypes.PAGE_CALLS_PUSH_CALLLOG_NEW:
        pubNubMessageHandlerPageCallsNewCallLog(payload);
        break;

      case PubNubPushTypes.PAGE_CALLS_PUSH_CALLLOG_CHANGE:
        pubNubMessageHandlerPageCallsChangeCallLog(payload);
        break;

      case PubNubPushTypes.PAGE_CALLS_PUSH_CALLLOG_DELETE:
        pubNubMessageHandlerPageCallsDeleteCallLog(payload);
        break;

      case PubNubPushTypes.PAGE_CALLS_PUSH_CALLLOG_READ:
        pubNubMessageHandlerPageCallsReadCallLog(payload);
        break;

      /**
       * PAGE: MESSAGES
       */
      case PubNubPushTypes.PAGE_MESSAGES_PUSH_MESSAGE_NEW:
        pubNubMessageHandlerMessagesNewMessage(payload);
        break;

      case PubNubPushTypes.PAGE_MESSAGES_PUSH_MESSAGE_CREATE:
        pubNubMessageHandlerMessagesCreateMessage(payload);
        break;

      case PubNubPushTypes.PAGE_MESSAGES_PUSH_MESSAGE_CHANGE:
        pubNubMessageHandlerMessagesChangeMessage(payload);
        break;

      case PubNubPushTypes.PAGE_MESSAGES_PUSH_MESSAGE_DELETE:
        pubNubMessageHandlerMessagesDeleteMessage(payload);
        break;

      case PubNubPushTypes.PAGE_MESSAGES_PUSH_MESSAGE_THREAD_DELETE:
        pubNubMessageHandlerMessagesDeleteThread(payload);
        break;

      case PubNubPushTypes.PAGE_MESSAGES_PUSH_MESSAGE_THREAD_READ:
        pubNubMessageHandlerMessagesReadThread(payload);
        break;

      /**
       * PAGE: VOICEMAILS
       */
      case PubNubPushTypes.PAGE_VOICEMAILS_PUSH_VOICEMAIL_NEW:
        pubNubMessageHandlerPageVoicemailsNewVoicemail(payload);
        break;

      case PubNubPushTypes.PAGE_VOICEMAILS_PUSH_VOICEMAIL_DELETE:
        pubNubMessageHandlerPageVoicemailsDeleteVoicemail(payload);
        break;

      case PubNubPushTypes.PAGE_VOICEMAILS_PUSH_VOICEMAIL_READ:
        pubNubMessageHandlerPageVoicemailsReadVoicemail(payload);
        break;

      /**
       * PAGE: CONTACTS
       */
      case PubNubPushTypes.PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_NEW:
        pubNubMessageHandlerPageContactsNewSimpleContact(payload);
        break;

      case PubNubPushTypes.PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_UPDATE:
        pubNubMessageHandlerPageContactsUpdateSimpleContact(payload);
        break;

      case PubNubPushTypes.PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_DELETE:
        pubNubMessageHandlerPageContactsDeleteSimpleContact(payload);
        break;

      case PubNubPushTypes.PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_DELETE_ALL:
        pubNubMessageHandlerPageContactsDeleteAllSimpleContacts(payload);
        break;

      case PubNubPushTypes.PAGE_CONTACTS_PUSH_SIMPLE_CONTACT_SYNC_ALL:
        pubNubMessageHandlerPageContactsSyncAllSimpleContacts(payload);
        break;

      case PubNubPushTypes.PAGE_CONTACTS_PUSH_PHONEBOOK_SYNC_OFF:
        pubNubMessageHandlerPageContactsSyncAllSimpleContacts(payload);
        break;

      /**
       * VOIP CALLS
       */
      case PubNubPushTypes.VOIP_CALLS_INCOMING_CALL:
        pubNubMessageHandlerVoipIncomingCall(payload);
        break;

      case PubNubPushTypes.VOIP_CALLS_CALL_CANCELLED:
        pubNubMessageHandlerVoipCallCancelled(payload);
        break;

      /**
       * USER
       */
      case PubNubPushTypes.USER_PUSH_USER_CHANGE:
        pubNubMessageHandlerUserChangeUser(payload);
        break;

      case PubNubPushTypes.USER_PUSH_USER_ACCOUNT_TYPE_CHANGE:
        pubNubMessageHandlerUserChangeUserAccountType(payload);
        break;

      case PubNubPushTypes.USER_PUSH_FORCE_LOG_OUT:
        pubNubMessageHandlerUserForceLogout(payload);
        break;

      /**
       * CALL QUALITY SURVEY
       */
      case PubNubPushTypes.CALL_QUALITY_SURVEY:
        pubNubMessageHandlerCallQualitySurveyOpenPopup(payload);
        break;

      /**
       * IGNORED FOR WEBAPP => SIP/VOIP CALLS
       */
      case PubNubPushTypes.WEBAPP_IGNORED_CALLING:
      case PubNubPushTypes.WEBAPP_IGNORED_CALLING_PICKEDUP:
      case PubNubPushTypes.WEBAPP_IGNORED_OFFLINE_CALLS_ENABLED:
      case PubNubPushTypes.WEBAPP_IGNORED_OFFLINE_CALLS_DISABLED:
      case PubNubPushTypes.WEBAPP_IGNORED_OFFLINE_CALLS_SYNC_TSANS:
      case PubNubPushTypes.WEBAPP_IGNORED_OFFLINE_CALLS_INCOMING_CALL_FROM_UNKNOWN_NUMBER:
        break;

      /**
       * IGNORED FOR WEBAPP => GENERAL
       */
      case PubNubPushTypes.WEBAPP_IGNORED_SYNC_PUSH_HISTORY:
      case PubNubPushTypes.WEBAPP_IGNORED_REQUEST_DEVICE_LOGS:
      case PubNubPushTypes.WEBAPP_IGNORED_VALIDATION_SUCCEED:
      case PubNubPushTypes.WEBAPP_IGNORED_VALIDATION_FAILED:
      case PubNubPushTypes.WEBAPP_IGNORED_PORT_IN:
      case PubNubPushTypes.WEBAPP_IGNORED_PACKAGES_SYNC:
      case PubNubPushTypes.WEBAPP_IGNORED_NOTIFICATION:
      case PubNubPushTypes.WEBAPP_IGNORED_ERROR_NOTIFICATION:
        break;

      /**
       * MISSING PUSHES, STILL, FOR THE FUTURE BACKEND UPDATES
       */
      default:
        pubNubMessageHandlerErrors(`Missing Push Type: "${type}"`);
        break;
    }
  };

  return {
    handlePubNubMessages,
    handlePubNubErrors: pubNubMessageHandlerErrors,
  };
};
