import React from 'react';
import { useIntl } from 'react-intl';

import { selectBrowserPermissionMicrophoneStatus, useWebAppSelector } from '@redux';
import { SIPDataCallStatusesWebRTC, SIPWebRTCOriginators } from '@redux/types';
import { testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { useSIPCallMethodsOngoing } from '../../../../hooks/useSIPCallMethods';
import { useSIPMediaPermission } from '../../../../hooks/useSIPMedia';
import { SIPCallsListItemButtonFeaturesAnswerProps } from '../../types';
import SIPCallsListItemButtonTooltip from '../SIPCallsListItemButtonTooltip';

import styles from './SIPCallsListItemButtonFeaturesAnswer.module.scss';

const SIPCallsListItemButtonFeaturesAnswer: React.FC<SIPCallsListItemButtonFeaturesAnswerProps> = ({
  isCallLocal,
  isCallOngoing,
  statusWebRTC,
  idWebRTC,
}) => {
  const intl = useIntl();
  const { sipCallAnswer } = useSIPCallMethodsOngoing();
  const { getSIPMediaPermission } = useSIPMediaPermission();

  const microphonePermission = useWebAppSelector(selectBrowserPermissionMicrophoneStatus);

  const isCallRinging = [SIPDataCallStatusesWebRTC.RINGING_180, SIPDataCallStatusesWebRTC.RINGING_183].includes(
    statusWebRTC,
  );
  const isFeatureNotAvailable = isCallLocal || isCallOngoing || !isCallRinging;
  if (isFeatureNotAvailable) {
    return null;
  }

  const onClickHandlerButtonAnswer = async () => {
    const { hasMediaPermission } = await getSIPMediaPermission({ originator: SIPWebRTCOriginators.REMOTE });

    if (!hasMediaPermission) {
      return;
    }
    sipCallAnswer({
      idWebRTC,
    });
  };

  return (
    <SIPCallsListItemButtonTooltip content={intl.formatMessage({ id: 'SIP.Calls.UI.ButtonTooltips.answer' })}>
      <IconButton
        className={styles.root}
        onClick={onClickHandlerButtonAnswer}
        iconName={IconCommonNames.ANSWER_CALL}
        iconSize={IconCommonSizes.TINY}
        isDisabled={microphonePermission === 'denied'}
        propTestId={testIds.sipCallsListItemButtonFeatureAnswerRoot}
      />
    </SIPCallsListItemButtonTooltip>
  );
};

export default SIPCallsListItemButtonFeaturesAnswer;
