import classNames from 'classnames';
import { forwardRef } from 'react';

import { testGetTestingAttributes } from '@testing';

import { InputCoreProps } from '../../types';

import styles from './InputCore.module.scss';

const InputCore = forwardRef<HTMLInputElement, InputCoreProps>(
  (
    {
      value,
      defaultValue,
      className = '',
      placeholder = '',
      isDisabled = false,
      type = 'text',
      maxLength = undefined,
      onChange = () => undefined,
      onBlur = () => undefined,
      onFocus = () => undefined,
      onMouseOver = () => undefined,
      onMouseLeave = () => undefined,
      propTestId = '',
      autoFocus = false,
      autoComplete = undefined,
    },
    ref,
  ) => (
    <input
      className={classNames(styles.root, className)}
      ref={ref}
      type={type}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      disabled={isDisabled}
      onChange={(event) => onChange(event.target.value)}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      maxLength={maxLength}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      {...testGetTestingAttributes(propTestId)}
    />
  ),
);

export default InputCore;
