import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { showWarningToast } from '@onoff/toast-notification';

import { SIP } from '@constants';
import {
  useWebAppDispatch,
  useWebAppSelector,
  sipPrepareOutgoingCallHandler,
  selectSIPDataCallsByActiveAndWaiting,
  selectSIPDataCallsByVisibility,
  selectSIPDataCallsOutgoingCapabilities,
  selectIsCurrentBrowserTabActive,
  selectBrowserPermissionMicrophoneStatus,
} from '@redux';
import { SIPStatisticsScopes, SIPWebRTCOriginators } from '@redux/types';
import { useUserCapabilities } from 'hooks';

import { checkSIPCallsDuplications, checkSIPCallsMaximumWebRTCSessions, sipDebugConsoleLogger } from '../../../helpers';
import { SIPCallMethodMakeCallProps, UseSIPCallMethodsOutgoingReturn } from '../../../types';
import { useSIPMediaPermission } from '../../useSIPMedia';
import { useSIPStatistics } from '../../useSIPStatistics';

export const useSIPCallMethodsOutgoing = (): UseSIPCallMethodsOutgoingReturn => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const { canUserMakeCallsBySIPStates } = useWebAppSelector(selectSIPDataCallsOutgoingCapabilities);
  const { dataSIPCallsByVisibility } = useWebAppSelector(selectSIPDataCallsByVisibility);
  const { dataSIPCallsByActiveAndWaiting } = useWebAppSelector(selectSIPDataCallsByActiveAndWaiting);
  const isActiveBrowserTab = useWebAppSelector(selectIsCurrentBrowserTabActive);
  const microphonePermission = useWebAppSelector(selectBrowserPermissionMicrophoneStatus);
  const microphonePermissionGranted = microphonePermission === 'granted';

  const { canUserMakeCalls: canUserMakeCallsByUser } = useUserCapabilities();
  const { getSIPMediaPermission } = useSIPMediaPermission();

  const { sipSendLogToGTM, sipSendLogToGTMCallInitOutgoing } = useSIPStatistics();

  const canUserMakeCallsBySIP =
    canUserMakeCallsByUser && canUserMakeCallsBySIPStates && isActiveBrowserTab && microphonePermissionGranted;

  const sipCallMake = useCallback(
    async ({
      phoneNumberLocal,
      phoneNumberRemote,
      analyticsCTA,
      analyticsHasContact,
    }: SIPCallMethodMakeCallProps): Promise<void> => {
      // The SIP is not available, return
      if (!canUserMakeCallsBySIP) {
        sipDebugConsoleLogger('SIP HOOKS: DEVELOPMENT ISSUE PREVENTION!');
        return;
      }

      // The SIP already has the exact same call, return
      const { hasDuplication } = checkSIPCallsDuplications({
        sipDataCalls: dataSIPCallsByVisibility,
        phoneNumberLocal,
        phoneNumberRemote,
      });
      if (hasDuplication) {
        sipDebugConsoleLogger('SIP HOOKS: CALL DUPLICATION!');

        showWarningToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.CallOutgoingDuplication.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.CallOutgoingDuplication.description' }),
        });

        return;
      }

      // The SIP already has maximum calls, return
      const { isMaximumAllowedWebRTCSessions } = checkSIPCallsMaximumWebRTCSessions({
        countActiveSessions: dataSIPCallsByActiveAndWaiting.length,
      });

      if (isMaximumAllowedWebRTCSessions) {
        sipDebugConsoleLogger('SIP HOOKS: MAXIMUM ALLOWED SESSIONS!');

        sipSendLogToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_HOOKS,
          sipCallOriginator: SIPWebRTCOriginators.LOCAL,
          sipCallTitle: 'MAXIMUM ALLOWED SESSIONS!',
          sipCallDescription: '',
          sipCallTransactionId: '',
        });

        showWarningToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.CallOutgoingMaximumSessions.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.CallOutgoingMaximumSessions.description' }),
        });

        return;
      }

      // The SIP already has more than allowed active/ongoing call, return
      const hasMoreThanAllowedOngoingCalls =
        dataSIPCallsByActiveAndWaiting.length >= SIP.CONFIGURATIONS.WEBRTC_ACTIVE_OUTGOING_SESSION_LIMIT;
      if (hasMoreThanAllowedOngoingCalls) {
        sipDebugConsoleLogger('SIP HOOKS: THE SECOND ACTIVE OUTGOING CALL!');

        showWarningToast({
          heading: intl.formatMessage({ id: 'SIP.Notifications.CallOutgoingSecondOne.title' }),
          message: intl.formatMessage({ id: 'SIP.Notifications.CallOutgoingSecondOne.description' }),
        });

        return;
      }

      // The SIP does not have media permission, return
      const { hasMediaPermission } = await getSIPMediaPermission({ originator: SIPWebRTCOriginators.LOCAL });

      if (!hasMediaPermission) {
        return;
      }

      // All fine, make the call
      await dispatch(
        sipPrepareOutgoingCallHandler({
          propPhoneNumberLocal: phoneNumberLocal,
          propPhoneNumberRemote: phoneNumberRemote,
        }),
      );

      sipSendLogToGTMCallInitOutgoing({
        propPhoneNumberLocal: phoneNumberLocal,
        propPhoneNumberRemote: phoneNumberRemote,
        propCTA: analyticsCTA,
        propHasContact: analyticsHasContact,
      });
    },
    [
      canUserMakeCallsBySIP,
      dataSIPCallsByVisibility,
      dataSIPCallsByActiveAndWaiting.length,
      getSIPMediaPermission,
      dispatch,
      sipSendLogToGTMCallInitOutgoing,
      intl,
      sipSendLogToGTM,
    ],
  );

  return useMemo(
    () => ({
      canUserMakeCallsBySIP,
      sipCallMake,
    }),
    [canUserMakeCallsBySIP, sipCallMake],
  );
};
