import { Reducer } from '@reduxjs/toolkit';

import { AccountType, Status, UserCallAnswerMethods } from 'types';

import { alphaSortByKey } from 'helpers';

import { SUPPORTED_LANGUAGES } from '@constants';

import { REDUX_ACTION_TYPES, UserReduxState, UserActionTypes } from '../../types';

export const userInitialState: UserReduxState = {
  statuses: {
    updateLanguage: Status.IDLE,
    updateName: Status.IDLE,
    updateEmail: Status.IDLE,
    updatePassword: Status.IDLE,
  },
  data: {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    kycStatus: {},
    nationalCountryIsoCode: 'FR',
    countryIsoCode: 'FR',
    communicationLocale: SUPPORTED_LANGUAGES.EN,
    callAnswerMethod: UserCallAnswerMethods.VOIP,
    callOutMethod: 'VOIP',
    onOffCallStatus: 'DISABLED',
    accountType: AccountType.NO_TYPE,
    b2bUser: false,
    enableOfflineCalls: false,
    hasPassword: true,
    offersUnsubscribe: true,
    submitReceiptOnLogin: true,
    validCelebrity: false,
    showEmergencyCallsWarning: false,
    supportPhone: '',
    features: {},
    callRecording: {
      acknowledgmentNeeded: false,
      enabled: false,
    },

    allowedCountries: {
      supported: [],
    },
    availableCountries: {
      supported: [],
    },
  },
  language: SUPPORTED_LANGUAGES.EN,
};

export const userReducer: Reducer<UserReduxState, UserActionTypes> = (state, action) => {
  const reducerState: UserReduxState = state || userInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.USER_SET:
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          ...action.user,
        },
      };

    case REDUX_ACTION_TYPES.USER_UPDATE:
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          ...action.user,
        },
      };

    case REDUX_ACTION_TYPES.USER_SET_ACCOUNT_TYPE_AND_FEATURES: {
      const { accountType, features } = action;

      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          accountType,
          features,
        },
      };
    }

    case REDUX_ACTION_TYPES.USER_SET_ALLOWED_COUNTRIES: {
      const allowedCountries = { ...action.countries };
      allowedCountries.supported = alphaSortByKey(allowedCountries.supported, 'name', false);

      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          allowedCountries,
        },
      };
    }

    case REDUX_ACTION_TYPES.USER_SET_AVAILABLE_COUNTRIES: {
      const availableCountries = { ...action.countries };
      availableCountries.supported = alphaSortByKey(availableCountries.supported, 'name', false);
      return {
        ...reducerState,
        data: {
          ...reducerState.data,
          availableCountries,
        },
      };
    }

    case REDUX_ACTION_TYPES.USER_SET_LANGUAGE: {
      const { language } = action;
      return { ...reducerState, language };
    }

    case REDUX_ACTION_TYPES.USER_SET_UPDATE_LANGUAGE_STATUS: {
      const { statuses } = reducerState;
      return {
        ...reducerState,
        statuses: {
          ...statuses,
          updateLanguage: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.USER_SET_UPDATE_NAME_STATUS: {
      const { statuses } = reducerState;
      return {
        ...reducerState,
        statuses: {
          ...statuses,
          updateName: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.USER_SET_UPDATE_EMAIL_STATUS: {
      const { statuses } = reducerState;
      return {
        ...reducerState,
        statuses: {
          ...statuses,
          updateEmail: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.USER_SET_UPDATE_PASSWORD_STATUS: {
      const { statuses } = reducerState;
      return {
        ...reducerState,
        statuses: {
          ...statuses,
          updatePassword: action.status,
        },
      };
    }

    default:
      return reducerState;
  }
};
