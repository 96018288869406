import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useRef } from 'react';

import { StyleableComponent } from 'types';

import InputCore from '../InputCore';

import styles from './InputCheckbox.module.scss';

interface InputCheckboxProps extends StyleableComponent {
  checked?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

const InputCheckbox: React.FC<InputCheckboxProps & PropsWithChildren> = ({
  checked = false,
  indeterminate = false,
  disabled = false,
  className = '',
  children = null,
  onChange = () => undefined,
}) => {
  const checkbox = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkbox && checkbox.current) {
      checkbox.current.indeterminate = indeterminate;
      checkbox.current.checked = checked;
    }
  }, [checked, indeterminate]);

  const handleChange = () => {
    if (checkbox.current) {
      onChange(checkbox.current.checked);
    }
  };

  return (
    <label className={classNames(styles.label, className)}>
      <InputCore
        type="checkbox"
        ref={checkbox}
        data-testid="checkbox"
        isDisabled={disabled}
        className={styles.checkbox}
        onChange={handleChange}
      />

      {children && <span>{children}</span>}
    </label>
  );
};

export default InputCheckbox;
