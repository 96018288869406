/**
 * The Core Dependency of the JsSIP Library
 */
import * as sdpTransform from 'sdp-transform';

import { authenticationHasAuth, getSIPBrowserRestriction, isNumber, isString } from 'helpers';

import { SIP } from '@constants';
import { SIPDataCallStatusesWebRTC, SIPDataCallValues, SIPWebRTCRTPAudioCodecs } from '@redux/types';

import {
  CheckSIPAvailabilityReturn,
  CheckSIPLogsModeReturn,
  CheckSIPDebugModeReturn,
  CheckSIPDTMFValueValidityReturn,
  CheckSIPDTMFSupportReturn,
  CheckSIPCallsDuplicationsProps,
  CheckSIPCallsDuplicationsReturn,
  CheckSIPCallsMaximumWebRTCSessionsProps,
  CheckSIPCallsMaximumWebRTCSessionsReturn,
  SipGetModifiedSDPForPCMACodecProps,
  SipGetModifiedSDPForPCMACodecReturn,
  SipAudioManagementAudioCreateAndAppendProps,
  SipAudioManagementAudioProps,
} from '../types';

export const checkSIPAvailability = (): CheckSIPAvailabilityReturn => ({
  isSIPAvailable: authenticationHasAuth(),
});

export const checkSIPLogsMode = (): CheckSIPLogsModeReturn => {
  const isSIPLogs = process.env.REACT_APP_SIP_LOGS === 'true';
  const { isSIPAvailable } = checkSIPAvailability();
  return {
    isSIPLogsMode: isSIPAvailable && isSIPLogs,
  };
};

export const checkSIPDebugMode = (): CheckSIPDebugModeReturn => {
  const isEnvDevOrStaging = ['development', 'staging'].includes(process.env.REACT_APP_ENV || '');
  const isSIPDebug = process.env.REACT_APP_SIP_DEBUG === 'true';
  const { isSIPAvailable } = checkSIPAvailability();
  return {
    isSIPDebugMode: isSIPAvailable && isEnvDevOrStaging && isSIPDebug,
  };
};

export const sipDebugConsoleLogger = (...args: unknown[]): void => {
  const { isSIPLogsMode } = checkSIPLogsMode();
  const { isSIPDebugMode } = checkSIPDebugMode();
  if (isSIPLogsMode || isSIPDebugMode) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export const checkSIPDTMFValueValidity = (value?: SIPDataCallValues['webRTCDTMF']): CheckSIPDTMFValueValidityReturn => {
  const isDTMFValueValid =
    (isString(value) && value.trim().length === 1) || (isNumber(value) && `${value}`.trim().length === 1);

  return {
    isDTMFValueValid,
  };
};

export const checkSIPDTMFSupport = (error: unknown): CheckSIPDTMFSupportReturn => {
  const isDTMFNotSupported = (error as Error | undefined)?.message?.includes('canInsertDTMF') === true;
  return {
    isDTMFNotSupported,
  };
};

export const checkSIPCallsDuplications = ({
  sipDataCalls,
  phoneNumberLocal,
  phoneNumberRemote,
}: CheckSIPCallsDuplicationsProps): CheckSIPCallsDuplicationsReturn => {
  const hasDuplication = sipDataCalls
    .filter(({ statuses: { webRTC } }) => webRTC !== SIPDataCallStatusesWebRTC.TERMINATED)
    .some(
      ({ phoneNumbers: { local, remote } }) =>
        (local === phoneNumberLocal && remote === phoneNumberRemote) ||
        (local === phoneNumberRemote && remote === phoneNumberLocal),
    );

  return { hasDuplication };
};

export const checkSIPCallsMaximumWebRTCSessions = ({
  countActiveSessions,
}: CheckSIPCallsMaximumWebRTCSessionsProps): CheckSIPCallsMaximumWebRTCSessionsReturn => {
  const countAllowedSessions = SIP.CONFIGURATIONS.WEBRTC_ALLOWED_SESSIONS_LIMIT;
  const isCallHoldingEnabled = SIP.CALL_FEATURES.HOLD_ENABLED && !getSIPBrowserRestriction({ feature: 'CALL_HOLD' });

  const isMaximumAllowedWebRTCSessions =
    (isCallHoldingEnabled === false && countActiveSessions > 0) || countActiveSessions === countAllowedSessions;

  return {
    isMaximumAllowedWebRTCSessions,
  };
};

export const sipStatisticsMaskPhoneNumber = (phoneNumber: string): string => {
  const visibleDigits = 4;
  const repeatCondition = phoneNumber.length - visibleDigits;
  const repeatCount = Math.max(0, repeatCondition);
  return `${phoneNumber.substring(0, visibleDigits)}${'*'.repeat(repeatCount)}`;
};

export const sipGetModifiedSDPForPCMACodec = ({
  sdpCurrent,
}: SipGetModifiedSDPForPCMACodecProps): SipGetModifiedSDPForPCMACodecReturn => {
  try {
    if (sdpCurrent.length < 1) {
      throw new Error('Dev Error');
    }

    /**
     * Parse the SDP String
     */
    const sdpCurrentAsParsed: sdpTransform.SessionDescription = sdpTransform.parse(sdpCurrent);

    /**
     * Create a New Media
     */
    const mediaNew: sdpTransform.SessionDescription['media'] = sdpCurrentAsParsed.media.map(
      (eachMedia: sdpTransform.SessionDescription['media'][0]) => {
        /**
         * WEBRTC-SDP-REF-01
         *
         * Prevents the fast call recording on VoIP if the codec PCMA
         * VoIP Team might need this modification in the future regarding
         * OPUS Codec Optimizations for Non-HD Calls
         */
        // const mediaNewFMTP: sdpTransform.MediaAttributes['fmtp'] = [
        //   /**
        //    * Modify the Config of the OPUS Codec Type
        //    * For the Config String, more answer can be found in VoIP Team
        //    */
        //   {
        //     payload: SIPWebRTCRTPAudioCodecPayloads.AUDIO_PAYLOAD_OPUS,
        //     config: 'maxplaybackrate=8000;maxaveragebitrate=14000',
        //   },
        //   ...eachMedia.fmtp.filter(({ payload }) => payload !== SIPWebRTCRTPAudioCodecPayloads.AUDIO_PAYLOAD_OPUS),
        // ];

        /**
         * Order Codecs by PCMA first
         */
        const mediaNewRTPByPCMA: sdpTransform.MediaAttributes['rtp'] = eachMedia.rtp.filter(
          ({ codec }) => codec === SIPWebRTCRTPAudioCodecs.AUDIO_CODEC_PCMA,
        );
        const mediaNewRTPByOthers: sdpTransform.MediaAttributes['rtp'] = eachMedia.rtp.filter(
          ({ codec }) => codec !== SIPWebRTCRTPAudioCodecs.AUDIO_CODEC_PCMA,
        );
        const mediaNewRTP: sdpTransform.MediaAttributes['rtp'] = [...mediaNewRTPByPCMA, ...mediaNewRTPByOthers];

        /**
         * Regenerate Media Payloads by sorted RTP
         */
        const mediaNewPayloads: string = mediaNewRTP
          .map(({ payload }) => payload)
          .join(' ')
          .trim();

        /**
         * Extend the existing Media with the New One
         */
        const newEachMedia: sdpTransform.SessionDescription['media'][0] = {
          ...eachMedia,
          /**
           * WEBRTC-SDP-REF-01
           */
          // fmtp: mediaNewFMTP,
          rtp: mediaNewRTP,
          payloads: mediaNewPayloads,
        };
        return newEachMedia;
      },
    );

    // New SDP with the modified Media
    const sdpNewAsParsed: sdpTransform.SessionDescription = {
      ...sdpCurrentAsParsed,
      media: mediaNew,
    };

    // New SDP as String
    const sdpNew: string = sdpTransform.write(sdpNewAsParsed);

    return { sdpNew };
  } catch {
    return { sdpNew: sdpCurrent };
  }
};

export const sipAudioManagementWrapperCreateAndAppend = (): HTMLElement => {
  const elementWrapperId = SIP.CONFIGURATIONS.AUDIO_MANAGEMENT.ELEMENT_WRAPPER_ID;

  const elementWrapper = window.document.getElementById(elementWrapperId);
  if (elementWrapper !== null) {
    return elementWrapper;
  }

  const newElementWrapper = window.document.createElement('div');
  newElementWrapper.id = elementWrapperId;
  newElementWrapper.style.position = 'fixed';
  newElementWrapper.style.zIndex = '-1';
  newElementWrapper.style.opacity = '0';
  newElementWrapper.style.visibility = 'hidden';
  window.document.body.appendChild(newElementWrapper);

  return newElementWrapper;
};

export const sipAudioManagementWrapperRemove = (): void => {
  const elementWrapperId = SIP.CONFIGURATIONS.AUDIO_MANAGEMENT.ELEMENT_WRAPPER_ID;
  const elementWrapper = window.document.getElementById(elementWrapperId);
  const elementWrapperParentNode = elementWrapper?.parentNode;
  if (elementWrapper && elementWrapperParentNode) {
    elementWrapperParentNode.removeChild(elementWrapper);
  }
};

export const sipAudioManagementAudioCreateAndAppend = ({
  idWebRTC,
  streams,
}: SipAudioManagementAudioCreateAndAppendProps): void => {
  const elementAudioId = `${SIP.CONFIGURATIONS.AUDIO_MANAGEMENT.ELEMENT_AUDIO_ID_PREFIX}${idWebRTC}`;
  const elementAudio = window.document.getElementById(elementAudioId);

  if (elementAudio instanceof HTMLAudioElement) {
    elementAudio.autoplay = true;
    [elementAudio.srcObject] = streams;
    return;
  }

  const elementWrapper = sipAudioManagementWrapperCreateAndAppend();
  const newElementAudio = window.document.createElement('audio');
  newElementAudio.id = elementAudioId;
  newElementAudio.autoplay = false;
  newElementAudio.style.position = 'fixed';
  newElementAudio.style.zIndex = '-1';
  newElementAudio.style.opacity = '0';
  newElementAudio.style.visibility = 'hidden';
  [newElementAudio.srcObject] = streams;
  elementWrapper.append(newElementAudio);
};

export const sipAudioManagementAudioPlay = ({ idWebRTC }: SipAudioManagementAudioProps): void => {
  const elementAudioId = `${SIP.CONFIGURATIONS.AUDIO_MANAGEMENT.ELEMENT_AUDIO_ID_PREFIX}${idWebRTC}`;
  const elementAudio = window.document.getElementById(elementAudioId);
  if (elementAudio instanceof HTMLAudioElement) {
    elementAudio.play().catch(() => {
      // ignore
    });
  }
};

export const sipAudioManagementAudioPause = ({ idWebRTC }: SipAudioManagementAudioProps): void => {
  const elementAudioId = `${SIP.CONFIGURATIONS.AUDIO_MANAGEMENT.ELEMENT_AUDIO_ID_PREFIX}${idWebRTC}`;
  const elementAudio = window.document.getElementById(elementAudioId);
  if (elementAudio instanceof HTMLAudioElement) {
    elementAudio.pause();
  }
};

export const sipAudioManagementAudioRemove = ({ idWebRTC }: SipAudioManagementAudioProps): void => {
  const elementAudioId = `${SIP.CONFIGURATIONS.AUDIO_MANAGEMENT.ELEMENT_AUDIO_ID_PREFIX}${idWebRTC}`;
  const elementAudio = window.document.getElementById(elementAudioId);
  const elementAudioParentNode = elementAudio?.parentNode;
  if (elementAudio && elementAudioParentNode) {
    elementAudioParentNode.removeChild(elementAudio);
  }
};
