import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IntegrationDetailedSearchContactsData, Status, StyleableComponent } from 'types';

import { formatNumberAsLocal, getDisplayName } from 'helpers';

import { InformationRow } from 'components/InformationRow';
import LoadingSkeleton from 'components/LoadingSkeleton';

import { IntegrationCRMCardType } from '../../types';

import styles from './IntegrationCRMCardContent.module.scss';

interface IntegrationCRMCardContentProps extends StyleableComponent {
  integrationCrmCardType: IntegrationCRMCardType;
  status: Status;
  details: IntegrationDetailedSearchContactsData | null;
}

const IntegrationCRMCardContent: React.FC<IntegrationCRMCardContentProps> = ({
  integrationCrmCardType,
  status,
  details,
  className = '',
}) => {
  const integrationContactName = getDisplayName({ firstName: details?.firstName, lastName: details?.lastName });
  const integrationEmails = details?.emails ?? [];
  const integrationPhoneNumbers = details?.phones ?? [];

  const isDisplayLoading = status === Status.LOADING || (status === Status.IDLE && !details);
  const isDisplayFailed = !isDisplayLoading && (status === Status.FAILED || !details);
  const isDisplaySucceeded =
    !isDisplayLoading && !isDisplayFailed && (status === Status.SUCCEEDED || (status === Status.IDLE && !!details));

  const isCalls = integrationCrmCardType === IntegrationCRMCardType.CALL_POPUP;
  const isContacts = integrationCrmCardType === IntegrationCRMCardType.CONTACT_PAGE;

  return (
    <div
      className={classNames(className, {
        [styles.calls]: isCalls,
        [styles.contacts]: isContacts,
      })}
    >
      {isDisplayLoading ? <LoadingSkeleton className={styles.loading} /> : null}

      {isDisplayFailed ? (
        <div className={styles.failed}>
          <FormattedMessage id="Integrations.contact_not_found" />
        </div>
      ) : null}

      {isDisplaySucceeded ? (
        <>
          <InformationRow
            className={styles.text}
            label={<FormattedMessage id="InfoRow.ContactsInfo.contact_full_name" />}
            value={integrationContactName}
          />

          {integrationEmails.length > 0 ? (
            integrationEmails.map((email, index) => (
              <InformationRow
                key={index}
                className={styles.text}
                label={<FormattedMessage id="InfoRow.ContactsInfo.contact_email" />}
                value={email}
              />
            ))
          ) : (
            <InformationRow
              className={styles.text}
              label={<FormattedMessage id="InfoRow.ContactsInfo.contact_email" />}
              value={undefined}
            />
          )}

          {integrationPhoneNumbers.length > 0 ? (
            integrationPhoneNumbers.map((phoneNumber, index) => (
              <InformationRow
                key={index}
                className={styles.text}
                label={<FormattedMessage id="InfoRow.ContactsInfo.contact_phone_number" />}
                value={formatNumberAsLocal(phoneNumber)}
              />
            ))
          ) : (
            <InformationRow
              className={styles.text}
              label={<FormattedMessage id="InfoRow.ContactsInfo.contact_phone_number" />}
              value={undefined}
            />
          )}

          <InformationRow
            className={styles.text}
            label={<FormattedMessage id="InfoRow.ContactsInfo.contact_company" />}
            value={details?.companyName}
          />

          <InformationRow
            className={styles.text}
            label={<FormattedMessage id="InfoRow.ContactsInfo.assignee" />}
            value={details?.contactOwnerName}
          />

          <InformationRow
            className={styles.text}
            label={<FormattedMessage id="InfoRow.ContactsInfo.url" />}
            value={
              <a
                href={details?.contactUrl}
                rel="noreferrer noopener"
                target="_blank"
                className={styles.link}
              >
                {details?.contactUrl}
              </a>
            }
          />
        </>
      ) : null}
    </div>
  );
};

export default IntegrationCRMCardContent;
