import classNames from 'classnames';
import React from 'react';

import { StyleableComponent, TestableComponent } from 'types';

import { testGetTestingAttributes } from '@testing';
import { IconButton, IconCommonNames } from 'components/Icon';

import styles from './ModalHeader.module.scss';

export interface ModalLayoutHeaderProps extends StyleableComponent, TestableComponent {
  title?: string;
  onCloseButtonClick?: () => void;
  classNameTitle?: string;
  isCloseButtonDisabled?: boolean;
}

const ModalHeader: React.FC<ModalLayoutHeaderProps> = ({
  title = '',
  onCloseButtonClick,
  className,
  classNameTitle,
  isCloseButtonDisabled = false,
  propTestId = '',
  propTestIds = {
    title: '',
    closeButton: '',
  },
}) => {
  if (title === '' && onCloseButtonClick === undefined) {
    return null;
  }

  return (
    <div
      className={classNames(styles.root, className)}
      {...testGetTestingAttributes(propTestId)}
    >
      <div className={classNames(styles.titleWrapper)}>
        {title !== '' && (
          <h3
            className={classNames(styles.title, classNameTitle)}
            {...testGetTestingAttributes(propTestIds.title)}
          >
            {title}
          </h3>
        )}
      </div>
      {onCloseButtonClick && (
        <IconButton
          iconName={IconCommonNames.CLOSE}
          onClick={onCloseButtonClick}
          className={styles.closeButton}
          isDisabled={isCloseButtonDisabled}
          {...testGetTestingAttributes(propTestIds.closeButton)}
        />
      )}
    </div>
  );
};

export default ModalHeader;
