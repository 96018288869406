import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { StyleableComponent } from 'types';

import { useUserCapabilities } from 'hooks';
import { ApplicationPaths } from 'routes';

import styles from './SubHeaderItemLink.module.scss';

export interface SubHeaderItemLinkProps extends StyleableComponent {
  to: ApplicationPaths;
  isActive: boolean;
  text: string;
  unseenContentCount?: number;
}

const SubHeaderItemLink: React.FC<SubHeaderItemLinkProps> = ({
  to,
  isActive,
  text,
  unseenContentCount = 0,
  className = '',
}) => {
  const { isUserB2B } = useUserCapabilities();

  return (
    <li
      className={classnames(styles.root, className, {
        [styles.isActive]: isActive,
        [styles.isB2B]: isUserB2B,
        [styles.isB2C]: !isUserB2B,
      })}
    >
      <Link
        to={to}
        className={styles.link}
      >
        <span className={styles.text}>{text}</span>
        {unseenContentCount > 0 && <span className={styles.count}>{unseenContentCount}</span>}
      </Link>
    </li>
  );
};

export default SubHeaderItemLink;
