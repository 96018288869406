import classNames from 'classnames';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';

import { testGetTestingAttributes } from '@testing';
import { AvatarCommon, AvatarSize } from 'components/Avatar';
import { ButtonRoundFlat } from 'components/Button';
import { DropdownCommon, DropdownIconOption, DropdownCommonProps, useDropdownPopper } from 'components/Dropdowns';
import { isIconCommonName, IconCommonNames } from 'components/Icon';

import { useFilePhoto } from '../../hooks';
import { PhotoInputCommonProps } from '../../types';

import styles from './PhotoInputCommon.module.scss';

const PhotoInputCommon: React.FC<PhotoInputCommonProps> = ({
  onChange,
  imageUrl = '',
  className = '',
  backgroundColor = '',
  propTestIds = {
    avatar: '',
    buttonAdd: '',
    buttonEdit: '',
    input: '',
    dropdown: '',
    dropdownChange: '',
    dropdownRemove: '',
  },
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const intl = useIntl();

  const { onChangeHandler, imageSrc, clear } = useFilePhoto({
    onChange,
    imageUrl,
    fileRef,
  });

  const hasImage = imageSrc.length > 0;

  const { isDropdownOpen, openDropdown, closeDropdown, popper } = useDropdownPopper({
    dropdownRef,
    buttonRef,
    popperOptions: {
      placement: 'right-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
    },
  });

  const openFile = () => fileRef.current?.click();

  const onAvatarButtonHandler = () => {
    if (hasImage) {
      openDropdown();
      return;
    }
    openFile();
  };

  const onSelectHandler: DropdownCommonProps['onSelect'] = (value) => {
    if (isIconCommonName(value)) {
      if (value === IconCommonNames.BASKET) {
        clear();
      } else if (value === IconCommonNames.FILE_UPLOAD) {
        openFile();
      }
    }

    closeDropdown();
  };

  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.dropdownOpen]: isDropdownOpen,
          [styles.hasImage]: hasImage,
        },
        className,
      )}
    >
      <div className={styles.wrapper}>
        <button
          className={styles.avatarButton}
          onClick={onAvatarButtonHandler}
          {...testGetTestingAttributes(propTestIds.buttonAdd)}
        >
          <AvatarCommon
            imageSrc={imageSrc}
            backgroundColor={hasImage ? backgroundColor : ''}
            size={AvatarSize.LARGE}
            hasPhotoIcon
            propTestId={propTestIds.avatar}
          />
        </button>
        <ButtonRoundFlat
          onClick={onAvatarButtonHandler}
          ref={buttonRef}
          className={styles.editButton}
          iconName={IconCommonNames.EDIT}
          propTestId={propTestIds.buttonEdit}
        />
        <input
          className={styles.fileInput}
          onChange={onChangeHandler}
          ref={fileRef}
          type="file"
          accept=".jpg, .png, .gif, .bmp"
          {...testGetTestingAttributes(propTestIds.input)}
        />
      </div>
      <DropdownCommon
        className={styles.dropdown}
        isOpen={isDropdownOpen}
        ref={dropdownRef}
        propTestId={propTestIds.dropdown}
        options={[
          {
            label: intl.formatMessage({ id: 'PhotoInputCommon.change_photo' }),
            value: IconCommonNames.FILE_UPLOAD,
            propTestId: propTestIds.dropdownChange,
          },
          {
            label: intl.formatMessage({ id: 'PhotoInputCommon.delete' }),
            value: IconCommonNames.BASKET,
            propTestId: propTestIds.dropdownRemove,
          },
        ]}
        onSelect={onSelectHandler}
        renderOption={({ label, value, propTestId }) => {
          if (isIconCommonName(value)) {
            return (
              <DropdownIconOption
                className={classNames(styles.dropdownItem)}
                label={label}
                value={value}
                propTestId={propTestId}
              />
            );
          }
          return undefined;
        }}
        style={popper.styles.popper}
      />
    </div>
  );
};

export default PhotoInputCommon;
