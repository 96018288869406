import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Status } from 'types';

import { LOCALSTORAGE_KEYS } from '@constants';
import {
  useWebAppDispatch,
  useWebAppSelector,
  authenticationRenewAuthKeyHandler,
  authenticationSetStatusRenewAuthKey,
  pushGetPushesHandler,
  selectUserData,
  selectAuthenticationStatuses,
} from '@redux';

import { UsePubNubConfigReturn } from '../../types';

export const usePubNubConfig = (): UsePubNubConfigReturn => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const { userId } = useWebAppSelector(selectUserData);
  const { statusRenewAuthKey } = useWebAppSelector(selectAuthenticationStatuses);

  const canRenewAuthKey = statusRenewAuthKey === Status.IDLE;
  const pubNubSubscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY || '';
  const pubNubAuthKey = window.localStorage.getItem(LOCALSTORAGE_KEYS.AUTH_KEY) || '';
  const pubNubUserDeviceId = window.localStorage.getItem(LOCALSTORAGE_KEYS.USER_DEVICE_ID) || '';
  const pubNubUserId = userId || '';
  const pubNubChannel = `onoff${pubNubUserId}-mc.${pubNubUserDeviceId}`;

  const shouldPubNubInit =
    canRenewAuthKey &&
    pubNubSubscribeKey.length > 0 &&
    pubNubAuthKey.length > 0 &&
    pubNubUserDeviceId.length > 0 &&
    pubNubUserId.length > 0;

  const callbackPubNubPushHistoryUpdate = () => {
    dispatch(pushGetPushesHandler({ isWindowOnline: true }));
  };

  const callbackPubNubRenewAuthKey = () => {
    if (canRenewAuthKey) {
      dispatch(authenticationRenewAuthKeyHandler());
    }
  };

  const callbackPubNubRenewAuthKeySucceededHandler = (): void => {
    if (statusRenewAuthKey === Status.SUCCEEDED) {
      dispatch(authenticationSetStatusRenewAuthKey(Status.IDLE));
    }
  };

  const callbackPubNubRenewAuthKeyErrorHandler = (): void => {
    if (statusRenewAuthKey === Status.FAILED) {
      dispatch(authenticationSetStatusRenewAuthKey(Status.IDLE));
    }
  };

  useEffect(callbackPubNubRenewAuthKeySucceededHandler, [statusRenewAuthKey, dispatch]);

  useEffect(callbackPubNubRenewAuthKeyErrorHandler, [statusRenewAuthKey, intl, dispatch]);

  return {
    shouldPubNubInit,
    pubNubSubscribeKey,
    pubNubAuthKey,
    pubNubUserId,
    pubNubChannel,
    callbackPubNubPushHistoryUpdate,
    callbackPubNubRenewAuthKey,
  };
};
