import classNames from 'classnames';
import React from 'react';

import { Button } from '@onoff/ui';

import { testGetTestingAttributes, testIds } from '@testing';
import { IllustrationCommon } from 'components/Illustration';
import { Modal, ModalSize } from 'components/Modal';
import { useUserCapabilities } from 'hooks';

import { ModalsDialogType } from '../../enum';
import { getIllustrationName } from '../../helpers';

import styles from './ModalsDialog.module.scss';

export interface ModalsDialogProps {
  isOpen: boolean;
  type: ModalsDialogType;
  title: string;
  buttonText: string;
  onButtonClick: () => void;
  onCloseAnimationEnd?: () => void;
  content?: React.ReactNode;
}

const ModalsDialog: React.FC<ModalsDialogProps> = ({
  isOpen,
  type,
  title,
  buttonText,
  onButtonClick,
  content,
  onCloseAnimationEnd = () => null,
}) => {
  const { isUserB2B } = useUserCapabilities();
  const illustrationName = getIllustrationName(type);
  const buttonColorScheme = isUserB2B ? 'brand-b2b' : 'brand-b2c';

  return (
    <Modal
      isOpen={isOpen}
      canEscClose={false}
      canOverlayClose={false}
      size={ModalSize.S}
      className={styles.root}
      onCloseAnimationEnd={onCloseAnimationEnd}
    >
      <IllustrationCommon
        name={illustrationName}
        className={classNames(styles.illustration, {
          [styles.isB2B]: isUserB2B,
          [styles.isB2C]: !isUserB2B,
        })}
      />
      <h3
        className={styles.title}
        {...testGetTestingAttributes(testIds.modalDialogTitle)}
      >
        {title}
      </h3>
      <div
        className={styles.content}
        {...testGetTestingAttributes(testIds.modalDialogContent)}
      >
        {content}
      </div>
      <Button
        onClick={onButtonClick}
        size={'large'}
        className={styles.button}
        colorScheme={buttonColorScheme}
        {...testGetTestingAttributes(testIds.modalDialogButton)}
      >
        {buttonText}
      </Button>
    </Modal>
  );
};

export default ModalsDialog;
