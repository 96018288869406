import { AxiosError } from 'axios';

import { isArray } from 'helpers';
import { getErrorDataFromUnknownError } from 'helpers/errorHandling';
import { isObject, isString, isOptionalString, isOptionalObject } from 'helpers/typeCheckers';

import { API_ERRORS } from '@constants';

import { ApiError, ApiErrorPurchase, QueryParamKey, ApiHTTPResponseCodes } from '../types';

export const isApiPurchaseError = (input: unknown): input is ApiErrorPurchase =>
  isObject(input) &&
  isString(input.code) &&
  isOptionalObject(input.additionalInfo) &&
  isOptionalString(input.additionalInfo?.paymentResponseCode);

export const getApiError = (error: AxiosError): ApiError => {
  if (error.response) {
    const { status, data } = error.response;

    // 423
    if (status === ApiHTTPResponseCodes.USER_BLOCKED && isString(data)) {
      return { code: data };
    }

    // 209 || 400
    if (status === ApiHTTPResponseCodes.EXCEPTION_ERROR || status === ApiHTTPResponseCodes.BAD_REQUEST) {
      const { errorCode, errorDescription } = getErrorDataFromUnknownError(data);

      return {
        code: errorCode,
        description: errorDescription,
      };
    }

    // 403
    if (status === ApiHTTPResponseCodes.REQUEST_FORBIDDEN) {
      return { code: API_ERRORS.HTTP.REQUEST_FORBIDDEN };
    }
  }

  // returns empty failed response anyways
  return { code: API_ERRORS.HTTP.UNKNOWN_ERROR };
};

export const parseQueryParams = (params: Record<string, QueryParamKey> | string = {}): string => {
  const queryParamKeys = Object.keys(params);

  if (queryParamKeys.length === 0) {
    return '';
  }

  const searchParams = new URLSearchParams();

  queryParamKeys.forEach((key) => {
    const queryParamValue: Array<string | number | boolean> | string = params[key];

    if (queryParamValue === undefined) {
      return;
    }

    if (isArray(queryParamValue)) {
      queryParamValue.forEach((value) => {
        searchParams.append(key, value.toString());
      });
    } else {
      searchParams.append(key, queryParamValue);
    }
  });

  return searchParams.toString();
};
