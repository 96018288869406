import React from 'react';

import { StyleableComponent } from 'types';

const PremiumCrown: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 19H22V21H2V19ZM2 5L7 8L12 2L17 8L22 5V17H2V5Z"
      fill="currentColor"
    />
  </svg>
);

export default PremiumCrown;
