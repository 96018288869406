import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useCallback, useRef, useState } from 'react';

import { InputContactPhoneNumber } from 'components/Input';
import { ModalsSelectCountry } from 'components/Modals';

import { ModalsAddEditContactPhoneInputProps } from '../../types';

const ModalsAddEditContactPhoneInput: React.FC<ModalsAddEditContactPhoneInputProps> = ({
  onChange,
  countryList = [],
  countryCode = 'FR',
  number = '',
  type = '',
  className = '',
  label = '',
  error,
  onRemovePhone,
  onSelectPhoneType,
  propTestIds,
}) => {
  const [isModalSelectCountryOpen, setIsModalSelectCountryOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const closeModalSelectCountry = useCallback(() => {
    setIsModalSelectCountryOpen(false);
    inputRef.current?.focus();
  }, [setIsModalSelectCountryOpen]);

  const openModalSelectCountry = useCallback(() => setIsModalSelectCountryOpen(true), [setIsModalSelectCountryOpen]);

  const [currentCountryCode, setCurrentCountryCode] = useState<CountryCode>(
    parsePhoneNumberFromString(number)?.country || countryCode,
  );

  return (
    <>
      <InputContactPhoneNumber
        className={className}
        label={label}
        number={number}
        type={type}
        error={error}
        countryCode={currentCountryCode}
        onChange={onChange}
        onRemovePhone={onRemovePhone}
        onSelectPhoneType={onSelectPhoneType}
        onSelectCountry={openModalSelectCountry}
        propTestIds={propTestIds}
        ref={inputRef}
      />

      <ModalsSelectCountry
        isOpen={isModalSelectCountryOpen}
        onClose={closeModalSelectCountry}
        onSelectCountry={setCurrentCountryCode}
        countryList={countryList}
      />
    </>
  );
};

export default ModalsAddEditContactPhoneInput;
