import React from 'react';

import { StyleableComponent } from 'types';

const AccountDelete: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width={150}
    height={150}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M150 75C150 116.421 116.421 150 75 150C33.5786 150 0 116.421 0 75C0 33.5786 33.5786 0 75 0C116.421 0 150 33.5786 150 75Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M140.526 74.9999C140.526 111.189 111.189 140.526 74.9999 140.526C38.8108 140.526 9.47363 111.189 9.47363 74.9999C9.47363 38.8108 38.8108 9.47363 74.9999 9.47363C111.189 9.47363 140.526 38.8108 140.526 74.9999Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M129.079 74.9998C129.079 104.867 104.867 129.079 75.0001 129.079C45.1331 129.079 20.9211 104.867 20.9211 74.9998C20.9211 45.1329 45.1331 20.9209 75.0001 20.9209C104.867 20.9209 129.079 45.1329 129.079 74.9998Z"
      fill="#F3F4F5"
    />
    <path
      d="M42.1039 6.22521C42.7523 4.44398 44.7218 3.52557 46.503 4.17388L93.5912 21.3126C95.3724 21.9609 96.2909 23.9304 95.6425 25.7117L77.2126 76.3476C76.5643 78.1288 74.5947 79.0472 72.8135 78.3989L25.7253 61.2602C23.9441 60.6119 23.0257 58.6423 23.674 56.8611L42.1039 6.22521Z"
      fill="#E4E5E7"
    />
    <path
      d="M41.3539 5.47521C42.0023 3.69398 43.9718 2.77557 45.753 3.42388L92.8412 20.5626C94.6224 21.2109 95.5409 23.1804 94.8925 24.9617L76.4626 75.5976C75.8143 77.3788 73.8447 78.2972 72.0635 77.6489L24.9753 60.5102C23.1941 59.8619 22.2757 57.8923 22.924 56.1111L41.3539 5.47521Z"
      fill="white"
    />
    <path
      d="M35.1536 49.605C35.2833 49.2488 35.6772 49.0651 36.0334 49.1947L71.1883 61.9901C71.5445 62.1197 71.7282 62.5136 71.5985 62.8699L70.6594 65.45C70.5298 65.8063 70.1358 65.99 69.7796 65.8603L34.6247 53.065C34.2685 52.9353 34.0848 52.5414 34.2145 52.1852L35.1536 49.605Z"
      fill="#E2E2E2"
    />
    <mask
      id="mask0_10248_125571"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x={45}
      y={18}
      width={35}
      height={35}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.7219 18.9438L79.3767 27.9175L70.4031 52.5723L45.7482 43.5987L54.7219 18.9438Z"
        fill="#273348"
      />
    </mask>
    <g mask="url(#mask0_10248_125571)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.0458 23.4289C60.2452 20.9537 52.7115 24.4667 50.2362 31.2673C47.761 38.068 51.274 45.6016 58.0747 48.0769C64.8753 50.5521 72.409 47.0391 74.8842 40.2385C77.3663 33.4403 73.8533 25.9067 67.0458 23.4289ZM69.6426 38.5496C68.7491 39.5223 67.3471 40.4741 65.2376 41.4578C63.1634 42.4308 61.5192 42.8879 60.2079 42.9892C59.3925 43.0599 58.6543 42.9398 57.9394 42.6483C56.4104 42.1231 55.1603 41.0035 54.4797 39.5438C53.7878 38.0722 53.7267 36.3924 54.3097 34.8767C54.529 34.1668 54.9391 33.5342 55.4797 32.9725C56.3758 31.9929 57.7828 31.0273 59.8847 30.0643C61.9966 29.0738 63.6131 28.6066 64.942 28.543C65.7179 28.4735 66.4956 28.5924 67.2105 28.8839C68.7232 29.411 69.9594 30.5255 70.6426 31.9783C71.3302 33.4404 71.3937 35.1133 70.8226 36.6178C70.5889 37.3459 70.1763 37.9854 69.6426 38.5496Z"
        fill="url(#paint0_linear_10248_125571)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.9493 38.4245C61.7468 37.6229 60.6095 35.1838 61.4111 32.9814C62.2127 30.779 64.6543 29.6347 66.8567 30.4364C69.0591 31.238 70.1965 33.677 69.3923 35.8863C68.5907 38.0888 66.1517 39.2261 63.9493 38.4245Z"
        fill="url(#paint1_linear_10248_125571)"
      />
    </g>
    <mask
      id="mask1_10248_125571"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={150}
      height={150}
    >
      <path
        d="M0 0H150V150H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_10248_125571)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8269 102.074C58.4167 98.2199 69.0059 94.3657 79.6295 90.499C90.2192 86.6447 100.842 82.7782 111.432 78.9238C113.579 86.8289 115.602 94.395 117.549 101.643C119.43 108.607 121.259 115.322 122.989 121.765C123.437 123.523 122.756 125.347 121.432 125.829C112.913 128.93 104.394 132.03 95.8742 135.131C87.3553 138.232 78.8021 141.345 70.2827 144.446C68.9929 144.915 67.3114 143.99 66.5115 142.321C63.6953 136.273 60.7804 129.954 57.7447 123.41C54.5769 116.607 51.2636 109.51 47.8269 102.074Z"
        fill="#C8CBCE"
      />
      <path
        d="M83.7648 111.222C84.0771 111.742 83.5995 112.369 83.0227 112.225L81.0558 111.735L80.0758 115.665C79.8198 116.691 80.7976 117.604 81.8031 117.277L83.8897 116.599C84.1556 116.513 84.4414 116.658 84.5278 116.924L85.0494 118.53C85.1358 118.796 84.9903 119.082 84.7243 119.168L82.6418 119.845C79.6183 120.827 76.6903 118.093 77.459 115.011L78.4374 111.082L76.471 110.591C75.8877 110.446 75.7726 109.666 76.2866 109.358L80.3792 106.899C80.6989 106.706 81.1136 106.81 81.3057 107.13L83.7648 111.222ZM85.3353 102.595L87.8541 104.709L86.551 106.262C86.1655 106.721 86.5282 107.421 87.127 107.369L91.8834 106.952C92.2575 106.92 92.5295 106.59 92.4972 106.221L92.081 101.465C92.0288 100.867 91.2774 100.629 90.8912 101.089L89.5897 102.64L87.0718 100.526C84.783 98.6061 81.2618 99.7441 80.5371 102.65L80.1913 104.038C80.1237 104.31 80.2888 104.584 80.5602 104.652L82.1979 105.06C82.4693 105.128 82.7442 104.963 82.8119 104.692L83.1579 103.304C83.4028 102.322 84.5859 101.967 85.3353 102.595ZM96.8277 108.718L95.1488 107.308C94.9345 107.128 94.615 107.156 94.4352 107.37L93.3521 108.661C93.1723 108.875 93.2002 109.195 93.4145 109.375L95.096 110.786C95.9047 111.464 95.652 112.778 94.6454 113.105L90.793 114.356L90.1673 112.431C89.9819 111.86 89.1964 111.797 88.9235 112.333L86.7557 116.586C86.5864 116.918 86.7185 117.325 87.0508 117.494L91.3048 119.661C91.8367 119.932 92.4401 119.425 92.2534 118.851L91.6276 116.925L95.4758 115.675C98.497 114.693 99.2633 110.761 96.8277 108.718Z"
        fill="white"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8269 102.074C58.4167 98.2199 69.0059 94.3657 79.6295 90.499C90.2192 86.6447 100.842 82.7782 111.432 78.9238L113.616 86.9307C103.273 88.0817 92.2599 90.6683 81.1613 94.7078C70.0628 98.7473 59.9638 103.845 51.3006 109.612L47.8269 102.074Z"
        fill="#8E929B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.9544 71.791L95.6985 52.5938C98.6856 51.5066 102.018 53.0607 103.106 56.0477L111.432 78.924L47.8267 102.074L39.5005 79.1981C38.4133 76.2111 39.9674 72.8782 42.9544 71.791Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.5534 93.7126L109.331 69.7716C110.009 69.5246 110.716 69.8823 110.951 70.5277L113.274 76.9082C113.508 77.5531 113.197 78.2815 112.518 78.5285L46.7407 102.47C46.062 102.717 45.3551 102.359 45.1203 101.714L42.798 95.3335C42.5631 94.6881 42.8747 93.9597 43.5534 93.7126Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.6395 93.3176L108.245 70.1672L107.59 68.3687L43.9849 91.519L44.6395 93.3176Z"
        fill="#C8CBCE"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2095 92.0958L22.991 90.3079L21 89.4595L22.7879 88.2405L23.6363 86.25L24.8548 88.0383L26.8458 88.8863L25.058 90.1048L24.2095 92.0958Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.209 23.0958L109.991 21.3079L108 20.4595L109.788 19.2405L110.636 17.25L111.855 19.0383L113.846 19.8863L112.058 21.1048L111.209 23.0958Z"
      fill="#E4E4E4"
    />
    <path
      d="M150 75C150 116.421 116.421 150 75 150C33.5786 150 0 116.421 0 75C0 33.5786 33.5786 0 75 0C116.421 0 150 33.5786 150 75Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M140.526 74.9999C140.526 111.189 111.189 140.526 75 140.526C38.8108 140.526 9.47369 111.189 9.47369 74.9999C9.47369 38.8108 38.8108 9.47363 75 9.47363C111.189 9.47363 140.526 38.8108 140.526 74.9999Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M129.079 74.9998C129.079 104.867 104.867 129.079 75 129.079C45.133 129.079 20.921 104.867 20.921 74.9998C20.921 45.1329 45.133 20.9209 75 20.9209C104.867 20.9209 129.079 45.1329 129.079 74.9998Z"
      fill="#F3F4F5"
    />
    <path
      d="M42.1038 6.22521C42.7521 4.44398 44.7217 3.52557 46.5029 4.17388L93.5911 21.3126C95.3723 21.9609 96.2907 23.9304 95.6424 25.7117L77.2124 76.3476C76.5641 78.1288 74.5946 79.0472 72.8134 78.3989L25.7252 61.2602C23.944 60.6119 23.0256 58.6423 23.6739 56.8611L42.1038 6.22521Z"
      fill="#E4E5E7"
    />
    <path
      d="M41.3538 5.47522C42.0021 3.69398 43.9717 2.77557 45.7529 3.42388L92.8411 20.5626C94.6223 21.2109 95.5407 23.1804 94.8924 24.9617L76.4625 75.5976C75.8141 77.3788 73.8446 78.2972 72.0634 77.6489L24.9752 60.5102C23.194 59.8619 22.2755 57.8923 22.9239 56.1111L41.3538 5.47522Z"
      fill="white"
    />
    <path
      d="M35.1536 49.605C35.2833 49.2488 35.6772 49.0651 36.0334 49.1947L71.1883 61.9901C71.5445 62.1197 71.7282 62.5136 71.5985 62.8699L70.6594 65.45C70.5298 65.8063 70.1359 65.99 69.7796 65.8603L34.6247 53.065C34.2685 52.9353 34.0848 52.5414 34.2145 52.1852L35.1536 49.605Z"
      fill="#E2E2E2"
    />
    <path
      d="M77.0885 40.7619C73.8469 49.6681 63.9992 54.2601 55.093 51.0185C46.1868 47.777 41.5948 37.9292 44.8363 29.0231C48.0779 20.1169 57.9256 15.5248 66.8318 18.7664C75.738 22.008 80.3301 31.8557 77.0885 40.7619Z"
      fill="#FAFAFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.6224 40.2282C72.6755 48.3247 63.7231 52.4993 55.6265 49.5524C47.53 46.6055 43.3554 37.6531 46.3023 29.5565C49.2492 21.46 58.2017 17.2854 66.2982 20.2323C74.3947 23.1792 78.5693 32.1317 75.6224 40.2282ZM55.96 48.6362C63.5505 51.3989 71.9435 47.4852 74.7062 39.8947C77.4689 32.3042 73.5552 23.9113 65.9647 21.1486C58.3742 18.3859 49.9813 22.2995 47.2185 29.89C44.4558 37.4805 48.3695 45.8735 55.96 48.6362Z"
      fill="white"
    />
    <path
      d="M74.7062 39.8945C71.9435 47.485 63.5505 51.3987 55.96 48.636C48.3695 45.8732 44.4558 37.4803 47.2185 29.8898C49.9813 22.2993 58.3742 18.3856 65.9647 21.1483C73.5552 23.9111 77.4689 32.304 74.7062 39.8945Z"
      fill="url(#paint2_linear_10248_125571)"
    />
    <mask
      id="mask2_10248_125571"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x={46}
      y={20}
      width={30}
      height={30}
    >
      <path
        d="M74.7062 39.8945C71.9435 47.485 63.5505 51.3987 55.96 48.636C48.3695 45.8732 44.4558 37.4803 47.2185 29.8898C49.9813 22.2993 58.3742 18.3856 65.9647 21.1483C73.5552 23.9111 77.4689 32.304 74.7062 39.8945Z"
        fill="url(#paint3_linear_10248_125571)"
      />
    </mask>
    <g mask="url(#mask2_10248_125571)">
      <path
        opacity="0.9"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.2704 36.5081C63.256 37.5948 66.5572 36.0554 67.6439 33.0698C68.7305 30.0842 67.1911 26.783 64.2055 25.6963C61.2199 24.6097 57.9187 26.1491 56.8321 29.1347C55.7454 32.1203 57.2848 35.4215 60.2704 36.5081ZM60.0286 37.4577C53.855 35.2107 47.0287 38.3938 44.7817 44.5674L44.3148 45.8502L66.6714 53.9873L67.1383 52.7045C69.3853 46.5309 66.2022 39.7047 60.0286 37.4577Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.8269 102.074C58.4167 98.2199 69.0059 94.3657 79.6295 90.499C90.2192 86.6447 100.842 82.7782 111.432 78.9238C113.579 86.8289 115.602 94.395 117.549 101.643C119.43 108.607 121.259 115.322 122.989 121.765C123.437 123.523 122.756 125.347 121.432 125.829C112.913 128.93 104.394 132.03 95.8742 135.131C87.3553 138.232 78.8021 141.345 70.2827 144.446C68.9929 144.915 67.3114 143.99 66.5115 142.321C63.6953 136.273 60.7804 129.954 57.7447 123.41C54.5769 116.607 51.2636 109.51 47.8269 102.074Z"
      fill="#C8CBCE"
    />
    <path
      d="M83.7647 111.222C84.077 111.742 83.5994 112.369 83.0226 112.225L81.0557 111.735L80.0757 115.665C79.8197 116.691 80.7975 117.604 81.803 117.277L83.8896 116.599C84.1556 116.513 84.4413 116.658 84.5277 116.924L85.0494 118.53C85.1358 118.796 84.9902 119.082 84.7242 119.168L82.6418 119.845C79.6182 120.827 76.6902 118.093 77.459 115.011L78.4374 111.082L76.471 110.591C75.8877 110.446 75.7726 109.666 76.2866 109.358L80.3791 106.899C80.6988 106.706 81.1136 106.81 81.3056 107.13L83.7647 111.222ZM85.3352 102.595L87.854 104.709L86.5509 106.262C86.1654 106.721 86.5281 107.421 87.127 107.369L91.8833 106.952C92.2574 106.92 92.5294 106.59 92.4971 106.221L92.081 101.465C92.0287 100.867 91.2774 100.629 90.8911 101.089L89.5896 102.64L87.0717 100.526C84.7829 98.6061 81.2617 99.7441 80.537 102.65L80.1912 104.038C80.1236 104.31 80.2888 104.584 80.5601 104.652L82.1979 105.06C82.4692 105.128 82.7441 104.963 82.8118 104.692L83.1578 103.304C83.4028 102.322 84.5859 101.967 85.3352 102.595ZM96.8277 108.718L95.1487 107.308C94.9345 107.128 94.6149 107.156 94.4351 107.37L93.352 108.661C93.1722 108.875 93.2001 109.195 93.4144 109.375L95.096 110.786C95.9047 111.464 95.6519 112.778 94.6453 113.105L90.7929 114.356L90.1672 112.431C89.9819 111.86 89.1964 111.797 88.9235 112.333L86.7556 116.586C86.5863 116.918 86.7184 117.325 87.0507 117.494L91.3047 119.661C91.8366 119.932 92.44 119.425 92.2534 118.851L91.6275 116.925L95.4758 115.675C98.4969 114.693 99.2633 110.761 96.8277 108.718Z"
      fill="white"
    />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.8269 102.074C58.4167 98.2199 69.0059 94.3657 79.6295 90.499C90.2192 86.6447 100.842 82.7782 111.432 78.9239L113.616 86.9307C103.273 88.0817 92.2599 90.6683 81.1613 94.7078C70.0628 98.7473 59.9638 103.845 51.3006 109.612L47.8269 102.074Z"
      fill="#8E929B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.9546 71.791L95.6987 52.5937C98.6857 51.5066 102.019 53.0607 103.106 56.0477L111.432 78.924L47.8269 102.074L39.5007 79.1981C38.4135 76.2111 39.9676 72.8782 42.9546 71.791Z"
      fill="#E4E5E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.5536 93.7126L109.331 69.7716C110.01 69.5246 110.717 69.8823 110.951 70.5277L113.274 76.9082C113.508 77.5531 113.197 78.2815 112.518 78.5285L46.7408 102.47C46.0621 102.717 45.3552 102.359 45.1205 101.714L42.7982 95.3335C42.5633 94.6881 42.8749 93.9597 43.5536 93.7126Z"
      fill="#E4E5E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.6397 93.3176L108.245 70.1672L107.59 68.3687L43.985 91.519L44.6397 93.3176Z"
      fill="#C8CBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2095 92.0958L22.991 90.3079L21 89.4595L22.7879 88.2405L23.6363 86.25L24.8548 88.0383L26.8458 88.8863L25.058 90.1048L24.2095 92.0958Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.209 23.0958L109.991 21.3079L108 20.4595L109.788 19.2405L110.636 17.25L111.855 19.0383L113.846 19.8863L112.058 21.1048L111.209 23.0958Z"
      fill="#E4E4E4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10248_125571"
        x1="50.2652"
        y1="39.6237"
        x2="74.4167"
        y2="30.3429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#575DF2" />
        <stop
          offset={1}
          stopColor="#32A8FF"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_10248_125571"
        x1="61.421"
        y1="35.6859"
        x2="69.2457"
        y2="32.6829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#575DF2" />
        <stop
          offset={1}
          stopColor="#32A8FF"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_10248_125571"
        x1="47.2508"
        y1="39.2089"
        x2="74.1848"
        y2="28.8589"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#575DF2" />
        <stop
          offset={1}
          stopColor="#32A8FF"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_10248_125571"
        x1="47.2508"
        y1="39.2089"
        x2="74.1848"
        y2="28.8589"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#575DF2" />
        <stop
          offset={1}
          stopColor="#32A8FF"
        />
      </linearGradient>
    </defs>
  </svg>
);

export default AccountDelete;
