import classNames from 'classnames';
import React from 'react';

import { StyleableComponent } from 'types';

import styles from './BalanceCredit.module.scss';

interface BalanceCreditProps extends StyleableComponent {
  isUserB2B?: boolean;
}

const BalanceCredit: React.FC<BalanceCreditProps> = ({ className = '', isUserB2B = false }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(styles.root, className, {
      [styles.b2b]: isUserB2B,
    })}
  >
    <circle
      cx="12"
      cy="12"
      r="12"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9687 5.69014C12.9687 5.30899 12.61 5 12.1675 5C11.725 5 11.3663 5.30899 11.3663 5.69014V7.363C11.0119 7.42326 10.672 7.51162 10.3466 7.62806C9.6721 7.86047 9.0806 8.19121 8.57213 8.62028C8.07403 9.04041 7.68489 9.54547 7.40471 10.1354C7.1349 10.7165 7 11.3511 7 12.0394C7 12.8082 7.14528 13.492 7.43584 14.0909C7.73677 14.6898 8.14667 15.1949 8.66552 15.6061C9.18438 16.0173 9.77587 16.3301 10.44 16.5447C10.7403 16.6417 11.0491 16.7168 11.3663 16.7699V18.3099C11.3663 18.691 11.725 19 12.1675 19C12.61 19 12.9687 18.691 12.9687 18.3099V16.8526C13.1601 16.8395 13.3497 16.8173 13.5376 16.786C13.8592 16.7324 14.1757 16.6609 14.4871 16.5715C14.7984 16.4821 15.0837 16.3748 15.3432 16.2497C15.5611 16.1514 15.6908 16.0083 15.7323 15.8206C15.7842 15.624 15.7219 15.4318 15.5455 15.2441C15.4314 15.11 15.2809 15.034 15.0941 15.0161C14.9177 14.9893 14.7361 15.0161 14.5493 15.0966C14.2484 15.2396 13.9267 15.3469 13.5843 15.4184C13.2522 15.4899 12.9097 15.5256 12.5569 15.5256C12.0069 15.5256 11.4933 15.4541 11.0159 15.3111C10.549 15.1591 10.1391 14.9357 9.78625 14.6407C9.4438 14.3368 9.174 13.9703 8.97683 13.5412C8.77967 13.1032 8.68109 12.6026 8.68109 12.0394C8.68109 11.5299 8.76929 11.0651 8.9457 10.645C9.13249 10.2248 9.39711 9.8628 9.73955 9.55888C10.082 9.24601 10.4867 9.00913 10.9537 8.84823C11.431 8.67839 11.9654 8.59347 12.5569 8.59347C12.9305 8.59347 13.2833 8.63369 13.6154 8.71414C13.9475 8.79459 14.264 8.90186 14.5649 9.03595C14.7413 9.1164 14.9177 9.14768 15.0941 9.1298C15.2809 9.11193 15.4314 9.03594 15.5455 8.90186C15.7012 8.73202 15.7583 8.5443 15.7167 8.33871C15.6856 8.13311 15.5559 7.98115 15.3276 7.88282C14.9229 7.68616 14.4819 7.5342 14.0045 7.42693C13.6674 7.34948 13.3221 7.29999 12.9687 7.27846V5.69014Z"
      fill="white"
    />
  </svg>
);

export default BalanceCredit;
