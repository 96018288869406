import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { passwordIsValid } from 'helpers/input_validation';

export interface UseFormValidationPasswordProps {
  passwordNew: string;
  passwordRepeat: string;
}

export type UseFormValidationPassword = (props: UseFormValidationPasswordProps) => {
  isPasswordValid: boolean;
  passwordNewErrorMessage: string;
  passwordRepeatErrorMessage: string;
};

export const useFormValidationPassword: UseFormValidationPassword = ({ passwordNew, passwordRepeat }) => {
  const intl = useIntl();

  const isPasswordNewNotEmpty = passwordNew.length > 0;
  const isPasswordRepeatNotEmpty = passwordRepeat.length > 0;
  const isPasswordNewValid = passwordIsValid(passwordNew);
  const isPasswordRepeatValid = passwordNew === passwordRepeat;

  const isPasswordValid =
    isPasswordNewNotEmpty && isPasswordRepeatNotEmpty && isPasswordNewValid && isPasswordRepeatValid;

  const passwordNewErrorMessage =
    isPasswordNewNotEmpty && !isPasswordNewValid ? intl.formatMessage({ id: 'General.Validations.password_hint' }) : '';

  const passwordRepeatErrorMessage =
    isPasswordRepeatNotEmpty && !isPasswordRepeatValid
      ? intl.formatMessage({ id: 'General.Validations.password_mismatch' })
      : '';

  return useMemo(
    () => ({
      isPasswordValid,
      passwordNewErrorMessage,
      passwordRepeatErrorMessage,
    }),
    [isPasswordValid, passwordNewErrorMessage, passwordRepeatErrorMessage],
  );
};
