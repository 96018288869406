import { MultiDeviceFetchDeviceListResponse, MultiDeviceLogoutDeviceRequest, MultiDeviceService } from 'types';

import { apiMobile } from 'api';

const multiDeviceService: MultiDeviceService = {
  fetchDeviceList: () => apiMobile.get<MultiDeviceFetchDeviceListResponse>('/devices/list', {}),
  logoutDevices: (ids) => apiMobile.post<void, MultiDeviceLogoutDeviceRequest>('/devices/logout', { ids }),
};

export default multiDeviceService;
