import { forwardRef } from 'react';

import { ButtonDropdownCategoryProps } from '../../types';
import ButtonDropdownCore from '../ButtonDropdownCore';

const ButtonDropdownCategory = forwardRef<HTMLButtonElement, ButtonDropdownCategoryProps>(
  ({ className, onClick, category = null, propTestId = '' }, ref) => (
    <ButtonDropdownCore
      ref={ref}
      className={className}
      onClick={onClick}
      propTestId={propTestId}
    >
      {category !== null && <span style={{ color: category.color }}>{category.name}</span>}
    </ButtonDropdownCore>
  ),
);

export default ButtonDropdownCategory;
