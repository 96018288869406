import { createPopper, Instance as PopperInstance } from '@popperjs/core';
import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

import { testGetTestingAttributes, testIds } from '@testing';
import { PhoneKeyboardCommon, PhoneKeyboardSize } from 'components/PhoneKeyboard';
import { useClickAway } from 'hooks';

import { SIPCallsListItemDropdownDTMFProps } from '../../types';

import styles from './SIPCallsListItemDropdownDTMF.module.scss';

const SIPCallsListItemDropdownDTMF: React.FC<SIPCallsListItemDropdownDTMFProps> = ({
  isOpen,
  onClickHandlerButtons,
  onCloseHandlerDropdown,
}) => {
  const refElementWrapper = useRef<HTMLDivElement | null>(null);
  const refPopperInstance = useRef<PopperInstance | null>(null);
  const [statePopperReference, setStatePopperReference] = useState<HTMLDivElement | null>(null);
  const [statePopperFollower, setStatePopperFollower] = useState<HTMLDivElement | null>(null);
  const [stateValuePreview, setStateValuePreview] = useState<string>('');

  const onClickHandlerButtonsKeyboard = (value: string): void => {
    setStateValuePreview(`${stateValuePreview}${value}`);
    onClickHandlerButtons(value);
  };

  const callbackDropdownPopperInitHandler: () => () => void = () => {
    if (refPopperInstance.current === null && statePopperReference !== null && statePopperFollower !== null) {
      refPopperInstance.current = createPopper(statePopperReference, statePopperFollower, {
        placement: 'bottom-start',
        strategy: 'absolute',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: ({ placement = '' }: { placement?: string }) => {
                if (placement.startsWith('bottom')) {
                  return [0, 5];
                }
                return [0, 40];
              },
            },
          },
        ],
      });
    }

    return () => {
      refPopperInstance.current?.destroy();
      refPopperInstance.current = null;
    };
  };

  const callbackDropdownPopperUpdateHandler: () => void = () => {
    if (isOpen && refPopperInstance.current !== null) {
      refPopperInstance.current.update();
    }
  };

  useEffect(callbackDropdownPopperInitHandler, [statePopperReference, statePopperFollower]);

  useEffect(callbackDropdownPopperUpdateHandler, [isOpen]);

  useClickAway(refElementWrapper, onCloseHandlerDropdown);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={setStatePopperReference}
      className={styles.root}
      {...testGetTestingAttributes(testIds.sipCallsListItemDropdownDTMFRoot)}
    >
      {createPortal(
        <div
          ref={refElementWrapper}
          className={styles.wrapper}
        >
          <div
            ref={setStatePopperFollower}
            className={styles.dropdown}
          >
            <div className={styles.preview}>
              <span className={styles.previewText}>{stateValuePreview}</span>
            </div>
            <PhoneKeyboardCommon
              onClickKey={onClickHandlerButtonsKeyboard}
              size={PhoneKeyboardSize.S}
              longPressThreshold={400}
              className={styles.keyboard}
            />
          </div>
        </div>,
        window.document.body,
      )}
    </div>
  );
};

export default SIPCallsListItemDropdownDTMF;
