import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { useWebAppSelector, selectCategoryById } from '@redux';

import { sipCallsExtractContactData } from '../../helpers';
import { SIPCallsListItemUIDefaultFooterDetailContactProps } from '../../types';
import SIPCallsListItemUIDefaultFooterDetailContactItem from '../SIPCallsListItemUIDefaultFooterDetailContactItem';

import styles from './SIPCallsListItemUIDefaultFooterDetailContact.module.scss';

const SIPCallsListItemUIDefaultFooterDetailContact: React.FC<SIPCallsListItemUIDefaultFooterDetailContactProps> = ({
  idCategoryLocal,
  phoneNumberRemote,
  contact = undefined,
  className = '',
}) => {
  const intl = useIntl();
  const { name: categoryName, color: categoryColor } = useWebAppSelector(selectCategoryById(idCategoryLocal)) || {};
  const { contactDisplayName, contactCompany, contactEmail, callDisplayPhoneNumber } = sipCallsExtractContactData({
    phoneNumberRemote,
    contact,
  });

  return (
    <div className={classNames(styles.root, className)}>
      <SIPCallsListItemUIDefaultFooterDetailContactItem
        label={intl.formatMessage({ id: 'SIP.Calls.UI.Contacts.label_fullname' })}
        value={contactDisplayName}
      />
      <SIPCallsListItemUIDefaultFooterDetailContactItem
        label={intl.formatMessage({ id: 'SIP.Calls.UI.Contacts.label_company' })}
        value={contactCompany}
      />
      <SIPCallsListItemUIDefaultFooterDetailContactItem
        label={intl.formatMessage({ id: 'SIP.Calls.UI.Contacts.label_category' })}
        value={categoryName}
        valueColor={categoryColor}
      />
      <SIPCallsListItemUIDefaultFooterDetailContactItem
        label={intl.formatMessage({ id: 'SIP.Calls.UI.Contacts.label_phonenumber' })}
        value={callDisplayPhoneNumber}
      />
      <SIPCallsListItemUIDefaultFooterDetailContactItem
        label={intl.formatMessage({ id: 'SIP.Calls.UI.Contacts.label_email' })}
        value={contactEmail}
      />
    </div>
  );
};

export default SIPCallsListItemUIDefaultFooterDetailContact;
