import dayjs from 'dayjs';

import { LocalizationService, SupportedLanguage } from 'types';

import { isLocaleValid } from 'helpers';

import { SUPPORTED_LANGUAGES, LOCALSTORAGE_KEYS } from '@constants';

const localizationService: LocalizationService = {
  getLocale: (): SupportedLanguage => {
    const languageLocalStorage = window.localStorage
      .getItem(LOCALSTORAGE_KEYS.LOCALE)
      ?.toLocaleUpperCase(SUPPORTED_LANGUAGES.EN);
    if (isLocaleValid(languageLocalStorage)) {
      return languageLocalStorage;
    }

    const languageNavigator = window.navigator.language?.split('-')[0]?.toLocaleUpperCase(SUPPORTED_LANGUAGES.EN);
    if (isLocaleValid(languageNavigator)) {
      return languageNavigator;
    }

    return SUPPORTED_LANGUAGES.EN;
  },

  setLocale: (locale: SupportedLanguage): void => {
    const localeAsUpperCase = locale?.toLocaleUpperCase(SUPPORTED_LANGUAGES.EN);
    const localeAsLowerCase = locale?.toLocaleLowerCase(SUPPORTED_LANGUAGES.EN);

    // TODO: Technical Depth
    dayjs.locale(localeAsLowerCase);

    window.localStorage.setItem(LOCALSTORAGE_KEYS.LOCALE, localeAsUpperCase);
    window.document.documentElement.lang = localeAsLowerCase;
  },
};

export default localizationService;
