import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

import { ContactPhoneNumberCard } from 'types';

type PrepareContactPhoneNumberCardsProps = {
  cards: ContactPhoneNumberCard[];
  countryCode: CountryCode;
};

export const prepareContactPhoneNumberCards = ({
  cards,
  countryCode,
}: PrepareContactPhoneNumberCardsProps): Required<ContactPhoneNumberCard>[] =>
  cards
    .map((card) => {
      if (!card.phoneNumber) {
        return card;
      }

      const parsedPhoneNumberObject = parsePhoneNumberFromString(card.phoneNumber, countryCode);
      const formattedPhoneNumber = parsedPhoneNumberObject?.formatInternational();

      if (!formattedPhoneNumber) {
        return card;
      }

      return { ...card, phoneNumber: formattedPhoneNumber };
    })
    .filter((card): card is Required<ContactPhoneNumberCard> => !!card.phoneNumber);
