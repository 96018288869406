import { useCallback } from 'react';

import { useWebAppSelector, selectSIPDataCallsCapabilities } from '@redux';
import { SIPStatisticsScopes, SIPWebRTCOriginators, SIPWebRTCSDPTypes } from '@redux/types';

import { sipDebugConsoleLogger, sipGetModifiedSDPForPCMACodec } from '../../../../../../../helpers';
import { useSIPStatistics } from '../../../../../../useSIPStatistics';

import { UseWebRTCSDP, OnSDP } from './types';

export const useWebRTCSDP: UseWebRTCSDP = () => {
  const { isCallsWebRTCHdCodecEnabled } = useWebAppSelector(selectSIPDataCallsCapabilities);

  const { sipSendErrorToSentry } = useSIPStatistics();

  const onSDP: OnSDP = useCallback(
    ({ event }) => {
      sipDebugConsoleLogger('SIP WebRTC: SDP EVENT: ', { event });

      /**
       * If we would like to use or modify any data during the SDP Transactions,
       * We can use this "event.sdp" (Session Description Protocol)
       * by the Type(Offer, Answer, etc...) and the Originator(Local, Remote)
       *
       * https://en.wikipedia.org/wiki/Session_Description_Protocol
       * https://webrtchacks.github.io/sdp-anatomy/
       */
      const { type, originator, sdp: sdpCurrent } = event;
      const sdpOriginator = originator as unknown as SIPWebRTCOriginators;
      const sdpType = type as SIPWebRTCSDPTypes;

      /**
       * PCMA Codec, a.k.a HD Calls
       */
      if (
        isCallsWebRTCHdCodecEnabled &&
        sdpOriginator === SIPWebRTCOriginators.LOCAL &&
        sdpType === SIPWebRTCSDPTypes.OFFER
      ) {
        try {
          /**
           * We now, param reassign is needed, and it is OK for this case
           */
          const { sdpNew } = sipGetModifiedSDPForPCMACodec({ sdpCurrent });
          // eslint-disable-next-line no-param-reassign
          event.sdp = sdpNew;
        } catch (error) {
          sipSendErrorToSentry({
            scope: SIPStatisticsScopes.SIP_WEBRTC,
            error: `SESSION SDP PCMA CODEC FAILED! "${error instanceof Error ? error.message : error}"`,
          });
        }
      }
    },
    [isCallsWebRTCHdCodecEnabled, sipSendErrorToSentry],
  );

  return { onSDP };
};
