import { ComponentType, LazyExoticComponent, lazy } from 'react';

import { LazyErrorBoundary, LazyReload } from '../../components';

export const lazyWithFallback = (
  importedComponent: () => Promise<{ default: ComponentType }>,
): LazyExoticComponent<ComponentType> =>
  lazy(async () => {
    const importedComponentName = importedComponent.name;
    const sessionStorageKey = `lazy-with-fallback-${importedComponentName}`;
    const isAlreadyReloadedOnce = window.sessionStorage.getItem(sessionStorageKey) === 'true';

    try {
      const component = await importedComponent();

      window.sessionStorage.setItem(sessionStorageKey, 'false');

      /**
       * We can use following line to test/dev purposes
       */
      // throw new Error('test');

      return component;
    } catch (_error) {
      /**
       * We would like to reload the page automatically only once,
       * If the error still exists, the user will see the Error Boundary
       * and they can do refreshes manually as much as they want
       */
      if (isAlreadyReloadedOnce === false) {
        window.sessionStorage.setItem(sessionStorageKey, 'true');

        return {
          default: LazyReload,
        };
      }

      return {
        default: LazyErrorBoundary,
      };
    }
  });
