import classNames from 'classnames';
import React, {
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { StyleableComponent } from 'types';

import { IconCommon, IconCommonNames } from 'components/Icon';
import { useClickAway } from 'hooks';

import { usePopper } from '../../hooks';
import InputSelectItem, { InputSelectItemProps } from '../InputSelectItem';

import styles from './InputSelect.module.scss';

export interface InputSelectProps extends StyleableComponent {
  isDisabled?: boolean;
  selected?: string;
  onChange: (id: string) => void;
  onDropdownToggle?: (isOpen: boolean) => void;
  placeholder?: string;
  errorMessage?: string;
}

const InputSelect: React.FC<InputSelectProps & PropsWithChildren> = ({
  onChange = () => null,
  selected = '',
  isDisabled = false,
  placeholder = '',
  onDropdownToggle,
  errorMessage,
  children,
  className,
}) => {
  const rootElementRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const currentlySelectedRef = useRef<ReactElement>();
  const visibleDropdownItemRefs = useRef<HTMLDivElement[]>([]);

  const hasError = errorMessage && errorMessage.length > 0;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useClickAway(rootElementRef, () => setIsDropdownOpen(false));

  usePopper({
    referenceElement: buttonRef.current,
    dropdownElement: dropdownRef.current,
    isOpen: isDropdownOpen,
    popperInstanceProps: {
      placement: 'bottom',
      strategy: 'fixed',
      gutter: 0,
    },
  });

  const toggleIsOpen = useCallback(() => setIsDropdownOpen((current) => !current), [setIsDropdownOpen]);

  useEffect(() => {
    onDropdownToggle?.(isDropdownOpen);
  }, [isDropdownOpen, onDropdownToggle]);

  const isDropdownItem = (item: unknown): item is ReactElement<InputSelectItemProps, typeof InputSelectItem> =>
    !!item && (item as JSX.Element).type?.displayName === 'SelectItem';

  const childrenArray = React.Children.toArray(children);
  const dropdownItemsArray = childrenArray.filter(isDropdownItem);

  const itemClickHandler = useCallback(
    (selectedId: string) => {
      if (selectedId !== selected) {
        onChange(selectedId);
      }

      toggleIsOpen();
      buttonRef.current?.focus();
    },
    [onChange, selected, toggleIsOpen],
  );

  const childElements = useMemo(
    () =>
      childrenArray.map((child) => {
        if (!isValidElement(child)) {
          return null;
        }

        if (!isDropdownItem(child)) {
          return child;
        }

        const isCurrentItemSelected = selected === child.props.id;
        const dropdownItemIndex = dropdownItemsArray.findIndex((elem) => elem.props.id === child.props.id);

        // Clone item with new props
        const item = React.cloneElement(child, {
          key: child.props.id,
          role: 'option',
          'aria-selected': isCurrentItemSelected,
          'data-value': child.props.children as string,
          isSelected: isCurrentItemSelected,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref: (node: HTMLDivElement) => {
            if (!child.props.isHidden) {
              visibleDropdownItemRefs.current[dropdownItemIndex] = node;
            }
          },
          onClick: (event: React.MouseEvent<HTMLDivElement>) => {
            itemClickHandler(child.props.id);
            if (child.props.onClick) {
              child.props.onClick(event);
            }
          },
        });

        if (isCurrentItemSelected) {
          currentlySelectedRef.current = (
            <InputSelectItem
              id={child.props.id}
              icon={child.props.icon}
              className={styles.currentlySelected}
            >
              {child.props.children}
            </InputSelectItem>
          );
        }

        return item;
      }),
    [childrenArray, dropdownItemsArray, itemClickHandler, selected],
  );

  useEffect(() => {
    if (!selected) {
      currentlySelectedRef.current = undefined;
    }
  }, [selected]);

  return (
    <div
      ref={rootElementRef}
      className={classNames(styles.root, className)}
      tabIndex={-1}
    >
      <div
        ref={buttonRef}
        className={classNames(styles.selectButton, { [styles.isDisabled]: isDisabled, [styles.hasError]: hasError })}
        aria-expanded={isDropdownOpen}
        aria-haspopup="listbox"
        onClick={toggleIsOpen}
        tabIndex={0}
      >
        {!currentlySelectedRef.current && placeholder && <span className={styles.placeholder}>{placeholder}</span>}

        {currentlySelectedRef.current}

        <IconCommon
          name={IconCommonNames.EXPAND_MORE}
          className={classNames(styles.dropdownIcon, { [styles.isOpen]: isDropdownOpen })}
        />
      </div>

      <div
        ref={dropdownRef}
        role="listbox"
        className={classNames(styles.dropdown, {
          [styles.isOpen]: isDropdownOpen,
        })}
      >
        {childElements}
      </div>

      {errorMessage && (
        <span
          data-testid="select-error"
          role="alert"
          className={styles.errorMessage}
        >
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default InputSelect;
