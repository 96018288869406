import React from 'react';

import { useWebAppSelector, selectSIPDataCallsRingtoneAvailability, selectSIPDataCallsCapabilities } from '@redux';
import { SIPDataCallStatusesRingtone, SIPDataCallStatusesWebRTC } from '@redux/types';
import { testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { useSIPCallMethodsOngoing } from '../../../../hooks/useSIPCallMethods';
import { SIPCallsListItemButtonUIRingtoneProps } from '../../types';

import styles from './SIPCallsListItemButtonUIRingtone.module.scss';

const SIPCallsListItemButtonUIRingtone: React.FC<SIPCallsListItemButtonUIRingtoneProps> = ({
  isCallLocal,
  isCallRemote,
  isCallOngoing,
  isCallWaiting,
  idWebRTC,
  statusWebRTC,
}) => {
  const { canRingtonePlay } = useWebAppSelector(selectSIPDataCallsRingtoneAvailability);
  const { isCallsIncomingRingtoneDisabled } = useWebAppSelector(selectSIPDataCallsCapabilities);
  const isRingtoneAvailable = canRingtonePlay && !isCallsIncomingRingtoneDisabled;
  const isCallRemoteAndConnecting = isCallRemote && statusWebRTC === SIPDataCallStatusesWebRTC.ANSWERED;

  const { sipCallChangeRingtone } = useSIPCallMethodsOngoing();

  const onClickHandlerChangeRingtoneAsMute = (): void => {
    sipCallChangeRingtone({
      idWebRTC,
      statusRingtone: SIPDataCallStatusesRingtone.MUTE,
    });
  };

  if (isCallLocal || isCallRemoteAndConnecting || isCallOngoing || isCallWaiting) {
    return null;
  }

  return (
    <IconButton
      iconName={isRingtoneAvailable ? IconCommonNames.VOLUME_ON : IconCommonNames.VOLUME_OFF}
      iconSize={IconCommonSizes.TINY}
      onClick={isRingtoneAvailable ? onClickHandlerChangeRingtoneAsMute : undefined}
      isDisabled={!isRingtoneAvailable}
      className={isRingtoneAvailable ? styles.root : undefined}
      propTestId={testIds.sipCallsListItemButtonUIRingtoneRoot}
    />
  );
};

export default SIPCallsListItemButtonUIRingtone;
