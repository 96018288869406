import React from 'react';

import { useWebAppSelector, selectCategoriesWithDynamicColor } from '@redux';
import { Modal, ModalSize } from 'components/Modal';

import { ModalsAddEditContactProps } from '../../types';
import ModalsAddEditContactContent from '../ModalsAddEditContactContent';

import styles from './ModalsAddEditContact.module.scss';

const ModalsAddEditContact: React.FC<ModalsAddEditContactProps> = ({
  isOpen,
  zIndex = undefined,
  isEditModal = false,
  isCRMContactModal = false,
  formDataDefault = undefined,
  onClose,
}) => {
  const categoryList = useWebAppSelector(selectCategoriesWithDynamicColor);

  if (categoryList.length === 0) {
    return null;
  }

  return (
    <Modal
      zIndex={zIndex}
      isOpen={isOpen}
      onRequestClose={onClose}
      size={ModalSize.XXL}
      className={styles.root}
    >
      <ModalsAddEditContactContent
        categoryList={categoryList}
        formDataDefault={formDataDefault}
        onClose={onClose}
        isEditModal={isEditModal}
        isCRMContactModal={isCRMContactModal}
      />
    </Modal>
  );
};

export default ModalsAddEditContact;
