import { PremiumPlanPriceConfig } from 'types';

export const hasValidPrices = (plan?: PremiumPlanPriceConfig): plan is PremiumPlanPriceConfig =>
  plan !== undefined && typeof plan.monthlyPrice === 'number' && typeof plan.yearlyPrice === 'number';

// TODO: Use big.js for comoparisons and calculations
export const calcPriceWithDiscount = (price: number, discountPercentage = 0) =>
  price - (price * (discountPercentage || 0)) / 100;

export const getPriceComparison = (optionA: PremiumPlanPriceConfig, optionB: PremiumPlanPriceConfig) => {
  const hasPriceAdvantage = optionA.monthlyPrice < optionB.monthlyPrice;
  const { price } = optionA;
  const { monthlyPrice } = optionA;
  const percentageSaved =
    hasPriceAdvantage && optionB.monthlyPrice ? 100 - (optionA.monthlyPrice * 100) / optionB.monthlyPrice : undefined;
  const priceInAdvantage = hasPriceAdvantage ? optionA.yearlyPrice : undefined;
  const priceInDisadvantage = hasPriceAdvantage ? optionB.yearlyPrice : undefined;
  const priceDifference = hasPriceAdvantage ? optionB.yearlyPrice - optionA.yearlyPrice : undefined;
  const priceWithDiscount = calcPriceWithDiscount(optionA.price, optionA.discountPercentage);

  return {
    price,
    monthlyPrice,
    percentageSaved,
    hasPriceAdvantage,
    priceInAdvantage,
    priceInDisadvantage,
    priceDifference,
    priceWithDiscount,
  };
};
