import { IntegrationContact } from 'types';

import { IntegrationBasicSearchContact } from '@redux/types';

export const findIntegrationIdForActiveContactId = (
  contactId: string,
  integrationContacts: IntegrationBasicSearchContact,
): string =>
  Object.keys(integrationContacts).find((key) => integrationContacts[key].some((detail) => detail.id === contactId)) ||
  '';

export const findIntegrationContactForActiveContactId = (
  contactId: string,
  integrationContacts: IntegrationBasicSearchContact,
): IntegrationContact | undefined =>
  Object.values(integrationContacts)
    .flatMap((contact) => contact)
    .find((contact) => contact.id === contactId);
