import classnames from 'classnames';
import React from 'react';

import { StyleableComponent, TestableComponent } from 'types';

import { testGetTestingAttributes } from '@testing';

import styles from './CategoryCommonButtonColor.module.scss';

export interface CategoryCommonButtonColorProps extends StyleableComponent, TestableComponent {
  color: string;
  onClick: () => void;
}

const CategoryCommonButtonColor: React.FC<CategoryCommonButtonColorProps> = ({
  color,
  onClick,
  propTestId,
  className = '',
}) => (
  <button
    type="button"
    className={classnames(styles.root, className)}
    style={{
      background: color,
    }}
    onClick={onClick}
    {...testGetTestingAttributes(propTestId)}
  ></button>
);

export default CategoryCommonButtonColor;
