import parsePhoneNumberFromString from 'libphonenumber-js';

import { Contact } from 'types';

import { removeNewLines, removePlusses, removeWhiteSpaces } from '../common';
import { getContactFullName, normalizePhoneNumber } from '../contact';

export const normalizeSearchTerm = (searchTerm: string): string => {
  const normalizedSearchTerm = removePlusses(removeWhiteSpaces(searchTerm)).toLowerCase();
  return normalizedSearchTerm;
};

export const normalizeTextAsCanonicalDecomposition = (text: string): string => {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
  const normalizedInput = text
    .normalize('NFD')
    .replaceAll(/[\u0300-\u036f]/gi, '')
    .replaceAll(/[\u0131]/g, 'i');
  return normalizedInput;
};

export const isPhoneNumberAndOrContactIncludesSearchTerm = ({
  contact,
  phoneNumber = '',
  summary = '',
  searchTerm = '',
}: {
  contact?: Partial<Contact>;
  phoneNumber?: string;
  summary?: string;
  searchTerm?: string;
}): boolean => {
  if (searchTerm.length < 1) {
    return true;
  }

  // number
  const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
  const parsedNumber = parsePhoneNumberFromString(`+${normalizedPhoneNumber}`);
  const phoneNumberNational = normalizePhoneNumber(parsedNumber?.formatNational() || '');
  const phoneNumberInternational = normalizePhoneNumber(parsedNumber?.formatInternational() || '');

  // contact
  const contactFullName = removeWhiteSpaces(getContactFullName(contact)).toLowerCase();

  // summary
  const normalizedSummary = removePlusses(removeNewLines(removeWhiteSpaces(summary))).toLowerCase();

  // search term
  const normalizedSearchTerm = normalizeTextAsCanonicalDecomposition(normalizeSearchTerm(searchTerm));

  // all data for search + normalized
  const dataList = [
    normalizedPhoneNumber,
    phoneNumberNational,
    phoneNumberInternational,
    contactFullName,
    normalizedSummary,
  ].map((data) => normalizeTextAsCanonicalDecomposition(data));

  // all data with at least one match
  const dataListWithMatch = dataList.filter((eachData) => eachData.includes(normalizedSearchTerm));

  // yay!
  return dataListWithMatch.length > 0;
};
