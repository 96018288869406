import classNames from 'classnames';
import React from 'react';

import { Category, StyleableComponent } from 'types';

import styles from './CategoryIconSmall.module.scss';

interface CategoryIconSmallProps extends StyleableComponent {
  color?: Category['color'];
}

const CategoryIconSmall: React.FC<CategoryIconSmallProps> = ({ color, className }) => (
  <div
    className={classNames(className, styles.root)}
    style={{ backgroundColor: color }}
  />
);

export default CategoryIconSmall;
