import { useCallback, useEffect } from 'react';

import { Status } from 'types';

import {
  browserPermissionsSetMicrophonePermission,
  selectAuthenticationStatuses,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { useResponsiveBreakpoints } from 'hooks';

import { requestMediaAccess } from '../helpers';

export const useBrowserMicrophonePermissions = () => {
  const dispatch = useWebAppDispatch();
  const { statusLogin } = useWebAppSelector(selectAuthenticationStatuses);
  const { isMobile } = useResponsiveBreakpoints();
  const shouldCheckForMicPermission = statusLogin === Status.SUCCEEDED && !isMobile;

  const handlePermissionChanges = useCallback(async () => {
    try {
      if (shouldCheckForMicPermission && 'permissions' in navigator) {
        await requestMediaAccess();
        dispatch(browserPermissionsSetMicrophonePermission('granted'));
      }
    } catch {
      dispatch(browserPermissionsSetMicrophonePermission('denied'));
    }
  }, [dispatch, shouldCheckForMicPermission]);

  const handleCheckMicPermission = useCallback(async () => {
    try {
      if (shouldCheckForMicPermission) {
        await navigator.permissions.query({ name: 'microphone' as PermissionName }).then((micPermission) => {
          // eslint-disable-next-line no-param-reassign
          micPermission.onchange = () => {
            dispatch(browserPermissionsSetMicrophonePermission(micPermission.state));
          };
        });
      }
    } catch {
      // ignore
    }
  }, [dispatch, shouldCheckForMicPermission]);

  useEffect(() => {
    handleCheckMicPermission();
  }, [dispatch, handleCheckMicPermission, isMobile, shouldCheckForMicPermission, statusLogin]);

  useEffect(() => {
    handlePermissionChanges();
  }, [dispatch, handlePermissionChanges, shouldCheckForMicPermission]);
};
