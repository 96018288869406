import { LegacyClientConfiguration, ClientConfigurationEnv } from 'types';

import universal from 'config/universal';

const config: LegacyClientConfiguration = {
  env: ClientConfigurationEnv.PROD,
  universal,
  API_CONTROLLER: universal.apiController,
  auth: {
    // https://developers.facebook.com/
    facebook: {
      id: process.env.FACEBOOK_APP_ID || '524114344381954',
    },
    // https://cloud.google.com/console/project
    google: {
      id: process.env.GOOGLE_CLIENT_ID || '893526095042-l48j8kpc090jghi74amul7h09lhj1gen.apps.googleusercontent.com',
      accessKeys: {
        fname: 'ofa',
        lname: 'wea',
        email: 'U3',
      },
    },
  },
};

export default config;
