import React from 'react';

import { StyleableComponent } from 'types';

const ContactDelete: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      cx="100"
      cy="100"
      r="100"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <circle
      cx="100"
      cy="100"
      r="87.3684"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <circle
      cx="100"
      cy="100"
      r="72.1053"
      fill="#F3F4F5"
    />
    <rect
      x="57.7036"
      y="4"
      width="75.9661"
      height="81"
      rx="4.57627"
      transform="rotate(20 57.7036 4)"
      fill="#E4E5E7"
    />
    <rect
      x="56.7036"
      y="3"
      width="75.9661"
      height="81"
      rx="4.57627"
      transform="rotate(20 56.7036 3)"
      fill="white"
    />
    <rect
      x="47.1844"
      y="65.2799"
      width="51.7119"
      height="5.49153"
      rx="0.915254"
      transform="rotate(20 47.1844 65.2799)"
      fill="#E2E2E2"
    />
    <circle
      cx="81.2832"
      cy="46.523"
      r="22.8814"
      transform="rotate(20 81.2832 46.523)"
      fill="#FAFAFA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.83 53.6376C96.9007 64.4329 84.9641 69.9991 74.1687 66.0699C63.3733 62.1407 57.8072 50.2041 61.7364 39.4087C65.6656 28.6133 77.6022 23.0472 88.3976 26.9764C99.193 30.9056 104.759 42.8422 100.83 53.6376ZM74.6134 64.8482C84.734 68.5318 95.9246 63.3136 99.6082 53.1929C103.292 43.0723 98.0736 31.8817 87.9529 28.198C77.8323 24.5144 66.6417 29.7327 62.9581 39.8533C59.2744 49.974 64.4927 61.1646 74.6134 64.8482Z"
      fill="white"
    />
    <circle
      cx="81.2831"
      cy="46.523"
      r="19.5012"
      transform="rotate(20 81.2831 46.523)"
      fill="#D4D7DB"
    />
    <mask
      id="mask0_2493_22498"
      maskUnits="userSpaceOnUse"
      x="61"
      y="27"
      width="40"
      height="40"
    >
      <circle
        cx="81.2831"
        cy="46.523"
        r="19.5012"
        transform="rotate(20 81.2831 46.523)"
        fill="#F1F1F1"
      />
    </mask>
    <g mask="url(#mask0_2493_22498)">
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.3605 48.6777C84.3413 50.1266 88.7429 48.0741 90.1918 44.0933C91.6407 40.1125 89.5882 35.7109 85.6074 34.262C81.6266 32.8131 77.225 34.8656 75.7761 38.8464C74.3272 42.8272 76.3797 47.2288 80.3605 48.6777ZM80.0381 49.9438C71.8066 46.9477 62.7049 51.1919 59.7089 59.4234L59.0864 61.1337L88.8952 71.9833L89.5177 70.2729C92.5138 62.0415 88.2696 52.9398 80.0381 49.9438Z"
        fill="white"
      />
    </g>
    <mask
      id="mask1_2493_22498"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="200"
      height="200"
    >
      <rect
        width="200"
        height="200"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_2493_22498)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7693 136.099C77.889 130.96 92.008 125.821 106.173 120.666C120.292 115.526 134.456 110.371 148.576 105.232C151.439 115.772 154.137 125.86 156.732 135.524C159.24 144.81 161.678 153.762 163.985 162.353C164.583 164.698 163.674 167.13 161.909 167.772C150.55 171.906 139.192 176.041 127.832 180.175C116.474 184.309 105.07 188.46 93.7104 192.594C91.9906 193.22 89.7486 191.987 88.682 189.761C84.9271 181.698 81.0406 173.272 76.9931 164.547C72.7693 155.476 68.3515 146.013 63.7693 136.099Z"
        fill="#C8CBCE"
      />
      <path
        d="M111.686 148.296C112.103 148.989 111.466 149.825 110.697 149.634L108.074 148.98L106.768 154.22C106.426 155.589 107.73 156.806 109.071 156.37L111.853 155.466C112.208 155.351 112.588 155.545 112.704 155.899L113.399 158.04C113.514 158.395 113.32 158.776 112.966 158.891L110.189 159.793C106.158 161.103 102.254 157.458 103.279 153.348L104.583 148.109L101.961 147.456C101.184 147.262 101.03 146.222 101.715 145.81L107.172 142.532C107.598 142.276 108.152 142.413 108.408 142.84L111.686 148.296ZM113.78 136.794L117.139 139.612L115.401 141.683C114.887 142.295 115.371 143.228 116.169 143.158L122.511 142.603C123.01 142.56 123.373 142.12 123.33 141.628L122.775 135.286C122.705 134.49 121.703 134.172 121.188 134.786L119.453 136.854L116.096 134.035C113.044 131.475 108.349 132.992 107.383 136.866L106.922 138.718C106.832 139.08 107.052 139.446 107.414 139.536L109.597 140.081C109.959 140.171 110.326 139.951 110.416 139.589L110.877 137.739C111.204 136.429 112.781 135.956 113.78 136.794ZM129.104 144.957L126.865 143.078C126.579 142.838 126.153 142.875 125.914 143.161L124.469 144.882C124.23 145.167 124.267 145.593 124.553 145.833L126.795 147.714C127.873 148.619 127.536 150.37 126.194 150.806L121.057 152.475L120.223 149.908C119.976 149.147 118.929 149.063 118.565 149.777L115.674 155.448C115.449 155.891 115.625 156.433 116.068 156.659L121.74 159.549C122.449 159.91 123.253 159.234 123.005 158.468L122.17 155.9L127.301 154.233C131.329 152.924 132.351 147.682 129.104 144.957Z"
        fill="white"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7693 136.099C77.889 130.96 92.008 125.821 106.173 120.666C120.292 115.526 134.456 110.371 148.576 105.232L151.488 115.908C137.697 117.442 123.013 120.891 108.215 126.277C93.4172 131.663 79.9518 138.46 68.4009 146.149L63.7693 136.099Z"
        fill="#8E929B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.2728 95.7215L127.598 70.1252C131.581 68.6756 136.025 70.7477 137.474 74.7305L148.576 105.232L63.7692 136.099L52.6675 105.598C51.2179 101.615 53.2901 97.1711 57.2728 95.7215Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.0714 124.95L145.775 93.029C146.679 92.6997 147.622 93.1766 147.935 94.0371L151.032 102.544C151.345 103.404 150.929 104.376 150.024 104.705L62.321 136.626C61.4161 136.956 60.4736 136.479 60.1606 135.619L57.0642 127.111C56.751 126.251 57.1665 125.28 58.0714 124.95Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5196 124.423L144.326 93.5562L143.454 91.1581L58.6467 122.025L59.5196 124.423Z"
        fill="#C8CBCE"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2793 122.794L30.6547 120.411L28 119.279L30.3838 117.654L31.5151 115L33.1397 117.384L35.7944 118.515L33.4106 120.14L32.2793 122.794Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.279 30.7944L146.655 28.4106L144 27.2793L146.384 25.654L147.515 23L149.14 25.3844L151.794 26.5151L149.411 28.1397L148.279 30.7944Z"
      fill="#E4E4E4"
    />
  </svg>
);

export default ContactDelete;
