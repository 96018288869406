import classNames from 'classnames';
import React, { useState } from 'react';

import { StyleableComponent } from 'types';

import LogoFallback from '../../assets/LogoFallback';

import styles from './IntegrationCRMLogo.module.scss';

interface IntegrationCRMLogoProps extends StyleableComponent {
  url?: string;
}

const IntegrationCRMLogo: React.FC<IntegrationCRMLogoProps> = ({ url, className }) => {
  const [hasError, setHasError] = useState(false);

  if (hasError) {
    return <LogoFallback />;
  }

  return (
    <img
      src={url}
      className={classNames(styles.root, className)}
      alt="Integration icon"
      onError={() => setHasError(true)}
    />
  );
};

export default IntegrationCRMLogo;
