import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast, showWarningToast } from '@onoff/toast-notification';

import {
  useWebAppDispatch,
  sipSetDataCallStatusWebRTCByWebRTCIdHandler,
  sipSetDataCallTimestampTerminatedByWebRTCIdHandler,
} from '@redux';
import { SIPWebRTCOriginators, SIPDataCallStatusesWebRTC, SIPStatisticsScopes } from '@redux/types';

import { sipDebugConsoleLogger } from '../../../../../../../helpers';
import { RTCSessionCauses } from '../../../../../../../types';
import { useSIPReportCallState } from '../../../../../../useSIPReportCallState';
import { useSIPStatistics } from '../../../../../../useSIPStatistics';

import { UseWebRTCFailed, OnFailed } from './types';

export const useWebRTCFailed: UseWebRTCFailed = () => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const {
    sipSendErrorToSentry,
    sipSendErrorToGTM,
    sipSendLogToGTMCallEnded,
    sipSendLogToGTMCallBusyOutgoing,
    sipSendLogToGTMCallNotFoundOutgoing,
    sipSendLogToGTMCallUnavailableOutgoing,
    sipSendLogToGTMCallAddressIncompleteOutgoing,
  } = useSIPStatistics();

  const { sipReportCallStateWebRTCIncomingCancelled } = useSIPReportCallState();

  const onFailed: OnFailed = useCallback(
    ({
      event: { cause, message, originator: eventOriginator },
      idWebRTC,
      idTransaction,
      originatorWebRTC,
      timeSessionStarted,
      timeSessionEnded,
      identityRemote,
      webRTCMOSStopAndGetResults,
    }) => {
      sipDebugConsoleLogger('SIP WebRTC: SESSION FAILED: ', { cause, message, eventOriginator });

      // Call States
      const isCallIncoming = originatorWebRTC === SIPWebRTCOriginators.REMOTE;
      const isCallOutgoing = !isCallIncoming;

      // Failure Reasons
      const isWebRTCSessionBusy = cause === RTCSessionCauses.BUSY;
      const isWebRTCSessionRejected = cause === RTCSessionCauses.REJECTED;
      const isWebRTCSessionNotFound = cause === RTCSessionCauses.NOT_FOUND;
      const isWebRTCSessionUnavailable = cause === RTCSessionCauses.UNAVAILABLE;
      const isWebRTCSessionAddressIncomplete = cause === RTCSessionCauses.ADDRESS_INCOMPLETE;
      const isWebRTCSessionFailed =
        [
          /**
           * REDIRECTED
           * REJECTED
           * BYE
           * CANCELED
           * NO_ANSWER
           * They are not a failure, they are same as ended
           */
          RTCSessionCauses.REDIRECTED,
          RTCSessionCauses.REJECTED,
          RTCSessionCauses.BYE,
          RTCSessionCauses.CANCELED,
          RTCSessionCauses.NO_ANSWER,

          /**
           * BUSY
           * This is not a failure, this is same as ended
           * We are also showing a toast message
           */
          RTCSessionCauses.BUSY,

          /**
           * NOT_FOUND
           * This is not a failure, this is same as ended
           * Number is not found/exist
           * We are also showing a toast message
           */
          RTCSessionCauses.NOT_FOUND,

          /**
           * UNAVAILABLE
           * This is not a failure, this is same as ended
           * Number is not available to call
           * We are also showing a toast message
           */
          RTCSessionCauses.UNAVAILABLE,

          /**
           * ADDRESS_INCOMPLETE
           * This is not a failure, this is same as ended
           * Number is not available to call or not exist
           * We are also showing a toast message
           */
          RTCSessionCauses.ADDRESS_INCOMPLETE,
        ].includes(cause as RTCSessionCauses) === false;

      /**
       * Set WebRTC Session Status as TERMINATED or FAILED by the Reasons
       */
      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusWebRTC: isWebRTCSessionFailed
            ? SIPDataCallStatusesWebRTC.FAILED
            : SIPDataCallStatusesWebRTC.TERMINATED,
        }),
      );

      /**
       * Set WebRTC Session Terminated Timestamp
       */
      dispatch(
        sipSetDataCallTimestampTerminatedByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallTimestampCallTerminated: new Date().getTime(),
        }),
      );

      /**
       * OUTGOING CALLS
       *
       * NOTIFICATIONS and LOGGERS
       * for "BUSY" or "NOT_FOUND" or "UNAVAILABLE" or "ANY ERRORS"
       */
      if (isCallOutgoing) {
        /**
         * Remote User Phone or Name or Empty
         */
        const remoteUserOrEmpty = identityRemote?.uri?.user ?? '';

        /**
         * The User is "BUSY"
         */
        if (isWebRTCSessionBusy) {
          // Notification
          showWarningToast({
            heading: intl.formatMessage({ id: 'SIP.Notifications.WebRTC.Busy.title' }),
            message: intl.formatMessage({ id: 'SIP.Notifications.WebRTC.Busy.description' }),
          });

          // GTM
          sipSendLogToGTMCallBusyOutgoing({
            propTransactionId: idTransaction,
            propPhoneNumberRemote: remoteUserOrEmpty,
          });
        }

        /**
         * The User is "NOT_FOUND" or "UNAVAILABLE" or "ADDRESS_INCOMPLETE"
         */
        if (isWebRTCSessionNotFound || isWebRTCSessionUnavailable || isWebRTCSessionAddressIncomplete) {
          /**
           * Notification for all 3
           */
          showWarningToast({
            heading: intl.formatMessage({ id: 'SIP.Notifications.WebRTC.NotFound.title' }),
            message: intl.formatMessage({ id: 'SIP.Notifications.WebRTC.NotFound.description' }),
          });

          /**
           * GTM for "NOT_FOUND"
           */
          if (isWebRTCSessionNotFound) {
            sipSendLogToGTMCallNotFoundOutgoing({
              propTransactionId: idTransaction,
              propPhoneNumberRemote: remoteUserOrEmpty,
            });
          }

          /**
           * GTM for "UNAVAILABLE"
           */
          if (isWebRTCSessionUnavailable) {
            sipSendLogToGTMCallUnavailableOutgoing({
              propTransactionId: idTransaction,
              propPhoneNumberRemote: remoteUserOrEmpty,
            });
          }

          /**
           * GTM for "ADDRESS_INCOMPLETE"
           */
          if (isWebRTCSessionAddressIncomplete) {
            sipSendLogToGTMCallAddressIncompleteOutgoing({
              propTransactionId: idTransaction,
              propPhoneNumberRemote: remoteUserOrEmpty,
            });
          }
        }

        /**
         * The WebRTC Session is Failed
         */
        if (isWebRTCSessionFailed) {
          // Notification
          showErrorToast({
            heading: intl.formatMessage({ id: 'SIP.Notifications.WebRTC.Failed.title' }),
            message: intl.formatMessage({ id: 'SIP.Notifications.WebRTC.Failed.description' }),
          });
        }
      }

      /**
       * INCOMING CALLS
       */
      if (isCallIncoming) {
        /**
         * The User clicked the "REJECT CALL" button
         */
        if (isWebRTCSessionRejected) {
          sipReportCallStateWebRTCIncomingCancelled({ transactionId: idTransaction });
        }
      }

      /**
       * OUTGOING && INCOMING CALLS
       *
       * LOGGERS
       * It is NOT a FAILURE
       * Send to GTM as LOG
       */
      if (!isWebRTCSessionFailed) {
        // Get MOS
        const {
          callMOS,
          callMOSJitterMin,
          callMOSJitterMax,
          callMOSJitterAverage,
          callMOSLatencyAverage,
          callMOSPacketsLost,
          callMOSDescription,
          callMOSNetworkType,
          callMOSNetworkChangedDuringTheCall,
        } = webRTCMOSStopAndGetResults({ idWebRTC });

        sipSendLogToGTMCallEnded({
          propTransactionId: idTransaction,
          propOriginatorInit: originatorWebRTC,
          propOriginatorEvent: eventOriginator as unknown as SIPWebRTCOriginators,
          propStatus: `${cause}`,
          propTimeStart: timeSessionStarted as unknown as string,
          propTimeEnd: timeSessionEnded as unknown as string,
          propCallMOS: callMOS,
          propCallMOSJitterMin: callMOSJitterMin,
          propCallMOSJitterMax: callMOSJitterMax,
          propCallMOSJitterAverage: callMOSJitterAverage,
          propCallMOSLatencyAverage: callMOSLatencyAverage,
          propCallMOSPacketsLost: callMOSPacketsLost,
          propCallMOSDescription: callMOSDescription,
          propCallMOSNetworkType: callMOSNetworkType,
          propCallMOSNetworkChangedDuringTheCall: callMOSNetworkChangedDuringTheCall,
        });
      }

      /**
       * LOGGERS
       * It is a FAILURE
       * Send to SENTRY as ERROR
       * Send to GTM as ERROR
       */
      if (isWebRTCSessionFailed) {
        // SENTRY: send error
        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_WEBRTC,
          error: `SESSION FAILED! "${cause}"`,
        });

        // GTM: send error
        sipSendErrorToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_WEBRTC,
          sipCallOriginator: originatorWebRTC,
          sipCallTitle: 'SESSION FAILED!',
          sipCallDescription: `${cause}`,
          sipCallTransactionId: idTransaction,
        });
      }
    },
    [
      dispatch,
      intl,
      sipSendLogToGTMCallBusyOutgoing,
      sipSendLogToGTMCallNotFoundOutgoing,
      sipSendLogToGTMCallUnavailableOutgoing,
      sipSendLogToGTMCallAddressIncompleteOutgoing,
      sipReportCallStateWebRTCIncomingCancelled,
      sipSendLogToGTMCallEnded,
      sipSendErrorToSentry,
      sipSendErrorToGTM,
    ],
  );

  return { onFailed };
};
