import React, { useRef, useState } from 'react';

import { MoreVertical } from '@onoff/icons';
import { IconButton } from '@onoff/ui';

import { testGetTestingAttributes, testIds } from '@testing';
import { useClickAway } from 'hooks';

import { InfoTopBarActionsProps, InfoTopBarActionsTypes } from '../../types';
import InfoTopBarActionsDropdown from '../InfoTopBarActionsDropdown';
import InfoTopBarActionsFeaturesCallsAndSMS from '../InfoTopBarActionsFeaturesCallsAndSMS';
import InfoTopBarActionsFeaturesContact from '../InfoTopBarActionsFeaturesContact';
import InfoTopBarActionsFeaturesSMSGroup from '../InfoTopBarActionsFeaturesSMSGroup';

import styles from './InfoTopBarActions.module.scss';

const InfoTopBarActions: React.FC<InfoTopBarActionsProps> = ({ type, phoneNumberOrEmpty, contact, category }) => {
  const refDropdown = useRef<HTMLDivElement>(null);
  const [stateDropdownVisible, setStateDropdownVisible] = useState<boolean>(false);

  const isPageCalls = type === InfoTopBarActionsTypes.PAGE_CALLS;
  const isPageMessages = type === InfoTopBarActionsTypes.PAGE_MESSAGES;
  const isPageMessagesGroup = type === InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP;
  const isPageVoicemails = type === InfoTopBarActionsTypes.PAGE_VOICEMAILS;
  const isPageContacts = type === InfoTopBarActionsTypes.PAGE_CONTACTS;

  const onClickHandlerDropdownOpen = (): void => {
    setStateDropdownVisible(true);
  };

  const onClickHandlerDropdownClose = (): void => {
    setStateDropdownVisible(false);
  };

  useClickAway(refDropdown, onClickHandlerDropdownClose);

  return (
    <div
      className={styles.root}
      {...testGetTestingAttributes(testIds.infoTopBarActionsRoot)}
    >
      {isPageContacts ? <InfoTopBarActionsFeaturesContact contact={contact} /> : null}

      {isPageCalls || isPageVoicemails || isPageMessages ? (
        <InfoTopBarActionsFeaturesCallsAndSMS
          type={type}
          phoneNumberOrEmpty={phoneNumberOrEmpty}
          contact={contact}
          category={category}
        />
      ) : null}

      {isPageMessagesGroup ? <InfoTopBarActionsFeaturesSMSGroup /> : null}

      <div
        ref={refDropdown}
        className={styles.more}
      >
        <IconButton
          onClick={onClickHandlerDropdownOpen}
          {...testGetTestingAttributes(testIds.infoTopBarActionsButtonMore)}
        >
          <MoreVertical />
        </IconButton>
        <InfoTopBarActionsDropdown
          isOpened={stateDropdownVisible}
          type={type}
          phoneNumberOrEmpty={phoneNumberOrEmpty}
          contact={contact}
        />
      </div>
    </div>
  );
};

export default InfoTopBarActions;
