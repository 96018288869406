import { minTwoDigitNumberAsString } from 'helpers';

export interface CreateCreditCardExpireDateProps {
  year?: number;
  month?: number;
}

export const createCreditCardExpireDate = ({ year = 2014, month = 1 }: CreateCreditCardExpireDateProps): string =>
  `${minTwoDigitNumberAsString(month)} / ${year}`;

export interface CreateCreditCardNumberProps {
  last4: string;
  specialChar?: string;
}

export const createCreditCardNumber = ({ last4, specialChar = '*' }: CreateCreditCardNumberProps): string =>
  `${specialChar.repeat(4)} ${specialChar.repeat(4)} ${specialChar.repeat(4)} ${last4}`;

export const formatAndCleanCreditCardBrand = (brand: string): string =>
  brand.toLocaleLowerCase('en').replace(/\s/gm, '');
