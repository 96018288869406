import React from 'react';

import { testGetTestingAttributes, testIds } from '@testing';
import { useUserCapabilities } from 'hooks';

import { SIPCallsListItemUIDefaultProps } from '../../types';
import SIPCallsListItemButtonFeaturesAnswer from '../SIPCallsListItemButtonFeaturesAnswer';
import SIPCallsListItemButtonFeaturesCallNotes from '../SIPCallsListItemButtonFeaturesCallNotes';
import SIPCallsListItemButtonFeaturesDTMF from '../SIPCallsListItemButtonFeaturesDTMF';
import SIPCallsListItemButtonFeaturesHold from '../SIPCallsListItemButtonFeaturesHold';
import SIPCallsListItemButtonFeaturesMute from '../SIPCallsListItemButtonFeaturesMute';
import SIPCallsListItemButtonFeaturesReject from '../SIPCallsListItemButtonFeaturesReject';
import SIPCallsListItemButtonFeaturesTerminate from '../SIPCallsListItemButtonFeaturesTerminate';
import SIPCallsListItemButtonUIRingtone from '../SIPCallsListItemButtonUIRingtone';
import SIPCallsListItemButtonUIView from '../SIPCallsListItemButtonUIView';
import SIPCallsListItemIconStates from '../SIPCallsListItemIconStates';
import SIPCallsListItemInfoContact from '../SIPCallsListItemInfoContact';
import SIPCallsListItemInfoStates from '../SIPCallsListItemInfoStates';
import SIPCallsListItemMoreMenu from '../SIPCallsListItemMoreMenu';
import { SIPCallsListItemNotificationCallRecording } from '../SIPCallsListItemNotificationCallRecording';
import SIPCallsListItemUIDefaultFooter from '../SIPCallsListItemUIDefaultFooter';

import styles from './SIPCallsListItemUIDefault.module.scss';

const SIPCallsListItemUIDefault: React.FC<SIPCallsListItemUIDefaultProps> = ({
  data,
  isCallLocal,
  isCallRemote,
  isCallOngoing,
  isCallWaiting,
  onClickHandlerCallNotesModalOpen,
  onClickHandlerCallTransferModalOpen,
}) => {
  const { ids, statuses, phoneNumbers, timestamps } = data;
  const { isUserB2B } = useUserCapabilities();

  return (
    <div
      className={styles.root}
      {...testGetTestingAttributes(testIds.sipCallsListItemUIDefaultRoot)}
    >
      <div className={styles.notifications}>
        <SIPCallsListItemNotificationCallRecording
          data={data}
          isCallOngoing={isCallOngoing}
        />
      </div>
      <div
        className={styles.header}
        {...testGetTestingAttributes(testIds.sipCallsListItemUIDefaultHeader)}
      >
        <SIPCallsListItemButtonUIRingtone
          isCallLocal={isCallLocal}
          isCallRemote={isCallRemote}
          isCallOngoing={isCallOngoing}
          isCallWaiting={isCallWaiting}
          idWebRTC={ids.webRTC}
          statusWebRTC={statuses.webRTC}
        />
        <SIPCallsListItemIconStates
          isCallLocal={isCallLocal}
          isCallRemote={isCallRemote}
          isCallOngoing={isCallOngoing}
          isCallWaiting={isCallWaiting}
          statusWebRTC={statuses.webRTC}
        />
        <SIPCallsListItemInfoStates
          isCallLocal={isCallLocal}
          isCallRemote={isCallRemote}
          isCallOngoing={isCallOngoing}
          isCallWaiting={isCallWaiting}
          statusCallInitializing={statuses.callInitialising}
          statusWebRTC={statuses.webRTC}
          statusView={statuses.view}
          timestampCallRang={timestamps.callRang}
          timestampCallAnswered={timestamps.callAnswered}
        />
        <SIPCallsListItemButtonUIView
          statusView={statuses.view}
          idWebRTC={ids.webRTC}
        />
      </div>
      <div
        className={styles.body}
        {...testGetTestingAttributes(testIds.sipCallsListItemUIDefaultBody)}
      >
        <SIPCallsListItemInfoContact
          statusView={statuses.view}
          idCategoryLocal={ids.categoryLocal}
          phoneNumberRemote={phoneNumbers.remote}
          isCallOngoing={isCallOngoing}
        />
        <div className={styles.actions}>
          <div className={styles.actionsLeft}>
            <SIPCallsListItemButtonFeaturesMute
              isCallLocal={isCallLocal}
              isCallRemote={isCallRemote}
              isCallOngoing={isCallOngoing}
              isCallWaiting={isCallWaiting}
              statusFeatureMute={statuses.featureMute}
              idWebRTC={ids.webRTC}
            />
            <SIPCallsListItemButtonFeaturesDTMF
              isCallLocal={isCallLocal}
              isCallRemote={isCallRemote}
              isCallOngoing={isCallOngoing}
              isCallWaiting={isCallWaiting}
              statusWebRTC={statuses.webRTC}
              idWebRTC={ids.webRTC}
            />
            {isUserB2B ? (
              <SIPCallsListItemMoreMenu
                isCallLocal={isCallLocal}
                isCallRemote={isCallRemote}
                isCallOngoing={isCallOngoing}
                isCallWaiting={isCallWaiting}
                onMenuItemCallNotesClick={onClickHandlerCallNotesModalOpen}
                onMenuItemCallTransferClick={onClickHandlerCallTransferModalOpen}
              />
            ) : (
              <SIPCallsListItemButtonFeaturesCallNotes
                isCallLocal={isCallLocal}
                isCallRemote={isCallRemote}
                isCallOngoing={isCallOngoing}
                isCallWaiting={isCallWaiting}
                onClickHandlerCallNotesModalOpen={onClickHandlerCallNotesModalOpen}
              />
            )}
          </div>
          <div className={styles.actionsRight}>
            <SIPCallsListItemButtonFeaturesHold
              isCallLocal={isCallLocal}
              isCallRemote={isCallRemote}
              isCallOngoing={isCallOngoing}
              isCallWaiting={isCallWaiting}
              statusFeatureHold={statuses.featureHold}
              idWebRTC={ids.webRTC}
            />
            <SIPCallsListItemButtonFeaturesAnswer
              isCallLocal={isCallLocal}
              isCallRemote={isCallRemote}
              isCallOngoing={isCallOngoing}
              isCallWaiting={isCallWaiting}
              statusWebRTC={statuses.webRTC}
              idWebRTC={ids.webRTC}
            />
            <SIPCallsListItemButtonFeaturesReject
              isCallLocal={isCallLocal}
              isCallRemote={isCallRemote}
              isCallOngoing={isCallOngoing}
              isCallWaiting={isCallWaiting}
              statusWebRTC={statuses.webRTC}
              idWebRTC={ids.webRTC}
            />
            <SIPCallsListItemButtonFeaturesTerminate
              isCallLocal={isCallLocal}
              isCallRemote={isCallRemote}
              isCallOngoing={isCallOngoing}
              isCallWaiting={isCallWaiting}
              statusWebRTC={statuses.webRTC}
              idWebRTC={ids.webRTC}
            />
          </div>
        </div>
      </div>
      <div
        className={styles.footer}
        {...testGetTestingAttributes(testIds.sipCallsListItemUIDefaultFooter)}
      >
        <SIPCallsListItemUIDefaultFooter
          idCategoryLocal={ids.categoryLocal}
          phoneNumberRemote={phoneNumbers.remote}
        />
      </div>
    </div>
  );
};

export default SIPCallsListItemUIDefault;
