import {
  CategoriesService,
  DeleteCategoryRequest,
  FetchCategoriesResponse,
  SwitchCategoryPrivacyRequest,
  CategorySwitchStatusRequest,
  SwitchCategoryVisibilityRequest,
  UpdateCategoryRequest,
  CancelSubscriptionRequest,
} from 'types';

import { apiMobile } from 'api';

const categoriesService: CategoriesService = {
  deleteCategory: (categoryId) => apiMobile.post<void, DeleteCategoryRequest>('/delete-category', { categoryId }),

  fetchCategories: () =>
    apiMobile
      .get<FetchCategoriesResponse>('v3/get-categories', { inclCounter: true })
      .then(({ categories }) => categories),

  switchCategoryPrivacy: (categoryId, toPrivate, privatePassword) =>
    apiMobile.post<void, SwitchCategoryPrivacyRequest>('/switch-category-privacy', {
      categoryId,
      switchOn: toPrivate,
      privatePassword,
    }),

  switchCategoryStatus: (statusUpdate) =>
    apiMobile.post<void, CategorySwitchStatusRequest>('/switch-category-status', statusUpdate),

  switchCategoryVisibility: (categoryId, visible) =>
    apiMobile.post<void, SwitchCategoryVisibilityRequest>('/switch-category-visibility', {
      categoryId,
      visible,
    }),

  updateCategory: (category) =>
    apiMobile.post<void, UpdateCategoryRequest>('/update-category', {
      id: category.id,
      color: category.color,
      name: category.name,
    }),

  cancelSubscription: (cancelSubscriptionRequest) =>
    apiMobile.post<void, CancelSubscriptionRequest>(
      '/authenticated/category/cancel-subscription',
      cancelSubscriptionRequest,
    ),
};

export default categoriesService;
