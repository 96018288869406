import {
  CheckPasswordResponse,
  CheckPasswordRequest,
  FetchAllowedCountriesResponse,
  FetchAvailableCountriesResponse,
  FetchUserCreditsResponse,
  FetchUserGeoResponse,
  FetchUserResponse,
  UpdateUserLanguageRequest,
  UserService,
  UpdateNameRequest,
  UpdateEmailRequest,
  UpdatePasswordRequest,
  FetchKycStatusResponse,
} from 'types';

import { apiMobile } from 'api';

const userService: UserService = {
  checkPassword: (password) =>
    apiMobile.post<CheckPasswordResponse, CheckPasswordRequest>('/check-password', {
      password,
    }),

  deleteUser: () => apiMobile.post<void>('/delete-user', {}),

  fetchUser: () => apiMobile.get<FetchUserResponse>('/get-user', {}),

  fetchKycStatus: () =>
    apiMobile.get<FetchKycStatusResponse>('/kyc/status', {}),

  fetchUserAvailableCountries: () =>
    apiMobile.get<FetchAvailableCountriesResponse>('v2/list-countries-for-numbers', {}),

  fetchUserAllowedCountries: () => apiMobile.get<FetchAllowedCountriesResponse>('v2/list-countries-for-dialer', {}),

  fetchUserCredits: () => apiMobile.get<FetchUserCreditsResponse>('/get-user-credits', {}),

  fetchUserGeoLocation: async (): Promise<FetchUserGeoResponse> => {
    const response = await window.fetch(process.env.REACT_APP_GEO_IP_URL || '');
    return response.json();
  },

  updateEmail: (email) => apiMobile.post<void, UpdateEmailRequest>('/change-user-email', { email }),

  updateLanguage: (locale) => apiMobile.post<void, UpdateUserLanguageRequest>('/update-locale', { locale }),

  updateName: (firstName, lastName) => apiMobile.post<void, UpdateNameRequest>('/change-name', { firstName, lastName }),

  updatePassword: (oldPassword, newPassword) =>
    apiMobile.post<void, UpdatePasswordRequest>('/change-password', {
      oldPassword,
      newPassword,
    }),

  toggleCallQualitySurveyPermission: (params) => apiMobile.post<void>('/update-call-quality-survey', params),
};

export default userService;
