import { browserTabsInitializeMultiTabManagement, browserTabsIsCurrentBrowserTabActive } from 'helpers';

import { REDUX_ACTION_TYPES, ThunkResult, SetIsCurrentBrowserTabActive } from '../../types';

export const browserTabsSetIsCurrentTabActive = (isActive: boolean): SetIsCurrentBrowserTabActive => ({
  type: REDUX_ACTION_TYPES.BROWSER_TABS_SET_IS_CURRENT_ACTIVE,
  isActive,
});

export const browserTabsSetCurrentBrowserTabAsActiveHandler = (): ThunkResult<void> => async (dispatch) => {
  browserTabsInitializeMultiTabManagement();

  dispatch(browserTabsSetIsCurrentTabActive(true));
};

export const browserTabsValidateActiveBrowserTabHandler = (): ThunkResult<void> => async (dispatch) => {
  const isCurrentTabActive = browserTabsIsCurrentBrowserTabActive();

  dispatch(browserTabsSetIsCurrentTabActive(isCurrentTabActive));
};
