import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

import { Contact, PhoneNumberContactsMap } from 'types';

import { normalizePhoneNumber } from 'helpers';

import { Categories } from '@redux/types';

export const isContactFilterByVisibility = (categories: Categories, item: Contact): boolean => {
  // user has no categories
  if (!Object.keys(categories).length) {
    return true;
  }

  const { categoryId = '' } = item;

  // contact has no category - it's visible
  if (categoryId.length === 0) {
    return true;
  }

  // refer to categories' visibility
  const category = categories[categoryId] || null;

  if (category === null) {
    return false;
  }

  return category.isVisible;
};

export const isContactFilterByFavorite = (isFavoritesOnly: boolean, contact: Partial<Contact>): boolean => {
  if (!isFavoritesOnly) {
    return true;
  }

  return contact.favorite || false;
};

export const findContactsByPhoneNumber = (
  phoneNumbersContactMap: PhoneNumberContactsMap,
  phoneNumbers?: Array<string>,
  countryCode?: CountryCode,
): Array<Contact> | undefined => {
  if (phoneNumbers === undefined) {
    return undefined;
  }

  return phoneNumbers
    .map((phoneNumber) => {
      const phoneNumberInternational =
        parsePhoneNumberFromString(phoneNumber, countryCode)?.formatInternational() || phoneNumber;
      const phoneNumberNormalized = normalizePhoneNumber(phoneNumberInternational);
      return phoneNumbersContactMap[phoneNumberNormalized];
    })
    .filter((contact) => contact !== undefined);
};
