import { TestableComponent } from 'types';

export enum ModalsCallDetailsDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export interface ModalsCallDetailsDataProps {
  transactionId: string;
  callDurationStart: number;
  callDurationEnd: number;
  callDirection: ModalsCallDetailsDirection;
  displayContactName: string;
  displayCompanyName: string;
  displayPhoneNumber: string;
  displayPhoneNumberType: string;
}

export interface ModalsCallDetailsProps extends TestableComponent {
  isOpen: boolean;
  data: ModalsCallDetailsDataProps;
  onCloseHandler: () => void;
}

export type ModalsCallDetailsHeaderProps = Omit<ModalsCallDetailsDataProps, 'transactionId'>;
