import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { Loading } from '@onoff/ui';

import { isB2BProject } from 'helpers/common';

import Logo from 'components/Logo';
import { PageBase } from 'components/PageBase';

import { LayoutProps } from '../../types';

import styles from './LayoutAuth.module.scss';

const LayoutAuth: React.FC<LayoutProps & PropsWithChildren> = ({ children, isAvailableOnMobile }) => (
  <React.Suspense fallback={<Loading />}>
    <PageBase isAvailableOnMobile={isAvailableOnMobile}>
      <div className={styles.root}>
        <div className={classnames(styles.imageContainer, { [styles.isB2B]: isB2BProject() })}></div>
        <div className={styles.content}>
          <div className={styles.logoContainer}>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    </PageBase>
  </React.Suspense>
);

export default LayoutAuth;
