import classNames from 'classnames';
import React, { HTMLAttributes, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Bolt, Call, Headset, Lock } from '@onoff/icons';
import { Tooltip, TooltipSize, TooltipTheme } from '@onoff/ui';

import { CallTransferType } from 'types';

import styles from './TransferTypeButton.module.scss';

type TransferTypeButtonProps = Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  type: CallTransferType;
  onClick: (type: CallTransferType) => void;
  isSelected: boolean;
  isDisabled?: boolean;
};

export const TransferTypeButton: React.FC<TransferTypeButtonProps> = ({
  type,
  onClick,
  isSelected,
  isDisabled = false,
  ...props
}) => {
  const intl = useIntl();
  const [isHovered, setIsHovered] = useState(false);

  const isTypeCold = type === CallTransferType.COLD;
  const isTypeWarm = type === CallTransferType.WARM;

  let title = '';
  let subtitle = '';

  if (isTypeCold) {
    title = intl.formatMessage({ id: 'Modals.CallTransferring.heading_cold_transfer' });
    subtitle = intl.formatMessage({ id: 'Modals.CallTransferring.subheading_cold_transfer' });
  }
  if (isTypeWarm) {
    title = intl.formatMessage({ id: 'Modals.CallTransferring.heading_warm_transfer' });
    subtitle = intl.formatMessage({ id: 'Modals.CallTransferring.subheading_warm_transfer' });
  }

  const onClickHandler = () => {
    if (!isDisabled) {
      onClick(type);
    }
  };

  const onMouseEnterHandler = () => {
    setIsHovered(true);
  };

  const onMouseLeaveHandler = () => {
    setIsHovered(false);
  };

  return (
    <button
      className={classNames(styles.root, {
        [styles.cold]: isTypeCold,
        [styles.warm]: isTypeWarm,
        [styles.disabled]: isDisabled,
        [styles.selected]: isSelected,
      })}
      onClick={onClickHandler}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      {...props}
    >
      <span className={styles.header}>
        <span className={styles.icon}>
          {isTypeCold && <Bolt size="small" />}
          {isTypeWarm && <Headset size="small" />}
        </span>

        <span className={styles.title}>{title}</span>
        <span className={styles.subtitle}>{subtitle}</span>

        {isDisabled && (
          <span className={styles.lock}>
            <Tooltip
              content={intl.formatMessage({ id: 'Modals.CallTransferring.coming_soon' })}
              theme={TooltipTheme.DARK}
              placement="bottom"
              size={TooltipSize.SMALL}
              gutter={5}
              isOpen={isHovered}
              referenceStyle={{ cursor: 'default' }}
            >
              <Lock size="small" />
            </Tooltip>
          </span>
        )}
      </span>

      {isTypeCold && (
        <span className={styles.diagram}>
          <span className={styles.participant}>
            <Call />
            <FormattedMessage id="Modals.CallTransferring.diagram_a" />
          </span>
          <span className={styles.arrow} />
          <span className={styles.participant}>
            <Call />
            <FormattedMessage id="Modals.CallTransferring.diagram_b" />
          </span>
        </span>
      )}

      {isTypeWarm && (
        <span className={classNames(styles.diagram, styles.wide)}>
          <span className={styles.participant}>
            <Call />
            <FormattedMessage id="Modals.CallTransferring.diagram_a" />
          </span>
          <span className={styles.arrow} />
          <span className={styles.participant}>
            <Headset />
            <FormattedMessage id="Modals.CallTransferring.diagram_c" />
          </span>
          <span className={styles.arrow} />
          <span className={styles.participant}>
            <Call />
            <FormattedMessage id="Modals.CallTransferring.diagram_b" />
          </span>
        </span>
      )}
    </button>
  );
};
