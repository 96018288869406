import { createSelector } from '@reduxjs/toolkit';

import { SelectCheckoutState } from '../../types';

export const selectCheckoutState: SelectCheckoutState = (state) => state.checkout;

export const selectCheckoutPublicKeyState = createSelector([selectCheckoutState], (state) => state.checkoutPublicKey);

export const selectCheckoutRedirectConfirmationURLState = createSelector(
  [selectCheckoutState],
  (state) => state.redirectConfirmationURL,
);
