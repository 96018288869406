import { callStateReportingService } from 'services';

import {
  SIPReportCallStateUserAgentRegisteredProps,
  SIPReportCallStateWebRTCIncomingAnswerProps,
  SIPReportCallStateWebRTCIncomingCancelledProps,
  SIPReportCallStateWebRTCIncomingPushReceivedProps,
  SIPReportCallStateWebRTCIncomingShownProps,
  UseSIPReportCallStateReturn,
} from '../../types';

export const useSIPReportCallState = (): UseSIPReportCallStateReturn => {
  const sipReportCallStateUserAgentRegistered = (props: SIPReportCallStateUserAgentRegisteredProps): void => {
    callStateReportingService
      .reportIncomingCallState({
        ...props,
        callState: 'SIP_REGISTERED',
      })
      .catch(() => {
        /* ignore */
      });
  };

  const sipReportCallStateWebRTCIncomingPushReceived = (
    props: SIPReportCallStateWebRTCIncomingPushReceivedProps,
  ): void => {
    callStateReportingService
      .reportIncomingCallState({
        ...props,
        callState: 'RECEIVED',
      })
      .catch(() => {
        /* ignore */
      });
  };

  const sipReportCallStateWebRTCIncomingShown = (props: SIPReportCallStateWebRTCIncomingShownProps): void => {
    callStateReportingService
      .reportIncomingCallState({
        ...props,
        callState: 'SHOWN',
      })
      .catch(() => {
        /* ignore */
      });
  };

  const sipReportCallStateWebRTCIncomingCancelled = (props: SIPReportCallStateWebRTCIncomingCancelledProps): void => {
    callStateReportingService
      .reportIncomingCallState({
        ...props,
        callState: 'CANCELLED',
      })
      .catch(() => {
        /* ignore */
      });
  };

  const sipReportCallStateWebRTCIncomingAnswer = (props: SIPReportCallStateWebRTCIncomingAnswerProps): void => {
    callStateReportingService
      .reportIncomingCallState({
        ...props,
        callState: 'ACCEPTED',
      })
      .catch(() => {
        /* ignore */
      });
  };

  const sipReportCallStateCallingCancelledReceived = (props: SIPReportCallStateWebRTCIncomingAnswerProps): void => {
    callStateReportingService
      .reportIncomingCallState({
        ...props,
        callState: 'CALLING_CANCELLED_RECEIVED',
      })
      .catch(() => {
        /* ignore */
      });
  };

  return {
    sipReportCallStateUserAgentRegistered,
    sipReportCallStateWebRTCIncomingPushReceived,
    sipReportCallStateWebRTCIncomingShown,
    sipReportCallStateWebRTCIncomingCancelled,
    sipReportCallStateWebRTCIncomingAnswer,
    sipReportCallStateCallingCancelledReceived,
  };
};
