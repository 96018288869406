import { browserTabsIsCurrentBrowserTabActive, handleApplicationError } from 'helpers';

import {
  selectBrowserPermissionMicrophoneStatus,
  sipPubNubInitiateIncomingCallHandler,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { SIPEvent, SIPWebRTCOriginators } from '@redux/types';
import { sentryService } from 'services';
import { useSIPReportCallState } from 'sip';

import { SIPStatisticsScopes } from '../../../../@redux/types';
import { sipDebugConsoleLogger } from '../../../../sip/helpers';
import { useSIPStatistics } from '../../../../sip/hooks/useSIPStatistics';
import {
  PubNubPushMessageVoIPCallCancelledPayload,
  PubNubPushMessageVoIPIncomingCallPayload,
  UsePubNubMessagesHandlerVoipCallsReturn,
} from '../../types';

export const usePubNubMessagesHandlerVoipCalls = (): UsePubNubMessagesHandlerVoipCallsReturn => {
  const dispatch = useWebAppDispatch();

  const { sipReportCallStateWebRTCIncomingPushReceived, sipReportCallStateCallingCancelledReceived } =
    useSIPReportCallState();
  const microphonePermission = useWebAppSelector(selectBrowserPermissionMicrophoneStatus);

  const pubNubMessageHandlerVoipIncomingCall = async (props: PubNubPushMessageVoIPIncomingCallPayload) => {
    const {
      incomingCallId = '',
      transactionId = '',
      calleeId = '',
      callerId = '',
      clir = false,
      recording = false,
      sipConfig,
      sipCredentials,
      callId = '',
    } = props;

    const { sipSendLogToGTM } = useSIPStatistics();

    if (microphonePermission === 'denied') {
      sipDebugConsoleLogger('SIP MEDIA_PERMISSION: INCOMING CALL MEDIA PERMISSION REQUIRED!');

      sipSendLogToGTM({
        sipCallCase: SIPStatisticsScopes.SIP_MEDIA_PERMISSION,
        sipCallOriginator: SIPWebRTCOriginators.REMOTE,
        sipCallTitle: 'INCOMING CALL',
        sipCallDescription: 'MEDIA PERMISSION REQUIRED!',
        sipCallTransactionId: '',
      });
    }

    /**
     * Check the SIP Instance Id and Browser Tab Id and
     * prevent the Calls for these passive Browser Tab(s) by the IDs are not identical
     * which means, the tab is not active.
     * We are allowing the Calls only with one and active Browser tab
     * So, just return
     */
    const isActiveBrowserTab = browserTabsIsCurrentBrowserTabActive();
    if (!isActiveBrowserTab) {
      return;
    }

    sentryService.addBreadcrumb({
      message: SIPEvent.PUSH_RECEIVED_INCOMING_CALL,
      data: props,
    });

    try {
      await dispatch(
        sipPubNubInitiateIncomingCallHandler({
          propIdCall: incomingCallId,
          propIdTransaction: transactionId,
          propPhoneNumberLocal: calleeId,
          propPhoneNumberRemote: callerId,
          propIsCLIREnabled: clir === true,
          propIsCallRecorded: recording === true,
          propSipConfig: {
            sipDomain: sipConfig.domain,
            stunSrvRecord: sipConfig.stun,
            sipTechDomain: sipConfig.techDomain,
            wssSrvRecord: sipConfig.wss,
          },
          propSipCredentials: sipCredentials,
          propCallIdentifier: callId,
        }),
      );
    } catch (error) {
      handleApplicationError({ error });
    }

    sipReportCallStateWebRTCIncomingPushReceived({
      transactionId,
      incomingCallId,
    });
  };

  const pubNubMessageHandlerVoipCallCancelled = async (props: PubNubPushMessageVoIPCallCancelledPayload) => {
    sipReportCallStateCallingCancelledReceived({ transactionId: props.transactionId });

    sentryService.addBreadcrumb({
      message: SIPEvent.PUSH_RECEIVED_CALL_CANCELLED,
      data: props,
    });

    // TODO :: Use this push to handle resetting userAgent and un-registering (as of now it works through timeout)
  };

  return {
    pubNubMessageHandlerVoipIncomingCall,
    pubNubMessageHandlerVoipCallCancelled,
  };
};
