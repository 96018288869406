import classNames from 'classnames';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useEffect, useMemo, useState } from 'react';

import { showErrorToast } from '@onoff/toast-notification';

import { normalizePhoneNumber } from 'helpers';

import { SIP } from '@constants';
import {
  useWebAppSelector,
  selectSIPStatuses,
  selectCategoriesByCanMakeCalls,
  selectSIPDataCallsByVisibility,
} from '@redux';
import {
  SIPDataCallStatusesWebRTC,
  SIPDataCallStatusesFeatureMute,
  SIPDataCallStatusesFeatureUnMute,
  SIPDataCallStatusesFeatureHold,
  SIPDataCallStatusesFeatureUnHold,
  SIPDataCallStatusesView,
  SIPDataCallStatusesRingtone,
  SIPWebRTCOriginators,
  SIPStatisticsCallCTAs,
} from '@redux/types';
import { useUserCapabilities } from 'hooks';

import { useSIPCallMethodsOngoing, useSIPCallMethodsOutgoing } from '../../../../hooks/useSIPCallMethods';

import styles from './SIPDebugCore.module.scss';

const SIPDebugCore: React.FC = () => {
  // USER
  const { canUserMakeCalls: canUserMakeCallsByUser } = useUserCapabilities();

  // CATEGORY
  const { virtualPhoneNumber: { number: userPhoneNumberFirstOrDefault = '' } = {} } =
    useWebAppSelector(selectCategoriesByCanMakeCalls)[0] || {};

  // SIP State & Methods
  const { statusSIP, statusInitializeCall } = useWebAppSelector(selectSIPStatuses);
  const { dataSIPCallsByVisibility } = useWebAppSelector(selectSIPDataCallsByVisibility);
  const {
    sipCallAnswer,
    sipCallTerminate,
    sipCallReject,
    sipCallMute,
    sipCallUnMute,
    sipCallHold,
    sipCallUnHold,
    sipCallSendDTMF,
    sipCallChangeView,
    sipCallChangeRingtone,
  } = useSIPCallMethodsOngoing();
  const { canUserMakeCallsBySIP, sipCallMake } = useSIPCallMethodsOutgoing();

  // INTERNAL STATE
  const [stateIsDebugModeMinimized, setStateIsDebugModeMinimized] = useState<boolean>(false);
  const [stateNumberLocal, setStateNumberLocal] = useState<string>('');
  const [stateNumberRemote, setStateNumberRemote] = useState<string>('');
  const [stateIsNumberPrivate, setStateIsNumberPrivate] = useState<boolean>(false);
  const dataMakeCalls = useMemo(
    () => [
      {
        id: 0,
        isDynamic: true,
        country: 'DYNAMIC',
        from: stateNumberLocal,
        to: stateNumberRemote,
        clir: stateIsNumberPrivate,
      },
      {
        id: 1,
        isDynamic: false,
        country: 'France Onoff - Prod Number',
        from: stateNumberLocal,
        to: '33780917992',
        clir: true,
      },
      {
        id: 2,
        isDynamic: false,
        country: 'Estonia Native - Test Phone',
        from: stateNumberLocal,
        to: '37258876592',
        clir: false,
      },
    ],
    [stateNumberLocal, stateNumberRemote, stateIsNumberPrivate],
  );
  useEffect(() => {
    if (userPhoneNumberFirstOrDefault.length > 0) {
      setStateNumberLocal(userPhoneNumberFirstOrDefault);
      setStateNumberRemote(userPhoneNumberFirstOrDefault);
    }
  }, [userPhoneNumberFirstOrDefault]);

  // ONCLICK HANDLERS
  const onClickHandlerMakeCall = ({ numberFrom, numberTo }: { numberFrom: string; numberTo: string }): void => {
    const isNumberFromValid = parsePhoneNumberFromString(`+${normalizePhoneNumber(numberFrom)}`)?.isValid();
    const isNumberToValid = parsePhoneNumberFromString(`+${normalizePhoneNumber(numberTo)}`)?.isValid();

    if (!isNumberFromValid || !isNumberToValid) {
      let errorMessage = 'Phone Number is not valid!';
      if (!isNumberFromValid) {
        errorMessage = `Local (From) ${errorMessage}`;
      }
      if (!isNumberToValid) {
        errorMessage = `Remote (To) ${errorMessage}`;
      }
      if (!isNumberFromValid && !isNumberToValid) {
        errorMessage = 'Phone Numbers are not valid!';
      }

      showErrorToast({
        heading: 'SIP Debug Error',
        message: errorMessage,
      });
      return;
    }

    sipCallMake({
      phoneNumberLocal: numberFrom,
      phoneNumberRemote: numberTo,
      analyticsCTA: SIPStatisticsCallCTAs.CTA_DEBUG,
      analyticsHasContact: false,
    });
  };

  const onClickHandlerDebugMinimize = (): void => {
    setStateIsDebugModeMinimized(!stateIsDebugModeMinimized);
  };

  if (stateIsDebugModeMinimized) {
    return (
      <div className={classNames(styles.root, styles.minimized)}>
        <button
          type="button"
          onClick={onClickHandlerDebugMinimize}
          className={styles.buttonDebug}
        >
          {'SIP DEBUG'}
        </button>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <button
        type="button"
        onClick={onClickHandlerDebugMinimize}
        className={styles.buttonDebug}
      >
        {'MINIMIZE'}
      </button>

      <div className={styles.statuses}>
        <h4 className={styles.statusesTitle}>{'Capabilities'}</h4>
        <div className={styles.statusesRow}>
          {'CAN USER MAKE CALL BY USER TYPE: '}
          <b>{`${canUserMakeCallsByUser}`}</b>
        </div>
        <div className={styles.statusesRow}>
          {'CAN USER MAKE CALL BY SIP: '}
          <b>{`${canUserMakeCallsBySIP}`}</b>
        </div>
      </div>

      <div className={styles.statuses}>
        <h4 className={styles.statusesTitle}>{'Statuses'}</h4>
        <div className={styles.statusesRow}>
          {'InitializeCall: '}
          <b>{statusInitializeCall}</b>
        </div>
        <div className={styles.statusesRow}>
          {'SIP: '}
          <b>{statusSIP}</b>
        </div>
      </div>

      <div className={styles.callsMake}>
        {dataMakeCalls.map((makeCall) => (
          <div
            key={makeCall.id}
            className={styles.callsMakeBox}
          >
            <h4 className={styles.callsMakeBoxTitle}>
              <span>{'Make A Call'}</span>
              <b>{makeCall.country}</b>
            </h4>
            <div className={styles.callsMakeBoxRow}>
              <span>{'From: '}</span>
              {makeCall.isDynamic ? (
                <input
                  type="number"
                  className={styles.callsMakeBoxInput}
                  value={stateNumberLocal}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setStateNumberLocal(event.currentTarget.value)
                  }
                />
              ) : (
                <b>{makeCall.from}</b>
              )}
            </div>
            <div className={styles.callsMakeBoxRow}>
              <span>{'To: '}</span>
              {makeCall.isDynamic ? (
                <input
                  type="number"
                  className={classNames(styles.callsMakeBoxInput)}
                  value={stateNumberRemote}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setStateNumberRemote(event.currentTarget.value)
                  }
                />
              ) : (
                <b style={{ color: '#b3b3b3' }}>{makeCall.to}</b>
              )}
            </div>
            <div className={styles.callsMakeBoxRow}>
              <span>{'Private (CLIR): '}</span>
              {makeCall.isDynamic ? (
                <input
                  type="checkbox"
                  className={classNames(styles.callsMakeBoxInput)}
                  value={`${stateIsNumberPrivate}`}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setStateIsNumberPrivate(event.currentTarget.checked)
                  }
                />
              ) : (
                <b>{`${makeCall.clir}`}</b>
              )}
            </div>
            {canUserMakeCallsByUser ? (
              <button
                type="button"
                disabled={!canUserMakeCallsBySIP || stateNumberLocal.length === 0 || stateNumberRemote.length === 0}
                className={styles.callsMakeBoxButton}
                onClick={() =>
                  onClickHandlerMakeCall({
                    numberFrom: makeCall.from,
                    numberTo: makeCall.to,
                  })
                }
              >
                {'Make Call'}
              </button>
            ) : (
              <button
                type="button"
                className={styles.callsMakeBoxButton}
                disabled
              >
                {'This Account Can Not Make Calls'}
              </button>
            )}
          </div>
        ))}
      </div>

      <div className={styles.callsList}>
        <h4 className={styles.callsListTitle}>{'Calls Data'}</h4>
        <div className={styles.callsListWrapper}>
          {dataSIPCallsByVisibility.length === 0 ? (
            <span className={styles.callsListNoData}>{'No calls...'}</span>
          ) : (
            <>
              {dataSIPCallsByVisibility.map((call) => (
                <div
                  key={call.ids.transaction || call.ids.call || call.ids.webRTC}
                  className={classNames(styles.callsListBox, {
                    [styles.incoming]: call.originator === SIPWebRTCOriginators.REMOTE,
                    [styles.ringing]: call.statuses.webRTC === SIPDataCallStatusesWebRTC.RINGING_180,
                    [styles.ringing]: call.statuses.webRTC === SIPDataCallStatusesWebRTC.RINGING_183,
                    [styles.minimized]: call.statuses.view === SIPDataCallStatusesView.MINIMIZED,
                    [styles.completed]: call.statuses.view === SIPDataCallStatusesView.COMPLETED,
                  })}
                >
                  <span className={styles.callsListBoxBadge}>
                    {call.originator === SIPWebRTCOriginators.LOCAL ? 'OUTGOING' : 'INCOMING'}
                  </span>

                  {/* UI ACTIONS: begin */}
                  <div className={styles.callsListBoxButtons}>
                    <button
                      type="button"
                      onClick={() =>
                        sipCallChangeView({
                          idWebRTC: call.ids.webRTC,
                          statusView:
                            call.statuses.view === SIPDataCallStatusesView.DEFAULT
                              ? SIPDataCallStatusesView.MINIMIZED
                              : SIPDataCallStatusesView.DEFAULT,
                        })
                      }
                    >
                      {`VIEW: ${call.statuses.view}`}
                    </button>

                    <button
                      type="button"
                      onClick={() =>
                        sipCallChangeRingtone({
                          idWebRTC: call.ids.webRTC,
                          statusRingtone:
                            call.statuses.ringtone === SIPDataCallStatusesRingtone.DEFAULT
                              ? SIPDataCallStatusesRingtone.MUTE
                              : SIPDataCallStatusesRingtone.DEFAULT,
                        })
                      }
                    >
                      {`RINGTONE: ${call.statuses.ringtone}`}
                    </button>
                  </div>
                  {/* UI ACTIONS: end */}

                  {/* ANSWER DECLINE TERMINATE: begin */}
                  <span className={styles.callsListBoxSeparator} />
                  <div className={styles.callsListBoxButtons}>
                    <button
                      type="button"
                      disabled={
                        call.originator === SIPWebRTCOriginators.LOCAL ||
                        (call.statuses.webRTC !== SIPDataCallStatusesWebRTC.RINGING_180 &&
                          call.statuses.webRTC !== SIPDataCallStatusesWebRTC.RINGING_183)
                      }
                      onClick={() => sipCallAnswer({ idWebRTC: call.ids.webRTC })}
                    >
                      {'ANSWER CALL'}
                    </button>

                    <button
                      type="button"
                      disabled={
                        call.statuses.webRTC !== SIPDataCallStatusesWebRTC.RINGING_180 &&
                        call.statuses.webRTC !== SIPDataCallStatusesWebRTC.RINGING_183
                      }
                      onClick={() => sipCallReject({ idWebRTC: call.ids.webRTC })}
                    >
                      {call.originator === SIPWebRTCOriginators.LOCAL ? 'CANCEL CALL' : 'REJECT CALL'}
                    </button>

                    <button
                      type="button"
                      disabled={
                        ![
                          SIPDataCallStatusesWebRTC.RINGING_180,
                          SIPDataCallStatusesWebRTC.RINGING_183,
                          SIPDataCallStatusesWebRTC.ANSWERED,
                          SIPDataCallStatusesWebRTC.ACTIVE,
                          SIPDataCallStatusesWebRTC.WAITING,
                        ].includes(call.statuses.webRTC)
                      }
                      onClick={() => sipCallTerminate({ idWebRTC: call.ids.webRTC })}
                    >
                      {'TERMINATE CALL'}
                    </button>
                  </div>
                  {/* ANSWER DECLINE TERMINATE: end */}

                  {/* MUTE UNMUTE: begin */}
                  {SIP.CALL_FEATURES.MUTE_ENABLED ? (
                    <>
                      <span className={styles.callsListBoxSeparator} />
                      <div className={styles.callsListBoxButtons}>
                        <button
                          type="button"
                          disabled={
                            ![SIPDataCallStatusesWebRTC.ACTIVE, SIPDataCallStatusesWebRTC.WAITING].includes(
                              call.statuses.webRTC,
                            ) || call.statuses.featureUnMute !== SIPDataCallStatusesFeatureUnMute.UNMUTED
                          }
                          onClick={() => sipCallMute({ idWebRTC: call.ids.webRTC })}
                        >
                          {'MUTE CALL'}
                        </button>

                        <button
                          type="button"
                          disabled={
                            ![SIPDataCallStatusesWebRTC.ACTIVE, SIPDataCallStatusesWebRTC.WAITING].includes(
                              call.statuses.webRTC,
                            ) || call.statuses.featureMute !== SIPDataCallStatusesFeatureMute.MUTED
                          }
                          onClick={() => sipCallUnMute({ idWebRTC: call.ids.webRTC })}
                        >
                          {'UN MUTE CALL'}
                        </button>
                      </div>
                    </>
                  ) : null}
                  {/* MUTE UNMUTE: end */}

                  {/* HOLD UNHOLD: begin */}
                  {SIP.CALL_FEATURES.HOLD_ENABLED ? (
                    <>
                      <span className={styles.callsListBoxSeparator} />
                      <div className={styles.callsListBoxButtons}>
                        <button
                          type="button"
                          disabled={
                            ![SIPDataCallStatusesWebRTC.ACTIVE, SIPDataCallStatusesWebRTC.WAITING].includes(
                              call.statuses.webRTC,
                            ) || call.statuses.featureUnHold !== SIPDataCallStatusesFeatureUnHold.UNHELD
                          }
                          onClick={() => sipCallHold({ idWebRTC: call.ids.webRTC })}
                        >
                          {'HOLD CALL'}
                        </button>

                        <button
                          type="button"
                          disabled={
                            ![SIPDataCallStatusesWebRTC.ACTIVE, SIPDataCallStatusesWebRTC.WAITING].includes(
                              call.statuses.webRTC,
                            ) || call.statuses.featureHold !== SIPDataCallStatusesFeatureHold.HELD
                          }
                          onClick={() => sipCallUnHold({ idWebRTC: call.ids.webRTC })}
                        >
                          {'UN HOLD CALL'}
                        </button>
                      </div>
                    </>
                  ) : null}
                  {/* HOLD UNHOLD: end */}

                  {/* SEND DTMF: begin */}
                  {SIP.CALL_FEATURES.DTMF_ENABLED ? (
                    <>
                      <span className={styles.callsListBoxSeparator} />
                      <div className={styles.callsListBoxButtons}>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 1918].map((number) => (
                          <button
                            key={number}
                            type="button"
                            disabled={
                              ![SIPDataCallStatusesWebRTC.ACTIVE, SIPDataCallStatusesWebRTC.WAITING].includes(
                                call.statuses.webRTC,
                              )
                            }
                            onClick={() =>
                              sipCallSendDTMF({
                                idWebRTC: call.ids.webRTC,
                                valueWebRTCDTMF: number,
                              })
                            }
                          >
                            {`SEND DTMF: "${number}"`}
                          </button>
                        ))}
                      </div>
                    </>
                  ) : null}
                  {/* SEND DTMF: end */}

                  {/* CALL STATUSES: begin */}
                  <span className={styles.callsListBoxSeparator} />
                  <div className={styles.callsListBoxRow}>
                    <span>{'CALL STATUSES => WebRTC'}</span>
                    <b>{call.statuses.webRTC}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'CALL STATUSES => Feature Mute'}</span>
                    <b>{call.statuses.featureMute}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'CALL STATUSES => Feature UnMute'}</span>
                    <b>{call.statuses.featureUnMute}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'CALL STATUSES => Feature Hold'}</span>
                    <b>{call.statuses.featureHold}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'CALL STATUSES => Feature UnHold'}</span>
                    <b>{call.statuses.featureUnHold}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'CALL STATUSES => Feature DTMF'}</span>
                    <b>{call.statuses.featureDTMF}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'CALL STATUSES => VIEW'}</span>
                    <b>{call.statuses.view}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'CALL STATUSES => RINGTONE'}</span>
                    <b>{call.statuses.ringtone}</b>
                  </div>
                  {/* CALL STATUSES: end */}

                  {/* CALL ORIGINATOR: begin */}
                  <span className={styles.callsListBoxSeparator} />
                  <div className={styles.callsListBoxRow}>
                    <span>{'ORIGINATOR'}</span>
                    <b>{call.originator}</b>
                  </div>
                  {/* CALL ORIGINATOR: end */}

                  {/* CALL IDS: begin */}
                  <span className={styles.callsListBoxSeparator} />
                  <div className={styles.callsListBoxRow}>
                    <span>{'ID CALL'}</span>
                    <b>{call.ids.call || 'undefined'}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'ID TRANSACTION'}</span>
                    <b>{call.ids.transaction || 'undefined'}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'ID WebRTC'}</span>
                    <b>{call.ids.webRTC || 'undefined'}</b>
                  </div>
                  {/* CALL IDS: end */}

                  {/* CALL PHONE NUMBERS: begin */}
                  <span className={styles.callsListBoxSeparator} />
                  <div className={styles.callsListBoxRow}>
                    <span>{'PHONE NUMBER LOCAL'}</span>
                    <b>{call.phoneNumbers.local}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'PHONE NUMBER REMOTE'}</span>
                    <b>{call.phoneNumbers.remote}</b>
                  </div>
                  {/* CALL PHONE NUMBERS: end */}

                  {/* CALL TIMESTAMPS: begin */}
                  <span className={styles.callsListBoxSeparator} />
                  <div className={styles.callsListBoxRow}>
                    <span>{'TIMESTAMP CALL CREATED'}</span>
                    <b>{call.timestamps.callCreated}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'TIMESTAMP CALL RANG'}</span>
                    <b>{call.timestamps.callRang}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'TIMESTAMP CALL ANSWERED'}</span>
                    <b>{call.timestamps.callAnswered}</b>
                  </div>
                  <div className={styles.callsListBoxRow}>
                    <span>{'TIMESTAMP CALL TERMINATED'}</span>
                    <b>{call.timestamps.callTerminated}</b>
                  </div>
                  {/* CALL TIMESTAMPS: end */}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SIPDebugCore;
