import React from 'react';

import { StyleableComponent } from 'types';

const ConversationDelete: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M200 100C200 155.228 155.228 200 100 200C44.7715 200 0 155.228 0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 99.9998C187.368 148.252 148.252 187.368 100 187.368C51.7478 187.368 12.6316 148.252 12.6316 99.9998C12.6316 51.7475 51.7478 12.6313 100 12.6313C148.252 12.6313 187.368 51.7475 187.368 99.9998Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 99.9998C172.105 139.822 139.823 172.105 100 172.105C60.1774 172.105 27.8948 139.822 27.8948 99.9998C27.8948 60.1772 60.1774 27.8945 100 27.8945C139.823 27.8945 172.105 60.1772 172.105 99.9998Z"
      fill="#F3F4F5"
    />
    <mask
      id="mask0_13689_11452"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="200"
      height="200"
    >
      <path
        d="M0 0H200V200H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13689_11452)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.855 62.0785L59.2609 32.0167C57.509 31.3791 56.5913 29.4798 57.2121 27.7742L66.1977 3.08646C66.818 1.38216 68.7418 0.517117 70.4937 1.15476L153.088 31.2165C154.84 31.8542 155.757 33.7535 155.137 35.4578L146.151 60.1455C145.531 61.8511 143.607 62.7162 141.855 62.0785Z"
        fill="#C8CBCE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.855 62.0785L58.2609 32.0167C56.509 31.3791 55.5913 29.4798 56.2121 27.7742L65.1977 3.08646C65.818 1.38216 67.7418 0.517117 69.4937 1.15476L152.088 31.2165C153.84 31.8542 154.757 33.7535 154.137 35.4578L145.151 60.1455C144.531 61.8511 142.607 62.7162 140.855 62.0785Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.733 46L119.474 63.1796L136.062 54.4911L112.733 46Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.7314 11.2459C69.9534 10.636 70.6277 10.3216 71.2376 10.5435L144.121 37.0708C144.731 37.2928 145.045 37.9671 144.823 38.577L143.893 41.1322C143.671 41.7421 142.997 42.0566 142.387 41.8346L69.5037 15.3073C68.8938 15.0853 68.5794 14.411 68.8014 13.8011L69.7314 11.2459ZM107.183 37.383C106.961 37.9929 106.287 38.3073 105.677 38.0854L66.4145 23.795C65.8046 23.573 65.4901 22.8987 65.7121 22.2888L66.6421 19.7336C66.8641 19.1237 67.5384 18.8092 68.1483 19.0312L107.411 33.3216C108.021 33.5436 108.335 34.2179 108.113 34.8278L107.183 37.383Z"
        fill="#F4F4F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.677 67.49H18.2433C16.4524 67.49 15 65.8558 15 63.8391V34.6493C15 32.6342 16.4524 31 18.2433 31H102.677C104.468 31 105.92 32.6342 105.92 34.6493V63.8391C105.92 65.8558 104.468 67.49 102.677 67.49Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.2668 68.49H101.677C103.468 68.49 104.92 66.8558 104.92 64.8391V35.6493C104.92 33.6342 103.468 32 101.677 32H17.2433C15.4524 32 14 33.6342 14 35.6493V64.8391C14 66.8558 15.4524 68.49 17.2433 68.49H78.6093L86.3105 77.2173L94.2668 68.49Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2634 42.0179C22.2634 41.2572 22.88 40.6406 23.6407 40.6406H94.5362C95.2968 40.6406 95.9134 41.2572 95.9134 42.0179V45.2046C95.9134 45.9652 95.2968 46.5819 94.5362 46.5819H23.6407C22.88 46.5819 22.2634 45.9652 22.2634 45.2046V42.0179ZM62.9383 55.7902C62.9383 56.5508 62.3217 57.1674 61.561 57.1674H23.6407C22.88 57.1674 22.2634 56.5508 22.2634 55.7902V52.6034C22.2634 51.8428 22.88 51.2262 23.6407 51.2262H61.561C62.3217 51.2262 62.9383 51.8428 62.9383 52.6034V55.7902Z"
        fill="#ECECEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7693 136.099C77.889 130.96 92.008 125.821 106.173 120.666C120.292 115.526 134.456 110.371 148.576 105.232C151.439 115.772 154.137 125.86 156.732 135.524C159.24 144.81 161.678 153.762 163.985 162.353C164.583 164.698 163.674 167.13 161.909 167.772C150.55 171.906 139.192 176.041 127.832 180.175C116.474 184.309 105.07 188.46 93.7104 192.594C91.9906 193.22 89.7486 191.987 88.682 189.761C84.9271 181.698 81.0406 173.272 76.9931 164.547C72.7693 155.476 68.3515 146.013 63.7693 136.099Z"
        fill="#C8CBCE"
      />
      <path
        d="M111.686 148.296C112.103 148.989 111.466 149.825 110.697 149.633L108.074 148.98L106.768 154.22C106.426 155.589 107.73 156.805 109.071 156.37L111.853 155.466C112.208 155.351 112.588 155.545 112.704 155.899L113.399 158.04C113.514 158.395 113.32 158.776 112.966 158.891L110.189 159.793C106.158 161.103 102.254 157.458 103.279 153.348L104.583 148.109L101.961 147.455C101.184 147.262 101.03 146.222 101.715 145.81L107.172 142.532C107.598 142.275 108.152 142.413 108.408 142.84L111.686 148.296ZM113.78 136.794L117.139 139.612L115.401 141.683C114.887 142.295 115.371 143.228 116.169 143.158L122.511 142.603C123.01 142.56 123.373 142.12 123.33 141.628L122.775 135.286C122.705 134.49 121.703 134.172 121.188 134.786L119.453 136.854L116.096 134.035C113.044 131.475 108.349 132.992 107.383 136.866L106.922 138.718C106.832 139.08 107.052 139.446 107.414 139.536L109.597 140.081C109.959 140.171 110.326 139.951 110.416 139.589L110.877 137.738C111.204 136.429 112.781 135.956 113.78 136.794ZM129.104 144.957L126.865 143.078C126.579 142.838 126.153 142.875 125.914 143.161L124.469 144.882C124.23 145.167 124.267 145.593 124.553 145.833L126.795 147.714C127.873 148.619 127.536 150.37 126.194 150.806L121.057 152.475L120.223 149.908C119.976 149.147 118.929 149.063 118.565 149.777L115.674 155.448C115.449 155.891 115.625 156.433 116.068 156.659L121.74 159.549C122.449 159.91 123.253 159.234 123.005 158.468L122.17 155.9L127.301 154.233C131.329 152.924 132.351 147.682 129.104 144.957Z"
        fill="white"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7693 136.099C77.889 130.96 92.008 125.821 106.173 120.666C120.292 115.526 134.456 110.371 148.576 105.232L151.488 115.908C137.697 117.442 123.013 120.891 108.215 126.277C93.4172 131.663 79.9518 138.46 68.4009 146.149L63.7693 136.099Z"
        fill="#8E929B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.2727 95.7215L127.598 70.1252C131.581 68.6756 136.025 70.7477 137.474 74.7305L148.576 105.232L63.7691 136.099L52.6674 105.598C51.2178 101.615 53.29 97.1711 57.2727 95.7215Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.0715 124.95L145.775 93.029C146.68 92.6997 147.622 93.1766 147.935 94.0371L151.032 102.544C151.345 103.404 150.929 104.376 150.024 104.705L62.3212 136.626C61.4162 136.956 60.4737 136.479 60.1607 135.619L57.0643 127.111C56.7511 126.251 57.1666 125.28 58.0715 124.95Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5196 124.423L144.326 93.5563L143.454 91.1582L58.6467 122.025L59.5196 124.423Z"
        fill="#C8CBCE"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2793 122.794L30.6547 120.411L28 119.279L30.3838 117.654L31.5151 115L33.1397 117.384L35.7944 118.515L33.4106 120.14L32.2793 122.794Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.279 20.7944L146.655 18.4106L144 17.2793L146.384 15.654L147.515 13L149.14 15.3844L151.794 16.5151L149.411 18.1397L148.279 20.7944Z"
      fill="#E4E4E4"
    />
  </svg>
);

export default ConversationDelete;
