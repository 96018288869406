import { createSelector } from '@reduxjs/toolkit';

import { findIntegrationContactForActiveContactId, findIntegrationIdForActiveContactId } from 'helpers';

import {
  WebAppState,
  SelectIntegrationsActiveContactIntegration,
  SelectIntegrationsActiveIntegrationContact,
  SelectIntegrationsActiveIntegrationsState,
  SelectIntegrationsBasicSearchContacts,
  SelectIntegrationsDetailsForContact,
  SelectIntegrationsDetailsState,
  SelectIntegrationsExportContactStatuses,
  SelectIntegrationsState,
  SelectIntegrationsStatuses,
} from '../../types';

export const selectIntegrationsState: SelectIntegrationsState = (appState: WebAppState) => appState.integrations;

export const selectIntegrationsActiveId = createSelector(
  [selectIntegrationsState],
  (integrationsState) => integrationsState.activeId,
);

export const selectIntegrationsActiveIntegrations: SelectIntegrationsActiveIntegrationsState = createSelector(
  [selectIntegrationsState],
  (integrationsState) => integrationsState.activeIntegrations,
);

export const selectIntegrationsDetails: SelectIntegrationsDetailsState = createSelector(
  [selectIntegrationsState],
  (integrationsState) => integrationsState.details,
);

export const selectIntegrationsDetailsForContact: SelectIntegrationsDetailsForContact = (contactId: string) =>
  createSelector([selectIntegrationsDetails], (integrationDetailsState) => integrationDetailsState[contactId]);

export const selectIntegrationsStatuses: SelectIntegrationsStatuses = createSelector(
  [selectIntegrationsState],
  (integrationsState) => integrationsState.statuses,
);

export const selectIntegrationsContacts: SelectIntegrationsBasicSearchContacts = createSelector(
  [selectIntegrationsState],
  (integrationsState) => integrationsState.contacts,
);

export const selectIntegrationsIntegrationForActiveContact: SelectIntegrationsActiveContactIntegration = createSelector(
  [selectIntegrationsActiveIntegrations, selectIntegrationsActiveId, selectIntegrationsContacts],
  (activeIntegrations, activeId, integrationContacts) => {
    const integrationId = findIntegrationIdForActiveContactId(activeId, integrationContacts);

    return activeIntegrations.find(({ id }) => id === integrationId);
  },
);

export const selectIntegrationsActiveContact: SelectIntegrationsActiveIntegrationContact = createSelector(
  [selectIntegrationsActiveId, selectIntegrationsContacts],
  (activeId, integrationContacts) => findIntegrationContactForActiveContactId(activeId, integrationContacts),
);

export const selectIntegrationsExportContactStatuses: SelectIntegrationsExportContactStatuses = createSelector(
  [selectIntegrationsStatuses],
  (statuses) => statuses.exportContact,
);
