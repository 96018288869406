import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@onoff/ui';

import { Status } from 'types';

import { handleApplicationError } from 'helpers';

import {
  contactsSetStatus,
  saveContact,
  selectContactsStatuses,
  selectUserAllowedCountries,
  selectUserCountryIsoCode,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';
import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal/components';
import { useUserCapabilities } from 'hooks';

import { ModalsAddEditContactButtonTheme } from '../../enums';
import { useAddContactForm } from '../../hooks';
import { ModalsAddEditContactContentProps } from '../../types';
import ModalsAddEditContactForm from '../ModalsAddEditContactForm';

import styles from './ModalsAddEditContactContent.module.scss';

const ModalsAddEditContactContent: React.FC<ModalsAddEditContactContentProps> = ({
  formDataDefault,
  categoryList,
  onClose,
  isEditModal,
  isCRMContactModal,
}) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const countryCode = useWebAppSelector(selectUserCountryIsoCode);
  const countryList = useWebAppSelector(selectUserAllowedCountries);
  const { saveContact: saveContactStatus } = useWebAppSelector(selectContactsStatuses);
  const { isUserB2B } = useUserCapabilities();

  const theme = isUserB2B ? ModalsAddEditContactButtonTheme.B2B : ModalsAddEditContactButtonTheme.B2C;

  const { formData, errors, onAddPhone, onAddEmail, onRemovePhone, onRemoveEmail, onChange, trigger, isValid } =
    useAddContactForm({
      categoryList,
      formDataDefault,
    });

  const isDisabled = !isValid;

  const onConfirm = async () => {
    const result = await trigger();

    if (!result) {
      return;
    }

    try {
      await dispatch(saveContact(formData));

      onClose();
      dispatch(contactsSetStatus('saveContact', Status.IDLE));
    } catch (error) {
      handleApplicationError({ error });
    }
  };

  return (
    <>
      <ModalHeader
        title={intl.formatMessage({ id: isEditModal ? 'ModalAddContact.editTitle' : 'ModalAddContact.addTitle' })}
        propTestIds={{
          title: 'modal-add-edit-contact-title',
        }}
      />
      <ModalBody className={styles.body}>
        <ModalsAddEditContactForm
          isUserB2B={isUserB2B}
          errors={errors}
          formData={formData}
          categoryList={categoryList}
          countryList={countryList}
          countryCode={countryCode}
          onChange={onChange}
          onAddPhone={onAddPhone}
          onRemovePhone={onRemovePhone}
          onAddEmail={onAddEmail}
          onRemoveEmail={onRemoveEmail}
          isCRMContactModal={isCRMContactModal}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={onClose}
          className={styles.cancelButton}
          variant={'ghost'}
          colorScheme={'black'}
          size={'large'}
          {...testGetTestingAttributes(testIds.modalAddEditContactButtonClose)}
        >
          <FormattedMessage id={'ModalAddContact.cancelButtonText'} />
        </Button>
        <Button
          onClick={onConfirm}
          colorScheme={theme}
          size={'large'}
          disabled={isDisabled}
          loading={saveContactStatus === Status.LOADING}
          {...testGetTestingAttributes(testIds.modalAddEditContactButtonConfirm)}
        >
          <FormattedMessage
            id={isEditModal ? 'ModalAddContact.confirmEditButtonText' : 'ModalAddContact.confirmAddButtonText'}
          />
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalsAddEditContactContent;
