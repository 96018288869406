import { createSelector } from '@reduxjs/toolkit';

import {
  SelectPlansAndOffersAvailableOffersState,
  SelectPlansAndOffersAvailablePlansState,
  SelectPlansAndOffersPremiumState,
  SelectPlansAndOffersState,
  SelectPlansAndOffersStatusesState,
  SelectPlansAndOffersSubscriptionIdForRenewalPeriodChange,
} from '../../types';

export const selectPlansAndOffersState: SelectPlansAndOffersState = (state) => state.plansAndOffers;

export const selectPlansAndOffersStatuses: SelectPlansAndOffersStatusesState = createSelector(
  [selectPlansAndOffersState],
  (plansAndOffers) => plansAndOffers.statuses,
);

export const selectPlansAndOffersUserOffer = createSelector(
  [selectPlansAndOffersState],
  (plansAndOffers) => plansAndOffers.userOffer,
);

export const selectPlansAndOffersPremiumState: SelectPlansAndOffersPremiumState = createSelector(
  [selectPlansAndOffersState],
  (plansAndOffersState) => plansAndOffersState.premiumPlans,
);

export const selectPlansAndOffersSelectedPremium = createSelector(
  [selectPlansAndOffersState],
  (plansAndOffersState) => plansAndOffersState.selectedPremiumPlan,
);

export const selectTrialAvailable = createSelector(
  [selectPlansAndOffersPremiumState],
  (premiumPlans) => premiumPlans.some((plan) => plan.trialAvailable),
);

export const selectPlansAndOffersAvailableOffers: SelectPlansAndOffersAvailableOffersState = createSelector(
  [selectPlansAndOffersState],
  (plansAndOffers) => plansAndOffers.availableOffers,
);

export const selectPlansAndOffersAvailablePlans: SelectPlansAndOffersAvailablePlansState = createSelector(
  [selectPlansAndOffersState],
  (plansAndOffers) => plansAndOffers.availablePlans,
);

// eslint-disable-next-line max-len
export const selectPlansAndOffersSubscriptionIdForRenewalPeriodChange: SelectPlansAndOffersSubscriptionIdForRenewalPeriodChange =
  createSelector([selectPlansAndOffersState], (plansAndOffers) => plansAndOffers.subscriptionIdForRenewalPeriodChange);
