import React from 'react';

import { PurchaseReceiptsListResponseReceipt } from 'types';

import ModalsReceiptsListMobileItem from '../ModalsReceiptsListMobileItem';

export interface ModalsReceiptsListMobileListProps {
  receipts: PurchaseReceiptsListResponseReceipt[];
}

const ModalsReceiptsListMobileList: React.FC<ModalsReceiptsListMobileListProps> = ({ receipts }) => (
  <>
    {receipts.map((receipt) => (
      <ModalsReceiptsListMobileItem
        key={receipt.invoiceNumber}
        receipt={receipt}
      />
    ))}
  </>
);

export default ModalsReceiptsListMobileList;
