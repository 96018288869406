import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { formatNumberAsLocal, getContactOrNumberDisplayText } from 'helpers';

import { InfoTopBarAvatar, InfoTopBarContent } from 'components/InfoTopBar';

import { ModalsRecipientInfoListItemProps } from '../../types';

import styles from './ModalsRecipientInfoListItem.module.scss';

const ModalsRecipientInfoListItem: React.FC<ModalsRecipientInfoListItemProps> = ({
  category = undefined,
  contact = null,
  phoneNumber = '',
  className = '',
}) => {
  const intl = useIntl();

  const hasContact = contact !== null;
  const formattedPhoneNumber = formatNumberAsLocal(phoneNumber);
  const displayName = getContactOrNumberDisplayText({
    phoneNumberOrEmpty: phoneNumber,
    clirText: intl.formatMessage({ id: 'App.clir_calling_line_identification_restriction' }),
    contact,
  });

  return (
    <div className={classNames(styles.root, className)}>
      <InfoTopBarAvatar
        phoneNumberOrEmpty={phoneNumber}
        contact={contact}
        category={category}
      />
      <InfoTopBarContent
        mainRow={displayName}
        additionalRow={hasContact ? formattedPhoneNumber : undefined}
        className={styles.content}
      />
    </div>
  );
};

export default ModalsRecipientInfoListItem;
