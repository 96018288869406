import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@onoff/ui';

import { formatNumber } from 'helpers';

import { useWebAppDispatch, useWebAppSelector, deleteCategory, selectCategoryById } from '@redux';
import { IllustrationCommon, IllustrationCommonNames } from 'components/Illustration';
import { Info } from 'components/Info';
import { InputCheckbox } from 'components/Input';
import { ModalBody, ModalFooter, ModalHeader } from 'components/Modal';

import styles from './ModalsDeleteCategoryContent.module.scss';

export interface ModalsDeleteCategoryContentProps {
  categoryId: string;
  onRequestClose: () => void;
}

const ModalsDeleteCategoryContent: React.FC<ModalsDeleteCategoryContentProps> = ({ onRequestClose, categoryId }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const category = useWebAppSelector(selectCategoryById(categoryId));
  const categoryNumber = formatNumber(category?.virtualPhoneNumber.number);

  const [checkboxStates, setCheckboxStates] = useState({
    information: false,
    contacts: false,
    numbers: false,
  });

  const checkboxList = [
    {
      id: 1,
      checked: checkboxStates.information,
      label: intl.formatMessage({ id: 'ModalDeleteCategory.confirmation_checkbox_1' }),
      onChange: (checked) => setCheckboxStates((state) => ({ ...state, information: checked })),
    },
    {
      id: 2,
      checked: checkboxStates.contacts,
      label: intl.formatMessage({ id: 'ModalDeleteCategory.confirmation_checkbox_2' }),
      onChange: (checked) => setCheckboxStates((state) => ({ ...state, contacts: checked })),
    },
    {
      id: 3,
      checked: checkboxStates.numbers,
      label: intl.formatMessage({ id: 'ModalDeleteCategory.confirmation_checkbox_3' }, { number: categoryNumber }),
      onChange: (checked) => setCheckboxStates((state) => ({ ...state, numbers: checked })),
    },
  ];

  const allCheckboxesChecked = checkboxStates.contacts && checkboxStates.information && checkboxStates.numbers;

  const onDelete = () => {
    dispatch(deleteCategory(categoryId));
    onRequestClose();
  };

  return (
    <>
      <ModalHeader title={intl.formatMessage({ id: 'ModalDeleteCategory.title' })} />
      <ModalBody className={styles.body}>
        <IllustrationCommon
          name={IllustrationCommonNames.CONVERSATION_DELETE}
          className={styles.illustration}
        />
        <Info
          className={styles.info}
          classNameIcon={styles.infoIcon}
        >
          {intl.formatMessage({ id: 'ModalDeleteCategory.warning' })}
        </Info>
        <div className={styles.content}>
          <>
            <h3 className={styles.title}>{intl.formatMessage({ id: 'ModalDeleteCategory.confirmation_title' })}</h3>
            {checkboxList.map(({ id, label, checked, onChange }) => (
              <InputCheckbox
                key={id}
                checked={checked}
                onChange={onChange}
                className={styles.checkboxContainer}
              >
                {label}
              </InputCheckbox>
            ))}
          </>
        </div>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button
          variant="ghost"
          colorScheme="black"
          size={'large'}
          onClick={onRequestClose}
          className={styles.cancelButton}
        >
          {intl.formatMessage({ id: 'ModalDeleteCategory.button_cancel' })}
        </Button>
        <Button
          colorScheme="destructive"
          disabled={!allCheckboxesChecked}
          onClick={onDelete}
          size={'large'}
          className={styles.confirmButton}
        >
          {intl.formatMessage({ id: 'ModalDeleteCategory.button_confirm' })}
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalsDeleteCategoryContent;
