import { IllustrationCommonNames } from 'components/Illustration';

import { ModalsConfirmationColorScheme, ModalsConfirmationType } from '../enums';

const destructiveModalTypes = [
  ModalsConfirmationType.DISCONNECT,
  ModalsConfirmationType.DELETE_CONVERSATION,
  ModalsConfirmationType.DELETE_CONTACT,
  ModalsConfirmationType.DELETE_CALL_HISTORY,
  ModalsConfirmationType.DELETE_CALL_RECORDING,
  ModalsConfirmationType.DELETE_VOICEMAIL,
];

export const getIllustrationType = (
  modalsConfirmationType: ModalsConfirmationType,
): IllustrationCommonNames | undefined => {
  const illustrationLookUpMap: Record<ModalsConfirmationType, IllustrationCommonNames | undefined> = {
    [ModalsConfirmationType.DEFAULT]: undefined,
    [ModalsConfirmationType.DISCONNECT]: IllustrationCommonNames.LOGOUT,
    [ModalsConfirmationType.DELETE_CONVERSATION]: IllustrationCommonNames.CONVERSATION_DELETE,
    [ModalsConfirmationType.DELETE_CONTACT]: IllustrationCommonNames.CONTACT_DELETE,
    [ModalsConfirmationType.DELETE_CALL_HISTORY]: IllustrationCommonNames.CALL_LOG_DELETE,
    [ModalsConfirmationType.DELETE_CALL_RECORDING]: IllustrationCommonNames.RECORDING_DELETE,
    // TODO: add voicemail illustration - RECORDING_DELETE is just a placeholder
    [ModalsConfirmationType.DELETE_VOICEMAIL]: IllustrationCommonNames.RECORDING_DELETE,
    [ModalsConfirmationType.LACK_OF_CREDITS]: IllustrationCommonNames.CREDITS,
    [ModalsConfirmationType.CREDIT_CARD_MISMATCH]: IllustrationCommonNames.CREDIT_CARD_LOCATION,
    [ModalsConfirmationType.PREMIUM_FEATURE]: IllustrationCommonNames.PREMIUM_FEATURE,
    [ModalsConfirmationType.NUMBER_RESUBSCRIBE]: IllustrationCommonNames.NUMBER_RENEWAL,
    [ModalsConfirmationType.PLAN_RESUBSCRIBE]: IllustrationCommonNames.SUBSCRIPTION_RENEWAL,
    [ModalsConfirmationType.CANCEL_SUBSCRIPTION]: IllustrationCommonNames.UNSUBSCRIBE,
  };

  return illustrationLookUpMap[modalsConfirmationType];
};

export const getButtonColorScheme = (
  modalsConfirmationType: ModalsConfirmationType,
  isUserB2B: boolean,
): ModalsConfirmationColorScheme => {
  if (destructiveModalTypes.includes(modalsConfirmationType)) {
    return ModalsConfirmationColorScheme.DESTRUCTIVE;
  }

  return isUserB2B ? ModalsConfirmationColorScheme.B2B : ModalsConfirmationColorScheme.B2C;
};

export const getIllustrationColorScheme = (
  modalsConfirmationType: ModalsConfirmationType,
  isUserB2B: boolean,
): ModalsConfirmationColorScheme | null => {
  if (destructiveModalTypes.includes(modalsConfirmationType)) {
    return null;
  }

  return isUserB2B ? ModalsConfirmationColorScheme.B2B : ModalsConfirmationColorScheme.B2C;
};
