import { createSelector } from '@reduxjs/toolkit';

import {
  SelectAuthenticationState,
  SelectAuthenticationStatuses,
  SelectAuthenticationCaptcha,
  SelectAuthenticationLogin,
} from '../../types';

export const selectAuthenticationState: SelectAuthenticationState = (state) => state.authentication;

export const selectAuthenticationStatuses: SelectAuthenticationStatuses = createSelector(
  [selectAuthenticationState],
  (authenticationState) => authenticationState.statuses,
);

export const selectAuthenticationCaptcha: SelectAuthenticationCaptcha = createSelector(
  [selectAuthenticationState],
  (authenticationState) => authenticationState.captcha,
);

export const selectAuthenticationLogin: SelectAuthenticationLogin = createSelector(
  [selectAuthenticationState],
  (authenticationState) => authenticationState.login,
);
