import classnames from 'classnames';
import React from 'react';

import { StyleableComponent } from 'types/components';

import styles from './LoadingSkeleton.module.scss';

const LoadingSkeleton: React.FC<StyleableComponent> = ({ className = '' }) => (
  <div className={classnames(styles.root, className)}></div>
);

export default LoadingSkeleton;
