import React, { useEffect, useMemo } from 'react';

interface UseIsElementInViewportProps {
  callbackOnIntersect: () => void;
  ref: React.RefObject<HTMLElement | null>;
  options: IntersectionObserverInit;
}

export const useIsElementInViewport = ({ callbackOnIntersect, ref, options }: UseIsElementInViewportProps) => {
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          callbackOnIntersect();
        }
      }, options),
    [options, callbackOnIntersect],
  );

  useEffect(() => {
    if (ref.current !== null) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);
};
