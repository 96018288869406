export enum BrowserPermissionCanPlayAudioStatus {
  IDLE = 'IDLE',
  PROMPT = 'PROMPT',
  DISMISSED = 'DISMISSED',
}

export enum BrowserPermissionCanPlayAudioSource {
  MANUAL_CHECK = 'MANUAL_CHECK',
  INCOMING_CALL = 'INCOMING_CALL',
}

export type BrowserPermissionCanPlayAudioState = {
  status: BrowserPermissionCanPlayAudioStatus;
  source?: BrowserPermissionCanPlayAudioSource;
};
