import classNames from 'classnames';
import React from 'react';

import { testGetTestingAttributes } from '@testing';

import { DropdownCommonProps } from '../../types';

import styles from './DropdownCommon.module.scss';

const DropdownCommon = React.forwardRef<HTMLUListElement, DropdownCommonProps>(
  (
    {
      options,
      renderOption,
      onSelect,
      style,
      selectedValue = '',
      className = '',
      // this attribute for marking element visible or not
      // it is for testing purpose
      // https://github.com/testing-library/jest-dom/issues/209
      isOpen = false,
      propTestId = '',
    },
    ref,
  ) => {
    if (options.length === 0) {
      return null;
    }

    return (
      <ul
        ref={ref}
        className={classNames(styles.root, className)}
        style={style}
        hidden={!isOpen}
        {...testGetTestingAttributes(propTestId)}
      >
        {options.map(({ label, value, propTestId: optionTestId }) => (
          <li
            key={value}
            className={classNames(styles.item, {
              [styles.selected]: selectedValue === value,
            })}
            onClick={() => onSelect(value)}
            {...testGetTestingAttributes(optionTestId)}
          >
            {renderOption({ label, value }, selectedValue === value)}
          </li>
        ))}
      </ul>
    );
  },
);

export default DropdownCommon;
