export enum IllustrationCommonNames {
  LOGOUT = 'LOGOUT',
  CALL_LOG_DELETE = 'CALL_LOG_DELETE',
  CONTACT_DELETE = 'CONTACT_DELETE',
  PHONE_LOUPE = 'PHONE_LOUPE',
  CONVERSATION_DELETE = 'CONVERSATION_DELETE',
  CREDITS = 'CREDITS',
  CREDIT_CARD_LOCATION = 'CREDIT_CARD_LOCATION',
  PREMIUM_FEATURE = 'PREMIUM_FEATURE',
  NUMBER_RENEWAL = 'NUMBER_RENEWAL',
  SUBSCRIPTION_RENEWAL = 'SUBSCRIPTION_RENEWAL',
  RECORDING_DELETE = 'RECORDING_DELETE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  DEVICES = 'DEVICES',
  ACCOUNT_DELETE = 'ACCOUNT_DELETE',
  SUCCESS = 'SUCCESS',
  PREMIUM_UNLOCKED = 'PREMIUM_UNLOCKED',
  BLOCKING_EMPTY = 'BLOCKING_EMPTY',
}
