import { VoipDomainSrvRecord } from 'types';

import { LOCALSTORAGE_KEYS, REGEX, SIP } from '@constants';
import { SIPDataWssRecord } from '@redux/types';

import { getUserAgentDataBrowserName } from '../common';
import { multiDeviceGenerateToken } from '../multiDevice';

import {
  GetSIPWSSAvailableRecordUrlProps,
  GetSIPWSSAvailableRecordUrlReturn,
  GetSIPConfigWSSRecordsUrlsByPortAndPriorityProps,
  GetSIPConfigWSSRecordsUrlsByPortAndPriorityReturn,
  MapSIPWSSRecordsByUnavailableUrlProps,
  MapSIPWSSRecordsByUnavailableUrlReturn,
  GetSIPConfigStunRecordsByPriorityProps,
  GetSIPConfigStunRecordsByPriorityReturn,
  GetSIPBrowserRestrictionsProps,
} from './types';

export const getSIPCallsCapabilitiesIncomingRingtoneDisabledFromLocalStorage = (): boolean => {
  const isDisabled = window.localStorage.getItem(LOCALSTORAGE_KEYS.SIP_CALLS_INCOMING_RINGTONE_DISABLED) === 'true';
  return isDisabled;
};

export const setSIPCallsCapabilitiesIncomingRingtoneDisabledToLocalStorage = (isDisabled: boolean): void => {
  window.localStorage.setItem(LOCALSTORAGE_KEYS.SIP_CALLS_INCOMING_RINGTONE_DISABLED, `${isDisabled}`);
};

export const getSIPCallsCapabilitiesAdvancedMediaConstraintsEnabledFromLocalStorage = (): boolean => {
  const isEnabled =
    window.localStorage.getItem(LOCALSTORAGE_KEYS.SIP_CALLS_WEBRTC_ADVANCED_MEDIA_CONSTRAINTS_ENABLED) === 'true';
  return isEnabled;
};

export const setSIPCallsCapabilitiesAdvancedMediaConstraintsEnabledToLocalStorage = (isEnabled: boolean): void => {
  window.localStorage.setItem(LOCALSTORAGE_KEYS.SIP_CALLS_WEBRTC_ADVANCED_MEDIA_CONSTRAINTS_ENABLED, `${isEnabled}`);
};

export const getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage = (): boolean => {
  const isEnabled = window.localStorage.getItem(LOCALSTORAGE_KEYS.SIP_CALLS_WEBRTC_HD_CODEC_ENABLED) === 'true';
  return isEnabled;
};

export const setSIPCallsCapabilitiesHdCodecEnabledToLocalStorage = (isEnabled: boolean): void => {
  window.localStorage.setItem(LOCALSTORAGE_KEYS.SIP_CALLS_WEBRTC_HD_CODEC_ENABLED, `${isEnabled}`);
};

const sortSIPConfigWSSRecordsByPort = (wssRecords: VoipDomainSrvRecord[]): VoipDomainSrvRecord[] => {
  const sortedByPort = [...wssRecords].sort((portLow, portHigh) => (portLow.port || 0) - (portHigh.port || 0));
  return sortedByPort;
};
const sortSIPConfigWSSRecordsByPriority = (wssRecords: VoipDomainSrvRecord[]): VoipDomainSrvRecord[] => {
  const sortedByPriority = [...wssRecords].sort(
    (priorityLow, priorityHigh) => (priorityHigh.priority || 0) - (priorityLow.priority || 0),
  );
  return sortedByPriority;
};
export const getSIPConfigWSSRecordsUrlsByPortAndPriority = ({
  wssConfigRecords,
}: GetSIPConfigWSSRecordsUrlsByPortAndPriorityProps): GetSIPConfigWSSRecordsUrlsByPortAndPriorityReturn => {
  const wssRecords: SIPDataWssRecord[] = sortSIPConfigWSSRecordsByPort(
    sortSIPConfigWSSRecordsByPriority(wssConfigRecords),
  ).map((record) => ({
    wssUrl: `wss://${record.name}:${record.port}?transport=udp`,
    attemptCount: 0,
    isAvailable: true,
  }));

  return { wssRecords };
};

export const getSIPWSSAvailableRecordUrl = ({
  wssRecords,
}: GetSIPWSSAvailableRecordUrlProps): GetSIPWSSAvailableRecordUrlReturn => {
  const wssAvailableUrlOrEmpty = wssRecords.find((record) => record.isAvailable)?.wssUrl || '';
  return { wssAvailableUrlOrEmpty };
};

export const mapSIPWSSRecordsByUnavailableUrl = ({
  wssRecords,
  wssUnavailableUrl,
  wssAllowedAttemptCount,
}: MapSIPWSSRecordsByUnavailableUrlProps): MapSIPWSSRecordsByUnavailableUrlReturn => {
  const wssRecordsByAvailability: SIPDataWssRecord[] = wssRecords.map((record) => {
    if (record.wssUrl === wssUnavailableUrl) {
      const attemptCount = record.attemptCount + 1;
      return {
        wssUrl: record.wssUrl,
        attemptCount,
        isAvailable: attemptCount < wssAllowedAttemptCount,
      };
    }
    return record;
  });

  return { wssRecordsByAvailability };
};

export const getSIPConfigStunRecordsByPriority = ({
  stunConfigRecords,
}: GetSIPConfigStunRecordsByPriorityProps): GetSIPConfigStunRecordsByPriorityReturn => {
  const stunRecordsOrEmpty: string[] = stunConfigRecords
    .sort((priorityLow, priorityHigh) => (priorityLow.priority || 0) - (priorityHigh.priority || 0))
    .map(({ name = '', port = 0 }) => {
      if ([name.length, port].includes(0)) {
        return '';
      }
      return `stun:${name}:${port}`;
    })
    .filter((record) => record.length > 0);

  const stunRecords: string[] =
    stunRecordsOrEmpty.length > 0 ? stunRecordsOrEmpty : [SIP.CONFIGURATIONS.STUN_SERVER_FALLBACK_URL];

  return { stunRecords };
};

export const getSIPBrowserRestriction = ({ feature }: GetSIPBrowserRestrictionsProps): boolean => {
  const browserName = getUserAgentDataBrowserName().toLowerCase();

  return SIP.CALL_FEATURES_BROWSER_RESTRICTIONS[feature].includes(browserName);
};

export const generateAndSetSIPInstanceId = (): void => {
  const instanceIdNew = multiDeviceGenerateToken();
  window.localStorage.setItem(LOCALSTORAGE_KEYS.SIP_INSTANCE_ID, instanceIdNew);
};

export const getSIPInstanceIdFromLocalStorage = (): string => {
  const instanceIdLocal = window.localStorage.getItem(LOCALSTORAGE_KEYS.SIP_INSTANCE_ID) || '';
  return instanceIdLocal;
};

export const isIPv4Address = (ip: string) => REGEX.IPV4.test(ip);
