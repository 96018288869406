import { useCallback } from 'react';

import { GAEventKey, PremiumUpgradePromoClickSource } from 'types';

import { useWebAppDispatch, analyticsResendDataLayerVariables } from '@redux';
import { analyticsService } from 'services';

import { useDetectPremiumOfferFlow } from '../useDetectPremiumOfferFlow';

interface UsePremiumOfferAnalyticsTaggingReturn {
  sendPremiumOfferPromoTag: (clickSource: PremiumUpgradePromoClickSource) => void;
  sendPremiumOfferCheckoutTag: () => void;
}

export const usePremiumOfferAnalyticsTagging = (): UsePremiumOfferAnalyticsTaggingReturn => {
  const dispatch = useWebAppDispatch();

  const { isPremiumOfferFreeTrialFlow } = useDetectPremiumOfferFlow();

  const sendPremiumOfferPromoTag = useCallback(
    (clickSource: PremiumUpgradePromoClickSource): void => {
      analyticsService.pushToDataLayer({
        event: GAEventKey.PREMIUM_UPGRADE_PROMO,
        variables: { click_source: clickSource, trial: `${isPremiumOfferFreeTrialFlow}` },
      });
    },
    [isPremiumOfferFreeTrialFlow],
  );

  const sendPremiumOfferCheckoutTag = useCallback((): void => {
    dispatch(
      analyticsResendDataLayerVariables(GAEventKey.PREMIUM_UPGRADE_CHECKOUT, {
        trial: `${isPremiumOfferFreeTrialFlow}`,
      }),
    );
  }, [dispatch, isPremiumOfferFreeTrialFlow]);

  return {
    sendPremiumOfferPromoTag,
    sendPremiumOfferCheckoutTag,
  };
};
