import { Reducer } from '@reduxjs/toolkit';

import { NotificationsModalTypes } from 'types';

import { REDUX_ACTION_TYPES, NotificationsModalReduxState, NotificationsModalActionTypes } from '../../types';

export const notificationsModalInitialState: NotificationsModalReduxState = {
  isVisible: false,
  modalType: NotificationsModalTypes.NO_TYPE,
};

export const notificationsModalReducer: Reducer<NotificationsModalReduxState, NotificationsModalActionTypes> = (
  state,
  action,
) => {
  const reducerState: NotificationsModalReduxState = state || notificationsModalInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.NOTIFICATIONS_MODAL_SET_DATA: {
      const { isVisible, modalType } = action;

      return {
        isVisible: isVisible === undefined ? reducerState.isVisible : isVisible,
        modalType: modalType || reducerState.modalType,
      };
    }

    default:
      return reducerState;
  }
};
