import React from 'react';
import { useIntl } from 'react-intl';

import { SIP } from '@constants';
import { useWebAppSelector, selectBrowserPermissionMicrophoneStatus } from '@redux';
import { SIPDataCallStatusesFeatureMute } from '@redux/types';
import { testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { useSIPCallMethodsOngoing } from '../../../../hooks/useSIPCallMethods';
import { SIPCallsListItemButtonFeaturesMuteProps } from '../../types';
import SIPCallsListItemButtonTooltip from '../SIPCallsListItemButtonTooltip';

import styles from './SIPCallsListItemButtonFeaturesMute.module.scss';

const SIPCallsListItemButtonFeaturesMute: React.FC<SIPCallsListItemButtonFeaturesMuteProps> = ({
  isCallOngoing,
  isCallWaiting,
  statusFeatureMute,
  idWebRTC,
}) => {
  const intl = useIntl();
  const { sipCallMute, sipCallUnMute } = useSIPCallMethodsOngoing();
  const microphonePermission = useWebAppSelector(selectBrowserPermissionMicrophoneStatus);

  const isFeatureNotAvailable = !SIP.CALL_FEATURES.MUTE_ENABLED || !isCallOngoing;
  if (isFeatureNotAvailable) {
    return null;
  }

  const isCallMuted = statusFeatureMute === SIPDataCallStatusesFeatureMute.MUTED;

  const onClickHandlerButtonMute = (): void => {
    sipCallMute({
      idWebRTC,
    });
  };

  const onClickHandlerButtonUnMute = (): void => {
    sipCallUnMute({
      idWebRTC,
    });
  };

  return (
    <SIPCallsListItemButtonTooltip
      content={intl.formatMessage({
        id: isCallMuted ? 'SIP.Calls.UI.ButtonTooltips.unmute' : 'SIP.Calls.UI.ButtonTooltips.mute',
      })}
      placement="bottom-start"
    >
      <IconButton
        className={styles.root}
        onClick={isCallMuted ? onClickHandlerButtonUnMute : onClickHandlerButtonMute}
        iconName={isCallMuted ? IconCommonNames.MICROPHONE_OFF : IconCommonNames.MICROPHONE}
        iconSize={IconCommonSizes.TINY}
        isDisabled={isCallWaiting || microphonePermission === 'denied'}
        propTestId={
          isCallMuted ? testIds.sipCallsListItemButtonFeatureUnMuteRoot : testIds.sipCallsListItemButtonFeatureMuteRoot
        }
      />
    </SIPCallsListItemButtonTooltip>
  );
};

export default SIPCallsListItemButtonFeaturesMute;
