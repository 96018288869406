export enum CategoryColorsStatic {
  DISABLED = '#9298a2',
  EXPIRED = '#273348',
}

export enum CategoryColorsDynamic {
  COLOR_TYPE_1 = 'Red',
  COLOR_TYPE_2 = 'HotPink',
  COLOR_TYPE_3 = 'Yellow',
  COLOR_TYPE_4 = 'Green',
  COLOR_TYPE_5 = 'DarkTurquoise',
  COLOR_TYPE_6 = 'Blue',
  COLOR_TYPE_7 = 'Violet',
}

const mapCategoryColorsDynamic = new Map<CategoryColorsDynamic, string>([
  [CategoryColorsDynamic.COLOR_TYPE_1, '#ff5959'],
  [CategoryColorsDynamic.COLOR_TYPE_2, '#ff6d94'],
  [CategoryColorsDynamic.COLOR_TYPE_3, '#ffc627'],
  [CategoryColorsDynamic.COLOR_TYPE_4, '#72d54a'],
  [CategoryColorsDynamic.COLOR_TYPE_5, '#00c4b3'],
  [CategoryColorsDynamic.COLOR_TYPE_6, '#2f7de1'],
  [CategoryColorsDynamic.COLOR_TYPE_7, '#a25cbf'],
]);

export const getCategoryColors = (): string[] => Array.from(mapCategoryColorsDynamic.values());
