import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { formatNumberAsLocal } from 'helpers';

import { testIds } from '@testing';
import { IconCommonNames } from 'components/Icon';
import { ModalsAddEditContact } from 'components/Modals';

import { InfoTopBarActionsDropdownFeaturesContactAddEditProps } from '../../types';
import InfoTopBarActionsDropdownItem from '../InfoTopBarActionsDropdownItem';

const InfoTopBarActionsDropdownFeaturesContactAddEdit: React.FC<
  InfoTopBarActionsDropdownFeaturesContactAddEditProps
> = ({ phoneNumberOrEmpty, contact }) => {
  const intl = useIntl();

  const [stateModalContactVisible, setStateModalContactVisible] = useState<boolean>(false);

  const isContactExist = contact !== undefined;

  const onClickHandlerContactsAddEdit = (): void => {
    setStateModalContactVisible(!stateModalContactVisible);
  };

  return (
    <>
      <InfoTopBarActionsDropdownItem
        iconName={isContactExist ? IconCommonNames.PERSON : IconCommonNames.ADD_PERSON}
        label={intl.formatMessage({
          id: `InfoTopBar.Actions.Dropdown.button_contact_${isContactExist ? 'edit' : 'add'}`,
        })}
        onClick={onClickHandlerContactsAddEdit}
        propTestId={
          isContactExist
            ? testIds.infoTopBarActionsDropdownButtonContactsEdit
            : testIds.infoTopBarActionsDropdownButtonContactsAdd
        }
      />
      <ModalsAddEditContact
        isOpen={stateModalContactVisible}
        onClose={onClickHandlerContactsAddEdit}
        formDataDefault={contact || { phones: [{ number: formatNumberAsLocal(phoneNumberOrEmpty) }] }}
        isEditModal={isContactExist}
      />
    </>
  );
};

export default InfoTopBarActionsDropdownFeaturesContactAddEdit;
