import {
  useWebAppDispatch,
  categoriesSetAll,
  categoriesUpdateCategoryHandler,
  categoriesRemoveCategoriesAndRelatedDataHandler,
} from '@redux';

import {
  UsePubNubMessagesHandlerCategoriesReturn,
  PubNubPushMessageCategoriesNewCategoryPayload,
  PubNubPushMessageCategoriesUpdateCategoryPayload,
  PubNubPushMessageCategoriesDeleteCategoryPayload,
} from '../../types';

export const usePubNubMessagesHandlerCategories = (): UsePubNubMessagesHandlerCategoriesReturn => {
  const dispatch = useWebAppDispatch();

  const pubNubMessageHandlerCategoriesNewCategory = ({
    createdCategories,
  }: PubNubPushMessageCategoriesNewCategoryPayload): void => {
    dispatch(categoriesSetAll(createdCategories));
  };

  const pubNubMessageHandlerCategoriesUpdateCategory = ({
    category,
  }: PubNubPushMessageCategoriesUpdateCategoryPayload): void => {
    dispatch(categoriesUpdateCategoryHandler({ category }));
  };

  const pubNubMessageHandlerCategoriesRemoveCategory = ({
    deletedCategories,
  }: PubNubPushMessageCategoriesDeleteCategoryPayload): void => {
    dispatch(categoriesRemoveCategoriesAndRelatedDataHandler({ categoryIds: deletedCategories }));
  };

  return {
    pubNubMessageHandlerCategoriesNewCategory,
    pubNubMessageHandlerCategoriesUpdateCategory,
    pubNubMessageHandlerCategoriesRemoveCategory,
  };
};
