import classnames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Status } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  fetchUserCredits,
  selectBalanceState,
  selectAuthenticationStatuses,
} from '@redux';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { useUserCapabilities } from 'hooks';

import BalanceCredit from '../BalanceCredit';

import styles from './Balance.module.scss';

export interface BalanceProps {
  isMinimized: boolean;
}

const Balance: React.FC<BalanceProps> = ({ isMinimized }) => {
  const dispatch = useWebAppDispatch();
  const { statusLogin } = useWebAppSelector(selectAuthenticationStatuses);
  const { credits, isLoadedOnce } = useWebAppSelector(selectBalanceState);
  const { isUserB2B } = useUserCapabilities();

  const getUserCredits = useCallback(() => {
    /**
     * TODO: Technical Depth => "isLoadedOnce"
     */
    if (!isLoadedOnce && statusLogin === Status.SUCCEEDED) {
      dispatch(fetchUserCredits());
    }
  }, [isLoadedOnce, statusLogin, dispatch]);

  useEffect(() => {
    getUserCredits();
  }, [getUserCredits]);

  return (
    <div
      className={classnames(styles.root, {
        [styles.minimized]: isMinimized,
        [styles.isB2B]: isUserB2B,
        [styles.isB2C]: !isUserB2B,
      })}
    >
      <BalanceCredit isUserB2B={isUserB2B} />
      {!isMinimized && (
        <div className={styles.content}>
          <span className={styles.text}>
            <FormattedMessage id={'SideMenuCategories.balance_current'} />
          </span>
          {!isLoadedOnce ? (
            <LoadingSkeleton className={styles.loadingSkeleton} />
          ) : (
            <span className={styles.credits}>{credits}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default Balance;
