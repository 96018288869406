import classNames from 'classnames';
import React from 'react';

import { testGetTestingAttributes } from '@testing';

import { SwitchDashboardSize } from '../../enums';
import { SwitchDashboardProps } from '../../types';

import styles from './SwitchDashboard.module.scss';

const SwitchDashboard: React.FC<SwitchDashboardProps> = ({
  onSwitch,
  isSwitchedOn = false,
  leftPlaceholder = '',
  rightPlaceholder = '',
  className = '',
  size = SwitchDashboardSize.DEFAULT,
  propTestIds = {},
}) => {
  const onLeftPlaceholderClick = (): void => {
    if (isSwitchedOn) {
      onSwitch(false);
    }
  };

  const onRightPlaceholderClick = (): void => {
    if (!isSwitchedOn) {
      onSwitch(true);
    }
  };

  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.switchOn]: isSwitchedOn,
          [styles[`size-${size}`]]: true,
        },
        className,
      )}
    >
      <div className={styles.slider}>
        <div className={styles.sliderButton} />
      </div>
      <div
        className={styles.leftPlaceholder}
        onClick={onLeftPlaceholderClick}
        {...testGetTestingAttributes(propTestIds.switchOn)}
      >
        {leftPlaceholder}
      </div>
      <div
        className={styles.rightPlaceholder}
        onClick={onRightPlaceholderClick}
        {...testGetTestingAttributes(propTestIds.switchOff)}
      >
        {rightPlaceholder}
      </div>
    </div>
  );
};

export default SwitchDashboard;
