import { useEffect, useState } from 'react';

import { authenticationHasAuth } from 'helpers';

import { useWebAppSelector, selectMultiDeviceIsMaxActiveConnectionReached } from '@redux';

export const useAppMultiDevice = (): {
  showModalMultiDeviceLogout: boolean;
} => {
  const multiDeviceIsMaxActiveConnectionReached = useWebAppSelector(selectMultiDeviceIsMaxActiveConnectionReached);
  const [showModalMultiDeviceLogout, setShowModalMultiDeviceLogout] = useState<boolean>(false);
  const isUserAuthenticated = authenticationHasAuth();

  useEffect(() => {
    if (isUserAuthenticated && multiDeviceIsMaxActiveConnectionReached) {
      setShowModalMultiDeviceLogout(true);
    } else if (showModalMultiDeviceLogout) {
      setShowModalMultiDeviceLogout(false);
    }
  }, [multiDeviceIsMaxActiveConnectionReached, isUserAuthenticated, showModalMultiDeviceLogout]);

  return {
    showModalMultiDeviceLogout,
  };
};
