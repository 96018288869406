import { LegacyUniversalConfiguration } from 'types';

const universal: LegacyUniversalConfiguration = {
  apiController: 'api',

  validationRules: {
    category: {
      id: { required: true, min: 30, max: 45 },
      name: { required: true, min: 1, max: 190 },
      company: { min: 1, max: 190 },
      color: {
        required: true,
        min: 7,
        max: 7,
        regex: '^#[0-9A-Fa-f]{6}$',
      },
      notes: { min: 1, max: 2048 },
      emails: {
        email: { min: 0, max: 190 },
        type: { min: 0, max: 190 },
      },
      phones: {
        number: { min: 0, max: 190 },
        normalNumber: { min: 0, max: 190 },
        favorite: { boolean: true },
        type: { min: 0, max: 190 },
      },
    },
    messageV2: {
      body: { min: 1, max: 2048 },
      delayInMinutes: { min: 1, max: 2147483647 },
      // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
      mediaLength: { min: 1, max: 9223372036854775807 }, // java long, for onoff messages only
      sourceCategoryId: { required: true, min: 30, max: 45 },
      targetPhoneNumber: { min: 1, max: 45 },
      threadId: { min: 30, max: 45 },
      type: {
        required: true,
        min: 1,
        max: 45,
        oneOf: ['TEXT', 'IMAGE', 'AUDIO', 'VIDEO', 'INSTA_VIDEO'],
      },
      // videoThumbnail - optional, required for VIDEO, byte array
    },
    messageV4: {
      content: { required: true, min: 1, max: 2048 },
      contentType: { min: 1, max: 2048 }, // for multimedia messages
      delayInMinutes: { min: 1, max: 2147483647 },
      // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
      mediaLength: { min: 1, max: 9223372036854775807 }, // java long, for multimedia messages
      messageType: {
        required: true,
        min: 1,
        max: 45,
        oneOf: ['TEXT', 'IMAGE', 'AUDIO', 'VIDEO', 'INSTA_VIDEO'],
      },
      originalFilename: { min: 1, max: 2048 }, // for multimedia messages
      threadId: { required: true, min: 30, max: 45 },
    },
    contact: {
      id: { min: 30, max: 45 },
      categoryId: { min: 30, max: 45 },
      firstName: { min: 1, max: 190 },
      lastName: { min: 0, max: 190 },
      jobTitle: { min: 0, max: 190 },
      company: { min: 0, max: 190 },
      notes: { min: 0, max: 2048 },
      blocked: { boolean: true },
      favorite: { boolean: true },
      birthday: { min: 0, max: 190 },
      urls: { min: 0, max: 190 },
      phones: {
        number: { min: 0, max: 190 },
        normalNumber: { min: 0, max: 190 },
        favorite: { boolean: true },
        type: { min: 0, max: 190 },
      },
      emails: {
        email: { min: 0, max: 190 },
        type: { min: 0, max: 190 },
      },
      addresses: {
        street1: { min: 0, max: 190 },
        street2: { min: 0, max: 190 },
        state: { min: 0, max: 190 },
        country: { min: 0, max: 190 },
        postalCode: { min: 0, max: 190 },
        pobox: { min: 0, max: 190 },
        city: { min: 0, max: 190 },
        type: { min: 0, max: 190 },
      },
      imProfiles: {
        profile: { min: 0, max: 190 },
        type: { min: 0, max: 190 },
      },
      socialProfiles: {
        profile: { min: 0, max: 190 },
        type: { min: 0, max: 190 },
      },
      phoneBookId: { min: 0, max: 190 },
    },
  },
};

export default universal;
