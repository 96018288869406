import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';

import {
  ActiveIntegration,
  IntegrationDetailedSearchContactsData,
  SaveSimpleContactRequest,
  Status,
  StyleableComponent,
} from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  integrationsFetchContactDetailedSearchInfo,
  selectIntegrationsActiveIntegrations,
  selectIntegrationsDetailsForContact,
  selectIntegrationsStatuses,
} from '@redux';
import { ModalsAddEditContact } from 'components/Modals';

import { IntegrationCRMCardType } from '../../types';
import IntegrationCRMCard from '../IntegrationCRMCard';

import styles from './IntegrationCRM.module.scss';

interface IntegrationCRMProps extends StyleableComponent {
  contactId: string;
  phoneNumbers: Array<string>;
  appContactId?: string;
  selectedIntegration?: ActiveIntegration;
  integrationCrmCardType?: IntegrationCRMCardType;
  isLocalContact?: boolean;
  isCRMContact?: boolean;
}

const IntegrationCRM: React.FC<IntegrationCRMProps> = ({
  className,
  contactId,
  phoneNumbers,
  appContactId,
  selectedIntegration,
  isLocalContact = false,
  isCRMContact = false,
  integrationCrmCardType = IntegrationCRMCardType.CONTACT_PAGE,
}) => {
  const dispatch = useWebAppDispatch();

  const activeIntegrations = useWebAppSelector(selectIntegrationsActiveIntegrations);
  const contactIntegrationDetails = useWebAppSelector(selectIntegrationsDetailsForContact(contactId));
  const { fetchIntegrationContactDetails } = useWebAppSelector(selectIntegrationsStatuses);

  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [contactModalData, setContactModalData] = useState<SaveSimpleContactRequest>();

  const integrationsToDisplay: Array<ActiveIntegration> = useMemo(
    () => (selectedIntegration ? [selectedIntegration] : activeIntegrations),
    [selectedIntegration, activeIntegrations],
  );

  const onReloadFetchIntegrationDetail = useCallback(
    (integrationId: string) => {
      dispatch(
        integrationsFetchContactDetailedSearchInfo({
          phoneNumbers,
          integrationName: integrationId,
          contactId,
          appContactId,
        }),
      );
    },
    [contactId, dispatch, phoneNumbers, appContactId],
  );

  const onClickFetchIntegrationDetail = useCallback(
    (integrationId: string) => {
      if (fetchIntegrationContactDetails[integrationId] === Status.IDLE) {
        onReloadFetchIntegrationDetail(integrationId);
      }
    },
    [fetchIntegrationContactDetails, onReloadFetchIntegrationDetail],
  );

  const onContactModalOpenClick = (details: IntegrationDetailedSearchContactsData | null, isEdit: boolean): void => {
    setIsEditModal(isEdit);
    if (details) {
      const { firstName, lastName, emails, phones, companyName } = details;
      const updatedEmails = emails.map((email) => ({ email }));
      const updatedPhones = phones.map((phone) => ({ number: phone }));

      setContactModalData({
        id: isEdit ? contactId : undefined,
        firstName,
        lastName,
        emails: updatedEmails,
        phones: updatedPhones,
        company: companyName,
      });
    }
    setIsContactModalOpen(true);
  };

  return (
    <div className={classNames(className, styles.root)}>
      {integrationsToDisplay.map((integration, index) => (
        <IntegrationCRMCard
          key={`${contactId}${index}`}
          className={classNames(styles.crmCard, {
            [styles.callPopupCard]: integrationCrmCardType === IntegrationCRMCardType.CALL_POPUP,
            [styles.contactPageCard]: integrationCrmCardType === IntegrationCRMCardType.CONTACT_PAGE,
          })}
          integrationCrmCardType={integrationCrmCardType}
          integration={integration}
          status={fetchIntegrationContactDetails[integration.id]}
          details={contactIntegrationDetails ? contactIntegrationDetails[integration.id] : null}
          fetchIntegrationDetail={onClickFetchIntegrationDetail}
          reloadIntegrationDetail={onReloadFetchIntegrationDetail}
          onContactModalOpenClick={onContactModalOpenClick}
          isLocalContact={isLocalContact}
          isCRMContact={isCRMContact}
        />
      ))}

      <ModalsAddEditContact
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
        formDataDefault={contactModalData}
        isEditModal={isEditModal}
        isCRMContactModal={isEditModal}
      />
    </div>
  );
};

export default IntegrationCRM;
