import { StyleableComponent } from 'types/components';

const LogoB2C: React.FC<StyleableComponent> = ({ className = '' }) => (
  <svg
    className={className}
    width="85"
    height="25"
    viewBox="0 0 95 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.0551 7.35107C43.0012 7.35107 46.3132 10.0247 46.3132 14.6537C46.3132 19.2257 43.0012 21.928 39.0551 21.928C35.138 21.928 31.7971 19.2538 31.7971 14.6537C31.7971 10.0247 35.138 7.35107 39.0551 7.35107ZM39.0551 18.7654C41.2152 18.7654 42.6551 17.0976 42.6551 14.6537C42.6551 12.152 41.2152 10.5417 39.0551 10.5417C36.9528 10.5417 35.4261 12.152 35.4261 14.6537C35.4261 17.0976 36.9528 18.7654 39.0551 18.7654Z"
      fill="#273348"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.499 21.596H56.939V13.4309C56.939 11.4907 56.119 10.5643 54.6784 10.5643C53.1242 10.5643 51.8525 11.8096 51.8525 13.6624V21.596H48.2925V7.78453H51.5983V9.52183H51.6553C52.418 8.27699 53.9439 7.35059 55.7803 7.35059C58.6906 7.35059 60.499 9.31917 60.499 12.7646V21.596Z"
      fill="#273348"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.7361 7.35107C73.6814 7.35107 76.9938 10.0247 76.9938 14.6537C76.9938 19.2257 73.6814 21.928 69.7361 21.928C65.819 21.928 62.478 19.2538 62.478 14.6537C62.478 10.0247 65.819 7.35107 69.7361 7.35107ZM69.7361 18.7654C71.8959 18.7654 73.3361 17.0976 73.3361 14.6537C73.3361 12.152 71.8959 10.5417 69.7361 10.5417C67.6337 10.5417 66.107 12.152 66.107 14.6537C66.107 17.0976 67.6337 18.7654 69.7361 18.7654Z"
      fill="#273348"
    />
    <mask
      id="logo-b2c-mask-1"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="78"
      y="1"
      width="8"
      height="21"
    >
      <path
        d="M78.9739 1.05615H85.9019V21.5962H78.9739V1.05615Z"
        fill="white"
      />
    </mask>
    <g mask="url(#logo-b2c-mask-1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.9739 7.89293V6.22667C78.9739 2.9233 80.7561 1.05566 84.0049 1.05566C84.8101 1.05566 85.5573 1.17067 85.9019 1.28567V4.33067C85.4708 4.2448 85.0972 4.18705 84.7238 4.18705C83.2569 4.18705 82.5958 5.0488 82.5958 6.54229V7.89293H85.9019V10.9666H82.5958V21.5958H78.9739V10.9666V7.89293Z"
        fill="#273348"
      />
    </g>
    <mask
      id="logo-b2c-mask-2"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="87"
      y="1"
      width="8"
      height="21"
    >
      <path
        d="M87.8809 1.05615H94.8091V21.5962H87.8809V1.05615Z"
        fill="white"
      />
    </mask>
    <g mask="url(#logo-b2c-mask-2)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.8809 7.89293V6.22667C87.8809 2.9233 89.6633 1.05566 92.9116 1.05566C93.7168 1.05566 94.4637 1.17067 94.8091 1.28567V4.33067C94.3775 4.2448 94.0039 4.18705 93.6305 4.18705C92.1642 4.18705 91.5028 5.0488 91.5028 6.54229V7.89293H94.8091V10.9666H91.5028V21.5958H87.8809V10.9666V7.89293Z"
        fill="#273348"
      />
    </g>
    <mask
      id="logo-b2c-mask-3"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="26"
      height="26"
    >
      <path
        d="M0 1.42725H25.3239V26.8578H0V1.42725Z"
        fill="white"
      />
    </mask>
    <g mask="url(#logo-b2c-mask-3)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6619 1.42801C5.67257 1.42801 -0.000244141 7.12522 -0.000244141 14.1432C-0.000244141 21.1603 5.67257 26.8578 12.6608 26.8578C19.6501 26.8578 25.3237 21.1603 25.3237 14.1432C25.3237 7.12497 19.6501 1.42725 12.6619 1.42725V1.42801ZM20.0049 14.3361C19.5145 15.5222 18.5589 16.8473 16.9706 18.4471C15.4103 20.0195 14.0671 20.984 12.9141 21.5084C12.2007 21.8401 11.4856 21.9774 10.7433 21.9505C9.18452 21.9797 7.68201 21.372 6.58237 20.2684C5.47025 19.1591 4.85795 17.6451 4.88722 16.0749C4.8549 15.3576 5.01701 14.64 5.31705 13.9512C5.80719 12.7642 6.76433 11.4125 8.35132 9.8403C9.93882 8.24023 11.2548 7.27498 12.4351 6.77766C13.1131 6.4577 13.8582 6.30717 14.6069 6.33682C16.155 6.31612 17.6458 6.92282 18.7385 8.01943C19.8458 9.12115 20.4579 10.6244 20.4345 12.1858C20.466 12.9303 20.3039 13.6469 20.0039 14.3369"
        fill="url(#logo-b2c-gradient-1)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9046 11.9942C18.9046 14.2671 17.0657 16.112 14.8028 16.112C12.5383 16.112 10.7012 14.2671 10.7012 11.9942C10.7012 9.71994 12.5383 7.87402 14.8028 7.87402C17.0662 7.87402 18.9046 9.71942 18.9046 11.9942Z"
      fill="url(#logo-b2c-gradient-2)"
    />
    <defs>
      <linearGradient
        id="logo-b2c-gradient-1"
        x1="2.78536"
        y1="21.746"
        x2="21.7002"
        y2="5.36082"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#575DF2" />
        <stop
          offset="1"
          stopColor="#32A8FF"
        />
      </linearGradient>
      <linearGradient
        id="logo-b2c-gradient-2"
        x1="3.2435"
        y1="22.4043"
        x2="21.5604"
        y2="5.2393"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#575DF2" />
        <stop
          offset="1"
          stopColor="#32A8FF"
        />
      </linearGradient>
    </defs>
  </svg>
);

export default LogoB2C;
