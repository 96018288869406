import { isString } from 'helpers';

import { PubNubPushMessages, CheckPubNubLogsModeReturn } from '../types';

export const checkPubNubLogsMode = (): CheckPubNubLogsModeReturn => {
  const isPubNubLogsMode = process.env.REACT_APP_PUBNUB_LOGS === 'true';
  return {
    isPubNubLogsMode,
  };
};

export const pubNubDebugConsoleLogger = (...args: unknown[]): void => {
  const { isPubNubLogsMode } = checkPubNubLogsMode();
  if (isPubNubLogsMode) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

export const pubNubDecodeMessageAndParse = (message = ''): PubNubPushMessages | null => {
  try {
    // https://developer.mozilla.org/en-US/docs/Web/API/atob
    const messageDecoded = atob(message);

    // Convert to Unicode, (e.g. non-latin characters, emojis, and so on...)
    const messageDecodedAsUnicode = messageDecoded
      .split('')
      .map((eachCharacter = '') => {
        const byteStream = `00${eachCharacter.charCodeAt(0).toString(16)}`.slice(-2);
        const percentEncoding = `%${byteStream}`;
        return percentEncoding;
      })
      .join('');

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent
    const messageDecodedURI = decodeURIComponent(messageDecodedAsUnicode);

    // Parsed Message
    const messageParsed = JSON.parse(messageDecodedURI);

    // Parsed Message must have a Type
    if (!isString(messageParsed.type)) {
      throw new Error('Dev Error: PubNub Messages should have a type');
    }

    // Parsed Messages' Payload still might be a string
    if (isString(messageParsed.payload)) {
      messageParsed.payload = JSON.parse(messageParsed.payload);
    }

    return messageParsed;
  } catch {
    return null;
  }
};
