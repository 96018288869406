import { CountryCode } from 'libphonenumber-js';
import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Contact, SimpleContactPhone } from 'types';

import { useWebAppSelector, selectUserAllowedCountries } from '@redux';
import { ButtonFlag } from 'components/Button';
import { ModalsSelectCountry } from 'components/Modals';

import ModalsDialpadSelectPhoneNumberInput from '../ModalsDialpadSelectPhoneNumberInput';

import styles from './ModalsDialpadSelectPhoneNumber.module.scss';

interface ModalsDialpadSelectPhoneNumberProps {
  country: CountryCode;
  isDisabled?: boolean;
  hasError?: boolean;
  inputValue?: string;
  onChangeCountry: (country: CountryCode) => void;
  onChangeInput: (value: string) => void;
  onSelectContactPhone: (contact: Contact, phone: SimpleContactPhone) => void;
}

const ModalsDialpadSelectPhoneNumber: React.FC<ModalsDialpadSelectPhoneNumberProps> = ({
  country,
  isDisabled = false,
  hasError = false,
  inputValue = '',
  onChangeCountry,
  onChangeInput,
  onSelectContactPhone,
}) => {
  const intl = useIntl();

  const countries = useWebAppSelector(selectUserAllowedCountries);
  const [isSelectingCountry, setIsSelectingCountry] = useState<boolean>(false);
  const [currentContactName, setCurrentContactName] = useState<string>('');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const openCountrySelect = useCallback(() => setIsSelectingCountry(true), [setIsSelectingCountry]);
  const closeCountrySelect = useCallback(() => {
    setIsSelectingCountry(false);
    inputRef.current?.focus();
  }, [setIsSelectingCountry]);

  const handleChangeInput = (value: string) => {
    onChangeInput(value);
  };

  const handleSelectCountry = (selectedCountry: CountryCode) => {
    setCurrentContactName('');
    onChangeCountry(selectedCountry);
  };

  return (
    <div className={styles.root}>
      <ModalsSelectCountry
        isOpen={isSelectingCountry}
        onClose={closeCountrySelect}
        onSelectCountry={handleSelectCountry}
        countryList={countries}
      />
      <ButtonFlag
        isDisabled={isDisabled}
        hasError={hasError}
        className={styles.buttonFlag}
        onClick={openCountrySelect}
        currentCountry={country}
      />
      <ModalsDialpadSelectPhoneNumberInput
        country={country}
        onChange={handleChangeInput}
        onSelectContactPhone={onSelectContactPhone}
        placeholder={intl.formatMessage({ id: 'DialPad.enter_name_or_number' })}
        value={inputValue}
        isDisabled={isDisabled}
        hasError={hasError}
        ref={inputRef}
        setCurrentContactName={setCurrentContactName}
        currentContactName={currentContactName}
      />
    </div>
  );
};

export default ModalsDialpadSelectPhoneNumber;
