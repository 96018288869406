import { createSelector } from '@reduxjs/toolkit';

import {
  SelectCallQualitySurveyData,
  SelectCallQualitySurveyIsPopupOpen,
  SelectCallQualitySurveyState,
  SelectCallQualitySurveyStatuses,
} from '../../types';

export const selectCallQualitySurveyState: SelectCallQualitySurveyState = (state) => state.callQualitySurvey;

export const selectCallQualitySurveyIsPopupOpen: SelectCallQualitySurveyIsPopupOpen = createSelector(
  [selectCallQualitySurveyState],
  (callQualitySurveyState) => callQualitySurveyState.isPopupOpen,
);

export const selectCallQualitySurveyStatuses: SelectCallQualitySurveyStatuses = createSelector(
  [selectCallQualitySurveyState],
  (callQualitySurveyState) => callQualitySurveyState.statuses,
);

export const selectCallQualitySurveyData: SelectCallQualitySurveyData = createSelector(
  [selectCallQualitySurveyState],
  (callQualitySurveyState) => callQualitySurveyState.surveyData,
);
