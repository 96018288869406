import { ActiveIntegration, IntegrationContactRawData, Status } from 'types';

import { normalizePhoneNumber, transformActiveIntegrations } from 'helpers';

import { INTEGRATIONS } from '@constants';

import { selectUserData, selectIntegrationsState, selectIntegrationsStatuses } from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  ContactId,
  IntegrationDetailValue,
  IntegrationsResetBasicSearchContactsData,
  IntegrationsResetDetails,
  IntegrationsResetExportContactStatuses,
  IntegrationsSetActiveId,
  IntegrationsSetActiveIntegrations,
  IntegrationsSetBasicSearchContactsData,
  IntegrationsSetContactDetails,
  IntegrationsSetExportContactStatus,
  IntegrationsSetFetchActiveIntegrationsStatus,
  IntegrationsSetFetchBasicSearchStatus,
  IntegrationsSetFetchBasicSearchStatusByEach,
  IntegrationsSetFetchIntegrationContactStatus,
  IntegrationsStatusChangedHandlerProps,
} from '../../types';

export const integrationsSetActiveId = (activeId: string): IntegrationsSetActiveId => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_SET_ACTIVE_ID,
  activeId,
});

export const integrationsSetActiveIntegrations = (
  integrations: Array<ActiveIntegration>,
): IntegrationsSetActiveIntegrations => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_SET_ACTIVE_INTEGRATIONS,
  integrations,
});

export const integrationsSetFetchActiveIntegrationsStatus = (
  status: Status,
): IntegrationsSetFetchActiveIntegrationsStatus => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_FETCH_ACTIVE_INTEGRATIONS,
  status,
});

export const integrationsSetContactDetails = (
  detail: IntegrationDetailValue,
  contactId: ContactId,
): IntegrationsSetContactDetails => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_SET_CONTACT_DETAILS,
  detail,
  contactId,
});

export const integrationsSetFetchIntegrationContactStatus = (
  status: Status,
  integrationName: string,
): IntegrationsSetFetchIntegrationContactStatus => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_FETCH_INTEGRATION_CONTACT_DETAILS,
  status,
  integrationName,
});

export const integrationsSetExportContactStatus = (
  status: Status,
  integrationName: string,
): IntegrationsSetExportContactStatus => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_EXPORT_CONTACT,
  status,
  integrationName,
});

export const integrationsResetExportContactStatuses = (): IntegrationsResetExportContactStatuses => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_RESET_STATUSES_EXPORT_CONTACT,
});

export const integrationsResetDetails = (integrationIds: Array<string>): IntegrationsResetDetails => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_RESET_DETAILS,
  integrationIds,
});

export const integrationsSetFetchBasicSearchStatus = (
  fetchIntegrationBasicSearch: Status,
): IntegrationsSetFetchBasicSearchStatus => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_FETCH_BASIC_SEARCH,
  fetchIntegrationBasicSearch,
});

export const integrationsSetFetchBasicSearchStatusByEach = (
  status: Status,
  integrationName: string,
): IntegrationsSetFetchBasicSearchStatusByEach => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_SET_STATUS_FETCH_BASIC_SEARCH_BY_EACH,
  status,
  integrationName,
});

export const integrationsSetBasicSearchContacts = (
  integrationContacts: Array<IntegrationContactRawData>,
  integrationName: string,
): IntegrationsSetBasicSearchContactsData => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_SET_BASIC_SEARCH_CONTACTS,
  integrationContacts,
  integrationName,
});

export const integrationsResetBasicSearchContacts = (): IntegrationsResetBasicSearchContactsData => ({
  type: REDUX_ACTION_TYPES.INTEGRATIONS_RESET_BASIC_SEARCH_CONTACTS,
});

export const integrationsFetchActiveIntegrationsHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      const { b2bUser: isUserB2B } = selectUserData(getState());
      if (!isUserB2B) {
        return;
      }

      dispatch(integrationsSetFetchActiveIntegrationsStatus(Status.LOADING));

      const { integrationsEnabled = [] } = await services.integrationsService.fetchActiveIntegrations();

      if (integrationsEnabled.length === 0) {
        dispatch(integrationsSetFetchActiveIntegrationsStatus(Status.SUCCEEDED));
        return;
      }

      const activeIntegrations: ActiveIntegration[] = transformActiveIntegrations(integrationsEnabled);

      dispatch(integrationsSetActiveIntegrations(activeIntegrations));

      dispatch(integrationsSetFetchActiveIntegrationsStatus(Status.SUCCEEDED));
    } catch (error) {
      dispatch(integrationsSetFetchActiveIntegrationsStatus(Status.FAILED));
      throw error;
    }
  };

export const integrationsExportContact =
  (integrationName: string, contactId: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    dispatch(integrationsSetExportContactStatus(Status.LOADING, integrationName));

    try {
      await services.integrationsService.exportContact({ contactId, integrationName });
      dispatch(integrationsSetExportContactStatus(Status.SUCCEEDED, integrationName));
    } catch (error) {
      dispatch(integrationsSetExportContactStatus(Status.FAILED, integrationName));
    }
  };

export const integrationsFetchContactDetailedSearchInfo =
  ({
    phoneNumbers,
    integrationName,
    contactId,
    appContactId,
  }: {
    phoneNumbers: Array<string>;
    integrationName: string;
    contactId: string;
    appContactId?: string;
  }): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    const normalizedPhoneNumbers = phoneNumbers.map((number) => normalizePhoneNumber(number));

    try {
      dispatch(integrationsSetFetchIntegrationContactStatus(Status.LOADING, integrationName));
      const { contactDetailsData } = await services.integrationsService.fetchContactDetailedSearchInfo({
        request: {
          contactPhones: normalizedPhoneNumbers,
          integrationName,
          appContactId,
        },
      });

      if (contactDetailsData !== undefined) {
        const detail = { [integrationName]: contactDetailsData };
        dispatch(integrationsSetContactDetails(detail, contactId));
      }

      dispatch(integrationsSetFetchIntegrationContactStatus(Status.SUCCEEDED, integrationName));
    } catch (error) {
      dispatch(integrationsSetFetchIntegrationContactStatus(Status.FAILED, integrationName));
      throw error;
    }
  };

export const integrationsFetchContactBasicSearchInfo =
  (searchTerm: string, integrationName: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(integrationsSetFetchBasicSearchStatusByEach(Status.LOADING, integrationName));
      const { appContacts: integrationContacts } = await services.integrationsService.fetchContactBasicSearchInfo({
        request: {
          query: searchTerm,
          integrationName,
        },
      });

      if (integrationContacts !== undefined) {
        dispatch(integrationsSetBasicSearchContacts(integrationContacts, integrationName));
        dispatch(integrationsSetFetchBasicSearchStatusByEach(Status.SUCCEEDED, integrationName));
      }
    } catch (error) {
      dispatch(integrationsSetFetchBasicSearchStatusByEach(Status.FAILED, integrationName));
      throw error;
    }
  };

export const integrationsSearchContactBasicSearchInfoHandler =
  (searchTerm = ''): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    /**
     * There is an ongoing search, return
     */
    const { fetchIntegrationBasicSearch } = selectIntegrationsStatuses(getState());
    if (fetchIntegrationBasicSearch === Status.LOADING) {
      return;
    }

    /**
     * Search Term is not fitting CRM API expectations, return
     */
    const searchTermTrimmed = searchTerm.trim();
    if (searchTermTrimmed.length <= INTEGRATIONS.BASIC_SEARCH_SEARCH_TERM_MIN_THRESHOLD) {
      return;
    }

    try {
      dispatch(integrationsSetFetchBasicSearchStatus(Status.LOADING));

      const { activeIntegrations } = selectIntegrationsState(getState());
      await Promise.all(
        activeIntegrations.map((integration) =>
          dispatch(integrationsFetchContactBasicSearchInfo(searchTermTrimmed, integration.id)),
        ),
      );

      dispatch(integrationsSetFetchBasicSearchStatus(Status.SUCCEEDED));
    } catch (error) {
      dispatch(integrationsSetFetchBasicSearchStatus(Status.FAILED));
      throw error;
    }
  };

export const integrationsStatusChangedHandler =
  ({ integrationsEnabled }: IntegrationsStatusChangedHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    const activeIntegrations = transformActiveIntegrations(integrationsEnabled);
    const activeIntegrationIds = activeIntegrations.map(({ id }) => id);

    dispatch(integrationsSetActiveIntegrations(activeIntegrations));
    dispatch(integrationsResetDetails(activeIntegrationIds));
  };
