import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Modal, PopupVertical } from '@onoff/ui';

import { Status } from 'types';

import { formatNumber, isOfferAutoRenewable, isOfferOrCategoryPossibleToChangeDuration } from 'helpers';

import {
  plansAndOffersChangeDuration,
  plansAndOffersFetchAvailableOffersAndPlans,
  plansAndOffersSetSubscriptionIdForRenewalPeriodChange,
  selectCategoriesStatistics,
  selectPlansAndOffersStatuses,
  selectPlansAndOffersUserOffer,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { InputCheckbox } from 'components/Input';

import { Loading, SelectionItem } from './components';
import { calculatePlanPrices, isProductPremiumOffer as isProductPremiumOfferHelper } from './helpers';
import { useProductPlanData } from './hooks';
import { ModalsRenewalPeriodProps } from './types';

import styles from './ModalsRenewalPeriod.module.scss';

export { usePlanOrOfferRenewalPeriodData, useRenewalPeriodModal } from './hooks';

export const ModalsRenewalPeriod = ({ isOpen, onClose, product }: ModalsRenewalPeriodProps) => {
  const dispatch = useWebAppDispatch();

  const { fetchAvailableOffersAndPlans: fetchAvailableOffersAndPlansStatus, changeDuration: changeDurationStatus } =
    useWebAppSelector(selectPlansAndOffersStatuses);
  const userOffer = useWebAppSelector(selectPlansAndOffersUserOffer);
  const categoryStatistics = useWebAppSelector(selectCategoriesStatistics);

  const { availablePlans, subscriptionId, planDuration, nextPlanDuration } = useProductPlanData(product);
  const planPrices = calculatePlanPrices(availablePlans);

  const [selectedDuration, setSelectedDuration] = useState(nextPlanDuration ?? planDuration);
  const [additionallyApplyToPremiumOffer, setAdditionallyApplyToPremiumOffer] = useState(false);
  const [additionallyApplyToAllNumbers, setAdditionallyApplyToAllNumbers] = useState(false);

  const arePlansLoaded = !!availablePlans;
  const isPurchaseSourceSupported = isOfferOrCategoryPossibleToChangeDuration({ product });
  const isProductPremiumOffer = isProductPremiumOfferHelper(product);
  const hasPeriodsToSelectFrom = isPurchaseSourceSupported && arePlansLoaded && planPrices.length > 1;
  const hasNoPeriodsToSelectFrom = isPurchaseSourceSupported && arePlansLoaded && planPrices.length <= 1;
  const isSelectedPlanSameAsCurrentOrNext =
    (!nextPlanDuration && selectedDuration === planDuration) || selectedDuration === nextPlanDuration;
  const isLoadingPlans = fetchAvailableOffersAndPlansStatus === Status.LOADING;
  const isButtonLoading = changeDurationStatus === Status.LOADING;
  const isButtonDisabled =
    hasNoPeriodsToSelectFrom || isSelectedPlanSameAsCurrentOrNext || isLoadingPlans || isButtonLoading;
  const isApplyToPremiumOfferCheckboxVisible = !isProductPremiumOffer && isOfferAutoRenewable({ offer: userOffer });
  const isApplyToAllNumbersCheckboxVisible =
    (isProductPremiumOffer && categoryStatistics.length) || categoryStatistics.length > 1;

  const fetchData = () => {
    if (arePlansLoaded || !isOpen) {
      return;
    }

    dispatch(plansAndOffersFetchAvailableOffersAndPlans());
  };

  const resetStatesOnModalReopen = () => {
    if (isOpen) {
      setSelectedDuration(nextPlanDuration ?? planDuration);
      setAdditionallyApplyToPremiumOffer(false);
      setAdditionallyApplyToAllNumbers(false);

      dispatch(plansAndOffersSetSubscriptionIdForRenewalPeriodChange(''));
    }
  };

  useEffect(fetchData, [arePlansLoaded, dispatch, isOpen]);
  useEffect(resetStatesOnModalReopen, [dispatch, isOpen, nextPlanDuration, planDuration]);

  const onDurationSelectHandler = (duration: number) => {
    setSelectedDuration(duration);
  };

  const onApplyToPremiumOfferChangeHandler = (checked: boolean) => {
    setAdditionallyApplyToPremiumOffer(checked);
  };

  const onApplyToAllNumbersChangeHandler = (checked: boolean) => {
    setAdditionallyApplyToAllNumbers(checked);
  };

  const onCancelClickHandler = () => {
    onClose();
  };

  const onUpdateClickHandler = async () => {
    const selectedPlan = availablePlans?.find((plan) => plan.duration === selectedDuration);

    if (!planDuration || !selectedDuration || !selectedPlan || !subscriptionId) {
      return;
    }

    await dispatch(
      plansAndOffersChangeDuration({
        subscriptionId,
        productId: selectedPlan.productId,
        currentPlanDuration: planDuration,
        nextPlanDuration: selectedDuration,
        additionallyApplyToPremiumOffer,
        additionallyApplyToAllNumbers,
      }),
    );

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopupVertical size="large">
        <PopupVertical.Header className={styles.header}>
          <PopupVertical.Heading>
            {isProductPremiumOffer ? (
              <FormattedMessage id="Modals.RenewalPeriod.title_with_offer" />
            ) : (
              <FormattedMessage
                id="Modals.RenewalPeriod.title_with_number"
                values={{ number: formatNumber(product.virtualPhoneNumber.number) }}
              />
            )}
          </PopupVertical.Heading>
        </PopupVertical.Header>
        <PopupVertical.Body>
          {hasPeriodsToSelectFrom && (
            <>
              <div className={styles.info}>
                <FormattedMessage id="Modals.RenewalPeriod.info" />
              </div>

              <div role="radiogroup">
                {[...planPrices].reverse().map((plan) => (
                  <SelectionItem
                    key={plan.price}
                    plan={plan}
                    isSelected={plan.period === selectedDuration}
                    onSelect={onDurationSelectHandler}
                  />
                ))}
              </div>

              <div className={styles.checkboxes}>
                {isApplyToPremiumOfferCheckboxVisible && (
                  <InputCheckbox
                    className={styles.checkbox}
                    onChange={onApplyToPremiumOfferChangeHandler}
                    checked={additionallyApplyToPremiumOffer}
                  >
                    <FormattedMessage id="Modals.RenewalPeriod.checkbox_apply_to_premium" />
                  </InputCheckbox>
                )}
                {isApplyToAllNumbersCheckboxVisible && (
                  <InputCheckbox
                    className={styles.checkbox}
                    onChange={onApplyToAllNumbersChangeHandler}
                    checked={additionallyApplyToAllNumbers}
                  >
                    <FormattedMessage id="Modals.RenewalPeriod.checkbox_apply_to_all_numbers" />
                  </InputCheckbox>
                )}
              </div>
            </>
          )}

          {isLoadingPlans && <Loading />}

          {hasNoPeriodsToSelectFrom && (
            <div className={styles.nothingToSelect}>
              <FormattedMessage id="Modals.RenewalPeriod.nothing_to_select" />
            </div>
          )}

          {!isPurchaseSourceSupported && (
            <div className={styles.nothingToSelect}>
              <FormattedMessage id="Modals.RenewalPeriod.unsupported_purchase_source" />
            </div>
          )}
        </PopupVertical.Body>
        <PopupVertical.Footer>
          <Button
            variant="ghost"
            size="large"
            colorScheme="black"
            onClick={onCancelClickHandler}
          >
            <FormattedMessage id="Modals.RenewalPeriod.button_cancel" />
          </Button>
          <Button
            size="large"
            loading={isButtonLoading}
            disabled={isButtonDisabled}
            onClick={onUpdateClickHandler}
          >
            <FormattedMessage id="Modals.RenewalPeriod.button_update" />
          </Button>
        </PopupVertical.Footer>
      </PopupVertical>
    </Modal>
  );
};
