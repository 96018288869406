import React from 'react';

import { IconButton, IconCommonNames } from 'components/Icon';

import styles from './ModalsDialpadSimpleCallButton.module.scss';

interface ModalsDialpadSimpleCallButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const ModalsDialpadSimpleCallButton: React.FC<ModalsDialpadSimpleCallButtonProps> = ({
  isLoading,
  isDisabled,
  onClick,
}) => (
  <IconButton
    type="submit"
    iconName={isLoading ? IconCommonNames.SPINNER : IconCommonNames.CALL}
    className={styles.root}
    isDisabled={isLoading || isDisabled}
    onClick={onClick}
  ></IconButton>
);

export default ModalsDialpadSimpleCallButton;
