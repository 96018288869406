import { showErrorToast } from '@onoff/toast-notification';

import { Status, Message, ThreadUpdate, SendMessageRequest, MessageContentData } from 'types';

import { isString } from 'helpers';

import { MESSAGES } from '@constants';
import { getIntl } from '@intl';

import {
  selectMessages,
  selectMessagesSendLastMessageSent,
  selectMessagesSendMessageErrorList,
  selectThreadsState,
} from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  MessagesAppendAction,
  MessagesAddThreadToMessageAction,
  MessagesMarkAllAsReadAction,
  MessagesOffsetAction,
  MessagesSetThreadAction,
  MessagesSetStatusThreadMessageUpdateAction,
  MessagesPrependAction,
  MessagesRemoveAction,
  MessagesRemoveThreadFromMessageAction,
  MessagesSetStatusDeleteThreadMessagesAction,
  MessagesSendMessageAddErrorsAction,
  MessagesSendMessageClearErrorListAction,
  MessagesSendMessageSetLastMessageSentAction,
  MessagesSetStatusSendMessageUpdateAction,
  MessagesSetStatusPubNubMessageAddAction,
  MessagesSendMessageHandlerProps,
  MessagesSendTextMessageHandlerProps,
  MessagesSendFileMessageHandlerProps,
  MessagesPubNubNewMessageHandlerProps,
  MessagesPubNubRemoveMessageHandlerProps,
} from '../../types';
import { notificationsBrowserSetMessageHandler } from '../notificationsBrowser';
import {
  threadsCreateNewThreadHandler,
  threadsSetActiveThreadHandler,
  threadsRemove,
  threadsUpdate,
  threadsMarkAsReadHandler,
} from '../threads';

import {
  getMessageValidationErrorsForSending,
  getThreadIdOptions,
  getUniqueSetPhoneNumbers,
  isValidMessageType,
} from './helpers';

export const messagesAppend = (messages: Message[], threadId: string): MessagesAppendAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_APPEND,
  messages,
  threadId,
});

export const messagesAddThread = (message: Message): MessagesAddThreadToMessageAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_ADD_THREAD,
  message,
});

export const messagesMarkAllAsRead = (threadId: string): MessagesMarkAllAsReadAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_MARK_ALL_AS_READ,
  threadId,
});

export const messagesOffset = (threadId: string, messageId: string | true): MessagesOffsetAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_OFFSET,
  threadId,
  messageId,
});

export const messagesPrepend = (messages: Message[], threadId: string): MessagesPrependAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_PREPEND,
  messages,
  threadId,
});

export const messagesRemove = (messageIdList: string[], threadId: string): MessagesRemoveAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_REMOVE,
  messageIdList,
  threadId,
});

export const messagesRemoveThread = (threadIdList: string[]): MessagesRemoveThreadFromMessageAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_REMOVE_THREAD,
  threadIdList,
});

export const messagesSetStatusDeleteThreadMessages = (status: Status): MessagesSetStatusDeleteThreadMessagesAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_SET_STATUS_DELETE_THREAD,
  status,
});

export const messagesSetThread = (messages: Message[], threadId: string): MessagesSetThreadAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_SET_THREAD,
  messages,
  threadId,
});

export const messagesSetStatusThreadMessageUpdate = (status: Status): MessagesSetStatusThreadMessageUpdateAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_SET_STATUS_THREAD_MESSAGE_UPDATE,
  status,
});

export const messagesSendMessageAddErrors = (errorList: string[]): MessagesSendMessageAddErrorsAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_SEND_MESSAGE_ADD_ERRORS,
  errorList,
});

export const messagesSendMessageClearErrorList = (): MessagesSendMessageClearErrorListAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_SEND_MESSAGE_CLEAR_ERROR_LIST,
});

export const messagesSendMessageSetLastMessageSent = (
  lastMessageSent: Message,
): MessagesSendMessageSetLastMessageSentAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_SEND_MESSAGE_SET_LAST_MESSAGE_SENT,
  lastMessageSent,
});

export const messagesSetStatusSendMessageUpdate = (status: Status): MessagesSetStatusSendMessageUpdateAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_SET_STATUS_SEND_MESSAGE_UPDATE,
  status,
});

export const messagesSetStatusPubNubMessageAdd = (status: Status): MessagesSetStatusPubNubMessageAddAction => ({
  type: REDUX_ACTION_TYPES.MESSAGES_SET_STATUS_PUBNUB_MESSAGE_ADD,
  status,
});

const messagesThreadMessageUpdateHandler =
  (threadId: string): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services): Promise<void> => {
    try {
      dispatch(messagesSetStatusThreadMessageUpdate(Status.LOADING));
      const messageList = await services.messagesService.fetchMessages(threadId);

      dispatch(messagesSetThread(messageList, threadId));
      const offsetMessageId =
        messageList.length === MESSAGES.FETCH_MESSAGE_ELEMENT_LIMIT ? messageList[messageList.length - 1].id : true;
      dispatch(messagesOffset(threadId, offsetMessageId));
      dispatch(messagesSetStatusThreadMessageUpdate(Status.SUCCEEDED));
    } catch {
      dispatch(messagesSetStatusThreadMessageUpdate(Status.FAILED));
    }
  };

export const messagesThreadMessageReceivedHandler =
  (threadId: string): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    dispatch(messagesThreadMessageUpdateHandler(threadId));
  };

export const messagesLoadInitialThreadMessageListHandler =
  (threadId: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const currentOffset = getState().messages.messageOffsets[threadId];

    if (currentOffset === true) {
      return;
    }

    dispatch(messagesThreadMessageUpdateHandler(threadId));
  };

export const messagesLoadOffsetThreadMessageListHandler =
  (
    threadId: string,
    messageOffsetId: string | true = true,
    limit = MESSAGES.FETCH_MESSAGE_ELEMENT_LIMIT,
  ): ThunkResult<Promise<void>> =>
  async (dispatch, _getState, services) => {
    if (messageOffsetId === true) {
      return;
    }

    try {
      dispatch(messagesSetStatusThreadMessageUpdate(Status.LOADING));
      const messageOffsetList = await services.messagesService.fetchMessages(threadId, messageOffsetId, limit);
      const newMessageOffsetId =
        messageOffsetList.length === limit ? messageOffsetList[messageOffsetList.length - 1].id : true;

      dispatch(messagesOffset(threadId, newMessageOffsetId));
      dispatch(messagesPrepend(messageOffsetList, threadId));
      dispatch(messagesSetStatusThreadMessageUpdate(Status.SUCCEEDED));
    } catch {
      dispatch(messagesSetStatusThreadMessageUpdate(Status.FAILED));
    }
  };

export const messagesRemoveHandler =
  (messageIdList: string[], threadId: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services) => {
    await services.messagesService.deleteMessages(messageIdList);

    dispatch(messagesRemove(messageIdList, threadId));

    const threadMessageList = selectMessages(getState())[threadId]?.filter(
      (message) => !messageIdList.includes(message.id),
    );

    if (threadMessageList?.length > 0) {
      const { body, createdAt, type } = threadMessageList[0];
      dispatch(
        threadsUpdate([
          {
            threadId,
            body,
            createdAt,
            type,
          },
        ]),
      );
    } else {
      dispatch(threadsRemove([threadId]));
      dispatch(messagesSetStatusDeleteThreadMessages(Status.SUCCEEDED));
    }
  };

const messagesSendTextMessageHandler =
  ({
    body,
    sourceCategory,
    targetPhoneNumber,
    targetPhoneNumbers,
    group,
    threadId,
  }: MessagesSendTextMessageHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    const serverMessage: SendMessageRequest = {
      content: body,
      messageType: 'TEXT',
      threadId: isString(threadId) ? threadId : '',
    };

    const validationErrors = getMessageValidationErrorsForSending(serverMessage);
    if (validationErrors.length > 0) {
      dispatch(messagesSendMessageAddErrors(validationErrors));
      throw new Error('DEV: Send Text Message Validation Error');
    }

    const { createdAt = '', id = '' } = await services.messagesService.sendMessage(serverMessage);

    const message: Message = {
      body,
      createdAt,
      creatorId: '',
      id,
      incoming: false,
      mms: false,
      sms: true,
      sourceCategoryId: sourceCategory.id,
      sourcePhoneNumber: sourceCategory.virtualPhoneNumber.number,
      targetPhoneNumber,
      targetPhoneNumbers,
      threadId,
      type: serverMessage.messageType,
      group,
    };

    dispatch(messagesSendMessageSetLastMessageSent(message));
  };

const messagesSendFileMessageHandler =
  ({
    file,
    sourceCategory,
    targetPhoneNumber,
    targetPhoneNumbers,
    group,
    threadId,
  }: MessagesSendFileMessageHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    const messageType = file.type.split('/')[0]?.toUpperCase();
    const contentType = file.type;

    if (!isValidMessageType(messageType)) {
      throw new Error('incorrect file type');
    }

    // Prepare URL for upload on Amazon
    const messageData: MessageContentData = {
      contentType,
      messageType,
      size: file.size,
    };

    const { preSignedUrl = '', generatedId = '' } = await services.messagesService.generateMessageContentUrl(
      messageData,
    );

    // Upload audio/image file to pre signed URL
    await services.messagesService.uploadFileToPreSignedUrl(preSignedUrl, file, contentType);

    const serverMessage: SendMessageRequest = {
      content: generatedId,
      contentType,
      mediaLength: file.size,
      messageType,
      originalFilename: file.name,
      threadId,
    };

    const validationErrors = getMessageValidationErrorsForSending(serverMessage);
    if (validationErrors.length > 0) {
      dispatch(messagesSendMessageAddErrors(validationErrors));
      throw new Error('DEV: Send File Message Validation Error');
    }

    const {
      createdAt = '',
      contentUrl = '',
      id = '',
      videoThumbnail,
    } = await services.messagesService.sendMessage(serverMessage);

    const message: Message = {
      body: contentUrl,
      createdAt,
      id,
      incoming: false,
      sourceCategoryId: sourceCategory.id,
      targetPhoneNumber,
      targetPhoneNumbers,
      group,
      threadId,
      type: messageType,
      videoThumbnail,
      creatorId: '',
      sourcePhoneNumber: sourceCategory.virtualPhoneNumber.number,
      mms: true,
      sms: false,
    };

    dispatch(messagesSendMessageSetLastMessageSent(message));
  };

export const messagesSendMessageHandler =
  (options: MessagesSendMessageHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    try {
      dispatch(messagesSetStatusSendMessageUpdate(Status.LOADING));

      const { value, sourceCategory, targetPhoneNumbers = [] } = options;
      const uniqueTargetPhoneNumbers = getUniqueSetPhoneNumbers(targetPhoneNumbers);
      const targetPhoneNumber = uniqueTargetPhoneNumbers[0];

      const { threadId, group } = await getThreadIdOptions({
        threadId: options.threadId,
        group: options.group,
        sourceCategoryId: options.sourceCategory.id,
        targetPhoneNumbers: uniqueTargetPhoneNumbers,
      });

      if (isString(value)) {
        await dispatch(
          messagesSendTextMessageHandler({
            body: value,
            sourceCategory,
            targetPhoneNumber,
            targetPhoneNumbers: uniqueTargetPhoneNumbers,
            threadId,
            group,
          }),
        );
      } else {
        await dispatch(
          messagesSendFileMessageHandler({
            file: value,
            sourceCategory,
            targetPhoneNumber,
            targetPhoneNumbers: uniqueTargetPhoneNumbers,
            threadId,
            group,
          }),
        );
      }

      const lastMessageSent = selectMessagesSendLastMessageSent(getState());
      if (!lastMessageSent) {
        throw new Error('something went wrong during saving sent message to redux store');
      }

      const isNewThread = !getState().threads.data.find((thread) => thread.threadId === threadId);
      if (isNewThread) {
        await dispatch(threadsCreateNewThreadHandler(lastMessageSent));
      } else {
        dispatch(messagesAppend([lastMessageSent], threadId));
        const { body, createdAt, type } = lastMessageSent;
        dispatch(
          threadsUpdate([
            {
              body,
              createdAt,
              threadId,
              type,
            },
          ]),
        );
      }

      dispatch(messagesSetStatusSendMessageUpdate(Status.SUCCEEDED));
      dispatch(threadsSetActiveThreadHandler(threadId));
    } catch (error) {
      const errorList = selectMessagesSendMessageErrorList(getState());

      dispatch(messagesSetStatusSendMessageUpdate(Status.FAILED));

      if (errorList.length > 0) {
        errorList.forEach((errorListItem) => {
          showErrorToast({
            heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_error' }),
            message: errorListItem,
          });
        });
        dispatch(messagesSendMessageClearErrorList());
      } else {
        throw error;
      }
    }
  };

export const messagesPubNubNewMessageHandler =
  ({ message }: MessagesPubNubNewMessageHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const {
      incoming: isMessageIncoming,
      silent: isSilentMessage,
      threadId: messageThreadId,
      body: messageBody,
      type: messageType,
      createdAt: messageCreatedAt,
      sourcePhoneNumber: messageSourcePhoneNumber,
    } = message;

    // we don't update messages for silent flag (multi sms (recipient) functionality)
    if (isSilentMessage) {
      return;
    }

    // browser notification
    if (isMessageIncoming) {
      dispatch(
        notificationsBrowserSetMessageHandler({
          sourcePhoneNumber: messageSourcePhoneNumber,
          body: messageBody,
        }),
      );
    }

    const { data: threadsData, activeThreadId } = selectThreadsState(getState());
    const relatedThread = threadsData.find(({ threadId }) => threadId === messageThreadId);

    // new thread + new message
    if (relatedThread === undefined) {
      dispatch(threadsCreateNewThreadHandler(message));
    }
    // existing thread + new message
    else {
      const isRelatedMessageThreadActive = activeThreadId === messageThreadId;
      const { unreadMessages: relatedThreadUnreadMessagesCount = 0 } = relatedThread;

      const updatedThreadUnreadMessage: Partial<ThreadUpdate> =
        isMessageIncoming && !isRelatedMessageThreadActive
          ? { unreadMessages: relatedThreadUnreadMessagesCount + 1 }
          : {};

      const updatedThread: ThreadUpdate = {
        threadId: messageThreadId,
        body: messageBody,
        type: messageType,
        createdAt: messageCreatedAt,
        ...updatedThreadUnreadMessage,
      };

      dispatch(threadsUpdate([updatedThread]));

      dispatch(messagesAppend([message], messageThreadId));
      dispatch(messagesSetStatusPubNubMessageAdd(Status.SUCCEEDED));

      if (isRelatedMessageThreadActive) {
        /**
         * TODO: Technical Debt
         * We should not await a thunk/dispatch
         * "threadsMarkAsReadHandler" logic should be refactored
         */
        await dispatch(threadsMarkAsReadHandler([messageThreadId]));
      }
    }
  };

export const messagesPubNubRemoveMessageHandler =
  ({ messageIds }: MessagesPubNubRemoveMessageHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const messages = selectMessages(getState());

    const relatedThreadId = Object.keys(messages).find((messagesThreadId) =>
      (messages[messagesThreadId] || []).find((message) => messageIds.includes(message.id)),
    );

    if (relatedThreadId !== undefined) {
      // Remove the Message
      dispatch(messagesRemove(messageIds, relatedThreadId));

      // The possible last Message after the removal for the related Thread
      const relatedThread: Message[] = messages[relatedThreadId] || [];
      const relatedThreadLastMessage: Message | undefined = relatedThread
        .filter((message) => !messageIds.includes(message.id))
        .find((message) => message);

      // At least one Message is exist for the Thread, Update the Thread(List Item)
      if (relatedThreadLastMessage !== undefined) {
        const { body, type, createdAt } = relatedThreadLastMessage;
        dispatch(
          threadsUpdate([
            {
              threadId: relatedThreadId,
              body,
              type,
              createdAt,
            },
          ]),
        );
      }
      // Removed Message was the last Message, Remove the Thread
      else {
        dispatch(threadsRemove([relatedThreadId]));
        dispatch(messagesSetStatusDeleteThreadMessages(Status.SUCCEEDED));
      }
    }
  };
