import { useState } from 'react';

interface CalculateRow {
  rows: number;
  calculateRows: (target: HTMLTextAreaElement, hasValue: boolean) => void;
}

const getStyleNumberValue = (computedStyle: CSSStyleDeclaration, property: keyof CSSStyleDeclaration): number => {
  const styleNumberValue = computedStyle[property];
  if (typeof styleNumberValue !== 'string') {
    throw new Error('styleNumberValue should be string');
  }
  return parseInt(styleNumberValue, 10) || 0;
};

const getMinMaxRow = (current: number, max: number, min: number): number => {
  const minValue = Math.max(min, current) || 0;

  if (max === 0) {
    return minValue;
  }

  return Math.min(max, minValue) || 0;
};

export const useCalculateRows = (rowsMax = 0, rowsMin = 1): CalculateRow => {
  const [rows, setRows] = useState<number>(rowsMin);

  return {
    rows,
    calculateRows: (target: HTMLTextAreaElement, hasValue: boolean): void => {
      const computedStyle = window.getComputedStyle(target);
      const padding =
        getStyleNumberValue(computedStyle, 'paddingBottom') + getStyleNumberValue(computedStyle, 'paddingTop');
      const lineHeight = getStyleNumberValue(computedStyle, 'lineHeight');

      const rowsNew = hasValue ? Math.floor((target.scrollHeight - padding) / lineHeight) : 1;

      if (rowsMax || rowsMin) {
        setRows(getMinMaxRow(rowsNew, rowsMax, rowsMin));
        return;
      }

      setRows(rowsNew);
    },
  };
};
