import { createSelector } from '@reduxjs/toolkit';

import { SelectPurchaseFlowState } from '../../types';

export const selectPurchaseFlowState: SelectPurchaseFlowState = (state) => state.purchaseFlow;

export const selectPurchaseFlowSelectedPaymentMethod = createSelector(
  [selectPurchaseFlowState],
  (purchaseFlowState) => purchaseFlowState.selectedPaymentMethod,
);

export const selectPurchaseFlowInitPaymentMethodStatus = createSelector(
  [selectPurchaseFlowState],
  (purchaseFlowState) => purchaseFlowState.statuses.initialisePaymentMethod,
);
