import React from 'react';
import { Route } from 'react-router-dom';

import { ToastNotificationContainer } from '@onoff/toast-notification';
import { Loading } from '@onoff/ui';

import { Status } from 'types';

import { selectCallQualitySurveyIsPopupOpen, useWebAppSelector } from '@redux';
import { ModalsCallQualitySurvey, ModalsDialpad, ModalsMultiDeviceLogout } from 'components/Modals';
import { NotificationsBrowser, NotificationsModal } from 'components/Notifications';
import { usePubNub } from 'hooks';
import { ErrorServerErrorPage } from 'pages/error';
import { applicationRouteList, RoutesSentry } from 'routes';
import { SIP } from 'sip';

import { ModalsEmergencyCallsWarning } from '../Modals/ModalsEmergencyCallsWarning';

import {
  useAppAnalytics,
  useAppErrorHandlers,
  useAppMultiDevice,
  useAppTitle,
  useAppUserInit,
  useWindowStorageListener,
  usePrefilledDialpadModal,
  useBrowserMicrophonePermissions,
  useCheckIfCanPlayAudio,
  useEmergencyCallsWarningModal,
} from './hooks';

const App: React.FC = () => {
  const isCallQualitySurveyPopupOpen = useWebAppSelector(selectCallQualitySurveyIsPopupOpen);

  usePubNub();

  useAppTitle();

  useBrowserMicrophonePermissions();

  useCheckIfCanPlayAudio();

  useAppErrorHandlers();

  useAppAnalytics();

  const { fetchNecessaryDataStatus, isAuthenticated } = useAppUserInit();

  useWindowStorageListener();

  const { showModalMultiDeviceLogout } = useAppMultiDevice();

  const { isModalDialpadOpen, prefilledPhoneNumber, onModalDialpadRequestClose } = usePrefilledDialpadModal();

  const { isEmergencyCallsWarningModalOpen, onEmergencyCallsWarningModalClose } = useEmergencyCallsWarningModal();

  // const { isCallTransferAnnouncementModalOpen, onCallTransferAnnouncementModalClose } =
  //   useCallTransferAnnouncementModal();

  if (fetchNecessaryDataStatus === Status.FAILED) {
    return <ErrorServerErrorPage />;
  }

  if (isAuthenticated && fetchNecessaryDataStatus !== Status.SUCCEEDED) {
    return <Loading />;
  }

  return (
    <>
      <ModalsDialpad
        isOpen={isModalDialpadOpen && !showModalMultiDeviceLogout}
        onRequestClose={onModalDialpadRequestClose}
        prefilledPhoneNumberRemote={prefilledPhoneNumber}
      />
      <ModalsMultiDeviceLogout isOpen={showModalMultiDeviceLogout} />
      <ModalsCallQualitySurvey isOpen={isCallQualitySurveyPopupOpen} />
      <ModalsEmergencyCallsWarning
        isOpen={isEmergencyCallsWarningModalOpen}
        onClose={onEmergencyCallsWarningModalClose}
      />
      {/* <ModalsCallTransferAnnouncement */}
      {/*   isOpen={isCallTransferAnnouncementModalOpen} */}
      {/*   onClose={onCallTransferAnnouncementModalClose} */}
      {/* /> */}
      <RoutesSentry>
        {applicationRouteList.map(({ children, path }, i) => (
          <Route
            key={`app-${i}`}
            path={path}
            element={children}
          />
        ))}
      </RoutesSentry>
      <SIP />
      <NotificationsBrowser />
      <NotificationsModal />
      <ToastNotificationContainer />
    </>
  );
};

export default App;
