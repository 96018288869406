export enum PaymentMethod {
  CREDIT_CARD = 'credit-card',
  GOOGLE_PAY = 'google-pay',
  APPLE_PAY = 'apple-pay',
}

export type SelectedPaymentMethod =
  | {
      type: PaymentMethod.CREDIT_CARD;
      id?: string;
    }
  | {
      type: PaymentMethod.GOOGLE_PAY;
    }
  | {
      type: PaymentMethod.APPLE_PAY;
    };
