import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast, showSuccessToast } from '@onoff/toast-notification';

import { Status } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  categoriesCancelSubscriptionHandler,
  categoriesSetStatusCancelSubscription,
  selectCategoriesCancelSubscriptionStatus,
} from '@redux';

import { ModalsConfirmation, ModalsConfirmationType } from '../../../ModalsConfirmation';

export interface ModalsConfirmationUnsubscribeNumberProps {
  categoryId: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalsConfirmationUnsubscribeCategory: React.FC<ModalsConfirmationUnsubscribeNumberProps> = ({
  categoryId,
  isOpen,
  onRequestClose,
}) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const cancelSubscriptionStatus = useWebAppSelector(selectCategoriesCancelSubscriptionStatus);

  const cancelSubscriptionHandler = () => {
    dispatch(categoriesCancelSubscriptionHandler(categoryId));
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (cancelSubscriptionStatus === Status.SUCCEEDED) {
      onRequestClose();

      showSuccessToast({
        heading: intl.formatMessage({ id: 'Categories.Notifications.unsubscribe_success_title' }),
        message: intl.formatMessage({ id: 'Categories.Notifications.unsubscribe_success_description' }),
      });

      dispatch(categoriesSetStatusCancelSubscription(Status.IDLE));
    }

    if (cancelSubscriptionStatus === Status.FAILED) {
      showErrorToast({
        heading: intl.formatMessage({ id: 'Categories.Notifications.unsubscribe_error_title' }),
        message: intl.formatMessage({ id: 'Categories.Notifications.unsubscribe_error_description' }),
      });

      dispatch(categoriesSetStatusCancelSubscription(Status.IDLE));
    }
  }, [isOpen, onRequestClose, cancelSubscriptionStatus, dispatch, intl]);

  return (
    <ModalsConfirmation
      isOpen={isOpen}
      type={ModalsConfirmationType.CANCEL_SUBSCRIPTION}
      content={intl.formatMessage({ id: 'Categories.unsubscribe_popup_description' })}
      title={intl.formatMessage({ id: 'Categories.unsubscribe_popup_title' })}
      confirmButtonText={intl.formatMessage({ id: 'Categories.unsubscribe_popup_confirm' })}
      cancelButtonText={intl.formatMessage({ id: 'Categories.unsubscribe_popup_cancel' })}
      onConfirm={cancelSubscriptionHandler}
      onCancel={onRequestClose}
      isLoading={cancelSubscriptionStatus === Status.LOADING}
    />
  );
};

export default ModalsConfirmationUnsubscribeCategory;
