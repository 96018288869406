import { useMemo } from 'react';

import { AccountType } from 'types';

import { isB2BProject } from 'helpers';

import { useWebAppSelector, selectUserData, selectHasCategories } from '@redux';

interface UseUserCapabilitiesReturn {
  canUserSendSMS: boolean;
  canUserReceiveSMS: boolean;
  canUserMakeCalls: boolean;
  canUserReceiveCalls: boolean;
  hasUserMultiDeviceSupport: boolean;
  isUserStarter: boolean;
  isUserPremium: boolean;
  isUserB2B: boolean;
  isUserB2C: boolean;
}

export const useUserCapabilities = (): UseUserCapabilitiesReturn => {
  const { userId = '', b2bUser = false, accountType } = useWebAppSelector(selectUserData);
  const hasCategories = useWebAppSelector(selectHasCategories);

  const isUserB2B = userId.length > 0 ? b2bUser === true : isB2BProject();
  const isUserB2C = !isUserB2B;
  const isUserStarter = accountType === AccountType.STARTER;
  const isUserPremium = accountType === AccountType.PREMIUM;

  const canUserSendSMS = hasCategories && (isUserB2B || isUserPremium);
  const canUserReceiveSMS = hasCategories && (isUserB2B || isUserPremium);
  const canUserMakeCalls = hasCategories && (isUserB2B || isUserPremium);
  const canUserReceiveCalls = hasCategories && (isUserB2B || isUserPremium);
  const hasUserMultiDeviceSupport = isUserB2B || isUserPremium;

  return useMemo(
    () => ({
      canUserSendSMS,
      canUserReceiveSMS,
      canUserMakeCalls,
      canUserReceiveCalls,
      hasUserMultiDeviceSupport,
      isUserStarter,
      isUserPremium,
      isUserB2B,
      isUserB2C,
    }),
    [
      canUserSendSMS,
      canUserReceiveSMS,
      canUserMakeCalls,
      canUserReceiveCalls,
      hasUserMultiDeviceSupport,
      isUserStarter,
      isUserPremium,
      isUserB2B,
      isUserB2C,
    ],
  );
};
