export enum PhoneKeyboardSize {
  /**
   * s => 161px width
   */
  S = 's',
  /**
   * m => 226px width
   */
  M = 'm',
}
