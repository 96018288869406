import React, { HTMLAttributes } from 'react';

import styles from './RatingStar.module.scss';

type RatingStarProps = { isFilled: boolean } & HTMLAttributes<HTMLButtonElement>;

export const RatingStar: React.FC<RatingStarProps> = ({ isFilled, ...props }) => (
  <button
    className={styles.root}
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
    >
      {isFilled ? (
        <path
          d="M26 37.4181L34.9917 42.8565C36.6384 43.8531 38.6534 42.3798 38.22 40.5165L35.8367 30.2898L43.7884 23.3998C45.24 22.1431 44.46 19.7598 42.5534 19.6081L32.0884 18.7198L27.9934 9.05648C27.2567 7.30148 24.7434 7.30148 24.0067 9.05648L19.9117 18.6981L9.4467 19.5865C7.54004 19.7381 6.76004 22.1215 8.2117 23.3781L16.1634 30.2681L13.78 40.4948C13.3467 42.3581 15.3617 43.8315 17.0084 42.8348L26 37.4181Z"
          fill="#F19F19"
        />
      ) : (
        <path
          d="M42.575 19.5867L32.0884 18.6767L27.9934 9.035C27.2567 7.28 24.7434 7.28 24.0067 9.035L19.9117 18.6983L9.4467 19.5867C7.54004 19.7383 6.76004 22.1217 8.2117 23.3783L16.1634 30.2683L13.78 40.495C13.3467 42.3583 15.3617 43.8317 17.0084 42.835L26 37.4183L34.9917 42.8567C36.6384 43.8533 38.6534 42.38 38.22 40.5167L35.8367 30.2683L43.7884 23.3783C45.24 22.1217 44.4817 19.7383 42.575 19.5867ZM26 33.3667L17.8534 38.285L20.02 29.0117L12.8267 22.7717L22.3167 21.9483L26 13.2167L29.705 21.97L39.195 22.7933L32.0017 29.0333L34.1684 38.3067L26 33.3667Z"
          fill="#BEC1C8"
        />
      )}
    </svg>
  </button>
);

export default RatingStar;
