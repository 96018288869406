export const REDUX_ACTION_TYPES = {
  // AUTHENTICATION
  AUTHENTICATION_SET_STATUS_CAPTCHA_CHECK: 'AUTHENTICATION_SET_STATUS_CAPTCHA_CHECK',
  AUTHENTICATION_SET_STATUS_CAPTCHA_VERIFY_AND_REGISTER: 'AUTHENTICATION_SET_STATUS_CAPTCHA_VERIFY_AND_REGISTER',
  AUTHENTICATION_SET_STATUS_RENEW_AUTH_KEY: 'AUTHENTICATION_SET_STATUS_RENEW_AUTH_KEY',
  AUTHENTICATION_SET_STATUS_LOGIN: 'AUTHENTICATION_SET_STATUS_LOGIN',
  AUTHENTICATION_SET_STATUS_LOGOUT: 'AUTHENTICATION_SET_STATUS_LOGOUT',
  AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_REQUEST: 'AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_REQUEST',
  AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_VERIFY: 'AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_VERIFY',
  AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_CHANGE: 'AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_CHANGE',
  AUTHENTICATION_SET_CAPTCHA_REQUIRE: 'AUTHENTICATION_SET_CAPTCHA_REQUIRE',
  AUTHENTICATION_SET_CAPTCHA_RENDERED: 'AUTHENTICATION_SET_CAPTCHA_RENDERED',
  AUTHENTICATION_SET_LOGIN_ERROR_CODE: 'AUTHENTICATION_SET_LOGIN_ERROR_CODE',

  // USER
  USER_SET: 'USER_SET',
  USER_UPDATE: 'USER_UPDATE',
  USER_SET_ALLOWED_COUNTRIES: 'USER_SET_ALLOWED_COUNTRIES',
  USER_SET_AVAILABLE_COUNTRIES: 'USER_SET_AVAILABLE_COUNTRIES',
  USER_SET_LANGUAGE: 'USER_SET_LANGUAGE',
  USER_SET_ACCOUNT_TYPE_AND_FEATURES: 'USER_SET_ACCOUNT_TYPE_AND_FEATURES',
  USER_RESET_STORE: 'USER_RESET_STORE',
  USER_SET_UPDATE_LANGUAGE_STATUS: 'USER_SET_UPDATE_LANGUAGE_STATUS',
  USER_SET_UPDATE_NAME_STATUS: 'USER_SET_UPDATE_NAME_STATUS',
  USER_SET_UPDATE_EMAIL_STATUS: 'USER_SET_UPDATE_EMAIL_STATUS',
  USER_SET_UPDATE_PASSWORD_STATUS: 'USER_SET_UPDATE_PASSWORD_STATUS',

  // USER GEO INFO
  USER_GEO_INFO_SET_STATUS_FETCH_USER_GEO_INFO: 'USER_GEO_INFO_SET_STATUS_FETCH_USER_GEO_INFO',
  USER_GEO_INFO_SET_GEO_INFO_DATA: 'USER_GEO_INFO_SET_GEO_INFO_DATA',

  // BALANCE/CREDITS
  SET_BALANCE: 'SET_BALANCE',

  // CATEGORIES
  ADD_CATEGORY: 'ADD_CATEGORY',
  REMOVE_CATEGORY: 'REMOVE_CATEGORY',
  SET_ALL_CATEGORIES: 'SET_ALL_CATEGORIES',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  CATEGORY_IS_LOADING: 'CATEGORY_IS_LOADING',
  CATEGORIES_SET_STATUS_CANCEL_SUBSCRIPTION: 'CATEGORIES_SET_STATUS_CANCEL_SUBSCRIPTION',
  CATEGORIES_SET_STATUS_FETCH_STATISTICS: 'CATEGORIES_SET_STATUS_FETCH_STATISTICS',
  CATEGORIES_SET_STATISTICS_DATA: 'CATEGORIES_SET_STATISTICS_DATA',
  CATEGORIES_STATISTICS_UPDATE_NEXT_PLAN_DURATION: 'CATEGORIES_STATISTICS_UPDATE_NEXT_PLAN_DURATION',
  CATEGORIES_STATISTICS_REMOVE_NEXT_PLAN_DURATION: 'CATEGORIES_STATISTICS_REMOVE_NEXT_PLAN_DURATION',

  // PAGES => CALL LOGS
  CALL_LOGS_ADD_UNSEEN: 'CALL_LOGS_ADD_UNSEEN',
  CALL_LOGS_SET_ALL_AS_SEEN: 'CALL_LOGS_SET_ALL_AS_SEEN',
  CALL_LOGS_SET_DATA: 'CALL_LOGS_SET_DATA',
  CALL_LOGS_ADD_DATA: 'CALL_LOGS_ADD_DATA',
  CALL_LOGS_SET_ACTIVE_CALL_LOG_ID: 'CALL_LOGS_SET_ACTIVE_CALL_LOG_ID',
  CALL_LOGS_SET_SEARCH_STRING: 'CALL_LOGS_SET_SEARCH_STRING',
  CALL_LOGS_DELETE_CALL_LOGS: 'CALL_LOGS_DELETE_CALL_LOGS',
  CALL_LOGS_UPDATE_CALL_LOG: 'CALL_LOGS_UPDATE_CALL_LOG',
  CALL_LOGS_SET_STATUS_FETCH_CALL_LOGS: 'CALL_LOGS_SET_STATUS_FETCH_CALL_LOGS',
  CALL_LOGS_SET_STATUS_DELETE_CALL_LOG: 'CALL_LOGS_SET_STATUS_DELETE_CALL_LOG',
  CALL_LOGS_SET_STATUS_SAVE_CALL_NOTE: 'CALL_LOGS_SET_STATUS_SAVE_CALL_NOTE',

  // PAGES => MESSAGES/THREADS
  MESSAGES_ADD_THREAD: 'MESSAGES_ADD_THREAD',
  MESSAGES_APPEND: 'MESSAGES_APPEND',
  MESSAGES_MARK_ALL_AS_READ: 'MESSAGES_MARK_ALL_AS_READ',
  MESSAGES_OFFSET: 'MESSAGES_OFFSET',
  MESSAGES_PREPEND: 'MESSAGES_PREPEND',
  MESSAGES_REMOVE: 'MESSAGES_REMOVE',
  MESSAGES_REMOVE_THREAD: 'MESSAGES_REMOVE_THREAD',
  MESSAGES_SET_THREAD: 'MESSAGES_SET_THREAD',
  MESSAGES_SEND_MESSAGE_ADD_ERRORS: 'MESSAGES_SEND_MESSAGE_ADD_ERRORS',
  MESSAGES_SEND_MESSAGE_CLEAR_ERROR_LIST: 'MESSAGES_SEND_MESSAGE_CLEAR_ERROR_LIST',
  MESSAGES_SEND_MESSAGE_SET_LAST_MESSAGE_SENT: 'MESSAGES_SEND_MESSAGE_SET_LAST_MESSAGE_SENT',
  MESSAGES_SET_STATUS_SEND_MESSAGE_UPDATE: 'MESSAGES_SET_STATUS_SEND_MESSAGE_UPDATE',
  MESSAGES_SET_STATUS_THREAD_MESSAGE_UPDATE: 'MESSAGES_SET_STATUS_THREAD_MESSAGE_UPDATE',
  MESSAGES_SET_STATUS_DELETE_THREAD: 'MESSAGES_SET_STATUS_DELETE_THREAD',
  MESSAGES_SET_STATUS_PUBNUB_MESSAGE_ADD: 'MESSAGES_SET_STATUS_PUBNUB_MESSAGE_ADD',
  THREADS_ADD: 'THREADS_ADD',
  THREADS_REMOVE: 'THREADS_REMOVE',
  THREADS_UPDATE: 'THREADS_UPDATE',
  THREADS_SET_SEARCH_STRING: 'THREADS_SET_SEARCH_STRING',
  THREADS_SET_STATUS: 'THREADS_SET_STATUS',
  THREADS_SET_ACTIVE_THREAD_ID: 'THREADS_SET_ACTIVE_THREAD_ID',

  // PAGES => VOICEMAILS
  VOICEMAILS_PREPEND: 'VOICEMAILS_PREPEND',
  VOICEMAILS_ADD_TO_QUEUE: 'VOICEMAILS_ADD_TO_QUEUE',
  VOICEMAILS_APPEND: 'VOICEMAILS_APPEND',
  VOICEMAILS_DELETE: 'VOICEMAILS_DELETE',
  VOICEMAILS_CLEAR_SEARCH_STRING: 'VOICEMAILS_CLEAR_SEARCH_STRING',
  VOICEMAILS_MARK_AS_LISTENED: 'VOICEMAILS_MARK_AS_LISTENED',
  VOICEMAILS_RELEASE_FROM_QUEUE: 'VOICEMAILS_RELEASE_FROM_QUEUE',
  VOICEMAILS_SET_SEARCH_STRING: 'VOICEMAILS_SET_SEARCH_STRING',
  VOICEMAILS_SET_ACTIVE_VOICEMAIL_ID: 'VOICEMAILS_SET_ACTIVE_VOICEMAIL_ID',
  VOICEMAILS_SET_STATUS_FETCH_VOICEMAILS: 'VOICEMAILS_SET_STATUS_FETCH_VOICEMAILS',
  VOICEMAILS_SET_STATUS_DELETE_VOICEMAILS: 'VOICEMAILS_SET_STATUS_DELETE_VOICEMAILS',

  // PAGES => CONTACTS
  SET_SIMPLE_CONTACTS: 'SET_SIMPLE_CONTACTS',
  ADD_SIMPLE_CONTACT: 'ADD_SIMPLE_CONTACT',
  DELETE_SIMPLE_CONTACTS: 'DELETE_SIMPLE_CONTACTS',
  UPDATE_SIMPLE_CONTACT: 'UPDATE_SIMPLE_CONTACT',
  CONTACTS_SET_SEARCH_STRING: 'CONTACTS_SET_SEARCH_STRING',
  CONTACTS_CLEAR_SEARCH_STRING: 'CONTACTS_CLEAR_SEARCH_STRING',
  CONTACTS_SET_ACTIVE_ID: 'CONTACTS_SET_ACTIVE_ID',
  CONTACTS_SET_STATUS: 'CONTACTS_SET_STATUS',

  // PRIVACY => BLOCKED NUMBERS
  PRIVACY_SET_BLOCKED_NUMBERS: 'PRIVACY_SET_BLOCKED_NUMBERS',
  PRIVACY_ADD_BLOCKED_NUMBER: 'PRIVACY_ADD_BLOCKED_NUMBER',
  PRIVACY_REMOVE_BLOCKED_NUMBER: 'PRIVACY_REMOVE_BLOCKED_NUMBER',
  PRIVACY_SET_STATUS_FETCH_BLOCKED_NUMBERS: 'PRIVACY_SET_STATUS_FETCH_BLOCKED_NUMBERS',
  PRIVACY_SET_STATUS_BLOCK_NUMBER: 'PRIVACY_SET_STATUS_BLOCK_NUMBER',
  PRIVACY_SET_STATUS_UNBLOCK_NUMBER: 'PRIVACY_SET_STATUS_UNBLOCK_NUMBER',

  // CHECKOUT JS
  CHECKOUT_SET_PUBLICKEY_IS_LOADING: 'CHECKOUT_SET_PUBLICKEY_IS_LOADING',
  CHECKOUT_SET_PUBLICKEY_IS_FAILURE: 'CHECKOUT_SET_PUBLICKEY_IS_FAILURE',
  CHECKOUT_SET_PUBLICKEY_DATA: 'CHECKOUT_SET_PUBLICKEY_DATA',
  CHECKOUT_SET_REDIRECTCONFIRMATIONURL_IS_LOADING: 'CHECKOUT_SET_REDIRECTCONFIRMATIONURL_IS_LOADING',
  CHECKOUT_SET_REDIRECTCONFIRMATIONURL_IS_FAILURE: 'CHECKOUT_SET_REDIRECTCONFIRMATIONURL_IS_FAILURE',

  // SETTINGS => MULTI-DEVICE MANAGEMENT
  MULTI_DEVICE_SET_DEVICES: 'MULTI_DEVICE_SET_DEVICES',
  MULTI_DEVICE_UPDATE_DEVICE: 'MULTI_DEVICE_UPDATE_DEVICE',
  MULTI_DEVICE_SET_STATUS_FETCH_DEVICE_LIST: 'MULTI_DEVICE_SET_STATUS_FETCH_DEVICE_LIST',
  MULTI_DEVICE_SET_STATUS_LOGOUT_DEVICE: 'MULTI_DEVICE_SET_STATUS_LOGOUT_DEVICE',

  // SETTINGS => CREDIT-CARD MANAGEMENT
  CREDITCARDS_SET_STATUS: 'CREDITCARDS_SET_STATUS',
  CREDITCARDS_SET_DATA: 'CREDITCARDS_SET_DATA',

  // SETTINGS => RECEIPTS MANAGEMENT
  RECEIPTS_SET_STATUS_GET_RECEIPTS_LIST: 'RECEIPTS_SET_STATUS_GET_RECEIPTS_LIST',
  RECEIPTS_SET_DATA_RECEIPTS_LIST: 'RECEIPTS_SET_DATA_RECEIPTS_LIST',

  // NOTIFICATIONS => BROWSER
  NOTIFICATIONS_BROWSER_SET_PERMISSION: 'NOTIFICATIONS_BROWSER_SET_PERMISSION',
  NOTIFICATIONS_BROWSER_SET_DATA: 'NOTIFICATIONS_BROWSER_SET_DATA',
  NOTIFICATIONS_BROWSER_REMOVE_DATA: 'NOTIFICATIONS_BROWSER_REMOVE_DATA',

  // NOTIFICATIONS => TOASTER
  NOTIFICATIONS_TOAST_SET_DATA_AUTOREMOVABLE: 'NOTIFICATIONS_TOAST_SET_DATA_AUTOREMOVABLE',
  NOTIFICATIONS_TOAST_SET_DATA_PERMANENT: 'NOTIFICATIONS_TOAST_SET_DATA_PERMANENT',
  NOTIFICATIONS_TOAST_SET_DATA_IS_VISIBLE: 'NOTIFICATIONS_TOAST_SET_DATA_IS_VISIBLE',
  NOTIFICATIONS_TOAST_SET_DATA_IS_HIGHLIGHTED: 'NOTIFICATIONS_TOAST_SET_DATA_IS_HIGHLIGHTED',
  NOTIFICATIONS_TOAST_REMOVE_DATA: 'NOTIFICATIONS_TOAST_REMOVE_DATA',

  // NOTIFICATIONS => MODAL
  NOTIFICATIONS_MODAL_SET_DATA: 'NOTIFICATIONS_MODAL_SET_DATA',

  // PURCHASES
  PURCHASE_SET_IS_FORBIDDEN: 'PURCHASE_SET_IS_FORBIDDEN',
  PURCHASE_SET_IS_FAILURE_GENERAL: 'PURCHASE_SET_IS_FAILURE_GENERAL',
  PURCHASE_RESTART_AGAIN: 'PURCHASE_RESTART_AGAIN',
  PURCHASE_SET_DATA: 'PURCHASE_SET_DATA',
  PURCHASE_REMOVE_DATA: 'PURCHASE_REMOVE_DATA',
  PURCHASE_SET_SUMMARY_IS_LOADING: 'PURCHASE_SET_SUMMARY_IS_LOADING',
  PURCHASE_SET_SUMMARY_IS_FAILURE: 'PURCHASE_SET_SUMMARY_IS_FAILURE',
  PURCHASE_SET_SUMMARY_DATA: 'PURCHASE_SET_SUMMARY_DATA',
  PURCHASE_SET_SUBMISSION_IS_LOADING: 'PURCHASE_SET_SUBMISSION_IS_LOADING',
  PURCHASE_SET_SUBMISSION_IS_FAILURE: 'PURCHASE_SET_SUBMISSION_IS_FAILURE',
  PURCHASE_SET_SUBMISSION_IS_SUCCESS: 'PURCHASE_SET_SUBMISSION_IS_SUCCESS',
  PURCHASE_SET_SUBMISSION_ERROR_CODES: 'PURCHASE_SET_SUBMISSION_ERROR_CODES',

  // PURCHASE FLOW
  PURCHASE_FLOW_SET_SELECTED_PAYMENT_METHOD: 'PURCHASE_FLOW_SET_SELECTED_PAYMENT_METHOD',
  PURCHASE_FLOW_SET_STATUS: 'PURCHASE_FLOW_SET_STATUS',

  // PLANS AND OFFERS
  PLANSANDOFFERS_SET_STATUS_FETCH_USEROFFER: 'PLANSANDOFFERS_SET_STATUS_FETCH_USEROFFER',
  PLANSANDOFFERS_SET_STATUS_CANCEL_USEROFFER: 'PLANSANDOFFERS_SET_STATUS_CANCEL_USEROFFER',
  PLANSANDOFFERS_SET_USEROFFER: 'PLANSANDOFFERS_SET_USEROFFER',
  PLANSANDOFFERS_SET_PREMIUM_OFFER_PRICE_CONFIG: 'PLANSANDOFFERS_SET_PREMIUM_OFFER_PRICE_CONFIG',
  PLANSANDOFFERS_SET_SELECTED_PREMIUM_OFFER: 'PLANSANDOFFERS_SET_SELECTED_PREMIUM_OFFER',
  PLANSANDOFFERS_RESET_PREMIUM_OFFER_PRICE_CONFIG: 'PLANSANDOFFERS_RESET_PREMIUM_OFFER_PRICE_CONFIG',
  PLANSANDOFFERS_SET_PREMIUM_FETCH_PRICE_STATUS: 'PLANSANDOFFERS_SET_PREMIUM_FETCH_PRICE_STATUS',
  PLANSANDOFFERS_SET_PREMIUM_SUBMIT_OFFER_STATUS: 'PLANSANDOFFERS_SET_PREMIUM_SUBMIT_OFFER_STATUS',
  PLANSANDOFFERS_SET_STATUS_FETCH_AVAILABLE_OFFERS_AND_PLANS:
    'PLANSANDOFFERS_SET_STATUS_FETCH_AVAILABLE_OFFERS_AND_PLANS',
  PLANSANDOFFERS_SET_AVAILABLE_OFFERS: 'PLANSANDOFFERS_SET_AVAILABLE_OFFERS',
  PLANSANDOFFERS_ADD_PLANS: 'PLANSANDOFFERS_ADD_PLANS',
  PLANSANDOFFERS_SET_STATUS_CHANGE_DURATION: 'PLANSANDOFFERS_SET_STATUS_CHANGE_DURATION',
  PLANSANDOFFERS_SET_STATUS_CANCEL_CHANGE_DURATION: 'PLANSANDOFFERS_SET_STATUS_CANCEL_CHANGE_DURATION',
  PLANSANDOFFERS_SET_USEROFFER_NEXT_PLAN_DURATION: 'PLANSANDOFFERS_SET_USEROFFER_NEXT_PLAN_DURATION',
  PLANSANDOFFERS_REMOVE_USEROFFER_NEXT_PLAN_DURATION: 'PLANSANDOFFERS_REMOVE_USEROFFER_NEXT_PLAN_DURATION',
  PLANSANDOFFERS_SET_SUBSCRIPTION_ID_FOR_RENEWAL_PERIOD_CHANGE:
    'PLANSANDOFFERS_SET_SUBSCRIPTION_ID_FOR_RENEWAL_PERIOD_CHANGE',

  // CRM INTEGRATIONS
  INTEGRATIONS_SET_ACTIVE_INTEGRATIONS: 'INTEGRATIONS_SET_ACTIVE_INTEGRATIONS',
  INTEGRATIONS_SET_STATUS_FETCH_ACTIVE_INTEGRATIONS: 'INTEGRATIONS_SET_STATUS_FETCH_ACTIVE_INTEGRATIONS',
  INTEGRATIONS_SET_CONTACT_DETAILS: 'INTEGRATIONS_SET_CONTACT_DETAILS',
  INTEGRATIONS_SET_STATUS_FETCH_INTEGRATION_CONTACT_DETAILS:
    'INTEGRATIONS_SET_STATUS_FETCH_INTEGRATION_CONTACT_DETAILS',
  INTEGRATIONS_SET_STATUS_EXPORT_CONTACT: 'INTEGRATIONS_SET_STATUS_EXPORT_CONTACT',
  INTEGRATIONS_RESET_STATUSES_EXPORT_CONTACT: 'INTEGRATIONS_RESET_STATUSES_EXPORT_CONTACT',
  INTEGRATIONS_RESET_DETAILS: 'INTEGRATIONS_RESET_DETAILS',
  INTEGRATIONS_SET_STATUS_FETCH_BASIC_SEARCH: 'INTEGRATIONS_SET_STATUS_FETCH_BASIC_SEARCH',
  INTEGRATIONS_SET_STATUS_FETCH_BASIC_SEARCH_BY_EACH: 'INTEGRATIONS_SET_STATUS_FETCH_BASIC_SEARCH_BY_EACH',
  INTEGRATIONS_SET_BASIC_SEARCH_CONTACTS: 'INTEGRATIONS_SET_BASIC_SEARCH_CONTACTS',
  INTEGRATIONS_RESET_BASIC_SEARCH_CONTACTS: 'INTEGRATIONS_RESET_BASIC_SEARCH_CONTACTS',
  INTEGRATIONS_SET_ACTIVE_ID: 'INTEGRATIONS_SET_ACTIVE_ID',

  // SIP/VoIP CALLS
  SIP_SET_STATUS_SIP: 'SIP_SET_STATUS_SIP',
  SIP_SET_STATUS_CALL_INITIALIZATION: 'SIP_SET_STATUS_CALL_INITIALIZATION',
  SIP_SET_STATUS_INITIATE_CALL_TRANSFER: 'SIP_SET_STATUS_INITIATE_CALL_TRANSFER',
  SIP_SET_STATUS_CANCEL_CALL_RECORDING: 'SIP_SET_STATUS_CANCEL_CALL_RECORDING',
  SIP_SET_DATA_START_CALL_INCOMING: 'SIP_SET_DATA_START_CALL_INCOMING',
  SIP_SET_DATA_START_CALL_OUTGOING: 'SIP_SET_DATA_START_CALL_OUTGOING',
  SIP_CLEAR_DATA_START_CALL: 'SIP_CLEAR_DATA_START_CALL',
  SIP_SET_DATA_CONNECT: 'SIP_SET_DATA_CONNECT',
  SIP_SET_DATA_CONFIG: 'SIP_SET_DATA_CONFIG',
  SIP_SET_DATA_WSS_RECORDS: 'SIP_SET_DATA_WSS_RECORDS',
  SIP_SET_DATA_WSS_UNAVAILABLE_URL_OR_EMPTY: 'SIP_SET_DATA_WSS_UNAVAILABLE_URL_OR_EMPTY',
  SIP_SET_DATA_STUN_RECORDS: 'SIP_SET_DATA_STUN_RECORDS',
  SIP_SET_DATA_CALL: 'SIP_SET_DATA_CALL',
  SIP_UPDATE_DATA_CALL_BY_INITIALISING_ID: 'SIP_UPDATE_DATA_CALL_BY_INITIALISING_ID',
  SIP_REMOVE_DATA_CALL_BY_INITIALISING_ID: 'SIP_REMOVE_DATA_CALL_BY_INITIALISING_ID',
  SIP_REMOVE_DATA_CALL_BY_CALL_IDENTIFIER: 'SIP_REMOVE_DATA_CALL_BY_CALL_IDENTIFIER',
  SIP_SET_DATA_CALLS: 'SIP_SET_DATA_CALLS',
  SIP_SET_DATA_CALLS_CAPABILITIES_INCOMING_RINGTONE_DISABLED:
    'SIP_SET_DATA_CALLS_CAPABILITIES_INCOMING_RINGTONE_DISABLED',
  SIP_SET_DATA_CALLS_CAPABILITIES_ADVANCED_MEDIA_CONSTRAINTS_ENABLED:
    'SIP_SET_DATA_CALLS_CAPABILITIES_ADVANCED_MEDIA_CONSTRAINTS_ENABLED',
  SIP_SET_DATA_CALLS_CAPABILITIES_HD_CODEC_ENABLED: 'SIP_SET_DATA_CALLS_CAPABILITIES_HD_CODEC_ENABLED',

  // CALL QUALITY SURVEY
  CALL_QUALITY_SURVEY_START_SURVEY: 'CALL_QUALITY_SURVEY_START_SURVEY',
  CALL_QUALITY_SURVEY_END_SURVEY: 'CALL_QUALITY_SURVEY_END_SURVEY',
  CALL_QUALITY_SURVEY_SET_STATUS_INITIALIZE_SURVEY: 'CALL_QUALITY_SURVEY_SET_STATUS_INITIALIZE_SURVEY',
  CALL_QUALITY_SURVEY_SET_STATUS_SUBMIT_SURVEY: 'CALL_QUALITY_SURVEY_SET_STATUS_SUBMIT_SURVEY',

  // PUSHES/PubNub
  PUSH_SET_STATUS_GET_PUSHES: 'PUSH_SET_STATUS_GET_PUSHES',
  PUSH_SET_STATUS_DELETE_PUSHES: 'PUSH_SET_STATUS_DELETE_PUSHES',
  PUSH_SET_ID_LAST_PUSH: 'PUSH_SET_ID_LAST_PUSH',
  PUSH_SET_HAS_MISSING_PUSHES: 'PUSH_SET_HAS_MISSING_PUSHES',

  // STEALTH MODE (PRIVATE MODE) => LEGACY
  OPEN_STEALTH_MODAL: 'OPEN_STEALTH_MODAL',
  CLOSE_STEALTH_MODAL: 'CLOSE_STEALTH_MODAL',

  // BROWSER TABS
  BROWSER_TABS_SET_IS_CURRENT_ACTIVE: 'BROWSER_TABS_SET_IS_CURRENT_ACTIVE',

  // PERMISSIONS
  BROWSER_PERMISSIONS_SET_MICROPHONE_PERMISSION: 'BROWSER_PERMISSIONS_SET_MICROPHONE_PERMISSION',
  BROWSER_PERMISSIONS_SET_PLAY_AUDIO_PERMISSION: 'BROWSER_PERMISSIONS_SET_PLAY_AUDIO_PERMISSION',
} as const;
