import { MultiDeviceDevice } from 'types';

import { useWebAppSelector, selectMultiDeviceCurrentDevice, selectMultiDeviceOtherConnectedDevices } from '@redux';

import { MultiDeviceListType } from '../enums';

type UseDeviceList = (type: MultiDeviceListType) => Array<MultiDeviceDevice>;

export const useDeviceList: UseDeviceList = (type) => {
  const currentDevice = useWebAppSelector(selectMultiDeviceCurrentDevice);
  const connectedDevices = useWebAppSelector(selectMultiDeviceOtherConnectedDevices);

  const modalList = connectedDevices;
  const settingsList = currentDevice ? [currentDevice, ...connectedDevices] : connectedDevices;

  const deviceList = {
    [MultiDeviceListType.MODAL]: modalList,
    [MultiDeviceListType.SETTINGS]: settingsList,
  };

  return deviceList[type];
};
