import { isNumeric } from './common';

export const passwordIsValid = (pass) => {
  const regexp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,30}$/;

  return pass.match(regexp);
};

export const Validator = class Validator {
  constructor(value, rules) {
    this.value = value;
    this.rules = rules;
    this.errors = [];

    this.init();
  }

  init() {
    if (typeof this.value === 'object') {
      for (var fieldName in this.value) {
        this.validate(fieldName, this.value[fieldName], this.rules[fieldName]);
      }
    } else {
      this.validate(fieldName, this.value, this.rules);
    }
  }

  // TODO: needs nice error messages + translations
  validate(fieldName, value, rules) {
    if (rules.hasOwnProperty('min')) {
      if (value.length < rules.min) {
        this.errors.push(`${fieldName}: length should be at least ${rules.min}`);
      }
    }

    if (rules.hasOwnProperty('max')) {
      if (value.length > rules.max) {
        this.errors.push(`${fieldName}: maximum length allowed is ${rules.max}`);
      }
    }

    if (rules.hasOwnProperty('regex')) {
      if (!rules.regex.test(value)) {
        this.errors.push(`${fieldName}: regex fail`);
      }
    }

    if (rules.hasOwnProperty('digits')) {
      if (!(rules.digits === true && isNumeric(value))) {
        this.errors.push(`${fieldName}: only digits allowed`);
      }
    }

    if (rules.hasOwnProperty('required')) {
      if (!(rules.required === true && value.trim().length > 0)) {
        this.errors.push(`${fieldName}: is required`);
      }
    }

    if (rules.hasOwnProperty('boolean')) {
      if (!(rules.boolean === true && (value === true || value === false))) {
        this.errors.push(`${fieldName}: not boolean value provided`);
      }
    }

    if (rules.hasOwnProperty('oneOf')) {
      if (!(rules.oneOf.indexOf(value) !== -1)) {
        this.errors.push(`${fieldName}: does not match allowed values`);
      }
    }
  }

  hasErrors() {
    return this.errors.length;
  }
};
