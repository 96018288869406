import React from 'react';

const CreditCardIconVisa: React.FC = () => (
  <svg
    width="50"
    height="35"
    viewBox="0 0 50 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.1998 24.004H18.871L20.9531 11.7534H24.2817L22.1998 24.004Z"
      fill="#15195A"
    />
    <path
      d="M34.267 12.0529C33.6104 11.8051 32.569 11.5314 31.2813 11.5314C27.9939 11.5314 25.679 13.1995 25.6648 15.5843C25.6375 17.3439 27.3222 18.3211 28.5823 18.9078C29.8701 19.5073 30.3079 19.8986 30.3079 20.433C30.2948 21.2537 29.2672 21.632 28.3088 21.632C26.9798 21.632 26.2677 21.437 25.1855 20.9804L24.7471 20.7846L24.2813 23.5346C25.0621 23.8731 26.5005 24.1735 27.9939 24.1866C31.4868 24.1866 33.7608 22.5444 33.7877 20.0029C33.801 18.6083 32.9114 17.5398 30.9935 16.6666C29.8292 16.106 29.1162 15.7281 29.1162 15.1546C29.1298 14.6332 29.7192 14.0991 31.0335 14.0991C32.1157 14.073 32.9109 14.3205 33.5132 14.5682L33.8143 14.6983L34.267 12.0529Z"
      fill="#15195A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.2258 11.7534H42.8007L45.4862 24.0039H42.404C42.404 24.0039 42.1024 22.5963 42.0068 22.1662H37.7328C37.6092 22.4918 37.0342 24.0039 37.0342 24.0039H33.5413L38.4859 12.7699C38.8285 11.9748 39.4318 11.7534 40.2258 11.7534ZM40.0207 16.2365C40.0207 16.2365 38.9658 18.9603 38.6917 19.6641H41.4586C41.3217 19.0516 40.6913 16.1192 40.6913 16.1192L40.4587 15.0636C40.3607 15.3354 40.219 15.7091 40.1235 15.9612C40.0587 16.1321 40.0151 16.247 40.0207 16.2365Z"
      fill="#15195A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.44742 11.7534H9.80324C10.5292 11.7792 11.1183 12.0008 11.3099 12.7832L12.4738 18.4113C12.474 18.4118 12.4741 18.4124 12.4743 18.4129L12.8305 20.1072L16.0906 11.7534H19.6106L14.3782 23.991H10.858L7.891 13.3464C6.86728 12.7769 5.69892 12.3188 4.39263 12.0009L4.44742 11.7534Z"
      fill="#15195A"
    />
  </svg>
);

export default CreditCardIconVisa;
