import { v4 as uuidv4 } from 'uuid';

import { ActiveIntegration, IntegrationContact, IntegrationContactRawData, Status } from 'types';

import { getContactFullName } from 'helpers';

import { IntegrationDetails, IntegrationStatusMap } from '@redux/types';

export const addIntegrationDefaultStatuses = (integrations: Array<ActiveIntegration>): IntegrationStatusMap =>
  integrations.reduce((acc, integration) => {
    acc[integration.id] = Status.IDLE;
    return acc;
  }, {});

export const removeIntegrationsDetailsByIntegrationId = (
  integrationIds: Array<string>,
  details: IntegrationDetails,
): IntegrationDetails =>
  Object.keys(details).reduce((contactDetailsAccumulator, userId) => {
    if (integrationIds.length === 0) return details;

    const contactDetails = { ...contactDetailsAccumulator };
    contactDetails[userId] = Object.keys(details[userId]).reduce((integrationDetailsAccumulator, integrationId) => {
      const integrationDetails = { ...integrationDetailsAccumulator };
      if (!integrationIds.includes(integrationId)) {
        integrationDetails[integrationId] = details[userId][integrationId];
      }
      return integrationDetails;
    }, {});

    return contactDetails;
  }, {});

export const appendExtraPropsToIntegrationContacts = (
  integrationContacts: Array<IntegrationContactRawData>,
): Array<IntegrationContact> =>
  integrationContacts.map((integrationContact) => {
    const id = uuidv4();
    return {
      ...integrationContact,
      id,
      key: id,
      displayName: getContactFullName(integrationContact),
      isIntegrationContact: true,
    };
  });

export const mapIntegrationContactsDefaultValues = (integrations: Array<ActiveIntegration> = []): Record<string, []> =>
  integrations.reduce((acc, current) => {
    const result = { ...acc };
    result[current.id] = [];
    return result;
  }, {});
