import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { Status } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  userResetStore,
  authenticationLogoutHandler,
  selectAuthenticationStatuses,
} from '@redux';
import {
  DropdownCommon,
  DropdownCommonOptions,
  DropdownIconOption,
  DropdownIconOptionProps,
} from 'components/Dropdowns';
import { IconButton, IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';
import { useClickAway, useUserCapabilities } from 'hooks';
import { ApplicationPaths } from 'routes';

import { useNavElements } from '../../hooks';
import LogoB2B from '../LogoB2B';
import LogoB2C from '../LogoB2C';

import styles from './Header.module.scss';

export interface HeaderProps {
  hasMenu?: boolean;
  onClickMenu?: () => void;
}

const Header: React.FC<HeaderProps> = ({ hasMenu = false, onClickMenu }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useWebAppDispatch();

  const { statusLogout } = useWebAppSelector(selectAuthenticationStatuses);
  const { isUserB2B, isUserB2C, isUserPremium } = useUserCapabilities();

  const refActions = useRef<HTMLDivElement>(null);
  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState<boolean>(false);

  const navElements = useNavElements();
  const dropdownOptionsByNavElements: DropdownIconOptionProps[] = navElements.map((navElement) => ({
    ...navElement,
    value: navElement.icon,
  }));
  const dropdownOptions: DropdownIconOptionProps[] = [
    ...dropdownOptionsByNavElements,
    {
      label: intl.formatMessage({ id: 'Header.Actions.button_logout' }),
      value: IconCommonNames.LOGOUT,
    },
  ];

  const handleDropdownOptionSelect = (value: string) => {
    /**
     * Logout
     */
    if (value === IconCommonNames.LOGOUT) {
      dispatch(authenticationLogoutHandler());
      return;
    }

    /**
     * All
     */
    const navElementPath = navElements.find((element) => element.icon === value)?.path;
    if (navElementPath) {
      setIsActionsDropdownOpen(false);
      navigate(navElementPath);
    }
  };

  const renderDropdownOption = (option: DropdownCommonOptions) => {
    const { label, value } = option;
    const isCurrentPath = navElements.find(({ icon }) => icon === value)?.isCurrentPath === true;

    return (
      <DropdownIconOption
        className={classNames(styles.actionsDropdownOption, {
          [styles.isActiveB2B]: isCurrentPath && isUserB2B,
          [styles.isActiveB2C]: isCurrentPath && isUserB2C,
          [styles.isUpgradeToPremium]: value === IconCommonNames.PREMIUM_CROWN,
          [styles.isLogout]: value === IconCommonNames.LOGOUT,
        })}
        value={value as IconCommonNames}
        label={label}
      />
    );
  };

  const toggleActionsDropdown = () => setIsActionsDropdownOpen((current) => !current);

  useEffect(() => {
    if (statusLogout === Status.SUCCEEDED) {
      navigate(ApplicationPaths.ROOT, { state: { redirectUri: ApplicationPaths.CALLS } });
      dispatch(userResetStore());
    }
  }, [statusLogout, dispatch, navigate]);

  useClickAway(refActions, () => setIsActionsDropdownOpen(false));

  return (
    <header className={styles.root}>
      {hasMenu && onClickMenu && (
        <div className={styles.sideMenu}>
          <IconButton
            iconName={IconCommonNames.MENU}
            onClick={onClickMenu}
          />
        </div>
      )}

      <div className={styles.container}>
        <div className={styles.logo}>
          <Link
            to={ApplicationPaths.CALLS}
            className={styles.logoLink}
          >
            {isUserB2B ? <LogoB2B /> : <LogoB2C />}
          </Link>
        </div>

        <div
          ref={refActions}
          className={styles.actions}
        >
          <div className={styles.actionsTrigger}>
            <button
              type="button"
              className={styles.actionsTriggerButton}
              onClick={toggleActionsDropdown}
            >
              {isUserPremium && (
                <IconCommon
                  name={IconCommonNames.PREMIUM_CROWN}
                  size={IconCommonSizes.TINY}
                  className={styles.actionsTriggerButtonIconPremium}
                />
              )}
              <span className={styles.actionsTriggerButtonText}>
                <FormattedMessage id={'Header.Actions.button_trigger'} />
              </span>
              <IconCommon name={IconCommonNames.DROP_DOWN} />
            </button>
          </div>

          {isActionsDropdownOpen && (
            <DropdownCommon
              className={styles.actionsDropdown}
              isOpen={isActionsDropdownOpen}
              options={dropdownOptions}
              onSelect={handleDropdownOptionSelect}
              renderOption={renderDropdownOption}
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
