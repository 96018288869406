import {
  EmptyResponse,
  VoipCancelCallRecordingRequest,
  VoipInitiateCallTransferRequest,
  VoipOutgoingCallRequest,
  VoipOutgoingCallResponse,
  VoipService,
} from 'types';

import { apiMobile } from 'api';

const voipService: VoipService = {
  prepareOutgoingCall: ({ calleeNumber, categoryId, isVoipCall }: VoipOutgoingCallRequest) =>
    apiMobile.get<VoipOutgoingCallResponse, VoipOutgoingCallRequest>('/prepare-outgoing-call', {
      calleeNumber,
      categoryId,
      isVoipCall,
    }),
  initiateCallTransfer: (props: VoipInitiateCallTransferRequest) =>
    apiMobile.post<Promise<EmptyResponse>, VoipInitiateCallTransferRequest>('/initiate-call-transfer', props),
  cancelCallRecording: (props: VoipCancelCallRecordingRequest) =>
    apiMobile.post<EmptyResponse, VoipCancelCallRecordingRequest>('/call/call-recording/cancel', props),
};

export default voipService;
