import { IllustrationCommonNames } from 'components/Illustration';

import { ModalsDialogType } from '../enum';

export const getIllustrationName = (type: ModalsDialogType): IllustrationCommonNames => {
  const typeToIllustrationNameMap = {
    [ModalsDialogType.SUCCESS]: IllustrationCommonNames.SUCCESS,
    [ModalsDialogType.PREMIUM_UNLOCKED]: IllustrationCommonNames.PREMIUM_UNLOCKED,
    [ModalsDialogType.PREMIUM_FEATURE]: IllustrationCommonNames.PREMIUM_FEATURE,
  };

  return typeToIllustrationNameMap[type];
};
