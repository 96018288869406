import React from 'react';

import { StyleableComponent } from 'types';

const PremiumFeature: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M200 100C200 155.228 155.228 200 100 200C44.7715 200 0 155.228 0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 99.9998C187.368 148.252 148.252 187.368 100 187.368C51.7478 187.368 12.6317 148.252 12.6317 99.9998C12.6317 51.7475 51.7478 12.6313 100 12.6313C148.252 12.6313 187.368 51.7475 187.368 99.9998Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 99.9998C172.105 139.822 139.823 172.105 100 172.105C60.1773 172.105 27.8947 139.822 27.8947 99.9998C27.8947 60.1772 60.1773 27.8945 100 27.8945C139.823 27.8945 172.105 60.1772 172.105 99.9998Z"
      fill="#F3F4F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.5064 165.328L31.5222 66.8907C29.5867 60.9337 32.8467 54.5354 38.8037 52.5999L119.777 26.2901C125.734 24.3545 132.132 27.6146 134.068 33.5716L166.052 132.009C167.987 137.965 164.727 144.363 158.77 146.299L77.7969 172.608C71.8399 174.544 65.4416 171.284 63.5064 165.328Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.3728 97.8799C59.7986 99.1902 61.206 99.9073 62.5163 99.4816L128.217 78.1342C129.527 77.7085 130.244 76.3011 129.818 74.9907C129.393 73.6804 127.985 72.9633 126.675 73.3891L60.9745 94.7364C59.6642 95.1622 58.9471 96.5696 59.3728 97.8799Z"
      fill="url(#paint0_linear_13458_5672)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.9715 51.6112C88.3971 52.9212 89.8041 53.6381 91.1141 53.2124L109.88 47.1149C111.19 46.6893 111.907 45.2823 111.482 43.9723C111.056 42.6623 109.649 41.9454 108.339 42.371L89.5727 48.4686C88.2628 48.8942 87.5459 50.3012 87.9715 51.6112Z"
      fill="#F1F1F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.7359 108.23C63.1617 109.541 64.5691 110.258 65.8794 109.832L131.58 88.4848C132.89 88.059 133.607 86.6517 133.182 85.3413C132.756 84.031 131.348 83.3139 130.038 83.7396L64.3376 105.087C63.0273 105.513 62.3102 106.92 62.7359 108.23Z"
      fill="url(#paint1_linear_13458_5672)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.0988 118.58C66.5245 119.89 67.9315 120.607 69.2415 120.182L134.943 98.834C136.253 98.4083 136.97 97.0013 136.544 95.6913C136.119 94.3813 134.712 93.6644 133.402 94.09L67.7001 115.438C66.3901 115.863 65.6732 117.27 66.0988 118.58Z"
      fill="url(#paint2_linear_13458_5672)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.9765 144.955C76.0654 151.384 82.9705 154.902 89.3996 152.813L137.028 137.338C143.457 135.249 146.976 128.344 144.887 121.915C142.798 115.486 135.893 111.967 129.464 114.056L81.8349 129.532C75.4059 131.621 71.8876 138.526 73.9765 144.955Z"
      fill="#A5A9AF"
    />
    <path
      d="M109.653 129.548L112.618 138.673L111.118 139.16L108.153 130.035L105.439 135.363L104.034 134.648L107.971 126.922L115.696 130.858L114.981 132.263L109.653 129.548Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.0211 69.0627L62.4277 70.1835C61.7162 72.0202 60.1791 73.3921 57.9915 74.1029C54.8182 75.134 51.7252 74.1567 50.0205 71.7168L48.5048 72.2093L48.0154 70.7033L49.2621 70.2983C49.1917 70.1428 49.1392 69.9811 49.0868 69.8198C49.0344 69.6585 48.982 69.4972 48.9476 69.3301L47.7009 69.7352L47.2114 68.2286L48.7271 67.7362C48.6722 64.7607 50.6001 62.1518 53.7734 61.1208C55.961 60.41 58.0109 60.6163 59.6602 61.6662L58.3722 63.9878C57.1971 63.2992 55.9763 63.1407 54.7211 63.5485C53.1252 64.0671 52.0849 65.2575 51.8292 66.7282L56.3652 65.2544L56.8547 66.7609L51.8703 68.3804C51.9046 68.5476 51.957 68.7089 52.0094 68.8702C52.0618 69.0315 52.1143 69.1931 52.1848 69.3486L57.1692 67.7291L57.6586 69.2351L53.1225 70.7089C54.1939 71.7485 55.7353 72.1001 57.3312 71.5816C58.5864 71.1737 59.481 70.3285 60.0211 69.0627Z"
      fill="#A5A9AF"
    />
    <path
      d="M81.2204 81.58C78.5087 82.4611 76.0118 82.6516 73.7296 82.1516C71.4658 81.6153 69.6308 80.3946 68.2245 78.4895L72.8047 74.4122C73.3168 75.336 73.8988 76.0553 74.5506 76.5703C75.1936 77.0579 75.9382 77.3459 76.7845 77.4343C77.6583 77.5138 78.6156 77.3844 79.6564 77.0462C80.5877 76.7436 81.3149 76.3257 81.8381 75.7923C82.3798 75.2226 82.7352 74.5923 82.9044 73.9014C83.0646 73.1832 83.0157 72.4269 82.7576 71.6325C82.4996 70.8382 82.1216 70.2342 81.6238 69.8206C81.1261 69.407 80.5266 69.193 79.8254 69.1785C79.1427 69.1278 78.322 69.2582 77.3634 69.5697C77.0073 69.6854 76.6694 69.8103 76.3496 69.9445C76.0483 70.0424 75.7148 70.181 75.3491 70.3604L74.0008 66.2108C74.2289 66.167 74.4569 66.1232 74.6849 66.0793C74.9314 65.9993 75.1779 65.9192 75.4245 65.8391C76.9583 65.3407 77.956 64.6834 78.4176 63.8672C78.8977 63.0147 78.9152 61.9037 78.4702 60.5342C78.1054 59.4112 77.4826 58.6597 76.6021 58.2796C75.74 57.8632 74.6927 57.8552 73.4601 58.2557C72.1454 58.6829 71.2113 59.3498 70.658 60.2563C70.0958 61.1355 69.8776 62.2814 70.0035 63.694L64.3359 63.4461C64.2196 61.9698 64.4669 60.5873 65.0778 59.2987C65.716 58.0011 66.6491 56.8652 67.8769 55.8909C69.0958 54.8892 70.5544 54.1125 72.2526 53.5607C74.2795 52.9021 76.0663 52.6698 77.6132 52.8637C79.1874 53.0486 80.4949 53.5777 81.5356 54.4508C82.6037 55.3151 83.3692 56.4593 83.832 57.8836C84.1969 59.0066 84.3303 60.0231 84.2323 60.9332C84.1528 61.8069 83.9282 62.6067 83.5584 63.3325C83.2071 64.022 82.7883 64.5971 82.3021 65.0579C81.8159 65.5187 81.3352 65.8566 80.8599 66.0716L80.9 66.1948C81.5389 66.0175 82.227 65.9453 82.9645 65.9783C83.6931 65.9838 84.4069 66.1304 85.106 66.418C85.8325 66.6968 86.4939 67.1481 87.0903 67.7719C87.6778 68.3684 88.1317 69.1596 88.4521 70.1457C88.9861 71.7891 89.0195 73.3832 88.5524 74.9279C88.1038 76.4364 87.2512 77.7733 85.9946 78.9386C84.7291 80.0766 83.1377 80.9571 81.2204 81.58Z"
      fill="#C8CBCE"
    />
    <path
      d="M160.6 76.8C160.6 87.1829 152.183 95.6 141.8 95.6C131.417 95.6 123 87.1829 123 76.8C123 66.417 131.417 58 141.8 58C152.183 58 160.6 66.417 160.6 76.8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.571 75.1429C148.291 75.1429 148.982 75.4288 149.491 75.9379C150 76.4469 150.286 77.1373 150.286 77.8571V83.2857C150.286 84.0056 150 84.696 149.491 85.205C148.982 85.714 148.291 86 147.571 86H136.714C135.994 86 135.304 85.714 134.795 85.205C134.286 84.696 134 84.0056 134 83.2857V77.8571C134 77.1373 134.286 76.4469 134.795 75.9379C135.304 75.4288 135.994 75.1429 136.714 75.1429V72.4286C136.714 70.9888 137.286 69.608 138.304 68.59C139.322 67.5719 140.703 67 142.143 67C143.583 67 144.963 67.5719 145.981 68.59C146.999 69.608 147.571 70.9888 147.571 72.4286V75.1429ZM142.143 68.8095C143.103 68.8095 144.023 69.1908 144.702 69.8695C145.381 70.5482 145.762 71.4687 145.762 72.4286V75.1429H138.524V72.4286C138.524 71.4687 138.905 70.5482 139.584 69.8695C140.263 69.1908 141.183 68.8095 142.143 68.8095ZM147.571 76.9524H136.714C136.474 76.9524 136.244 77.0477 136.075 77.2174C135.905 77.3871 135.81 77.6172 135.81 77.8571V83.2857C135.81 83.5257 135.905 83.7558 136.075 83.9255C136.244 84.0952 136.474 84.1905 136.714 84.1905H147.571C147.811 84.1905 148.042 84.0952 148.211 83.9255C148.381 83.7558 148.476 83.5257 148.476 83.2857V77.8571C148.476 77.6172 148.381 77.3871 148.211 77.2174C148.042 77.0477 147.811 76.9524 147.571 76.9524Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.279 89.7944L189.655 87.4106L187 86.2793L189.384 84.654L190.515 82L192.14 84.3844L194.794 85.5151L192.411 87.1397L191.279 89.7944Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2793 33.7944L22.6547 31.4106L20 30.2793L22.3838 28.654L23.5151 26L25.1397 28.3844L27.7944 29.5151L25.4106 31.1397L24.2793 33.7944Z"
      fill="#E4E4E4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_13458_5672"
        x1="70.0008"
        y1="94.0003"
        x2="131"
        y2="74.5002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3F3F3" />
        <stop
          offset="1"
          stopColor="#F3F3F3"
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_13458_5672"
        x1="73.3639"
        y1="104.351"
        x2="131.5"
        y2="86.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3F3F3" />
        <stop
          offset="1"
          stopColor="#F3F3F3"
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_13458_5672"
        x1="76.7269"
        y1="114.701"
        x2="145.5"
        y2="95.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F3F3F3" />
        <stop
          offset="1"
          stopColor="#F3F3F3"
          stopOpacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
);

export default PremiumFeature;
