import { callQualitySurveyOptions } from '../constants';

export type ModalsCallQualitySurveyProps = {
  isOpen: boolean;
};

export type CallQualitySurveyRating = typeof callQualitySurveyOptions[number];

export enum CallQualitySurveyReasonOption {
  DISTORTED_SPEECH = 'distorted-speech',
  ONEWAY_AUDIO = 'one-way-audio',
  ECHO = 'echo',
  BACKGROUND_NOISE = 'call-drop',
  OTHER = 'other',
}
