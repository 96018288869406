import { useEffect } from 'react';

import { useWebAppDispatch, sipSetDataCallsStatisticsEstablishmentByWebRTCIdHandler } from '@redux';
import { SIPDataCallStatusesWebRTC } from '@redux/types';

import { useSIPStatistics } from '../../../../hooks/useSIPStatistics';
import { UseSIPCallsAnalyticsProps } from '../../types';

export const useSIPCallsAnalytics = ({ call }: UseSIPCallsAnalyticsProps): void => {
  const { ids, originator, statuses, phoneNumbers, timestamps, statistics } = call;

  const dispatch = useWebAppDispatch();
  const { sipSendLogToGTMCallEstablished } = useSIPStatistics();

  const callbackGTMCallEstablishedEventHandler = (): void => {
    if (
      statistics.isEstablishmentSent === false &&
      [SIPDataCallStatusesWebRTC.RINGING_180, SIPDataCallStatusesWebRTC.RINGING_183].includes(statuses.webRTC) &&
      timestamps.callRang > 0
    ) {
      sipSendLogToGTMCallEstablished({
        propOriginator: originator,
        propTransactionId: ids.transaction,
        propPhoneNumberRemote: phoneNumbers.remote,
        propTimestampCreated: timestamps.callCreated,
        propTimestampRang: timestamps.callRang,
      });

      dispatch(
        sipSetDataCallsStatisticsEstablishmentByWebRTCIdHandler({
          propCallIdWebRTC: ids.webRTC,
          propCallIsEstablishmentSent: true,
        }),
      );
    }
  };

  useEffect(callbackGTMCallEstablishedEventHandler, [
    statuses.webRTC,
    originator,
    ids.transaction,
    ids.webRTC,
    phoneNumbers.remote,
    timestamps.callCreated,
    timestamps.callRang,
    statistics.isEstablishmentSent,
    sipSendLogToGTMCallEstablished,
    dispatch,
  ]);
};
