import classNames from 'classnames';
import React from 'react';

import { IconButton, IconCommonNames } from 'components/Icon';

import { SMSButtonSendProps } from '../../types';

import styles from './SMSButtonSend.module.scss';

const SMSButtonSend: React.FC<SMSButtonSendProps> = ({
  isUserB2B,
  className,
  isDisabled = false,
  isSending = false,
  onSend,
}) => (
  <IconButton
    className={classNames(className, styles.root, {
      [styles.isB2B]: isUserB2B,
    })}
    iconName={isSending ? IconCommonNames.SPINNER : IconCommonNames.SEND}
    isDisabled={isDisabled}
    onClick={isSending ? undefined : onSend}
  />
);

export default SMSButtonSend;
