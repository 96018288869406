import React from 'react';

import { StyleableComponent } from 'types';

const DropUp: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53_1077)">
      <path
        d="M8.71054 12.2897L11.3005 9.69973C11.6905 9.30973 12.3205 9.30973 12.7105 9.69973L15.3005 12.2897C15.9305 12.9197 15.4805 13.9997 14.5905 13.9997H9.41054C8.52054 13.9997 8.08054 12.9197 8.71054 12.2897Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_1077">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DropUp;
