import { useIntl } from 'react-intl';

type FormatPrice = (price: number, currencyCode: string) => string;

export const useFormatPrice = (): FormatPrice => {
  const intl = useIntl();

  return (price, currencyCode) => {
    const priceToString = price.toString();
    const truncatedPrice = priceToString.slice(0, priceToString.indexOf('.') + 3);
    const finalPrice = Number.isInteger(price) ? price : Number(truncatedPrice);

    return intl.formatNumber(finalPrice, {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: 2,
    });
  };
};
