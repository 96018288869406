import axios from 'axios';

import {
  DeleteMessagesRequest,
  FetchMessagesRequest,
  FetchMessagesResponse,
  GenerateMessageContentUrlRequest,
  GenerateMessageContentUrlResponse,
  MessagesService,
  SendMessageRequest,
  SendMessageResponse,
  UpdateSmsForCreditsRequest,
} from 'types';

import { apiMobile } from 'api';

const messagesService: MessagesService = {
  deleteMessages: (ids) => apiMobile.post<void, DeleteMessagesRequest>('/v4/delete-messages', { ids }),

  fetchMessages: (threadId, offset, limit = 30) =>
    apiMobile.get<FetchMessagesResponse, FetchMessagesRequest>('/v4/retrieve-thread-messages', {
      limit,
      offset,
      threadId,
    }),

  generateMessageContentUrl: (messageContentData) =>
    apiMobile
      .get<GenerateMessageContentUrlResponse, GenerateMessageContentUrlRequest>(
        '/v4/generate-message-content-url',
        messageContentData,
      )
      .then((res) => {
        if (messageContentData.messageType === 'VIDEO') {
          return {
            ...res[0],
            videoThumb: res[1],
          };
        }
        return res[0];
      }),

  sendMessage: (message) => apiMobile.post<SendMessageResponse, SendMessageRequest>('/v4/send-message', message),

  updateSendingSmsForCredits: (smsForCredits, categoryId) =>
    apiMobile.post<void, UpdateSmsForCreditsRequest>('/update-send-sms-for-credits', {
      smsForCredits,
      categoryId,
    }),

  uploadFileToPreSignedUrl: (preSignedUrl, file, fileType) =>
    axios({
      data: file,
      headers: { 'Content-Type': fileType },
      method: 'put',
      url: preSignedUrl,
    }),
};

export default messagesService;
