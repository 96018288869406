import { useState, ChangeEvent } from 'react';

import { useCalculateRows } from 'hooks';

type UseOnChangeWithCalculationOptions = {
  value?: string;
  onChange?: (value: string) => void;
  rowsMax?: number;
  rowsMin?: number;
};

type UseOnChangeWithCalculationResult = {
  makeCalculation: (target: HTMLTextAreaElement) => void;
  onChangeHandler: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  rows: number;
  isEmpty: boolean;
};

type UseOnChangeWithCalculation = {
  (options: UseOnChangeWithCalculationOptions): UseOnChangeWithCalculationResult;
};

const useOnChangeWithCalculation: UseOnChangeWithCalculation = ({
  value = '',
  onChange = () => undefined,
  rowsMax = 0,
  rowsMin = 1,
}) => {
  const { calculateRows, rows } = useCalculateRows(rowsMax, rowsMin);
  const [isEmpty, setIsEmpty] = useState(value.length === 0);

  const makeCalculation = (target: HTMLTextAreaElement) => {
    // if we want dynamic height changing we need to set it before row calculation
    // eslint-disable-next-line no-param-reassign
    target.style.height = '1px';
    calculateRows(target, value !== '');
    // eslint-disable-next-line no-param-reassign
    target.style.height = 'auto';
  };

  return {
    makeCalculation,
    isEmpty,
    onChangeHandler: (event: ChangeEvent<HTMLTextAreaElement>) => {
      const { value: inputValue } = event.target;
      onChange(inputValue);
      setIsEmpty(inputValue.length === 0);
      makeCalculation(event.target);
    },
    rows,
  };
};

export default useOnChangeWithCalculation;
