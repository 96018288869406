import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { showErrorToast, showSuccessToast } from '@onoff/toast-notification';
import { Button } from '@onoff/ui';

import { CallNotesOrigin, GAEventKey, Status } from 'types';

import {
  callsGetCallNoteByTransactionIdFromSessionStorage,
  callsSetCallNoteToSessionStorage,
  getContactOrNumberDisplayText,
  getErrorDataFromUnknownError,
} from 'helpers';

import { API_ERRORS } from '@constants';
import {
  useWebAppDispatch,
  useWebAppSelector,
  callLogsSetStatusSaveCallNote,
  callLogsSaveCallNoteHandler,
  selectCallLogsStatuses,
  selectContactByPhoneNumber,
} from '@redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalSize } from 'components/Modal';
import { useUserCapabilities } from 'hooks';
import { analyticsService } from 'services';

import { ModalsCallNotesProps } from '../../types';

import styles from './ModalsCallNotes.module.scss';

const ModalsCallNotes: React.FC<ModalsCallNotesProps> = ({
  data,
  isOpen,
  isCloseAndSaveAsDraft,
  onCloseHandler,
  isCallInProgress,
}) => {
  const {
    ids: { transaction: transactionId },
    phoneNumbers: { remote: phoneNumberRemote },
  } = data;
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const contact = useWebAppSelector(selectContactByPhoneNumber(phoneNumberRemote));
  const { saveCallNote: statusSaveCallNote } = useWebAppSelector(selectCallLogsStatuses);
  const { isUserB2B } = useUserCapabilities();

  const storageCallNoteValue = callsGetCallNoteByTransactionIdFromSessionStorage(transactionId)?.note || '';
  const [stateCallNoteValue, setStateCallNoteValue] = useState<string>(storageCallNoteValue);
  const isCallNoteValuesUpToDate = stateCallNoteValue === storageCallNoteValue;
  const isButtonSaveLoading = statusSaveCallNote === Status.LOADING;
  const isButtonSaveDisabled =
    isButtonSaveLoading || (storageCallNoteValue.length === 0 && stateCallNoteValue.length === 0);

  const contactDisplayName = getContactOrNumberDisplayText({
    phoneNumberOrEmpty: phoneNumberRemote,
    clirText: intl.formatMessage({ id: 'App.clir_calling_line_identification_restriction' }),
    contact,
  });

  const onChangeHandlerUpdateStateCallNote = ({ currentTarget }: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setStateCallNoteValue(currentTarget.value);
  };

  const onFocusHandlerUpdateSelectionState = ({ currentTarget }: React.ChangeEvent<HTMLTextAreaElement>): void => {
    currentTarget.setSelectionRange(stateCallNoteValue.length, stateCallNoteValue.length);
  };

  const onClickHandlerCancel = (): void => {
    setStateCallNoteValue(storageCallNoteValue);
    onCloseHandler();
  };

  const onClickHandlerSave = async () => {
    try {
      await dispatch(
        callLogsSaveCallNoteHandler({
          transactionId,
          callNote: stateCallNoteValue,
          isCallInProgress,
        }),
      );

      callsSetCallNoteToSessionStorage({
        transactionId,
        note: stateCallNoteValue,
        isDraft: false,
      });

      analyticsService.pushToDataLayer({
        event: storageCallNoteValue ? GAEventKey.CALL_NOTE_UPDATED : GAEventKey.CALL_NOTE_SAVED,
        variables: {
          origin: CallNotesOrigin.CALL_NOTE,
          call_in_progress: isCallInProgress ? 'Yes' : 'No',
        },
      });

      showSuccessToast({
        message: intl.formatMessage({ id: 'Modals.CallNotes.notification_succeeded_description' }),
      });

      dispatch(callLogsSetStatusSaveCallNote(Status.IDLE));

      onCloseHandler();
    } catch (error) {
      const { errorCode, errorDescription } = getErrorDataFromUnknownError(error);

      showErrorToast({
        heading: intl.formatMessage({ id: 'Modals.CallNotes.notification_failed_title' }),
        message:
          errorCode !== API_ERRORS.HTTP.UNKNOWN_ERROR && errorDescription
            ? errorDescription
            : intl.formatMessage({ id: 'Modals.CallNotes.notification_failed_description' }),
      });

      dispatch(callLogsSetStatusSaveCallNote(Status.IDLE));
    }
  };

  const callbackSaveCallNoteToStorageWhenClosingModalHandler = (): void => {
    const isStateCallNoteExist = stateCallNoteValue.length > 0;
    if (isCloseAndSaveAsDraft && isStateCallNoteExist) {
      callsSetCallNoteToSessionStorage({
        transactionId,
        note: stateCallNoteValue,
        isDraft: isCallNoteValuesUpToDate === false,
      });

      setStateCallNoteValue('');
    }
  };

  useEffect(callbackSaveCallNoteToStorageWhenClosingModalHandler, [
    isCloseAndSaveAsDraft,
    transactionId,
    stateCallNoteValue,
    isCallNoteValuesUpToDate,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      size={ModalSize.L}
      zIndex={42}
      className={styles.root}
      classNameOverlay={styles.overlay}
      canOverlayClose={false}
      canEscClose={false}
      onRequestClose={onCloseHandler}
    >
      <ModalHeader title={`${intl.formatMessage({ id: 'Modals.CallNotes.title' })}: ${contactDisplayName}`} />

      <ModalBody className={styles.body}>
        <textarea
          className={styles.callNote}
          onChange={onChangeHandlerUpdateStateCallNote}
          value={stateCallNoteValue}
          placeholder={intl.formatMessage({ id: 'Modals.CallNotes.placeholder' })}
          autoFocus
          onFocus={onFocusHandlerUpdateSelectionState}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          className={styles.footerButton}
          colorScheme="black"
          variant="ghost"
          size="large"
          onClick={onClickHandlerCancel}
        >
          <FormattedMessage id="Modals.CallNotes.button_cancel" />
        </Button>
        <Button
          className={styles.footerButton}
          colorScheme={isUserB2B ? 'brand-b2b' : 'brand-b2c'}
          variant="solid"
          size="large"
          loading={isButtonSaveLoading}
          disabled={isButtonSaveDisabled}
          onClick={onClickHandlerSave}
        >
          <FormattedMessage id="Modals.CallNotes.button_save" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalsCallNotes;
