import classNames from 'classnames';
import React from 'react';

import { People, User } from '@onoff/icons';

import { testGetTestingAttributes } from '@testing';
import { IconCommon, IconCommonNames } from 'components/Icon';

import avatarBlocked from '../../assets/avatar_block.svg';
import avatarStar from '../../assets/avatar_star.svg';
import { getAvatarType } from '../../helpers';
import { AvatarCommonProps, AvatarSize, AvatarType } from '../../types';

import styles from './AvatarCommon.module.scss';

const AvatarCommon: React.FC<AvatarCommonProps> = ({
  imageSrc = '',
  className = '',
  fullName = '',
  backgroundColor = '',
  size = AvatarSize.DEFAULT,
  hasStarIcon = false,
  hasBlockedIcon = false,
  isGroup = false,
  hasPhotoIcon = false,
  propTestId = '',
}) => {
  const avatarType = getAvatarType({ imageSrc, fullName, isGroup, hasPhotoIcon });
  const hasStatusIcon = hasBlockedIcon || hasStarIcon;

  return (
    <div
      className={classNames(styles.root, styles[`size-${size}`], className, {
        [styles.hasStatusIcon]: hasStatusIcon,
        [styles[`type-${avatarType}`]]: true,
      })}
      style={{
        backgroundColor,
      }}
      {...testGetTestingAttributes(propTestId)}
    >
      {avatarType === AvatarType.IMAGE && (
        <img
          className={classNames(styles.image)}
          src={imageSrc}
          alt={fullName}
        />
      )}
      {avatarType === AvatarType.LABEL && <span className={classNames(styles.label)}>{fullName[0]}</span>}
      {avatarType === AvatarType.GROUP && (
        <People
          color="white"
          className={classNames(styles.icon)}
        />
      )}
      {avatarType === AvatarType.DEFAULT && (
        <User
          color="white"
          className={classNames(styles.icon)}
        />
      )}
      {hasStatusIcon && (
        <img
          className={classNames(styles.statusIcon)}
          src={hasBlockedIcon ? avatarBlocked : avatarStar}
          alt={'star'}
        />
      )}
      {avatarType === AvatarType.PHOTO && (
        <IconCommon
          className={classNames(styles.icon)}
          name={IconCommonNames.CAMERA}
        />
      )}
    </div>
  );
};

export default AvatarCommon;
