import { ActionCreator } from '@reduxjs/toolkit';

import { Message, Status, Thread, ThreadUpdate } from 'types';

import { getContactByPhoneNumber, getCurrentTimeWithMilliseconds, handleApplicationError } from 'helpers';

import { selectContactByPhoneNumber, selectSimpleContacts, selectThreadsState } from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThreadsAddAction,
  ThreadsPubNubDeleteThreadHandlerProps,
  ThreadsPubNubReadThreadHandlerProps,
  ThreadsReduxState,
  ThreadsRemoveAction,
  ThreadsSetActiveThreadIdAction,
  ThreadsSetSearchStringAction,
  ThreadsSetStatusAction,
  ThreadsUpdateAction,
  ThunkResult,
} from '../../types';
import {
  messagesAddThread,
  messagesMarkAllAsRead,
  messagesRemoveThread,
  messagesSetStatusDeleteThreadMessages,
  messagesSetStatusThreadMessageUpdate,
} from '../messages';

export const threadsAdd = (threads: Array<Thread>, isAllLoaded?: boolean): ThreadsAddAction => ({
  type: REDUX_ACTION_TYPES.THREADS_ADD,
  threads,
  isAllLoaded,
});

export const threadsRemove: ActionCreator<ThreadsRemoveAction> = (threadIds: Array<Thread['threadId']>) => ({
  type: REDUX_ACTION_TYPES.THREADS_REMOVE,
  threadIds,
});

export const threadsUpdate = (updates: Array<ThreadUpdate>): ThreadsUpdateAction => ({
  type: REDUX_ACTION_TYPES.THREADS_UPDATE,
  updates,
});

export const threadsSetSearchString = (searchString: string): ThreadsSetSearchStringAction => ({
  type: REDUX_ACTION_TYPES.THREADS_SET_SEARCH_STRING,
  searchString,
});

export const threadsSetStatus = (
  request: keyof ThreadsReduxState['statuses'],
  status: Status,
): ThreadsSetStatusAction => ({
  type: REDUX_ACTION_TYPES.THREADS_SET_STATUS,
  request,
  status,
});

export const threadsSetActiveThreadId = (activeThreadId: string): ThreadsSetActiveThreadIdAction => ({
  type: REDUX_ACTION_TYPES.THREADS_SET_ACTIVE_THREAD_ID,
  activeThreadId,
});

export const threadsFetchHandler =
  (limit = 50): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      const { allLoaded, statuses, offset } = selectThreadsState(getState());

      const isLoading = statuses.fetchThreads === Status.LOADING;

      if (allLoaded || isLoading) {
        return;
      }

      dispatch(threadsSetStatus('fetchThreads', Status.LOADING));

      const shortMessageList = await services.threadsService.fetchThreads(offset, limit);
      const threadList: Thread[] = shortMessageList.map((message) => {
        const contact = selectContactByPhoneNumber(message.sourcePhoneNumber)(getState()) || false;

        return {
          ...message,
          contact,
        };
      });

      const isAllLoaded = threadList.length < limit;
      dispatch(threadsAdd(threadList, isAllLoaded));
      dispatch(threadsSetStatus('fetchThreads', Status.SUCCEEDED));
    } catch (error) {
      dispatch(threadsSetStatus('fetchThreads', Status.FAILED));
      handleApplicationError({ error });
    }
  };

export const threadsMarkAsReadHandler =
  (threadIds: Array<Thread['threadId']>): ThunkResult<Promise<void>> =>
  (dispatch, _, services): Promise<void> =>
    services.threadsService.markThreadsAsRead(threadIds).then(() => {
      const updates: Array<ThreadUpdate> = [];
      threadIds.forEach((threadId) => {
        dispatch(messagesMarkAllAsRead(threadId));
        updates.push({
          threadId,
          seenAt: getCurrentTimeWithMilliseconds(),
          unreadMessages: 0,
        });
      });
      dispatch(threadsUpdate(updates));
    });

export const threadsRemoveHandler =
  (threadIds: Array<Thread['threadId']>): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(messagesSetStatusDeleteThreadMessages(Status.LOADING));
      await services.threadsService.removeThreads(threadIds);
      dispatch(messagesSetStatusDeleteThreadMessages(Status.SUCCEEDED));
      dispatch(messagesRemoveThread(threadIds));
      dispatch(threadsRemove(threadIds));
    } catch (error) {
      dispatch(messagesSetStatusDeleteThreadMessages(Status.FAILED));
      throw error;
    }
  };

export const threadsSetActiveThreadHandler =
  (activeThreadId: string): ThunkResult<void> =>
  (dispatch) => {
    dispatch(threadsSetActiveThreadId(activeThreadId));
    if (activeThreadId.length > 0) {
      dispatch(messagesSetStatusThreadMessageUpdate(Status.IDLE));
      dispatch(threadsMarkAsReadHandler([activeThreadId]));
    }
  };

export const threadsCreateNewThreadHandler =
  (message: Message): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    const contactList = selectSimpleContacts(getState());
    const contact = getContactByPhoneNumber(message.sourcePhoneNumber, contactList);

    const newThread: Thread = {
      body: message.body,
      createdAt: message.createdAt,
      id: message.id,
      incoming: message.incoming || false,
      sourceCategoryId: message.sourceCategoryId,
      targetCategoryId: message.targetCategoryId,
      sourcePhoneNumber: message.sourcePhoneNumber,
      targetPhoneNumber: message.targetPhoneNumber,
      targetPhoneNumbers: message.targetPhoneNumbers,
      group: message.group,
      threadId: message.threadId,
      type: message.type,
      unreadMessages: 1,
      contact: contact || false,
      creatorId: message.creatorId,
      mms: message.mms,
      sms: message.sms,
      seenAt: message.seenAt || '',
    };

    dispatch(threadsAdd([newThread]));
    dispatch(messagesAddThread(message));
  };

export const threadsPubNubDeleteThreadHandler =
  ({ threadIds }: ThreadsPubNubDeleteThreadHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    dispatch(messagesRemoveThread(threadIds));
    dispatch(threadsRemove(threadIds));
    dispatch(threadsSetActiveThreadId(''));
    dispatch(messagesSetStatusDeleteThreadMessages(Status.IDLE));
  };

export const threadsPubNubReadThreadHandler =
  ({ threadIds }: ThreadsPubNubReadThreadHandlerProps): ThunkResult<Promise<void>> =>
  async (dispatch): Promise<void> => {
    const updatedThreads: ThreadUpdate[] = threadIds.map((threadId) => ({
      threadId,
      seenAt: getCurrentTimeWithMilliseconds(),
      unreadMessages: 0,
    }));
    dispatch(threadsUpdate(updatedThreads));
  };
