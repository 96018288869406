import React from 'react';

import { StyleableComponent } from 'types';

const EyeOff: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5203_13129)">
      <path
        d="M12 6.49969C14.76 6.49969 17 8.73969 17 11.4997C17 12.0097 16.9 12.4997 16.76 12.9597L19.82 16.0197C21.21 14.7897 22.31 13.2497 23 11.4897C21.27 7.10969 17 3.99969 12 3.99969C10.73 3.99969 9.51 4.19969 8.36 4.56969L10.53 6.73969C11 6.59969 11.49 6.49969 12 6.49969ZM2.71 3.15969C2.32 3.54969 2.32 4.17969 2.71 4.56969L4.68 6.53969C3.06 7.82969 1.77 9.52969 1 11.4997C2.73 15.8897 7 18.9997 12 18.9997C13.52 18.9997 14.97 18.6997 16.31 18.1797L19.03 20.8997C19.42 21.2897 20.05 21.2897 20.44 20.8997C20.83 20.5097 20.83 19.8797 20.44 19.4897L4.13 3.15969C3.74 2.76969 3.1 2.76969 2.71 3.15969ZM12 16.4997C9.24 16.4997 7 14.2597 7 11.4997C7 10.7297 7.18 9.99969 7.49 9.35969L9.06 10.9297C9.03 11.1097 9 11.2997 9 11.4997C9 13.1597 10.34 14.4997 12 14.4997C12.2 14.4997 12.38 14.4697 12.57 14.4297L14.14 15.9997C13.49 16.3197 12.77 16.4997 12 16.4997ZM14.97 11.1697C14.82 9.76969 13.72 8.67969 12.33 8.52969L14.97 11.1697Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_5203_13129">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EyeOff;
