import React, { useState } from 'react';

import { Favourite, Person } from '@onoff/icons';
import { IconButton } from '@onoff/ui';

import { contactsToggleFavorite, useWebAppDispatch } from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';
import { IconCommon, IconCommonNames } from 'components/Icon';
import { ModalsAddEditContact } from 'components/Modals';

import { InfoTopBarActionsFeaturesContactProps } from '../../types';

const InfoTopBarActionsFeaturesContact: React.FC<InfoTopBarActionsFeaturesContactProps> = ({ contact }) => {
  const dispatch = useWebAppDispatch();

  const [stateModalContactVisible, setStateModalContactVisible] = useState<boolean>(false);

  const isContactExist = contact !== undefined;
  const isContactFavorited = isContactExist && contact.favorite === true;

  const onClickHandlerContactsEdit = (): void => {
    setStateModalContactVisible(!stateModalContactVisible);
  };

  const onClickHandlerContactsFavoriteUnfavorite = (): void => {
    if (isContactExist) {
      dispatch(contactsToggleFavorite(contact.key));
    }
  };

  return (
    <>
      <IconButton
        onClick={onClickHandlerContactsFavoriteUnfavorite}
        {...testGetTestingAttributes(
          isContactFavorited
            ? testIds.infoTopBarActionsButtonContactsUnFavorite
            : testIds.infoTopBarActionsButtonContactsFavorite,
        )}
      >
        {isContactFavorited ? <Favourite /> : <IconCommon name={IconCommonNames.UN_FAVORITE} />}
      </IconButton>
      <IconButton
        onClick={onClickHandlerContactsEdit}
        {...testGetTestingAttributes(testIds.infoTopBarActionsButtonContactsEdit)}
      >
        <Person />
      </IconButton>
      <ModalsAddEditContact
        isOpen={stateModalContactVisible}
        onClose={onClickHandlerContactsEdit}
        formDataDefault={contact}
        isEditModal={true}
      />
    </>
  );
};

export default InfoTopBarActionsFeaturesContact;
