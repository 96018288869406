import { Category, UserCallAnswerMethods, UserFeatures } from 'types';

import packageJson from '../../../package.json';
import { isCategoryExpired } from '../category';
import { getUserAgentData } from '../common';
import { isCreditCardExpired } from '../creditCards';
import { getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage } from '../sip';
import { isString } from '../typeCheckers';

import { GetUserGTMDataLayerVariablesProps, GetUserGTMDataLayerVariablesReturn } from './types';

const getOldestCategory = (categories: Array<Category>): Category | undefined => {
  const sortedCategoriesByCreationDate: Array<Category> = [...categories].sort(
    (categoryA, categoryZ) => new Date(categoryA.created).getTime() - new Date(categoryZ.created).getTime(),
  );

  return sortedCategoriesByCreationDate[0];
};

const getActiveDaysCountForCategory = (category: Category): number => {
  const hoursInADay = 24;
  const minutesInAnHour = 60;
  const secondsInAMinute = 60;
  const millisecondsInASecond = 1000;
  const dayAsMillisecond = hoursInADay * minutesInAnHour * secondsInAMinute * millisecondsInASecond;

  return Math.round((new Date().getTime() - new Date(category.created).getTime()) / dayAsMillisecond);
};

export const hasUserB2CLegacyFeatures = (userFeatures?: UserFeatures): boolean =>
  userFeatures?.webAppCallInEnabled === true &&
  userFeatures?.webAppCallOutEnabled === false &&
  userFeatures?.webAppSmsInEnabled === true &&
  userFeatures?.webAppSmsOutEnabled === true;

export const getUserGTMDataLayerVariables = ({
  categories,
  creditCards,
  devices,
  integrations,
  calls,
}: GetUserGTMDataLayerVariablesProps): GetUserGTMDataLayerVariablesReturn => {
  const userAgent = getUserAgentData();

  // Categories
  const oldestCategory = getOldestCategory(categories);
  const daysUserLifetime = oldestCategory !== undefined ? getActiveDaysCountForCategory(oldestCategory) : 0;
  const countCategoriesActive = categories.filter((category) => !isCategoryExpired(category)).length;

  // Credit Cards
  const countCreditCardsExpired = creditCards.filter((creditCard) =>
    isCreditCardExpired({
      year: creditCard.expiryYear,
      month: creditCard.expiryMonth,
    }),
  ).length;
  const countCreditCardsActive = creditCards.length - countCreditCardsExpired;

  // Devices
  const countDevicesConnected = devices.filter((device) => device.loggedIn).length;

  // Integrations
  const namesIntegrationsList = integrations
    .map((integration) => integration.name || integration.id)
    .filter(
      (integrationNameOrId): integrationNameOrId is string =>
        isString(integrationNameOrId) && integrationNameOrId.length > 0,
    )
    .sort(undefined);
  const namesIntegrations = namesIntegrationsList.join(', ');
  const countIntegrationsActive = namesIntegrationsList.length;

  // Calls
  const callAnswerMethod = (): string => {
    if (calls.onoffCallStatus === 'ENABLED') {
      return 'onoffCalls';
    }

    if (calls.callAnswerMethod === UserCallAnswerMethods.CALLBACK) {
      return 'callBack';
    }

    return 'voip';
  };
  const isCallCodecPCMA = getSIPCallsCapabilitiesHdCodecEnabledFromLocalStorage();

  return {
    webAppVersion: packageJson.version,
    browserName: userAgent.browser.name || '',
    browserVersion: userAgent.browser.version || '',
    daysUserLifetime,
    countCategoriesActive,
    countCreditCardsActive,
    countCreditCardsExpired,
    countDevicesConnected,
    countIntegrationsActive,
    namesIntegrations,
    callAnswerMethod: callAnswerMethod(),
    callIsCodecPCMA: `${isCallCodecPCMA}`,
  };
};
