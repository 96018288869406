import { FormattedMessage } from 'react-intl';

import { Button } from '@onoff/ui';

import { LINKS } from '@constants';

import styles from './NoCategories.module.scss';

type NoCategoriesProps = {
  isUserB2C: boolean;
};

const NoCategories: React.FC<NoCategoriesProps> = ({ isUserB2C }) => (
  <div className={styles.root}>
    <h2 className={styles.header}>
      <FormattedMessage id={`FeatureBlockSplash.no_categories_header_${isUserB2C ? 'b2c' : 'b2b'}`} />
    </h2>
    {isUserB2C && (
      <Button
        size="medium"
        variant="solid"
        colorScheme="brand-b2c"
        onClick={() => {
          window.open(LINKS.WEBSITE_WSJ, '_blank');
        }}
      >
        <span>
          <FormattedMessage id="FeatureBlockSplash.no_categories_cta" />
        </span>
      </Button>
    )}
  </div>
);

export default NoCategories;
