import React from 'react';

import { StyleableComponent } from 'types';

const ExpandLess: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53_1075)">
      <path
        d="M11.2907 8.71047L6.7007 13.3005C6.3107 13.6905 6.3107 14.3205 6.7007 14.7105C7.0907 15.1005 7.7207 15.1005 8.1107 14.7105L12.0007 10.8305L15.8807 14.7105C16.2707 15.1005 16.9007 15.1005 17.2907 14.7105C17.6807 14.3205 17.6807 13.6905 17.2907 13.3005L12.7007 8.71047C12.3207 8.32047 11.6807 8.32047 11.2907 8.71047Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_1075">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ExpandLess;
