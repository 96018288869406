import { SIP } from '@constants';
import { useWebAppSelector, selectSIPDataCallsCapabilities } from '@redux';

import { UseSIPMediaConstraintsReturn } from '../../../types';

export const useSIPMediaConstraints = (): UseSIPMediaConstraintsReturn => {
  const { isCallsWebRTCAdvancedMediaConstraintsEnabled } = useWebAppSelector(selectSIPDataCallsCapabilities);

  const constraints = isCallsWebRTCAdvancedMediaConstraintsEnabled
    ? SIP.CONFIGURATIONS.MEDIA_CONSTRAINTS_ADVANCED
    : SIP.CONFIGURATIONS.MEDIA_CONSTRAINTS;

  return { constraints };
};
