import React from 'react';

import { StyleableComponent } from 'types';

const IncomingCall: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3 4.71047C18.91 4.32047 18.28 4.32047 17.89 4.71047L7 15.5905V10.0005C7 9.45047 6.55 9.00047 6 9.00047C5.45 9.00047 5 9.45047 5 10.0005V18.0005C5 18.5505 5.45 19.0005 6 19.0005H14C14.55 19.0005 15 18.5505 15 18.0005C15 17.4505 14.55 17.0005 14 17.0005H8.41L19.3 6.11047C19.68 5.73047 19.68 5.09047 19.3 4.71047Z"
      fill="currentColor"
    />
  </svg>
);

export default IncomingCall;
