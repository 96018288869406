import { DataLayerVariables, GAEventKey, UserTypes } from 'types';

import { getUserGTMDataLayerVariables } from 'helpers';

import {
  selectCategories,
  selectCreditCardsData,
  selectIntegrationsActiveIntegrations,
  selectMultiDeviceConnectedDevices,
  selectUserData,
} from '../../selectors';
import { ThunkResult } from '../../types';

/**
 * @description: This function is to resend the datalayer variables. Please note: the selector's data
 * should be there before you call this function, otherwise the function will send useless data
 */
export const analyticsResendDataLayerVariables =
  (
    eventKey: GAEventKey = GAEventKey.RESEND_DATALAYER_VARIABLES,
    variables: Partial<DataLayerVariables> = {},
  ): ThunkResult<void> =>
  (_dispatch, getState, services): void => {
    const user = selectUserData(getState());
    const userDataLayerVariables = getUserGTMDataLayerVariables({
      categories: selectCategories(getState()),
      devices: selectMultiDeviceConnectedDevices(getState()),
      creditCards: selectCreditCardsData(getState()),
      integrations: selectIntegrationsActiveIntegrations(getState()),
      calls: {
        callAnswerMethod: user.callAnswerMethod,
        onoffCallStatus: user.onOffCallStatus,
      },
    });

    services.analyticsService.pushToDataLayer({
      event: eventKey,
      variables: {
        // User
        userID: user.userId,
        userType: user.b2bUser ? UserTypes.B2B : UserTypes.B2C,
        accountType: user.accountType,
        countryIsoCode: user.countryIsoCode,
        lifetimeDays: userDataLayerVariables.daysUserLifetime,
        syncContacts: `${user.syncContacts}`,
        // Calls
        callAnswerMethod: userDataLayerVariables.callAnswerMethod,
        callRecording_status: `${user.callRecording?.enabled === true}`,
        callIsCodecPCMA: userDataLayerVariables.callIsCodecPCMA,
        // Categories
        nb_activeCategories: userDataLayerVariables.countCategoriesActive,
        // App/Browser
        webAppVersion: userDataLayerVariables.webAppVersion,
        browserName: userDataLayerVariables.browserName,
        browserVersion: userDataLayerVariables.browserVersion,
        // Multi-Device Management
        nb_multidevice: userDataLayerVariables.countDevicesConnected,
        // Credit Cards
        cc_expired_count: userDataLayerVariables.countCreditCardsExpired,
        cc_active_count: userDataLayerVariables.countCreditCardsActive,
        // Integrations
        nb_activeIntegrations: userDataLayerVariables.countIntegrationsActive,
        list_activeIntegrations: userDataLayerVariables.namesIntegrations,
        ...variables,
      },
    });
  };
