import { TestableComponent } from 'types';

import { SIPDataCall } from '@redux/types';

export type ModalsCallTransferProps = TestableComponent & {
  callData: SIPDataCall;
  isOpen: boolean;
  onClose: () => void;
};

export enum CallTransferError {
  GENERIC = 'GENERIC',
  INVALID_NUMBER = 'INVALID_NUMBER',
}
