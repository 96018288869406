import { SESSIONSTORAGE_KEYS } from '@constants';
import { ApplicationPaths } from 'routes';

export const checkoutGetCallbackPathFromStorage = (): string | null =>
  window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.CHECKOUT_CALLBACK_REDIRECTION_PATH);

export const checkoutSetCallbackPathToStorage = (path: ApplicationPaths): void =>
  window.sessionStorage.setItem(SESSIONSTORAGE_KEYS.CHECKOUT_CALLBACK_REDIRECTION_PATH, path);

export const checkoutRemoveCallbackPathFromStorage = (): void =>
  window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.CHECKOUT_CALLBACK_REDIRECTION_PATH);
