import React from 'react';

import { StyleableComponent } from 'types';

const Favorite: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53_1013)">
      <path
        d="M12.0003 17.2698L16.1503 19.7798C16.9103 20.2398 17.8403 19.5598 17.6403 18.6998L16.5403 13.9798L20.2103 10.7998C20.8803 10.2198 20.5203 9.11977 19.6403 9.04977L14.8103 8.63977L12.9203 4.17977C12.5803 3.36977 11.4203 3.36977 11.0803 4.17977L9.19032 8.62977L4.36032 9.03977C3.48032 9.10977 3.12032 10.2098 3.79032 10.7898L7.46032 13.9698L6.36032 18.6898C6.16032 19.5498 7.09032 20.2298 7.85032 19.7698L12.0003 17.2698Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_1013">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Favorite;
