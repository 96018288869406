import React from 'react';

import { StyleableComponent } from 'types';

const OnOffCalls: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3238_10119)">
      <rect
        width="50"
        height="50"
        fill="#32A8FF"
      />
      <path
        d="M0 8.87784C0 3.97475 3.97474 0 8.87784 0L41.1222 0C46.0253 0 50 3.97474 50 8.87784V41.1222C50 46.0253 46.0253 50 41.1222 50H8.87784C3.97475 50 0 46.0253 0 41.1222L0 8.87784Z"
        fill="#32A8FF"
      />
      <g clipPath="url(#clip1_3238_10119)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2812 25.0006C13.2812 18.5327 18.5315 13.282 25.0001 13.282V13.2812C31.4678 13.2812 36.7188 18.5322 36.7188 25.0006C36.7188 31.4676 31.4678 36.7187 24.9992 36.7187C18.5315 36.7187 13.2812 31.4676 13.2812 25.0006ZM28.9879 28.9669C30.4579 27.4927 31.3423 26.2713 31.7962 25.1784L31.7952 25.1791C32.0729 24.5432 32.2229 23.8828 32.1937 23.1967C32.2154 21.7576 31.649 20.3722 30.6242 19.3565C29.6128 18.3461 28.233 17.7867 26.8003 17.806C26.1074 17.7787 25.4177 17.9175 24.7903 18.2123C23.6979 18.6707 22.4799 19.5603 21.0107 21.0349C19.5419 22.4839 18.6561 23.7297 18.2024 24.8237C17.9248 25.4585 17.7747 26.1198 17.8046 26.781C17.7775 28.2281 18.3442 29.6234 19.3735 30.6458C20.3912 31.6628 21.7818 32.2229 23.2245 32.1961C23.9115 32.2208 24.5733 32.0943 25.2335 31.7884C26.3007 31.3053 27.5439 30.4164 28.9879 28.9669ZM23.185 23.0207C23.185 25.1156 24.8853 26.8159 26.981 26.8159C29.0754 26.8159 30.7773 25.1156 30.7773 23.0207C30.7773 20.9243 29.0758 19.2235 26.981 19.2235C24.8853 19.2235 23.185 20.9247 23.185 23.0207Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3238_10119">
        <rect
          width="50"
          height="50"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3238_10119">
        <rect
          width="28.125"
          height="28.125"
          fill="white"
          transform="translate(10.9375 10.9375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default OnOffCalls;
