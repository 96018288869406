import classNames from 'classnames';
import React from 'react';

import { FlagCommonSizes } from '../../enums';
import { useCountryFlag } from '../../hooks';
import { FlagRoundProps } from '../../types';

import styles from './FlagRound.module.scss';

const FlagRound: React.FC<FlagRoundProps> = ({ country = 'FR', size = FlagCommonSizes.DEFAULT, className }) => {
  const { flag, onError } = useCountryFlag(country);

  return (
    <img
      className={classNames(
        styles.root,
        {
          [styles[`size_${size}`]]: true,
        },
        className,
      )}
      alt={flag.alt}
      src={flag.src}
      onError={onError}
    />
  );
};

export default FlagRound;
