import { createSelector } from '@reduxjs/toolkit';

import { MULTI_DEVICE } from '@constants';

import {
  SelectMultiDeviceConnectedDevices,
  SelectMultiDeviceCurrentDevice,
  SelectMultiDeviceDevices,
  SelectMultiDeviceIsMaxActiveConnectionReached,
  SelectMultiDeviceOtherConnectedDevices,
  SelectMultiDeviceStatuses,
} from '../../types';

export const selectMultiDeviceStatuses: SelectMultiDeviceStatuses = (state) => state.multiDevice.statuses;

export const selectMultiDeviceDevices: SelectMultiDeviceDevices = (state) => state.multiDevice.devices;

export const selectMultiDeviceCurrentDevice: SelectMultiDeviceCurrentDevice = createSelector(
  [selectMultiDeviceDevices],
  (devices) => devices.find((device) => device.isOwnDevice),
);

export const selectMultiDeviceConnectedDevices: SelectMultiDeviceConnectedDevices = createSelector(
  [selectMultiDeviceDevices],
  (devices) => devices.filter((device) => device.loggedIn),
);

export const selectMultiDeviceOtherConnectedDevices: SelectMultiDeviceOtherConnectedDevices = createSelector(
  [selectMultiDeviceConnectedDevices, selectMultiDeviceCurrentDevice],
  (connectedDevices, currentDevice) => {
    if (currentDevice === undefined) {
      return connectedDevices;
    }

    return connectedDevices.filter((device) => device.deviceId !== currentDevice.deviceId);
  },
);

export const selectMultiDeviceIsMaxActiveConnectionReached: SelectMultiDeviceIsMaxActiveConnectionReached =
  createSelector([selectMultiDeviceConnectedDevices], (devices) => devices.length > MULTI_DEVICE.MAX_CONNECTED_DEVICES);
