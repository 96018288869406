import { isB2BProject } from 'helpers';

import iconB2B from 'assets/images/notifications/notifications-browser-icon-b2b.png';
import iconB2C from 'assets/images/notifications/notifications-browser-icon-b2c.png';
import { useUserCapabilities } from 'hooks';

const useNotificationsBrowserIcon = (): string => {
  const { isUserB2B } = useUserCapabilities();

  return isUserB2B || isB2BProject() ? iconB2B : iconB2C;
};

export default useNotificationsBrowserIcon;
