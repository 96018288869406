import { Breadcrumb } from '@sentry/browser';

import { SIPEvent } from '@redux/types';

export interface SentryServiceCaptureExceptionScope {
  /**
   * Having a Scope tag in Sentry for better filtering
   * [**KEY** | *VALUE*]
   */
  scopeKey: string;
  /**
   * Having a Scope tag in Sentry for better filtering
   * [*KEY* | **VALUE**]
   */
  scopeValue: string;
}

export type SentryServiceCaptureExceptionScopeExtras = Record<string, unknown>;

export type SentryServiceCaptureExceptionProps = {
  error: string | Error;
  scope?: SentryServiceCaptureExceptionScope;
  extras?: SentryServiceCaptureExceptionScopeExtras;
};

export enum SentryServiceBreadcrumbMessage {
  EMAIL_UNSUBSCRIPTION_REQUEST = 'EMAIL_UNSUBSCRIPTION_REQUEST',
  CALL_TRANSFER_REQUEST = 'CALL_TRANSFER_REQUEST',
}

export type SentryServiceAddBreadcrumbProps = Breadcrumb & {
  message: SentryServiceBreadcrumbMessage | SIPEvent;
};

export type SentryService = {
  init: () => void;
  captureException: (props: SentryServiceCaptureExceptionProps) => void;
  addBreadcrumb: (props: SentryServiceAddBreadcrumbProps) => void;
};
