import { useCallback } from 'react';

import {
  useWebAppDispatch,
  sipSetDataCallStatusWebRTCByWebRTCIdHandler,
  sipSetDataCallTimestampTerminatedByWebRTCIdHandler,
  sipSetDataCallStatusViewByWebRTCIdHandler,
} from '@redux';
import { SIPWebRTCOriginators, SIPDataCallStatusesWebRTC, SIPDataCallStatusesView } from '@redux/types';

import { sipDebugConsoleLogger } from '../../../../../../../helpers';
import { useSIPStatistics } from '../../../../../../useSIPStatistics';

import { OnEnded, UseWebRTCEnded } from './types';

export const useWebRTCEnded: UseWebRTCEnded = () => {
  const dispatch = useWebAppDispatch();

  const { sipSendLogToGTMCallEnded } = useSIPStatistics();

  const onEnded: OnEnded = useCallback(
    ({
      event: { cause, message, originator: eventOriginator },
      idWebRTC,
      idTransaction,
      originatorWebRTC,
      timeSessionStarted,
      timeSessionEnded,
      webRTCMOSStopAndGetResults,
    }) => {
      sipDebugConsoleLogger('SIP WebRTC: SESSION ENDED: ', { cause, message, eventOriginator });

      // Set WebRTC Session Status as TERMINATED (Call is established, and successfully ended)
      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusWebRTC: SIPDataCallStatusesWebRTC.TERMINATED,
        }),
      );

      // Set WebRTC Session Terminated/Completed Timestamp
      dispatch(
        sipSetDataCallTimestampTerminatedByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallTimestampCallTerminated: new Date().getTime(),
        }),
      );

      // Then, Set WebRTC Session Status as COMPLETED (For the Call Outcomes UI State)
      dispatch(
        sipSetDataCallStatusViewByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusView: SIPDataCallStatusesView.COMPLETED,
        }),
      );

      // Get MOS
      const {
        callMOS,
        callMOSJitterMin,
        callMOSJitterMax,
        callMOSJitterAverage,
        callMOSLatencyAverage,
        callMOSPacketsLost,
        callMOSDescription,
        callMOSNetworkType,
        callMOSNetworkChangedDuringTheCall,
      } = webRTCMOSStopAndGetResults({ idWebRTC });

      // Send Call Statistics to GTM
      sipSendLogToGTMCallEnded({
        propTransactionId: idTransaction,
        propOriginatorInit: originatorWebRTC,
        propOriginatorEvent: eventOriginator as unknown as SIPWebRTCOriginators,
        propStatus: `${cause}`,
        propTimeStart: timeSessionStarted as unknown as string,
        propTimeEnd: timeSessionEnded as unknown as string,
        propCallMOS: callMOS,
        propCallMOSJitterMin: callMOSJitterMin,
        propCallMOSJitterMax: callMOSJitterMax,
        propCallMOSJitterAverage: callMOSJitterAverage,
        propCallMOSLatencyAverage: callMOSLatencyAverage,
        propCallMOSPacketsLost: callMOSPacketsLost,
        propCallMOSDescription: callMOSDescription,
        propCallMOSNetworkType: callMOSNetworkType,
        propCallMOSNetworkChangedDuringTheCall: callMOSNetworkChangedDuringTheCall,
      });
    },
    [dispatch, sipSendLogToGTMCallEnded],
  );

  return { onEnded };
};
