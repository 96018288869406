import { createSelector } from '@reduxjs/toolkit';

import { SelectBrowserTabsState } from '../../types';

export const selectBrowserTabsState: SelectBrowserTabsState = (state) => state.browserTabs;

export const selectIsCurrentBrowserTabActive = createSelector(
  [selectBrowserTabsState],
  (browserTabsState) => browserTabsState.isActiveTab,
);
