import React, { useState } from 'react';

import { Person } from '@onoff/icons';
import { IconButton } from '@onoff/ui';

import { useWebAppSelector, selectThreadsActiveThread } from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';
import { ModalsRecipientInfo } from 'components/Modals';

const InfoTopBarActionsFeaturesSMSGroup: React.FC = () => {
  const threadActive = useWebAppSelector(selectThreadsActiveThread);

  const [stateModalMessageGroupVisible, setStateModalMessageGroupVisible] = useState<boolean>(false);

  const onClickHandlerMessagesGroup = (): void => {
    setStateModalMessageGroupVisible(!stateModalMessageGroupVisible);
  };

  return (
    <>
      <IconButton
        onClick={onClickHandlerMessagesGroup}
        {...testGetTestingAttributes(testIds.infoTopBarActionsButtonMessagesGroup)}
      >
        <Person />
      </IconButton>
      {threadActive ? (
        <ModalsRecipientInfo
          isOpen={stateModalMessageGroupVisible}
          onClose={onClickHandlerMessagesGroup}
          thread={threadActive}
        />
      ) : null}
    </>
  );
};

export default InfoTopBarActionsFeaturesSMSGroup;
