import { Purchase, PurchaseReceiptsListResponseSubscription } from 'types';
import { PurchaseReceiptsListResponseSubscriptionAppStore } from 'types/services/purchase/receipts';

import { SESSIONSTORAGE_KEYS } from '@constants';

interface FormatPurchasePriceProps {
  price: number;
  currencyCode: string;
  locale?: string;
}

export const convertPurchaseCurrencyCodeToSymbol = (currencyCode: string): string => {
  const codes: Record<string, string> = {
    eur: '€',
    gbp: '£',
    usd: '$',
  };
  return codes[currencyCode.toLocaleLowerCase('en')] || Object.values(codes)[0];
};

export const formatPurchasePrice = ({ price, currencyCode, locale = 'fr' }: FormatPurchasePriceProps): string =>
  `${price.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}${convertPurchaseCurrencyCodeToSymbol(currencyCode)}`;

export const getPurchaseSessionStorageData = (): Pick<Purchase, 'virtualPhoneNumber' | 'categoryId' | 'email'> => {
  const virtualPhoneNumber = window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.PURCHASE_PHONE_NUMBER) || '';
  const categoryId = window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.PURCHASE_CATEGORY_ID) || '';
  const email = window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.PURCHASE_EMAIL) || '';

  return {
    virtualPhoneNumber,
    categoryId,
    email,
  };
};

export const setPurchaseSessionStorageData = ({
  virtualPhoneNumber,
  categoryId,
  email,
}: Pick<Purchase, 'virtualPhoneNumber' | 'categoryId' | 'email'>): void => {
  window.sessionStorage.setItem(SESSIONSTORAGE_KEYS.PURCHASE_PHONE_NUMBER, virtualPhoneNumber);
  window.sessionStorage.setItem(SESSIONSTORAGE_KEYS.PURCHASE_CATEGORY_ID, categoryId);
  window.sessionStorage.setItem(SESSIONSTORAGE_KEYS.PURCHASE_EMAIL, email);
};

export const removePurchaseSessionStorageData = (): void => {
  window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.PURCHASE_PHONE_NUMBER);
  window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.PURCHASE_CATEGORY_ID);
  window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.PURCHASE_EMAIL);
};

export const purchaseReceiptsShouldShowSubscription = (
  subscription: PurchaseReceiptsListResponseSubscription,
): boolean => {
  const subscriptionAppStore = subscription.appStore as PurchaseReceiptsListResponseSubscriptionAppStore | undefined;
  const subscriptionAppStoresThatShouldNotBeShown = [
    PurchaseReceiptsListResponseSubscriptionAppStore.APPLE,
    PurchaseReceiptsListResponseSubscriptionAppStore.GOOGLE,
  ];

  if (subscriptionAppStore === undefined) {
    return false;
  }

  if (subscriptionAppStoresThatShouldNotBeShown.includes(subscriptionAppStore)) {
    return false;
  }

  return true;
};
