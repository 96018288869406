import { useState } from 'react';

import { UseInputState, UseInputOptions } from '../types';

const useInputState = (options: UseInputOptions): UseInputState => {
  const { value = '', onChange } = options;
  const [hasFocus, setHasFocus] = useState(false);
  const [isEmpty, setIsEmpty] = useState(value.length === 0);
  const [hasHover, setHasHover] = useState(false);

  return {
    hasHover,
    hasFocus,
    isEmpty,
    value,
    onChangeHandler: (inputValue) => {
      setIsEmpty(inputValue.length === 0);
      if (onChange !== undefined) {
        onChange(inputValue);
      }
    },
    onBlur: () => setHasFocus(false),
    onFocus: () => setHasFocus(true),
    onMouseOver: () => setHasHover(true),
    onMouseLeave: () => setHasHover(false),
  };
};

export default useInputState;
