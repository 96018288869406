import { useEffect } from 'react';

import {
  useWebAppDispatch,
  sipSetDataCallsCapabilitiesIncomingRingtoneDisabledHandler,
  sipSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabledHandler,
} from '@redux';

export const useSIPWebAppValidateCallCapabilities = (): void => {
  const dispatch = useWebAppDispatch();

  const callbackUpdateReduxStoreByWindowStorageChangesHandler = (): void => {
    dispatch(sipSetDataCallsCapabilitiesIncomingRingtoneDisabledHandler());

    dispatch(sipSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabledHandler());

    // https://onoffapp.atlassian.net/browse/WAP-3267
    // dispatch(sipSetDataCallsCapabilitiesHdCodecEnabledHandler());
  };

  useEffect(callbackUpdateReduxStoreByWindowStorageChangesHandler, [dispatch]);
};
