import React from 'react';

const CreditCardIconUnionpay: React.FC = () => (
  <svg
    width="50"
    height="35"
    viewBox="0 0 50 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.8192 5.25722L33.2906 5.25476C33.2894 5.25476 33.2881 5.25476 33.2881 5.25476C33.2808 5.25476 33.2736 5.25608 33.2664 5.25608C31.9573 5.29629 30.327 6.34903 30.0301 7.64714L25.5239 27.64C25.227 28.9502 26.0348 30.0164 27.3343 30.036H37.3445C38.6242 29.9725 39.8677 28.9319 40.1596 27.6485L44.6659 7.65551C44.9676 6.33196 44.1403 5.25722 42.8192 5.25722Z"
      fill="#01798A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.524 27.64L30.0302 7.64716C30.3272 6.34905 31.9575 5.29631 33.2665 5.2561L29.4779 5.25363L22.6521 5.25232C21.3395 5.27924 19.6879 6.33936 19.3911 7.64716L14.8836 27.64C14.5855 28.9502 15.3945 30.0164 16.6932 30.036H27.3344C26.0349 30.0164 25.2271 28.9502 25.524 27.64Z"
      fill="#024381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8835 27.64L19.391 7.64714C19.6878 6.33934 21.3394 5.27922 22.6521 5.2523L13.9078 5.25C12.5878 5.25 10.8954 6.32227 10.5937 7.64714L6.08621 27.64C6.05878 27.762 6.04356 27.8815 6.03448 27.9986V28.3695C6.12272 29.3246 6.85018 30.0201 7.89586 30.036H16.6932C15.3945 30.0163 14.5855 28.9502 14.8835 27.64Z"
      fill="#DD0228"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8364 19.8203H23.0019C23.154 19.8203 23.2563 19.7692 23.3042 19.6679L23.7343 19.0226H24.8858L24.6457 19.447H26.0264L25.8512 20.0973H24.2083C24.0191 20.3827 23.7861 20.5169 23.5061 20.501H22.6503L22.8364 19.8203H22.8364ZM22.6474 20.7524H25.6724L25.4796 21.4588H24.2631L24.0774 22.1406H25.2612L25.0684 22.847H23.8846L23.6096 23.8545C23.5415 24.0229 23.6311 24.0987 23.8767 24.0815H24.8415L24.6628 24.7379H22.8105C22.4594 24.7379 22.3389 24.5365 22.4491 24.1328L22.8007 22.847H22.044L22.2362 22.1406H22.993L23.1785 21.4588H22.4551L22.6474 20.7524H22.6474ZM27.4755 19.0177L27.4278 19.4313C27.4278 19.4313 27.9985 19.0017 28.5167 19.0017H30.4317L29.6994 21.6599C29.6386 21.9638 29.3782 22.1149 28.9182 22.1149H26.7477L26.2393 23.9814C26.21 24.0815 26.2514 24.1328 26.3609 24.1328H26.788L26.631 24.7121H25.5453C25.1285 24.7121 24.9552 24.5865 25.0239 24.3341L26.4606 19.0177H27.4755H27.4755ZM29.0973 19.7692H27.388L27.1836 20.4864C27.1836 20.4864 27.4682 20.2803 27.9439 20.273C28.4183 20.2655 28.9598 20.273 28.9598 20.273L29.0973 19.7692ZM28.478 21.433C28.6044 21.4502 28.6751 21.4001 28.6836 21.2817L28.7882 20.9037H27.0763L26.9328 21.433H28.478ZM27.3233 22.2919H28.31L28.2917 22.72H28.5544C28.6871 22.72 28.7529 22.6774 28.7529 22.5933L28.8307 22.3163H29.6508L29.5412 22.72C29.4486 23.0567 29.2031 23.2324 28.804 23.2496H28.2784L28.2759 23.9814C28.2663 24.0986 28.372 24.1584 28.5897 24.1584H29.0837L28.9243 24.7379H27.7394C27.4072 24.7537 27.2444 24.595 27.2478 24.2584L27.3233 22.2919V22.2919Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3666 15.4764C15.2328 16.1339 14.9225 16.639 14.4413 16.9976C13.9645 17.3502 13.3494 17.527 12.5964 17.527C11.8877 17.527 11.3682 17.3465 11.0367 16.9841C10.8067 16.7267 10.6923 16.3998 10.6923 16.0045C10.6923 15.8411 10.7118 15.6654 10.7508 15.4764L11.5531 11.5972H12.7649L11.9735 15.4325C11.9491 15.5386 11.9394 15.6374 11.9407 15.7265C11.9394 15.9229 11.988 16.0839 12.0866 16.2095C12.2301 16.3962 12.4631 16.4889 12.7874 16.4889C13.1602 16.4889 13.4674 16.3974 13.7059 16.2132C13.9444 16.0302 14.1001 15.7704 14.17 15.4325L14.9639 11.5972H16.1695L15.3666 15.4764Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4555 13.9503H21.4045L20.6611 17.4124H19.714L20.4555 13.9503ZM20.7541 12.689H21.7116L21.5328 13.5293H20.5754L20.7541 12.689Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2446 17.1488C21.9964 16.911 21.8711 16.5902 21.8699 16.1827C21.8699 16.1131 21.8741 16.0339 21.8833 15.9472C21.8924 15.8594 21.904 15.774 21.9204 15.6947C22.0329 15.1324 22.2726 14.6859 22.6418 14.3565C23.0104 14.0259 23.4552 13.86 23.9759 13.86C24.4023 13.86 24.7405 13.9796 24.9881 14.2186C25.2356 14.459 25.3597 14.7834 25.3597 15.1959C25.3597 15.2665 25.3543 15.3483 25.3451 15.4361C25.3342 15.5251 25.3209 15.6105 25.3055 15.6947C25.1955 16.2485 24.9565 16.6902 24.5873 17.0135C24.218 17.3392 23.7745 17.5013 23.2574 17.5013C22.8292 17.5013 22.4922 17.3843 22.2446 17.1488M24.0532 16.4633C24.2205 16.2815 24.3403 16.0057 24.4133 15.6386C24.4243 15.5813 24.4339 15.5215 24.44 15.4617C24.446 15.4031 24.4485 15.3483 24.4485 15.2983C24.4485 15.0848 24.3943 14.9189 24.2855 14.8018C24.1773 14.6834 24.0234 14.6249 23.8245 14.6249C23.5616 14.6249 23.3475 14.7175 23.1797 14.903C23.0105 15.0885 22.8907 15.369 22.8152 15.7423C22.8049 15.7996 22.7964 15.857 22.7885 15.9131C22.7825 15.9704 22.7807 16.0241 22.7818 16.0729C22.7818 16.2852 22.836 16.4487 22.9449 16.5645C23.053 16.6804 23.2063 16.7377 23.4078 16.7377C23.6718 16.7377 23.8859 16.6462 24.0532 16.4633"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.517 19.8497L31.7458 19.0422H32.9028L32.8529 19.3385C32.8529 19.3385 33.4441 19.0422 33.8699 19.0422C34.2958 19.0422 35.3005 19.0422 35.3005 19.0422L35.0731 19.8497H34.8481L33.7689 23.6583H33.9939L33.7798 24.4146H33.5548L33.4611 24.7428H32.3407L32.4341 24.4146H30.2237L30.4391 23.6583H30.6606L31.7408 19.8497H31.517H31.517ZM32.7652 19.8497L32.4708 20.8805C32.4708 20.8805 32.9746 20.6866 33.4088 20.6317C33.5048 20.2718 33.6302 19.8497 33.6302 19.8497H32.7652L32.7652 19.8497ZM32.3344 21.3636L32.0389 22.4432C32.0389 22.4432 32.5973 22.1675 32.9805 22.1443C33.0911 21.7271 33.2019 21.3636 33.2019 21.3636H32.3344V21.3636ZM32.5509 23.6583L32.7724 22.8751H31.9088L31.6862 23.6583H32.5509ZM35.3492 18.9922H36.4369L36.483 19.3946C36.4758 19.4971 36.5366 19.546 36.6656 19.546H36.8577L36.6633 20.2279H35.8638C35.5585 20.2437 35.4016 20.1267 35.387 19.8741L35.3492 18.9922ZM35.0305 20.4548H38.5538L38.347 21.1868H37.2253L37.0329 21.8674H38.1536L37.9455 22.5981H36.6974L36.415 23.0264H37.0259L37.167 23.8839C37.1839 23.9693 37.2593 24.0108 37.3883 24.0108H37.578L37.3787 24.717H36.707C36.359 24.7342 36.1791 24.6171 36.1644 24.3646L36.0025 23.5815L35.4466 24.4146C35.3152 24.65 35.1132 24.7599 34.8408 24.7427H33.8152L34.0147 24.0363H34.3347C34.4661 24.0363 34.5755 23.9778 34.674 23.8595L35.544 22.5981H34.4223L34.6302 21.8674H35.8469L36.0403 21.1868H34.8225L35.0305 20.4548Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4828 13.949H17.3386L17.2408 14.4492L17.3636 14.3065C17.641 14.0089 17.978 13.8612 18.3758 13.8612C18.736 13.8612 18.9957 13.9662 19.1587 14.1772C19.3193 14.3882 19.3631 14.6798 19.2871 15.0543L18.8156 17.4136H17.936L18.3618 15.2751C18.4057 15.0543 18.3935 14.8896 18.3259 14.7834C18.2591 14.6773 18.1313 14.6249 17.947 14.6249C17.7207 14.6249 17.5303 14.6956 17.3752 14.8359C17.2194 14.9774 17.1166 15.1739 17.0661 15.4239L16.6738 17.4136H15.7924L16.4828 13.949Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2975 13.949H27.1541L27.0568 14.4492L27.1783 14.3065C27.4559 14.0089 27.7941 13.8612 28.1907 13.8612C28.5508 13.8612 28.8111 13.9662 28.9728 14.1772C29.1324 14.3882 29.1786 14.6798 29.1006 15.0543L28.631 17.4136H27.7502L28.1761 15.2751C28.2198 15.0543 28.2077 14.8896 28.1408 14.7834C28.0714 14.6773 27.946 14.6249 27.7625 14.6249C27.5361 14.6249 27.3464 14.6956 27.1894 14.8359C27.0336 14.9774 26.9303 15.1739 26.8817 15.4239L26.4875 17.4136H25.6072L26.2975 13.949"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.5315 11.802H33.0182C33.4964 11.802 33.8662 11.9107 34.1204 12.124C34.3734 12.34 34.5001 12.6499 34.5001 13.0537V13.0658C34.5001 13.1426 34.495 13.2293 34.4878 13.3232C34.4756 13.4159 34.4597 13.5098 34.4392 13.6074C34.3297 14.1418 34.0753 14.5712 33.6825 14.8969C33.2882 15.2214 32.8212 15.3849 32.2835 15.3849H30.9499L30.5375 17.4136H29.3829L30.5315 11.802M31.1531 14.409H32.2592C32.5474 14.409 32.7761 14.3418 32.9429 14.2089C33.1084 14.0747 33.2179 13.8698 33.2786 13.5917C33.2883 13.5403 33.2943 13.494 33.3017 13.4514C33.3055 13.4111 33.3102 13.3708 33.3102 13.3319C33.3102 13.1329 33.2398 12.989 33.0985 12.8987C32.9575 12.8071 32.7361 12.7633 32.4294 12.7633H31.4901L31.1531 14.409"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.6682 18.0833C39.3032 18.8615 38.9553 19.3152 38.751 19.5263C38.5464 19.735 38.1415 20.2205 37.1657 20.1839L37.2497 19.5898C38.0707 19.3361 38.5148 18.1929 38.7679 17.6867L38.4662 13.9587L39.1013 13.9502H39.6341L39.6914 16.2888L40.6901 13.9502H41.7012L39.6682 18.0833"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.8409 14.232L36.4392 14.509C36.0195 14.1796 35.6363 13.9759 34.8967 14.3199C33.8892 14.7883 33.0474 18.381 35.8212 17.1976L35.9794 17.3855L37.0707 17.4135L37.7873 14.1491L36.8409 14.232M36.2204 16.0168C36.0451 16.5352 35.6536 16.878 35.347 16.7804C35.0404 16.6853 34.9309 16.1851 35.1085 15.6654C35.2836 15.1457 35.6778 14.8042 35.9819 14.9018C36.2885 14.9969 36.3992 15.497 36.2204 16.0168Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.2882 5.26131L29.4779 5.25366L33.2665 5.27006C33.2737 5.27006 33.2809 5.26131 33.2882 5.26131"
      fill="#E02F41"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4779 5.27406L22.7044 5.25C22.6874 5.25 22.6697 5.25765 22.6521 5.26531L29.4779 5.27406"
      fill="#2E4F7D"
    />
  </svg>
);

export default CreditCardIconUnionpay;
