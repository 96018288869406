import React from 'react';

import { StyleableComponent } from 'types';

const VolumeOff: React.FC<StyleableComponent> = ({ className = '' }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.63 3.63039C3.24 4.02039 3.24 4.65039 3.63 5.04039L7.29 8.70039L7 9.00039H4C3.45 9.00039 3 9.45039 3 10.0004V14.0004C3 14.5504 3.45 15.0004 4 15.0004H7L10.29 18.2904C10.92 18.9204 12 18.4704 12 17.5804V13.4104L16.18 17.5904C15.69 17.9604 15.16 18.2704 14.58 18.5004C14.22 18.6504 14 19.0304 14 19.4204C14 20.1404 14.73 20.6004 15.39 20.3304C16.19 20.0004 16.94 19.5604 17.61 19.0204L18.95 20.3604C19.34 20.7504 19.97 20.7504 20.36 20.3604C20.75 19.9704 20.75 19.3404 20.36 18.9504L5.05 3.63039C4.66 3.24039 4.03 3.24039 3.63 3.63039ZM19 12.0004C19 12.8204 18.85 13.6104 18.59 14.3404L20.12 15.8704C20.68 14.7004 21 13.3904 21 12.0004C21 8.17039 18.6 4.89039 15.22 3.60039C14.63 3.37039 14 3.83039 14 4.46039V4.65039C14 5.03039 14.25 5.36039 14.61 5.50039C17.18 6.54039 19 9.06039 19 12.0004ZM10.29 5.71039L10.12 5.88039L12 7.76039V6.41039C12 5.52039 10.92 5.08039 10.29 5.71039ZM16.5 12.0004C16.5 10.2304 15.48 8.71039 14 7.97039V9.76039L16.48 12.2404C16.49 12.1604 16.5 12.0804 16.5 12.0004Z"
      fill="currentColor"
    />
  </svg>
);

export default VolumeOff;
