import { useEffect } from 'react';

import {
  useWebAppDispatch,
  useWebAppSelector,
  sipSetDataCallStatusWebRTCByWebRTCIdHandler,
  selectSIPDataCallByStatusWebRTCTerminate,
  selectSIPDataCallByStatusWebRTCReject,
} from '@redux';
import { SIPWebRTCStatusCodes, SIPWebRTCStatusReasons, SIPDataCallStatusesWebRTC } from '@redux/types';

import { sipAudioManagementAudioRemove } from '../../../../../../../../helpers';
import { RTCSessionCauses } from '../../../../../../../../types';

import { UseWebRTCSessionsTerminateOrRejectProps } from './types';

export const useWebRTCSessionsTerminateOrReject = ({
  propWebRTCSessions,
}: UseWebRTCSessionsTerminateOrRejectProps): void => {
  const dispatch = useWebAppDispatch();
  const { idWebRTCForTerminate } = useWebAppSelector(selectSIPDataCallByStatusWebRTCTerminate);
  const { idWebRTCForReject } = useWebAppSelector(selectSIPDataCallByStatusWebRTCReject);

  const canTerminate =
    idWebRTCForTerminate.length > 0 && propWebRTCSessions.find(({ id }) => id === idWebRTCForTerminate) !== undefined;
  const canReject =
    idWebRTCForReject.length > 0 && propWebRTCSessions.find(({ id }) => id === idWebRTCForReject) !== undefined;

  const callbackWebRTCSessionsTerminateOrRejectHandler = (): void => {
    if (canTerminate || canReject) {
      const endSession = propWebRTCSessions.find(({ id }) => id === (idWebRTCForTerminate || idWebRTCForReject));

      if (endSession && !endSession.isEnded()) {
        if (idWebRTCForTerminate) {
          endSession.terminate({
            status_code: SIPWebRTCStatusCodes.TERMINATE_487,
            cause: RTCSessionCauses.BYE,
            reason_phrase: RTCSessionCauses.BYE,
          });
          sipAudioManagementAudioRemove({ idWebRTC: idWebRTCForTerminate });
        }

        if (idWebRTCForReject) {
          endSession.terminate({
            status_code: SIPWebRTCStatusCodes.BUSY_486,
            cause: SIPWebRTCStatusReasons.BUSY_486,
            reason_phrase: SIPWebRTCStatusReasons.BUSY_486,
          });
          sipAudioManagementAudioRemove({ idWebRTC: idWebRTCForReject });
        }

        endSession.removeAllListeners();
      }
    }
  };

  const callbackWebRTCSessionsTerminateHandler = (): void => {
    if (canTerminate) {
      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTCForTerminate,
          propCallStatusWebRTC: SIPDataCallStatusesWebRTC.TERMINATED,
        }),
      );
    }
  };

  const callbackWebRTCSessionsRejectHandler = (): void => {
    if (canReject) {
      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTCForReject,
          propCallStatusWebRTC: SIPDataCallStatusesWebRTC.REJECTED,
        }),
      );
    }
  };

  useEffect(callbackWebRTCSessionsTerminateOrRejectHandler, [
    canTerminate,
    canReject,
    idWebRTCForTerminate,
    idWebRTCForReject,
    propWebRTCSessions,
    dispatch,
  ]);

  useEffect(callbackWebRTCSessionsTerminateHandler, [canTerminate, idWebRTCForTerminate, dispatch]);

  useEffect(callbackWebRTCSessionsRejectHandler, [canReject, idWebRTCForReject, dispatch]);
};
