import React from 'react';

import { CreditCardBrands } from 'types/network/purchase';

import { formatAndCleanCreditCardBrand } from '../../../helpers';
import { CreditCardProps } from '../../../types';

import CreditCardIconAmericanExpress from './CreditCardIconAmericanExpress';
import CreditCardIconDinersClubInternational from './CreditCardIconDinersClubInternational';
import CreditCardIconDiscover from './CreditCardIconDiscover';
import CreditCardIconJcb from './CreditCardIconJcb';
import CreditCardIconMaestro from './CreditCardIconMaestro';
import CreditCardIconMastercard from './CreditCardIconMastercard';
import CreditCardIconUnionpay from './CreditCardIconUnionpay';
import CreditCardIconVisa from './CreditCardIconVisa';

export type CreditCardIconProps = Partial<Pick<CreditCardProps, 'brand'>>;

const CreditCardIcon: React.FC<CreditCardIconProps | { brand: undefined }> = ({ brand }) => {
  if (!brand) {
    return null;
  }

  switch (formatAndCleanCreditCardBrand(brand)) {
    case CreditCardBrands.AMERICAN_EXPRESS:
      return <CreditCardIconAmericanExpress />;

    case CreditCardBrands.DINERS_CLUB_INTERNATIONAL:
      return <CreditCardIconDinersClubInternational />;

    case CreditCardBrands.DISCOVER:
      return <CreditCardIconDiscover />;

    case CreditCardBrands.JCB:
      return <CreditCardIconJcb />;

    case CreditCardBrands.MAESTRO:
      return <CreditCardIconMaestro />;

    case CreditCardBrands.MASTERCARD:
      return <CreditCardIconMastercard />;

    case CreditCardBrands.UNIONPAY:
      return <CreditCardIconUnionpay />;

    case CreditCardBrands.VISA:
      return <CreditCardIconVisa />;

    default:
      return null;
  }
};

export default CreditCardIcon;
