import {
  PushService,
  PushServiceGetPushesRequest,
  PushServiceGetPushesResponse,
  PushServiceDeletePushesRequest,
  PushServiceDeletePushesResponse,
} from 'types';

import { apiMobile } from 'api';

const pushService: PushService = {
  getPushes: (params: PushServiceGetPushesRequest = {}) =>
    apiMobile.get<PushServiceGetPushesResponse, PushServiceGetPushesRequest>('/push/get-pushes', params),
  deletePushes: (params: PushServiceDeletePushesRequest) =>
    apiMobile.post<PushServiceDeletePushesResponse, PushServiceDeletePushesRequest>('/push/delete-pushes', params),
};

export default pushService;
