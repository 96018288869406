import React from 'react';

import { StyleableComponent } from 'types';

const RecordingDelete: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M200 100C200 155.228 155.228 200 100 200C44.7715 200 0 155.228 0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 99.9998C187.368 148.252 148.252 187.368 100 187.368C51.7478 187.368 12.6316 148.252 12.6316 99.9998C12.6316 51.7475 51.7478 12.6313 100 12.6313C148.252 12.6313 187.368 51.7475 187.368 99.9998Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 99.9998C172.105 139.822 139.823 172.105 100 172.105C60.1774 172.105 27.8948 139.822 27.8948 99.9998C27.8948 60.1772 60.1774 27.8945 100 27.8945C139.823 27.8945 172.105 60.1772 172.105 99.9998Z"
      fill="#F3F4F5"
    />
    <mask
      id="mask0_13000_149274"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="200"
      height="200"
    >
      <path
        d="M0 0H200V200H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13000_149274)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.855 62.0785L59.2609 32.0167C57.509 31.3791 56.5913 29.4798 57.2121 27.7742L66.1977 3.08646C66.818 1.38216 68.7418 0.517117 70.4937 1.15476L153.088 31.2165C154.84 31.8542 155.757 33.7535 155.137 35.4578L146.151 60.1455C145.531 61.8511 143.607 62.7162 141.855 62.0785Z"
        fill="#C8CBCE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.855 62.0785L58.2609 32.0167C56.509 31.3791 55.5913 29.4798 56.2121 27.7742L65.1977 3.08646C65.818 1.38216 67.7418 0.517117 69.4937 1.15476L152.088 31.2165C153.84 31.8542 154.757 33.7535 154.137 35.4578L145.151 60.1455C144.531 61.8511 142.607 62.7162 140.855 62.0785Z"
        fill="#E4E5E7"
      />
      <g opacity="0.7">
        <g clipPath="url(#clip0_13000_149274)">
          <rect
            x="69.7019"
            y="10"
            width="15.8521"
            height="15.8521"
            rx="7.92606"
            transform="rotate(20 69.7019 10)"
            fill="white"
          />
        </g>
        <path
          d="M73.5676 17.4944L72.1399 21.4168C72.0311 21.7159 72.2943 22.0175 72.6072 21.947L76.4029 21.1075C76.6914 21.0453 76.8154 20.7046 76.6358 20.4677L74.2664 17.3886C74.072 17.1334 73.6764 17.1953 73.5676 17.4944Z"
          fill="#E4E5E7"
        />
        <rect
          x="87.5698"
          y="17.9917"
          width="62.476"
          height="0.932477"
          rx="0.466239"
          transform="rotate(20 87.5698 17.9917)"
          fill="white"
        />
        <rect
          x="86.073"
          y="23"
          width="41.0944"
          height="6.0611"
          rx="1.39872"
          transform="rotate(20 86.073 23)"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.677 69.49H18.2433C16.4524 69.49 15 67.8558 15 65.8391V36.6493C15 34.6342 16.4524 33 18.2433 33H102.677C104.468 33 105.92 34.6342 105.92 36.6493V65.8391C105.92 67.8558 104.468 69.49 102.677 69.49Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.677 70.49H17.2433C15.4524 70.49 14 68.8558 14 66.8391V37.6493C14 35.6342 15.4524 34 17.2433 34H101.677C103.468 34 104.92 35.6342 104.92 37.6493V66.8391C104.92 68.8558 103.468 70.49 101.677 70.49Z"
        fill="white"
      />
      <rect
        x="20"
        y="45.0977"
        width="15.2436"
        height="15.2436"
        rx="7.62182"
        fill="#D4D7DB"
      />
      <path
        d="M25.958 55.3173C26.3842 55.3173 26.7329 54.9686 26.7329 54.5424V50.668C26.7329 50.2418 26.3842 49.8931 25.958 49.8931C25.5318 49.8931 25.1831 50.2418 25.1831 50.668V54.5424C25.1831 54.9686 25.5318 55.3173 25.958 55.3173ZM28.2826 50.668V54.5424C28.2826 54.9686 28.6313 55.3173 29.0575 55.3173C29.4837 55.3173 29.8324 54.9686 29.8324 54.5424V50.668C29.8324 50.2418 29.4837 49.8931 29.0575 49.8931C28.6313 49.8931 28.2826 50.2418 28.2826 50.668Z"
        fill="white"
      />
      <rect
        x="38.9221"
        y="46.6899"
        width="60.0779"
        height="0.896685"
        rx="0.448343"
        fill="#E2E2E2"
      />
      <rect
        x="38.9221"
        y="46.6899"
        width="24.2105"
        height="0.896685"
        rx="0.448343"
        fill="#B1B1B1"
      />
      <rect
        x="39"
        y="53"
        width="39"
        height="5"
        rx="1.34503"
        fill="#EEEEEE"
      />
      <circle
        cx="64.0294"
        cy="46.6901"
        r="2.69006"
        fill="#B1B1B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7693 136.099C77.889 130.96 92.008 125.821 106.173 120.666C120.292 115.526 134.456 110.371 148.576 105.232C151.439 115.772 154.137 125.86 156.732 135.524C159.24 144.81 161.678 153.762 163.985 162.353C164.583 164.698 163.674 167.13 161.909 167.772C150.55 171.906 139.192 176.041 127.832 180.175C116.474 184.309 105.07 188.46 93.7104 192.594C91.9906 193.22 89.7486 191.987 88.682 189.761C84.9271 181.698 81.0406 173.272 76.9931 164.547C72.7693 155.476 68.3515 146.013 63.7693 136.099Z"
        fill="#C8CBCE"
      />
      <path
        d="M111.686 148.296C112.103 148.989 111.466 149.825 110.697 149.633L108.074 148.98L106.768 154.22C106.426 155.589 107.73 156.805 109.071 156.37L111.853 155.466C112.208 155.351 112.588 155.545 112.704 155.899L113.399 158.04C113.514 158.395 113.32 158.776 112.966 158.891L110.189 159.793C106.158 161.103 102.254 157.458 103.279 153.348L104.583 148.109L101.961 147.455C101.184 147.262 101.03 146.222 101.715 145.81L107.172 142.532C107.598 142.275 108.152 142.413 108.408 142.84L111.686 148.296ZM113.78 136.794L117.139 139.612L115.401 141.683C114.887 142.295 115.371 143.228 116.169 143.158L122.511 142.603C123.01 142.56 123.373 142.12 123.33 141.628L122.775 135.286C122.705 134.49 121.703 134.172 121.188 134.786L119.453 136.854L116.096 134.035C113.044 131.475 108.349 132.992 107.383 136.866L106.922 138.718C106.832 139.08 107.052 139.446 107.414 139.536L109.597 140.081C109.959 140.171 110.326 139.951 110.416 139.589L110.877 137.738C111.204 136.429 112.781 135.956 113.78 136.794ZM129.104 144.957L126.865 143.078C126.579 142.838 126.153 142.875 125.914 143.161L124.469 144.882C124.23 145.167 124.267 145.593 124.553 145.833L126.795 147.714C127.873 148.619 127.536 150.37 126.194 150.806L121.057 152.475L120.223 149.908C119.976 149.147 118.929 149.063 118.565 149.777L115.674 155.448C115.449 155.891 115.625 156.433 116.068 156.659L121.74 159.549C122.449 159.91 123.253 159.234 123.005 158.468L122.17 155.9L127.301 154.233C131.329 152.924 132.351 147.682 129.104 144.957Z"
        fill="white"
      />
      <path
        opacity="0.6"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.7693 136.099C77.889 130.96 92.008 125.821 106.173 120.666C120.292 115.526 134.456 110.371 148.576 105.232L151.488 115.908C137.697 117.442 123.013 120.891 108.215 126.277C93.4172 131.663 79.9518 138.46 68.4009 146.149L63.7693 136.099Z"
        fill="#8E929B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.2727 95.7215L127.598 70.1252C131.581 68.6756 136.025 70.7477 137.474 74.7305L148.576 105.232L63.7691 136.099L52.6674 105.598C51.2178 101.615 53.29 97.1711 57.2727 95.7215Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.0715 124.95L145.775 93.029C146.68 92.6997 147.622 93.1766 147.935 94.0371L151.032 102.544C151.345 103.404 150.929 104.376 150.024 104.705L62.3212 136.626C61.4162 136.956 60.4737 136.479 60.1607 135.619L57.0643 127.111C56.7511 126.251 57.1666 125.28 58.0715 124.95Z"
        fill="#E4E5E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5196 124.423L144.326 93.5563L143.454 91.1582L58.6467 122.025L59.5196 124.423Z"
        fill="#C8CBCE"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2793 122.794L30.6547 120.411L28 119.279L30.3838 117.654L31.5151 115L33.1397 117.384L35.7944 118.515L33.4106 120.14L32.2793 122.794Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.279 20.7944L146.655 18.4106L144 17.2793L146.384 15.654L147.515 13L149.14 15.3844L151.794 16.5151L149.411 18.1397L148.279 20.7944Z"
      fill="#E4E4E4"
    />
    <defs>
      <clipPath id="clip0_13000_149274">
        <rect
          x="69.7019"
          y="10"
          width="15.8521"
          height="15.8521"
          rx="7.92606"
          transform="rotate(20 69.7019 10)"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RecordingDelete;
