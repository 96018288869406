import { parsePhoneNumber } from 'libphonenumber-js';
import React from 'react';

import { Category } from 'types';

import CategoryIconSmall from 'components/CategoryIconSmall';

import styles from './CategorySelectPopUpItem.module.scss';

interface CategorySelectPopUpItemProps {
  category: Category;
  onSelect?: (category: Category) => void;
}

const CategorySelectPopUpItem: React.FC<CategorySelectPopUpItemProps> = ({ category, onSelect }) => (
  <li
    className={styles.root}
    onClick={onSelect !== undefined ? () => onSelect(category) : undefined}
    key={category.id}
  >
    <CategoryIconSmall color={category.color} />
    <div className={styles.catDetails}>
      <p className={styles.categoryName}>{category.name}</p>
      <p className={styles.categoryNumber}>
        {parsePhoneNumber(`+${category.virtualPhoneNumber.number}`).formatInternational()}
      </p>
    </div>
  </li>
);

export default CategorySelectPopUpItem;
