import React from 'react';

import { Modal, ModalSize } from 'components/Modal';

import ModalsAccountDeletionContent, { ModalsAccountDeletionContentProps } from '../ModalsAccountDeletionContent';

import styles from './ModalsAccountDeletion.module.scss';

export interface ModalsAccountDeletionProps extends ModalsAccountDeletionContentProps {
  isOpen: boolean;
}

const ModalsAccountDeletion: React.FC<ModalsAccountDeletionProps> = ({ isOpen, onRequestClose, deletionReasonId }) => (
  <Modal
    isOpen={isOpen}
    className={styles.root}
    onRequestClose={onRequestClose}
    size={ModalSize.L}
  >
    <ModalsAccountDeletionContent
      onRequestClose={onRequestClose}
      deletionReasonId={deletionReasonId}
    />
  </Modal>
);

export default ModalsAccountDeletion;
