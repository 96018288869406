import { PurchaseCreditCard } from 'types/services/purchase';

export enum CreditCardThemes {
  B2B = 'b2b',
  B2C = 'b2c',
}

export type CreditCardProps = Required<
  Pick<PurchaseCreditCard, 'methodId' | 'brand' | 'expiryMonth' | 'expiryYear' | 'last4' | 'default'>
> & {
  isLoading?: boolean;
  className?: string;
  theme?: CreditCardThemes;
  isSelected?: boolean;
  onSelect?: () => void;
  hasMenuDelete?: boolean;
  hasMenuSetAsDefault?: boolean;
};
