import { AvatarType } from './types';

type AvatarTypeOptions = {
  imageSrc?: string;
  fullName?: string;
  isGroup?: boolean;
  hasPhotoIcon?: boolean;
};
export const getAvatarType = (options: AvatarTypeOptions): AvatarType => {
  const { isGroup = false, imageSrc = '', fullName = '', hasPhotoIcon = false } = options;
  const fullNameTrimmed = fullName.trim();

  if (isGroup) {
    return AvatarType.GROUP;
  }

  if (imageSrc.length > 0) {
    return AvatarType.IMAGE;
  }

  if (fullNameTrimmed.length > 0 && !fullNameTrimmed.startsWith('+')) {
    return AvatarType.LABEL;
  }

  if (hasPhotoIcon) {
    return AvatarType.PHOTO;
  }

  return AvatarType.DEFAULT;
};
