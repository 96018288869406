import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { showErrorToast } from '@onoff/toast-notification';

import { checkoutRemoveCallbackPathFromStorage, handleApplicationError } from 'helpers';
import { sessionStoragePersistenceRemovePaymentMethod } from 'helpers/paymentMethods';

import { creditCardsAddCardHandler, useWebAppDispatch } from '@redux';

import {
  ProtectedCheckoutErrorUrlSearchKeys,
  ProtectedCheckoutErrorUrlSearchValues,
  ProtectedCheckoutSuccessUrlSearchKeys,
} from '../../routes';

import { useCreditCardAnalytics } from './useCreditCardAnalytics';

type UseAddCreditCardProps = {
  onCardAdded: (paymentMethodId: string) => void;
};

export const useAddCreditCard = ({ onCardAdded }: UseAddCreditCardProps) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { sendCreditCardAddFailureEvent, sendCreditCardAddSuccessEvent } = useCreditCardAnalytics();
  const hasHandledResult = useRef(false);

  const checkoutSuccessAndSid = searchParams.get(ProtectedCheckoutSuccessUrlSearchKeys.CHECKOUT_SESSION_ID);
  const checkoutError = searchParams.get(ProtectedCheckoutErrorUrlSearchKeys.ERROR);

  const handleReset = useCallback((): void => {
    // Clear URL Search Params and LocalData on Success or Error
    if (checkoutSuccessAndSid || checkoutError) {
      setSearchParams(new URLSearchParams());
      checkoutRemoveCallbackPathFromStorage();
    }
  }, [checkoutSuccessAndSid, checkoutError, setSearchParams]);

  const handleCheckoutSuccess = useCallback(async () => {
    if (checkoutSuccessAndSid !== null && checkoutSuccessAndSid.length > 0) {
      try {
        const { paymentMethodId } = await dispatch(
          creditCardsAddCardHandler({
            sid: checkoutSuccessAndSid,
            isDefaultCreditCard: true,
          }),
        );
        if (paymentMethodId && onCardAdded) {
          onCardAdded(paymentMethodId);
        }
      } catch (error) {
        handleApplicationError({ error });
      }
      handleReset();
      sendCreditCardAddSuccessEvent();
      sessionStoragePersistenceRemovePaymentMethod();
    }
  }, [checkoutSuccessAndSid, handleReset, sendCreditCardAddSuccessEvent, dispatch, onCardAdded]);

  const handleCheckoutFailure = useCallback((): void => {
    if (checkoutError === ProtectedCheckoutErrorUrlSearchValues.ADDING_CREDIT_CARD_FAILED) {
      const description = intl.formatMessage({
        id: 'Purchase.Notifications.error_description_add_credit_card',
      });

      showErrorToast({
        heading: intl.formatMessage({ id: 'Notifications.Toast.title_error' }),
        message: description,
      });

      sendCreditCardAddFailureEvent({
        error_title: ProtectedCheckoutErrorUrlSearchValues.ADDING_CREDIT_CARD_FAILED,
        error_description: description,
        error_code: undefined,
      });

      handleReset();
      sessionStoragePersistenceRemovePaymentMethod();
    }
  }, [checkoutError, handleReset, intl, sendCreditCardAddFailureEvent]);

  useEffect(() => {
    if (!hasHandledResult.current) {
      handleCheckoutSuccess();
      handleCheckoutFailure();
      hasHandledResult.current = true;
    }

    // Here it's fine to keep the deps empty, as we have all the info needed from url params
    // eslint-disable-next-line
  }, []);
};
