import React, { Component } from 'react';
import { ModalUniStyle as s1 } from 'components/UI/styleRoutes';
import PropTypes from "prop-types";

class ModalUniFooter extends Component {
  static defaultProps = {
    className: '',
    style: {},
  };

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
  };

  render() {
    return (
      <div className={`${s1.footer} ${this.props.className}`}>
        { this.props.children}
      </div>
    );
  }
}

export default ModalUniFooter;
