import { useCallback } from 'react';

import { SIP } from '@constants';
import { SIPStatisticsScopes } from '@redux/types';

import {
  sipDebugConsoleLogger,
  sipAudioManagementAudioCreateAndAppend,
  sipAudioManagementAudioPlay,
} from '../../../../../../../helpers';
import { RTCSessionTrackEvent } from '../../../../../../../types';
import { useSIPStatistics } from '../../../../../../useSIPStatistics';

import { UseWebRTCConnections, ConnectionsAudioManagement, OnConnectionIncoming, OnConnectionOutgoing } from './types';

export const useWebRTCConnections: UseWebRTCConnections = () => {
  const { sipSendErrorToSentry } = useSIPStatistics();

  const connectionsAudioManagement: ConnectionsAudioManagement = ({ idWebRTC, streams }) => {
    /**
     * Create the Audio Element and assign the Media Streams
     */
    sipAudioManagementAudioCreateAndAppend({ idWebRTC, streams });

    /**
     * Play the Audio (Media Streams) only if the the config is "partially"
     * More info => @constants/SIP.CONFIGURATIONS.AUDIO_MANAGEMENT
     */
    if (SIP.CONFIGURATIONS.AUDIO_MANAGEMENT.CALLS_OUTGOING_ARTIFICIAL_RINGBACK_TYPE === 'partially') {
      sipAudioManagementAudioPlay({ idWebRTC });
    }
  };

  const onConnectionIncoming: OnConnectionIncoming = useCallback(
    ({ idWebRTC, eventPeerconnection, webRTCMOSStart }) => {
      sipDebugConsoleLogger('SIP WebRTC: Incoming Calls Track');

      try {
        const { peerconnection } = eventPeerconnection;

        peerconnection.ontrack = (eventTrack: RTCSessionTrackEvent) => {
          const { streams, track } = eventTrack;

          connectionsAudioManagement({
            idWebRTC,
            streams,
          });

          webRTCMOSStart({
            idWebRTC,
            track,
            connectionGetStats: peerconnection.getStats.bind(peerconnection),
          });
        };
      } catch (error) {
        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_WEBRTC,
          error: `SESSION CONNECTION INCOMING FAILED! "${error instanceof Error ? error.message : error}"`,
        });
      }
    },
    [sipSendErrorToSentry],
  );

  const onConnectionOutgoing: OnConnectionOutgoing = useCallback(
    ({ idWebRTC, connection, eventTrack, webRTCMOSStart }) => {
      sipDebugConsoleLogger('SIP WebRTC: Outgoing Calls Track');

      try {
        const { streams, track } = eventTrack;

        connectionsAudioManagement({
          idWebRTC,
          streams,
        });

        webRTCMOSStart({
          idWebRTC,
          track,
          connectionGetStats: connection.getStats.bind(connection),
        });
      } catch (error) {
        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_WEBRTC,
          error: `SESSION CONNECTION OUTGOING FAILED! "${error instanceof Error ? error.message : error}"`,
        });
      }
    },
    [sipSendErrorToSentry],
  );

  return {
    onConnectionIncoming,
    onConnectionOutgoing,
  };
};
