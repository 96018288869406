import {
  init as sentryInit,
  Integrations as SentryIntegrations,
  withScope as sentryWithScope,
  captureException as sentryCaptureException,
  addBreadcrumb as sentryAddBreadcrumb,
  reactRouterV6Instrumentation,
} from '@sentry/react';
import { Integrations as SentryTracingIntegrations } from '@sentry/tracing';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { SentryService, SentryServiceCaptureExceptionProps } from 'types';

import packageJson from '../../../package.json';

const sentryService: SentryService = {
  init: (): void => {
    sentryInit({
      environment: process.env.REACT_APP_ENV,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new SentryTracingIntegrations.BrowserTracing({
          routingInstrumentation: reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        new SentryIntegrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: false,
        }),
      ],
      normalizeDepth: 10,
      tracesSampleRate: 0.5,
      release: `webapp@${packageJson.version}`,
    });
  },

  captureException: ({ error, scope, extras }: SentryServiceCaptureExceptionProps): void => {
    const sentryError = error instanceof Error ? error : new Error(error);
    const { scopeKey = '', scopeValue = '' } = scope || {};

    if (![scopeKey.length, scopeValue.length].includes(0)) {
      sentryWithScope((sentryScope) => {
        if (extras) {
          Object.entries(extras).forEach(([extraKey, extraValue]) => {
            sentryScope.setExtras({ [extraKey]: extraValue });
          });
        }

        sentryScope.setLevel('error');
        sentryScope.setTag(scopeKey, scopeValue);
        sentryCaptureException(sentryError);
      });

      return;
    }

    sentryCaptureException(sentryError);
  },

  addBreadcrumb: (breadcrumb): void => {
    sentryAddBreadcrumb({
      level: 'log',
      category: 'breadcrumb',
      ...breadcrumb,
    });
  },
};

export default sentryService;
