import classNames from 'classnames';
import React from 'react';

import { checkServiceContactPhoneNumber } from 'helpers';

import { useWebAppSelector, selectIntegrationsActiveIntegrations } from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';

import { InfoTopBarActionsTypes, InfoTopBarActionsDropdownProps } from '../../types';
import InfoTopBarActionsDropdownFeaturesCRMExport from '../InfoTopBarActionsDropdownFeaturesCRMExport';
import InfoTopBarActionsDropdownFeaturesContactAddEdit from '../InfoTopBarActionsDropdownFeaturesContactAddEdit';
import InfoTopBarActionsDropdownFeaturesContactBlock from '../InfoTopBarActionsDropdownFeaturesContactBlock';
import InfoTopBarActionsDropdownFeaturesContactFavorite from '../InfoTopBarActionsDropdownFeaturesContactFavorite';
import InfoTopBarActionsDropdownFeaturesDelete from '../InfoTopBarActionsDropdownFeaturesDelete';
import InfoTopBarActionsDropdownFeaturesNumberBlock from '../InfoTopBarActionsDropdownFeaturesNumberBlock';

import styles from './InfoTopBarActionsDropdown.module.scss';

const InfoTopBarActionsDropdown: React.FC<InfoTopBarActionsDropdownProps> = ({
  isOpened,
  type,
  phoneNumberOrEmpty,
  contact,
}) => {
  const activeContactCRMIntegrations = useWebAppSelector(selectIntegrationsActiveIntegrations);

  const isPageContacts = type === InfoTopBarActionsTypes.PAGE_CONTACTS;
  const isPageMessagesGroup = type === InfoTopBarActionsTypes.PAGE_MESSAGES_GROUP;
  const isPhoneNumberAvailable = phoneNumberOrEmpty.length > 0;
  const isContactExist = contact !== undefined;
  const { isServiceContactNumber } = checkServiceContactPhoneNumber({ phoneNumber: phoneNumberOrEmpty });

  return (
    <div
      className={classNames(styles.root, {
        [styles.opened]: isOpened,
      })}
      {...testGetTestingAttributes(testIds.infoTopBarActionsDropdownRoot)}
    >
      {
        // PhoneNumber Exist => (Calls Page || Messages Page || Voicemails Page || Contacts Page)
        isPhoneNumberAvailable && !isPageMessagesGroup ? (
          <>
            {
              // CRM Export => (Contacts Page + CRM Integration)
              isPageContacts && activeContactCRMIntegrations.length > 0 ? (
                <InfoTopBarActionsDropdownFeaturesCRMExport />
              ) : null
            }

            {
              // Add/Edit Contact => Not Onoff Support Number && (Calls || Messages || Voicemails)
              !isPageContacts && !isServiceContactNumber ? (
                <InfoTopBarActionsDropdownFeaturesContactAddEdit
                  phoneNumberOrEmpty={phoneNumberOrEmpty}
                  contact={contact}
                />
              ) : null
            }

            {
              // Contact Favorite Unfavorite => (Calls || Messages || Voicemails)
              !isPageContacts && isContactExist ? (
                <InfoTopBarActionsDropdownFeaturesContactFavorite contact={contact} />
              ) : null
            }

            {
              // Contact Block Unblock => (Calls || Messages || Voicemails || Contacts)
              isContactExist ? <InfoTopBarActionsDropdownFeaturesContactBlock contact={contact} /> : null
            }

            {
              // PhoneNumber Block Unblock => Not Onoff Support Number && (Calls || Messages || Voicemails)
              !isPageContacts && !isContactExist && !isServiceContactNumber ? (
                <InfoTopBarActionsDropdownFeaturesNumberBlock phoneNumberOrEmpty={phoneNumberOrEmpty} />
              ) : null
            }
          </>
        ) : null
      }

      <InfoTopBarActionsDropdownFeaturesDelete type={type} />
    </div>
  );
};

export default InfoTopBarActionsDropdown;
