import React from 'react';

import InfoBannerBrowserRecommendation from './InfoBannerBrowserRecommendation';
import InfoBannerMicrophonePermission from './InfoBannerMicrophonePermission';
import InfoBannerNoPermissionToPlayAudio from './InfoBannerNoPermissionToPlayAudio';
import InfoBannerPubNub from './InfoBannerPubNub';
import InfoBannerSIPCallsMultipleBrowserTab from './InfoBannerSIPCallsMultipleBrowserTab';
import InfoBannerSIPCallsUnavailable from './InfoBannerSIPCallsUnavailable';

import styles from './TopPageInfoBanner.module.scss';

const TopPageInfoBanner: React.FC = () => (
  <div className={styles.root}>
    <InfoBannerSIPCallsMultipleBrowserTab />
    <InfoBannerSIPCallsUnavailable />
    <InfoBannerPubNub />
    <InfoBannerBrowserRecommendation />
    <InfoBannerMicrophonePermission />
    <InfoBannerNoPermissionToPlayAudio />
  </div>
);

export default TopPageInfoBanner;
