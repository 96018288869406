import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';

import { PaymentMethod } from 'types';

import { checkoutSetCallbackPathToStorage, handleApplicationError, isErrorObject } from 'helpers';
import { sessionStoragePersistenceAddPaymentMethod } from 'helpers/paymentMethods';

import { selectCheckoutPublicKeyState, useWebAppSelector } from '@redux';
import { ApplicationPaths } from 'routes';
import { purchaseService } from 'services';

import PaymentMethodSpecification = google.payments.api.PaymentMethodSpecification;
import Environment = google.payments.api.Environment;

const config = {
  apiVersion: 2, // This comes from Google Pay types suggestion
  apiVersionMinor: 0, // This comes from Google Pay types suggestion

  merchantInfo: {
    merchantName: 'Onoff Telecom',
    merchantId: process.env.REACT_APP_ENV === 'production' ? 'BCR2DN4TYHSJXUSV' : '',
  },
  transactionInfo: {
    currencyCode: 'EUR',
    totalPriceStatus: 'FINAL',
  },
} as const;

type UseGooglePayProps = {
  callbackRoute: ApplicationPaths;
};

export const useGooglePay = ({ callbackRoute }: UseGooglePayProps) => {
  const paymentsClient = useMemo(
    () =>
      new google.payments.api.PaymentsClient({
        environment: (process.env.REACT_APP_GOOGLE_PAY_ENV as Environment) || 'TEST',
      }),
    [],
  );

  const { isLoading: isLoadingCheckoutPublicKey, data: checkoutPublicKey } =
    useWebAppSelector(selectCheckoutPublicKeyState);

  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false);
  const [isConfirmationUrlPending, setIsConfirmationUrlPending] = useState(false);

  const allowedPaymentMethods: PaymentMethodSpecification[] = useMemo(
    () => [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['AMEX', 'DISCOVER', 'MASTERCARD', 'VISA', 'JCB'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'checkoutltd',
            gatewayMerchantId: checkoutPublicKey || '',
          },
        },
      },
    ],
    [checkoutPublicKey],
  );

  const getCheckoutToken = async (googlePayToken: string): Promise<string> => {
    const { data } = await axios.post(
      process.env.REACT_APP_CHECKOUT_TOKEN_ENDPOINT || '',
      {
        type: 'googlepay',
        token_data: JSON.parse(googlePayToken),
      },
      {
        headers: {
          authorization: `Bearer ${checkoutPublicKey}`,
        },
      },
    );

    return data.token;
  };

  const triggerGooglePay = async (totalPrice?: number) => {
    if (isLoadingCheckoutPublicKey) {
      return;
    }

    setIsConfirmationUrlPending(true);

    checkoutSetCallbackPathToStorage(callbackRoute);

    try {
      const { transactionInfo, ...rest } = config;

      const { paymentMethodData } = await paymentsClient.loadPaymentData({
        ...rest,
        allowedPaymentMethods,
        transactionInfo: {
          ...transactionInfo,
          totalPrice: totalPrice ? totalPrice.toString(10) : '0',
        },
      });

      const checkoutToken = await getCheckoutToken(paymentMethodData.tokenizationData.token);

      const { redirectConfirmationURL, isFailure } = await purchaseService.getCheckoutConfirmationPageUrl({
        token: checkoutToken,
      });

      if (isFailure) {
        throw Error('Failure');
      }

      if (redirectConfirmationURL) {
        sessionStoragePersistenceAddPaymentMethod(PaymentMethod.GOOGLE_PAY);
        window.location.href = redirectConfirmationURL;
      }
    } catch (error) {
      if (isErrorObject(error) && error.name === 'AbortError') {
        // User closed the Google Pay popup
        return;
      }

      handleApplicationError({ error });
    } finally {
      setIsConfirmationUrlPending(false);
    }
  };

  useEffect(() => {
    paymentsClient
      .isReadyToPay({
        apiVersion: config.apiVersion,
        apiVersionMinor: config.apiVersionMinor,
        allowedPaymentMethods,
      })
      .then(({ result }) => {
        setIsGooglePayAvailable(result);
      })
      .catch((error: unknown) => handleApplicationError({ error }));
  }, [allowedPaymentMethods, paymentsClient]);

  return {
    triggerGooglePay,
    isGooglePayAvailable,
    isConfirmationUrlPending,
    isLoadingCheckoutPublicKey,
  };
};
