import { createPopper, Placement, Modifier, Instance } from '@popperjs/core';
import classNames from 'classnames';
import React, { useEffect, useCallback, useState, useRef, PropsWithChildren } from 'react';

import { useOnClickOutside } from '../../hooks';
import Portal from '../TooltipPortal';

import styles from './Tooltip.module.scss';

export enum TooltipEventType {
  HOVER = 'hover',
  CLICK = 'click',
}

export enum TooltipTheme {
  GREEN = 'green',
  WHITE = 'white',
  DARK = 'dark',
}

export type TooltipPlacements = Placement; // comes from popper.js

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type TooltipModifiers = Array<Partial<Modifier<unknown, unknown>>>; // comes from popper.js

export interface TooltipProps {
  content: JSX.Element | string;
  className?: string;
  style?: React.CSSProperties;
  referenceClassName?: string;
  referenceStyle?: React.CSSProperties;
  theme?: TooltipTheme;
  eventType?: TooltipEventType;
  position?: 'fixed' | 'absolute';
  placement?: TooltipPlacements;
  zIndex?: number;
  width?: number;
  gutter?: number;
  isDisabled?: boolean;
  alwaysVisible?: boolean;
  useTransition?: boolean;
  transitionDuration?: number;
  usePortal?: boolean;
  callbackOnShow?: () => void;
  callbackOnHide?: () => void;
  popperModifiers?: TooltipModifiers;
}

const Tooltip: React.FC<TooltipProps & PropsWithChildren> = ({
  children,
  content,
  className = '',
  style = {},
  referenceClassName = '',
  referenceStyle = {},
  theme = TooltipTheme.WHITE,
  eventType = TooltipEventType.HOVER,
  position = 'fixed',
  placement = 'top',
  zIndex = 100,
  width = 385,
  gutter = 12,
  isDisabled = false,
  alwaysVisible = false,
  useTransition = true,
  transitionDuration = 250,
  usePortal = false,
  callbackOnShow,
  callbackOnHide,
  popperModifiers,
}) => {
  const isTouchableDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  const tooltipElementContent = useRef<HTMLDivElement | null>(null);
  const popperInstanceRef = useRef<Instance>();

  const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLDivElement | null>(null);
  const [tooltipElementArrow, setTooltipElementArrow] = useState<HTMLSpanElement | null>(null);
  const isTooltipVisible = (tooltipIsVisible || alwaysVisible) && !isDisabled;

  const tooltipVisibilityHandler = (isVisible: boolean): void => {
    if (isVisible && callbackOnShow) {
      callbackOnShow();
    } else if (callbackOnHide) {
      callbackOnHide();
    }

    if (popperInstanceRef.current && isVisible) {
      popperInstanceRef.current.update();
    }

    setTooltipIsVisible(isVisible);
  };

  const onMouseEnterHandler = () => tooltipVisibilityHandler(true);
  const onMouseLeaveHandler = () => tooltipVisibilityHandler(false);
  const onClickInsideHandler = () => tooltipVisibilityHandler(true);
  const onClickOutsideHandler = () => tooltipVisibilityHandler(false);

  const tooltipEventListeners = (): {
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
  } => {
    if (alwaysVisible || isDisabled) {
      return {};
    }

    if (eventType === TooltipEventType.HOVER && !isTouchableDevice) {
      return {
        onMouseEnter: onMouseEnterHandler,
        onMouseLeave: onMouseLeaveHandler,
      };
    }

    return {
      onClick: onClickInsideHandler,
    };
  };

  const tooltipInit = useCallback((): void => {
    if (referenceElement && tooltipElement && tooltipElementArrow) {
      const initModifiers: TooltipModifiers = [
        {
          name: 'arrow',
          options: { element: tooltipElementArrow },
        },
        {
          name: 'offset',
          options: { offset: [0, gutter] },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['auto'],
          },
        },
      ];

      popperInstanceRef.current = createPopper(referenceElement, tooltipElement, {
        strategy: position,
        placement,
        modifiers: [...initModifiers, ...(popperModifiers || [])],
      });
    }
  }, [referenceElement, tooltipElement, tooltipElementArrow, position, placement, gutter, popperModifiers]);

  useEffect(() => {
    tooltipInit();
  }, [tooltipInit]);

  useEffect(() => {
    if (alwaysVisible || tooltipIsVisible) {
      popperInstanceRef.current?.update();
    }
  }, [alwaysVisible, tooltipIsVisible]);

  useOnClickOutside<HTMLDivElement | HTMLSpanElement>(
    [tooltipElementContent.current, referenceElement],
    onClickOutsideHandler,
    tooltipIsVisible,
  );

  const tooltipRender = (): JSX.Element => (
    <div
      role="tooltip"
      data-tooltip-placement={placement}
      ref={setTooltipElement}
      style={{
        zIndex,
        transitionDuration: `${transitionDuration}ms`,
        maxWidth: width,
        ...style,
      }}
      className={classNames(
        styles.tooltip,
        styles[`tooltip--${theme}`],
        useTransition && styles['tooltip--animation'],
        isTooltipVisible && styles['tooltip--visible'],
        className,
      )}
    >
      <div
        data-testid="tooltip-content"
        ref={tooltipElementContent}
        className={styles.content}
      >
        {content}
      </div>
      <span
        data-testid="tooltip-arrow"
        ref={setTooltipElementArrow}
        className={styles.arrow}
      />
    </div>
  );

  return (
    <>
      <span
        data-testid="tooltip-reference"
        ref={setReferenceElement}
        {...tooltipEventListeners()}
        className={classNames(styles.reference, referenceClassName)}
        style={{ ...referenceStyle }}
      >
        {children}
      </span>

      {!usePortal && tooltipRender()}

      {usePortal && <Portal id="tooltip-portal">{tooltipRender()}</Portal>}
    </>
  );
};

export default Tooltip;
