import { useIntl } from 'react-intl';

import { showErrorToast } from '@onoff/toast-notification';

import {
  selectSIPDataCalls,
  selectSIPDataConfig,
  selectSIPDataStartCall,
  sipSetStatusSIP,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import {
  SIPErrorCodesDisconnect,
  SIPEvent,
  SIPStatisticsScopes,
  SIPStatuses,
  SIPWebRTCOriginators,
} from '@redux/types';
import { sentryService } from 'services';

import { sipDebugConsoleLogger } from '../../../../../helpers';
import {
  SIPOnConnectedEventHandler,
  SIPOnConnectingEventHandler,
  SIPOnDisconnectedEventHandler,
  SIPOnRegisteredEventHandler,
  SIPOnRegistrationExpiringEventHandler,
  SIPOnRegistrationFailedEventHandler,
  SIPOnUnRegisteredEventHandler,
  UseSIPUserAgentEventListenersOthersReturn,
} from '../../../../../types';
import { useSIPReportCallState } from '../../../../useSIPReportCallState';
import { useSIPStatistics } from '../../../../useSIPStatistics';

export const useSIPUserAgentEventListenersOthers = (): UseSIPUserAgentEventListenersOthersReturn => {
  const dispatch = useWebAppDispatch();
  const intl = useIntl();
  const sipDataConfig = useWebAppSelector(selectSIPDataConfig);
  const sipDataStartCall = useWebAppSelector(selectSIPDataStartCall);
  const sipDataCalls = useWebAppSelector(selectSIPDataCalls);
  const { sipSendErrorToSentry, sipSendErrorToGTM } = useSIPStatistics();
  const { sipReportCallStateUserAgentRegistered } = useSIPReportCallState();

  const onConnectingEventHandler: SIPOnConnectingEventHandler = async ({ event }): Promise<void> => {
    const { socket, attempts } = event;

    sipDebugConsoleLogger('SIPOnConnectingEventHandler', { socket }, { attempts });

    dispatch(sipSetStatusSIP(SIPStatuses.CONNECTING));
  };

  const onConnectedEventHandler: SIPOnConnectedEventHandler = ({ socket }): void => {
    sipDebugConsoleLogger('SIPOnConnectedEventHandler', { socket });

    dispatch(sipSetStatusSIP(SIPStatuses.CONNECTED));
  };

  const onDisconnectedEventHandler: SIPOnDisconnectedEventHandler = ({ error, code, socket, reason }): void => {
    sipDebugConsoleLogger('SIPOnDisconnectedEventHandler', { socket }, { error }, { code }, { reason });
    if (error && code === SIPErrorCodesDisconnect.ERROR_1006) {
      dispatch(sipSetStatusSIP(SIPStatuses.TRY_ANOTHER_WSS));
      return;
    }

    sentryService.addBreadcrumb({
      message: SIPEvent.USER_AGENT_DISCONNECTED,
      data: {
        error,
        code,
        socket,
        reason,
      },
    });

    dispatch(sipSetStatusSIP(SIPStatuses.DISCONNECTED));
  };

  const onRegisteredEventHandler: SIPOnRegisteredEventHandler = async ({
    event,
    transactionId,
    originator,
  }): Promise<void> => {
    sipDebugConsoleLogger('SIPOnRegisteredEventHandler', { response: event.response });

    dispatch(sipSetStatusSIP(SIPStatuses.REGISTERED));

    if (originator === SIPWebRTCOriginators.REMOTE) {
      sipReportCallStateUserAgentRegistered({ transactionId });
    }
  };

  const onUnRegisteredEventHandler: SIPOnUnRegisteredEventHandler = ({ response, cause }): void => {
    sipDebugConsoleLogger('SIPOnUnRegisteredEventHandler', { response }, { cause });

    dispatch(sipSetStatusSIP(SIPStatuses.UNREGISTERED));
  };

  const onRegistrationFailedEventHandler: SIPOnRegistrationFailedEventHandler = ({ response, cause }): void => {
    sipDebugConsoleLogger('SIPOnRegistrationFailedEventHandler', { response }, { cause });
    sipSendErrorToSentry({
      scope: SIPStatisticsScopes.SIP_FAILED,
      error: `SIP FAILED! "${cause}"`,
      extras: {
        sipDataConfig,
        sipDataStartCall,
        sipDataCalls,
      },
    });

    sipSendErrorToGTM({
      sipCallCase: SIPStatisticsScopes.SIP_FAILED,
      sipCallOriginator: SIPWebRTCOriginators.SYSTEM,
      sipCallTitle: 'SIP FAILED!',
      sipCallDescription: `${cause}`,
      sipCallTransactionId: '',
    });

    showErrorToast({
      heading: intl.formatMessage({ id: 'SIP.Notifications.SIPFailure.title' }),
      message: intl.formatMessage({ id: 'SIP.Notifications.SIPFailure.description' }),
    });

    dispatch(sipSetStatusSIP(SIPStatuses.FAILED));
  };

  const onRegistrationExpiringEventHandler: SIPOnRegistrationExpiringEventHandler = (): void => {
    sipDebugConsoleLogger('SIPOnRegistrationExpiringEventHandler');

    dispatch(sipSetStatusSIP(SIPStatuses.TIMEOUT));
  };

  return {
    onConnectingEventHandler,
    onConnectedEventHandler,
    onDisconnectedEventHandler,
    onRegisteredEventHandler,
    onUnRegisteredEventHandler,
    onRegistrationFailedEventHandler,
    onRegistrationExpiringEventHandler,
  };
};
