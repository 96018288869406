import React from 'react';

import { StyleableComponent } from 'types';

const Unsubscription: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M200 100C200 155.228 155.228 200 100 200C44.7715 200 1.52588e-05 155.228 1.52588e-05 100C1.52588e-05 44.7715 44.7715 -2.28882e-05 100 -2.28882e-05C155.228 -2.28882e-05 200 44.7715 200 100Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 100C187.368 148.252 148.252 187.368 100 187.368C51.7478 187.368 12.6316 148.252 12.6316 100C12.6316 51.7477 51.7478 12.6316 100 12.6316C148.252 12.6316 187.368 51.7477 187.368 100Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 100C172.105 139.823 139.823 172.105 100 172.105C60.1774 172.105 27.8947 139.823 27.8947 100C27.8947 60.1773 60.1774 27.8947 100 27.8947C139.823 27.8947 172.105 60.1773 172.105 100Z"
      fill="#F3F4F5"
    />
    <path
      opacity="0.2"
      d="M178.773 130.242L143.78 21.4052C142.783 18.3058 139.462 16.6009 136.363 17.5978L31.9391 51.1727C28.8397 52.1695 27.1348 55.49 28.1317 58.5894L63.1253 167.426C64.1222 170.526 67.4426 172.231 70.5421 171.234L174.966 137.659C178.065 136.662 179.77 133.342 178.773 130.242Z"
      fill="#E4E5E7"
    />
    <path
      d="M171.119 135.346L66.6951 168.921C63.5956 169.917 60.2752 168.213 59.2783 165.113L24.2847 56.2763C23.2878 53.1768 24.9927 49.8564 28.0921 48.8595L132.516 15.2847C135.615 14.2878 138.936 15.9927 139.933 19.0921L174.926 127.929C175.923 131.028 174.218 134.349 171.119 135.346Z"
      fill="white"
    />
    <path
      d="M52.9672 64.3428L39.4984 68.6732C37.8961 69.1887 36.1801 68.3075 35.6647 66.7051C35.1493 65.1027 36.0305 63.3868 37.6328 62.8713L51.1016 58.5409C52.7039 58.0254 54.4199 58.9066 54.9353 60.509C55.4508 62.1114 54.5696 63.8274 52.9672 64.3428Z"
      fill="#E3E4E8"
    />
    <path
      d="M57.4314 78.2258L43.9627 82.5563C42.3603 83.0717 40.6444 82.1905 40.129 80.5882C39.6135 78.9858 40.4947 77.2698 42.0971 76.7544L55.5658 72.4239C57.1682 71.9085 58.8842 72.7897 59.3996 74.3921C59.915 75.9944 59.0338 77.7111 57.4314 78.2258Z"
      fill="#E3E4E8"
    />
    <path
      d="M61.895 92.1095L48.4263 96.44C46.8239 96.9554 45.1079 96.0742 44.5925 94.4718C44.0771 92.8695 44.9583 91.1535 46.5606 90.6381L60.0294 86.3076C61.6317 85.7922 63.3477 86.6734 63.8631 88.2757C64.3786 89.8781 63.4974 91.5941 61.895 92.1095Z"
      fill="#E3E4E8"
    />
    <path
      d="M66.359 105.993L52.8903 110.324C51.2879 110.839 49.572 109.958 49.0565 108.356C48.5411 106.753 49.4223 105.037 51.0247 104.522L64.4934 100.191C66.0958 99.6759 67.8118 100.557 68.3272 102.159C68.8426 103.761 67.9614 105.478 66.359 105.993Z"
      fill="#E3E4E8"
    />
    <path
      d="M70.8226 119.876L57.3539 124.207C55.7515 124.722 54.0355 123.841 53.5201 122.239C53.0047 120.636 53.8859 118.92 55.4882 118.405L68.957 114.074C70.5593 113.559 72.2753 114.44 72.7907 116.042C73.3062 117.645 72.425 119.362 70.8226 119.876Z"
      fill="#E3E4E8"
    />
    <path
      d="M75.2867 133.76L61.8179 138.09C60.2156 138.606 58.4996 137.725 57.9842 136.122C57.4687 134.52 58.3499 132.804 59.9523 132.288L73.421 127.958C75.0234 127.443 76.7394 128.324 77.2548 129.926C77.7702 131.528 76.889 133.244 75.2867 133.76Z"
      fill="#E3E4E8"
    />
    <path
      d="M79.7502 147.643L66.2815 151.973C64.6791 152.489 62.9631 151.608 62.4477 150.005C61.9323 148.403 62.8135 146.687 64.4158 146.172L77.8846 141.841C79.4869 141.326 81.2029 142.207 81.7183 143.809C82.2338 145.412 81.3526 147.128 79.7502 147.643Z"
      fill="#E3E4E8"
    />
    <path
      d="M102.752 146.649C102.18 146.834 101.567 146.519 101.383 145.946L69.6697 47.3119C69.4854 46.7396 69.8007 46.1265 70.3729 45.943C70.9451 45.7587 71.5582 46.0739 71.7418 46.6461L103.455 145.281C103.639 145.853 103.324 146.465 102.752 146.649Z"
      fill="#F4F4F4"
    />
    <path
      d="M127.41 138.722C126.838 138.906 126.225 138.591 126.041 138.018L94.328 39.3839C94.1437 38.8116 94.4589 38.1985 95.0312 38.015C95.6034 37.8307 96.2165 38.1459 96.4001 38.7181L128.113 137.353C128.298 137.924 127.983 138.537 127.41 138.722Z"
      fill="#F4F4F4"
    />
    <path
      d="M152.069 130.793C151.497 130.977 150.884 130.662 150.7 130.09L118.987 31.4552C118.803 30.8829 119.118 30.2698 119.69 30.0863C120.262 29.902 120.876 30.2172 121.059 30.7894L152.772 129.424C152.956 129.996 152.642 130.609 152.069 130.793Z"
      fill="#F4F4F4"
    />
    <path
      d="M83.0339 56.7903L66.4048 62.1371C65.0747 62.5645 63.649 61.8329 63.2215 60.5028L61.6725 55.6853C61.2451 54.3552 61.9766 52.9295 63.3068 52.5021L79.9359 47.1553C81.266 46.7279 82.6917 47.4594 83.1191 48.7895L84.6682 53.607C85.0963 54.9371 84.3647 56.3629 83.0339 56.7903Z"
      fill="currentColor"
    />
    <path
      d="M96.5097 67.7762L75.0409 74.6789C73.5521 75.1576 71.9567 74.3387 71.478 72.85L70.1132 68.6061C69.6345 67.1173 70.4534 65.5219 71.9421 65.0432L93.4109 58.1405C94.8997 57.6618 96.4951 58.4806 96.9738 59.9694L98.3386 64.2133C98.8179 65.7027 97.9991 67.2975 96.5097 67.7762Z"
      fill="currentColor"
    />
    <path
      d="M101.283 81.5601L84.9987 86.796C83.6804 87.22 82.2678 86.4946 81.8446 85.1763L80.2817 80.3145C79.8577 78.9962 80.583 77.5836 81.9014 77.1603L98.1855 71.9244C99.5038 71.5004 100.916 72.2258 101.34 73.5441L102.903 78.4059C103.326 79.7242 102.601 81.1368 101.283 81.5601Z"
      fill="#E3E4E8"
    />
    <path
      d="M115.64 92.2633L92.6891 99.6426C91.1553 100.136 89.5121 99.2921 89.0188 97.7583L87.7067 93.6773C87.2135 92.1435 88.0573 90.5002 89.591 90.007L112.542 82.6277C114.075 82.1344 115.719 82.9782 116.212 84.512L117.524 88.593C118.017 90.1268 117.173 91.7701 115.64 92.2633Z"
      fill="#E3E4E8"
    />
    <path
      d="M130.213 102.896L101.326 112.184C99.6257 112.73 97.8037 111.795 97.2571 110.094L96.139 106.617C95.5924 104.917 96.5276 103.095 98.2284 102.548L127.115 93.2602C128.816 92.7136 130.638 93.6488 131.185 95.3496L132.303 98.8265C132.849 100.527 131.914 102.349 130.213 102.896Z"
      fill="#E3E4E8"
    />
    <path
      d="M73.8144 58.0482L70 56.0897L70.9415 54.2553L72.9214 55.2723L75.1147 51L76.9498 51.9415L73.8144 58.0482Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.279 89.7944L189.655 87.4106L187 86.2793L189.384 84.654L190.515 82L192.14 84.3844L194.794 85.5151L192.411 87.1397L191.279 89.7944Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2793 33.7944L22.6547 31.4106L20 30.2793L22.3838 28.654L23.5151 26L25.1397 28.3844L27.7944 29.5151L25.4106 31.1397L24.2793 33.7944Z"
      fill="#E4E4E4"
    />
    <path
      d="M165.6 137.8C165.6 148.183 157.183 156.6 146.8 156.6C136.417 156.6 128 148.183 128 137.8C128 127.417 136.417 119 146.8 119C157.183 119 165.6 127.417 165.6 137.8Z"
      fill="white"
    />
    <path
      d="M146.8 119C143.082 119 139.447 120.103 136.355 122.168C133.264 124.234 130.854 127.17 129.431 130.606C128.008 134.041 127.636 137.821 128.361 141.468C129.087 145.115 130.877 148.464 133.506 151.094C136.136 153.723 139.485 155.513 143.132 156.239C146.779 156.964 150.559 156.592 153.994 155.169C157.43 153.746 160.366 151.336 162.432 148.245C164.497 145.153 165.6 141.518 165.6 137.8C165.6 132.814 163.619 128.032 160.094 124.506C156.568 120.981 151.786 119 146.8 119ZM145.155 126.872C145.155 126.436 145.328 126.018 145.637 125.709C145.945 125.401 146.364 125.227 146.8 125.227C147.236 125.227 147.655 125.401 147.963 125.709C148.272 126.018 148.445 126.436 148.445 126.872V140.972C148.445 141.409 148.272 141.827 147.963 142.136C147.655 142.444 147.236 142.617 146.8 142.617C146.364 142.617 145.945 142.444 145.637 142.136C145.328 141.827 145.155 141.409 145.155 140.972V126.872ZM146.8 150.137C146.382 150.137 145.973 150.013 145.625 149.781C145.277 149.549 145.006 149.218 144.846 148.832C144.686 148.445 144.644 148.02 144.726 147.61C144.807 147.2 145.009 146.823 145.304 146.527C145.6 146.231 145.977 146.03 146.387 145.948C146.798 145.867 147.223 145.908 147.609 146.068C147.996 146.229 148.326 146.5 148.559 146.847C148.791 147.195 148.915 147.604 148.915 148.022C148.915 148.583 148.692 149.121 148.296 149.518C147.899 149.915 147.361 150.137 146.8 150.137Z"
      fill="currentColor"
    />
  </svg>
);

export default Unsubscription;
