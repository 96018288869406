// TODO: this file is legacy, we need to get rid of it

import { SimpleContactPhone, SimpleContactEmail, PreparedContact } from 'types';

export type ContactObjectContactPhone = Pick<SimpleContactPhone, 'number' | 'normalNumber'>;
export type ContactObjectContactEmail = Pick<SimpleContactEmail, 'email'>;

export type ContactObjectContact = {
  isFriend?: false;
  blocked?: boolean;
  favorite?: boolean;
};

// Removing empty phones and emails
const removeEmptyPropsByName = <T extends Record<string, string>>(array: T[]): T[] | undefined => {
  if (array && array.length) {
    return array.filter((objectItem) => {
      if (!Object.values(objectItem).includes('')) {
        return objectItem;
      }
      return false;
    });
  }
  return undefined;
};

export default class ContactObject<T extends ContactObjectContact> {
  fullName: string;

  displayName: string;

  isFriend: false;

  contactImageUrl: string;

  blocked: boolean;

  favorite: boolean;

  firstName?: string;

  lastName?: string;

  profileImageUrl?: string;

  imageUrl?: string;

  company?: string;

  phones?: ContactObjectContactPhone[];

  emails?: ContactObjectContactEmail[];

  virtualPhoneNumber?: string;

  categoryId?: string;

  key = '';

  constructor(thread: T, displayOrder?: string) {
    if (typeof thread !== 'object') {
      throw new SyntaxError("ContactObject constructor expects 'object'");
    }

    Object.assign<ContactObject<T>, T>(this, thread);

    // NB: Order of the functions matters
    this.fullName = this.getContactFullName(displayOrder);
    this.displayName = this.getDisplayName();

    this.isFriend = thread.isFriend || false;
    this.contactImageUrl = this.getContactImageUrl();

    this.blocked = thread.blocked || false;
    this.favorite = thread.favorite || false;
  }

  getFirstName(): string {
    return this.firstName || '';
  }

  getLastName(): string {
    return this.lastName || '';
  }

  getContactFullName(displayOrder?: string): string {
    if (displayOrder && displayOrder === 'LastNameFirst') {
      return `${this.getLastName()} ${this.getFirstName()}`.trim();
    }

    return `${this.getFirstName()} ${this.getLastName()}`.trim();
  }

  getContactImageUrl(): string {
    return this.isFriend ? this.profileImageUrl || '' : this.imageUrl || '';
  }

  getDisplayName(): string {
    if (this.fullName) return this.fullName;
    if (this.firstName) return this.firstName;
    if (this.lastName) return this.lastName;
    if (this.company) return this.company;
    if (this.phones && this.phones.length) return this.phones[0].number || '';
    if (this.emails && this.emails.length) return this.emails[0].email || '';

    return '';
  }

  prepareForSave(): PreparedContact {
    const cleanObject = JSON.parse(JSON.stringify(this));
    const allowedProps = [
      'id',
      'categoryId',
      'firstName',
      'lastName',
      'jobTitle',
      'company',
      'notes',
      'blocked',
      'favorite',
      'birthday',
      'urls',
      'phones',
      'emails',
      'addresses',
      'imageUrl',
      'socialProfiles',
      'phoneBookId',
    ];

    // Removing any properties that are not listed as allowed
    Object.keys(cleanObject).forEach((prop) => {
      if (!allowedProps.includes(prop)) {
        delete cleanObject[prop];
      }
    });

    cleanObject.phones = removeEmptyPropsByName(cleanObject.phones);
    cleanObject.emails = removeEmptyPropsByName(cleanObject.emails);

    return cleanObject;
  }
}
