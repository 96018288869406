import {
  useWebAppDispatch,
  voicemailsPubNubNewVoicemailHandler,
  voicemailsDelete,
  voicemailsMarkAsListened,
} from '@redux';

import {
  UsePubNubMessagesHandlerPageVoicemailsReturn,
  PubNubPushMessagePageVoicemailsNewVoicemailPayload,
  PubNubPushMessagePageVoicemailsDeleteVoicemailPayload,
  PubNubPushMessagePageVoicemailsReadVoicemailPayload,
} from '../../types';

export const usePubNubMessagesHandlerPageVoicemails = (): UsePubNubMessagesHandlerPageVoicemailsReturn => {
  const dispatch = useWebAppDispatch();

  const pubNubMessageHandlerPageVoicemailsNewVoicemail = (
    voicemail: PubNubPushMessagePageVoicemailsNewVoicemailPayload,
  ): void => {
    dispatch(voicemailsPubNubNewVoicemailHandler({ voicemail }));
  };

  const pubNubMessageHandlerPageVoicemailsDeleteVoicemail = (
    voicemailIds: PubNubPushMessagePageVoicemailsDeleteVoicemailPayload,
  ): void => {
    dispatch(voicemailsDelete(voicemailIds));
  };

  const pubNubMessageHandlerPageVoicemailsReadVoicemail = (
    voicemailIds: PubNubPushMessagePageVoicemailsReadVoicemailPayload,
  ): void => {
    dispatch(voicemailsMarkAsListened(voicemailIds));
  };

  return {
    pubNubMessageHandlerPageVoicemailsNewVoicemail,
    pubNubMessageHandlerPageVoicemailsDeleteVoicemail,
    pubNubMessageHandlerPageVoicemailsReadVoicemail,
  };
};
