import {
  SIPDataCall,
  SIPDataCallStatusesCallInitialising,
  SIPDataCallStatusesCallRecording,
  SIPDataCallStatusesFeatureDTMF,
  SIPDataCallStatusesFeatureHold,
  SIPDataCallStatusesFeatureMute,
  SIPDataCallStatusesFeatureUnHold,
  SIPDataCallStatusesFeatureUnMute,
  SIPDataCallStatusesRingtone,
  SIPDataCallStatusesView,
  SIPDataCallStatusesWebRTC,
  SIPWebRTCOriginators,
} from '../../types';

type GetDataCallReadyForInitCallProps = {
  callId: string;
  categoryId: string;
  transactionId: string;
  phoneNumberLocalNormalized: string;
  formattedCalleeNumber: string;
  callRecording: boolean;
};

type GetDataCallInitializingProps = {
  initialisingId: string;
  phoneNumberLocalNormalized: string;
  formattedCalleeNumber: string;
  categoryLocalId: string;
};

export const getDataCallInitialisationDone = ({
  callId,
  categoryId,
  transactionId,
  phoneNumberLocalNormalized,
  formattedCalleeNumber,
  callRecording,
}: GetDataCallReadyForInitCallProps): SIPDataCall => ({
  ids: {
    initialising: '',
    callIdentifier: callId,
    categoryLocal: categoryId,
    call: '',
    transaction: transactionId,
    webRTC: '',
  },
  statuses: {
    callInitialising: SIPDataCallStatusesCallInitialising.INITIALISED,
    webRTC: SIPDataCallStatusesWebRTC.PUSH_OUTGOING,
    featureMute: SIPDataCallStatusesFeatureMute.DEFAULT,
    featureUnMute: SIPDataCallStatusesFeatureUnMute.UNMUTED,
    featureHold: SIPDataCallStatusesFeatureHold.DEFAULT,
    featureUnHold: SIPDataCallStatusesFeatureUnHold.UNHELD,
    featureDTMF: SIPDataCallStatusesFeatureDTMF.DEFAULT,
    view: SIPDataCallStatusesView.DEFAULT,
    ringtone: SIPDataCallStatusesRingtone.MUTE,
    callRecording: callRecording ? SIPDataCallStatusesCallRecording.ON : SIPDataCallStatusesCallRecording.OFF,
  },
  originator: SIPWebRTCOriginators.LOCAL,
  phoneNumbers: {
    local: phoneNumberLocalNormalized,
    remote: formattedCalleeNumber,
  },
  timestamps: {
    callCreated: new Date().getTime(),
    callRang: 0,
    callAnswered: 0,
    callTerminated: 0,
  },
  values: {
    webRTCDTMF: '',
  },
  statistics: {
    isEstablishmentSent: false,
  },
});

export const getDataCallInitialising = ({
  categoryLocalId,
  initialisingId,
  phoneNumberLocalNormalized,
  formattedCalleeNumber,
}: GetDataCallInitializingProps): SIPDataCall => ({
  ids: {
    initialising: initialisingId,
    callIdentifier: '',
    categoryLocal: categoryLocalId,
    call: '',
    transaction: '',
    webRTC: '',
  },
  statuses: {
    callInitialising: SIPDataCallStatusesCallInitialising.INITIALISING,
    webRTC: SIPDataCallStatusesWebRTC.DEFAULT,
    featureMute: SIPDataCallStatusesFeatureMute.DEFAULT,
    featureUnMute: SIPDataCallStatusesFeatureUnMute.UNMUTED,
    featureHold: SIPDataCallStatusesFeatureHold.DEFAULT,
    featureUnHold: SIPDataCallStatusesFeatureUnHold.UNHELD,
    featureDTMF: SIPDataCallStatusesFeatureDTMF.DEFAULT,
    view: SIPDataCallStatusesView.DEFAULT,
    ringtone: SIPDataCallStatusesRingtone.MUTE,
    callRecording: SIPDataCallStatusesCallRecording.OFF,
  },
  originator: SIPWebRTCOriginators.LOCAL,
  phoneNumbers: {
    local: phoneNumberLocalNormalized,
    remote: formattedCalleeNumber,
  },
  timestamps: {
    callCreated: 0,
    callRang: 0,
    callAnswered: 0,
    callTerminated: 0,
  },
  values: {
    webRTCDTMF: '',
  },
  statistics: {
    isEstablishmentSent: false,
  },
});
