import React from 'react';

import { StyleableComponent } from 'types';

const SmsScheduling: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3238_10119)">
      <rect
        width="50"
        height="50"
        fill="#2BA7A5"
      />
      <path
        d="M0 8.87784C0 3.97475 3.97474 0 8.87784 0L41.1222 0C46.0253 0 50 3.97474 50 8.87784V41.1222C50 46.0253 46.0253 50 41.1222 50H8.87784C3.97475 50 0 46.0253 0 41.1222L0 8.87784Z"
        fill="#2BA7A5"
      />
      <g clipPath="url(#clip1_3238_10119)">
        <path
          d="M25.0007 35.1562C27.0092 35.1561 28.9725 34.5605 30.6425 33.4446C32.3127 32.3286 33.6145 30.7424 34.3832 28.8866C35.1519 27.0308 35.353 24.9887 34.9611 23.0186C34.5692 21.0485 33.6019 19.2388 32.1816 17.8184C30.7612 16.3981 28.9515 15.4308 26.9814 15.0389C25.0113 14.647 22.9692 14.8481 21.1134 15.6168C19.2576 16.3856 17.6714 17.6873 16.5554 19.3575C15.4394 21.0277 14.8438 22.9913 14.8438 25C14.8438 26.6915 15.2562 28.323 16.0379 29.7822L16.3101 30.2915L15.6053 33.4625L15.339 34.6611L16.5375 34.3947L19.708 33.69L20.2177 33.9632L20.2185 33.9636C21.6897 34.7499 23.3326 35.1596 25.0007 35.1562ZM25.0007 35.1562C25.0005 35.1562 25.0002 35.1562 25 35.1562V34.375L25.0016 35.1562C25.0013 35.1562 25.001 35.1562 25.0007 35.1562ZM19.85 34.6517L19.5947 34.5152L19.3121 34.578L14.3103 35.6897L15.422 30.6879L15.4848 30.4053L15.3484 30.15C14.5015 28.5661 14.0598 26.7973 14.0625 25.0012V25C14.0625 18.9592 18.9592 14.0625 25 14.0625C31.0408 14.0625 35.9375 18.9592 35.9375 25C35.9375 31.0408 31.0408 35.9375 25 35.9375H24.9988C23.2028 35.9402 21.4339 35.4985 19.85 34.6517ZM30.0177 25.7812C29.8531 26.8387 29.357 27.8246 28.5908 28.5908C27.6385 29.5431 26.3468 30.0781 25 30.0781C23.6532 30.0781 22.3616 29.5431 21.4092 28.5908C20.643 27.8246 20.147 26.8387 19.9823 25.7812H20.7747C20.9318 26.6304 21.3425 27.4191 21.9617 28.0383C22.7675 28.8442 23.8604 29.2969 25 29.2969C26.1396 29.2969 27.2325 28.8442 28.0384 28.0383C28.6576 27.4191 29.0683 26.6304 29.2253 25.7812H30.0177Z"
          stroke="white"
          strokeWidth="1.5625"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3238_10119">
        <rect
          width="50"
          height="50"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3238_10119">
        <rect
          width="28.125"
          height="28.125"
          fill="white"
          transform="translate(10.9375 10.9375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SmsScheduling;
