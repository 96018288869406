import { useEffect } from 'react';

import { useWebAppSelector, selectSIPStatuses } from '@redux';
import { SIPStatuses } from '@redux/types';

import { checkSIPAvailability } from '../../../helpers';
import { UseSIPFailedProps } from '../../../types';

export const useSIPFailed = ({ sipDisconnect }: UseSIPFailedProps): void => {
  const { statusSIP } = useWebAppSelector(selectSIPStatuses);
  const { isSIPAvailable } = checkSIPAvailability();

  const callbackSIPFailedHandler = (): void => {
    if (isSIPAvailable && statusSIP === SIPStatuses.FAILED) {
      sipDisconnect();
    }
  };

  useEffect(callbackSIPFailedHandler, [isSIPAvailable, statusSIP, sipDisconnect]);
};
