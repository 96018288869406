import { PrivacyFetchBlockedNumbersResponse, PrivacyService, PrivacyBlockNumberParams } from 'types';

import { apiMobile } from 'api';

const privacyService: PrivacyService = {
  fetchBlockedNumbers: () => apiMobile.get<PrivacyFetchBlockedNumbersResponse>('/retrieve-blocked-numbers', {}),
  blockNumber: (params: PrivacyBlockNumberParams) =>
    apiMobile.post<void, PrivacyBlockNumberParams>('/block-number', params),
};

export default privacyService;
