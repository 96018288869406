import classnames from 'classnames';
import React from 'react';

import { testGetTestingAttributes } from '@testing';

import { SwitchSimpleThemes } from '../../enums';
import { SwitchSimpleProps } from '../../types';

import styles from './SwitchSimple.module.scss';

const SwitchSimple: React.FC<SwitchSimpleProps> = ({
  onSwitch,
  propTestId,
  isSwitchedOn = false,
  className = '',
  isDisabled = false,
  theme = SwitchSimpleThemes.DEFAULT,
}) => (
  <button
    disabled={isDisabled}
    type="button"
    role="switch"
    aria-checked={isSwitchedOn}
    className={classnames(styles.root, className, {
      [styles.isSwitchedOn]: isSwitchedOn,
      [styles.disabled]: isDisabled,
      [styles.themeB2B]: theme === SwitchSimpleThemes.B2B,
      [styles.themeB2C]: theme === SwitchSimpleThemes.B2C,
    })}
    onClick={() => (isDisabled ? undefined : onSwitch(!isSwitchedOn))}
    {...testGetTestingAttributes(propTestId)}
  >
    <span className={styles.dot} />
  </button>
);

export default SwitchSimple;
