import { useCallback } from 'react';

import {
  sipSetDataCallStatusWebRTCByWebRTCIdHandler,
  sipSetDataCallTimestampRangByWebRTCIdHandler,
  useWebAppDispatch,
} from '@redux';
import { SIPDataCallStatusesWebRTC, SIPWebRTCOriginators } from '@redux/types';

import { sipDebugConsoleLogger } from '../../../../../../../helpers';
import { RTCSessionIncomingEvent, RTCSessionOutgoingEvent } from '../../../../../../../types';
import { useSIPReportCallState } from '../../../../../../useSIPReportCallState';

import { OnProgress, UseWebRTCProgress } from './types';

export const useWebRTCProgress: UseWebRTCProgress = () => {
  const dispatch = useWebAppDispatch();

  const { sipReportCallStateWebRTCIncomingShown } = useSIPReportCallState();

  const getOnProgressStatusCode = (event: RTCSessionIncomingEvent | RTCSessionOutgoingEvent): 180 | 183 => {
    try {
      const { status_code: statusCode = 0 } = 'response' in event ? event.response || {} : {};
      /**
       * 180 => SIP/180 instructs the Web Client to play ringback tone locally.
       *
       * 183 => SIP/183 contains actual audio string of ringback tone.
       * this ringtone/audio comes from the connection's media streams => "useWebRTCConnections"
       */
      return statusCode === 183 ? statusCode : 180;
    } catch {
      return 180;
    }
  };

  const onProgress: OnProgress = useCallback(
    ({ event, idWebRTC, idTransaction, originatorWebRTC }) => {
      sipDebugConsoleLogger('SIP WebRTC: SESSION PROGRESS: ', { event });

      // Set WebRTC Session Status as RINGING
      const statusCode = getOnProgressStatusCode(event);

      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallStatusWebRTC:
            statusCode === 183 ? SIPDataCallStatusesWebRTC.RINGING_183 : SIPDataCallStatusesWebRTC.RINGING_180,
        }),
      );

      // Set WebRTC Session Ringing Timestamp
      dispatch(
        sipSetDataCallTimestampRangByWebRTCIdHandler({
          propCallIdWebRTC: idWebRTC,
          propCallTimestampCallRang: new Date().getTime(),
        }),
      );

      if (originatorWebRTC === SIPWebRTCOriginators.REMOTE) {
        sipReportCallStateWebRTCIncomingShown({ transactionId: idTransaction });
      }
    },
    [dispatch, sipReportCallStateWebRTCIncomingShown],
  );

  return { onProgress };
};
