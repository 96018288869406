import React from 'react';
import { useIntl } from 'react-intl';

import { Category } from 'types';

import CategoryCommonInfoListItem from '../CategoryCommonInfoListItem';

import styles from './CategoryCommonInfoList.module.scss';

export type CategoryCommonInfoListProps = Pick<
  Category,
  'callEnabled' | 'messageEnabled' | 'voicemailEnabled' | 'isPrivate'
> & { isLandlineNumber: boolean };

const CategoryCommonInfoList: React.FC<CategoryCommonInfoListProps> = ({
  callEnabled,
  messageEnabled,
  voicemailEnabled,
  isPrivate,
  isLandlineNumber,
}) => {
  const intl = useIntl();

  if (callEnabled && messageEnabled && voicemailEnabled && !isPrivate && !isLandlineNumber) {
    return null;
  }

  return (
    <div className={styles.root}>
      {!callEnabled && !voicemailEnabled && (
        <CategoryCommonInfoListItem text={intl.formatMessage({ id: 'Categories.call_off' })} />
      )}
      {!callEnabled && voicemailEnabled && (
        <CategoryCommonInfoListItem text={intl.formatMessage({ id: 'Categories.call_routing' })} />
      )}
      {!messageEnabled && !isLandlineNumber && (
        <CategoryCommonInfoListItem text={intl.formatMessage({ id: 'Categories.message_off' })} />
      )}
      {isLandlineNumber && (
        <CategoryCommonInfoListItem text={intl.formatMessage({ id: 'Categories.landline_number_info' })} />
      )}
      {!voicemailEnabled && (
        <CategoryCommonInfoListItem text={intl.formatMessage({ id: 'Categories.voicemail_off' })} />
      )}
      {isPrivate && <CategoryCommonInfoListItem text={intl.formatMessage({ id: 'Categories.stealth_mode' })} />}
    </div>
  );
};

export default CategoryCommonInfoList;
