import classNames from 'classnames';
import React from 'react';

import { ActiveIntegration, Status, StyleableComponent } from 'types';

import { testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { IntegrationCRMCardType } from '../../types';
import IntegrationCRMLogo from '../IntegrationCRMLogo';

import styles from './IntegrationCRMCardHeader.module.scss';

interface IntegrationCRMCardHeaderProps extends StyleableComponent {
  integrationCrmCardType: IntegrationCRMCardType;
  integration: ActiveIntegration;
  isExpanded: boolean;
  fetchStatus: Status;
  onReloadClick: () => void;
  onClick: () => void;
  onContactSyncClick?: () => void;
  onContactAddClick?: () => void;
  isReloadButtonVisible?: boolean;
  isContactSyncButtonVisible?: boolean;
  isContactAddButtonVisible?: boolean;
}

const IntegrationCRMCardHeader: React.FC<IntegrationCRMCardHeaderProps> = ({
  integrationCrmCardType,
  integration,
  isExpanded = false,
  fetchStatus,
  onClick,
  onReloadClick,
  onContactSyncClick,
  onContactAddClick,
  isReloadButtonVisible = false,
  isContactSyncButtonVisible = false,
  isContactAddButtonVisible = false,
  className = '',
}) => {
  const { iconUrl, name, titleLabel } = integration;
  const isCalls = integrationCrmCardType === IntegrationCRMCardType.CALL_POPUP;
  const isContacts = integrationCrmCardType === IntegrationCRMCardType.CONTACT_PAGE;
  const iconButtonSize = isCalls ? IconCommonSizes.TINY : IconCommonSizes.DEFAULT;

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.calls]: isCalls,
        [styles.contacts]: isContacts,
      })}
    >
      <IntegrationCRMLogo
        url={iconUrl}
        className={styles.logo}
      />

      <div className={styles.name}>{titleLabel || name}</div>

      {isContactSyncButtonVisible ? (
        <IconButton
          iconName={IconCommonNames.ACCOUNT_INFO_REPLACE}
          iconSize={iconButtonSize}
          onClick={onContactSyncClick}
          isDisabled={fetchStatus === Status.LOADING}
          className={styles.iconButton}
        />
      ) : null}

      {isContactAddButtonVisible ? (
        <IconButton
          iconName={IconCommonNames.ADD_PERSON}
          iconSize={iconButtonSize}
          onClick={onContactAddClick}
          isDisabled={fetchStatus === Status.LOADING}
          className={styles.iconButton}
        />
      ) : null}

      {isExpanded ? (
        <IconButton
          iconName={isReloadButtonVisible ? IconCommonNames.REFRESH : IconCommonNames.SPINNER}
          iconSize={iconButtonSize}
          onClick={isReloadButtonVisible ? onReloadClick : undefined}
          className={styles.iconButton}
        />
      ) : null}

      <IconButton
        iconName={isExpanded ? IconCommonNames.EXPAND_LESS : IconCommonNames.EXPAND_MORE}
        iconSize={iconButtonSize}
        onClick={onClick}
        className={styles.iconButton}
        isDisabled={fetchStatus === Status.LOADING}
        propTestId={testIds.integrationCrmCardHeaderOpenButton}
      />
    </div>
  );
};

export default IntegrationCRMCardHeader;
