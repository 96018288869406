import { MessageContentData, SendMessageRequest, Message } from 'types';

import { Validator } from 'helpers/input_validation';

import config from 'config';
import { threadsService } from 'services';

export const getThreadIdOptions = async (
  options: Partial<Pick<Message, 'threadId' | 'group' | 'sourceCategoryId' | 'targetPhoneNumbers'>>,
): Promise<Pick<Message, 'threadId' | 'group'>> => {
  // TODO: Technical Debt
  const { threadId = '', group = false, sourceCategoryId = '', targetPhoneNumbers = [] } = options;
  if (threadId.length === 0) {
    const fetchThreadIdResult = await threadsService.fetchThreadId(sourceCategoryId, targetPhoneNumbers);
    if (fetchThreadIdResult.threadId === undefined) {
      throw new Error('threadId was not returned by server');
    }

    return {
      threadId: fetchThreadIdResult.threadId,
      group: fetchThreadIdResult.group,
    };
  }

  return {
    threadId,
    group,
  };
};

export const getMessageValidationErrorsForSending = (message: SendMessageRequest): string[] => {
  // TODO: review validation rules and necessity
  const validation = new Validator(message, config.universal.validationRules.messageV4);

  // TODO: Technical Debt
  if (validation.hasErrors()) {
    const errors = validation.errors as unknown as string[];
    return errors;
  }

  return [];
};

export const isValidMessageType = (type: string): type is MessageContentData['messageType'] =>
  ['AUDIO', 'IMAGE'].includes(type);

export const getUniqueSetPhoneNumbers = (phoneNumbers: string[]): string[] => Array.from(new Set(phoneNumbers));
