import classNames from 'classnames';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React from 'react';
import { useIntl } from 'react-intl';

import { Lozenge } from '@onoff/ui';

import { CategoryPlanStatusTypes } from 'types';

import { DropdownCategoryOptionProps } from '../../types';
import DropdownCommonOption from '../DropdownCommonOption';

import styles from './DropdownCategoryOption.module.scss';

const DropdownCategoryOption: React.FC<DropdownCategoryOptionProps> = ({
  category: {
    name,
    color,
    virtualPhoneNumber: { number, planStatus },
  },
  className = '',
  countryCode = 'FR',
  propTestId = '',
}) => {
  const intl = useIntl();

  return (
    <DropdownCommonOption
      className={classNames(styles.root, className)}
      propTestId={propTestId}
    >
      <div className={styles.dotContainer}>
        <div
          className={styles.dot}
          style={{ backgroundColor: color }}
        />
      </div>

      <div className={styles.infoContainer}>
        <div className={styles.name}>{name}</div>
        <div className={styles.phone}>{parsePhoneNumberFromString(number, countryCode)?.formatInternational()}</div>
      </div>

      {planStatus === CategoryPlanStatusTypes.EXPIRED && (
        <div className={styles.badgeContainer}>
          <Lozenge
            text={intl.formatMessage({ id: 'Categories.expired' })}
            className={styles.badge}
            colorScheme="red"
          />
        </div>
      )}
    </DropdownCommonOption>
  );
};

export default DropdownCategoryOption;
