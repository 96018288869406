import React from 'react';

import { InfoOutlined as IconInfoOutlined } from '@onoff/icons';

import styles from './CategoryCommonInfoListItem.module.scss';

export interface CategoryCommonInfoListItemProps {
  text: string;
}

const CategoryCommonInfoListItem: React.FC<CategoryCommonInfoListItemProps> = ({ text }) => (
  <div className={styles.root}>
    <IconInfoOutlined className={styles.icon} />
    <span className={styles.text}>{text}</span>
  </div>
);

export default CategoryCommonInfoListItem;
