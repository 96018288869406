import classNames from 'classnames';
import React from 'react';

import { testGetTestingAttributes } from '@testing';
import { IconCommon, IconCommonSizes } from 'components/Icon';

import { InfoTopBarActionsDropdownItemsProps } from '../../types';

import styles from './InfoTopBarActionsDropdownItem.module.scss';

const InfoTopBarActionsDropdownItem: React.FC<InfoTopBarActionsDropdownItemsProps> = ({
  label,
  iconName,
  onClick,
  className = '',
  propTestId = '',
  isDisabled = false,
}) => (
  <div
    className={classNames(styles.root, className, {
      [styles.disabled]: isDisabled,
    })}
    onClick={isDisabled ? undefined : onClick}
    {...testGetTestingAttributes(propTestId)}
  >
    <IconCommon
      name={iconName}
      size={IconCommonSizes.TINY}
    />
    <span className={styles.text}>{label}</span>
  </div>
);

export default InfoTopBarActionsDropdownItem;
