import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useWebAppSelector, selectUserData } from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';
import Banner from 'components/Banner';

const LayoutInfoBarSIPCallsUnavailable: React.FC = () => {
  const { onOffCallStatus } = useWebAppSelector(selectUserData);

  if (onOffCallStatus === 'DISABLED') {
    return null;
  }

  return (
    <Banner colorScheme="warning">
      <div {...testGetTestingAttributes(testIds.layoutInfoBarSIPCallsUnavailableText)}>
        <FormattedMessage id="Layouts.InfoBar.sip_calls_unavailable_incoming" />
      </div>
    </Banner>
  );
};

export default LayoutInfoBarSIPCallsUnavailable;
