import { VoicemailsService } from 'types';
import {
  AllVoicemailsResponse,
  DeleteVoicemailRequest,
  GetVoicemailsRequest,
  StringCollectionRequest,
} from 'types/server/main';

import { apiMobile } from 'api';

const fetchVoicemailsDefaultLimit = 50;

const voicemailsService: VoicemailsService = {
  fetchVoicemails: async (offset, limit = fetchVoicemailsDefaultLimit, inclCounter = true) => {
    const { data = [], totalCount } = await apiMobile.get<AllVoicemailsResponse, GetVoicemailsRequest>(
      '/v2/get-voicemails',
      {
        limit,
        offset,
        inclCounter,
      },
    );
    return { data, totalCount };
  },

  markVoicemailsAsListened: (ids) =>
    apiMobile.post<void, StringCollectionRequest>('/mark-voicemails-as-listened', { ids }),

  deleteVoicemail: (voicemailId) => apiMobile.post<void, DeleteVoicemailRequest>('/delete-voicemail', { voicemailId }),
};

export default voicemailsService;
