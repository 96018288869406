import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, SIPActionTypes, SIPReduxState, SIPStatuses } from '../../types';

export const sipInitialState: SIPReduxState = {
  statuses: {
    statusSIP: SIPStatuses.IDLE,
    statusInitializeCall: Status.IDLE,
    statusInitiateCallTransfer: Status.IDLE,
    statusCancelCallRecording: Status.IDLE,
  },
  dataSIPStartCall: {
    incomingPhoneNumberLocal: '',
    incomingPhoneNumberRemote: '',
    incomingTransactionID: '',
    incomingCategoryID: '',
    incomingCallIdentifier: '',
    incomingInstanceId: '',
    outgoingPhoneNumberLocal: '',
    outgoingPhoneNumberRemote: '',
    outgoingTransactionID: '',
    outgoingCategoryID: '',
    outgoingCallIdentifier: '',
    outgoingInstanceId: '',
  },
  dataSIPConnect: {
    idUser: '',
    idCategory: '',
    ipAddress: '',
  },
  dataSIPConfig: {
    sipUsername: '',
    sipSecret: '',
    sipDomain: '',
    sipTechDomain: '',
    wssSrvRecord: [],
    stunSrvRecord: [],
  },
  dataSIPWss: {
    records: [],
    availableUrlOrEmpty: '',
  },
  dataSIPStun: {
    records: [],
  },
  dataCalls: [],
  dataCallsCapabilities: {
    isCallsIncomingRingtoneDisabled: false,
    isCallsWebRTCAdvancedMediaConstraintsEnabled: false,
    // https://onoffapp.atlassian.net/browse/WAP-3267
    isCallsWebRTCHdCodecEnabled: true,
  },
};

export const sipReducer: Reducer<SIPReduxState, SIPActionTypes> = (state, action) => {
  const reducerState: SIPReduxState = state || sipInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.SIP_SET_STATUS_SIP: {
      const { statusSIP } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusSIP,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_STATUS_CALL_INITIALIZATION: {
      const { statusInitializeCall } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusInitializeCall,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_STATUS_INITIATE_CALL_TRANSFER: {
      const { statusInitiateCallTransfer } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusInitiateCallTransfer,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_STATUS_CANCEL_CALL_RECORDING: {
      const { statusCancelCallRecording } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusCancelCallRecording,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_START_CALL_INCOMING: {
      const { incoming } = action;

      return {
        ...reducerState,
        dataSIPStartCall: {
          ...reducerState.dataSIPStartCall,
          ...incoming,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_START_CALL_OUTGOING: {
      const { outgoing } = action;

      return {
        ...reducerState,
        dataSIPStartCall: {
          ...reducerState.dataSIPStartCall,
          ...outgoing,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_CLEAR_DATA_START_CALL: {
      return {
        ...reducerState,
        dataSIPStartCall: sipInitialState.dataSIPStartCall,
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_CONNECT: {
      const { dataSIPConnect } = action;

      return {
        ...reducerState,
        dataSIPConnect,
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_CONFIG: {
      const { dataSIPConfig } = action;

      return {
        ...reducerState,
        dataSIPConfig,
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_WSS_RECORDS: {
      const { wssRecords } = action;

      return {
        ...reducerState,
        dataSIPWss: {
          ...reducerState.dataSIPWss,
          records: wssRecords,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_WSS_UNAVAILABLE_URL_OR_EMPTY: {
      const { wssUnavailableUrlOrEmpty } = action;

      return {
        ...reducerState,
        dataSIPWss: {
          ...reducerState.dataSIPWss,
          availableUrlOrEmpty: wssUnavailableUrlOrEmpty,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_STUN_RECORDS: {
      const { stunRecords } = action;

      return {
        ...reducerState,
        dataSIPStun: {
          ...reducerState.dataSIPStun,
          records: stunRecords,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_CALL: {
      const { dataCall } = action;

      return {
        ...reducerState,
        dataCalls: [dataCall, ...reducerState.dataCalls],
      };
    }

    case REDUX_ACTION_TYPES.SIP_UPDATE_DATA_CALL_BY_INITIALISING_ID: {
      const { dataCall, initialisingId } = action;

      return {
        ...reducerState,
        dataCalls: reducerState.dataCalls.map((currentDataCall) => {
          if (currentDataCall.ids.initialising === initialisingId) {
            return dataCall;
          }

          return currentDataCall;
        }),
      };
    }

    case REDUX_ACTION_TYPES.SIP_REMOVE_DATA_CALL_BY_INITIALISING_ID: {
      const { initialisingId } = action;

      return {
        ...reducerState,
        dataCalls: reducerState.dataCalls.filter((call) => call.ids.initialising !== initialisingId),
      };
    }

    case REDUX_ACTION_TYPES.SIP_REMOVE_DATA_CALL_BY_CALL_IDENTIFIER: {
      const { callIdentifier } = action;
      return {
        ...reducerState,
        dataCalls: reducerState.dataCalls.filter((call) => call.ids.callIdentifier !== callIdentifier),
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_CALLS: {
      const { dataCalls } = action;

      return {
        ...reducerState,
        dataCalls,
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_CALLS_CAPABILITIES_INCOMING_RINGTONE_DISABLED: {
      const { isCallsIncomingRingtoneDisabled } = action;

      return {
        ...reducerState,
        dataCallsCapabilities: {
          ...reducerState.dataCallsCapabilities,
          isCallsIncomingRingtoneDisabled,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_CALLS_CAPABILITIES_ADVANCED_MEDIA_CONSTRAINTS_ENABLED: {
      const { isCallsWebRTCAdvancedMediaConstraintsEnabled } = action;

      return {
        ...reducerState,
        dataCallsCapabilities: {
          ...reducerState.dataCallsCapabilities,
          isCallsWebRTCAdvancedMediaConstraintsEnabled,
        },
      };
    }

    case REDUX_ACTION_TYPES.SIP_SET_DATA_CALLS_CAPABILITIES_HD_CODEC_ENABLED: {
      const { isCallsWebRTCHdCodecEnabled } = action;

      return {
        ...reducerState,
        dataCallsCapabilities: {
          ...reducerState.dataCallsCapabilities,
          isCallsWebRTCHdCodecEnabled,
        },
      };
    }

    default:
      return reducerState;
  }
};
