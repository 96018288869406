import React, { useEffect, useRef, useState } from 'react';

import { EditOutlined as IconEditOutlined } from '@onoff/icons';

import { Category } from 'types';

import { useWebAppDispatch, updateCategoryName } from '@redux';
import { useClickAway } from 'hooks';

import styles from './CategoryCommonName.module.scss';

export interface CategoryCommonNameProps {
  categoryId: Category['id'];
  categoryName: Category['name'];
  isEditable?: boolean;
}

const CategoryCommonName: React.FC<CategoryCommonNameProps> = ({ categoryId, categoryName, isEditable = false }) => {
  const dispatch = useWebAppDispatch();

  const refInput = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>(categoryName);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseEnterHandler = (): void => {
    setIsHovered(true);
  };

  const onMouseLeaveHandler = (): void => {
    setIsHovered(false);
  };

  const onClickHandler = (): void => {
    setIsHovered(false);
    setIsClicked(true);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.currentTarget.value);
  };

  const onBlurHandler = (): void => {
    // not empty and not the same as before
    if (inputValue.length > 0 && inputValue !== categoryName) {
      dispatch(
        updateCategoryName({
          categoryId,
          newName: inputValue,
        }),
      );
    } else {
      setInputValue(categoryName);
    }

    setIsClicked(false);
  };

  useEffect(() => {
    if (isClicked) {
      refInput.current?.focus();
    }
  }, [isClicked]);

  useClickAway(refInput, onBlurHandler);

  useEffect(() => {
    setInputValue(categoryName);
  }, [categoryName]);

  if (!isEditable) {
    return <h3 className={styles.root}>{categoryName}</h3>;
  }

  if (isClicked) {
    return (
      <input
        ref={refInput}
        onBlur={onBlurHandler}
        className={styles.input}
        value={inputValue}
        onChange={onChangeHandler}
        maxLength={50}
      />
    );
  }

  return (
    <div
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onClick={onClickHandler}
      className={styles.wrapper}
    >
      <h3 className={styles.root}>{inputValue}</h3>
      {isHovered && <IconEditOutlined className={styles.icon} />}
    </div>
  );
};

export default CategoryCommonName;
