import { useEffect, useState } from 'react';

type UseAseArtificialLoadingReturn = boolean;
type UseAseArtificialLoadingProps = {
  startLoader: boolean;
  resetLoader?: boolean;
  timeoutInMs: number;
};

export const useArtificialLoading = ({
  startLoader,
  timeoutInMs,
  resetLoader,
}: UseAseArtificialLoadingProps): UseAseArtificialLoadingReturn => {
  const [isArtificialLoading, setIsArtificialLoading] = useState(true);

  useEffect(() => {
    if (startLoader) {
      setTimeout(() => {
        setIsArtificialLoading(false);
      }, timeoutInMs);
    }
  }, [startLoader, timeoutInMs]);

  useEffect(() => {
    if (resetLoader) {
      setIsArtificialLoading(true);
    }
  }, [resetLoader]);

  return isArtificialLoading;
};
