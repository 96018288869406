import { AvatarCommonProps } from '../../components/Avatar';
import { ContactId } from '../../helpers';
import { SimpleContact, SaveSimpleContactRequest, Phone, Email, SimpleContactPhone } from '../network';

import { Status } from './status';

export enum ContactsOrder {
  FIRST_NAME_FIRST = 'FirstNameFirst',
  LAST_NAME_FIRST = 'LastNameFirst',
}

export type ContactsSimpleContactsMap = Record<string, Contact>;

export type PhoneNumberContactsMap = Record<string, Contact>;

// type for src/helpers/classes/ContactObject prepareForSave
export type PreparedContact = Pick<
  SimpleContact,
  | 'id'
  | 'categoryId'
  | 'firstName'
  | 'lastName'
  | 'jobTitle'
  | 'company'
  | 'notes'
  | 'phones'
  | 'emails'
  | 'imageUrl'
  | 'phoneBookId'
> &
  Required<Pick<SimpleContact, 'blocked'>> &
  Required<Pick<SimpleContact, 'favorite'>>;

export type Contact = Omit<SimpleContact, 'userId'> &
  Partial<Pick<SimpleContact, 'userId'>> & {
    key: string;
    isFriend: false;
    displayName: string;
  };

type SaveContactFields = 'firstName' | 'lastName' | 'company' | 'categoryId' | 'phones' | 'emails' | 'notes';

type SaveContactPhone = Phone & {
  id?: string;
};

type SaveContactEmail = Email & {
  id?: string;
};

export type SaveContactImageUrl = string | FileList;

type SaveContactFormDataPartial = Omit<
  Required<Pick<SaveSimpleContactRequest, SaveContactFields>>,
  'phones' | 'emails'
>;

export type SaveContactFormData = SaveContactFormDataPartial & {
  imageUrl: SaveContactImageUrl | null;
  phones: SaveContactPhone[];
  emails: SaveContactEmail[];
};

export enum EmailOptionType {
  HOME = 'home',
  WORK = 'work',
  OTHER = 'other',
}

export enum PhoneOptionType {
  MOBILE = 'mobile',
  MAIN = 'main',
  HOME = 'home',
  WORK = 'work',
  OTHER = 'other',
}

export type ContactPhone = ContactId & {
  phones?: Pick<SimpleContactPhone, 'normalNumber' | 'number'>[];
};

export enum ContactListGroupKeys {
  ONOFF = 'ONOFF',
}

export interface ContactsStatuses {
  fetchContacts: Status;
  deleteContact: Status;
  saveContact: Status;
}

export type ContactSimpleContactPhone = SimpleContactPhone;

export type ContactPhoneNumberCard = {
  avatarProps: AvatarCommonProps;
  contactId: Contact['id'];
  contactFullName: string;
  phoneNumber?: SimpleContactPhone['number'];
};
