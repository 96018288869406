import React from 'react';
import { useIntl } from 'react-intl';

import { useWebAppDispatch, contactsToggleBlock } from '@redux';
import { testIds } from '@testing';
import { IconCommonNames } from 'components/Icon';

import { InfoTopBarActionsDropdownFeaturesContactBlockProps } from '../../types';
import InfoTopBarActionsDropdownItem from '../InfoTopBarActionsDropdownItem';

const InfoTopBarActionsDropdownFeaturesContactBlock: React.FC<InfoTopBarActionsDropdownFeaturesContactBlockProps> = ({
  contact,
}) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const isContactExist = contact !== undefined;
  const isContactBlocked = isContactExist && contact.blocked === true;

  const onClickHandlerContactsBlockUnblock = (): void => {
    if (isContactExist) {
      dispatch(contactsToggleBlock(contact.key));
    }
  };

  return (
    <InfoTopBarActionsDropdownItem
      iconName={isContactBlocked ? IconCommonNames.REMOVE_OUTLINED : IconCommonNames.BLOCK}
      label={intl.formatMessage({
        id: `InfoTopBar.Actions.Dropdown.button_contact_block_${isContactBlocked ? 'remove' : 'add'}`,
      })}
      onClick={onClickHandlerContactsBlockUnblock}
      propTestId={
        isContactBlocked
          ? testIds.infoTopBarActionsDropdownButtonContactsUnBlock
          : testIds.infoTopBarActionsDropdownButtonContactsBlock
      }
    />
  );
};

export default InfoTopBarActionsDropdownFeaturesContactBlock;
