import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { showSuccessToast } from '@onoff/toast-notification';

import { handleApplicationError } from 'helpers';

import {
  callQualitySurveyEndSurvey,
  callQualitySurveySubmitSurveyHandler,
  selectCallQualitySurveyData,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { Modal, ModalSize } from 'components/Modal';

import { CallQualitySurveyRating, CallQualitySurveyReasonOption, ModalsCallQualitySurveyProps } from '../../types';

import ModalsCallQualitySurveyActions from './ModalsCallQualitySurveyActions';
import ModalsCallQualitySurveyGiveRating from './ModalsCallQualitySurveyGiveRating';
import ModalsCallQualitySurveyGiveReason from './ModalsCallQualitySurveyGiveReason';
import ModalsCallQualitySurveyHeader from './ModalsCallQualitySurveyHeader';
import { hasValidSurveyReason } from './helpers';

import styles from './ModalsCallQualitySurveyHeader.module.scss';

const ModalsCallQualitySurvey: React.FC<ModalsCallQualitySurveyProps> = ({ isOpen }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const surveyData = useWebAppSelector(selectCallQualitySurveyData);

  const [rating, setRating] = useState<CallQualitySurveyRating | null>(null);
  const [reasons, setReasons] = useState<string[]>([]);
  const [customReason, setCustomReason] = useState('');

  const hasRating = rating !== null;
  const askReason = hasRating && rating <= 3;
  const canSubmit = askReason ? hasValidSurveyReason({ reasons, customReason }) : hasRating;

  const onCloseHandler = () => {
    dispatch(callQualitySurveyEndSurvey());
  };

  const onSubmitHandler = async () => {
    try {
      await dispatch(
        callQualitySurveySubmitSurveyHandler({
          transactionId: surveyData?.transactionId || '',
          rating: rating as CallQualitySurveyRating,
          reasons: reasons.length > 0 ? reasons : undefined,
          otherReason:
            reasons.includes(CallQualitySurveyReasonOption.OTHER) && customReason.length > 0 ? customReason : undefined,
        }),
      );

      setRating(null);
      setReasons([]);
      setCustomReason('');

      showSuccessToast({
        heading: intl.formatMessage({ id: 'ModalCallQualitySurvey.notification_succeeded_heading' }),
        message: intl.formatMessage({ id: 'ModalCallQualitySurvey.notification_succeeded_description' }),
      });
    } catch (error) {
      handleApplicationError({ error });
    }
  };

  if (!surveyData) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseHandler}
      size={ModalSize.M}
      className={styles.root}
    >
      <ModalsCallQualitySurveyHeader />
      <ModalsCallQualitySurveyGiveRating
        rating={rating}
        setRating={setRating}
      />
      {askReason && (
        <ModalsCallQualitySurveyGiveReason
          selectedReasons={reasons}
          customReason={customReason}
          setReasons={setReasons}
          setCustomReason={setCustomReason}
        />
      )}
      <ModalsCallQualitySurveyActions
        canSubmit={canSubmit}
        onCancel={onCloseHandler}
        onSubmit={onSubmitHandler}
      />
    </Modal>
  );
};

export default ModalsCallQualitySurvey;
