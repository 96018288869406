import React from 'react';
import { useIntl } from 'react-intl';

import { useWebAppDispatch, contactsToggleFavorite } from '@redux';
import { testIds } from '@testing';
import { IconCommonNames } from 'components/Icon';

import { InfoTopBarActionsDropdownFeaturesContactFavoriteProps } from '../../types';
import InfoTopBarActionsDropdownItem from '../InfoTopBarActionsDropdownItem';

const InfoTopBarActionsDropdownFeaturesContactFavorite: React.FC<
  InfoTopBarActionsDropdownFeaturesContactFavoriteProps
> = ({ contact }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const isContactExist = contact !== undefined;
  const isContactFavorited = isContactExist && contact.favorite === true;

  const onClickHandlerContactsFavoriteUnfavorite = (): void => {
    if (isContactExist) {
      dispatch(contactsToggleFavorite(contact.key));
    }
  };

  return (
    <InfoTopBarActionsDropdownItem
      iconName={isContactFavorited ? IconCommonNames.FAVORITE : IconCommonNames.UN_FAVORITE}
      label={intl.formatMessage({
        id: `InfoTopBar.Actions.Dropdown.button_contact_favorite_${isContactFavorited ? 'remove' : 'add'}`,
      })}
      onClick={onClickHandlerContactsFavoriteUnfavorite}
      propTestId={
        isContactFavorited
          ? testIds.infoTopBarActionsDropdownButtonContactsUnFavorite
          : testIds.infoTopBarActionsDropdownButtonContactsFavorite
      }
    />
  );
};

export default InfoTopBarActionsDropdownFeaturesContactFavorite;
