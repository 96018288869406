import { Reducer } from '@reduxjs/toolkit';

import { REDUX_ACTION_TYPES, BalanceReduxState, BalanceActionTypes } from '../../types';

export const balanceInitialState: BalanceReduxState = {
  isLoadedOnce: false,
  credits: 0,
};

export const balanceReducer: Reducer<BalanceReduxState, BalanceActionTypes> = (state, action) => {
  const reducerState: BalanceReduxState = state || balanceInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.SET_BALANCE:
      return {
        ...reducerState,
        credits: action.balance,
        isLoadedOnce: true,
      };

    default:
      return reducerState;
  }
};
