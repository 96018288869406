import { useCallback, useRef } from 'react';

import { SIP } from '@constants';
import { useWebAppDispatch, sipSetDataCallWebRTCIdByNewWebRTCSessionHandler } from '@redux';
import { SIPWebRTCOriginators } from '@redux/types';

import { sipDebugConsoleLogger } from '../../../../../helpers';
import {
  UseSIPUserAgentEventListenersWebRTCReturn,
  SIPOnNewRTCSessionEventHandler,
  RTCSession,
  RTCSessionSDPEvent,
  RTCSessionSendingEvent,
  RTCSessionIceCandidateEvent,
  RTCSessionIncomingEvent,
  RTCSessionOutgoingEvent,
  RTCSessionEndEvent,
  RTCSessionPeerConnectionEvent,
  RTCSessionTrackEvent,
} from '../../../../../types';

import {
  useWebRTCSDP,
  useWebRTCSending,
  useWebRTCIceCandidate,
  useWebRTCProgress,
  useWebRTCAccepted,
  useWebRTCEnded,
  useWebRTCFailed,
  useWebRTCConnections,
  useWebRTCConnectionsMOS,
} from './eventListeners';
import { useWebRTCSessions } from './sessions';

export const useSIPUserAgentEventListenersWebRTC = (): UseSIPUserAgentEventListenersWebRTCReturn => {
  const dispatch = useWebAppDispatch();

  const refSIPWebRTCSession = useRef<RTCSession | null>(null);

  useWebRTCSessions({ newWebRTCSession: refSIPWebRTCSession.current });

  const { onSDP } = useWebRTCSDP();
  const { onSending } = useWebRTCSending();
  const { onIceCandidate } = useWebRTCIceCandidate();
  const { onProgress } = useWebRTCProgress();
  const { onAccepted } = useWebRTCAccepted();
  const { onEnded } = useWebRTCEnded();
  const { onFailed } = useWebRTCFailed();
  const { onConnectionIncoming, onConnectionOutgoing } = useWebRTCConnections();
  const { webRTCMOSStart, webRTCMOSStopAndGetResults } = useWebRTCConnectionsMOS();

  const onNewRTCSessionEventHandler: SIPOnNewRTCSessionEventHandler = useCallback(
    async ({ originator, request, session }) => {
      sipDebugConsoleLogger('SIPOnNewRTCSessionEventHandler', { originator }, { request }, { session });

      const { id: idWebRTC } = session;
      const originatorWebRTC = originator as unknown as SIPWebRTCOriginators;
      const isCallIncoming = originatorWebRTC === SIPWebRTCOriginators.REMOTE;
      const isCallOutgoing = !isCallIncoming;
      const idTransaction = request.getHeader(SIP.CONFIGURATIONS.REQUEST.HEADER_TRANSACTION_ID_KEY) || '';

      /**
       * Associate WebRTC ID by Transaction ID
       *
       * THIS IS THE MOST IMPORTANT ACTION THAT
       * WE ARE ASSOCIATING WEBRTC ID TO THE REDUX CALL STATE (BY EACH)
       * TO MOVE FORWARD WITH ALL THE CALLING EXPERIENCE
       */

      dispatch(
        sipSetDataCallWebRTCIdByNewWebRTCSessionHandler({
          propCallIdWebRTC: idWebRTC,
          propCallIdTransaction: idTransaction,
        }),
      );

      // Then, Start to Listen
      session.on('sdp', (event: RTCSessionSDPEvent) => onSDP({ event }));

      session.on('sending', (event: RTCSessionSendingEvent) => onSending({ event }));

      session.on<'icecandidate'>('icecandidate', (event: RTCSessionIceCandidateEvent) =>
        onIceCandidate({ event, idTransaction }),
      );

      session.on<'progress'>('progress', (event: RTCSessionIncomingEvent | RTCSessionOutgoingEvent) =>
        onProgress({ event, idWebRTC, idTransaction, originatorWebRTC }),
      );

      session.on<'accepted'>('accepted', (event: RTCSessionIncomingEvent | RTCSessionOutgoingEvent) =>
        onAccepted({ event, idWebRTC, idTransaction, originatorWebRTC }),
      );

      session.on<'ended'>('ended', (event: RTCSessionEndEvent) =>
        onEnded({
          event,
          idWebRTC,
          idTransaction,
          originatorWebRTC,
          timeSessionStarted: session.start_time,
          timeSessionEnded: session.end_time,
          webRTCMOSStopAndGetResults,
        }),
      );

      session.on<'failed'>('failed', (event: RTCSessionEndEvent) =>
        onFailed({
          event,
          idWebRTC,
          idTransaction,
          originatorWebRTC,
          timeSessionStarted: session.start_time,
          timeSessionEnded: session.end_time,
          identityRemote: session.remote_identity,
          webRTCMOSStopAndGetResults,
        }),
      );

      // Then, start to Track
      /**
       * INCOMING CALLS
       * We are the peer for the connection
       */
      if (isCallIncoming) {
        session.on<'peerconnection'>('peerconnection', (eventPeerconnection: RTCSessionPeerConnectionEvent) =>
          onConnectionIncoming({
            idWebRTC,
            eventPeerconnection,
            webRTCMOSStart,
          }),
        );
      }

      /**
       * OUTGOING CALLS
       * We are the connection itself
       */
      if (isCallOutgoing) {
        session.connection?.addEventListener<'track'>('track', (eventTrack: RTCSessionTrackEvent) =>
          onConnectionOutgoing({
            idWebRTC,
            connection: session.connection,
            eventTrack,
            webRTCMOSStart,
          }),
        );
      }

      /**
       * ALL DONE,
       * Update the REF with the session to move forward => "useSIPWebRTCSessions"
       */
      refSIPWebRTCSession.current = session;
    },
    [
      onSDP,
      onSending,
      onIceCandidate,
      onProgress,
      onAccepted,
      onEnded,
      onFailed,
      onConnectionIncoming,
      onConnectionOutgoing,
      webRTCMOSStart,
      webRTCMOSStopAndGetResults,
      dispatch,
    ],
  );

  return {
    onNewRTCSessionEventHandler,
  };
};
