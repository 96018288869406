import { SERVICE_CONTACTS } from '@constants';

import { normalizePhoneNumber } from '../contact';

import { CheckServiceContactPhoneNumberProps, CheckServiceContactPhoneNumberReturn } from './types';

export const checkServiceContactPhoneNumber = ({
  phoneNumber,
}: CheckServiceContactPhoneNumberProps): CheckServiceContactPhoneNumberReturn => {
  const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
  let displayName = '';

  Object.values(SERVICE_CONTACTS).forEach((contact) => {
    if (contact.PHONE_NUMBERS.includes(normalizedPhoneNumber)) {
      displayName = contact.DISPLAY_NAME;
    }
  });

  if (displayName) {
    return {
      isServiceContactNumber: true,
      serviceContactDisplayName: displayName,
    };
  }

  return {
    isServiceContactNumber: false,
    serviceContactDisplayName: undefined,
  };
};
