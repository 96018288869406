export enum IconCommonNames {
  ACCOUNT_INFO_REPLACE = 'ACCOUNT_INFO_REPLACE',
  ADD = 'ADD',
  ADD_PERSON = 'ADD_PERSON',
  ANSWER_CALL = 'ANSWER_CALL',
  BACKSPACE = 'BACKSPACE',
  BASKET = 'BASKET',
  BLOCK = 'BLOCK',
  CALL = 'CALL',
  CALL_END = 'CALL_END',
  CAMERA = 'CAMERA',
  CHECK = 'CHECK',
  CHECK_CIRCLE_FILLED = 'CHECK_CIRCLE_FILLED',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CLOSE = 'CLOSE',
  CLOSE_FULL = 'CLOSE_FULL',
  CREDIT_CARD = 'CREDIT_CARD',
  DELETE = 'DELETE',
  DEVICE_HUB = 'DEVICE_HUB',
  DIALPAD = 'DIALPAD',
  DOWNLOAD = 'DOWNLOAD',
  DROP_DOWN = 'DROP_DOWN',
  DROP_LEFT = 'DROP_LEFT',
  DROP_RIGHT = 'DROP_RIGHT',
  DROP_UP = 'DROP_UP',
  EDIT = 'EDIT',
  END_CALL = 'END_CALL',
  EXPAND_LESS = 'EXPAND_LESS',
  EXPAND_MORE = 'EXPAND_MORE',
  EYE = 'EYE',
  EYE_OFF = 'EYE_OFF',
  FAVORITE = 'FAVORITE',
  FILE_UPLOAD = 'FILE_UPLOAD',
  GLOBE = 'GLOBE',
  HELP = 'HELP',
  INCOMING_CALL = 'INCOMING_CALL',
  INFO_FILLED = 'INFO_FILLED',
  INFO_OUTLINED = 'INFO_OUTLINED',
  LAPTOP = 'LAPTOP',
  LOGOUT = 'LOGOUT',
  MENU = 'MENU',
  MESSAGE = 'MESSAGE',
  MICROPHONE = 'MICROPHONE',
  MICROPHONE_OFF = 'MICROPHONE_OFF',
  MISSED_INCOMING_CALL = 'MISSED_INCOMING_CALL',
  MORE_HORIZONTAL = 'MORE_HORIZONTAL',
  MORE_VERTICAL = 'MORE_VERTICAL',
  MUSIC_NOTE = 'MUSIC_NOTE',
  NEW_TAB = 'NEW_TAB',
  OPEN_IN_FULL = 'OPEN_IN_FULL',
  OUTGOING_CALL = 'OUTGOING_CALL',
  PAUSE = 'PAUSE',
  PEOPLE = 'PEOPLE',
  PERSON = 'PERSON',
  PHONE_FORWARD = 'PHONE_FORWARD',
  PHONE_PAUSED = 'PHONE_PAUSED',
  PHONE_PLAY = 'PHONE_PLAY',
  PICTURE = 'PICTURE',
  PLAY = 'PLAY',
  PREMIUM_CROWN = 'PREMIUM_CROWN',
  RECEIPT = 'RECEIPT',
  REFRESH = 'REFRESH',
  REMOVE_OUTLINED = 'REMOVE_OUTLINED',
  SEARCH = 'SEARCH',
  SEND = 'SEND',
  SETTINGS = 'SETTINGS',
  SMARTPHONE = 'SMARTPHONE',
  SPINNER = 'SPINNER',
  UN_FAVORITE = 'UN_FAVORITE',
  USER = 'USER',
  VOICEMAIL = 'VOICEMAIL',
  VOLUME_OFF = 'VOLUME_OFF',
  VOLUME_ON = 'VOLUME_ON',
  WARNING = 'WARNING',
}

export enum IconCommonSizes {
  /**
   * MINI => 14px
   */
  MINI = 'mini',
  /**
   * TINY => 18px
   */
  TINY = 'tiny',
  /**
   * DEFAULT => 24px
   */
  DEFAULT = 'default',
  /**
   * MEDIUM => 28px
   */
  MEDIUM = 'medium',
  /**
   * BIG => 32px
   */
  BIG = 'big',
  /**
   * LARGE => 36px
   */
  LARGE = 'large',
  /**
   * HUGE => 48px
   */
  HUGE = 'huge',
}
