import { Status, PurchaseReceiptsListResponse } from 'types';

import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  ReceiptsSetDataReceiptsList,
  ReceiptsSetStatusGetReceiptsList,
} from '../../types';

export const receiptsSetStatusGetReceiptList = (status: Status): ReceiptsSetStatusGetReceiptsList => ({
  type: REDUX_ACTION_TYPES.RECEIPTS_SET_STATUS_GET_RECEIPTS_LIST,
  status,
});

export const receiptsSetDataReceiptsList = (data: PurchaseReceiptsListResponse): ReceiptsSetDataReceiptsList => ({
  type: REDUX_ACTION_TYPES.RECEIPTS_SET_DATA_RECEIPTS_LIST,
  data,
});

export const receiptsGetReceiptsListHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    dispatch(receiptsSetStatusGetReceiptList(Status.LOADING));

    try {
      const data = await services.purchaseService.getReceiptsList();
      dispatch(receiptsSetDataReceiptsList(data));
      dispatch(receiptsSetStatusGetReceiptList(Status.SUCCEEDED));
    } catch (error) {
      dispatch(receiptsSetStatusGetReceiptList(Status.FAILED));
    }
  };
