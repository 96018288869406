import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import { useUserCapabilities } from 'hooks';

import { useNavElements, useLegalLinks } from '../../hooks';

import styles from './SideMenuSettings.module.scss';

const SideMenuSettings: React.FC = () => {
  const navigate = useNavigate();
  const { isUserB2B } = useUserCapabilities();
  const navElements = useNavElements();
  const legalLinkElements = useLegalLinks();

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>
        <span className={styles.titleText}>
          <FormattedMessage id={'Settings.sidemenu_title'} />
        </span>
        <span className={styles.titleEmoji}>{'🎉'}</span>
      </h2>
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          {navElements
            .filter(({ isVisibleToSettingsPage }) => isVisibleToSettingsPage)
            .map(({ label, path, isCurrentPath }) => (
              <li
                key={path}
                role="link"
                className={classNames(styles.navListElement, {
                  [styles.isActiveB2B]: isCurrentPath && isUserB2B,
                  [styles.isActiveB2C]: isCurrentPath && !isUserB2B,
                })}
                onClick={() => navigate(path)}
              >
                {label}
              </li>
            ))}
        </ul>
      </nav>
      <nav className={styles.legalLinks}>
        {legalLinkElements.map((link) => (
          <a
            key={link.url}
            href={link.url}
            className={styles.legalLink}
            target="_blank"
            rel="noreferrer"
          >
            {link.label}
          </a>
        ))}
      </nav>
    </div>
  );
};

export default SideMenuSettings;
