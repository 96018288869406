import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@onoff/ui';

import { SupportedLanguage, UserTypes } from 'types';

import { selectUserLanguage, useWebAppSelector } from '@redux';
import mobileSplash from 'assets/images/mobile-splash.png';
import { useUserCapabilities } from 'hooks';
import Header from 'layouts/components/Header';

import styles from './PageBaseAppDownload.module.scss';

const appLinks: Record<SupportedLanguage, Record<UserTypes, string>> = {
  EN: {
    B2C: 'https://onoff.app.link/organic/en',
    B2B: 'https://onoff.app.link/OnoffBusinessApp',
  },
  FR: {
    B2C: 'https://onoff.app.link/organic',
    B2B: 'https://onoff.app.link/OnoffBusinessApp',
  },
};

const PageBaseAppDownload: React.FC = () => {
  const currentLanguage = useWebAppSelector(selectUserLanguage);
  const { isUserB2B } = useUserCapabilities();
  const userType = isUserB2B ? UserTypes.B2B : UserTypes.B2C;
  const appLink = appLinks[currentLanguage][userType];

  const clickHandler = () => {
    window.location.href = appLink;
  };

  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.container}>
        <img
          className={styles.illustration}
          src={mobileSplash}
          alt=""
          width="316"
          height="300"
        />

        <h1 className={styles.title}>
          <FormattedMessage id="BrowserCap.mobile_splash_title" />
        </h1>

        <p className={styles.description}>
          <FormattedMessage id="BrowserCap.mobile_splash_description" />
        </p>

        <Button onClick={clickHandler}>
          <FormattedMessage id="BrowserCap.button_text" />
        </Button>
      </div>
    </div>
  );
};

export default PageBaseAppDownload;
