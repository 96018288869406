import { Status, UserOfferOrCategory } from 'types';

import { isOfferAutoRenewable, isPlanAutoRenewable } from 'helpers';

import {
  selectCategoriesFetchStatisticsStatus,
  selectCategoryStatisticsByCategoryId,
  selectPlansAndOffersStatuses,
  selectPlansAndOffersSubscriptionIdForRenewalPeriodChange,
  useWebAppSelector,
} from '@redux';
import { useUserCapabilities } from 'hooks';

import { isProductPremiumOffer } from '../helpers';

type UsePlanOrOfferRenewalPeriodDataReturn = {
  isPlanAutoRenewable: boolean;
  shouldOpenModal: boolean;
  nextRenewalPeriod?: number;
};

export const usePlanOrOfferRenewalPeriodData = (
  product: UserOfferOrCategory,
): UsePlanOrOfferRenewalPeriodDataReturn => {
  const subscriptionIdForRenewalPeriodChange = useWebAppSelector(
    selectPlansAndOffersSubscriptionIdForRenewalPeriodChange,
  );
  const fetchStatisticsStatus = useWebAppSelector(selectCategoriesFetchStatisticsStatus);
  const { fetchUserOffer: fetchUserOfferStatus } = useWebAppSelector(selectPlansAndOffersStatuses);
  const { isUserPremium } = useUserCapabilities();
  const statusesToCheckForCompletion: Status[] = [fetchStatisticsStatus];
  const results: UsePlanOrOfferRenewalPeriodDataReturn = {
    isPlanAutoRenewable: false,
    shouldOpenModal: false,
    nextRenewalPeriod: undefined,
  };

  if (isProductPremiumOffer(product)) {
    // Offer

    results.isPlanAutoRenewable = isOfferAutoRenewable({ offer: product });

    results.nextRenewalPeriod = product.nextPlanDuration;
  } else {
    // Plan

    const categoryStatistics = useWebAppSelector(selectCategoryStatisticsByCategoryId(product.id));

    if (categoryStatistics) {
      results.isPlanAutoRenewable = isPlanAutoRenewable({ category: product, categoryStatistics });

      results.nextRenewalPeriod = categoryStatistics.nextPlanDuration;
    }
  }

  if (isUserPremium) {
    statusesToCheckForCompletion.push(fetchUserOfferStatus);
  }

  if (
    statusesToCheckForCompletion.every((status) => status === Status.SUCCEEDED) &&
    product.subscriptionId === subscriptionIdForRenewalPeriodChange
  ) {
    results.shouldOpenModal = true;
  }

  return results;
};
