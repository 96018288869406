import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Modal, PopupVertical } from '@onoff/ui';

import image from 'assets/images/modals/emergency-calls-warning.png';

import styles from './ModalsEmergencyCallsWarning.module.scss';

type ModalsEmergencyCallsWarningProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ModalsEmergencyCallsWarning: React.FC<ModalsEmergencyCallsWarningProps> = ({ isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    overlayCanClose={false}
  >
    <PopupVertical className={styles.root}>
      <PopupVertical.Body className={styles.body}>
        <img
          src={image}
          width="200"
          height="200"
          alt=""
        />
        <div className={styles.content}>
          <h2 className={styles.title}>
            <FormattedMessage id="Modals.EmergencyCallsWarning.title" />
          </h2>
          <p>
            <FormattedMessage id="Modals.EmergencyCallsWarning.content" />
          </p>
        </div>
        <Button
          onClick={onClose}
          size="large"
        >
          <FormattedMessage id="Modals.EmergencyCallsWarning.button_title" />
        </Button>
      </PopupVertical.Body>
    </PopupVertical>
  </Modal>
);
