import { withScope } from '@sentry/browser';
import { useCallback, useEffect, useRef } from 'react';

import { SIP } from '@constants';
import { selectSIPDataCalls, useWebAppSelector } from '@redux';
import { SIPEvent, SIPStatisticsScopes } from '@redux/types';
import { sentryService } from 'services';

import { sipDebugConsoleLogger } from '../../../../../../../helpers';
import { useSIPStatistics } from '../../../../../../useSIPStatistics';

import { UseWebRTCIceCandidate, OnIceCandidate } from './types';

export const useWebRTCIceCandidate: UseWebRTCIceCandidate = () => {
  const { sipSendErrorToSentry } = useSIPStatistics();

  const { dataSIPCalls } = useWebAppSelector(selectSIPDataCalls);
  const readyTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const transactionId = useRef<string | null>(null);

  useEffect(() => {
    // Prevents calling ready() for gathering state when call is not happening
    if (readyTimer.current && transactionId.current) {
      const foundCall = dataSIPCalls.find((call) => call.ids.transaction === transactionId.current);

      if (foundCall && (foundCall.statuses.webRTC === 'rejected' || foundCall.statuses.webRTC === 'terminated')) {
        clearTimeout(readyTimer.current);
      }
    }
  }, [dataSIPCalls]);

  const onIceCandidate: OnIceCandidate = useCallback(
    ({ event, idTransaction }) => {
      sipDebugConsoleLogger('SIP WebRTC: IceCandidate: ', { event });

      if (readyTimer.current) {
        clearTimeout(readyTimer.current);
        transactionId.current = null;
      }

      try {
        const { candidate: { candidate = '', type = '', address, port } = {}, ready } = event;
        /**
         * It looks like, when gathering ICE candidates on Windows/MacOS(some) machines/networks/VPN
         * The APP doesn't realize that suitable candidates are already found, and because of this,
         * ICE Gathering state becomes "disconnected (timeout)" and then "closed".
         * Resulting that the APP never builds SDP offer and responds to INVITE with 200 OK.
         *
         * We manually check that: If icecandidate is type as "srflx", and has "address" and "port",
         * Setting the event state (ICE Candidate Gathering State) to "ready",
         * Because having a "srflx" candidate is enough(99%) to make a call
         *
         * https://developer.mozilla.org/en-US/docs/Glossary/ICE
         * https://developer.mozilla.org/en-US/docs/Web/API/RTCIceCandidate
         * https://developer.mozilla.org/en-US/docs/Web/API/RTCIceCandidate/type
         */
        const typeSRFLX = 'srflx';
        const ipPlaceholder = '0.0.0.0';

        const hasTypeSRFLX = type === typeSRFLX || candidate.includes(typeSRFLX);
        const hasAddressIPv4orIPv6 = (address || '').length > 0 && address !== ipPlaceholder;
        const hasPort = port !== null || port !== undefined;

        transactionId.current = idTransaction;
        readyTimer.current = setTimeout(() => {
          withScope((scope) => {
            scope.setTag(SIPStatisticsScopes.SIP, SIPStatisticsScopes.SIP_ICE_GATHERING_TIMEOUT);

            sentryService.addBreadcrumb({
              message: SIPEvent.ICE_GATHERING_TIMEOUT,
            });
          });

          ready();
        }, SIP.TIMEOUTS.ICE_CANDIDATE_GATHERING_MS);

        if (hasTypeSRFLX && hasAddressIPv4orIPv6 && hasPort) {
          clearTimeout(readyTimer.current);
          transactionId.current = null;
          ready();
        }
      } catch (error) {
        sipSendErrorToSentry({
          scope: SIPStatisticsScopes.SIP_WEBRTC,
          error: `SESSION ICECANDIDATE FAILED! "${error instanceof Error ? error.message : error}"`,
        });
      }
    },
    [sipSendErrorToSentry],
  );

  return { onIceCandidate };
};
