import { showErrorToast } from '@onoff/toast-notification';

import { PaymentMethod, PurchaseCreditCard, SelectedPaymentMethod, Status } from 'types';

import { getIntl } from '@intl';

import { selectCreditCardsStatus } from '../../selectors';
import {
  PurchaseFlowActionSetSelectedPaymentMethod,
  PurchaseFlowActionSetStatus,
  PurchaseFlowReduxState,
  REDUX_ACTION_TYPES,
  ThunkResult,
} from '../../types';
import { creditCardsFetchAndSetDataHandler } from '../creditCards';

export const purchaseFlowSetSelectedPaymentMethod = (
  selectedPaymentMethod: SelectedPaymentMethod,
): PurchaseFlowActionSetSelectedPaymentMethod => ({
  type: REDUX_ACTION_TYPES.PURCHASE_FLOW_SET_SELECTED_PAYMENT_METHOD,
  selectedPaymentMethod,
});

export const purchaseFlowSetStatus = (
  id: keyof PurchaseFlowReduxState['statuses'],
  status: Status,
): PurchaseFlowActionSetStatus => ({
  type: REDUX_ACTION_TYPES.PURCHASE_FLOW_SET_STATUS,
  id,
  status,
});

export const purchaseFlowInitPaymentMethodHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    try {
      let userCreditCards: PurchaseCreditCard[] = getState().creditCards.data;
      const creditCardsStatus = selectCreditCardsStatus(getState());

      dispatch(purchaseFlowSetStatus('initialisePaymentMethod', Status.LOADING));

      if (creditCardsStatus === Status.IDLE) {
        const { creditCards } = await dispatch(creditCardsFetchAndSetDataHandler());
        userCreditCards = creditCards;
      }

      if (userCreditCards.length > 0) {
        const defaultCard = userCreditCards.find((card) => card.default);

        if (defaultCard) {
          dispatch(
            purchaseFlowSetSelectedPaymentMethod({
              type: PaymentMethod.CREDIT_CARD,
              id: defaultCard.methodId,
            }),
          );
        }
      }

      dispatch(purchaseFlowSetStatus('initialisePaymentMethod', Status.SUCCEEDED));
    } catch (error) {
      dispatch(purchaseFlowSetStatus('initialisePaymentMethod', Status.FAILED));

      showErrorToast({
        heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_error' }),
        message: getIntl().formatMessage({
          id: 'Errors.oops',
        }),
      });
    }
  };
