import classnames from 'classnames';
import React, { useState } from 'react';

import { MoreHorizontal as IconMoreHorizontal } from '@onoff/icons';

import StealthLock from 'components/SideMenu/StealthLock';
import StealthModal from 'components/SideMenu/StealthModal';

import styles from './StealthMode.module.scss';

const StealthMode: React.FC = () => {
  const [isStealthUnlockerVisible, setIsStealthUnlockerVisible] = useState<boolean>(false);
  const onClickHandlerStealthLockVisibility = (): void => {
    setIsStealthUnlockerVisible(!isStealthUnlockerVisible);
  };

  return (
    <>
      {
        // TODO: This MODAL component is legacy and JavaScript.
        // It is related to each CategoryBox PrivateMode Switch Buttons.
        // It should be refactored in the future.
        <StealthModal />
      }
      <div className={styles.root}>
        <div
          className={classnames(styles.unlocker, {
            [styles.isVisible]: isStealthUnlockerVisible,
          })}
        >
          {
            // TODO: This UNLOCKER component is legacy and JavaScript.
            // It should be refactored in the future. It is working as a common unlocker for the CategoryBoxes.
            <StealthLock />
          }
        </div>
        <button
          type="button"
          className={styles.trigger}
          onClick={onClickHandlerStealthLockVisibility}
        >
          <IconMoreHorizontal className={styles.triggerIcon} />
        </button>
      </div>
    </>
  );
};

export default StealthMode;
