import { KeyboardEvent } from 'react';

const handleKeydownDefault = (event: KeyboardEvent): void => {
  const { key, shiftKey } = event;
  if (key === 'Enter') {
    if (shiftKey) {
      return;
    }
    event.preventDefault();
  }
};

export default handleKeydownDefault;
