import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast } from '@onoff/toast-notification';

import { checkForMediaPermissions } from 'helpers';

import {
  useWebAppDispatch,
  sipFilterDataCallsBySIPDisconnectionHandler,
  sipResetDataStartCallIncomingHandler,
} from '@redux';
import { SIPStatisticsScopes, SIPWebRTCOriginators } from '@redux/types';

import { sipDebugConsoleLogger } from '../../../helpers';
import { UseSIPMediaPermissionReturn, GetSIPMediaPermissionReturn, GetSIPMediaPermissionProps } from '../../../types';
import { useSIPStatistics } from '../../useSIPStatistics';
import { useSIPMediaConstraints } from '../constraints';

export const useSIPMediaPermission = (): UseSIPMediaPermissionReturn => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const { sipSendLogToGTM } = useSIPStatistics();
  const { constraints } = useSIPMediaConstraints();

  const getSIPMediaPermission = useCallback(
    async ({ originator }: GetSIPMediaPermissionProps): Promise<GetSIPMediaPermissionReturn> => {
      try {
        await checkForMediaPermissions(constraints);

        return {
          hasMediaPermission: true,
        };
      } catch {
        if (originator === SIPWebRTCOriginators.LOCAL) {
          sipDebugConsoleLogger('SIP MEDIA_PERMISSION: OUTGOING CALL MEDIA PERMISSION REQUIRED!');

          sipSendLogToGTM({
            sipCallCase: SIPStatisticsScopes.SIP_MEDIA_PERMISSION,
            sipCallOriginator: SIPWebRTCOriginators.LOCAL,
            sipCallTitle: 'OUTGOING CALL',
            sipCallDescription: 'MEDIA PERMISSION REQUIRED!',
            sipCallTransactionId: '',
          });

          showErrorToast({
            heading: intl.formatMessage({ id: 'SIP.Notifications.MediaPermissionOutgoing.title' }),
            message: intl.formatMessage({ id: 'SIP.Notifications.MediaPermissionOutgoing.description' }),
          });
        }

        if (originator === SIPWebRTCOriginators.REMOTE) {
          sipDebugConsoleLogger('SIP MEDIA_PERMISSION: INCOMING CALL MEDIA PERMISSION REQUIRED!');

          sipSendLogToGTM({
            sipCallCase: SIPStatisticsScopes.SIP_MEDIA_PERMISSION,
            sipCallOriginator: SIPWebRTCOriginators.REMOTE,
            sipCallTitle: 'INCOMING CALL',
            sipCallDescription: 'MEDIA PERMISSION REQUIRED!',
            sipCallTransactionId: '',
          });

          dispatch(sipResetDataStartCallIncomingHandler());
          dispatch(sipFilterDataCallsBySIPDisconnectionHandler());

          showErrorToast({
            heading: intl.formatMessage({ id: 'SIP.Notifications.MediaPermissionIncomingCall.title' }),
            message: intl.formatMessage({ id: 'SIP.Notifications.MediaPermissionIncomingCall.description' }),
            closable: true,
          });
        }

        return {
          hasMediaPermission: false,
        };
      }
    },
    [intl, constraints, dispatch, sipSendLogToGTM],
  );

  return {
    getSIPMediaPermission,
  };
};
