import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { CountryCode } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Loading, Modal, PopupWide } from '@onoff/ui';

import { PaymentMethod, Status } from 'types';

import {
  applyDefaultValidationSchema,
  checkoutRemoveCallbackPathFromStorage,
  handleApplicationError,
  isString,
} from 'helpers';
import { sessionStoragePersistenceAddPaymentMethod } from 'helpers/paymentMethods';

import {
  checkoutGetRedirectConfirmationUrlDataHandler,
  selectCheckoutRedirectConfirmationURLState,
  selectUserData,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';

import BillingAddress from './components/BillingAddress';
import CardAddingInfo from './components/CardAddingInfo';
import CardDetails from './components/CardDetails';
import { useArtificialLoading } from './hooks/useArtificialLoading';
import { useGetCheckoutPublicKey } from './hooks/useGetCheckoutPublicKey';

import styles from './ModalAddCreditCard.module.scss';

interface ModalsAddCreditCardProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export type AddCreditCardFormData = {
  firstName: string;
  lastName: string;
  countryIsoCode: CountryCode;
  city: string;
  address: string;
  zipCode: string;
};

export const countriesWithStrictAVSCheck: CountryCode[] = ['AU', 'US', 'GB', 'CA'];

export const ModalAddCreditCard: React.FC<ModalsAddCreditCardProps> = ({ isOpen, onRequestClose }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const userData = useWebAppSelector(selectUserData);
  const { publicKey: checkoutPublicKey, status: checkoutPublicKeyStatus } = useGetCheckoutPublicKey();
  const { isLoading: isFetchingRedirectUrl } = useWebAppSelector(selectCheckoutRedirectConfirmationURLState);
  const isArtificiallyLoading = useArtificialLoading({ startLoader: isOpen, resetLoader: !isOpen, timeoutInMs: 800 });
  const isLoading = isArtificiallyLoading || checkoutPublicKeyStatus === Status.LOADING;

  const [isCardDataValid, setIsCardDataValid] = useState(false);

  const methods = useForm<AddCreditCardFormData>({
    defaultValues: { countryIsoCode: userData?.countryIsoCode || 'FR' },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      applyDefaultValidationSchema<AddCreditCardFormData>((yup) => ({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        countryIsoCode: yup.string().required(),
        city: yup.string().when('countryIsoCode', (countryIsoCode: CountryCode, schema) => {
          if (countriesWithStrictAVSCheck.includes(countryIsoCode)) {
            return yup.string().required();
          }
          return schema;
        }),
        address: yup.string().when('countryIsoCode', (countryIsoCode: CountryCode, schema) => {
          if (countriesWithStrictAVSCheck.includes(countryIsoCode)) {
            return yup.string().required();
          }
          return schema;
        }),
        zipCode: yup.string().required(),
      })),
    ),
  });

  const { formState, handleSubmit, getValues: getFormValues } = methods;

  const { isSubmitting, isValid } = formState;

  const onSubmit = async () => {
    // Data valid -> tokenize card -> submit
    await window.Frames.submitCard();
  };

  const onClickHandlerCloseModal = (): void => {
    onRequestClose();
    checkoutRemoveCallbackPathFromStorage();
  };

  const onCardTokenized = async (token: string) => {
    if (isString(token)) {
      try {
        const confirmationPageUrl = await dispatch(
          checkoutGetRedirectConfirmationUrlDataHandler({
            token,
            ...getFormValues(),
          }),
        );
        sessionStoragePersistenceAddPaymentMethod(PaymentMethod.CREDIT_CARD);
        window.location.href = confirmationPageUrl;
      } catch (error) {
        handleApplicationError({ error });
      }
    }
  };

  useEffect(() => {
    if (isOpen && !isLoading) {
      methods.setFocus('firstName');
      methods.reset();
    }
  }, [isLoading, isOpen, methods]);

  return (
    <Modal
      isOpen={isOpen}
      className={styles.root}
      onClose={onClickHandlerCloseModal}
    >
      <PopupWide>
        <PopupWide.Header>
          <PopupWide.Heading>
            <FormattedMessage id="Settings.billing_add_card_modal_title" />
          </PopupWide.Heading>
        </PopupWide.Header>
        <PopupWide.CloseButton
          onClick={() => {
            if (!isSubmitting) {
              onRequestClose();
            }
          }}
        />
        <PopupWide.Body className={classNames(styles.content, { [styles.loading]: isLoading })}>
          {isLoading && (
            <div className={styles.loaderWrapper}>
              <Loading />
            </div>
          )}

          <FormProvider {...methods}>
            <CardDetails
              publicKey={checkoutPublicKey}
              onCardValidationChanged={setIsCardDataValid}
              onCardTokenized={onCardTokenized}
            />

            <BillingAddress />
          </FormProvider>

          <CardAddingInfo />
        </PopupWide.Body>
        <PopupWide.Footer>
          <Button
            {...testGetTestingAttributes(testIds.addCreditCardButtonCancel)}
            variant="ghost"
            colorScheme="black"
            size="large"
            onClick={onClickHandlerCloseModal}
            className={styles.actionButton}
          >
            {intl.formatMessage({ id: 'Settings.billing_add_card_cancel' })}
          </Button>
          <Button
            {...testGetTestingAttributes(testIds.addCreditCardButtonSubmit)}
            form="payment-form"
            type="submit"
            size="large"
            disabled={!isCardDataValid || !isValid}
            loading={isSubmitting || isFetchingRedirectUrl}
            onClick={handleSubmit(onSubmit)}
            className={classNames(styles.actionButton, styles.submitButton)}
          >
            {intl.formatMessage({ id: 'Settings.billing_add_card_validate' })}
          </Button>
        </PopupWide.Footer>
      </PopupWide>
    </Modal>
  );
};
