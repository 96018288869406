import { createSelector } from '@reduxjs/toolkit';

import { Thread } from 'types';

import {
  getMostRecentContactListByPhoneNumberList,
  isThreadFilteredBySearchString,
  isThreadFilteredByUnreadMessages,
  mapThreadToThreadDisplay,
  matchThreadWithContact,
  sortListByObjectKey,
} from 'helpers';

import {
  SelectThreadCategory,
  SelectThreadContact,
  SelectThreadsByCategoryId,
  SelectThreadsState,
  SelectVisibleThreadsByString,
} from '../../types';
import {
  selectCategoriesByVisibilityWithDynamicColor,
  selectCategoriesDataWithDynamicColor,
  selectCategoriesWithDynamicColor,
} from '../categories';
import { selectContactsPhoneNumberContactMap, selectSimpleContacts } from '../contacts';
import { selectUserCountryIsoCode } from '../user';

export const selectThreadsState: SelectThreadsState = (state) => state.threads;

export const selectThreads = createSelector([selectThreadsState], (threadsState) => threadsState.data);

export const selectThreadsDisplay = createSelector(
  [selectThreadsState, selectContactsPhoneNumberContactMap, selectUserCountryIsoCode],
  (threadsState, phoneNumberContactMap, userCountryIsoCode) =>
    threadsState.data.map((thread) => mapThreadToThreadDisplay(thread, phoneNumberContactMap, userCountryIsoCode)),
);

export const selectThreadsStatuses = createSelector([selectThreadsState], (threadsState) => threadsState.statuses);

export const selectThreadsSortedByCreatedAt = createSelector([selectThreads], (threadList) =>
  sortListByObjectKey(threadList, 'createdAt', true),
);

export const selectThreadsActiveId = createSelector([selectThreadsState], (threads) => threads.activeThreadId);

export const selectThreadsSearchString = createSelector([selectThreadsState], (threads) => threads.searchString);

export const selectThreadsByCategoryId: SelectThreadsByCategoryId = (categoryId) =>
  createSelector(
    [selectThreads],
    (threads): Array<Thread> =>
      threads.filter(({ targetCategoryId, sourceCategoryId }) => (targetCategoryId || sourceCategoryId) === categoryId),
  );

export const selectVisibleThreads = createSelector(
  [selectThreadsSortedByCreatedAt, selectCategoriesByVisibilityWithDynamicColor],
  (threads, categories): Array<Thread> =>
    threads.filter((thread) =>
      categories.find((category) => thread.targetCategoryId === category.id || thread.sourceCategoryId === category.id),
    ),
);

export const selectVisibleThreadsByString: SelectVisibleThreadsByString = (
  searchString = '',
  isFilterUnreadMessages = false,
) =>
  createSelector(
    [selectVisibleThreads, selectContactsPhoneNumberContactMap, selectUserCountryIsoCode, selectThreadsActiveId],
    (threads, phoneNumberContactMap, userCountyryIsoCode, threadActiveId) =>
      threads
        .filter(
          (thread) =>
            isThreadFilteredByUnreadMessages(isFilterUnreadMessages, thread, threadActiveId) &&
            isThreadFilteredBySearchString(searchString, thread, phoneNumberContactMap, userCountyryIsoCode),
        )
        .map((thread) => mapThreadToThreadDisplay(thread, phoneNumberContactMap, userCountyryIsoCode)),
  );

export const selectThreadsActiveThread = createSelector(
  [selectThreadsDisplay, selectThreadsActiveId],
  (threads, id) => threads.find(({ threadId }) => threadId === id) || false,
);

export const selectThreadsActiveThreadCategoryId = createSelector([selectThreadsActiveThread], (thread) => {
  if (thread === false) {
    return '';
  }

  const { incoming, targetCategoryId = '', sourceCategoryId = '' } = thread;

  return incoming ? targetCategoryId : sourceCategoryId;
});

export const selectThreadsActiveThreadCategory = createSelector(
  [selectCategoriesDataWithDynamicColor, selectThreadsActiveThreadCategoryId],
  (categories, id) => categories[id],
);

export const selectThreadContact: SelectThreadContact = (thread) =>
  createSelector(
    [selectContactsPhoneNumberContactMap, selectUserCountryIsoCode],
    (phoneNumberContactMap, userCountryIsoCode) =>
      matchThreadWithContact(thread, phoneNumberContactMap, userCountryIsoCode),
  );

export const selectThreadsActiveThreadContact = createSelector(
  [selectThreadsActiveThread, selectContactsPhoneNumberContactMap, selectUserCountryIsoCode],
  matchThreadWithContact,
);

export const selectThreadCategory: SelectThreadCategory = (thread) =>
  createSelector([selectCategoriesWithDynamicColor], (categories) => {
    if (thread !== null && thread !== false) {
      const categoryId: string = (thread.incoming ? thread.targetCategoryId : thread.sourceCategoryId) || '';
      return categories.find((category) => category.id === categoryId) || null;
    }
    return null;
  });

export const selectThreadsUnseenCount = createSelector([selectVisibleThreads], (threads): number =>
  threads.reduce((memoUnreadMessages, { unreadMessages = 0 }) => memoUnreadMessages + unreadMessages, 0),
);

export const selectThreadsMostRecentContact = createSelector(
  [selectThreadsSortedByCreatedAt, selectSimpleContacts],
  (threadList, contactList) => {
    const phoneNumberList = threadList.reduce<Array<string>>((foundPhoneNumberList, thread) => {
      if (thread.targetPhoneNumber) {
        return [...foundPhoneNumberList, thread.targetPhoneNumber];
      }
      return foundPhoneNumberList;
    }, []);
    return getMostRecentContactListByPhoneNumberList(contactList, phoneNumberList);
  },
);

export const selectThreadsAllLoaded = createSelector([selectThreadsState], (threads) => threads.allLoaded);
