import {
  useWebAppDispatch,
  callLogsAddDataHandler,
  callLogsDeleteCallLogs,
  callLogsSetAllAsSeen,
  callLogsUpdateCallLog,
} from '@redux';

import {
  UsePubNubMessagesHandlerPageCallsReturn,
  PubNubPushMessagePageCallsNewCallLogPayload,
  PubNubPushMessagePageCallsChangeCallLogPayload,
  PubNubPushMessagePageCallsDeleteCallLogPayload,
} from '../../types';

export const usePubNubMessagesHandlerPageCalls = (): UsePubNubMessagesHandlerPageCallsReturn => {
  const dispatch = useWebAppDispatch();

  const pubNubMessageHandlerPageCallsNewCallLog = (callLog: PubNubPushMessagePageCallsNewCallLogPayload): void => {
    dispatch(callLogsAddDataHandler({ callLog }));
  };

  const pubNubMessageHandlerPageCallsChangeCallLog = ({
    callLogId,
    ...rest
  }: PubNubPushMessagePageCallsChangeCallLogPayload): void => {
    dispatch(callLogsUpdateCallLog(callLogId, rest));
  };

  const pubNubMessageHandlerPageCallsDeleteCallLog = (
    callLogIds: PubNubPushMessagePageCallsDeleteCallLogPayload,
  ): void => {
    dispatch(callLogsDeleteCallLogs(callLogIds));
  };

  const pubNubMessageHandlerPageCallsReadCallLog = (): void => {
    dispatch(callLogsSetAllAsSeen());
  };

  return {
    pubNubMessageHandlerPageCallsNewCallLog,
    pubNubMessageHandlerPageCallsChangeCallLog,
    pubNubMessageHandlerPageCallsDeleteCallLog,
    pubNubMessageHandlerPageCallsReadCallLog,
  };
};
