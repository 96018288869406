import { useCallback, useEffect, useState } from 'react';

import { Status } from 'types';

import { authenticationHasAuth } from 'helpers';

import {
  authenticationSetStatusLogin,
  selectAuthenticationStatuses,
  selectUserId,
  userFetchContentHandler,
  userInitHandler,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';

type UseAppUserInitReturn = {
  fetchNecessaryDataStatus: Status;
  isAuthenticated: boolean;
};

export const useAppUserInit = (): UseAppUserInitReturn => {
  const [fetchNecessaryDataStatus, setFetchNecessaryDataStatus] = useState(Status.IDLE);
  const dispatch = useWebAppDispatch();
  const { statusLogin } = useWebAppSelector(selectAuthenticationStatuses);
  const userId = useWebAppSelector(selectUserId);

  const isUserAuthenticated = authenticationHasAuth();
  const isUserLoginIdle = statusLogin === Status.IDLE;
  const isUserLoginSucceeded = statusLogin === Status.SUCCEEDED;

  const handleInitUser = useCallback(async () => {
    try {
      setFetchNecessaryDataStatus(Status.LOADING);
      await dispatch(userInitHandler());
      setFetchNecessaryDataStatus(Status.SUCCEEDED);

      // after user init is done & necessary data is fetched -> fetch content data
      dispatch(userFetchContentHandler());
    } catch {
      setFetchNecessaryDataStatus(Status.FAILED);
    }
  }, [dispatch]);

  const callbackUserInit = () => {
    if (isUserLoginSucceeded && fetchNecessaryDataStatus === Status.IDLE) {
      handleInitUser();
    }
  };

  const callbackUserLoginStatus = (): void => {
    if (isUserAuthenticated && isUserLoginIdle) {
      dispatch(authenticationSetStatusLogin(Status.SUCCEEDED));
    }
  };

  useEffect(callbackUserInit, [
    dispatch,
    handleInitUser,
    isUserAuthenticated,
    isUserLoginSucceeded,
    fetchNecessaryDataStatus,
  ]);
  useEffect(callbackUserLoginStatus, [dispatch, isUserAuthenticated, isUserLoginIdle]);

  useEffect(() => {
    if (!userId) {
      // This handles logout case
      setFetchNecessaryDataStatus(Status.IDLE);
    }
  }, [userId]);

  return { fetchNecessaryDataStatus, isAuthenticated: isUserAuthenticated };
};
