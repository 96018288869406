import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@onoff/ui';

import { MultiDeviceDevice, MultiDeviceDeviceType, Status } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  multiDeviceLogoutDeviceHandler,
  selectMultiDeviceStatuses,
} from '@redux';
import { IconCommon, IconCommonNames } from 'components/Icon';

import styles from './MultiDeviceListItem.module.scss';

export interface MultiDeviceListItemProps {
  device: MultiDeviceDevice;
}

const MultiDeviceListItem: React.FC<MultiDeviceListItemProps> = ({ device }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { logoutDevice: logoutDeviceStatus } = useWebAppSelector(selectMultiDeviceStatuses);

  const isLogoutDeviceStatusLoading = logoutDeviceStatus === Status.LOADING;

  const { deviceId, deviceType, isOwnDevice, timeZone, name, hardware, os } = device;

  const onClickHandlerDisconnectDevice = () => {
    dispatch(multiDeviceLogoutDeviceHandler(deviceId));
  };

  const deviceInfosFiltered = [os, hardware, timeZone].filter((info) => !!info);
  const deviceInfoText = deviceInfosFiltered.join(' | ');

  return (
    <li className={styles.root}>
      <span>
        {deviceType === MultiDeviceDeviceType.DESKTOP ? <IconCommon name={IconCommonNames.LAPTOP} /> : null}
        {deviceType === MultiDeviceDeviceType.MOBILE ? <IconCommon name={IconCommonNames.SMARTPHONE} /> : null}
      </span>

      <div className={styles.itemInfos}>
        <div className={styles.deviceName}>{name || intl.formatMessage({ id: 'Devices.unknown_device' })}</div>
        <div className={styles.deviceInfos}>{deviceInfoText}</div>
      </div>

      <Button
        variant="outline"
        colorScheme="destructive"
        onClick={onClickHandlerDisconnectDevice}
        disabled={isOwnDevice || isLogoutDeviceStatusLoading}
      >
        <FormattedMessage id="Devices.connected_device_list_disconnect" />
      </Button>
    </li>
  );
};

export default MultiDeviceListItem;
