import { REDUX_ACTION_TYPES, StealthCloseStealthModal, StealthOpenStealthModal } from '../../types';

export const stealthOpenModal = (categoryId: string): StealthOpenStealthModal => ({
  type: REDUX_ACTION_TYPES.OPEN_STEALTH_MODAL,
  categoryId,
});

export const stealthCloseModal = (): StealthCloseStealthModal => ({
  type: REDUX_ACTION_TYPES.CLOSE_STEALTH_MODAL,
});
