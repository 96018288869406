import React from 'react';

import { StyleableComponent } from 'types';

const VolumeOn: React.FC<StyleableComponent> = ({ className = '' }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 10.0001V14.0001C3 14.5501 3.45 15.0001 4 15.0001H7L10.29 18.2901C10.92 18.9201 12 18.4701 12 17.5801V6.41008C12 5.52008 10.92 5.07008 10.29 5.70008L7 9.00008H4C3.45 9.00008 3 9.45008 3 10.0001ZM16.5 12.0001C16.5 10.2301 15.48 8.71008 14 7.97008V16.0201C15.48 15.2901 16.5 13.7701 16.5 12.0001ZM14 4.45008V4.65009C14 5.03009 14.25 5.36009 14.6 5.50009C17.18 6.53009 19 9.06008 19 12.0001C19 14.9401 17.18 17.4701 14.6 18.5001C14.24 18.6401 14 18.9701 14 19.3501V19.5501C14 20.1801 14.63 20.6201 15.21 20.4001C18.6 19.1101 21 15.8401 21 12.0001C21 8.16008 18.6 4.89008 15.21 3.60009C14.63 3.37009 14 3.82008 14 4.45008Z"
      fill="currentColor"
    />
  </svg>
);

export default VolumeOn;
