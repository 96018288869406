export enum CreditCardBrands {
  AMEX = 'amex',
  AMERICAN_EXPRESS = 'americanexpress',
  DINERS_CLUB_INTERNATIONAL = 'dinersclubinternational',
  DISCOVER = 'discover',
  JCB = 'jcb',
  MAESTRO = 'maestro',
  MASTERCARD = 'mastercard',
  UNIONPAY = 'unionpay',
  VISA = 'visa',
}

export interface CreditCard {
  methodId?: string;
  type?: string;
  brand?: CreditCardBrands;
  expiryMonth?: number;
  expiryYear?: number;
  last4?: string;
  issuerCountries?: string[];
  default?: boolean;
}

export interface CreditCardsListResponse {
  creditCards?: CreditCard[];
}
