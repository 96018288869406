import { BrowserPermissionCanPlayAudioState } from 'types';

import { REDUX_ACTION_TYPES, SetMicrophonePermission, SetPlayAudioPermission } from '../../types';

export const browserPermissionsSetMicrophonePermission = (
  permissionState: PermissionState,
): SetMicrophonePermission => ({
  type: REDUX_ACTION_TYPES.BROWSER_PERMISSIONS_SET_MICROPHONE_PERMISSION,
  permissionState,
});

export const browserPermissionsSetPlayAudioPermission = (
  permissionState: BrowserPermissionCanPlayAudioState,
): SetPlayAudioPermission => ({
  type: REDUX_ACTION_TYPES.BROWSER_PERMISSIONS_SET_PLAY_AUDIO_PERMISSION,
  permissionState,
});
