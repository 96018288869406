import React from 'react';

import { StyleableComponent } from 'types';

const MusicNote: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0005 5V13.55C11.0605 13.01 9.90046 12.8 8.67046 13.23C7.33046 13.71 6.30046 14.9 6.06046 16.3C5.60046 19.04 7.92046 21.38 10.6505 20.95C12.6105 20.64 14.0005 18.84 14.0005 16.85V7H16.0005C17.1005 7 18.0005 6.1 18.0005 5C18.0005 3.9 17.1005 3 16.0005 3H14.0005C12.9005 3 12.0005 3.9 12.0005 5Z"
      fill="currentColor"
    />
  </svg>
);

export default MusicNote;
