import { LOCALSTORAGE_KEYS } from '@constants';

export const authenticationHasAuth = (): boolean => {
  const accessToken = window.localStorage.getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN) || '';
  const authKey = window.localStorage.getItem(LOCALSTORAGE_KEYS.AUTH_KEY) || '';
  const userDeviceId = window.localStorage.getItem(LOCALSTORAGE_KEYS.USER_DEVICE_ID) || '';

  return accessToken.length > 0 && authKey.length > 0 && userDeviceId.length > 0;
};

export const authenticationRemoveAndRedirectToRoot = (): void => {
  window.sessionStorage.clear();
  window.localStorage.clear();
  /**
   * .replace()
   * We do not want to allow the browser history
   */
  window.location.replace('/');
};
