import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CallsContactLocalTabRowProps } from '../../types';

import styles from './InformationRowCore.module.scss';

const InformationRowCore: React.FC<CallsContactLocalTabRowProps> = ({ label, value, color, className }) => {
  const isValueEmpty = !value;

  return (
    <div className={classNames(styles.root, className)}>
      <span className={styles.header}>{label}</span>
      <span
        className={classNames(styles.content, {
          [styles.isEmpty]: isValueEmpty,
        })}
        style={{ color }}
      >
        {isValueEmpty ? <FormattedMessage id="InfoRow.ContactsInfo.empty" /> : value}
      </span>
    </div>
  );
};

export default InformationRowCore;
