import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { Category } from 'types';

import CategorySelectPopUp from 'components/CategorySelectPopUp';
import { IconCommon, IconCommonNames } from 'components/Icon';
import { useClickAway } from 'hooks';

import styles from './ModalsDialpadMultiCategoriesCallButton.module.scss';

interface ModalsDialpadMultiCategoriesCallButtonProps {
  categories: Array<Category>;
  currentCategory: Category | undefined;
  onChangeCategory: (category: Category) => void;
  onClick: () => void;
}

const ModalsDialpadMultiCategoriesCallButton: React.FC<ModalsDialpadMultiCategoriesCallButtonProps> = ({
  categories,
  currentCategory,
  onChangeCategory,
  onClick,
}) => {
  const categorySelectPopUpContainerRef = useRef<HTMLDivElement>(null);

  const [isCategoryPopUpOpen, setIsCategoryPopUpOpen] = useState(false);
  const [popUpAnchorRef, setPopUpAnchorRef] = useState<HTMLDivElement | null>(null);
  const [popUpRef, setPopUpRef] = useState<HTMLDivElement | null>(null);

  const {
    styles: { popper: popperStyles },
    attributes: { popper: popperAttributes },
  } = usePopper(popUpAnchorRef, popUpRef, {
    placement: 'top',
  });

  const handleSelectCategory = (category) => {
    onChangeCategory(category);
    setIsCategoryPopUpOpen(false);
  };

  useClickAway(categorySelectPopUpContainerRef, () => setIsCategoryPopUpOpen(false));

  return (
    <div
      ref={setPopUpAnchorRef}
      className={styles.root}
    >
      <button
        type="button"
        className={styles.multiCatSubmit}
        disabled={categories.length === 0}
        onClick={onClick}
      >
        <IconCommon name={IconCommonNames.CALL} />
      </button>
      <div ref={categorySelectPopUpContainerRef}>
        {isCategoryPopUpOpen && (
          <CategorySelectPopUp
            categoryList={categories}
            onSelectCategory={handleSelectCategory}
            ref={setPopUpRef}
            style={popperStyles}
            {...popperAttributes}
          />
        )}
        <button
          className={styles.multiCatSelect}
          onClick={() => setIsCategoryPopUpOpen(!isCategoryPopUpOpen)}
          type="button"
        >
          {currentCategory?.name}
          <IconCommon
            name={IconCommonNames.DROP_UP}
            className={styles.multiCatSelectIcon}
          />
        </button>
      </div>
    </div>
  );
};

export default ModalsDialpadMultiCategoriesCallButton;
