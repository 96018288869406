import classnames from 'classnames';
import React from 'react';

import { StyleableComponent } from 'types';

import { useUserCapabilities } from 'hooks';

import styles from './SubHeaderItemScroll.module.scss';

export interface SubHeaderItemScrollProps extends StyleableComponent {
  text: string;
  unseenContentCount?: number;
}

const SubHeaderItemScroll: React.FC<SubHeaderItemScrollProps> = ({ text, unseenContentCount = 0, className = '' }) => {
  const { isUserB2B } = useUserCapabilities();

  return (
    <li
      role="button"
      className={classnames(styles.root, className, {
        // TODO: "isActive" scroll animations will be reconsidered in the future.
        [styles.isActive]: false,
        [styles.isB2B]: isUserB2B,
        [styles.isB2C]: !isUserB2B,
      })}
    >
      <span className={styles.text}>{text}</span>
      {unseenContentCount > 0 && <span className={styles.count}>{unseenContentCount}</span>}
    </li>
  );
};

export default SubHeaderItemScroll;
