import { REDUX_ACTION_TYPES, NotificationsModalActionSetData, NotificationsModalActionSetDataProps } from '../../types';

export const notificationsModalSetData = ({
  isVisible,
  modalType,
}: NotificationsModalActionSetDataProps): NotificationsModalActionSetData => ({
  type: REDUX_ACTION_TYPES.NOTIFICATIONS_MODAL_SET_DATA,
  isVisible,
  modalType,
});
