import React from 'react';

import { StyleableComponent } from 'types';

const Microphone: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.001 14C13.661 14 15.001 12.66 15.001 11V5C15.001 3.34 13.661 2 12.001 2C10.341 2 9.00096 3.34 9.00096 5V11C9.00096 12.66 10.341 14 12.001 14ZM17.911 11C17.421 11 17.011 11.36 16.931 11.85C16.521 14.2 14.471 16 12.001 16C9.53096 16 7.48096 14.2 7.07096 11.85C6.99096 11.36 6.58096 11 6.09096 11C5.48096 11 5.00096 11.54 5.09096 12.14C5.58096 15.14 7.98096 17.49 11.001 17.92V20C11.001 20.55 11.451 21 12.001 21C12.551 21 13.001 20.55 13.001 20V17.92C16.021 17.49 18.421 15.14 18.911 12.14C19.011 11.54 18.521 11 17.911 11Z"
      fill="currentColor"
    />
  </svg>
);

export default Microphone;
