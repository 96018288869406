import React, { PropsWithChildren } from 'react';

import { useResponsiveBreakpoints } from 'hooks';
import { LayoutProps } from 'layouts';

import PageBaseAppDownload from '../PageBaseAppDownload';

const PageBase: React.FC<LayoutProps & PropsWithChildren> = ({ children, isAvailableOnMobile = false }) => {
  const { isMobile } = useResponsiveBreakpoints();

  if (isMobile && !isAvailableOnMobile) {
    return <PageBaseAppDownload />;
  }

  return <>{children}</>;
};

export default PageBase;
