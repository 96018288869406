import React from 'react';
import { useIntl } from 'react-intl';

import { Modal, ModalBody, ModalHeader, ModalSize } from 'components/Modal';

import { ModalsNewThreadProps } from '../../types';
import ModalsNewThreadContent from '../ModalsNewThreadContent';

import styles from './ModalsNewThread.module.scss';

const ModalsNewThread: React.FC<ModalsNewThreadProps> = ({
  isOpen = false,
  zIndex = undefined,
  selectedPhoneNumberList = [],
  selectedCategory = undefined,
  onClose = () => undefined,
}) => {
  const intl = useIntl();

  return (
    <Modal
      zIndex={zIndex}
      isOpen={isOpen}
      onRequestClose={onClose}
      size={ModalSize.XXL}
      className={styles.root}
    >
      <ModalHeader
        className={styles.header}
        title={intl.formatMessage({ id: 'ModalNewThread.title' })}
        onCloseButtonClick={onClose}
      />
      <ModalBody className={styles.content}>
        <ModalsNewThreadContent
          selectedPhoneNumberList={selectedPhoneNumberList}
          selectedCategory={selectedCategory}
          onSendSucceeded={onClose}
        />
      </ModalBody>
    </Modal>
  );
};

export default ModalsNewThread;
