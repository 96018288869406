import classnames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { StyleableComponent } from 'types/components';

import SubHeaderActions from '../SubHeaderActions';
import SubHeaderItems from '../SubHeaderItems';

import styles from './SubHeader.module.scss';

export interface SubHeaderProps extends StyleableComponent, PropsWithChildren {
  hasActions?: boolean;
}

const SubHeader: React.FC<SubHeaderProps> = ({ hasActions = false, className = '', children }) => (
  <section className={classnames(styles.root, className)}>
    <SubHeaderItems>{children}</SubHeaderItems>
    {hasActions && <SubHeaderActions />}
  </section>
);

export default SubHeader;
