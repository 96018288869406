import { useEffect, useState } from 'react';

export const useIsPageVisible = (): boolean => {
  const [isPageVisible, setIsPageVisible] = useState(true);

  const pageVisibilityChangeHandler = () => {
    setIsPageVisible(document.visibilityState === 'visible');
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', pageVisibilityChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', pageVisibilityChangeHandler);
    };
  }, []);

  return isPageVisible;
};
