import React, { PropsWithChildren } from 'react';

import styles from './CategorySelectPopUpList.module.scss';

interface CategorySelectPopUpListProps {
  title?: string;
}

const CategorySelectPopUpList: React.FC<CategorySelectPopUpListProps & PropsWithChildren> = ({
  children,
  title = '',
}) => (
  <>
    {title.length > 0 && <p className={styles.title}>{title}</p>}
    <ul className={styles.list}>{children}</ul>
  </>
);

export default CategorySelectPopUpList;
