import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { StyleableComponent, TestableComponent } from 'types';

import { testGetTestingAttributes } from '@testing';

import styles from './ModalFooter.module.scss';

export type ModalFooterProps = StyleableComponent & Pick<TestableComponent, 'propTestId'>;

const ModalFooter: React.FC<ModalFooterProps & PropsWithChildren> = ({ children, className, propTestId }) => (
  <div
    className={classNames(styles.root, className)}
    {...testGetTestingAttributes(propTestId)}
  >
    {children}
  </div>
);

export default ModalFooter;
