import {
  CallLogsService,
  CallLogResponse,
  DeleteCallLogRequest,
  EmptyResponse,
  RequestParams,
  SaveCallNoteRequest,
} from 'types';
import { GetCallLogsRequest } from 'types/server/main';

import { apiMobile } from 'api';

const callLogsService: CallLogsService = {
  fetchCallLogs: (offset) =>
    apiMobile.get<CallLogResponse[], GetCallLogsRequest>('/v2/retrieve-all-call-logs', {
      offset,
    }),

  deleteCallLog: (data: DeleteCallLogRequest) =>
    apiMobile.post<Promise<EmptyResponse>, DeleteCallLogRequest>('/delete-call-log', data),

  saveCallNote: (props: SaveCallNoteRequest, params: RequestParams = {}) =>
    apiMobile.post<Promise<EmptyResponse>, SaveCallNoteRequest>('/save-call-note', { ...props, ...params }),
};

export default callLogsService;
