import { useState, useEffect, RefObject } from 'react';
import { usePopper } from 'react-popper';

import { useClickAway } from 'hooks';

type PopperOptions = Parameters<typeof usePopper>[2];

type UseDropdownPopperOptions<D = HTMLUListElement, B = HTMLButtonElement> = {
  dropdownRef: RefObject<D>;
  buttonRef: RefObject<B>;
  popperOptions?: PopperOptions | null;
};

type UseDropdownPopperResult = {
  popper: ReturnType<typeof usePopper>;
  isDropdownOpen: boolean;
  openDropdown: () => void;
  closeDropdown: () => void;
};

const useDropdownPopper = (options: UseDropdownPopperOptions): UseDropdownPopperResult => {
  const { dropdownRef, buttonRef, popperOptions = null } = options;
  const defaultPopperOptions: PopperOptions = {
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 3] } }],
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { update, ...rest } = usePopper(
    buttonRef.current,
    dropdownRef.current,
    popperOptions === null ? defaultPopperOptions : popperOptions,
  );

  const openDropdown = () => setIsDropdownOpen(true);
  const closeDropdown = () => setIsDropdownOpen(false);

  useClickAway(dropdownRef, closeDropdown);

  useEffect(() => {
    if (isDropdownOpen && update !== null && dropdownRef.current !== null && buttonRef.current !== null) {
      update();
    }
  }, [isDropdownOpen, update, buttonRef, dropdownRef]);

  return {
    popper: {
      update,
      ...rest,
    },
    isDropdownOpen,
    openDropdown,
    closeDropdown,
  };
};

export default useDropdownPopper;
