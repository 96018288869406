import { useRef } from 'react';

interface UseFaviconReturn {
  element: HTMLLinkElement;
  initialHref: string;
}

export const useFavicon = (): UseFaviconReturn => {
  const element = document.querySelector("link[rel~='icon']") as HTMLLinkElement;

  const elementRef = useRef(element);
  const initialHrefRef = useRef(elementRef.current.href);

  return {
    element: elementRef.current,
    initialHref: initialHrefRef.current,
  };
};
