import parsePhoneNumberFromString, { CountryCode, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { showErrorToast } from '@onoff/toast-notification';

import { Category, Contact, SimpleContactPhone } from 'types';

import { getCategoryById, getCategoryPhoneNumber, getErrorDataFromUnknownError, handleApplicationError } from 'helpers';

import { API_ERRORS } from '@constants';
import { useWebAppSelector, selectCategoriesByCanMakeCalls, selectContactByPhoneNumber } from '@redux';
import { SIPStatisticsCallCTAs } from '@redux/types';
import { PhoneKeyboardCommon } from 'components/PhoneKeyboard';
import { useSIPCallMethodsOutgoing } from 'sip';

import ModalsDialpadCallButtonWrapper from '../ModalsDialpadCallButtonWrapper';
import ModalsDialpadSelectPhoneNumber from '../ModalsDialpadSelectPhoneNumber';

import styles from './ModalsDialpadContent.module.scss';

interface ModalsDialpadContentProps {
  prefilledPhoneNumberRemote: string;
}

const ModalsDialpadContent: React.FC<ModalsDialpadContentProps> = ({ prefilledPhoneNumberRemote = '' }) => {
  const intl = useIntl();

  const categoriesCanMakeCalls = useWebAppSelector(selectCategoriesByCanMakeCalls);

  const parsedPrefilledPhoneNumber = parsePhoneNumberFromString(prefilledPhoneNumberRemote);
  const isPrefilledPhoneNumberParsingFailed =
    prefilledPhoneNumberRemote.length > 0 && parsedPrefilledPhoneNumber === undefined;

  const initCountryCode = parsedPrefilledPhoneNumber?.country || 'FR';
  const initInputValue = parsedPrefilledPhoneNumber?.formatInternational() || prefilledPhoneNumberRemote;

  const [category, setCategory] = useState<Category | undefined>(categoriesCanMakeCalls[0]);
  const [countryCode, setCountryCode] = useState<CountryCode>(initCountryCode);
  const [inputValue, setInputValue] = useState<string>(initInputValue);
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | undefined>(parsedPrefilledPhoneNumber);
  const [hasError, setHasError] = useState<boolean>(isPrefilledPhoneNumberParsingFailed);

  const matchedContact = useWebAppSelector(selectContactByPhoneNumber(inputValue || ''));

  const { sipCallMake, canUserMakeCallsBySIP } = useSIPCallMethodsOutgoing();

  const handleChangeCategory = (valueCategory: Category): void => {
    setCategory(valueCategory);
    setHasError(false);
  };

  const handleChangeCountry = (newCountryCode: CountryCode): void => {
    setCountryCode(newCountryCode);
    setHasError(false);

    if (phoneNumber?.nationalNumber) {
      const currentNumberNational = phoneNumber.nationalNumber;
      const newNumber = parsePhoneNumber(currentNumberNational, newCountryCode);
      setPhoneNumber(newNumber);
      setInputValue(newNumber.formatInternational());
    }
  };

  const handleChangeInput = (valueInput: string): void => {
    setInputValue(valueInput);
    setHasError(false);
  };

  const handleClickKeyPad = (valueKeyPad: string): void => {
    const inputValueWithKeyPadValue = `${inputValue || ''}${valueKeyPad}`;
    setInputValue(inputValueWithKeyPadValue);
    setHasError(false);
  };

  const handleErrors = (errorMessage: string): void => {
    showErrorToast({
      heading: intl.formatMessage({ id: 'Notifications.Toast.title_error' }),
      message: errorMessage,
    });
    setHasError(true);
  };

  const handleOutgoingCall = async (localCategory: Category, callingPhoneNumber: PhoneNumber) => {
    setHasError(false);

    const phoneNumberLocal = getCategoryPhoneNumber(localCategory);
    const phoneNumberRemote = callingPhoneNumber.number.toString();

    try {
      await sipCallMake({
        phoneNumberLocal,
        phoneNumberRemote,
        analyticsCTA: SIPStatisticsCallCTAs.CTA_DIALPAD,
        analyticsHasContact: matchedContact !== undefined,
      });
    } catch (error) {
      const { errorCode } = getErrorDataFromUnknownError(error);

      if (errorCode === API_ERRORS.CALLING.INVALID_CALLEE_NUMBER) {
        setHasError(true);
      } else {
        handleApplicationError({ error });
      }
    }
  };

  const handleSetCategory = (categoryId?: string) => {
    const newCategory = getCategoryById(categoriesCanMakeCalls, categoryId);

    if (newCategory !== undefined) {
      setCategory(newCategory);
    }
  };

  const handleSelectContactPhone = (contact: Contact, phone: SimpleContactPhone): void => {
    handleSetCategory(contact.categoryId);

    setInputValue(phone.number || '');
  };

  const handleSubmit = (): void => {
    if (category === undefined) {
      handleErrors(
        intl.formatMessage({
          id: 'Categories.call_off',
        }),
      );

      return;
    }

    if (phoneNumber && phoneNumber.isValid()) {
      handleOutgoingCall(category, phoneNumber);
    } else {
      handleErrors(
        intl.formatMessage({
          id: 'InputErrors.General.PhoneNumberInvalid',
        }),
      );
    }
  };

  const callbackSetPhoneNumberHandler = (): void => {
    if (inputValue) {
      const normalizedInputValue = inputValue.trim();
      const currentCountry = parsePhoneNumberFromString(normalizedInputValue)?.country || countryCode;

      if (currentCountry !== countryCode) {
        setCountryCode(currentCountry);
      }

      setPhoneNumber(parsePhoneNumberFromString(normalizedInputValue, currentCountry));
    } else {
      setPhoneNumber(undefined);
    }
  };

  useEffect(callbackSetPhoneNumberHandler, [inputValue, countryCode]);

  return (
    <div className={styles.root}>
      <ModalsDialpadSelectPhoneNumber
        country={countryCode}
        isDisabled={!canUserMakeCallsBySIP}
        hasError={hasError}
        inputValue={inputValue}
        onChangeCountry={handleChangeCountry}
        onChangeInput={handleChangeInput}
        onSelectContactPhone={handleSelectContactPhone}
      />
      <PhoneKeyboardCommon
        isDisabled={!canUserMakeCallsBySIP}
        onClickKey={handleClickKeyPad}
        className={styles.phoneKeyboard}
      />
      <ModalsDialpadCallButtonWrapper
        isCallLoading={!canUserMakeCallsBySIP}
        categories={categoriesCanMakeCalls}
        currentCategory={category}
        onChangeCategory={handleChangeCategory}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default ModalsDialpadContent;
