import { useEffect, useState } from 'react';

import { selectUserData, useWebAppSelector } from '@redux';

type UseEmergencyCallsWarningModalReturn = {
  isEmergencyCallsWarningModalOpen: boolean;
  onEmergencyCallsWarningModalClose: () => void;
};

export const useEmergencyCallsWarningModal = (): UseEmergencyCallsWarningModalReturn => {
  const { showEmergencyCallsWarning } = useWebAppSelector(selectUserData);
  const [isEmergencyCallsWarningModalOpen, setIsEmergencyCallsWarningModalOpen] = useState(false);

  const onEmergencyCallsWarningModalClose = () => {
    setIsEmergencyCallsWarningModalOpen(false);
  };

  useEffect(() => {
    if (showEmergencyCallsWarning) {
      setIsEmergencyCallsWarningModalOpen(true);
    }
  }, [showEmergencyCallsWarning]);

  return {
    isEmergencyCallsWarningModalOpen,
    onEmergencyCallsWarningModalClose,
  };
};
