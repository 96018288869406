import React from 'react';

import { checkSIPAvailability, checkSIPDebugMode } from '../../helpers';
import { useSIP } from '../../hooks/useSIP';
import { SIPCalls } from '../SIPCalls';
import { SIPDebug } from '../SIPDebug';

import styles from './SIP.module.scss';

const SIP: React.FC = () => {
  const { isSIPAvailable } = checkSIPAvailability();
  const { isSIPDebugMode } = checkSIPDebugMode();

  useSIP();

  if (!isSIPAvailable) {
    return null;
  }

  return (
    <div className={styles.root}>
      <SIPCalls />
      {isSIPDebugMode ? <SIPDebug /> : null}
    </div>
  );
};

export default SIP;
