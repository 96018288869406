import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, CreditCardsReduxState, CreditCardsActionTypes } from '../../types';

export const creditCardsInitialState: CreditCardsReduxState = {
  status: Status.IDLE,
  data: [],
};

export const creditCardsReducer: Reducer<CreditCardsReduxState, CreditCardsActionTypes> = (state, action) => {
  const reducerState: CreditCardsReduxState = state || creditCardsInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.CREDITCARDS_SET_STATUS: {
      return {
        ...reducerState,
        status: action.status,
      };
    }

    case REDUX_ACTION_TYPES.CREDITCARDS_SET_DATA: {
      const { data } = action;

      return {
        ...reducerState,
        data,
      };
    }

    default:
      return reducerState;
  }
};
