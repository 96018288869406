import classnames from 'classnames';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { CategoryPlanStatusTypes } from 'types';

import { testIds } from '@testing';
import { ButtonDropdownCategory, ButtonFormIconText } from 'components/Button';
import { DropdownCategory, DropdownCategoryProps, useDropdownPopper } from 'components/Dropdowns';
import { InfoBoxSimple } from 'components/InfoBox';
import { InputText, InputCommonLayout, InputContactEmail, InputTextProps } from 'components/Input';
import { PhotoInputCommon } from 'components/PhotoInput';
import { TextAreaNewContact } from 'components/TextArea';

import { hasFullNameError, getPhoneErrorMessage, getEmailErrorMessage } from '../../helpers';
import { ModalsAddEditContactFormProps } from '../../types';
import ModalsAddEditContactPhoneInput from '../ModalsAddEditContactPhoneInput';

import styles from './ModalsAddEditContactForm.module.scss';

const ModalsAddEditContactForm: React.FC<ModalsAddEditContactFormProps> = ({
  formData,
  countryCode,
  className,
  countryList,
  errors,
  onChange = () => undefined,
  onAddPhone = () => undefined,
  onRemovePhone = () => undefined,
  onAddEmail = () => undefined,
  onRemoveEmail = () => undefined,
  categoryList = [],
  isUserB2B = false,
  isCRMContactModal = false,
}) => {
  const intl = useIntl();
  const dropdownCategoryRef = useRef<HTMLUListElement>(null);
  const buttonDropdownCategoryRef = useRef<HTMLButtonElement>(null);

  const { isDropdownOpen, openDropdown, closeDropdown, popper } = useDropdownPopper({
    dropdownRef: dropdownCategoryRef,
    buttonRef: buttonDropdownCategoryRef,
  });

  const { categoryId, firstName, lastName, company, notes, phones = [], emails = [] } = formData;

  const contactCategory = categoryList.find((item) => item.id === categoryId);
  const isContactCategoryExpired = contactCategory?.virtualPhoneNumber.planStatus === CategoryPlanStatusTypes.EXPIRED;

  const onSelectHandler: DropdownCategoryProps['onSelect'] = (value) => {
    onChange('categoryId', value);
    closeDropdown();
  };

  const onChangeFirstName: InputTextProps['onChange'] = (value) => {
    onChange('firstName', value);
    onChange('lastName', lastName);
  };

  const onChangeLastName: InputTextProps['onChange'] = (value) => {
    onChange('lastName', value);
    onChange('firstName', firstName);
  };

  const getRemovePhoneHandler = (index: number) => (phones.length > 1 ? () => onRemovePhone(index) : undefined);

  const getRemoveEmailHandler = (index: number) => (emails.length > 1 ? () => onRemoveEmail(index) : undefined);

  return (
    <div
      className={classnames(
        styles.root,
        {
          [styles.dropdownCategoryOpen]: isDropdownOpen,
          [styles.hasFullNameError]: hasFullNameError(errors),
        },
        className,
      )}
    >
      <div className={styles.rowPhoto}>
        <PhotoInputCommon
          backgroundColor={contactCategory?.color}
          imageUrl={formData.imageUrl}
          className={styles.photo}
          onChange={(value) => onChange('imageUrl', value)}
          propTestIds={{
            avatar: testIds.modalAddEditContactAvatar,
            buttonAdd: testIds.modalAddEditContactAvatarButtonAdd,
            buttonEdit: testIds.modalAddEditContactAvatarButtonEdit,
            input: testIds.modalAddEditContactAvatarInput,
            dropdown: testIds.modalAddEditContactAvatarDropdown,
            dropdownChange: testIds.modalAddEditContactAvatarDropdownChange,
            dropdownRemove: testIds.modalAddEditContactAvatarDropdownRemove,
          }}
        />
      </div>

      <div className={styles.infoBoxContainer}>
        {isCRMContactModal && (
          <InfoBoxSimple className={styles.infoBox}>
            <FormattedMessage id="FormAddContact.crmContactOverwriteWarning" />
          </InfoBoxSimple>
        )}

        {isContactCategoryExpired && (
          <InfoBoxSimple className={styles.infoBox}>
            <FormattedMessage id="FormAddContact.categoryExpiredWarning" />
          </InfoBoxSimple>
        )}
      </div>

      <div className={styles.cell}>
        <div className={styles.row2Cols}>
          <InputText
            className={styles.cell}
            error={errors.firstName?.message}
            isErrorMessageHidden
            onChange={onChangeFirstName}
            value={firstName}
            placeholder={intl.formatMessage({ id: 'FormAddContact.firstNamePlaceholder' })}
            label={intl.formatMessage({ id: 'FormAddContact.firstNameLabel' })}
            propTestId={testIds.modalAddEditContactInputNameFirst}
          />
          <InputText
            className={styles.cell}
            error={errors.lastName?.message}
            isErrorMessageHidden
            onChange={onChangeLastName}
            value={lastName}
            placeholder={intl.formatMessage({ id: 'FormAddContact.lastNamePlaceholder' })}
            propTestId={testIds.modalAddEditContactInputNameLast}
          />
        </div>
        <div className={styles.fullNameError}>{errors.firstName?.message || errors.lastName?.message}</div>
      </div>
      <div className={styles.row2Cols}>
        <InputText
          className={styles.cell}
          onChange={(value) => onChange('company', value)}
          value={company}
          placeholder={intl.formatMessage({ id: 'FormAddContact.companyPlaceholder' })}
          label={intl.formatMessage({ id: 'FormAddContact.companyLabel' })}
          propTestId={testIds.modalAddEditContactInputCompany}
        />
        <InputCommonLayout
          className={styles.categoryCell}
          label={intl.formatMessage({ id: 'FormAddContact.categoryLabel' })}
          isWrappedByDiv={true}
        >
          <ButtonDropdownCategory
            ref={buttonDropdownCategoryRef}
            className={styles.buttonDropdownCategory}
            onClick={openDropdown}
            category={contactCategory}
            propTestId={testIds.modalAddEditContactSelectCategoryButton}
          />
          <DropdownCategory
            ref={dropdownCategoryRef}
            style={popper.styles.popper}
            className={styles.dropdownCategory}
            isOpen={isDropdownOpen}
            categoryList={categoryList}
            selectedValue={categoryId}
            countryCode={countryCode}
            onSelect={onSelectHandler}
            propTestId={testIds.modalAddEditContactSelectCategoryDropdown}
          />
        </InputCommonLayout>
      </div>
      <div className={styles.list}>
        {phones.map((item, index) => (
          <ModalsAddEditContactPhoneInput
            key={item.id}
            label={index === 0 ? intl.formatMessage({ id: 'FormAddContact.phoneLabel' }) : ''}
            number={item.number}
            type={item.type}
            countryCode={countryCode}
            countryList={countryList}
            error={getPhoneErrorMessage(errors, index)}
            onChange={(value) => onChange(`phones.${index}.number`, value)}
            onRemovePhone={getRemovePhoneHandler(index)}
            onSelectPhoneType={(value) => onChange(`phones.${index}.type`, value)}
            propTestIds={{
              input: testIds.modalAddEditContactInputPhone,
              countryButton: testIds.modalAddEditContactInputPhoneCountryButton,
              categoryButton: testIds.modalAddEditContactInputPhoneCategoryButton,
              categoryDropdown: testIds.modalAddEditContactInputPhoneCategoryDropdown,
            }}
          />
        ))}
        <ButtonFormIconText
          isUserB2B={isUserB2B}
          onClick={onAddPhone}
          propTestId={testIds.modalAddEditContactInputPhoneButtonAdd}
        >
          {intl.formatMessage({ id: 'FormAddContact.addPhoneButton' })}
        </ButtonFormIconText>
      </div>
      <div className={styles.list}>
        {emails.map((item, index) => (
          <InputContactEmail
            key={item.id}
            label={index === 0 ? intl.formatMessage({ id: 'FormAddContact.emailLabel' }) : ''}
            email={item.email}
            error={getEmailErrorMessage(errors, index)}
            type={item.type}
            onChange={(value) => onChange(`emails.${index}.email`, value)}
            onRemoveEmail={getRemoveEmailHandler(index)}
            onSelectEmailType={(value) => onChange(`emails.${index}.type`, value)}
            propTestIds={{
              input: testIds.modalAddEditContactInputEmail,
              categoryButton: testIds.modalAddEditContactInputEmailCategoryButton,
              categoryDropdown: testIds.modalAddEditContactInputEmailCategoryDropdown,
            }}
          />
        ))}
        <ButtonFormIconText
          isUserB2B={isUserB2B}
          onClick={onAddEmail}
          propTestId={testIds.modalAddEditContactInputEmailButtonAdd}
        >
          {intl.formatMessage({ id: 'FormAddContact.addEmailButton' })}
        </ButtonFormIconText>
      </div>
      <div className={styles.row}>
        <TextAreaNewContact
          onChange={(value) => onChange('notes', value)}
          placeholder={intl.formatMessage({ id: 'FormAddContact.notesPlaceholder' })}
          label={intl.formatMessage({ id: 'FormAddContact.notesLabel' })}
          value={notes}
          propTestId={testIds.modalAddEditContactTextareaNotes}
        />
      </div>
    </div>
  );
};

export default ModalsAddEditContactForm;
