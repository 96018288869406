import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useWebAppDispatch,
  useWebAppSelector,
  browserTabsSetCurrentBrowserTabAsActiveHandler,
  selectIsCurrentBrowserTabActive,
} from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';
import Banner from 'components/Banner';

import styles from './InfoBannerSIPCallsUnavailable.module.scss';

const InfoBannerSIPCallsUnavailable: React.FC = () => {
  const dispatch = useWebAppDispatch();
  const isActiveTab = useWebAppSelector(selectIsCurrentBrowserTabActive);

  const onClickHandlerButton = (): void => {
    dispatch(browserTabsSetCurrentBrowserTabAsActiveHandler());
  };

  if (isActiveTab) {
    return null;
  }

  return (
    <Banner colorScheme="destructive">
      <div {...testGetTestingAttributes(testIds.layoutInfoBarSIPCallsMultipleBrowserTabText)}>
        <FormattedMessage
          id="Layouts.InfoBar.sip_calls_multiple_browser_tab"
          values={{
            buttonText: (
              <button
                className={styles.link}
                onClick={onClickHandlerButton}
                {...testGetTestingAttributes(testIds.layoutInfoBarSIPCallsMultipleBrowserTabButton)}
              >
                <FormattedMessage id="Layouts.InfoBar.sip_calls_multiple_browser_tab_button_text" />
              </button>
            ),
          }}
        />
      </div>
    </Banner>
  );
};

export default InfoBannerSIPCallsUnavailable;
