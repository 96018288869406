import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';

import { authenticationHasAuth } from 'helpers';

import { LayoutFullWidth } from 'layouts';
import { ErrorNotFoundPage } from 'pages/error';

import { ApplicationPaths } from '../../types';

const RoutesWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { state, pathname, search, hash: hashFromLocation } = useLocation();
  const { redirectUri, hash: hashFromState } = state || {};
  const hash = hashFromLocation || hashFromState;

  const isAuthenticated = authenticationHasAuth();
  const isRouteRoot = pathname === ApplicationPaths.ROOT;
  const isRouteAnyExceptRoot = !isRouteRoot;
  const isRouteReset = pathname === ApplicationPaths.RESET;
  const isRoutePasswordRecovery = pathname === ApplicationPaths.PASSWORD_RECOVERY;
  const isRouteEmailUnsubscribe = pathname === ApplicationPaths.EMAIL_UNSUBSCRIBE;
  const isRoute404 = Object.values(ApplicationPaths).includes(pathname as ApplicationPaths) === false;

  /**
   * If "NOT" Authenticated,
   * If the Route is *(ANY) except "ROOT"
   * If the Route is "NOT" "RESET"
   * If the Route is "NOT" "PASSWORD_RECOVERY"
   * If the Route is "NOT" "EMAIL_UNSUBSCRIBE"
   *
   * Redirect to the Login with "redirectUri"
   */
  if (
    !isAuthenticated &&
    isRouteAnyExceptRoot &&
    !isRouteReset &&
    !isRoutePasswordRecovery &&
    !isRouteEmailUnsubscribe
  ) {
    return (
      <Navigate
        to={{ pathname: ApplicationPaths.ROOT, search }}
        state={{ redirectUri: pathname, hash }}
      />
    );
  }

  /**
   * If Authenticated,
   * If the Route is "PASSWORD_RECOVERY"
   *
   * Redirect to the "SETTINGS_ACCOUNT"
   */
  if (isAuthenticated && isRoutePasswordRecovery) {
    return (
      <Navigate
        to={{
          pathname: ApplicationPaths.SETTINGS_ACCOUNT,
          hash,
        }}
      />
    );
  }

  /**
   * If Authenticated,
   * If the Route is "ROOT"
   *
   * Redirect to the "CALLS" (Calls is our main page after login)
   */
  if (isAuthenticated && isRouteRoot) {
    return (
      <Navigate
        to={{
          pathname: redirectUri || ApplicationPaths.CALLS,
          search,
          hash,
        }}
      />
    );
  }

  /**
   * Any paths that not part of "ApplicationPaths"
   *
   * Redirect to the "NotFound"
   */
  if (isRoute404) {
    return (
      <LayoutFullWidth
        isAvailableOnMobile
        children={<ErrorNotFoundPage />}
      />
    );
  }

  /**
   * Else, return the children
   */
  return <>{children}</>;
};

export default RoutesWrapper;
