import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { BorderWrapperCoreProps } from '../../types';

import styles from './BorderWrapperCore.module.scss';

const BorderWrapperCore: React.FC<BorderWrapperCoreProps & PropsWithChildren> = ({
  children,
  onMouseOver,
  onMouseLeave,
  className = '',
  hasFocus = false,
  hasHover = false,
  isEmpty = false,
  isDisabled = false,
  hasError = false,
}) => (
  <div
    className={classNames(
      styles.root,
      {
        [styles.hasFocus]: hasFocus,
        [styles.hasHover]: hasHover,
        [styles.hasError]: hasError,
        [styles.isDisabled]: isDisabled,
        [styles.isEmpty]: isEmpty,
      },
      className,
    )}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </div>
);

export default BorderWrapperCore;
