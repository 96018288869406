export const testIds = {
  default: '',

  // Category Color Picker
  categoryColorPicker: 'category-color-picker',

  // Category Switches
  categorySwitchRoot: 'category-switch-root',
  categorySwitchCalls: 'category-switch-calls',
  categorySwitchMessages: 'category-switch-messages',
  categorySwitchVoicemails: 'category-switch-voicemails',
  categorySwitchPrivate: 'category-switch-private',
  categorySwitchHide: 'category-switch-hide',

  // Contacts
  contactsNewContactButton: 'contacts-new-contact-button',
  contactsContactInfoName: 'contacts-contact-info-name',
  contactsContactListDisplayName: 'contacts-contact-list-display-name',
  contactsInfoTopBarButtonMore: 'contacts-info-top-bar-button-more',
  contactsButtonSwitchFavoriteListOff: 'contacts-button-switch-favorite-list-off',
  contactsButtonSwitchFavoriteListOn: 'contacts-button-switch-favorite-list-on',
  contactsDetailPhoneGroup: 'contacts-detail-phone-group',
  contactsDetailPhoneItem: 'contacts-detail-phone-item',
  contactsDetailEmailGroup: 'contacts-detail-email-group',
  contactsDetailEmailItem: 'contacts-detail-email-item',
  contactsDetailCompanyGroup: 'contacts-detail-company-group',
  contactsDetailCompanyItem: 'contacts-detail-company-item',

  // Contacts Modals
  modalAddEditContactTitle: 'modal-add-edit-contact-title',
  modalAddEditContactButtonClose: 'modal-add-edit-contact-button-close',
  modalAddEditContactButtonConfirm: 'modal-add-edit-contact-button-confirm',
  modalAddEditContactAvatar: 'modal-add-edit-contact-avatar',
  modalAddEditContactAvatarButtonAdd: 'modal-add-edit-contact-avatar-button-add',
  modalAddEditContactAvatarButtonEdit: 'modal-add-edit-contact-avatar-button-edit',
  modalAddEditContactAvatarInput: 'modal-add-edit-contact-avatar-input',
  modalAddEditContactAvatarDropdown: 'modal-add-edit-contact-avatar-dropdown',
  modalAddEditContactAvatarDropdownChange: 'modal-add-edit-contact-avatar-dropdown-change',
  modalAddEditContactAvatarDropdownRemove: 'modal-add-edit-contact-avatar-dropdown-remove',
  modalAddEditContactInputNameFirst: 'modal-add-edit-contact-input-name-first',
  modalAddEditContactInputNameLast: 'modal-add-edit-contact-input-name-last',
  modalAddEditContactInputCompany: 'modal-add-edit-contact-input-company',
  modalAddEditContactSelectCategoryButton: 'modal-add-edit-contact-select-category-button',
  modalAddEditContactSelectCategoryDropdown: 'modal-add-edit-contact-select-category-dropdown',
  modalAddEditContactInputPhone: 'modal-add-edit-contact-input-phone',
  modalAddEditContactInputPhoneCountryButton: 'modal-add-edit-contact-input-phone-country-button',
  modalAddEditContactInputPhoneCategoryButton: 'modal-add-edit-contact-input-phone-category-button',
  modalAddEditContactInputPhoneCategoryDropdown: 'modal-add-edit-contact-input-phone-category-dropdown',
  modalAddEditContactInputPhoneButtonAdd: 'modal-add-edit-contact-input-phone-button-add',
  modalAddEditContactInputEmail: 'modal-add-edit-contact-input-email',
  modalAddEditContactInputEmailCategoryButton: 'modal-add-edit-contact-input-email-category-button',
  modalAddEditContactInputEmailCategoryDropdown: 'modal-add-edit-contact-input-email-category-dropdown',
  modalAddEditContactInputEmailButtonAdd: 'modal-add-edit-contact-input-email-button-add',
  modalAddEditContactTextareaNotes: 'modal-add-edit-contact-textarea-notes',

  // Confirmation Modal
  modalConfirmationButtonConfirm: 'modal-confirmation-button-confirm',
  modalConfirmationButtonCancel: 'modal-confirmation-button-cancel',

  // CallQualitySurvey Modal
  modalCallQualitySurveyRatingButton: 'modal-call-quality-survey-rating-button',
  modalCallQualitySurveySubmitButton: 'modal-call-quality-survey-submit-button',
  modalCallQualitySurveyGiveReasonContainer: 'modal-call-quality-survey-give-reason-container',
  modalCallQualitySurveyGiveReasonTextarea: 'modal-call-quality-survey-give-reason-textarea',

  // Page Empty State
  pageEmptyStateRoot: 'page-empty-state-root',
  pageEmptyStateIllustration: 'page-empty-state-illustration',
  pageEmptyStateIllustrationCalls: 'page-empty-state-illustration-calls',
  pageEmptyStateIllustrationMessages: 'page-empty-state-illustration-messages',
  pageEmptyStateIllustrationVoicemails: 'page-empty-state-illustration-voicemails',
  pageEmptyStateIllustrationContacts: 'page-empty-state-illustration-contacts',
  pageEmptyStateTitle: 'page-empty-state-title',
  pageEmptyStateDescription: 'page-empty-state-description',
  pageEmptyStateButton: 'page-empty-state-button',

  // Page UnFocused State
  pageUnFocusedStateRoot: 'page-unfocused-state-root',
  pageUnFocusedStateIllustration: 'page-unfocused-state-illustration',
  pageUnFocusedStateIllustrationCalls: 'page-unfocused-state-illustration-calls',
  pageUnFocusedStateIllustrationMessages: 'page-unfocused-state-illustration-messages',
  pageUnFocusedStateIllustrationVoicemails: 'page-unfocused-state-illustration-voicemails',
  pageUnFocusedStateIllustrationContacts: 'page-unfocused-state-illustration-contacts',
  pageUnFocusedStateTitle: 'page-unfocused-state-title',
  pageUnFocusedStateDescription: 'page-unfocused-state-description',

  // Dialog
  modalDialogTitle: 'modal-dialog-title',
  modalDialogContent: 'modal-dialog-content',
  modalDialogButton: 'modal-dialog-button',

  // Integration CRM:
  integrationCrmCardHeader: 'integration-crm-card-header',
  integrationCrmCardHeaderOpenButton: 'integration-crm-card-header-open-button',
  integrationCrmCardContent: 'integration-crm-card-content',

  // Login page
  emailInput: 'email',
  passwordInput: 'password',

  // SIP Calls UI
  sipCallsListRoot: 'sip-calls-list-root',
  sipCallsListItemRoot: 'sip-calls-list-item-root',
  sipCallsListItemUIDefaultRoot: 'sip-calls-list-item-ui-default-root',
  sipCallsListItemUIDefaultHeader: 'sip-calls-list-item-ui-default-header',
  sipCallsListItemUIDefaultBody: 'sip-calls-list-item-ui-default-body',
  sipCallsListItemUIDefaultFooter: 'sip-calls-list-item-ui-default-footer',
  sipCallsListItemUIMinimizedRoot: 'sip-calls-list-item-ui-minimized-root',
  sipCallsListItemUICompletedRoot: 'sip-calls-list-item-ui-completed-root',
  sipCallsListItemUICompletedButtonMore: 'sip-calls-list-item-ui-completed-button-more',
  sipCallsListItemUICompletedButtonDismiss: 'sip-calls-list-item-ui-completed-button-dismiss',
  sipCallsListItemUICompletedModalCallOutcomes: 'sip-calls-list-item-ui-completed-modal-call-outcomes',
  sipCallsListItemButtonUIRingtoneRoot: 'sip-calls-list-item-button-ui-ringtone-root',
  sipCallsListItemButtonUIViewRoot: 'sip-calls-list-item-button-ui-view-root',
  sipCallsListItemIconStatesWaiting: 'sip-calls-list-item-icon-states-waiting',
  sipCallsListItemIconStatesLoading: 'sip-calls-list-item-icon-states-loading',
  sipCallsListItemIconStatesOutgoing: 'sip-calls-list-item-icon-states-outgoing',
  sipCallsListItemIconStatesIncoming: 'sip-calls-list-item-icon-states-incoming',
  sipCallsListItemInfoContactRoot: 'sip-calls-list-item-info-contact-root',
  sipCallsListItemInfoStatesInitializing: 'sip-calls-list-item-info-states-initializing',
  sipCallsListItemInfoStatesWaiting: 'sip-calls-list-item-info-states-waiting',
  sipCallsListItemInfoStatesTimer: 'sip-calls-list-item-info-states-timer',
  sipCallsListItemInfoStatesOutgoingPreparing: 'sip-calls-list-item-info-states-outgoing-preparing',
  sipCallsListItemInfoStatesOutgoingRinging: 'sip-calls-list-item-info-states-outgoing-ringing',
  sipCallsListItemInfoStatesIncomingRinging: 'sip-calls-list-item-info-states-incoming-ringing',
  sipCallsListItemInfoStatesIncomingConnecting: 'sip-calls-list-item-info-states-incoming-connecting',
  sipCallsListItemButtonFeatureAnswerRoot: 'sip-calls-list-item-button-feature-answer-root',
  sipCallsListItemButtonFeatureDTMFRoot: 'sip-calls-list-item-button-feature-dtmf-root',
  sipCallsListItemButtonFeatureDTMFButton: 'sip-calls-list-item-button-feature-dtmf-button',
  sipCallsListItemButtonFeatureHoldRoot: 'sip-calls-list-item-button-feature-hold-root',
  sipCallsListItemButtonFeatureUnHoldRoot: 'sip-calls-list-item-button-feature-unhold-root',
  sipCallsListItemButtonFeatureMuteRoot: 'sip-calls-list-item-button-feature-mute-root',
  sipCallsListItemButtonFeatureUnMuteRoot: 'sip-calls-list-item-button-feature-unmute-root',
  sipCallsListItemButtonFeatureRejectRoot: 'sip-calls-list-item-button-feature-reject-root',
  sipCallsListItemButtonFeatureTerminateRoot: 'sip-calls-list-item-button-feature-terminate-root',
  sipCallsListItemButtonFeatureCallNotesRoot: 'sip-calls-list-item-button-feature-call-notes-root',
  sipCallsListItemDropdownDTMFRoot: 'sip-calls-list-item-dropdown-dtmf-root',
  sipCallsListItemAudioRingbackRoot: 'sip-calls-list-item-audio-ringback-root',
  sipCallsListItemButtonMoreMenuTrigger: 'sip-calls-list-item-button-more-menu-trigger',
  sipCallsListItemButtonMoreMenuCallNotesButton: 'sip-calls-list-item-button-more-menu-call-notes-button',
  sipCallsListItemButtonMoreMenuCallTransferButton: 'sip-calls-list-item-button-more-menu-call-transfer-button',

  // Pagination
  paginationControls: 'pagination-controls',
  paginationButtonPrevious: 'pagination-button-previous',
  paginationButtonNext: 'pagination-button-next',
  paginationInfo: 'pagination-info',

  // Add credit card modal
  addCreditCardInputFirstname: 'add-credit-card-input-firstname',
  addCreditCardInputLastname: 'add-credit-card-input-lastname',
  addCreditCardInputWrapperCardNumber: 'add-credit-card-input-wrapper-card-number',
  addCreditCardInputWrapperCardExpiryDate: 'add-credit-card-input-wrapper-card-expiry-date',
  addCreditCardInputWrapperCardCvv: 'add-credit-card-input-wrapper-card-cvv',
  addCreditCardInputCity: 'add-credit-card-input-city',
  addCreditCardInputAddress: 'add-credit-card-input-address',
  addCreditCardInputZip: 'add-credit-card-input-zip',
  addCreditCardButtonCancel: 'add-credit-card-button-cancel',
  addCreditCardButtonSubmit: 'add-credit-card-button-submit',

  // Premium page
  premiumOfferTrialInfoBox: 'premium-offer-trial-info-box',
  premiumOfferInfoBox: 'premium-offer-info-box',

  // InfoTopBarActions and Dropdown
  infoTopBarActionsRoot: 'info-topbar-actions-root',
  infoTopBarActionsButtonContactsFavorite: 'info-topbar-actions-button-contacts-favorite',
  infoTopBarActionsButtonContactsUnFavorite: 'info-topbar-actions-button-contacts-unfavorite',
  infoTopBarActionsButtonContactsEdit: 'info-topbar-actions-button-contacts-edit',
  infoTopBarActionsButtonCallsMakeCall: 'info-topbar-actions-button-calls-make-call',
  infoTopBarActionsButtonMessagesSendSMS: 'info-topbar-actions-button-messages-send-sms',
  infoTopBarActionsButtonMessagesGroup: 'info-topbar-actions-button-messages-group',
  infoTopBarActionsButtonMore: 'info-topbar-actions-button-more',
  infoTopBarActionsDropdownRoot: 'info-topbar-actions-dropdown-root',
  infoTopBarActionsDropdownButtonContactsCRM: 'info-topbar-actions-dropdown-button-contacts-crm',
  infoTopBarActionsDropdownButtonContactsAdd: 'info-topbar-actions-dropdown-button-contacts-add',
  infoTopBarActionsDropdownButtonContactsEdit: 'info-topbar-actions-dropdown-button-contacts-edit',
  infoTopBarActionsDropdownButtonContactsFavorite: 'info-topbar-actions-dropdown-button-contacts-favorite',
  infoTopBarActionsDropdownButtonContactsUnFavorite: 'info-topbar-actions-dropdown-button-contacts-unfavorite',
  infoTopBarActionsDropdownButtonContactsBlock: 'info-topbar-actions-dropdown-button-contacts-block',
  infoTopBarActionsDropdownButtonContactsUnBlock: 'info-topbar-actions-dropdown-button-contacts-unblock',
  infoTopBarActionsDropdownButtonPhoneNumberBlock: 'info-topbar-actions-dropdown-button-phone-number-block',
  infoTopBarActionsDropdownButtonPhoneNumberUnBlock: 'info-topbar-actions-dropdown-button-phone-number-unblock',
  infoTopBarActionsDropdownButtonDeleteCallLog: 'info-topbar-actions-dropdown-button-delete-call-log',
  infoTopBarActionsDropdownButtonDeleteConversation: 'info-topbar-actions-dropdown-button-delete-conversation',
  infoTopBarActionsDropdownButtonDeleteVoicemail: 'info-topbar-actions-dropdown-button-delete-voicemail',
  infoTopBarActionsDropdownButtonDeleteContact: 'info-topbar-actions-dropdown-button-delete-contact',

  // Layouts
  layoutInfoBarSIPCallsMultipleBrowserTabText: 'layout-info-bar-sip-calls-multiple-browser-tab-text',
  layoutInfoBarSIPCallsMultipleBrowserTabButton: 'layout-info-bar-sip-calls-multiple-browser-tab-button',
  layoutInfoBarSIPCallsUnavailableText: 'layout-info-bar-sip-calls-unavailable-text',

  // Toast notification. These are temporary testids to match @onoff/toast-notification ones
  // TODO :: Remove after https://onoffapp.atlassian.net/browse/WAP-2883 is completed
  toastNotificationTitle: 'onoff-toast-notification-title',
  toastNotificationDescription: 'onoff-toast-notification-description',
  toastNotificationCloseButton: 'onoff-toast-notification-close-button',
} as const;

export type TestIds = typeof testIds;
