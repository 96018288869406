import classnames from 'classnames';
import React, { PropsWithChildren, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropDown as IconDropDown } from '@onoff/icons';

import styles from './CategoryCommonOptions.module.scss';

const CategoryCommonOptions: React.FC<PropsWithChildren> = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onClickHandlerButtonExpand = (): void => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={classnames(styles.root, {
        [styles.isExpanded]: isExpanded,
      })}
    >
      <button
        type="button"
        className={styles.expand}
        onClick={onClickHandlerButtonExpand}
      >
        <span className={styles.expandText}>
          <FormattedMessage id={'Categories.options'} />
        </span>
        <IconDropDown className={styles.expandIcon} />
      </button>

      {isExpanded && <div className={styles.options}>{children}</div>}
    </div>
  );
};

export default CategoryCommonOptions;
