import { MultiDeviceDevice, MultiDeviceDeviceType, MultiDeviceDeviceWithRequiredDeviceId, Status } from 'types';

import { isString, multiDeviceIsDesktop } from 'helpers';

import { LOCALSTORAGE_KEYS } from '@constants';

import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  MultiDeviceSetDevicesAction,
  MultiDeviceSetStatusFetchDeviceListAction,
  MultiDeviceSetStatusLogoutDeviceAction,
  MultiDeviceUpdateDeviceAction,
} from '../../types';

export const multiDeviceSetStatusFetchDeviceList = (status: Status): MultiDeviceSetStatusFetchDeviceListAction => ({
  type: REDUX_ACTION_TYPES.MULTI_DEVICE_SET_STATUS_FETCH_DEVICE_LIST,
  status,
});

export const multiDeviceSetStatusLogoutDevice = (status: Status): MultiDeviceSetStatusLogoutDeviceAction => ({
  type: REDUX_ACTION_TYPES.MULTI_DEVICE_SET_STATUS_LOGOUT_DEVICE,
  status,
});

export const multiDeviceSetDevices = (devices: Array<MultiDeviceDevice>): MultiDeviceSetDevicesAction => ({
  type: REDUX_ACTION_TYPES.MULTI_DEVICE_SET_DEVICES,
  devices,
});

export const multiDeviceUpdateDevice = (payload: {
  id: string;
  device: Partial<MultiDeviceDevice>;
}): MultiDeviceUpdateDeviceAction => ({
  type: REDUX_ACTION_TYPES.MULTI_DEVICE_UPDATE_DEVICE,
  payload,
});

export const multiDeviceFetchDeviceListHandler = (): ThunkResult<Promise<void>> => async (dispatch, _, services) => {
  try {
    dispatch(multiDeviceSetStatusFetchDeviceList(Status.LOADING));
    const { devices: devicesResponse } = await services.multiDeviceService.fetchDeviceList();

    if (devicesResponse === undefined) {
      throw new Error('Dev Error: missing property in server response');
    }

    const devices: Array<MultiDeviceDevice> = devicesResponse
      .filter((device): device is MultiDeviceDeviceWithRequiredDeviceId => isString(device.deviceId))
      .map((device) => ({
        ...device,
        deviceType: multiDeviceIsDesktop(device) ? MultiDeviceDeviceType.DESKTOP : MultiDeviceDeviceType.MOBILE,
        isOwnDevice: window.localStorage.getItem(LOCALSTORAGE_KEYS.USER_DEVICE_ID) === device.deviceId,
      }));

    dispatch(multiDeviceSetDevices(devices));
    dispatch(multiDeviceSetStatusFetchDeviceList(Status.SUCCEEDED));
  } catch (error) {
    dispatch(multiDeviceSetStatusFetchDeviceList(Status.FAILED));
    throw error;
  }
};

export const multiDeviceLogoutDeviceHandler =
  (id: string): ThunkResult<Promise<void>> =>
  async (dispatch, _, services) => {
    try {
      dispatch(multiDeviceSetStatusLogoutDevice(Status.LOADING));

      await services.multiDeviceService.logoutDevices([id]);

      dispatch(multiDeviceUpdateDevice({ id, device: { loggedIn: false } }));
      dispatch(multiDeviceSetStatusLogoutDevice(Status.SUCCEEDED));
    } catch (error) {
      dispatch(multiDeviceSetStatusLogoutDevice(Status.FAILED));
      throw error;
    }
  };
