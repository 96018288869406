import { AppStore, SubscriptionStatus } from 'types';

import { isNumber, isObject, isString } from 'helpers';

import { PlansAndOffersPlan, PlansAndOffersUserOffer } from '@redux/types';

import { PlanPrices } from '../types';

export const calculatePlanPrices = (plans?: PlansAndOffersPlan[]): PlanPrices[] => {
  if (!plans) {
    return [];
  }

  const prices: PlanPrices[] = [];

  plans.forEach((plan, index) => {
    const { duration: period, currencyCode, price } = plan;
    const pricePerMonth = plan.price / plan.duration;
    const savings = index === 0 ? 0 : Math.round(((prices[0].price - pricePerMonth) / prices[0].price) * 100);

    prices.push({ currencyCode, period, price, pricePerMonth, savings });
  });

  return prices;
};

export const isProductPremiumOffer = (input: unknown): input is PlansAndOffersUserOffer =>
  isObject(input) &&
  Object.keys(input).every((key) =>
    ['subscriptionId', 'status', 'source', 'expirationDate', 'planDuration', 'nextPlanDuration'].includes(key),
  ) &&
  (!input.subscriptionId || isString(input.subscriptionId)) &&
  (!input.status || Object.values(SubscriptionStatus).includes(input.status as SubscriptionStatus)) &&
  (!input.source || Object.values(AppStore).includes(input.source as AppStore)) &&
  (!input.expirationDate || isString(input.expirationDate)) &&
  (!input.planDuration || isNumber(input.planDuration)) &&
  (!input.nextPlanDuration || isNumber(input.nextPlanDuration));
