import classNames from 'classnames';
import { forwardRef, PropsWithChildren } from 'react';

import { testGetTestingAttributes } from '@testing';
import { BorderWrapperCore } from 'components/BorderWrapper';
import { IconCommon, IconCommonNames } from 'components/Icon';

import { ButtonDropdownCoreProps } from '../../types';

import styles from './ButtonDropdownCore.module.scss';

const ButtonDropdownCore = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonDropdownCoreProps>>(
  ({ className, onClick, children, propTestId = '' }, ref) => (
    <button
      ref={ref}
      className={classNames(styles.root, className)}
      type="button"
      onClick={onClick}
      {...testGetTestingAttributes(propTestId)}
    >
      <BorderWrapperCore className={classNames(styles.borderWrapper)}>
        <div className={styles.children}>{children}</div>
        <IconCommon
          name={IconCommonNames.DROP_DOWN}
          className={styles.icon}
        />
      </BorderWrapperCore>
    </button>
  ),
);

export default ButtonDropdownCore;
