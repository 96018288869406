import React from 'react';

import { StyleableComponent } from 'types';

const SubscriptionRenewal: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M200 100C200 155.228 155.228 200 100 200C44.7715 200 0 155.228 0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 99.9998C187.368 148.252 148.252 187.368 100 187.368C51.7478 187.368 12.6316 148.252 12.6316 99.9998C12.6316 51.7475 51.7478 12.6313 100 12.6313C148.252 12.6313 187.368 51.7475 187.368 99.9998Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 99.9998C172.105 139.822 139.823 172.105 100 172.105C60.1773 172.105 27.8947 139.822 27.8947 99.9998C27.8947 60.1772 60.1773 27.8945 100 27.8945C139.823 27.8945 172.105 60.1772 172.105 99.9998Z"
      fill="#F3F4F5"
    />
    <path
      opacity="0.2"
      d="M178.773 130.242L143.78 21.4051C142.783 18.3057 139.462 16.6008 136.363 17.5977L31.9391 51.1725C28.8397 52.1694 27.1348 55.4898 28.1317 58.5893L63.1253 167.426C64.1222 170.526 67.4426 172.23 70.542 171.234L174.966 137.659C178.065 136.662 179.77 133.341 178.773 130.242Z"
      fill="#E4E5E7"
    />
    <path
      d="M171.119 135.346L66.6951 168.921C63.5956 169.917 60.2752 168.213 59.2783 165.113L24.2847 56.2763C23.2878 53.1768 24.9927 49.8564 28.0921 48.8595L132.516 15.2847C135.615 14.2878 138.936 15.9927 139.933 19.0921L174.926 127.929C175.923 131.028 174.218 134.349 171.119 135.346Z"
      fill="white"
    />
    <path
      d="M52.9672 64.3427L39.4984 68.6732C37.8961 69.1886 36.1801 68.3074 35.6647 66.705C35.1493 65.1026 36.0305 63.3867 37.6328 62.8712L51.1016 58.5408C52.7039 58.0254 54.4199 58.9066 54.9353 60.5089C55.4507 62.1113 54.5695 63.8273 52.9672 64.3427Z"
      fill="#E3E4E8"
    />
    <path
      d="M57.4314 78.226L43.9627 82.5565C42.3603 83.0719 40.6443 82.1907 40.1289 80.5883C39.6135 78.9859 40.4947 77.27 42.097 76.7545L55.5658 72.4241C57.1681 71.9087 58.8841 72.7899 59.3995 74.3922C59.915 75.9946 59.0338 77.7113 57.4314 78.226Z"
      fill="#E3E4E8"
    />
    <path
      d="M61.895 92.1093L48.4262 96.4397C46.8239 96.9552 45.1079 96.074 44.5925 94.4716C44.0771 92.8692 44.9582 91.1533 46.5606 90.6378L60.0293 86.3074C61.6317 85.792 63.3477 86.6731 63.8631 88.2755C64.3785 89.8779 63.4973 91.5939 61.895 92.1093Z"
      fill="#E3E4E8"
    />
    <path
      d="M66.359 105.993L52.8903 110.324C51.2879 110.839 49.5719 109.958 49.0565 108.355C48.5411 106.753 49.4223 105.037 51.0247 104.522L64.4934 100.191C66.0958 99.6757 67.8117 100.557 68.3272 102.159C68.8426 103.761 67.9614 105.478 66.359 105.993Z"
      fill="#E3E4E8"
    />
    <path
      d="M70.8226 119.876L57.3538 124.207C55.7515 124.722 54.0355 123.841 53.5201 122.239C53.0047 120.636 53.8859 118.92 55.4882 118.405L68.957 114.074C70.5593 113.559 72.2753 114.44 72.7907 116.043C73.3061 117.645 72.4249 119.362 70.8226 119.876Z"
      fill="#E3E4E8"
    />
    <path
      d="M75.2866 133.76L61.8179 138.09C60.2155 138.606 58.4995 137.724 57.9841 136.122C57.4687 134.52 58.3499 132.804 59.9523 132.288L73.421 127.958C75.0234 127.442 76.7394 128.324 77.2548 129.926C77.7702 131.528 76.889 133.244 75.2866 133.76Z"
      fill="#E3E4E8"
    />
    <path
      d="M79.7502 147.643L66.2815 151.973C64.6791 152.489 62.9631 151.608 62.4477 150.005C61.9323 148.403 62.8135 146.687 64.4158 146.172L77.8846 141.841C79.4869 141.326 81.2029 142.207 81.7183 143.809C82.2338 145.412 81.3526 147.128 79.7502 147.643Z"
      fill="#E3E4E8"
    />
    <path
      d="M102.752 146.649C102.18 146.834 101.567 146.518 101.383 145.946L69.6697 47.3117C69.4854 46.7394 69.8006 46.1263 70.3728 45.9428C70.9451 45.7585 71.5582 46.0737 71.7418 46.6459L103.455 145.28C103.639 145.853 103.324 146.465 102.752 146.649Z"
      fill="#F4F4F4"
    />
    <path
      d="M127.41 138.722C126.838 138.906 126.225 138.591 126.041 138.018L94.328 39.3839C94.1437 38.8117 94.4589 38.1986 95.0312 38.015C95.6034 37.8308 96.2165 38.146 96.4001 38.7182L128.113 137.353C128.298 137.924 127.983 138.537 127.41 138.722Z"
      fill="#F4F4F4"
    />
    <path
      d="M152.069 130.793C151.497 130.977 150.884 130.662 150.7 130.09L118.987 31.4552C118.803 30.883 119.118 30.2699 119.69 30.0863C120.262 29.902 120.875 30.2172 121.059 30.7895L152.772 129.424C152.956 129.996 152.642 130.609 152.069 130.793Z"
      fill="#F4F4F4"
    />
    <path
      d="M83.0339 56.7902L66.4048 62.1369C65.0747 62.5644 63.649 61.8328 63.2216 60.5027L61.6725 55.6852C61.2451 54.3551 61.9767 52.9294 63.3068 52.502L79.9359 47.1552C81.266 46.7278 82.6917 47.4593 83.1192 48.7894L84.6682 53.6069C85.0963 54.937 84.3647 56.3627 83.0339 56.7902Z"
      fill="currentColor"
    />
    <path
      d="M96.5096 67.7763L75.0408 74.679C73.5521 75.1577 71.9566 74.3388 71.4779 72.8501L70.1132 68.6062C69.6345 67.1174 70.4533 65.522 71.9421 65.0433L93.4109 58.1406C94.8996 57.6619 96.4951 58.4807 96.9738 59.9695L98.3385 64.2134C98.8179 65.7028 97.9991 67.2976 96.5096 67.7763Z"
      fill="currentColor"
    />
    <path
      d="M101.283 81.5599L84.9987 86.7959C83.6803 87.2198 82.2678 86.4945 81.8445 85.1762L80.2816 80.3143C79.8577 78.996 80.583 77.5835 81.9013 77.1602L98.1854 71.9243C99.5038 71.5003 100.916 72.2256 101.34 73.544L102.902 78.4058C103.326 79.7241 102.601 81.1367 101.283 81.5599Z"
      fill="#E3E4E8"
    />
    <path
      d="M115.64 92.2633L92.6891 99.6426C91.1553 100.136 89.512 99.2921 89.0188 97.7583L87.7067 93.6773C87.2134 92.1435 88.0572 90.5002 89.591 90.007L112.542 82.6277C114.075 82.1344 115.719 82.9782 116.212 84.512L117.524 88.593C118.017 90.1268 117.173 91.7701 115.64 92.2633Z"
      fill="#E3E4E8"
    />
    <path
      d="M130.213 102.896L101.326 112.184C99.6257 112.73 97.8037 111.795 97.2571 110.094L96.139 106.617C95.5924 104.917 96.5276 103.095 98.2284 102.548L127.115 93.26C128.816 92.7134 130.638 93.6487 131.185 95.3494L132.303 98.8264C132.849 100.527 131.914 102.349 130.213 102.896Z"
      fill="#E3E4E8"
    />
    <path
      d="M73.8144 58.0482L70 56.0897L70.9415 54.2553L72.9214 55.2723L75.1147 51L76.9498 51.9415L73.8144 58.0482Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.279 89.7944L189.655 87.4106L187 86.2793L189.384 84.654L190.515 82L192.14 84.3844L194.794 85.5151L192.411 87.1397L191.279 89.7944Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2793 33.7944L22.6547 31.4106L20 30.2793L22.3838 28.654L23.5151 26L25.1397 28.3844L27.7944 29.5151L25.4106 31.1397L24.2793 33.7944Z"
      fill="#E4E4E4"
    />
    <path
      d="M165.6 137.8C165.6 148.183 157.183 156.6 146.8 156.6C136.417 156.6 128 148.183 128 137.8C128 127.417 136.417 119 146.8 119C157.183 119 165.6 127.417 165.6 137.8Z"
      fill="currentColor"
    />
    <path
      d="M157.381 135.607C157.098 134.2 156.545 132.865 155.755 131.677C154.978 130.507 153.988 129.5 152.839 128.71C151.67 127.906 150.358 127.344 148.976 127.056C148.25 126.906 147.511 126.832 146.77 126.836V124.392L142 128.054L146.77 131.717V129.278C147.351 129.275 147.932 129.331 148.492 129.448C149.566 129.672 150.586 130.109 151.495 130.734C152.39 131.348 153.161 132.133 153.765 133.044C154.703 134.454 155.203 136.118 155.2 137.821C155.2 138.964 154.976 140.095 154.54 141.148C154.328 141.655 154.07 142.141 153.767 142.598C153.464 143.053 153.119 143.478 152.738 143.867C151.576 145.046 150.102 145.856 148.495 146.195C147.377 146.425 146.226 146.425 145.108 146.195C144.034 145.971 143.013 145.534 142.105 144.908C141.21 144.294 140.44 143.511 139.837 142.601C138.9 141.189 138.4 139.524 138.4 137.821H136C136.002 140.011 136.645 142.151 137.847 143.967C138.624 145.134 139.613 146.14 140.761 146.931C142.543 148.159 144.648 148.813 146.8 148.809C147.531 148.809 148.261 148.734 148.977 148.586C150.358 148.295 151.67 147.733 152.839 146.931C153.413 146.538 153.947 146.088 154.436 145.59C154.925 145.091 155.367 144.546 155.756 143.963C156.961 142.15 157.604 140.01 157.6 137.821C157.6 137.077 157.527 136.335 157.381 135.607Z"
      fill="white"
    />
  </svg>
);

export default SubscriptionRenewal;
