import React from 'react';

import LoadingSkeleton from 'components/LoadingSkeleton';

import styles from './Loading.module.scss';

export const Loading = () => (
  <>
    <div className={styles.row}>
      <LoadingSkeleton className={styles.loadingSkeleton} />
    </div>
    <div className={styles.row}>
      <LoadingSkeleton className={styles.loadingSkeleton} />
    </div>
    <div className={styles.row}>
      <LoadingSkeleton className={styles.loadingSkeleton} />
    </div>
  </>
);
