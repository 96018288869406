import React from 'react';

import { testGetTestingAttributes, testIds } from '@testing';

import { callQualitySurveyOptions } from '../../../constants';
import { CallQualitySurveyRating } from '../../../types';

import RatingStar from './RatingStar';

import styles from './ModalsCallQualitySurveyGiveRating.module.scss';

type ModalsCallQualitySurveyGiveRatingProps = {
  rating: CallQualitySurveyRating | null;
  setRating: React.Dispatch<React.SetStateAction<CallQualitySurveyRating | null>>;
};

const ModalsCallQualitySurveyGiveRating: React.FC<ModalsCallQualitySurveyGiveRatingProps> = ({ rating, setRating }) => (
  <div className={styles.root}>
    {callQualitySurveyOptions.map((option) => (
      <RatingStar
        {...testGetTestingAttributes(`${testIds.modalCallQualitySurveyRatingButton}-${option}`)}
        key={option}
        isFilled={rating !== null && rating >= option}
        onClick={() => {
          setRating(option === rating ? null : option);
        }}
      />
    ))}
  </div>
);

export default ModalsCallQualitySurveyGiveRating;
