import React, { PropsWithChildren } from 'react';

import { Loading } from '@onoff/ui';

import { PageBase } from 'components/PageBase';
import Header from 'layouts/components/Header';

import { LayoutProps } from '../../types';

import styles from './LayoutFullWidth.module.scss';

const LayoutFullWidth: React.FC<LayoutProps & PropsWithChildren> = ({ children, isAvailableOnMobile }) => (
  <PageBase isAvailableOnMobile={isAvailableOnMobile}>
    <div className={styles.root}>
      <Header />
      <div className={styles.page}>
        <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
      </div>
    </div>
  </PageBase>
);

export default LayoutFullWidth;
