import React from 'react';

import { StyleableComponent } from 'types';

const PrivateMode: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3238_11405)">
      <rect
        width="50"
        height="50"
        fill="#FF5968"
      />
      <path
        d="M0 8.87784C0 3.97475 3.97474 0 8.87784 0L41.1222 0C46.0253 0 50 3.97474 50 8.87784V41.1222C50 46.0253 46.0253 50 41.1222 50H8.87784C3.97475 50 0 46.0253 0 41.1222L0 8.87784Z"
        fill="#FF5968"
      />
      <g clipPath="url(#clip1_3238_11405)">
        <path
          d="M16.9085 19.2127L17.5929 18.6677L16.9743 18.0491L14.6657 15.7405C14.5138 15.5886 14.5138 15.345 14.6657 15.1931C14.8176 15.0412 15.0727 15.0411 15.2247 15.1928C15.2248 15.1929 15.2248 15.193 15.2249 15.1931L34.3379 34.3294L34.3382 34.3298C34.4901 34.4817 34.4901 34.7253 34.3382 34.8773C34.1863 35.0292 33.9426 35.0292 33.7907 34.8773L30.6032 31.6898L30.243 31.3296L29.7681 31.5139C28.281 32.091 26.6773 32.4219 25 32.4219C19.5823 32.4219 14.9367 29.1189 12.9526 24.413C13.8133 22.359 15.192 20.5796 16.9085 19.2127ZM20.2673 21.3538L19.4906 20.5771L19.0117 21.5657C18.5988 22.4182 18.3594 23.3897 18.3594 24.4141C18.3594 28.0799 21.3342 31.0547 25 31.0547C26.027 31.0547 26.9884 30.814 27.8529 30.3884L28.8317 29.9065L28.0602 29.1351L26.2204 27.2952L25.9211 26.996L25.507 27.0832C25.3037 27.126 25.1543 27.1484 25 27.1484C23.4862 27.1484 22.2656 25.9279 22.2656 24.4141C22.2656 24.2517 22.2899 24.0872 22.3253 23.8745L22.3914 23.478L22.1071 23.1937L20.2673 21.3538ZM31.6406 24.4141C31.6406 20.7482 28.6658 17.7734 25 17.7734C24.4756 17.7734 23.9769 17.8505 23.51 17.9637L22.2434 16.6971C23.1324 16.5066 24.0534 16.4062 25 16.4062C30.4174 16.4062 35.0628 19.7089 37.0471 24.4033C36.3766 25.9992 35.397 27.4255 34.1826 28.6246L31.4517 25.8937C31.5644 25.432 31.6406 24.9355 31.6406 24.4141Z"
          fill="white"
          stroke="white"
          strokeWidth="1.5625"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3238_11405">
        <rect
          width="50"
          height="50"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3238_11405">
        <rect
          width="28.125"
          height="28.125"
          fill="white"
          transform="translate(10.9375 10.9375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PrivateMode;
