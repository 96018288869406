import { IntlShape } from 'react-intl';

import { formatPurchasePrice } from 'helpers';

import { ModalsPremiumPlansFeatureIconType, ModalsPremiumPlanTypes } from '../enums';
import { ModalsPremiumPlanFeature, ModalsPremiumPlanLabels } from '../types';

export const getFeatureList = (intl: IntlShape): ModalsPremiumPlanFeature[] => [
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_webapp_access',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_webapp_access',
    }),
    icon: ModalsPremiumPlansFeatureIconType.WEB_ACCESS,
  },
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_sms_scheduling',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_sms_scheduling',
    }),
    icon: ModalsPremiumPlansFeatureIconType.SMS_SCHEDULING,
  },
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_multi_device_support',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_multi_device_support',
    }),
    icon: ModalsPremiumPlansFeatureIconType.MULTI_DEVICE_SUPPORT,
  },
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_onoff_calls',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_onoff_calls',
    }),
    icon: ModalsPremiumPlansFeatureIconType.ON_OFF_CALLS,
  },
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_call_notes',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_call_notes',
    }),
    icon: ModalsPremiumPlansFeatureIconType.CALL_NOTES,
  },
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_on_off_scheduling',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_on_off_scheduling',
    }),
    icon: ModalsPremiumPlansFeatureIconType.ON_OFF_SCHEDULING,
  },
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_calls_over_internet',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_calls_over_internet',
    }),
    icon: ModalsPremiumPlansFeatureIconType.CALLS_OVER_INTERNET,
  },
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_voicemail_transcription',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_voicemail_transcription',
    }),
    icon: ModalsPremiumPlansFeatureIconType.VOICEMAIL_TRANSCRIPTION,
  },
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_private_mode',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_private_mode',
    }),
    icon: ModalsPremiumPlansFeatureIconType.PRIVATE_MODE,
  },
  {
    title: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.title_multi_lang_voicemail',
    }),
    description: intl.formatMessage({
      id: 'ModalPremiumPlan.Features.description_multi_lang_voicemail',
    }),
    icon: ModalsPremiumPlansFeatureIconType.MULTI_LANG_VOICEMAIL,
  },
];

export const getLabels = ({
  intl,
  type,
  currencyCode,
  price,
  period,
}: {
  intl: IntlShape;
  type: ModalsPremiumPlanTypes;
  currencyCode: string;
  price?: string;
  period?: number;
}): ModalsPremiumPlanLabels => {
  const typeToLabelsMap: Record<ModalsPremiumPlanTypes, ModalsPremiumPlanLabels> = {
    [ModalsPremiumPlanTypes.INFO]: {
      title: intl.formatMessage({ id: 'ModalPremiumPlan.Info.title' }),
      description: intl.formatMessage({ id: 'ModalPremiumPlan.Info.description' }, { price }),
    },
    [ModalsPremiumPlanTypes.TRIAL]: {
      title: `${intl.formatMessage({ id: 'ModalPremiumPlan.Trial.title' })} 🚀`,
      description: intl.formatMessage({ id: 'ModalPremiumPlan.Trial.description' }),
      buttonConfirm: intl.formatMessage(
        {
          id: 'ModalPremiumPlan.Trial.button_confirm',
        },
        {
          period: intl.formatMessage({
            id: period === 1 ? 'ModalPremiumPlan.Trial.period_one_week' : 'ModalPremiumPlan.Trial.period_one_month',
          }),
        },
      ),
      footerTitle: intl.formatMessage(
        {
          id:
            period === 1 ? 'ModalPremiumPlan.Trial.footer_title_monthly' : 'ModalPremiumPlan.Trial.footer_title_yearly',
        },
        { price },
      ),
      footerPriceInfo: intl.formatMessage(
        { id: 'ModalPremiumPlan.Trial.footer_price_info' },
        { price: formatPurchasePrice({ price: 0, currencyCode }) },
      ),
      infoBox: intl.formatMessage({ id: 'ModalPremiumPlan.Trial.info_box' }),
    },
    [ModalsPremiumPlanTypes.UPGRADE]: {
      title: `${intl.formatMessage({ id: 'ModalPremiumPlan.Upgrade.title' })} 🚀`,
      description: intl.formatMessage({ id: 'ModalPremiumPlan.Upgrade.description' }),
      buttonConfirm: intl.formatMessage({ id: 'ModalPremiumPlan.Upgrade.button_confirm' }),
      footerTitle: intl.formatMessage({ id: 'ModalPremiumPlan.Upgrade.footer_title' }),
      footerPriceInfo: intl.formatMessage(
        {
          id:
            period === 1
              ? 'ModalPremiumPlan.Upgrade.footer_price_monthly'
              : 'ModalPremiumPlan.Upgrade.footer_price_yearly',
        },
        { price },
      ),
    },
    [ModalsPremiumPlanTypes.UNSUBSCRIBE]: {
      title: intl.formatMessage({ id: 'ModalPremiumPlan.Unsubscribe.title' }),
      description: intl.formatMessage({ id: 'ModalPremiumPlan.Unsubscribe.description' }),
      infoBox: intl.formatMessage({ id: 'ModalPremiumPlan.Unsubscribe.info_box' }),
      buttonCancel: intl.formatMessage({ id: 'ModalPremiumPlan.Unsubscribe.button_cancel' }),
      buttonConfirm: intl.formatMessage({ id: 'ModalPremiumPlan.Unsubscribe.button_confirm' }),
    },
  };

  return typeToLabelsMap[type];
};
