// TODO: Technical Debt
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { defineMessages, IntlShape } from 'react-intl';
import * as yup from 'yup';

import { isValidEmail } from './common';

interface IValidation {
  login;
  addContact;
  requestRecovery;
  changePassword;
}

export const validationSchemas = (intl: IntlShape): IValidation => {
  const messages = defineMessages({
    presence: {
      id: 'InputErrors.General.Blank',
    },
    minLength: {
      id: 'InputErrors.General.MinLength',
    },
    maxLength: {
      id: 'InputErrors.General.MaxLength',
    },
    email: {
      id: 'InputErrors.General.EmailInvalid',
    },
  });

  const inputNameId = (attribute: string): string => `InputErrors.InputName.${attribute}`;
  const inputName = (attribute: string): string => intl.formatMessage({ id: inputNameId(attribute) });

  yup.setLocale({
    mixed: {
      required: ({ path = '' }) => intl.formatMessage(messages.presence, { inputName: inputName(path) }),
    },
    string: {
      email: intl.formatMessage(messages.email),
    },
    number: {
      min: ({ min, path = '' }) =>
        intl.formatMessage(messages.minLength, {
          inputName: inputName(path),
          length: min,
        }),
      max: ({ max, path = '' }) =>
        intl.formatMessage(messages.maxLength, {
          inputName: inputName(path),
          length: max,
        }),
    },
  });

  const emailarray = yup.array().of(
    yup.object().shape({
      email: yup.string().test('emailValidation', intl.formatMessage(messages.email), (value = '') => {
        if (value.length > 0) {
          return isValidEmail(value);
        }
        return true;
      }),
    }),
  );

  const fullNameMaxLength = yup
    .string()
    .max(50, intl.formatMessage({ id: 'InputErrors.AddContact.fullnameMaxLength' }));

  const fullNameRequired = yup.string().required(intl.formatMessage({ id: 'InputErrors.AddContact.fullnameRequired' }));

  return {
    login: yup.object().shape({ email: yup.string().email().required(), password: yup.string().required() }),
    addContact: yup.object().shape(
      {
        emails: emailarray,
        firstName: fullNameMaxLength.when('lastName', {
          is: (lastName: string | undefined) => lastName === undefined || lastName.length === 0,
          then: fullNameRequired,
          otherwise: yup.string().notRequired(),
        }),
        lastName: fullNameMaxLength.when('firstName', {
          is: (firstName: string | undefined) => firstName === undefined || firstName.length === 0,
          then: fullNameRequired,
          otherwise: yup.string().notRequired(),
        }),
        phones: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              number: yup
                .string()
                .required(intl.formatMessage({ id: 'InputErrors.AddContact.phoneRequired' }))
                .test(
                  'phoneLib',
                  intl.formatMessage({ id: 'InputErrors.General.PhoneNumberInvalid' }),
                  (value = '') => parsePhoneNumberFromString(value)?.isValid() || false,
                ),
            }),
          ),
      },
      [['firstName', 'lastName']],
    ),
    requestRecovery: yup.object().shape({ email: yup.string().email().required() }),
    changePassword: yup.object().shape({
      password: yup
        .string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\x20-\x7E]{6,}.*$/,
          intl.formatMessage({ id: 'InputErrors.General.PasswordInvalid' }),
        )
        .required(),
      repeatPassword: yup
        .string()
        .oneOf([yup.ref('password')], intl.formatMessage({ id: 'InputErrors.General.PasswordDoesntMatch' })),
    }),
  };
};
