import { testGetTestingAttributes, testIds } from '@testing';

import { SIPCallsListItemUIMinimizedProps } from '../../types';
import SIPCallsListItemButtonUIRingtone from '../SIPCallsListItemButtonUIRingtone';
import SIPCallsListItemButtonUIView from '../SIPCallsListItemButtonUIView';
import SIPCallsListItemIconStates from '../SIPCallsListItemIconStates';
import SIPCallsListItemInfoContact from '../SIPCallsListItemInfoContact';
import SIPCallsListItemInfoStates from '../SIPCallsListItemInfoStates';

import styles from './SIPCallsListItemUIMinimized.module.scss';

const SIPCallsListItemUIMinimized: React.FC<SIPCallsListItemUIMinimizedProps> = ({
  data,
  isCallLocal,
  isCallRemote,
  isCallOngoing,
  isCallWaiting,
}) => {
  const { ids, statuses, timestamps, phoneNumbers } = data;

  return (
    <div
      className={styles.root}
      {...testGetTestingAttributes(testIds.sipCallsListItemUIMinimizedRoot)}
    >
      <SIPCallsListItemButtonUIRingtone
        isCallLocal={isCallLocal}
        isCallRemote={isCallRemote}
        isCallOngoing={isCallOngoing}
        isCallWaiting={isCallWaiting}
        idWebRTC={ids.webRTC}
        statusWebRTC={statuses.webRTC}
      />
      <SIPCallsListItemIconStates
        isCallLocal={isCallLocal}
        isCallRemote={isCallRemote}
        isCallOngoing={isCallOngoing}
        isCallWaiting={isCallWaiting}
        statusWebRTC={statuses.webRTC}
      />
      <SIPCallsListItemInfoStates
        statusCallInitializing={statuses.callInitialising}
        isCallLocal={isCallLocal}
        isCallRemote={isCallRemote}
        isCallOngoing={isCallOngoing}
        isCallWaiting={isCallWaiting}
        statusWebRTC={statuses.webRTC}
        statusView={statuses.view}
        timestampCallRang={timestamps.callRang}
        timestampCallAnswered={timestamps.callAnswered}
      />
      <SIPCallsListItemInfoContact
        statusView={statuses.view}
        idCategoryLocal={ids.categoryLocal}
        phoneNumberRemote={phoneNumbers.remote}
        isCallOngoing={isCallOngoing}
      />
      <SIPCallsListItemButtonUIView
        statusView={statuses.view}
        idWebRTC={ids.webRTC}
      />
    </div>
  );
};

export default SIPCallsListItemUIMinimized;
