import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from 'components/App';
import config from 'config';
import { LanguageProvider, SentryProvider, ReduxProvider } from 'providers';

import './styles/main.scss';

// TODO: Technical Depth => "window.clientConfiguration + config" are Legacy
window.clientConfiguration = config;

const rootElement = window.document.getElementById('app');

if (rootElement) {
  const root = createRoot(rootElement);

  root.render(
    <React.StrictMode>
      <SentryProvider>
        <ReduxProvider>
          <LanguageProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </LanguageProvider>
        </ReduxProvider>
      </SentryProvider>
    </React.StrictMode>,
  );
}
