import React from 'react';

import styles from './LayoutPayment.module.scss';

type LayoutPurchaseProps = {
  content: React.ReactNode;
  summary: React.ReactNode;
};

const LayoutPurchase: React.FC<LayoutPurchaseProps> = ({ content, summary }) => (
  <div className={styles.root}>
    <div className={styles.content}>{content}</div>
    <div className={styles.summary}>{summary}</div>
  </div>
);

export default LayoutPurchase;
