export const normalizeNumber = (number = ''): string => number.replace(/\D/g, '');

/**
 * Convert HEX to RGB
 * @param {string} hexCode
 * @return {string}
 */
export const hexToRgb = (hexCode: string): string => {
  let rgb = '';

  try {
    const parsedHexCode = hexCode.replace(/[^0-9A-F]/gi, '');
    const bigint = parseInt(parsedHexCode, 16);
    // eslint-disable-next-line no-bitwise
    const r = (bigint >> 16) & 255;
    // eslint-disable-next-line no-bitwise
    const g = (bigint >> 8) & 255;
    // eslint-disable-next-line no-bitwise
    const b = bigint & 255;
    rgb = `${r},${g},${b}`;
  } catch (e) {
    // console.log(`${e.name}: ${e.message}`);
  }

  return rgb;
};
