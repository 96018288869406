import React from 'react';

import { isB2BProject } from 'helpers/common';

import logoOnoffBusiness from 'assets/images/logo-onoff-business.svg';
import logoOnoff from 'assets/images/logo-onoff.svg';

import styles from './Logo.module.scss';

const Logo: React.FC<React.HTMLAttributes<HTMLImageElement>> = ({ className }) => {
  const logo = isB2BProject() ? logoOnoffBusiness : logoOnoff;

  return (
    <img
      src={logo}
      alt="onoff logo"
      className={`${styles.logo} ${className}`}
    />
  );
};

export default Logo;
