import { useIntl } from 'react-intl';

import { UserTypes } from 'types';

import { selectUserLanguage, useWebAppSelector } from '@redux';
import { useUserCapabilities } from 'hooks';

import { LegalLinkElement, LegalLinks } from '../../types';

const legalLinks: LegalLinks = {
  EN: {
    B2C: {
      TERMS: 'https://www.onoff.app/en/terms-conditions/',
      PRIVACY: 'https://www.onoff.app/en/privacy-policy/',
    },
    B2B: {
      TERMS: 'https://www.onoffbusiness.com/en/terms-conditions/',
      PRIVACY: 'https://www.onoffbusiness.com/en/privacy-policy/',
    },
  },
  FR: {
    B2C: {
      TERMS: 'https://www.onoff.app/fr/conditions-generales-d-utilisation/',
      SALES_TERMS: 'https://www.onoff.app/fr/conditions-generales-de-vente-onoff-business/',
      PRIVACY: 'https://www.onoff.app/fr/politique-de-confidentialite/',
    },
    B2B: {
      TERMS: 'https://www.onoffbusiness.com/fr/conditions-generales-d-utilisation/',
      SALES_TERMS: 'https://www.onoffbusiness.com/fr/conditions-generales-de-vente-onoff-business/',
      PRIVACY: 'https://www.onoffbusiness.com/fr/politique-de-confidentialite/',
    },
  },
};

const useLegalLinks = (): LegalLinkElement[] => {
  const intl = useIntl();
  const { isUserB2B } = useUserCapabilities();
  const userType = isUserB2B ? UserTypes.B2B : UserTypes.B2C;
  const currentUserLanguage = useWebAppSelector(selectUserLanguage);
  const currentLegalLinks = legalLinks[currentUserLanguage][userType];

  const links = [
    {
      url: currentLegalLinks.TERMS,
      label: intl.formatMessage({ id: 'Settings.LegalLinks.terms_and_conditions' }),
    },
    {
      url: currentLegalLinks.PRIVACY,
      label: intl.formatMessage({ id: 'Settings.LegalLinks.privacy_policy' }),
    },
  ];

  if (currentLegalLinks.SALES_TERMS) {
    links.splice(1, 0, {
      url: currentLegalLinks.SALES_TERMS,
      label: intl.formatMessage({ id: 'Settings.LegalLinks.sales_terms_and_conditions' }),
    });
  }

  return links;
};

export default useLegalLinks;
