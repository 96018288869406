import classNames from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useWebAppSelector, selectContactByPhoneNumber, selectIntegrationsActiveIntegrations } from '@redux';
import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { SIPCallsListItemUIDefaultFooterProps } from '../../types';
import SIPCallsListItemUIDefaultFooterDetail from '../SIPCallsListItemUIDefaultFooterDetail';

import styles from './SIPCallsListItemUIDefaultFooter.module.scss';

const SIPCallsListItemUIDefaultFooter: React.FC<SIPCallsListItemUIDefaultFooterProps> = ({
  idCategoryLocal,
  phoneNumberRemote,
}) => {
  const contact = useWebAppSelector(selectContactByPhoneNumber(phoneNumberRemote));
  const crmActiveIntegrations = useWebAppSelector(selectIntegrationsActiveIntegrations);
  const [stateIsDetailExpanded, setStateIsDetailExpanded] = useState<boolean>(false);

  const hasContact = contact !== undefined;
  const hasCRMIntegration = hasContact && crmActiveIntegrations.length > 0;

  const onClickHandlerButtonExpand = (): void => {
    setStateIsDetailExpanded(!stateIsDetailExpanded);
  };

  return (
    <div className={styles.root}>
      <div
        className={classNames(styles.header, {
          [styles.expanded]: stateIsDetailExpanded,
        })}
      >
        <button
          type="button"
          className={styles.headerButton}
          onClick={onClickHandlerButtonExpand}
        >
          {hasCRMIntegration ? (
            <IconCommon
              className={styles.headerButtonIconCRM}
              name={IconCommonNames.DEVICE_HUB}
              size={IconCommonSizes.TINY}
            />
          ) : null}
          <span>
            <FormattedMessage id={`SIP.Calls.UI.Contacts.button_${hasContact ? 'contact' : 'number'}_info`} />
          </span>
          <IconCommon
            name={stateIsDetailExpanded ? IconCommonNames.EXPAND_LESS : IconCommonNames.EXPAND_MORE}
            size={IconCommonSizes.TINY}
          />
        </button>
      </div>
      {stateIsDetailExpanded ? (
        <div className={styles.body}>
          <div className={styles.bodyContent}>
            <SIPCallsListItemUIDefaultFooterDetail
              idCategoryLocal={idCategoryLocal}
              phoneNumberRemote={phoneNumberRemote}
              hasCRMIntegration={hasCRMIntegration}
              contact={contact}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SIPCallsListItemUIDefaultFooter;
