import { useCallback, useEffect } from 'react';

interface UseElementScrollIntoViewProps {
  dataHashId: string;
  options?: ScrollIntoViewOptions;
}

export const useElementScrollIntoView = ({
  dataHashId,
  options = {
    behavior: 'smooth',
    block: 'start',
    inline: 'end',
  },
}: UseElementScrollIntoViewProps): void => {
  const windowHash = window.location.hash;

  const scrollToHashHandler = useCallback(() => {
    if (windowHash.length > 0 && windowHash === dataHashId) {
      const elementOrNull = window.document.querySelector(`[data-hash-id="${dataHashId}"]`);
      elementOrNull?.scrollIntoView(options);
    }
  }, [dataHashId, options, windowHash]);

  useEffect(() => {
    scrollToHashHandler();
  }, [scrollToHashHandler]);
};
