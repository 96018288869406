import { useIntl } from 'react-intl';

import { AccountType } from 'types';

import { useWebAppSelector, selectUserData } from '@redux';

type UseUserPlanName = () => string;

export const useUserPlanName: UseUserPlanName = () => {
  const intl = useIntl();
  const { accountType } = useWebAppSelector(selectUserData);

  const accountTypeToPlanNameMap = new Map<AccountType, string>([
    [AccountType.STARTER, intl.formatMessage({ id: 'General.Plans.Names.b2c_starter' })],
    [AccountType.PREMIUM, intl.formatMessage({ id: 'General.Plans.Names.b2c_premium' })],
    [AccountType.BUSINESS1, intl.formatMessage({ id: 'General.Plans.Names.b2b_1' })],
    [AccountType.BUSINESS2, intl.formatMessage({ id: 'General.Plans.Names.b2b_2' })],
    [AccountType.BUSINESS3, intl.formatMessage({ id: 'General.Plans.Names.b2b_3' })],
  ]);

  if (accountType === undefined) {
    return '';
  }

  return accountTypeToPlanNameMap.get(accountType) || '';
};
