import classNames from 'classnames';
import { forwardRef } from 'react';

import { DropdownOptionProps, DropdownCategoryProps } from '../../types';
import DropdownCategoryOption from '../DropdownCategoryOption';
import DropdownCommon from '../DropdownCommon';

import styles from './DropdownCategory.module.scss';

const DropdownCategory = forwardRef<HTMLUListElement, DropdownCategoryProps>(
  (
    { categoryList = [], countryCode, onSelect, style, selectedValue = '', isOpen, className = '', propTestId = '' },
    ref,
  ) => {
    const options: DropdownOptionProps[] = categoryList.map(({ name, id }) => ({
      label: name,
      value: id,
    }));

    return (
      <DropdownCommon
        className={classNames(styles.root, className)}
        selectedValue={selectedValue}
        isOpen={isOpen}
        ref={ref}
        options={options}
        onSelect={onSelect}
        style={style}
        propTestId={propTestId}
        renderOption={({ value, propTestId: optionTestId }) => {
          const category = categoryList.find(({ id }) => id === value);

          if (category === undefined) {
            return null;
          }

          return (
            <DropdownCategoryOption
              category={category}
              countryCode={countryCode}
              propTestId={optionTestId}
            />
          );
        }}
      />
    );
  },
);

export default DropdownCategory;
