import { API_ERRORS } from '@constants';
import { getIntl } from '@intl';

// Error codes that do not have description provided by server side

export const getErrorDescription = (code: string) => {
  switch (code) {
    case API_ERRORS.HTTP.REQUEST_FORBIDDEN: {
      return getIntl().formatMessage({ id: 'ApiError.request_forbidden_description' });
    }
    case API_ERRORS.GENERAL.EMAIL_INVALID: {
      return getIntl().formatMessage({ id: 'ApiError.email_invalid' });
    }
    default: {
      return getIntl().formatMessage({ id: 'Errors.oops' });
    }
  }
};
