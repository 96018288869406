import { flatten } from 'flat';

import EN from './json/EN.json';
import FR from './json/FR.json';

export const intlMessages = { EN, FR };
export const intlMessagesFlatten: Record<string, Record<string, string>> = {
  EN: flatten(EN),
  FR: flatten(FR),
};
