import { useSIPDisconnect } from './disconnect';
import { useSIPFailed } from './failed';
import { useSIPTimeout } from './timeout';
import { useSIPTryAnotherWss } from './tryAnotherWss';
import { useSIPUserAgent } from './userAgent';
import { useSIPWebAppValidateCallCapabilities } from './webappValidateCallCapabilities';

export const useSIP = (): void => {
  const { sipStop } = useSIPUserAgent();

  const { sipDisconnect } = useSIPDisconnect({ sipStop });

  useSIPFailed({ sipDisconnect });

  useSIPTimeout({ sipDisconnect });

  useSIPTryAnotherWss({ sipStop });

  useSIPWebAppValidateCallCapabilities();
};
