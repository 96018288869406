import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, MultiDeviceReduxState, MultiDeviceActionTypes } from '../../types';

export const multiDeviceInitialState: MultiDeviceReduxState = {
  devices: [],
  statuses: {
    fetchDeviceList: Status.IDLE,
    logoutDevice: Status.IDLE,
  },
};

export const multiDeviceReducer: Reducer<MultiDeviceReduxState, MultiDeviceActionTypes> = (state, action) => {
  const reducerState: MultiDeviceReduxState = state || multiDeviceInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.MULTI_DEVICE_SET_STATUS_FETCH_DEVICE_LIST:
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchDeviceList: action.status,
        },
      };

    case REDUX_ACTION_TYPES.MULTI_DEVICE_SET_STATUS_LOGOUT_DEVICE:
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          logoutDevice: action.status,
        },
      };

    case REDUX_ACTION_TYPES.MULTI_DEVICE_SET_DEVICES:
      return {
        ...reducerState,
        devices: action.devices,
      };

    case REDUX_ACTION_TYPES.MULTI_DEVICE_UPDATE_DEVICE: {
      const devicesUpdated = reducerState.devices.map((device) => {
        if (device.deviceId === action.payload.id) {
          return {
            ...device,
            ...action.payload.device,
          };
        }

        return device;
      });

      return {
        ...reducerState,
        devices: devicesUpdated,
      };
    }

    default:
      return reducerState;
  }
};
