import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { selectCallQualitySurveyData, useWebAppSelector } from '@redux';
import { testGetTestingAttributes, testIds } from '@testing';

import { CallQualitySurveyReasonOption } from '../../../types';

import Reasons from './Reasons';

import styles from './ModalsCallQualitySurveyGiveReason.module.scss';

type ModalsCallQualitySurveyGiveReasonProps = {
  selectedReasons: string[];
  setReasons: (reasons: string[]) => void;
  customReason: string;
  setCustomReason: (customReason: string) => void;
};

const ModalsCallQualitySurveyGiveReason: React.FC<ModalsCallQualitySurveyGiveReasonProps> = ({
  selectedReasons,
  setReasons,
  customReason,
  setCustomReason,
}) => {
  const intl = useIntl();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { reasons } = useWebAppSelector(selectCallQualitySurveyData);
  const hasCustomReason = selectedReasons.includes(CallQualitySurveyReasonOption.OTHER);

  useEffect(() => {
    if (hasCustomReason) {
      // Only set the cursor when textarea appears
      textAreaRef.current?.setSelectionRange(customReason.length, customReason.length);
    }

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [hasCustomReason]);

  return (
    <div
      {...testGetTestingAttributes(testIds.modalCallQualitySurveyGiveReasonContainer)}
      className={styles.root}
    >
      <h2 className={styles.heading}>
        <FormattedMessage id="ModalCallQualitySurvey.GiveReason.heading" />
      </h2>

      <Reasons
        allReasons={reasons || []}
        selectedReasons={selectedReasons}
        setReasons={setReasons}
      />

      {selectedReasons.includes(CallQualitySurveyReasonOption.OTHER) && (
        <textarea
          {...testGetTestingAttributes(testIds.modalCallQualitySurveyGiveReasonTextarea)}
          ref={textAreaRef}
          autoFocus
          className={styles.customReasonTextarea}
          value={customReason}
          onChange={(event) => setCustomReason(event.target.value)}
          placeholder={intl.formatMessage({ id: 'ModalCallQualitySurvey.GiveReason.custom_reason_placeholder' })}
        />
      )}
    </div>
  );
};

export default ModalsCallQualitySurveyGiveReason;
