import classNames from 'classnames';
import React from 'react';

import { StyleableComponent } from 'types';

import { ErrorIllustrationDisconnect, ErrorIllustrationPaymentDeclined } from '../../assets';
import { ErrorIllustrationType } from '../../types';

import styles from './ErrorIllustration.module.scss';

interface ErrorIllustrationProps extends StyleableComponent {
  type: ErrorIllustrationType | undefined;
  isB2BProject?: boolean;
}

const ErrorIllustration: React.FC<ErrorIllustrationProps> = ({ className, type, isB2BProject = false }) => {
  const props = {
    className: classNames(styles.root, className, {
      [styles.B2BProject]: isB2BProject,
    }),
  };
  switch (type) {
    case ErrorIllustrationType.DISCONNECT:
      return <ErrorIllustrationDisconnect {...props} />;

    case ErrorIllustrationType.PAYMENT_DECLINED:
      return <ErrorIllustrationPaymentDeclined {...props} />;

    default:
      return <></>;
  }
};

export default ErrorIllustration;
