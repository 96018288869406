import React from 'react';

import { StyleableComponent } from 'types';

const AccountInfoReplace: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_4696_20912)">
      <path
        d="M9.67 13.02C9.45 13.01 9.23 13 9 13C6.58 13 4.32 13.67 2.39 14.82C1.51 15.34 1 16.32 1 17.35V19C1 19.55 1.45 20 2 20H10.26C9.47 18.87 9 17.49 9 16C9 14.93 9.25 13.93 9.67 13.02Z"
        fill="#273348"
      />
      <path
        d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z"
        fill="#273348"
      />
      <path
        d="M20.2386 14.4469L18.6692 16.0163C18.4892 16.1962 18.613 16.5 18.8661 16.5H19.873C19.873 18.3619 18.3598 19.875 16.498 19.875C16.0536 19.875 15.6205 19.7906 15.2323 19.6275C15.0298 19.5431 14.7992 19.605 14.6473 19.7569C14.3605 20.0438 14.4617 20.5275 14.8386 20.6794C15.3505 20.8875 15.913 21 16.498 21C18.9842 21 20.998 18.9862 20.998 16.5H22.0048C22.258 16.5 22.3817 16.1962 22.2017 16.0219L20.6323 14.4525C20.5255 14.34 20.3455 14.34 20.2386 14.4469ZM13.123 16.5C13.123 14.6381 14.6361 13.125 16.498 13.125C16.9423 13.125 17.3755 13.2094 17.7636 13.3725C17.9661 13.4569 18.1967 13.395 18.3486 13.2431C18.6355 12.9563 18.5342 12.4725 18.1573 12.3206C17.6455 12.1125 17.083 12 16.498 12C14.0117 12 11.998 14.0138 11.998 16.5H10.9911C10.738 16.5 10.6142 16.8038 10.7942 16.9781L12.3636 18.5475C12.4761 18.66 12.6505 18.66 12.763 18.5475L14.3323 16.9781C14.5067 16.8038 14.383 16.5 14.1298 16.5H13.123Z"
        fill="#273348"
      />
    </g>
  </svg>
);

export default AccountInfoReplace;
