import { useState, useMemo } from 'react';

import { AllowedCountry } from 'types';

// Normalize diacritics characters from originalText
const normalizeCountryName = (value: string) =>
  value
    .normalize('NFD')
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, '');

type UseCountrySearchProps = {
  countryList?: AllowedCountry[];
  query?: string;
};

type UseCountrySearchResult = {
  filteredCountryList: AllowedCountry[];
  setCurrentQuery: (query: string) => void;
};

export const useCountrySearch = (props: UseCountrySearchProps): UseCountrySearchResult => {
  const { countryList = [], query = '' } = props;
  const [currentQuery, setCurrentQuery] = useState<string>(query);

  const filteredCountryList = useMemo(() => {
    if (currentQuery.length === 0) {
      return [...countryList];
    }

    const newCountryList = countryList.filter(({ name, phoneCode, code }) => {
      const localQuery = currentQuery.startsWith('+') ? currentQuery.slice(1) : currentQuery;
      const normalizedQuery = normalizeCountryName(localQuery);
      const normalizedCountryName = normalizeCountryName(name);
      const normalizedCountryCode = normalizeCountryName(code);

      // numbers only
      if (/^\d+$/.test(localQuery)) {
        return phoneCode.includes(localQuery);
      }

      // only exact matches for country code
      if (normalizedCountryCode === normalizedQuery) {
        return true;
      }

      return normalizedCountryName.startsWith(normalizedQuery);
    });

    return newCountryList;
  }, [currentQuery, countryList]);

  return {
    filteredCountryList,
    setCurrentQuery,
  };
};
