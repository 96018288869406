import { naiveRound } from 'helpers';

import { useFormatPrice } from 'components/Modals/ModalsRenewalPeriod/hooks';
import { usePremiumPlansWithComparison } from 'hooks/usePremiumPlansWithComparison';

import { PlanPriceToDisplay } from '../../components/Modals/ModalsPremiumPlan/types';

export const usePlanPricesToDisplay = (): PlanPriceToDisplay[] => {
  const prices = usePremiumPlansWithComparison();
  const formatPrice = useFormatPrice();

  return prices.map((price) => ({
    ...price,
    price: formatPrice(price.price, price.currencyCode),
    monthlyPrice: formatPrice(price.monthlyPrice, price.currencyCode),
    priceInAdvantage: price.priceInAdvantage ? formatPrice(price.priceInAdvantage, price.currencyCode) : undefined,
    priceInDisadvantage: price.priceInDisadvantage
      ? formatPrice(price.priceInDisadvantage, price.currencyCode)
      : undefined,
    priceDifference: price.priceDifference ? formatPrice(price.priceDifference, price.currencyCode) : undefined,
    priceWithDiscount: formatPrice(price.priceWithDiscount, price.currencyCode),
    percentageSaved: price.percentageSaved ? `-${naiveRound(price.percentageSaved, 0)}%` : undefined,
  }));
};
