import React from 'react';

import { StyleableComponent } from 'types';

const CloseFull: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_53_1074)">
      <path
        d="M21.2907 4.11973L16.7007 8.70973L18.2907 10.2997C18.9207 10.9297 18.4707 12.0097 17.5807 12.0097H13.0007C12.4507 12.0097 12.0007 11.5597 12.0007 11.0097V6.40973C12.0007 5.51973 13.0807 5.06973 13.7107 5.69973L15.3007 7.28973L19.8907 2.69973C20.2807 2.30973 20.9107 2.30973 21.3007 2.69973C21.6807 3.09973 21.6807 3.72973 21.2907 4.11973ZM4.1207 21.2897L8.7107 16.6997L10.3007 18.2897C10.9307 18.9197 12.0107 18.4697 12.0107 17.5797V12.9997C12.0107 12.4497 11.5607 11.9997 11.0107 11.9997H6.4107C5.5207 11.9997 5.0707 13.0797 5.7007 13.7097L7.2907 15.2997L2.7007 19.8897C2.3107 20.2797 2.3107 20.9097 2.7007 21.2997C3.1007 21.6797 3.7307 21.6797 4.1207 21.2897Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_53_1074">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CloseFull;
