import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Edit, MoreHorizontal, PhoneForward } from '@onoff/icons';
import { Menu } from '@onoff/ui';

import { testIds } from '@testing';

import { SIPCallsListItemMoreMenuProps } from '../../types';

import styles from './SIPCallsListItemMoreMenu.module.scss';

const SIPCallsListItemMoreMenu: React.FC<SIPCallsListItemMoreMenuProps> = ({
  isCallOngoing,
  isCallWaiting,
  onMenuItemCallNotesClick,
  onMenuItemCallTransferClick,
}) => {
  const isFeatureNotAvailable = !isCallOngoing;

  if (isFeatureNotAvailable) {
    return null;
  }

  return (
    <Menu
      placement="top-start"
      gutter={3}
      usePortal
    >
      <Menu.Trigger>
        <button
          className={styles.menuTrigger}
          disabled={isCallWaiting}
          data-testid={testIds.sipCallsListItemButtonMoreMenuTrigger}
        >
          <MoreHorizontal size="small" />
        </button>
      </Menu.Trigger>
      <Menu.Content className={styles.menuContent}>
        <Menu.Item
          onClick={onMenuItemCallNotesClick}
          data-testid={testIds.sipCallsListItemButtonMoreMenuCallNotesButton}
        >
          <Edit
            size="small"
            className={styles.menuIcon}
          />
          <FormattedMessage id="SIP.Calls.UI.button_call_notes" />
        </Menu.Item>
        <Menu.Item
          onClick={onMenuItemCallTransferClick}
          data-testid={testIds.sipCallsListItemButtonMoreMenuCallTransferButton}
        >
          <PhoneForward
            size="small"
            className={styles.menuIcon}
          />
          <FormattedMessage id="SIP.Calls.UI.button_call_transfer" />
        </Menu.Item>
      </Menu.Content>
    </Menu>
  );
};

export default SIPCallsListItemMoreMenu;
