import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { showSuccessToast } from '@onoff/toast-notification';
import { Button, Modal, PopupWide } from '@onoff/ui';

import { CallTransferType, GAEventKey, SentryServiceBreadcrumbMessage, Status } from 'types';

import { handleApplicationError } from 'helpers';

import { API_ERRORS } from '@constants';
import { selectSIPStatuses, sipInitiateCallTransferHandler, useWebAppDispatch, useWebAppSelector } from '@redux';
import { analyticsService, sentryService } from 'services';

import { Column, PhoneNumberSelector, PhoneNumberSelectorProps, TransferTypeButton } from './components';
import { CallTransferError, ModalsCallTransferProps } from './types';

import styles from './ModalsCallTransfer.module.scss';

export const ModalsCallTransfer: React.FC<ModalsCallTransferProps> = ({ callData, isOpen, onClose }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { statusInitiateCallTransfer } = useWebAppSelector(selectSIPStatuses);

  const { categoryLocal: categoryId } = callData.ids;

  const [selectedCallTransferType, setSelectedCallTransferType] = useState<CallTransferType>(CallTransferType.COLD);
  const [callTransferError, setCallTransferError] = useState<CallTransferError | null>(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<PhoneNumberSelectorProps['selectedPhoneNumber']>(null);
  const [isCallTransferredSuccessfully, setIsCallTransferredSuccessfully] = useState(false);

  const transferTypeButtonClickHandler = (type: CallTransferType) => {
    setSelectedCallTransferType(type);
  };

  const phoneNumberSelectHandler: PhoneNumberSelectorProps['onPoneNumberSelect'] = (phoneNumber) => {
    setSelectedPhoneNumber(phoneNumber);
  };

  const startButtonClickHandler = async () => {
    if (!selectedPhoneNumber) {
      return;
    }

    const parsedPhoneNumberObject = parsePhoneNumberFromString(selectedPhoneNumber);

    if (!parsedPhoneNumberObject) {
      setCallTransferError(CallTransferError.INVALID_NUMBER);
      return;
    }

    try {
      await dispatch(
        sipInitiateCallTransferHandler({ categoryId, calleeNumber: parsedPhoneNumberObject.number.toString() }),
      );

      analyticsService.pushToDataLayer({
        event: GAEventKey.CALL_TRANSFER_INITIATE_SUCCESS,
        variables: {
          sipCallTransactionId: callData.ids.transaction,
          transferType: selectedCallTransferType,
        },
      });

      setIsCallTransferredSuccessfully(true);

      showSuccessToast({ message: intl.formatMessage({ id: 'Modals.CallTransferring.success_notification' }) });
    } catch (error) {
      sentryService.addBreadcrumb({
        message: SentryServiceBreadcrumbMessage.CALL_TRANSFER_REQUEST,
        data: {
          callData,
          selectedCallTransferType,
          selectedPhoneNumber,
          parsedPhoneNumberObject,
        },
      });

      const { errorCode } = handleApplicationError({
        error,
        showToast: false,
        reportToSentry: true,
      });

      analyticsService.pushToDataLayer({
        event: GAEventKey.CALL_TRANSFER_INITIATE_FAIL,
        variables: {
          sipCallTransactionId: callData.ids.transaction,
          transferType: selectedCallTransferType,
          error_code: errorCode,
        },
      });

      if (errorCode === API_ERRORS.CALLING.INVALID_CALLEE_NUMBER) {
        setCallTransferError(CallTransferError.INVALID_NUMBER);
      } else {
        setCallTransferError(CallTransferError.GENERIC);
      }
    }
  };

  const isStartButtonDisabled = !selectedCallTransferType || !selectedPhoneNumber;
  const isStartButtonLoading = statusInitiateCallTransfer === Status.LOADING || isCallTransferredSuccessfully;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopupWide className={styles.root}>
        <PopupWide.Body className={styles.body}>
          <div className={styles.columns}>
            <Column
              heading={intl.formatMessage({ id: 'Modals.CallTransferring.heading_transfer_type' })}
              className={styles.column}
            >
              <div className={styles.transferTypeButtons}>
                <TransferTypeButton
                  type={CallTransferType.COLD}
                  isSelected={selectedCallTransferType === CallTransferType.COLD}
                  onClick={transferTypeButtonClickHandler}
                />
                <TransferTypeButton
                  type={CallTransferType.WARM}
                  isSelected={selectedCallTransferType === CallTransferType.WARM}
                  onClick={transferTypeButtonClickHandler}
                  isDisabled
                />
              </div>
            </Column>
            <Column
              heading={intl.formatMessage({ id: 'Modals.CallTransferring.heading_select_transferee' })}
              className={styles.column}
            >
              <PhoneNumberSelector
                categoryId={categoryId}
                onPoneNumberSelect={phoneNumberSelectHandler}
                selectedPhoneNumber={selectedPhoneNumber}
                error={callTransferError}
                setError={setCallTransferError}
                className={styles.contactsSelector}
              />
            </Column>
          </div>
        </PopupWide.Body>
        <PopupWide.Footer>
          <Button
            variant="ghost"
            size="large"
            colorScheme="black"
            onClick={onClose}
          >
            <FormattedMessage id="Modals.CallTransferring.button_cancel" />
          </Button>
          <Button
            size="large"
            onClick={startButtonClickHandler}
            disabled={isStartButtonDisabled}
            loading={isStartButtonLoading}
          >
            <FormattedMessage id="Modals.CallTransferring.button_start_transferring" />
          </Button>
        </PopupWide.Footer>
      </PopupWide>
    </Modal>
  );
};
