import React from 'react';
import { useIntl } from 'react-intl';

import { NotificationsModalTypes, Status } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  notificationsModalSetData,
  selectNotificationsModalState,
  selectAuthenticationStatuses,
} from '@redux';
import { ModalsDialog, ModalsDialogType } from 'components/Modals';

/**
 * This component is for modals that need to be triggered from outside
 * (e.g from PubNub) therefore the name "NotificationsModal"
 */
const NotificationsModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();

  const { modalType, isVisible } = useWebAppSelector(selectNotificationsModalState);
  const { statusLogin } = useWebAppSelector(selectAuthenticationStatuses);

  const isPremiumUpgradeSuccessModalVisible: boolean =
    statusLogin === Status.SUCCEEDED && isVisible && modalType === NotificationsModalTypes.PREMIUM_OFFER_PUBNUB;

  const closeModal = (): void => {
    dispatch(
      notificationsModalSetData({
        isVisible: false,
      }),
    );
  };

  const resetModalType = (): void => {
    dispatch(
      notificationsModalSetData({
        modalType: NotificationsModalTypes.NO_TYPE,
      }),
    );
  };

  switch (modalType) {
    case NotificationsModalTypes.PREMIUM_OFFER_PUBNUB:
      return (
        <ModalsDialog
          isOpen={isPremiumUpgradeSuccessModalVisible}
          type={ModalsDialogType.PREMIUM_UNLOCKED}
          title={intl.formatMessage({ id: 'Notifications.Popup.Title.premium_offer_pubnub' })}
          content={intl.formatMessage({ id: 'Notifications.Popup.Description.premium_offer_pubnub' })}
          buttonText={intl.formatMessage({ id: 'Notifications.Popup.Button.premium_offer_pubnub' })}
          onButtonClick={closeModal}
          onCloseAnimationEnd={resetModalType}
        />
      );

    default:
      return null;
  }
};

export default NotificationsModal;
