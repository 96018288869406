import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { StyleableComponent } from 'types';

import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import styles from './Info.module.scss';

interface InfoProps extends StyleableComponent {
  iconName?: IconCommonNames;
  iconSize?: IconCommonSizes;
  classNameIcon?: string;
  classNameBody?: string;
}

const Info: React.FC<InfoProps & PropsWithChildren> = ({
  children,
  className,
  iconName = IconCommonNames.INFO_OUTLINED,
  iconSize = IconCommonSizes.DEFAULT,
  classNameIcon,
  classNameBody,
}) => (
  <div className={classNames(styles.root, className)}>
    <div>
      <IconCommon
        size={iconSize}
        name={iconName}
        className={classNames(styles.icon, classNameIcon)}
      />
    </div>
    <div className={classNames(styles.body, classNameBody)}>{children}</div>
  </div>
);

export default Info;
