import { Status } from 'types';

import { selectUserGeoInfoStatuses } from '../../selectors';
import {
  REDUX_ACTION_TYPES,
  ThunkResult,
  UserGeoInfoSetStatusFetchUserGeoInfoAction,
  UserGeoInfoSetGeoInfoDataAction,
  UserGeoInfoData,
} from '../../types';

export const userGeoInfoSetStatusFetchUserGeoInfo = (
  fetchUserGeoInfo: Status,
): UserGeoInfoSetStatusFetchUserGeoInfoAction => ({
  type: REDUX_ACTION_TYPES.USER_GEO_INFO_SET_STATUS_FETCH_USER_GEO_INFO,
  fetchUserGeoInfo,
});

export const userGeoInfoSetGeoInfoData = (data: UserGeoInfoData): UserGeoInfoSetGeoInfoDataAction => ({
  type: REDUX_ACTION_TYPES.USER_GEO_INFO_SET_GEO_INFO_DATA,
  data,
});

export const userGeoInfoFetchGeoInfoDataHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    /**
     * This check is for preventing highly possible duplications
     * with UserInit dispatch after the Succeeded Login, or Interval in SIP, etc...
     */
    const { fetchUserGeoInfo: statusFetchUserGeoInfo } = selectUserGeoInfoStatuses(getState());
    if (statusFetchUserGeoInfo === Status.LOADING) {
      return;
    }

    try {
      dispatch(userGeoInfoSetStatusFetchUserGeoInfo(Status.LOADING));

      const {
        ip = '',
        country_code: countryCode = '',
        country_name: countryName = '',
        region_code: regionCode = '',
        region_name: regionName = '',
        city = '',
        zip_code: zipCode = '',
        time_zone: timeZone = '',
        latitude = 0,
        longitude = 0,
        metro_code: metroCode = 0,
      } = await services.userService.fetchUserGeoLocation();

      const userGeoInfoData: UserGeoInfoData = {
        ip,
        countryCode,
        countryName,
        regionCode,
        regionName,
        city,
        zipCode,
        timeZone,
        latitude,
        longitude,
        metroCode,
      };

      dispatch(userGeoInfoSetGeoInfoData(userGeoInfoData));

      dispatch(userGeoInfoSetStatusFetchUserGeoInfo(Status.SUCCEEDED));
    } catch (error) {
      dispatch(userGeoInfoSetStatusFetchUserGeoInfo(Status.FAILED));
      throw error;
    }
  };
