import React from 'react';
import { useIntl } from 'react-intl';

import { DropdownCountryOption, DropdownCountryOptionOnSelect } from 'components/Dropdowns';
import { InputSearchCountry } from 'components/Input';
import { ModalHeader } from 'components/Modal';

import { useCountrySearch } from '../../hooks';
import { ModalsSelectCountryContentProps } from '../../types';

import styles from './ModalsSelectCountryContent.module.scss';

const ModalsSelectCountryContent: React.FC<ModalsSelectCountryContentProps> = ({ countryList, onSelectCountry }) => {
  const intl = useIntl();
  const selectCountry: DropdownCountryOptionOnSelect = (countryCode) => {
    onSelectCountry(countryCode);
  };

  const { filteredCountryList, setCurrentQuery } = useCountrySearch({ countryList });

  return (
    <>
      <ModalHeader
        title={intl.formatMessage({ id: 'ModalSelectCountry.modal_title' })}
        className={styles.modalTitle}
      />
      <InputSearchCountry
        className={styles.input}
        onChange={setCurrentQuery}
        autoFocus={true}
      />
      <div className={styles.list}>
        {filteredCountryList.map(({ code, phoneCode, name }) => (
          <DropdownCountryOption
            className={styles.item}
            key={code}
            code={code}
            phoneCode={phoneCode}
            name={name}
            onSelect={selectCountry}
          />
        ))}
      </div>
    </>
  );
};

export default React.memo(ModalsSelectCountryContent);
