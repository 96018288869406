import { StyleableComponent, TestableComponent } from 'types';

export enum AvatarSize {
  /**
   * 20px
   */
  TINY = 'tiny',
  /**
   * 32px
   */
  SMALL = 'small',
  /**
   * 50px
   */
  DEFAULT = 'default',
  /**
   * 56px
   */
  MEDIUM = 'medium',
  /**
   * 60px
   */
  BIG = 'big',
  /**
   * 82px
   */
  LARGE = 'large',
}

export enum AvatarType {
  DEFAULT = 'default',
  LABEL = 'label',
  IMAGE = 'image',
  GROUP = 'group',
  PHOTO = 'photo',
}

export interface AvatarCommonProps extends StyleableComponent, TestableComponent {
  imageSrc?: string;
  backgroundColor?: string;
  fullName?: string;
  size?: AvatarSize;
  hasStarIcon?: boolean;
  hasBlockedIcon?: boolean;
  hasPhotoIcon?: boolean;
  isGroup?: boolean;
}
