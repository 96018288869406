import React from 'react';

import { StyleableComponent } from 'types';

const Logout: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <ellipse
      cx="100"
      cy="104.762"
      rx="100"
      ry="95.2381"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <ellipse
      cx="100"
      cy="104.762"
      rx="87.3684"
      ry="83.208"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <ellipse
      cx="100"
      cy="104.762"
      rx="72.1053"
      ry="68.6717"
      fill="#F3F4F5"
    />
    <path
      d="M38.3276 31.8174L133.291 19.5419C136.03 19.1878 138.588 21.1139 139.005 23.8438L158.175 149.303C158.592 152.033 156.71 154.533 153.972 154.887L59.0082 167.162L38.3276 31.8174Z"
      fill="#C8CBCE"
    />
    <path
      d="M59.2119 168.779L38.3276 31.9229L114.657 32.4426C117.164 32.4597 119.314 34.3142 119.691 36.7853L139.228 164.818C139.69 167.845 137.339 170.511 134.267 170.443L59.2119 168.779Z"
      fill="white"
    />
    <ellipse
      rx="4.9529"
      ry="5.04575"
      transform="matrix(0.991817 -0.128147 0.151128 0.988585 113.639 102.185)"
      fill="#E4E5E7"
    />
    <path
      d="M50.232 52.5419L49.3731 46.8976C49.189 45.6876 50.1285 44.6211 51.3561 44.6465L109.785 45.8587C110.777 45.8793 111.621 46.6172 111.769 47.5949L112.628 53.2392C112.812 54.4493 111.873 55.5157 110.645 55.4903L52.2159 54.2781C51.224 54.2575 50.3808 53.5196 50.232 52.5419Z"
      fill="#C8CBCE"
    />
    <path
      d="M112.293 65.7975L53.61 64.576C52.6711 64.5565 51.8735 63.858 51.7331 62.9323C51.5592 61.7859 52.4499 60.7752 53.6128 60.7994L112.296 62.0209C113.235 62.0404 114.033 62.7389 114.173 63.6646C114.347 64.811 113.456 65.8217 112.293 65.7975Z"
      fill="#E4E5E7"
    />
    <ellipse
      cx="159.8"
      cy="97.9048"
      rx="18.8"
      ry="17.9048"
      fill="#BEC1C8"
    />
    <path
      d="M165.649 96.8706L158.753 90.3027L160.571 88.5713L170.571 98.0951L160.571 107.619L158.753 105.888L165.649 99.3196H150V96.8706H165.649Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.2793 103.614L26.6547 101.343L24 100.266L26.3838 98.7181L27.5151 96.1904L29.1397 98.4613L31.7944 99.5382L29.4106 101.085L28.2793 103.614Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.279 63.6137L158.655 61.3434L156 60.2659L158.384 58.7181L159.515 56.1904L161.14 58.4613L163.794 59.5382L161.411 61.0854L160.279 63.6137Z"
      fill="#E4E4E4"
    />
  </svg>
);

export default Logout;
