import { StyleableComponent, TestableComponent, Category, Contact, Maybe, Thread } from 'types';

import { IconCommonNames } from 'components/Icon';

import { AvatarSize } from '../../Avatar';

export type InfoTopBarLayoutProps = StyleableComponent & {
  renderAvatar?: () => React.ReactNode;
  renderContent?: () => React.ReactNode;
  renderActions?: () => React.ReactNode;
};

export interface InfoTopBarAvatarProps {
  phoneNumberOrEmpty: string;
  category?: Category;
  contact?: Maybe<Pick<Contact, 'imageUrl' | 'firstName' | 'lastName' | 'favorite' | 'blocked'>>;
  thread?: Thread | false;
  size?: AvatarSize;
}

export interface InfoTopBarContentProps extends TestableComponent, StyleableComponent {
  mainRow?: string;
  additionalRow?: string;
}

export type InfoTopBarContentContactProps = {
  contact: Pick<Contact, 'displayName'>;
};

export enum InfoTopBarActionsTypes {
  PAGE_CALLS = 'PAGE_CALLS',
  PAGE_MESSAGES = 'PAGE_MESSAGES',
  PAGE_MESSAGES_GROUP = 'PAGE_MESSAGES_GROUP',
  PAGE_VOICEMAILS = 'PAGE_VOICEMAILS',
  PAGE_CONTACTS = 'PAGE_CONTACTS',
}

export interface InfoTopBarActionsProps {
  type: InfoTopBarActionsTypes;
  phoneNumberOrEmpty: string;
  contact?: Contact;
  category?: Category;
}

export interface InfoTopBarActionsFeaturesContactProps {
  contact?: Contact;
}

export interface InfoTopBarActionsFeaturesCallsAndSMSProps {
  type: InfoTopBarActionsTypes;
  phoneNumberOrEmpty: string;
  contact?: Contact;
  category?: Category;
}

export interface InfoTopBarActionsDropdownProps {
  isOpened: boolean;
  type: InfoTopBarActionsTypes;
  phoneNumberOrEmpty: string;
  contact?: Contact;
}

export interface InfoTopBarActionsDropdownItemsProps extends StyleableComponent, Pick<TestableComponent, 'propTestId'> {
  label: string;
  iconName: IconCommonNames;
  onClick: () => void;
  isDisabled?: boolean;
}

export interface InfoTopBarActionsDropdownFeaturesContactAddEditProps {
  phoneNumberOrEmpty: string;
  contact?: Contact;
}

export interface InfoTopBarActionsDropdownFeaturesContactFavoriteProps {
  contact?: Contact;
}

export interface InfoTopBarActionsDropdownFeaturesContactBlockProps {
  contact?: Contact;
}

export interface InfoTopBarActionsDropdownFeaturesNumberBlockProps {
  phoneNumberOrEmpty: string;
}

export interface InfoTopBarActionsDropdownFeaturesDeleteProps {
  type: InfoTopBarActionsTypes;
}
