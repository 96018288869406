import classNames from 'classnames';
import React from 'react';

import { Button } from '@onoff/ui';

import { StyleableComponent } from 'types';

import { ErrorCoreProps } from '../../types';

import styles from './ErrorInfo.module.scss';

type ErrorInfoProps = Pick<ErrorCoreProps, 'title' | 'description' | 'onClick' | 'buttonText'> &
  StyleableComponent & {
    isProjectB2B?: boolean;
  };

const ErrorInfo: React.FC<ErrorInfoProps> = ({
  className,
  title,
  description,
  onClick,
  buttonText,
  isProjectB2B = false,
}) => (
  <div className={classNames(styles.root, className)}>
    <div className={styles.title}>{title}</div>
    <div className={styles.description}>{description}</div>
    <Button
      className={styles.button}
      onClick={onClick}
      colorScheme={isProjectB2B ? 'brand-b2b' : 'brand-b2c'}
      size="large"
    >
      {buttonText}
    </Button>
  </div>
);

export default ErrorInfo;
