import { createSelector } from '@reduxjs/toolkit';

import {
  SelectUserGeoInfoState,
  SelectUserGeoInfoStatuses,
  SelectUserGeoInfoData,
  SelectUserGeoInfoDataIp,
} from '../../types';

export const selectUserGeoInfo: SelectUserGeoInfoState = (state) => state.userGeoInfo;

export const selectUserGeoInfoStatuses: SelectUserGeoInfoStatuses = createSelector(
  [selectUserGeoInfo],
  (userGeoInfoState) => userGeoInfoState.statuses,
);

export const selectUserGeoInfoData: SelectUserGeoInfoData = createSelector(
  [selectUserGeoInfo],
  (userGeoInfoState) => userGeoInfoState.data,
);

export const selectUserGeoInfoDataIp: SelectUserGeoInfoDataIp = createSelector(
  [selectUserGeoInfoData],
  (userGeoInfoStateData) => userGeoInfoStateData.ip,
);
