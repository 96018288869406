import React from 'react';

import { StyleableComponent } from 'types';

const CallLogDelete: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="100"
      cy="100"
      r="100"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <circle
      cx="100"
      cy="99.9998"
      r="87.3684"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <circle
      cx="100"
      cy="99.9998"
      r="72.1053"
      fill="#F3F4F5"
    />
    <rect
      x="57.7036"
      y="4"
      width="75.9661"
      height="81"
      rx="4.57627"
      transform="rotate(20 57.7036 4)"
      fill="#E4E5E7"
    />
    <rect
      x="56.7036"
      y="3"
      width="75.9661"
      height="81"
      rx="4.57627"
      transform="rotate(20 56.7036 3)"
      fill="white"
    />
    <g clipPath="url(#clip0_11492_121902)">
      <path
        d="M52.2766 44.2697C53.494 40.9249 57.1925 39.2003 60.5373 40.4177C63.8821 41.6351 65.6067 45.3335 64.3893 48.6783C63.1719 52.0232 59.4735 53.7478 56.1286 52.5303C52.7838 51.3129 51.0592 47.6145 52.2766 44.2697Z"
        fill="#F1F1F1"
      />
      <g clipPath="url(#clip1_11492_121902)">
        <path
          d="M57.9871 43.9744C57.9217 44.1539 58.0147 44.3475 58.191 44.4117L59.9836 45.0642L55.2205 47.2853C55.0499 47.3648 54.9763 47.5668 55.0559 47.7374C55.1354 47.908 55.3374 47.9815 55.508 47.902L60.2711 45.6809L59.6187 47.4734C59.5545 47.6498 59.6463 47.8466 59.8227 47.9108C59.999 47.975 60.1959 47.8832 60.2601 47.7069L61.1938 45.1415C61.258 44.9651 61.1662 44.7683 60.9898 44.7041L58.4245 43.7704C58.2481 43.7062 58.0513 43.798 57.9871 43.9744Z"
          fill="#273348"
        />
      </g>
    </g>
    <g clipPath="url(#clip2_11492_121902)">
      <path
        d="M58.7132 26.5851C59.9306 23.2403 63.629 21.5157 66.9738 22.7331C70.3186 23.9505 72.0432 27.649 70.8258 30.9938C69.6084 34.3386 65.91 36.0632 62.5652 34.8458C59.2203 33.6284 57.4957 29.9299 58.7132 26.5851Z"
        fill="#F1F1F1"
      />
      <path
        d="M68.4095 26.7485C68.3303 26.5786 68.129 26.5053 67.959 26.5846L63.2145 28.7943L63.8646 27.0083C63.9285 26.8326 63.8371 26.6365 63.6614 26.5726C63.4856 26.5086 63.2895 26.6 63.2256 26.7758L62.2953 29.3317C62.2313 29.5075 62.3228 29.7036 62.4985 29.7675L65.0545 30.6978C65.2302 30.7618 65.4263 30.6703 65.4902 30.4946C65.5542 30.3189 65.4628 30.1228 65.287 30.0588L63.5011 29.4088L68.2467 27.1958C68.4123 27.1186 68.4867 26.9141 68.4095 26.7485Z"
        fill="#273348"
      />
    </g>
    <g clipPath="url(#clip3_11492_121902)">
      <path
        opacity="0.1"
        d="M45.8399 61.9543C47.0573 58.6095 50.7557 56.8849 54.1005 58.1023C57.4453 59.3197 59.1699 63.0181 57.9525 66.3629C56.7351 69.7077 53.0367 71.4323 49.6919 70.2149C46.3471 68.9975 44.6225 65.2991 45.8399 61.9543Z"
        fill="#F54436"
      />
      <g clipPath="url(#clip4_11492_121902)">
        <path
          d="M54.96 63.6738L51.9465 65.079L50.8063 62.6341L51.9576 63.0531C52.1339 63.1173 52.3307 63.0255 52.3949 62.8491C52.4591 62.6727 52.3673 62.4759 52.191 62.4117L50.267 61.7114C50.0906 61.6472 49.8938 61.739 49.8296 61.9154L49.1293 63.8394C49.0651 64.0158 49.1569 64.2126 49.3332 64.2768C49.5096 64.341 49.7064 64.2492 49.7706 64.0728L50.1896 62.9216L51.4725 65.6728C51.5521 65.8434 51.7541 65.9169 51.9247 65.8374L55.2444 64.2893C55.415 64.2098 55.4885 64.0078 55.409 63.8372C55.3315 63.671 55.1262 63.5963 54.96 63.6738Z"
          fill="#F54436"
        />
      </g>
    </g>
    <rect
      x="78.3589"
      y="29.8945"
      width="37.6388"
      height="7.47621"
      rx="1.289"
      transform="rotate(20 78.3589 29.8945)"
      fill="#D4D7DB"
    />
    <rect
      x="71.9221"
      y="47.5791"
      width="37.6388"
      height="7.47621"
      rx="1.289"
      transform="rotate(20 71.9221 47.5791)"
      fill="#E2E2E2"
    />
    <rect
      x="65.4856"
      y="65.2637"
      width="37.6388"
      height="7.47621"
      rx="1.289"
      transform="rotate(20 65.4856 65.2637)"
      fill="#F1F1F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.7693 136.099C77.889 130.96 92.008 125.821 106.173 120.666C120.292 115.526 134.456 110.371 148.576 105.232C151.439 115.772 154.137 125.86 156.732 135.524C159.24 144.81 161.678 153.762 163.985 162.353C164.583 164.698 163.674 167.13 161.909 167.772C150.55 171.906 139.192 176.041 127.832 180.175C116.474 184.309 105.07 188.46 93.7104 192.594C91.9906 193.22 89.7486 191.987 88.682 189.761C84.9271 181.698 81.0406 173.272 76.9931 164.547C72.7693 155.476 68.3515 146.013 63.7693 136.099Z"
      fill="#C8CBCE"
    />
    <path
      d="M111.686 148.296C112.103 148.989 111.466 149.825 110.697 149.633L108.074 148.98L106.768 154.22C106.426 155.589 107.73 156.805 109.071 156.37L111.853 155.466C112.208 155.351 112.588 155.545 112.704 155.899L113.399 158.04C113.514 158.395 113.32 158.776 112.966 158.891L110.189 159.793C106.158 161.103 102.254 157.458 103.279 153.348L104.583 148.109L101.961 147.455C101.184 147.262 101.03 146.222 101.715 145.81L107.172 142.532C107.598 142.275 108.152 142.413 108.408 142.84L111.686 148.296ZM113.78 136.794L117.139 139.612L115.401 141.683C114.887 142.295 115.371 143.228 116.169 143.158L122.511 142.603C123.01 142.56 123.373 142.12 123.33 141.628L122.775 135.286C122.705 134.49 121.703 134.172 121.188 134.786L119.453 136.854L116.096 134.035C113.044 131.475 108.349 132.992 107.383 136.866L106.922 138.718C106.832 139.08 107.052 139.446 107.414 139.536L109.597 140.081C109.959 140.171 110.326 139.951 110.416 139.589L110.877 137.738C111.204 136.429 112.781 135.956 113.78 136.794ZM129.104 144.957L126.865 143.078C126.579 142.838 126.153 142.875 125.914 143.161L124.469 144.882C124.23 145.167 124.267 145.593 124.553 145.833L126.795 147.714C127.873 148.619 127.536 150.37 126.194 150.806L121.057 152.475L120.223 149.908C119.976 149.147 118.929 149.063 118.565 149.777L115.674 155.448C115.449 155.891 115.625 156.433 116.068 156.659L121.74 159.549C122.449 159.91 123.253 159.234 123.005 158.468L122.17 155.9L127.301 154.233C131.329 152.924 132.351 147.682 129.104 144.957Z"
      fill="white"
    />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.7693 136.099C77.889 130.96 92.008 125.821 106.173 120.666C120.292 115.526 134.456 110.371 148.576 105.232L151.488 115.908C137.697 117.442 123.013 120.891 108.215 126.277C93.4172 131.663 79.9518 138.46 68.4009 146.149L63.7693 136.099Z"
      fill="#8E929B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.2727 95.7215L127.598 70.1252C131.581 68.6756 136.025 70.7477 137.474 74.7305L148.576 105.232L63.7691 136.099L52.6674 105.598C51.2178 101.615 53.29 97.1711 57.2727 95.7215Z"
      fill="#E4E5E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.0715 124.95L145.775 93.029C146.68 92.6997 147.622 93.1766 147.935 94.0371L151.032 102.544C151.345 103.404 150.929 104.376 150.024 104.705L62.3212 136.626C61.4162 136.956 60.4737 136.479 60.1607 135.619L57.0643 127.111C56.7511 126.251 57.1666 125.28 58.0715 124.95Z"
      fill="#E4E5E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.5196 124.423L144.326 93.5563L143.454 91.1582L58.6467 122.025L59.5196 124.423Z"
      fill="#C8CBCE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2793 122.794L30.6547 120.411L28 119.279L30.3838 117.654L31.5151 115L33.1397 117.384L35.7944 118.515L33.4106 120.14L32.2793 122.794Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M148.279 30.7944L146.655 28.4106L144 27.2793L146.384 25.654L147.515 23L149.14 25.3844L151.794 26.5151L149.411 28.1397L148.279 30.7944Z"
      fill="#E4E4E4"
    />
    <defs>
      <clipPath id="clip0_11492_121902">
        <rect
          x="54.481"
          y="38.2134"
          width="12.89"
          height="12.89"
          rx="2.578"
          transform="rotate(20 54.481 38.2134)"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_11492_121902">
        <rect
          width="8.19"
          height="8.19"
          fill="white"
          transform="translate(55.8013 41) rotate(20)"
        />
      </clipPath>
      <clipPath id="clip2_11492_121902">
        <rect
          x="60.9175"
          y="20.5288"
          width="12.89"
          height="12.89"
          rx="2.578"
          transform="rotate(20 60.9175 20.5288)"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip3_11492_121902">
        <rect
          x="48.0442"
          y="55.8979"
          width="12.89"
          height="12.89"
          rx="2.578"
          transform="rotate(20 48.0442 55.8979)"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip4_11492_121902">
        <rect
          width="8.19"
          height="8.19"
          fill="white"
          transform="translate(49.8013 59) rotate(20)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CallLogDelete;
