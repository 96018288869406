import React from 'react';

import { getIntl } from '@intl';
import { ErrorCore, ErrorType } from 'components/Error';

import styles from './ErrorServerError.module.scss';

const ErrorServerError: React.FC = () => {
  const title = getIntl().formatMessage({ id: 'Sentry.react_error_boundary_title' });
  const description = getIntl().formatMessage({ id: 'Sentry.react_error_boundary_description' });
  const buttonText = getIntl().formatMessage({ id: 'Sentry.react_error_boundary_button_text' });

  const onClickHandlerRefreshPage = () => {
    window.location.reload();
  };

  return (
    <ErrorCore
      title={title}
      description={description}
      buttonText={buttonText}
      type={ErrorType.ERROR_500}
      onClick={onClickHandlerRefreshPage}
      classNameIllustration={styles.illustration}
    />
  );
};

export default ErrorServerError;
