import React from 'react';

import { Button } from '@onoff/ui';

import { testGetTestingAttributes, testIds } from '@testing';

import { ModalsConfirmationColorScheme } from '../../enums';

import styles from './ModalsConfirmationButtons.module.scss';

export interface ModalsConfirmationButtonsProps {
  colorScheme: ModalsConfirmationColorScheme;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading?: boolean;
}

const ModalsConfirmationButtons: React.FC<ModalsConfirmationButtonsProps> = ({
  colorScheme,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
  isLoading,
}) => (
  <>
    <Button
      onClick={onConfirm}
      className={styles.confirmButton}
      colorScheme={colorScheme}
      size={'large'}
      disabled={isLoading}
      loading={isLoading}
      {...testGetTestingAttributes(testIds.modalConfirmationButtonConfirm)}
    >
      {confirmText}
    </Button>

    <Button
      onClick={onCancel}
      className={styles.cancelButton}
      variant={'ghost'}
      colorScheme={'black'}
      size={'large'}
      disabled={isLoading}
      {...testGetTestingAttributes(testIds.modalConfirmationButtonCancel)}
    >
      {cancelText}
    </Button>
  </>
);

export default ModalsConfirmationButtons;
