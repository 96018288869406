import React, { PropsWithChildren } from 'react';

import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';
import { Tooltip } from 'components/Tooltip';

import styles from './CategoryCommonButton.module.scss';

interface CategoryCommonButtonProps {
  title: string;
  tooltip?: string;
  onClick?: () => void;
}

const CategoryCommonButton: React.FC<CategoryCommonButtonProps & PropsWithChildren> = ({
  title,
  tooltip,
  onClick,
  children,
}) => (
  <div className={styles.root}>
    <button
      type="button"
      className={styles.button}
      onClick={onClick}
    >
      {title}
    </button>

    {children}

    {tooltip ? (
      <Tooltip
        content={tooltip}
        placement={'right'}
      >
        <IconCommon
          className={styles.tooltipIcon}
          name={IconCommonNames.INFO_OUTLINED}
          size={IconCommonSizes.TINY}
        />
      </Tooltip>
    ) : null}
  </div>
);

export default CategoryCommonButton;
