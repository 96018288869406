export enum ModalsConfirmationType {
  DEFAULT = 'DEFAULT',
  DISCONNECT = 'DISCONNECT',
  DELETE_CONVERSATION = 'DELETE_CONVERSATION',
  DELETE_CONTACT = 'DELETE_CONTACT',
  DELETE_CALL_HISTORY = 'DELETE_CALL_HISTORY',
  DELETE_CALL_RECORDING = 'DELETE_CALL_RECORDING',
  DELETE_VOICEMAIL = 'DELETE_VOICEMAIL',
  LACK_OF_CREDITS = 'LACK_OF_CREDITS',
  CREDIT_CARD_MISMATCH = 'CREDIT_CARD_MISMATCH',
  PREMIUM_FEATURE = 'PREMIUM_FEATURE',
  NUMBER_RESUBSCRIBE = 'NUMBER_RESUBSCRIBE',
  PLAN_RESUBSCRIBE = 'PLAN_RESUBSCRIBE',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
}

export enum ModalsConfirmationColorScheme {
  DESTRUCTIVE = 'destructive',
  B2B = 'brand-b2b',
  B2C = 'brand-b2c',
}
