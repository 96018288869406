import React from 'react';
import { useIntl } from 'react-intl';

import { CreditCard } from '@onoff/ui';

import { PurchaseCreditCard } from 'types/services/purchase';

import { minTwoDigitNumberAsString } from 'helpers';
import { isBoolean, isNumber, isString } from 'helpers/typeCheckers';

import {
  createCreditCardExpireDate,
  createCreditCardNumber,
  CreditCardBrandOnlyCard,
  CreditCardThemes,
} from 'components/CreditCard';
import { useUserCapabilities } from 'hooks';

import styles from './ModalsSelectCreditCardItem.module.scss';

export interface ModalsSelectCreditCardItemProps {
  card: Pick<PurchaseCreditCard, 'methodId' | 'brand' | 'expiryMonth' | 'expiryYear' | 'last4' | 'default'>;
  isSelected?: boolean;
  onSelect: (methodId: string) => void;
}

const ModalsSelectCreditCardItem: React.FC<ModalsSelectCreditCardItemProps> = ({ card, isSelected, onSelect }) => {
  const intl = useIntl();
  const { isUserB2B } = useUserCapabilities();

  const onClickHandlerSelectCard = (): void => {
    if (isString(card.methodId)) {
      onSelect(card.methodId);
    }
  };

  return (
    <button
      type="button"
      className={styles.root}
      onClick={onClickHandlerSelectCard}
    >
      <div className={styles.radioBorder}>{isSelected ? <span className={styles.radioBackground}></span> : null}</div>

      <div className={styles.buttonText}>
        <CreditCardBrandOnlyCard
          brand={card.brand}
          theme={CreditCardThemes.B2B}
        />
        <div className={styles.card}>
          {isString(card.methodId) &&
            isString(card.brand) &&
            isNumber(card.expiryMonth) &&
            isNumber(card.expiryYear) &&
            isString(card.last4) &&
            isBoolean(card.default) && (
              <CreditCard
                showDefaultCardLozenge
                cardType={card.brand}
                expiry={{
                  month: minTwoDigitNumberAsString(card.expiryMonth),
                  year: minTwoDigitNumberAsString(card.expiryYear),
                }}
                last4={card.last4}
                isDefault={card.default}
                variant={isUserB2B ? 'variant02' : 'variant01'}
                texts={{
                  default: intl.formatMessage({ id: 'Purchase.CreditCard.badge_default' }),
                  expired: intl.formatMessage({ id: 'Purchase.CreditCard.badge_expired' }),
                }}
              />
            )}
        </div>

        {isNumber(card.expiryMonth) && isNumber(card.expiryYear) && isString(card.last4) && (
          <div className={styles.info}>
            <span className={styles.infoText}>{createCreditCardNumber({ last4: card.last4 })}</span>
            <span className={styles.infoText}>
              {createCreditCardExpireDate({
                year: card.expiryYear,
                month: card.expiryMonth,
              })}
            </span>
          </div>
        )}
      </div>
    </button>
  );
};

export default ModalsSelectCreditCardItem;
