interface GroupList<T> {
  dates: Record<string, T[]>;
  datesId: string[];
}

export const getSortValue = <T>(a: T, b: T, reverse = false): 0 | 1 | -1 => {
  if (a < b) {
    return reverse ? 1 : -1;
  }
  if (a > b) {
    return reverse ? -1 : 1;
  }

  return 0;
};

export const sortListByObjectKey = <T = Record<string, string | number>>(
  list: Array<T>,
  key: keyof T,
  reverse = false,
): Array<T> => [...list].sort((a, b) => getSortValue(a[key], b[key], reverse));

export const useGroupListByDate = <T = Record<string, unknown>>(
  list: T[],
  key: keyof T,
  reverse = false,
): GroupList<T> => {
  const dates = [...list]
    .sort((a, b) => getSortValue(b[key], a[key], reverse))
    .reduce<Record<string, T[]>>((acc, callLog) => {
      const date = callLog[key];
      if (typeof date !== 'string') {
        throw new Error('key should be string');
      }

      const formattedDate = date.split('T')[0];
      if (acc[formattedDate]) {
        acc[formattedDate] = [...acc[formattedDate], callLog];
      } else {
        acc[formattedDate] = [callLog];
      }
      return acc;
    }, {});

  const datesId = Object.keys(dates).sort((a, b) => {
    if (parseInt(b.split('/')[1], 10) === parseInt(a.split('/')[1], 10)) {
      return getSortValue(parseInt(b, 10), parseInt(a, 10), reverse);
    }
    return 0;
  });

  return {
    dates,
    datesId,
  };
};
