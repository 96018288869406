import React from 'react';

import { isCategoryExpired } from 'helpers';

import { useWebAppSelector, selectCategoriesState, selectCategoriesByVisibilityAndExpiredFirst } from '@redux';
import { CategoryCommon, CategoryExpired } from 'components/Category';

import CategoryListLoading from './components/CategoryListLoading';

import styles from './CategoryList.module.scss';

export interface CategoryListProps {
  isMinimized: boolean;
}

const CategoryList: React.FC<CategoryListProps> = ({ isMinimized }) => {
  const isCategoriesLoading = useWebAppSelector(selectCategoriesState).isLoading;
  const visibleCategoriesExpiredFirst = useWebAppSelector(selectCategoriesByVisibilityAndExpiredFirst);

  if (isCategoriesLoading) {
    return <CategoryListLoading isMinimized={isMinimized} />;
  }

  return (
    <div className={styles.root}>
      {visibleCategoriesExpiredFirst.map((category) => {
        const CategoryDynamic = isCategoryExpired(category) ? CategoryExpired : CategoryCommon;
        return (
          <CategoryDynamic
            key={category.id}
            category={category}
            isMinimized={isMinimized}
          />
        );
      })}
    </div>
  );
};

export default CategoryList;
