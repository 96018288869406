import React from 'react';

import { StyleableComponent } from 'types';

const PremiumUnlocked: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle
      opacity="0.03"
      cx="100"
      cy="100"
      r="100"
      fill="#32A8FF"
    />
    <circle
      opacity="0.03"
      cx="99.9993"
      cy="100"
      r="87.3684"
      fill="#32A8FF"
    />
    <circle
      opacity="0.05"
      cx="99.9998"
      cy="100"
      r="72.1053"
      fill="#32A8FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.5064 165.328L31.5222 66.8907C29.5867 60.9337 32.8467 54.5354 38.8037 52.5999L119.777 26.2901C125.734 24.3545 132.132 27.6146 134.068 33.5716L166.052 132.009C167.987 137.965 164.727 144.363 158.77 146.299L77.7969 172.608C71.8399 174.544 65.4416 171.284 63.5064 165.328Z"
      fill="white"
    />
    <rect
      x="56.4004"
      y="103.343"
      width="84.6"
      height="6"
      rx="3"
      transform="rotate(-18 56.4004 103.343)"
      fill="#D7EEFF"
    />
    <rect
      x="60.1074"
      y="113.156"
      width="84.6"
      height="5.99999"
      rx="2.99999"
      transform="rotate(-18 60.1074 113.156)"
      fill="#D7EEFF"
    />
    <rect
      x="63.8164"
      y="122.968"
      width="84.6"
      height="6"
      rx="3"
      transform="rotate(-18 63.8164 122.968)"
      fill="#D7EEFF"
    />
    <circle
      cx="64.4212"
      cy="72.4213"
      r="17"
      transform="rotate(-18 64.4212 72.4213)"
      fill="#32A8FF"
    />
    <g clipPath="url(#clip0_10244_132610)">
      <path
        d="M58.743 79.9534L73.4411 75.1777L73.9187 76.6475L59.2205 81.4232L58.743 79.9534ZM55.4 69.6647L59.7908 70.6755L62.0327 65.0721L67.1399 68.2876L70.0981 64.889L72.9635 73.7079L58.2654 78.4836L55.4 69.6647Z"
        fill="white"
      />
    </g>
    <g clipPath="url(#clip1_10244_132610)">
      <g filter="url(#filter0_dd_10244_132610)">
        <circle
          cx="151.69"
          cy="130.89"
          r="21.69"
          fill="white"
        />
      </g>
      <path
        d="M147.54 135.634L143.102 131.195C142.607 130.7 141.821 130.7 141.326 131.195C140.831 131.689 140.831 132.476 141.326 132.97L146.64 138.284C147.135 138.779 147.934 138.779 148.428 138.284L161.872 124.853C162.366 124.359 162.366 123.572 161.872 123.078C161.377 122.583 160.591 122.583 160.096 123.078L147.54 135.634Z"
        fill="#32A8FF"
      />
    </g>
    <path
      d="M100.927 2L102.199 8.58343C102.794 11.6578 105.197 14.0611 108.272 14.6555L114.855 15.9275L108.272 17.1994C105.197 17.7938 102.794 20.1971 102.199 23.2715L100.927 29.8549L99.6555 23.2715C99.0611 20.1971 96.6578 17.7938 93.5834 17.1994L87 15.9275L93.5834 14.6555C96.6578 14.0611 99.0611 11.6578 99.6555 8.58343L100.927 2Z"
      fill="#FFCF3E"
    />
    <path
      d="M13.4597 81L14.3226 85.4742C14.7252 87.5579 16.3615 89.1941 18.4452 89.5968L22.9194 90.4597L18.4452 91.3226C16.3551 91.7252 14.7252 93.3615 14.3226 95.4452L13.4597 99.9194L12.5968 95.4452C12.1941 93.3615 10.5579 91.7252 8.47417 91.3226L4 90.4597L8.47417 89.5968C10.5579 89.1941 12.1941 87.5579 12.5968 85.4742L13.4597 81Z"
      fill="#47D7BB"
    />
    <path
      d="M181.46 48L182.323 52.4742C182.725 54.5579 184.362 56.1941 186.445 56.5968L190.919 57.4597L186.445 58.3226C184.355 58.7252 182.725 60.3615 182.323 62.4452L181.46 66.9194L180.597 62.4452C180.194 60.3615 178.558 58.7252 176.474 58.3226L172 57.4597L176.474 56.5968C178.564 56.1941 180.194 54.5579 180.597 52.4742L181.46 48Z"
      fill="#32A8FF"
    />
    <path
      d="M131.71 172L132.343 175.266C132.637 176.787 133.826 177.983 135.353 178.277L138.62 178.909L135.353 179.542C133.832 179.836 132.637 181.025 132.343 182.553L131.71 185.819L131.077 182.553C130.783 181.031 129.595 179.836 128.067 179.542L124.801 178.909L128.067 178.277C129.588 177.983 130.783 176.794 131.077 175.266L131.71 172Z"
      fill="#32A8FF"
    />
    <defs>
      <filter
        id="filter0_dd_10244_132610"
        x="120.543"
        y="109.2"
        width="62.2953"
        height="62.2944"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity="0"
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1.5762"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_10244_132610"
        />
        <feOffset dy="3.15241" />
        <feGaussianBlur stdDeviation="2.3643" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.152941 0 0 0 0 0.2 0 0 0 0 0.282353 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10244_132610"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="3.15241"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_10244_132610"
        />
        <feOffset dy="9.45722" />
        <feGaussianBlur stdDeviation="6.30481" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.152941 0 0 0 0 0.2 0 0 0 0 0.282353 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_10244_132610"
          result="effect2_dropShadow_10244_132610"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_10244_132610"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_10244_132610">
        <rect
          width="18.5455"
          height="18.5455"
          fill="white"
          transform="translate(52.7363 66.4678) rotate(-18)"
        />
      </clipPath>
      <clipPath id="clip1_10244_132610">
        <rect
          x="114.801"
          y="94"
          width="74"
          height="74"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PremiumUnlocked;
