import { Reducer } from '@reduxjs/toolkit';

import { Status } from '../../../types';
import { CallQualitySurveyActionTypes, CallQualitySurveyReduxState, REDUX_ACTION_TYPES } from '../../types';

export const callQualitySurveyInitialState: CallQualitySurveyReduxState = {
  isPopupOpen: false,
  statuses: {
    initializeSurvey: Status.IDLE,
    submitSurvey: Status.IDLE,
  },
  surveyData: {},
};

export const callQualitySurveyReducer: Reducer<CallQualitySurveyReduxState, CallQualitySurveyActionTypes> = (
  state,
  action,
) => {
  const reducerState: CallQualitySurveyReduxState = state || callQualitySurveyInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.CALL_QUALITY_SURVEY_START_SURVEY: {
      return {
        ...reducerState,
        isPopupOpen: true,
        surveyData: action.surveyData,
      };
    }

    case REDUX_ACTION_TYPES.CALL_QUALITY_SURVEY_END_SURVEY: {
      return {
        ...reducerState,
        isPopupOpen: false,
        statuses: {
          initializeSurvey: Status.IDLE,
          submitSurvey: Status.IDLE,
        },
      };
    }

    case REDUX_ACTION_TYPES.CALL_QUALITY_SURVEY_SET_STATUS_INITIALIZE_SURVEY: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          initializeSurvey: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.CALL_QUALITY_SURVEY_SET_STATUS_SUBMIT_SURVEY: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          submitSurvey: action.status,
        },
      };
    }

    default:
      return reducerState;
  }
};
