import classNames from 'classnames';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Input, InputLabel } from '@onoff/ui';

import { allowedCountries } from '@constants/countries';
import { testGetTestingAttributes, testIds } from '@testing';
import CountrySelector from 'components/CountrySelector';

import { AddCreditCardFormData, countriesWithStrictAVSCheck } from '../..';

import styles from './BillingAddress.module.scss';

const BillingAddress: React.FC = () => {
  const intl = useIntl();
  const { control, register, formState, watch } = useFormContext<AddCreditCardFormData>();
  const { errors } = formState;

  const showCityAndAddressFields = countriesWithStrictAVSCheck.includes(watch('countryIsoCode'));

  return (
    <fieldset
      className={classNames(styles.root)}
    >
      <legend>
        <InputLabel.Text>
          <FormattedMessage id="Modals.ModalAddCreditCard.BillingAddress.title" />
        </InputLabel.Text>
      </legend>
      <div className={classNames(styles.formFields, {
        [styles.fewerFields]: !showCityAndAddressFields,
      })}>
        <Controller
          control={control}
          render={({ field }) => {
            const { onChange, value } = field;

            return (
              <CountrySelector
                countryCode={value}
                onChange={onChange}
                countries={allowedCountries}
              />
            );
          }}
          name="countryIsoCode"
        />

        {showCityAndAddressFields && (
          <>
            <Input
              {...testGetTestingAttributes(testIds.addCreditCardInputCity)}
              {...register('city')}
              errorMessage={errors.city?.message}
              placeholder={intl.formatMessage({ id: 'Modals.ModalAddCreditCard.Inputs.city_placeholder' })}
            />
            <Input
              {...testGetTestingAttributes(testIds.addCreditCardInputAddress)}
              {...register('address')}
              errorMessage={errors.address?.message}
              placeholder={intl.formatMessage({ id: 'Modals.ModalAddCreditCard.Inputs.address_placeholder' })}
            />
          </>
        )}
        <Input
          {...testGetTestingAttributes(testIds.addCreditCardInputZip)}
          {...register('zipCode')}
          errorMessage={errors.zipCode?.message}
          placeholder={intl.formatMessage({ id: 'Modals.ModalAddCreditCard.Inputs.zip_placeholder' })}
        />
      </div>
    </fieldset>
  );
};

export default BillingAddress;
