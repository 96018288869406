import { useEffect } from 'react';

import { Status } from 'types';

import { formatPurchasePrice } from 'helpers';

import { DISCOUNT_CODES } from '@constants';
import {
  plansAndOffersFetchUserPlanPremiumOfferPrice,
  plansAndOffersSetFetchUserPlanPremiumPriceStatus,
  plansAndOffersSetSubmitUserPlanPremiumStatus,
  selectPlansAndOffersSelectedPremium,
  selectPlansAndOffersStatuses,
  useWebAppDispatch,
  useWebAppSelector
} from '@redux';
import { PlansAndOffersReduxState } from '@redux/types';

import { useDetectPremiumOfferFlow } from '../useDetectPremiumOfferFlow';

type UsePremiumOfferReturn = Pick<PlansAndOffersReduxState, 'statuses'> & {
  selectedPremiumPlan: PlansAndOffersReduxState['selectedPremiumPlan'];
  displayPrice: string;
  hasPriceData: boolean;
};

export const usePremiumOffer = (): UsePremiumOfferReturn => {
  const dispatch = useWebAppDispatch();

  const { isPremiumOfferFreeTrialFlow, isPremiumOfferTrialAvailable } = useDetectPremiumOfferFlow();
  const discountCode =
    isPremiumOfferFreeTrialFlow || isPremiumOfferTrialAvailable ? DISCOUNT_CODES.PREMIUM_TRIAL : undefined;

  const statuses = useWebAppSelector(selectPlansAndOffersStatuses);
  const selectedPremiumPlan = useWebAppSelector(selectPlansAndOffersSelectedPremium);
  const { price, currencyCode, productId } = selectedPremiumPlan;

  const hasPriceData = productId.length > 0;
  const displayPrice = formatPurchasePrice({
    price,
    currencyCode,
  });

  useEffect(() => {
    dispatch(plansAndOffersFetchUserPlanPremiumOfferPrice(discountCode));
  }, [dispatch, discountCode]);

  useEffect(
    () => () => {
      dispatch(plansAndOffersSetFetchUserPlanPremiumPriceStatus(Status.IDLE));
      dispatch(plansAndOffersSetSubmitUserPlanPremiumStatus(Status.IDLE));
    },
    [dispatch],
  );

  return {
    selectedPremiumPlan,
    statuses,
    displayPrice,
    hasPriceData,
  };
};
