import { useEffect, useRef } from 'react';

import {
  useWebAppDispatch,
  notificationsBrowserSetCallHandler,
  sipSetDataCallStatusWebRTCByWebRTCIdHandler,
  sipSetDataCallTimestampRangByWebRTCIdHandler,
} from '@redux';
import {
  SIPStatisticsScopes,
  SIPDataCallStatusesWebRTC,
  SIPWebRTCDirections,
  SIPWebRTCOriginators,
  SIPWebRTCStatusCodes,
  SIPWebRTCStatusReasons,
} from '@redux/types';

import { checkSIPCallsMaximumWebRTCSessions, sipDebugConsoleLogger } from '../../../../../../helpers';
import { RTCSession } from '../../../../../../types';
import { useSIPStatistics } from '../../../../../useSIPStatistics';

import {
  useWebRTCSessionsAnswer,
  useWebRTCSessionsDTMF,
  useWebRTCSessionsHoldOrUnHold,
  useWebRTCSessionsMuteOrUnMute,
  useWebRTCSessionsTerminateOrReject,
} from './methods';
import { UseWebRTCSessionsProps } from './types';

export const useWebRTCSessions = ({ newWebRTCSession }: UseWebRTCSessionsProps): void => {
  const dispatch = useWebAppDispatch();
  const refSIPWebRTCSessions = useRef<RTCSession[]>([]);

  const { sipSendLogToGTM } = useSIPStatistics();

  useWebRTCSessionsAnswer({ propWebRTCSessions: refSIPWebRTCSessions.current });

  useWebRTCSessionsDTMF({ propWebRTCSessions: refSIPWebRTCSessions.current });

  useWebRTCSessionsHoldOrUnHold({ propWebRTCSessions: refSIPWebRTCSessions.current });

  useWebRTCSessionsMuteOrUnMute({ propWebRTCSessions: refSIPWebRTCSessions.current });

  useWebRTCSessionsTerminateOrReject({ propWebRTCSessions: refSIPWebRTCSessions.current });

  const callbackWebRTCSessionsHandler = (): void => {
    if (newWebRTCSession) {
      /**
       * All WebRTC Sessions
       */
      const prevSIPWebRTCSessions = refSIPWebRTCSessions.current;

      /**
       * Current WebRTC Session Data
       */
      const {
        id: newWebRTCSessionId,
        direction: newWebRTCSessionDirection,
        remote_identity: newWebRTCSessionRemoteIdentity,
      } = newWebRTCSession;

      /**
       * Prevent Duplications for WebRTC Sessions
       */
      const isStateHasThisNewSession = prevSIPWebRTCSessions.find(({ id }) => id === newWebRTCSessionId) !== undefined;
      if (isStateHasThisNewSession) {
        return;
      }

      /**
       * Update the WebRTC Sessions Ref with the Current One
       * Append or Prepend actually does not matter,
       * but we Appended it (New Calls are on top by Redux and UI)
       */
      refSIPWebRTCSessions.current = [newWebRTCSession, ...prevSIPWebRTCSessions];

      /**
       * Current WebRTC Originator for Logs and Browser Notification
       */
      const newWebRTCSessionOriginator =
        (newWebRTCSessionDirection as unknown as SIPWebRTCDirections | undefined) === SIPWebRTCDirections.INCOMING
          ? SIPWebRTCOriginators.REMOTE
          : SIPWebRTCOriginators.LOCAL;

      /**
       * Check The Allowed Maximum WebRTC Sessions
       * and Terminate(Busy) the Call if needed(over-exceeded)
       */
      const { isMaximumAllowedWebRTCSessions } = checkSIPCallsMaximumWebRTCSessions({
        countActiveSessions: prevSIPWebRTCSessions.filter((session) => !session.isEnded()).length,
      });
      if (isMaximumAllowedWebRTCSessions) {
        sipDebugConsoleLogger('SIP WebRTC: MAXIMUM ALLOWED SESSIONS!');

        newWebRTCSession.terminate({
          status_code: SIPWebRTCStatusCodes.BUSY_486,
          cause: SIPWebRTCStatusReasons.BUSY_486,
          reason_phrase: SIPWebRTCStatusReasons.BUSY_486,
        });

        dispatch(
          sipSetDataCallStatusWebRTCByWebRTCIdHandler({
            propCallIdWebRTC: newWebRTCSessionId,
            propCallStatusWebRTC: SIPDataCallStatusesWebRTC.BUSY,
          }),
        );

        sipSendLogToGTM({
          sipCallCase: SIPStatisticsScopes.SIP_WEBRTC,
          sipCallOriginator: newWebRTCSessionOriginator,
          sipCallTitle: 'MAXIMUM ALLOWED SESSIONS!',
          sipCallDescription: '',
          sipCallTransactionId: '',
        });

        return;
      }

      /**
       * All Fine,
       * Update the Current WebRTC Session's Redux State
       * The call is technically Accepted and On-Delivery at this time,
       * But we can consider it as RINGING_180 (for rendering the Call UI),
       * The actual SIP RINGING_(180_or_183) and the timestamp states will be set by "useWebRTCProgress"
       */
      dispatch(
        sipSetDataCallStatusWebRTCByWebRTCIdHandler({
          propCallIdWebRTC: newWebRTCSessionId,
          propCallStatusWebRTC: SIPDataCallStatusesWebRTC.RINGING_180,
        }),
      );
      dispatch(
        sipSetDataCallTimestampRangByWebRTCIdHandler({
          propCallIdWebRTC: newWebRTCSessionId,
          propCallTimestampCallRang: 0,
        }),
      );

      /**
       * Show the Browser Notification,
       * if it is an Incoming Call
       */
      const isCallIncoming = newWebRTCSessionOriginator === SIPWebRTCOriginators.REMOTE;
      const { uri: { user: phoneNumberRemote = '' } = {} } = newWebRTCSessionRemoteIdentity || {};
      if (isCallIncoming && phoneNumberRemote) {
        dispatch(
          notificationsBrowserSetCallHandler({
            sourcePhoneNumber: phoneNumberRemote,
          }),
        );
      }
    }
  };

  useEffect(callbackWebRTCSessionsHandler, [newWebRTCSession, sipSendLogToGTM, dispatch]);
};
