import classNames from 'classnames';
import React, { useRef, useEffect, useState } from 'react';

import { testGetTestingAttributes } from '@testing';
import { BorderWrapperCore } from 'components/BorderWrapper';
import { InputCommonLayout } from 'components/Input';

import { useOnChangeWithCalculation } from '../../hooks';
import { TextAreaNewContactProps } from '../../types';

import styles from './TextAreaNewContact.module.scss';

const TextAreaNewContact: React.FC<TextAreaNewContactProps> = ({
  onChange,
  className = '',
  value = '',
  label = '',
  placeholder = '',
  propTestId = '',
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [hasHover, setHasHover] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { rows, onChangeHandler, makeCalculation, isEmpty } = useOnChangeWithCalculation({
    onChange,
    value,
    rowsMax: 5,
    rowsMin: 5,
  });

  useEffect(() => {
    if (textareaRef.current !== null) {
      makeCalculation(textareaRef.current);
    }
  }, [textareaRef, makeCalculation]);

  return (
    <InputCommonLayout
      className={classNames(styles.root, className)}
      label={label}
    >
      <BorderWrapperCore
        className={styles.borderWrapper}
        hasFocus={hasFocus}
        isEmpty={isEmpty}
        hasHover={hasHover}
        onMouseOver={() => setHasHover(true)}
        onMouseLeave={() => setHasHover(false)}
      >
        <textarea
          ref={textareaRef}
          className={classNames(styles.textarea)}
          onChange={onChangeHandler}
          rows={rows}
          defaultValue={value}
          placeholder={placeholder}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
          {...testGetTestingAttributes(propTestId)}
        />
      </BorderWrapperCore>
    </InputCommonLayout>
  );
};

export default TextAreaNewContact;
