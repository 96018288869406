import React from 'react';

import { StyleableComponent } from 'types';

const Credits: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M200 100C200 155.228 155.228 200 100 200C44.7715 200 0 155.228 0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 99.9998C187.368 148.252 148.252 187.368 100 187.368C51.7478 187.368 12.6316 148.252 12.6316 99.9998C12.6316 51.7475 51.7478 12.6313 100 12.6313C148.252 12.6313 187.368 51.7475 187.368 99.9998Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 99.9998C172.105 139.822 139.823 172.105 100 172.105C60.1773 172.105 27.8947 139.822 27.8947 99.9998C27.8947 60.1772 60.1773 27.8945 100 27.8945C139.823 27.8945 172.105 60.1772 172.105 99.9998Z"
      fill="#F3F4F5"
    />
    <mask
      id="mask0_13458_5454"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="200"
      height="200"
    >
      <path
        d="M0 0H200V200H0V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_13458_5454)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.951 53.8003C124.951 53.8003 55.4511 96.8003 37.4511 107.8C33.9511 109.939 31.5507 113.209 31.5507 113.209L52.0111 152.344L143.951 92.8002L124.951 53.8003Z"
        fill="#8D929C"
      />
      <path
        d="M108.122 65.0147C116.465 74.9569 115.168 89.7797 105.226 98.1222C95.2835 106.465 80.4607 105.168 72.1182 95.2257C63.7757 85.2834 65.0725 70.4607 75.0147 62.1181C84.957 53.7756 99.7797 55.0724 108.122 65.0147Z"
        fill="#E4E5E7"
      />
      <path
        d="M104.292 68.2285C110.86 76.0554 109.839 87.7244 102.012 94.2919C94.185 100.859 82.516 99.8385 75.9485 92.0117C69.3809 84.1848 70.4018 72.5158 78.2287 65.9483C86.0556 59.3807 97.7246 60.4016 104.292 68.2285Z"
        fill="#C7CBCE"
      />
      <path
        d="M81.5453 75.7176L84.1684 76.6206C84.5603 75.9004 85.0533 75.2596 85.6471 74.699C86.577 73.8211 87.6536 73.253 88.8732 72.9974L89.0874 72.9525L89.6238 75.1857L89.2689 75.3074C87.5643 75.9153 86.4272 77.0596 85.8436 78.7543C85.3697 80.1307 85.4167 81.4296 85.9828 82.6628C86.5474 83.8928 87.4779 84.7282 88.7858 85.1786C90.0961 85.6298 91.3713 85.5603 92.6244 84.9693C93.8751 84.3795 94.7388 83.3882 95.2225 81.9837C95.8033 80.2968 95.5756 78.6346 94.5331 76.9786L94.4235 76.8045L96.3027 75.4282L96.5598 75.7803C97.2584 76.7659 97.6787 77.8662 97.8195 79.0781C97.9153 79.903 97.8885 80.7279 97.7392 81.5523L100.687 82.4452L99.6649 85.4147L96.6463 84.5016C95.9317 85.6872 94.9384 86.5946 93.6689 87.2199C91.8797 88.1011 90.0185 88.2114 88.0993 87.5506C86.1807 86.8899 84.7646 85.6474 83.8609 83.8318C83.2017 82.5072 82.9781 81.0967 83.1888 79.6054L80.5228 78.6871L81.5453 75.7176Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.596 55.5298C135.596 55.5298 82.5867 89.5956 63.8365 100.584C37.914 115.773 31.5507 113.209 31.5507 113.209L73.8189 194.053C73.8189 194.053 79.7356 194.988 94.2329 184.975C112.581 172.302 171.332 133.368 171.332 133.368L135.596 55.5298Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7473 124.857L77.5728 178.998C78.0404 179.908 77.6783 181.036 76.7677 181.504C75.857 181.973 74.7287 181.611 74.2601 180.7L46.4357 126.559C45.9671 125.648 46.3293 124.52 47.2398 124.052C48.1513 123.585 49.2796 123.947 49.7473 124.857Z"
        fill="#E4E4E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.3903 100.148C113.666 90.164 133.203 92.4089 141.028 105.164C148.852 117.918 142.002 136.352 125.725 146.337C109.45 156.322 89.9124 154.077 82.088 141.322C74.2626 128.568 81.1144 110.133 97.3903 100.148Z"
        fill="currentColor"
      />
      <path
        d="M104.93 106.006L107.59 110.432C109.011 109.874 110.48 109.55 111.998 109.462C114.374 109.322 116.589 109.8 118.634 110.895L118.993 111.087L117.086 114.913L116.434 114.662C113.276 113.49 110.286 113.761 107.426 115.479C105.104 116.875 103.616 118.783 102.948 121.219C102.281 123.648 102.609 125.952 103.935 128.16C105.263 130.371 107.165 131.797 109.659 132.454C112.148 133.109 114.565 132.729 116.935 131.305C119.782 129.595 121.446 126.952 121.941 123.344L121.993 122.964L126.319 123.25L126.264 124.06C126.081 126.301 125.364 128.373 124.115 130.269C123.265 131.56 122.239 132.704 121.04 133.701L124.176 138.502L119.165 141.513L115.953 136.599C113.516 137.435 111.014 137.54 108.455 136.913C104.85 136.028 102.064 133.959 100.118 130.72C98.1727 127.483 97.6401 124.017 98.5239 120.347C99.1687 117.669 100.537 115.39 102.622 113.516L99.9195 109.017L104.93 106.006Z"
        fill="white"
      />
      <path
        d="M144.213 42.1096C134.271 50.4521 119.448 49.1553 111.106 39.2131C102.763 29.2708 104.06 14.448 114.002 6.10551C123.944 -2.23703 138.767 -0.940211 147.11 9.00204C155.452 18.9443 154.155 33.7671 144.213 42.1096Z"
        fill="#E4E5E7"
      />
      <path
        d="M140.999 38.2792C133.172 44.8467 121.503 43.8258 114.936 35.9989C108.368 28.172 109.389 16.503 117.216 9.93552C125.043 3.36798 136.712 4.38889 143.279 12.2158C149.847 20.0426 148.826 31.7116 140.999 38.2792Z"
        fill="#C7CBCE"
      />
      <path
        d="M133.51 15.5323L132.607 18.1554C133.327 18.5474 133.968 19.0404 134.529 19.6342C135.407 20.5641 135.975 21.6407 136.231 22.8603L136.275 23.0745L134.042 23.6109L133.921 23.256C133.313 21.5513 132.168 20.4142 130.474 19.8307C129.097 19.3568 127.798 19.4038 126.565 19.9699C125.335 20.5344 124.5 21.4649 124.049 22.7729C123.598 24.0832 123.668 25.3583 124.259 26.6114C124.848 27.8622 125.84 28.7259 127.244 29.2095C128.931 29.7904 130.593 29.5627 132.249 28.5202L132.423 28.4105L133.8 30.2897L133.448 30.5469C132.462 31.2455 131.362 31.6657 130.15 31.8065C129.325 31.9024 128.5 31.8755 127.676 31.7262L126.783 34.6744L123.813 33.6519L124.726 30.6334C123.541 29.9188 122.633 28.9255 122.008 27.6559C121.127 25.8668 121.016 24.0056 121.677 22.0864C122.338 20.1677 123.581 18.7516 125.396 17.848C126.721 17.1887 128.131 16.9651 129.623 17.1758L130.541 14.5098L133.51 15.5323Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.279 84.7944L170.655 82.4106L168 81.2793L170.384 79.654L171.515 77L173.14 79.3844L175.794 80.5151L173.411 82.1397L172.279 84.7944Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.2793 31.7944L64.6547 29.4106L62 28.2793L64.3838 26.654L65.5151 24L67.1397 26.3844L69.7944 27.5151L67.4106 29.1397L66.2793 31.7944Z"
      fill="#E4E4E4"
    />
  </svg>
);

export default Credits;
