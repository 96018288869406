import {
  useWebAppDispatch,
  contactsPubNubAddSimpleContactHandler,
  contactsPubNubUpdateSimpleContactHandler,
  deleteSimpleContacts,
  fetchContacts,
} from '@redux';

import {
  UsePubNubMessagesHandlerPageContactsReturn,
  PubNubPushMessagePageContactsNewSimpleContactPayload,
  PubNubPushMessagePageContactsUpdateSimpleContactPayload,
  PubNubPushMessagePageContactsDeleteSimpleContactPayload,
} from '../../types';

export const usePubNubMessagesHandlerPageContacts = (): UsePubNubMessagesHandlerPageContactsReturn => {
  const dispatch = useWebAppDispatch();

  const pubNubMessageHandlerPageContactsNewSimpleContact = (
    contact: PubNubPushMessagePageContactsNewSimpleContactPayload,
  ): void => {
    dispatch(contactsPubNubAddSimpleContactHandler({ contact }));
  };

  const pubNubMessageHandlerPageContactsUpdateSimpleContact = (
    contact: PubNubPushMessagePageContactsUpdateSimpleContactPayload,
  ): void => {
    dispatch(contactsPubNubUpdateSimpleContactHandler({ contact }));
  };

  const pubNubMessageHandlerPageContactsDeleteSimpleContact = (
    contactIds: PubNubPushMessagePageContactsDeleteSimpleContactPayload,
  ): void => {
    dispatch(deleteSimpleContacts(contactIds));
  };

  const pubNubMessageHandlerPageContactsDeleteAllSimpleContacts = (): void => {
    dispatch(fetchContacts());
  };

  const pubNubMessageHandlerPageContactsSyncAllSimpleContacts = (): void => {
    dispatch(fetchContacts());
  };

  return {
    pubNubMessageHandlerPageContactsNewSimpleContact,
    pubNubMessageHandlerPageContactsUpdateSimpleContact,
    pubNubMessageHandlerPageContactsDeleteSimpleContact,
    pubNubMessageHandlerPageContactsDeleteAllSimpleContacts,
    pubNubMessageHandlerPageContactsSyncAllSimpleContacts,
  };
};
