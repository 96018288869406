import { Status } from '../../../types';
import {
  REDUX_ACTION_TYPES,
  SIPActionClearDataStartCall,
  SIPActionRemoveDataCallByCallIdentifier,
  SIPActionRemoveDataCallInitialising,
  SIPActionSetDataCall,
  SIPActionSetDataCalls,
  SIPActionSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabled,
  SIPActionSetDataCallsCapabilitiesHdCodecEnabled,
  SIPActionSetDataCallsCapabilitiesIncomingRingtoneDisabled,
  SIPActionSetDataConfig,
  SIPActionSetDataConnect,
  SIPActionSetDataStartCallIncoming,
  SIPActionSetDataStartCallIncomingProps,
  SIPActionSetDataStartCallOutgoing,
  SIPActionSetDataStartCallOutgoingProps,
  SIPActionSetDataStunRecords,
  SIPActionSetDataWssRecords,
  SIPActionSetDataWssUnavailableUrlOrEmpty,
  SIPActionSetStatusCallInitialization,
  SIPActionSetStatusCancelCallRecording,
  SIPActionSetStatusInitiateCallTransfer,
  SIPActionSetStatusSIP,
  SIPActionUpdateDataCallInitialising,
  SIPDataCall,
  SIPDataConfig,
  SIPDataConnect,
  SIPDataWssRecord,
  SIPStatuses,
} from '../../types';

export const sipSetStatusSIP = (statusSIP: SIPStatuses): SIPActionSetStatusSIP => ({
  type: REDUX_ACTION_TYPES.SIP_SET_STATUS_SIP,
  statusSIP,
});

export const sipSetStatusCallInitialization = (statusInitializeCall: Status): SIPActionSetStatusCallInitialization => ({
  type: REDUX_ACTION_TYPES.SIP_SET_STATUS_CALL_INITIALIZATION,
  statusInitializeCall,
});

export const sipSetStatusInitiateCallTransfer = (
  statusInitiateCallTransfer: Status,
): SIPActionSetStatusInitiateCallTransfer => ({
  type: REDUX_ACTION_TYPES.SIP_SET_STATUS_INITIATE_CALL_TRANSFER,
  statusInitiateCallTransfer,
});

export const sipSetStatusCancelCallRecording = (
  statusCancelCallRecording: Status,
): SIPActionSetStatusCancelCallRecording => ({
  type: REDUX_ACTION_TYPES.SIP_SET_STATUS_CANCEL_CALL_RECORDING,
  statusCancelCallRecording,
});

export const sipSetDataStartCallIncoming = (
  incoming: SIPActionSetDataStartCallIncomingProps,
): SIPActionSetDataStartCallIncoming => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_START_CALL_INCOMING,
  incoming,
});

export const sipSetDataStartCallOutgoing = (
  outgoing: SIPActionSetDataStartCallOutgoingProps,
): SIPActionSetDataStartCallOutgoing => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_START_CALL_OUTGOING,
  outgoing,
});

export const sipClearDataStartCall = (): SIPActionClearDataStartCall => ({
  type: REDUX_ACTION_TYPES.SIP_CLEAR_DATA_START_CALL,
});

export const sipSetDataConnect = (dataSIPConnect: SIPDataConnect): SIPActionSetDataConnect => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_CONNECT,
  dataSIPConnect,
});

export const sipSetDataConfig = (dataSIPConfig: SIPDataConfig): SIPActionSetDataConfig => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_CONFIG,
  dataSIPConfig,
});

export const sipSetDataWssRecords = (wssRecords: SIPDataWssRecord[]): SIPActionSetDataWssRecords => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_WSS_RECORDS,
  wssRecords,
});

export const sipSetDataWssUnavailableUrlOrEmpty = (
  wssUnavailableUrlOrEmpty: SIPDataWssRecord['wssUrl'],
): SIPActionSetDataWssUnavailableUrlOrEmpty => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_WSS_UNAVAILABLE_URL_OR_EMPTY,
  wssUnavailableUrlOrEmpty,
});

export const sipSetDataStunRecords = (stunRecords: string[]): SIPActionSetDataStunRecords => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_STUN_RECORDS,
  stunRecords,
});

export const sipSetDataCall = (dataCall: SIPDataCall): SIPActionSetDataCall => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_CALL,
  dataCall,
});

export const sipUpdateDataCallByInitialisingId = (
  initialisingId: string,
  dataCall: SIPDataCall,
): SIPActionUpdateDataCallInitialising => ({
  type: REDUX_ACTION_TYPES.SIP_UPDATE_DATA_CALL_BY_INITIALISING_ID,
  dataCall,
  initialisingId,
});

export const sipRemoveDataCallByInitialisingId = (initialisingId: string): SIPActionRemoveDataCallInitialising => ({
  type: REDUX_ACTION_TYPES.SIP_REMOVE_DATA_CALL_BY_INITIALISING_ID,
  initialisingId,
});

export const sipRemoveDataCallByCallIdentifier = (callIdentifier: string): SIPActionRemoveDataCallByCallIdentifier => ({
  type: REDUX_ACTION_TYPES.SIP_REMOVE_DATA_CALL_BY_CALL_IDENTIFIER,
  callIdentifier,
});

export const sipSetDataCalls = (dataCalls: SIPDataCall[]): SIPActionSetDataCalls => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_CALLS,
  dataCalls,
});

export const sipSetDataCallsCapabilitiesIncomingRingtoneDisabled = (
  isCallsIncomingRingtoneDisabled: boolean,
): SIPActionSetDataCallsCapabilitiesIncomingRingtoneDisabled => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_CALLS_CAPABILITIES_INCOMING_RINGTONE_DISABLED,
  isCallsIncomingRingtoneDisabled,
});

export const sipSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabled = (
  isCallsWebRTCAdvancedMediaConstraintsEnabled: boolean,
): SIPActionSetDataCallsCapabilitiesAdvancedMediaConstraintsEnabled => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_CALLS_CAPABILITIES_ADVANCED_MEDIA_CONSTRAINTS_ENABLED,
  isCallsWebRTCAdvancedMediaConstraintsEnabled,
});

export const sipSetDataCallsCapabilitiesHdCodecEnabled = (
  isCallsWebRTCHdCodecEnabled: boolean,
): SIPActionSetDataCallsCapabilitiesHdCodecEnabled => ({
  type: REDUX_ACTION_TYPES.SIP_SET_DATA_CALLS_CAPABILITIES_HD_CODEC_ENABLED,
  isCallsWebRTCHdCodecEnabled,
});
