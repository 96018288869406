export const getCharacterCount = (str: string) => str.split('').filter((char) => char !== ' ').length;

export const cleanFromNonNumeric = (str: string) => str.replace(/[^0-9.]/, '');

export const compareCharacterCounts = (strA: string, strB: string) => {
  const hasChangesToValue = strA.length !== strB.length;
  const hasDeletedValue = strA.length > strB.length;

  return {
    hasChangesToValue,
    hasDeletedValue,
  };
};
