import classnames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { Category, CategoryPlanStatusTypes } from 'types';

import { canCategorySendSMS } from 'helpers';
import { CategoryColorsStatic } from 'helpers/category/colors';

import {
  useWebAppDispatch,
  stealthOpenModal,
  switchCategoryStatusAndUpdate,
  switchCategoryStatusAndUpdateForCalls,
  switchCategoryStatusAndUpdateForMessages,
  switchCategoryStatusAndUpdateForVoicemails,
  switchCategoryVisibility,
} from '@redux';
import { testIds } from '@testing';
import { SwitchSimple, SwitchToggle } from 'components/Switch';
import { useUserCapabilities } from 'hooks';

import CategoryCommonButtonNumberSettings from '../CategoryCommonButtonNumberSettings';
import CategoryCommonColors from '../CategoryCommonColors';
import CategoryCommonInfoList from '../CategoryCommonInfoList';
import CategoryCommonName from '../CategoryCommonName';
import CategoryCommonNumber from '../CategoryCommonNumber';
import CategoryCommonOptions from '../CategoryCommonOptions';
import CategoryCommonSwitch from '../CategoryCommonSwitch';
import CategoryMinimized from '../CategoryMinimized';
import CategorySubscriptionEndInfo from '../CategorySubscriptionEndInfo';

import styles from './CategoryCommon.module.scss';

export interface CategoryCommonProps {
  category: Category;
  isMinimized: boolean;
}

const CategoryCommon: React.FC<CategoryCommonProps> = ({ category, isMinimized }) => {
  const intl = useIntl();
  const dispatch = useWebAppDispatch();
  const { isUserB2B } = useUserCapabilities();

  const { expirationDate, smsSupported, number, planStatus } = category.virtualPhoneNumber;
  const isMessagingEnabled = smsSupported && category.messageEnabled;
  const isLandlineNumber = !canCategorySendSMS(category);

  const onSwitchHandlerCategoryStatus: SwitchToggle = (isSwitchedOn) => {
    dispatch(
      switchCategoryStatusAndUpdate({
        categoryId: category.id,
        switchTo: isSwitchedOn,
      }),
    );
  };

  const onSwitchHandlerCategoryStatusForCalls: SwitchToggle = (isSwitchedOn) => {
    dispatch(
      switchCategoryStatusAndUpdateForCalls({
        categoryId: category.id,
        switchTo: isSwitchedOn,
      }),
    );
  };

  const onSwitchHandlerCategoryStatusForMessages: SwitchToggle = (isSwitchedOn) => {
    dispatch(
      switchCategoryStatusAndUpdateForMessages({
        categoryId: category.id,
        switchTo: isSwitchedOn,
      }),
    );
  };

  const onSwitchHandlerCategoryStatusForVoicemails: SwitchToggle = (isSwitchedOn) => {
    dispatch(
      switchCategoryStatusAndUpdateForVoicemails({
        categoryId: category.id,
        switchTo: isSwitchedOn,
      }),
    );
  };

  const onSwitchHandlerCategoryStealthMode: SwitchToggle = () => {
    dispatch(stealthOpenModal(category.id));
  };

  const onSwitchHandlerCategoryVisibility: SwitchToggle = () => {
    dispatch(
      switchCategoryVisibility({
        categoryId: category.id,
        switchTo: false,
      }),
    );
  };

  if (isMinimized) {
    return (
      <CategoryMinimized
        categoryName={category.name}
        categoryColor={category.color}
        isActive={category.active}
      />
    );
  }

  return (
    <div
      className={classnames(styles.root, {
        [styles.isPassive]: !category.active,
        [styles.isLoading]: category.isLoading,
      })}
      style={{
        background: category.color,
        boxShadow: `0px 2px 6px ${category.active ? category.color : CategoryColorsStatic.DISABLED}`,
      }}
    >
      <div
        className={styles.wrapper}
        style={{
          background: category.active ? undefined : CategoryColorsStatic.DISABLED,
        }}
      >
        <div className={styles.head}>
          <CategoryCommonName
            categoryId={category.id}
            categoryName={category.name}
            isEditable
          />
          <CategoryCommonNumber number={number} />
          <SwitchSimple
            isSwitchedOn={category.active}
            onSwitch={onSwitchHandlerCategoryStatus}
            className={styles.switch}
            propTestId={testIds.categorySwitchRoot}
          />
        </div>

        {planStatus === CategoryPlanStatusTypes.SUBSCRIPTION_CANCELLED && expirationDate ? (
          <CategorySubscriptionEndInfo expirationDate={expirationDate} />
        ) : null}

        <CategoryCommonInfoList
          callEnabled={category.callEnabled}
          messageEnabled={category.messageEnabled}
          voicemailEnabled={category.voicemailEnabled}
          isPrivate={category.isPrivate}
          isLandlineNumber={isLandlineNumber}
        />
      </div>

      <CategoryCommonOptions>
        <CategoryCommonSwitch
          text={intl.formatMessage({ id: 'Categories.calls' })}
          isSwitchedOn={category.callEnabled}
          onSwitch={onSwitchHandlerCategoryStatusForCalls}
          hasBorderTop={false}
          propTestId={testIds.categorySwitchCalls}
        />
        <CategoryCommonSwitch
          text={intl.formatMessage({ id: 'Categories.messages' })}
          isSwitchedOn={isMessagingEnabled}
          onSwitch={onSwitchHandlerCategoryStatusForMessages}
          propTestId={testIds.categorySwitchMessages}
          isDisabled={isLandlineNumber}
        />
        <CategoryCommonSwitch
          text={intl.formatMessage({ id: 'Categories.voicemails' })}
          isSwitchedOn={category.voicemailEnabled}
          onSwitch={onSwitchHandlerCategoryStatusForVoicemails}
          propTestId={testIds.categorySwitchVoicemails}
        />
        {!isUserB2B && (
          <>
            <CategoryCommonSwitch
              text={intl.formatMessage({ id: 'Categories.stealth_mode' })}
              isSwitchedOn={category.isPrivate}
              onSwitch={onSwitchHandlerCategoryStealthMode}
              propTestId={testIds.categorySwitchPrivate}
            />
            {category.isPrivate && (
              <CategoryCommonSwitch
                text={intl.formatMessage({ id: 'Categories.hide_number' })}
                isSwitchedOn={!category.isVisible}
                onSwitch={onSwitchHandlerCategoryVisibility}
                hasBorderTop={false}
                propTestId={testIds.categorySwitchHide}
              />
            )}
          </>
        )}
        <CategoryCommonColors
          categoryId={category.id}
          categoryColor={category.color}
        />
        {!isUserB2B && <CategoryCommonButtonNumberSettings />}
      </CategoryCommonOptions>
    </div>
  );
};

export default CategoryCommon;
