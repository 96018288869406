import React from 'react';

import { Category } from 'types';

import ModalsDialpadMultiCategoriesCallButton from '../ModalsDialpadMultiCategoriesCallButton';
import ModalsDialpadSimpleCallButton from '../ModalsDialpadSimpleCallButton';

interface ModalsDialpadCallButtonWrapperProps {
  categories: Array<Category>;
  currentCategory: Category | undefined;
  isCallLoading: boolean;
  onChangeCategory: (value: Category) => void;
  onClick: () => void;
}

const ModalsDialpadCallButtonWrapper: React.FC<ModalsDialpadCallButtonWrapperProps> = ({
  categories,
  currentCategory,
  isCallLoading,
  onChangeCategory,
  onClick,
}) => (
  <>
    {isCallLoading || categories.length < 2 ? (
      <ModalsDialpadSimpleCallButton
        isLoading={isCallLoading}
        onClick={onClick}
      />
    ) : (
      <ModalsDialpadMultiCategoriesCallButton
        categories={categories}
        currentCategory={currentCategory}
        onChangeCategory={onChangeCategory}
        onClick={onClick}
      />
    )}
  </>
);

export default ModalsDialpadCallButtonWrapper;
