import classNames from 'classnames';
import { CountryCode } from 'libphonenumber-js';
import React from 'react';

import { StyleableComponent, TestableComponent } from 'types';

import { testGetTestingAttributes } from '@testing';
import { BorderWrapperCore } from 'components/BorderWrapper';
import { FlagRound } from 'components/Flag';

import styles from './ButtonFlag.module.scss';

interface ButtonFlagProps extends StyleableComponent, TestableComponent {
  currentCountry?: CountryCode;
  isDisabled?: boolean;
  hasError?: boolean;
  onClick?: () => void;
}

const ButtonFlag: React.FC<ButtonFlagProps> = ({
  currentCountry = 'FR',
  isDisabled = false,
  hasError = false,
  className = '',
  propTestId = '',
  onClick,
}) => (
  <button
    className={classNames(styles.root, className, {
      [styles.hasError]: hasError,
    })}
    type="button"
    onClick={onClick}
    disabled={isDisabled}
    {...testGetTestingAttributes(propTestId)}
  >
    <BorderWrapperCore className={styles.borderWrapper}>
      <FlagRound country={currentCountry} />
    </BorderWrapperCore>
  </button>
);

export default ButtonFlag;
