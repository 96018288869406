import React from 'react';

import { StyleableComponent } from 'types';

const MultiDeviceSupport: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3238_10119)">
      <rect
        width="50"
        height="50"
        fill="#15C576"
      />
      <path
        d="M0 8.87784C0 3.97475 3.97474 0 8.87784 0L41.1222 0C46.0253 0 50 3.97474 50 8.87784V41.1222C50 46.0253 46.0253 50 41.1222 50H8.87784C3.97475 50 0 46.0253 0 41.1222L0 8.87784Z"
        fill="#15C576"
      />
      <g clipPath="url(#clip1_3238_10119)">
        <path
          d="M33.2031 17.9688H23.8281C23.5173 17.9688 23.2193 18.0922 22.9995 18.312C22.7797 18.5318 22.6563 18.8298 22.6563 19.1406V34.375H15.625C15.3142 34.375 15.0161 34.2515 14.7964 34.0318C14.5766 33.812 14.4531 33.5139 14.4531 33.2031V14.4531C14.4531 14.1423 14.5766 13.8443 14.7964 13.6245C15.0161 13.4047 15.3142 13.2812 15.625 13.2812H32.0313C32.3421 13.2812 32.6401 13.4047 32.8599 13.6245C33.0797 13.8443 33.2031 14.1423 33.2031 14.4531V17.9688ZM26.1719 20.3125H35.5469C35.8577 20.3125 36.1558 20.436 36.3755 20.6557C36.5953 20.8755 36.7188 21.1736 36.7188 21.4844V35.5469C36.7188 35.8577 36.5953 36.1558 36.3755 36.3755C36.1558 36.5953 35.8577 36.7188 35.5469 36.7188H26.1719C25.8611 36.7188 25.563 36.5953 25.3432 36.3755C25.1235 36.1558 25 35.8577 25 35.5469V21.4844C25 21.1736 25.1235 20.8755 25.3432 20.6557C25.563 20.436 25.8611 20.3125 26.1719 20.3125Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3238_10119">
        <rect
          width="50"
          height="50"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3238_10119">
        <rect
          width="28.125"
          height="28.125"
          fill="white"
          transform="translate(10.9375 10.9375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MultiDeviceSupport;
