import { UserOfferOrCategory } from 'types';

import {
  selectCategoryStatisticsByCategoryId,
  selectPlansAndOffersAvailablePlans,
  selectPlansAndOffersAvailableOffers,
  useWebAppSelector,
} from '@redux';
import { PlansAndOffersPlan } from '@redux/types';

import { isProductPremiumOffer } from '../helpers';

type UseProductPlanDataReturn = {
  availablePlans?: PlansAndOffersPlan[];
  subscriptionId?: string;
  planDuration?: number;
  nextPlanDuration?: number;
};

export const useProductPlanData = (product: UserOfferOrCategory): UseProductPlanDataReturn => {
  const premiumOffers = useWebAppSelector(selectPlansAndOffersAvailableOffers);
  const plans = useWebAppSelector(selectPlansAndOffersAvailablePlans);
  const categoryStatistics = useWebAppSelector(
    selectCategoryStatisticsByCategoryId(isProductPremiumOffer(product) ? '' : product.id),
  );

  if (isProductPremiumOffer(product)) {
    const { subscriptionId, planDuration, nextPlanDuration } = product;

    return {
      availablePlans: premiumOffers.length ? premiumOffers : undefined,
      subscriptionId,
      planDuration,
      nextPlanDuration,
    };
  }

  const { subscriptionId } = product;
  const { planDuration, nextPlanDuration } = categoryStatistics ?? {};

  return {
    availablePlans: plans[product.virtualPhoneNumber.number],
    subscriptionId,
    planDuration,
    nextPlanDuration,
  };
};
