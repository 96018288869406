import React from 'react';

import { StyleableComponent } from 'types';

const Backspace: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M22.0001 3H7.00008C6.31008 3 5.77008 3.35 5.41008 3.88L0.370078 11.45C0.150078 11.79 0.150078 12.22 0.370078 12.56L5.41008 20.12C5.77008 20.64 6.31008 21 7.00008 21H22.0001C23.1001 21 24.0001 20.1 24.0001 19V5C24.0001 3.9 23.1001 3 22.0001 3ZM18.3001 16.3C17.9101 16.69 17.2801 16.69 16.8901 16.3L14.0001 13.41L11.1101 16.3C10.7201 16.69 10.0901 16.69 9.70008 16.3C9.31008 15.91 9.31008 15.28 9.70008 14.89L12.5901 12L9.70008 9.11C9.31008 8.72 9.31008 8.09 9.70008 7.7C10.0901 7.31 10.7201 7.31 11.1101 7.7L14.0001 10.59L16.8901 7.7C17.2801 7.31 17.9101 7.31 18.3001 7.7C18.6901 8.09 18.6901 8.72 18.3001 9.11L15.4101 12L18.3001 14.89C18.6801 15.27 18.6801 15.91 18.3001 16.3Z"
      fill="currentColor"
    />
  </svg>
);

export default Backspace;
