import { Reducer } from '@reduxjs/toolkit';

import { REDUX_ACTION_TYPES, CheckoutReduxState, CheckoutActionTypes } from '../../types';

export const checkoutInitialState: CheckoutReduxState = {
  checkoutPublicKey: {
    isLoading: false,
    isFailure: false,
    data: '',
  },
  redirectConfirmationURL: {
    isLoading: false,
    isFailure: false,
    data: '',
  },
};

export const checkoutReducer: Reducer<CheckoutReduxState, CheckoutActionTypes> = (state, action) => {
  const reducerState: CheckoutReduxState = state || checkoutInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.CHECKOUT_SET_PUBLICKEY_IS_LOADING: {
      const { isLoading } = action;

      return {
        ...reducerState,
        checkoutPublicKey: {
          ...reducerState.checkoutPublicKey,
          isLoading,
        },
      };
    }

    case REDUX_ACTION_TYPES.CHECKOUT_SET_PUBLICKEY_IS_FAILURE: {
      const { isFailure } = action;

      return {
        ...reducerState,
        checkoutPublicKey: {
          ...reducerState.checkoutPublicKey,
          isFailure,
        },
      };
    }

    case REDUX_ACTION_TYPES.CHECKOUT_SET_PUBLICKEY_DATA: {
      const { data } = action;

      return {
        ...reducerState,
        checkoutPublicKey: {
          isLoading: false,
          isFailure: false,
          data,
        },
      };
    }

    case REDUX_ACTION_TYPES.CHECKOUT_SET_REDIRECTCONFIRMATIONURL_IS_LOADING: {
      const { isLoading } = action;

      return {
        ...reducerState,
        redirectConfirmationURL: {
          ...reducerState.redirectConfirmationURL,
          isLoading,
        },
      };
    }

    case REDUX_ACTION_TYPES.CHECKOUT_SET_REDIRECTCONFIRMATIONURL_IS_FAILURE: {
      const { isFailure } = action;

      return {
        ...reducerState,
        redirectConfirmationURL: {
          ...reducerState.redirectConfirmationURL,
          isFailure,
        },
      };
    }

    default:
      return reducerState;
  }
};
