import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { authenticationHasAuth } from 'helpers';

import { selectCategoriesIsLoading, useWebAppSelector } from '@redux';

interface UsePrefilledDialpadModalReturn {
  isModalDialpadOpen: boolean;
  onModalDialpadRequestClose: () => void;
  prefilledPhoneNumber: string;
}

export const usePrefilledDialpadModal = (): UsePrefilledDialpadModalReturn => {
  const [searchParams] = useSearchParams();
  const prefilledPhoneNumber = searchParams.get('dial') || '';
  const isUserAuthenticated = authenticationHasAuth();
  const isCategoriesLoading = useWebAppSelector(selectCategoriesIsLoading);

  const [isModalDialpadOpen, setIsModalDialpadOpen] = useState(false);

  useEffect(() => {
    if (isUserAuthenticated && isCategoriesLoading === false && prefilledPhoneNumber.length > 0) {
      setIsModalDialpadOpen(true);
    }
  }, [isCategoriesLoading, isUserAuthenticated, prefilledPhoneNumber]);

  const onModalDialpadRequestClose = useCallback(() => {
    setIsModalDialpadOpen(false);
  }, []);

  return {
    isModalDialpadOpen,
    onModalDialpadRequestClose,
    prefilledPhoneNumber,
  };
};
