import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, PrivacyReduxState, PrivacyActionTypes } from '../../types';

export const privacyInitialState: PrivacyReduxState = {
  blockedNumbers: [],
  statuses: {
    fetchBlockedNumbers: Status.IDLE,
    blockNumber: Status.IDLE,
    unblockNumber: Status.IDLE,
  },
};

export const privacyReducer: Reducer<PrivacyReduxState, PrivacyActionTypes> = (incomingState, action) => {
  const reducerState: PrivacyReduxState = incomingState ?? privacyInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.PRIVACY_SET_BLOCKED_NUMBERS:
      return {
        ...reducerState,
        blockedNumbers: action.data,
      };

    case REDUX_ACTION_TYPES.PRIVACY_ADD_BLOCKED_NUMBER:
      return {
        ...reducerState,
        blockedNumbers: [...reducerState.blockedNumbers, action.number],
      };

    case REDUX_ACTION_TYPES.PRIVACY_REMOVE_BLOCKED_NUMBER:
      return {
        ...reducerState,
        blockedNumbers: reducerState.blockedNumbers.filter((number) => number !== action.number),
      };

    case REDUX_ACTION_TYPES.PRIVACY_SET_STATUS_FETCH_BLOCKED_NUMBERS:
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchBlockedNumbers: action.status,
        },
      };

    case REDUX_ACTION_TYPES.PRIVACY_SET_STATUS_BLOCK_NUMBER:
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          blockNumber: action.status,
        },
      };

    case REDUX_ACTION_TYPES.PRIVACY_SET_STATUS_UNBLOCK_NUMBER:
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          unblockNumber: action.status,
        },
      };

    default: {
      return reducerState;
    }
  }
};
