import React, { Component } from 'react';
import Modal from 'react-modal';

import ModalUniWrap from 'components/Universal/ModalUni/ModalUniWrap';
import ModalUniHeader from 'components/Universal/ModalUni/ModalUniHeader';
import ModalUniBody from 'components/Universal/ModalUni/ModalUniBody';
import ModalUniFooter from 'components/Universal/ModalUni/ModalUniFooter';

class ModalUni extends Component {
  render() {
    return (
      <Modal appElement={document.getElementById('app')} {...this.props}>
        { this.props.children }
      </Modal>
    );
  }
}

ModalUni.Wrap = ModalUniWrap;
ModalUni.Header = ModalUniHeader;
ModalUni.Body = ModalUniBody;
ModalUni.Footer = ModalUniFooter;

export default ModalUni;
