import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BrowserPermissionCanPlayAudioSource, BrowserPermissionCanPlayAudioStatus } from 'types';

import {
  browserPermissionsSetPlayAudioPermission,
  selectBrowserPermissionCanPlayAudioStatus,
  useWebAppDispatch,
  useWebAppSelector,
} from '@redux';
import Banner from 'components/Banner';

import styles from './InfoBannerNoPermissionToPlayAudio.module.scss';

const InfoBannerNoPermissionToPlayAudio: React.FC = () => {
  const dispatch = useWebAppDispatch();
  const { status: permissionPlayAudioStatus, source } = useWebAppSelector(selectBrowserPermissionCanPlayAudioStatus);

  const onClickHandlerButtonDismiss = () => {
    dispatch(browserPermissionsSetPlayAudioPermission({ status: BrowserPermissionCanPlayAudioStatus.DISMISSED }));
  };

  if (permissionPlayAudioStatus !== BrowserPermissionCanPlayAudioStatus.PROMPT || !source) {
    return null;
  }

  const dismissButton = (
    <button
      className={styles.link}
      onClick={onClickHandlerButtonDismiss}
    >
      <FormattedMessage id="Layouts.InfoBar.PermissionToPlayAudio.dismiss_text" />
    </button>
  );

  const texts: Record<BrowserPermissionCanPlayAudioSource, React.ReactNode> = {
    [BrowserPermissionCanPlayAudioSource.MANUAL_CHECK]: (
      <div>
        <FormattedMessage
          id="Layouts.InfoBar.PermissionToPlayAudio.error_after_manual_check"
          values={{
            dismissText: dismissButton,
          }}
        />
      </div>
    ),
    [BrowserPermissionCanPlayAudioSource.INCOMING_CALL]: (
      <div>
        <FormattedMessage
          id="Layouts.InfoBar.PermissionToPlayAudio.error_after_incoming_call"
          values={{
            dismissText: dismissButton,
          }}
        />
      </div>
    ),
  };

  return <Banner colorScheme="warning">{texts[source]}</Banner>;
};

export default InfoBannerNoPermissionToPlayAudio;
