import classNames from 'classnames';
import React, { forwardRef, useState } from 'react';

import { BorderWrapperCore } from 'components/BorderWrapper';
import { IconButton, IconCommonNames } from 'components/Icon';

import { useInputState } from '../../hooks';
import { InputPasswordProps } from '../../types';
import InputCommonLayout from '../InputCommonLayout';
import InputCore from '../InputCore';

import styles from './InputPassword.module.scss';

const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  (
    {
      onChange,
      placeholder,
      isDisabled,
      value,
      hasToggleVisibility = false,
      error = '',
      className = '',
      label = '',
      hint = '',
      maxLength = undefined,
      propTestId = '',
      autoComplete,
    },
    ref,
  ) => {
    const { hasFocus, isEmpty, hasHover, onBlur, onFocus, onMouseOver, onMouseLeave, onChangeHandler } = useInputState({
      value,
      onChange,
    });
    const hasError = error.length > 0;
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    return (
      <InputCommonLayout
        className={classNames(className)}
        label={label}
        error={error}
        hint={hint}
      >
        <BorderWrapperCore
          className={styles.borderWrapper}
          hasFocus={hasFocus}
          isDisabled={isDisabled}
          isEmpty={isEmpty}
          hasError={hasError}
          hasHover={hasHover}
        >
          <InputCore
            ref={ref}
            value={value}
            onChange={onChangeHandler}
            placeholder={placeholder}
            isDisabled={isDisabled}
            type={isPasswordVisible ? 'text' : 'password'}
            maxLength={maxLength}
            onBlur={onBlur}
            onFocus={onFocus}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            propTestId={propTestId}
            autoComplete={autoComplete}
          />
          {hasToggleVisibility && (
            <IconButton
              className={styles.icon}
              iconName={isPasswordVisible ? IconCommonNames.EYE_OFF : IconCommonNames.EYE}
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            />
          )}
        </BorderWrapperCore>
      </InputCommonLayout>
    );
  },
);

export default InputPassword;
