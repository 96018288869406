import classNames from 'classnames';
import React from 'react';

import { IconCommon, IconCommonSizes } from 'components/Icon';

import { DropdownIconOptionProps } from '../../types';
import DropdownCommonOption from '../DropdownCommonOption';

import styles from './DropdownIconOption.module.scss';

const DropdownIconOption: React.FC<DropdownIconOptionProps> = ({ label, value, className = '', propTestId }) => (
  <DropdownCommonOption
    className={classNames(styles.root, className)}
    propTestId={propTestId}
  >
    <IconCommon
      className={classNames(styles.icon)}
      name={value}
      size={IconCommonSizes.TINY}
    />
    {label}
  </DropdownCommonOption>
);

export default DropdownIconOption;
