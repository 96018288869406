import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { IntegrationCRM, IntegrationCRMCardType } from 'components/IntegrationCRM';
import { SwitchDashboard, SwitchDashboardSize, SwitchToggle } from 'components/Switch';

import { SIPCallsListItemUIDefaultFooterDetailProps } from '../../types';
import SIPCallsListItemUIDefaultFooterDetailContact from '../SIPCallsListItemUIDefaultFooterDetailContact';

import styles from './SIPCallsListItemUIDefaultFooterDetail.module.scss';

const SIPCallsListItemUIDefaultFooterDetail: React.FC<SIPCallsListItemUIDefaultFooterDetailProps> = ({
  idCategoryLocal,
  phoneNumberRemote,
  hasCRMIntegration,
  contact,
}) => {
  const intl = useIntl();
  const [stateIsContactLocal, setStateIsContactLocal] = useState<boolean>(true);
  const { id: contactId = '' } = contact || {};

  const onSwitchHandler: SwitchToggle = (isContactLocal: boolean) => {
    setStateIsContactLocal(!isContactLocal);
  };

  return (
    <div className={styles.root}>
      {hasCRMIntegration ? (
        <div className={styles.switch}>
          <SwitchDashboard
            isSwitchedOn={!stateIsContactLocal}
            onSwitch={onSwitchHandler}
            leftPlaceholder={intl.formatMessage({ id: 'SIP.Calls.UI.Contacts.switch_information' })}
            rightPlaceholder={intl.formatMessage({ id: 'SIP.Calls.UI.Contacts.switch_crm' })}
            size={SwitchDashboardSize.SMALL}
          />
        </div>
      ) : null}

      {stateIsContactLocal ? (
        <SIPCallsListItemUIDefaultFooterDetailContact
          idCategoryLocal={idCategoryLocal}
          phoneNumberRemote={phoneNumberRemote}
          contact={contact}
          className={styles.contact}
        />
      ) : (
        <IntegrationCRM
          contactId={contactId}
          phoneNumbers={[phoneNumberRemote]}
          integrationCrmCardType={IntegrationCRMCardType.CALL_POPUP}
          className={styles.crm}
        />
      )}
    </div>
  );
};

export default SIPCallsListItemUIDefaultFooterDetail;
