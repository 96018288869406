import { createSelector } from '@reduxjs/toolkit';

import {
  getDefaultCreditCard,
  getNonExpiredAndDefaultOrFirstCreditCard,
  getNonExpiredCreditCards,
  getSortedCreditCards,
} from 'helpers';

import { SelectCreditCardsState } from '../../types';

export const selectCreditCardsState: SelectCreditCardsState = (state) => state.creditCards;

export const selectCreditCardsData = createSelector([selectCreditCardsState], (creditCards) => creditCards.data ?? []);

export const selectCreditCardsDataBySorted = createSelector([selectCreditCardsData], (creditCardsData) =>
  getSortedCreditCards(creditCardsData),
);

export const selectCreditCardsDataByNonExpired = createSelector([selectCreditCardsDataBySorted], (creditCardsData) =>
  getNonExpiredCreditCards(creditCardsData),
);

export const selectCreditCardsDataByNonExpiredAndDefaultOrFirst = createSelector(
  [selectCreditCardsDataByNonExpired],
  (creditCardsData) => getNonExpiredAndDefaultOrFirstCreditCard(creditCardsData),
);

export const selectDefaultCreditCard = createSelector([selectCreditCardsDataByNonExpired], (creditCardsData) =>
  getDefaultCreditCard(creditCardsData),
);

export const selectCreditCardsStatus = createSelector(
  [selectCreditCardsState],
  (creditCardsState) => creditCardsState.status,
);
