import React from 'react';

import { StyleableComponent } from 'types';

const LogoFallback: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 3.41C0 1.52671 1.52671 0 3.41 0L26.59 0C28.4733 0 30 1.52671 30 3.41V26.59C30 28.4733 28.4733 30 26.59 30H3.41C1.52671 30 0 28.4733 0 26.59L0 3.41Z"
      fill="#D4D7DB"
    />
    <path
      d="M18.0016 17.3999L15.6016 14.9999V13.0919C16.4116 12.7979 16.9576 11.9579 16.7596 11.0159C16.6096 10.3079 16.0216 9.74394 15.3076 9.62394C14.1796 9.43794 13.2016 10.3019 13.2016 11.3999C13.2016 12.1799 13.7056 12.8399 14.4016 13.0919V14.9999L12.0016 17.3999H10.2016C9.87156 17.3999 9.60156 17.6699 9.60156 17.9999V19.7999C9.60156 20.1299 9.87156 20.3999 10.2016 20.3999H12.0016C12.3316 20.3999 12.6016 20.1299 12.6016 19.7999V18.5699L15.0016 16.0499L17.4016 18.5699V19.7999C17.4016 20.1299 17.6716 20.3999 18.0016 20.3999H19.8016C20.1316 20.3999 20.4016 20.1299 20.4016 19.7999V17.9999C20.4016 17.6699 20.1316 17.3999 19.8016 17.3999H18.0016Z"
      fill="white"
    />
  </svg>
);

export default LogoFallback;
