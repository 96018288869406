import { CountryCode } from 'libphonenumber-js';

export const allowedCountries = [
  // eslint-disable-next-line max-len
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AI',
  'AQ',
  'AG',
  'AR',
  'AM',
  'AW',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BE',
  'BZ',
  'BM',
  'BT',
  'BO',
  'BQ',
  'BA',
  'BW',
  'BV',
  'BR',
  'IO',
  'BN',
  'BG',
  'BI',
  'KH',
  'CA',
  'CV',
  'KY',
  'CF',
  'TD',
  'CL',
  'CN',
  'CX',
  'CC',
  'CO',
  'KM',
  'CG',
  'CD',
  'CK',
  'CR',
  'HR',
  'CU',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DM',
  'DO',
  'EC',
  'EG',
  'SV',
  'GQ',
  'ER',
  'EE',
  'ET',
  'FK',
  'FO',
  'FJ',
  'FI',
  'FR',
  'GF',
  'PF',
  'TF',
  'GA',
  'GM',
  'GE',
  'DE',
  'GI',
  'GR',
  'GL',
  'GD',
  'GP',
  'GU',
  'GT',
  'GG',
  'GN',
  'GW',
  'GY',
  'HT',
  'HM',
  'VA',
  'HN',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IR',
  'IQ',
  'IE',
  'IM',
  'IL',
  'IT',
  'JM',
  'JP',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KI',
  'KP',
  'KR',
  'XK',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LR',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'MT',
  'MH',
  'MQ',
  'MR',
  'MU',
  'YT',
  'MX',
  'FM',
  'MD',
  'MC',
  'MN',
  'ME',
  'MS',
  'MZ',
  'MM',
  'NA',
  'NR',
  'NP',
  'NL',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NU',
  'NF',
  'MP',
  'NO',
  'OM',
  'PK',
  'PW',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PN',
  'PL',
  'PT',
  'QA',
  'RE',
  'RO',
  'RU',
  'RW',
  'BL',
  'SH',
  'KN',
  'LC',
  'MF',
  'PM',
  'VC',
  'WS',
  'SM',
  'ST',
  'SA',
  'SN',
  'RS',
  'SC',
  'SL',
  'SG',
  'SX',
  'SK',
  'SI',
  'SB',
  'SO',
  'ZA',
  'GS',
  'SS',
  'ES',
  'LK',
  'SD',
  'SR',
  'SJ',
  'SZ',
  'SE',
  'CH',
  'SY',
  'TW',
  'TJ',
  'TZ',
  'TH',
  'TL',
  'TK',
  'TO',
  'TT',
  'TN',
  'TR',
  'TM',
  'TC',
  'TV',
  'UG',
  'UA',
  'AE',
  'GB',
  'US',
  'UM',
  'UY',
  'UZ',
  'VU',
  'VE',
  'VN',
  'VG',
  'VI',
  'WF',
  'EH',
  'YE',
  'ZM',
  'ZW',
] as CountryCode[];

export const countryTranslations = {
  EN: {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua & Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AC: 'Ascension Island',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BA: 'Bosnia & Herzegovina',
    BW: 'Botswana',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    VG: 'British Virgin Islands',
    BN: 'Brunei',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    IC: 'Canary Islands',
    CV: 'Cape Verde',
    BQ: 'Caribbean Netherlands',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    EA: 'Ceuta & Melilla',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo - Brazzaville',
    CD: 'Congo - Kinshasa',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: 'Côte d’Ivoire',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DG: 'Diego Garcia',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HN: 'Honduras',
    HK: 'Hong Kong SAR China',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    XK: 'Kosovo',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Laos',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macau SAR China',
    MK: 'Macedonia',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar (Burma)',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territories',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn Islands',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'São Tomé & Príncipe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia & South Sandwich Islands',
    KR: 'South Korea',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    BL: 'St. Barthélemy',
    SH: 'St. Helena',
    KN: 'St. Kitts & Nevis',
    LC: 'St. Lucia',
    MF: 'St. Martin',
    PM: 'St. Pierre & Miquelon',
    VC: 'St. Vincent & Grenadines',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard & Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syria',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad & Tobago',
    TA: 'Tristan da Cunha',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks & Caicos Islands',
    TV: 'Tuvalu',
    UM: 'U.S. Outlying Islands',
    VI: 'U.S. Virgin Islands',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VA: 'Vatican City',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis & Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  FR: {
    AF: 'Afghanistan',
    AX: 'Îles Åland',
    AL: 'Albanie',
    DZ: 'Algérie',
    AS: 'Samoa Américaines',
    AD: 'Andorre',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctique',
    AG: 'Antigua-et-Barbuda',
    AR: 'Argentine',
    AM: 'Arménie',
    AW: 'Aruba',
    AC: 'Île de l’Ascension',
    AU: 'Australie',
    AT: 'Autriche',
    AZ: 'Azerbaïdjan',
    BS: 'Bahamas',
    BH: 'Bahreïn',
    BD: 'Bangladesh',
    BB: 'Barbade',
    BY: 'Biélorussie',
    BE: 'Belgique',
    BZ: 'Belize',
    BJ: 'Bénin',
    BM: 'Bermudes',
    BT: 'Bhoutan',
    BO: 'Bolivie',
    BA: 'Bosnie-Herzégovine',
    BW: 'Botswana',
    BR: 'Brésil',
    IO: 'Territoire Britannique de l’océan Indien',
    VG: 'Îles Vierges Britanniques',
    BN: 'Brunéi Darussalam',
    BG: 'Bulgarie',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodge',
    CM: 'Cameroun',
    CA: 'Canada',
    IC: 'Îles Canaries',
    CV: 'Cap-Vert',
    BQ: 'Pays-Bas Caribéens',
    KY: 'Îles Caïmans',
    CF: 'République Centrafricaine',
    EA: 'Ceuta et Melilla',
    TD: 'Tchad',
    CL: 'Chili',
    CN: 'Chine',
    CX: 'Île Christmas',
    CC: 'Îles Cocos',
    CO: 'Colombie',
    KM: 'Comores',
    CG: 'Congo-Brazzaville',
    CD: 'Congo-Kinshasa',
    CK: 'Îles Cook',
    CR: 'Costa Rica',
    CI: 'Côte d’Ivoire',
    HR: 'Croatie',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Chypre',
    CZ: 'République Tchèque',
    DK: 'Danemark',
    DG: 'Diego Garcia',
    DJ: 'Djibouti',
    DM: 'Dominique',
    DO: 'République Dominicaine',
    EC: 'Équateur',
    EG: 'Égypte',
    SV: 'El Salvador',
    GQ: 'Guinée Équatoriale',
    ER: 'Érythrée',
    EE: 'Estonie',
    ET: 'Éthiopie',
    FK: 'Îles Malouines',
    FO: 'Îles Féroé',
    FJ: 'Fidji',
    FI: 'Finlande',
    FR: 'France',
    GF: 'Guyane Française',
    PF: 'Polynésie Française',
    TF: 'Terres Australes Françaises',
    GA: 'Gabon',
    GM: 'Gambie',
    GE: 'Géorgie',
    DE: 'Allemagne',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Grèce',
    GL: 'Groenland',
    GD: 'Grenade',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernesey',
    GN: 'Guinée',
    GW: 'Guinée-Bissau',
    GY: 'Guyana',
    HT: 'Haïti',
    HN: 'Honduras',
    HK: 'R.A.S. Chinoise de Hong Kong',
    HU: 'Hongrie',
    IS: 'Islande',
    IN: 'Inde',
    ID: 'Indonésie',
    IR: 'Iran',
    IQ: 'Irak',
    IE: 'Irlande',
    IM: 'Île de Man',
    IL: 'Israël',
    IT: 'Italie',
    JM: 'Jamaïque',
    JP: 'Japon',
    JE: 'Jersey',
    JO: 'Jordanie',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    XK: 'Kosovo',
    KW: 'Koweït',
    KG: 'Kirghizistan',
    LA: 'Laos',
    LV: 'Lettonie',
    LB: 'Liban',
    LS: 'Lesotho',
    LR: 'Libéria',
    LY: 'Libye',
    LI: 'Liechtenstein',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    MO: 'R.A.S. Chinoise de Macao',
    MK: 'Macédoine',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaisie',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malte',
    MH: 'Îles Marshall',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    MU: 'Maurice',
    YT: 'Mayotte',
    MX: 'Mexique',
    FM: 'États fédérés de Micronésie',
    MD: 'Moldavie',
    MC: 'Monaco',
    MN: 'Mongolie',
    ME: 'Monténégro',
    MS: 'Montserrat',
    MA: 'Maroc',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibie',
    NR: 'Nauru',
    NP: 'Népal',
    NL: 'Pays-Bas',
    NC: 'Nouvelle-Calédonie',
    NZ: 'Nouvelle-Zélande',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigéria',
    NU: 'Niue',
    NF: 'Île Norfolk',
    KP: 'Corée du Nord',
    MP: 'Îles Mariannes du Nord',
    NO: 'Norvège',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palaos',
    PS: 'Territoires Palestiniens',
    PA: 'Panama',
    PG: 'Papouasie-Nouvelle-Guinée',
    PY: 'Paraguay',
    PE: 'Pérou',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Pologne',
    PT: 'Portugal',
    PR: 'Porto Rico',
    QA: 'Qatar',
    RE: 'La Réunion',
    RO: 'Roumanie',
    RU: 'Russie',
    RW: 'Rwanda',
    WS: 'Samoa',
    SM: 'Saint-Marin',
    ST: 'Sao Tomé-et-Principe',
    SA: 'Arabie Saoudite',
    SN: 'Sénégal',
    RS: 'Serbie',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapour',
    SX: 'Saint-Martin (partie Néerlandaise)',
    SK: 'Slovaquie',
    SI: 'Slovénie',
    SB: 'Îles Salomon',
    SO: 'Somalie',
    ZA: 'Afrique du Sud',
    GS: 'Îles Géorgie du Sud et Sandwich du Sud',
    KR: 'Corée du Sud',
    SS: 'Soudan du Sud',
    ES: 'Espagne',
    LK: 'Sri Lanka',
    BL: 'Saint-Barthélemy',
    SH: 'Sainte-Hélène',
    KN: 'Saint-Christophe-et-Niévès',
    LC: 'Sainte-Lucie',
    MF: 'Saint-Martin (partie Française)',
    PM: 'Saint-Pierre-et-Miquelon',
    VC: 'Saint-Vincent-et-les-Grenadines',
    SD: 'Soudan',
    SR: 'Suriname',
    SJ: 'Svalbard et Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Suède',
    CH: 'Suisse',
    SY: 'Syrie',
    TW: 'Taïwan',
    TJ: 'Tadjikistan',
    TZ: 'Tanzanie',
    TH: 'Thaïlande',
    TL: 'Timor Oriental',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinité-et-Tobago',
    TA: 'Tristan da Cunha',
    TN: 'Tunisie',
    TR: 'Turquie',
    TM: 'Turkménistan',
    TC: 'Îles Turques-et-Caïques',
    TV: 'Tuvalu',
    UM: 'Îles mineures éloignées des États-Unis',
    VI: 'Îles Vierges des États-Unis',
    UG: 'Ouganda',
    UA: 'Ukraine',
    AE: 'Émirats Arabes Unis',
    GB: 'Royaume-Uni',
    US: 'États-Unis',
    UY: 'Uruguay',
    UZ: 'Ouzbékistan',
    VU: 'Vanuatu',
    VA: 'État de la Cité du Vatican',
    VE: 'Venezuela',
    VN: 'Vietnam',
    WF: 'Wallis-et-Futuna',
    EH: 'Sahara Occidental',
    YE: 'Yémen',
    ZM: 'Zambie',
    ZW: 'Zimbabwe',
  },
} as const;
