import React from 'react';

import { StyleableComponent } from 'types';

import {
  CallLogDelete,
  ContactDelete,
  PhoneLoupe,
  Logout,
  ConversationDelete,
  Credits,
  CreditCardLocation,
  PremiumFeature,
  NumberRenewal,
  SubscriptionRenewal,
  Unsubscription,
  Devices,
  AccountDelete,
  Success,
  PremiumUnlocked,
  BlockingEmpty,
  RecordingDelete,
} from '../../assets';
import { IllustrationCommonNames } from '../../enums';

export interface IllustrationCommonProps extends StyleableComponent {
  name: IllustrationCommonNames;
}

const IllustrationCommon: React.FC<IllustrationCommonProps> = ({ name, className }) => {
  const props: StyleableComponent = {
    className,
  };

  switch (name) {
    case IllustrationCommonNames.LOGOUT:
      return <Logout {...props} />;
    case IllustrationCommonNames.CALL_LOG_DELETE:
      return <CallLogDelete {...props} />;
    case IllustrationCommonNames.CONTACT_DELETE:
      return <ContactDelete {...props} />;
    case IllustrationCommonNames.PHONE_LOUPE:
      return <PhoneLoupe {...props} />;
    case IllustrationCommonNames.CONVERSATION_DELETE:
      return <ConversationDelete {...props} />;
    case IllustrationCommonNames.CREDITS:
      return <Credits {...props} />;
    case IllustrationCommonNames.CREDIT_CARD_LOCATION:
      return <CreditCardLocation {...props} />;
    case IllustrationCommonNames.PREMIUM_FEATURE:
      return <PremiumFeature {...props} />;
    case IllustrationCommonNames.NUMBER_RENEWAL:
      return <NumberRenewal {...props} />;
    case IllustrationCommonNames.SUBSCRIPTION_RENEWAL:
      return <SubscriptionRenewal {...props} />;
    case IllustrationCommonNames.RECORDING_DELETE:
      return <RecordingDelete {...props} />;
    case IllustrationCommonNames.UNSUBSCRIBE:
      return <Unsubscription {...props} />;
    case IllustrationCommonNames.DEVICES:
      return <Devices {...props} />;
    case IllustrationCommonNames.ACCOUNT_DELETE:
      return <AccountDelete {...props} />;
    case IllustrationCommonNames.SUCCESS:
      return <Success {...props} />;
    case IllustrationCommonNames.PREMIUM_UNLOCKED:
      return <PremiumUnlocked {...props} />;
    case IllustrationCommonNames.BLOCKING_EMPTY:
      return <BlockingEmpty {...props} />;
    default:
      return null;
  }
};
export default IllustrationCommon;
