export const checkForMediaPermissions = async (constraints: MediaStreamConstraints) => {
  const mediaStream = await window.navigator.mediaDevices.getUserMedia(constraints);
  if (!mediaStream) {
    throw new Error('Dev Error: User Media Permission Denied!');
  }

  const mediaTracks = mediaStream.getTracks();
  if (mediaTracks.length > 0) {
    mediaTracks.forEach((mediaTrack: MediaStreamTrack) => {
      mediaTrack.stop();
    });
  }

  return true;
};
