import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { InfoTopBarLayoutProps } from '../../types';

import styles from './InfoTopBarLayout.module.scss';

const InfoTopBarLayout: React.FC<InfoTopBarLayoutProps & PropsWithChildren> = ({
  renderAvatar,
  renderContent,
  renderActions,
  children,
  className = '',
}) => (
  <div className={classNames(styles.root, className)}>
    <div className={classNames(styles.leftCol)}>{renderAvatar !== undefined && renderAvatar()}</div>
    <div className={classNames(styles.middleCol)}>{renderContent !== undefined && renderContent()}</div>
    <div className={classNames(styles.rightCol)}>{renderActions !== undefined && renderActions()}</div>
    {children}
  </div>
);

export default InfoTopBarLayout;
