import classNames from 'classnames';
import React from 'react';

import styles from './LayoutTwoCols.module.scss';

interface LayoutTwoColsProps {
  render: (index: number, className: string) => React.ReactNode;
}

const LayoutTwoCols: React.FC<LayoutTwoColsProps> = (props) => (
  <div className={classNames(styles.root)}>
    {[styles.leftColumn, styles.rightColumn].map((className, index) => props.render(index, className))}
  </div>
);

export default LayoutTwoCols;
