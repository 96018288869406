import React from 'react';

import { Category } from 'types';

import { getCategoryNameFirstLetter } from 'helpers/category';
import { CategoryColorsStatic } from 'helpers/category/colors';
import { hexToRgb } from 'helpers/parsing';

import styles from './CategoryMinimized.module.scss';

export interface CategoryMinimizedProps {
  categoryName: Category['name'];
  categoryColor: Category['color'];
  isActive: boolean;
}

const CategoryMinimized: React.FC<CategoryMinimizedProps> = ({ categoryName, categoryColor, isActive }) => (
  <div
    className={styles.root}
    style={{
      background: isActive ? categoryColor : CategoryColorsStatic.DISABLED,
      boxShadow: `0px 2px 6px rgba(${hexToRgb(isActive ? categoryColor : CategoryColorsStatic.DISABLED)}, 0.5)`,
    }}
  >
    <span className={styles.name}>{getCategoryNameFirstLetter(categoryName)}</span>
  </div>
);

export default CategoryMinimized;
