import { useCallback, useEffect } from 'react';

import { NotificationsBrowserPermission } from 'types';

import {
  useWebAppDispatch,
  useWebAppSelector,
  notificationsBrowserSetPermission,
  selectNotificationsBrowserPermission,
} from '@redux';

import { isNotificationsBrowserSupported } from '../helpers';

const useNotificationsBrowserPermission = (): NotificationsBrowserPermission => {
  const dispatch = useWebAppDispatch();
  const permissionStore = useWebAppSelector(selectNotificationsBrowserPermission);

  const requestPermissionHandler = useCallback(async () => {
    if (isNotificationsBrowserSupported()) {
      const permissionWindow = await window.Notification.requestPermission();
      dispatch(notificationsBrowserSetPermission(permissionWindow));
    }
  }, [dispatch]);

  useEffect(() => {
    requestPermissionHandler();
  }, [requestPermissionHandler]);

  return permissionStore;
};

export default useNotificationsBrowserPermission;
