import { UserTypes } from 'types';

import { selectUserLanguage, useWebAppSelector } from '@redux';
import { useUserCapabilities } from 'hooks';

import { SupportLinks } from '../../types';

const supportLinks: SupportLinks = {
  EN: {
    B2C: 'https://support.onoff.app/hc/en-us',
    B2B: 'https://support.onoffbusiness.com/hc/en-gb',
  },
  FR: {
    B2C: 'https://support.onoff.app/hc/fr',
    B2B: 'https://support.onoffbusiness.com/hc/fr',
  },
};

const useSupportLink = (): string => {
  const { isUserB2B } = useUserCapabilities();
  const userType = isUserB2B ? UserTypes.B2B : UserTypes.B2C;
  const currentUserLanguage = useWebAppSelector(selectUserLanguage);

  return supportLinks[currentUserLanguage][userType];
};

export default useSupportLink;
