import React from 'react';
import { useIntl } from 'react-intl';

import { testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { useSIPCallMethodsOngoing } from '../../../../hooks/useSIPCallMethods';
import { SIPCallsListItemButtonFeaturesTerminateProps } from '../../types';
import SIPCallsListItemButtonTooltip from '../SIPCallsListItemButtonTooltip';

import styles from './SIPCallsListItemButtonFeaturesTerminate.module.scss';

const SIPCallsListItemButtonFeaturesTerminate: React.FC<SIPCallsListItemButtonFeaturesTerminateProps> = ({
  isCallOngoing,
  idWebRTC,
}) => {
  const intl = useIntl();
  const { sipCallTerminate } = useSIPCallMethodsOngoing();

  const isFeatureNotAvailable = !isCallOngoing;

  if (isFeatureNotAvailable) {
    return null;
  }

  const onClickHandlerButtonTerminate = (): void => {
    sipCallTerminate({
      idWebRTC,
    });
  };

  return (
    <SIPCallsListItemButtonTooltip
      content={intl.formatMessage({ id: 'SIP.Calls.UI.ButtonTooltips.end_call' })}
      placement="bottom-end"
    >
      <IconButton
        className={styles.root}
        onClick={onClickHandlerButtonTerminate}
        iconName={IconCommonNames.CALL_END}
        iconSize={IconCommonSizes.TINY}
        propTestId={testIds.sipCallsListItemButtonFeatureTerminateRoot}
      />
    </SIPCallsListItemButtonTooltip>
  );
};

export default SIPCallsListItemButtonFeaturesTerminate;
