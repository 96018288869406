import { createSelector } from '@reduxjs/toolkit';

import { CallLog, Category, Contact } from 'types';

import {
  findCallLogById,
  getMostRecentContactListByPhoneNumberList,
  isCallLogFilteredByMissedCalls,
  isPhoneNumberAndOrContactIncludesSearchTerm,
} from 'helpers';

import {
  CallLogsReduxState,
  SelectCallLogs,
  SelectCallLogsActiveCallLog,
  SelectCallLogsActiveId,
  SelectCallLogsByCategoryId,
  SelectCallLogsDeleteCallLogStatus,
  SelectCallLogsMostRecentContact,
  SelectCallLogsSearchString,
  SelectCallLogsState,
  SelectCallLogsStatuses,
  SelectCallLogsUnseenCount,
  SelectCallLogsVisible,
  WebAppState,
} from '../../types';
import { selectContactsPhoneNumberContactMap, selectSimpleContacts } from '../contacts';

export const selectCallLogsState: SelectCallLogsState = (state: WebAppState) => state.callLogs;

export const selectCallLogsSearchString: SelectCallLogsSearchString = createSelector(
  [selectCallLogsState],
  (callLogsState) => callLogsState.searchString,
);

export const selectCallLogsUnseenCount: SelectCallLogsUnseenCount = createSelector(
  [selectCallLogsState],
  (callLogs: CallLogsReduxState) => callLogs.countUnseenCalls,
);

export const selectCallLogs: SelectCallLogs = createSelector(
  [selectCallLogsState],
  (callLogs: CallLogsReduxState) => callLogs.data,
);

export const selectCallLogsVisible: SelectCallLogsVisible = (isMissedCalls: boolean) =>
  createSelector(
    [selectCallLogs, selectCallLogsSearchString, selectContactsPhoneNumberContactMap],
    (callLogs, searchTerm, phoneNumberContactsMap) =>
      callLogs.filter(
        (callLog) =>
          isPhoneNumberAndOrContactIncludesSearchTerm({
            contact: phoneNumberContactsMap[callLog.secondPartyPhone],
            phoneNumber: callLog.secondPartyPhone,
            searchTerm,
          }) && isCallLogFilteredByMissedCalls({ callLog, isMissedCalls }),
      ),
  );

export const selectCallLogsActiveCallLogId: SelectCallLogsActiveId = createSelector(
  [selectCallLogsState],
  (callLogsReduxState) => callLogsReduxState.activeCallLogId,
);

export const selectCallLogsActiveCallLog: SelectCallLogsActiveCallLog = createSelector(
  [selectCallLogs, selectCallLogsActiveCallLogId],
  (callLogs, activeCallLogId) => findCallLogById(callLogs, activeCallLogId || ''),
);

export const selectCallLogsStatuses: SelectCallLogsStatuses = createSelector(
  [selectCallLogsState],
  (callLogsState) => callLogsState.statuses,
);

export const selectCallLogsFetchCallLogsStatus: SelectCallLogsDeleteCallLogStatus = createSelector(
  [selectCallLogsStatuses],
  (callLogsStatusState) => callLogsStatusState.fetchCallLogs,
);

export const selectCallLogsDeleteCallLogStatus: SelectCallLogsDeleteCallLogStatus = createSelector(
  [selectCallLogsStatuses],
  (callLogsStatusState) => callLogsStatusState.deleteCallLog,
);

export const selectCallLogsMostRecentContact: SelectCallLogsMostRecentContact = createSelector(
  [selectCallLogs, selectSimpleContacts],
  (callLogList: CallLog[], contactList: Contact[]) => {
    const phoneNumberList: Array<string> = callLogList.map((callLog) => callLog.secondPartyPhone);
    return getMostRecentContactListByPhoneNumberList(contactList, phoneNumberList);
  },
);

export const selectCallLogsByCategoryId: SelectCallLogsByCategoryId = (categoryId: Category['id']) =>
  createSelector([selectCallLogs], (callLogs): CallLog[] =>
    callLogs.filter((callLog) => callLog.categoryId === categoryId),
  );

export const selectCallLogsAllLoaded = createSelector(
  [selectCallLogsState],
  (callLogsState) => callLogsState.allLoaded,
);
