import { CountryCode } from 'libphonenumber-js';

import { AppStore } from '../server/main';

import { Status } from './status';

export interface Category {
  isLoading: boolean;
  id: string;
  created: string;
  name: string;
  color: string;
  virtualPhoneNumber: VirtualPhoneNumber;
  callEnabled: boolean;
  voicemailEnabled: boolean;
  messageEnabled: boolean;
  active: boolean;
  isVisible: boolean;
  isPrivate: boolean;
  isUsedForProfilePic: boolean;
  profileImageUrl?: string;
  categoryImages: CategoryImage[];
  privatePassword?: string;
  subscriptionId?: string;
  source?: AppStore;
}

export interface CategoryImage {
  imageIndex: number;
  imageUrl: string;
  usedAsUserSearchProfilePic: boolean;
}

export enum CategoryStateTypes {
  UNVALIDATED = 'UNVALIDATED',
  IN_PROGRESS = 'IN_PROGRESS',
  NORMAL = 'NORMAL',
  VALIDATED = 'VALIDATED',
  SIM = 'SIM',
  TRIAL = 'TRIAL',
}

export enum CategoryPlanStatusTypes {
  SUBSCRIPTION_ACTIVE = 'SUBSCRIPTION_ACTIVE',
  SUBSCRIPTION_CANCELLED = 'SUBSCRIPTION_CANCELLED',
  PURCHASE_ACTIVE = 'PURCHASE_ACTIVE',
  EXPIRED = 'EXPIRED',
}

export interface VirtualPhoneNumber {
  number: string; // normalized number
  expirationDate?: string;
  countryIsoCode: CountryCode;
  state: CategoryStateTypes;
  planStatus: CategoryPlanStatusTypes;
  voiceSupported: boolean;
  smsSupported: boolean;
  mmsSupported: boolean;
  cancellationAllowed: boolean;
}

export interface CategoriesStatuses {
  unsubscribeNumber: Status;
  fetchStatistics: Status;
}
