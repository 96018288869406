import { CountryCode, formatNumber, parseNumber } from 'libphonenumber-js';

type FormatPhoneNumberProps = {
  phoneNumber: string;
  countryCode?: CountryCode;
  isNational?: boolean;
};

export const formatPhoneNumber = ({ phoneNumber, countryCode, isNational }: FormatPhoneNumberProps) => {
  let formattedValue = phoneNumber;
  const parsedNumber = parseNumber(phoneNumber, {
    defaultCountry: countryCode,
  });

  if ('phone' in parsedNumber) {
    formattedValue = formatNumber(parsedNumber, isNational ? 'NATIONAL' : 'INTERNATIONAL');
  }

  return formattedValue;
};
