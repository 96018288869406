import { CallQualitySurveyData, GAEventKey, Status } from 'types';
import { CallQualitySurveySubmitRequest } from 'types/server/main';

import { getErrorDataFromUnknownError, handleApplicationError } from 'helpers';

import { API_ERRORS } from '@constants';

import packageJson from '../../../../package.json';
import {
  selectCallQualitySurveyStatuses,
  selectIsCurrentBrowserTabActive,
  selectSIPDataCallsByVisibility,
} from '../../selectors';
import {
  CallQualitySurveyEndSurveyAction,
  CallQualitySurveySetStatusInitializeSurvey,
  CallQualitySurveySetStatusSubmitSurvey,
  CallQualitySurveyStartSurveyAction,
  REDUX_ACTION_TYPES,
  SIPDataCallStatusesWebRTC,
  ThunkResult,
} from '../../types';

export const callQualitySurveyStartSurvey = (
  surveyData: CallQualitySurveyData,
): CallQualitySurveyStartSurveyAction => ({
  type: REDUX_ACTION_TYPES.CALL_QUALITY_SURVEY_START_SURVEY,
  surveyData,
});

export const callQualitySurveyEndSurvey = (): CallQualitySurveyEndSurveyAction => ({
  type: REDUX_ACTION_TYPES.CALL_QUALITY_SURVEY_END_SURVEY,
});

export const callQualitySurveySetStatusInitializeSurvey = (
  status: Status,
): CallQualitySurveySetStatusInitializeSurvey => ({
  type: REDUX_ACTION_TYPES.CALL_QUALITY_SURVEY_SET_STATUS_INITIALIZE_SURVEY,
  status,
});

export const callQualitySurveySetStatusSubmitSurvey = (status: Status): CallQualitySurveySetStatusSubmitSurvey => ({
  type: REDUX_ACTION_TYPES.CALL_QUALITY_SURVEY_SET_STATUS_SUBMIT_SURVEY,
  status,
});

export const callQualitySurveyInitializeSurveyHandler =
  (): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    const isActiveBrowserTab = selectIsCurrentBrowserTabActive(getState());
    const { dataSIPCallsByVisibility } = selectSIPDataCallsByVisibility(getState());
    const hasActiveCalls = dataSIPCallsByVisibility.some(
      ({ statuses: { webRTC } }) => webRTC === SIPDataCallStatusesWebRTC.ACTIVE,
    );

    const { initializeSurvey: initializeCallQualitySurveyStatus } = selectCallQualitySurveyStatuses(getState());

    if (!isActiveBrowserTab || initializeCallQualitySurveyStatus !== Status.IDLE || hasActiveCalls) {
      return;
    }

    try {
      dispatch(callQualitySurveySetStatusInitializeSurvey(Status.LOADING));

      const surveyData = await services.callQualitySurveyService.getSurveyData();
      dispatch(callQualitySurveyStartSurvey(surveyData));
      dispatch(callQualitySurveySetStatusInitializeSurvey(Status.SUCCEEDED));

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.SURVEY_DISPLAY,
      });
    } catch (error) {
      dispatch(callQualitySurveySetStatusInitializeSurvey(Status.FAILED));
      handleApplicationError({ error, showToast: false, reportToSentry: true });
    }
  };

export const callQualitySurveySubmitSurveyHandler =
  (args: CallQualitySurveySubmitRequest): ThunkResult<Promise<void>> =>
  async (dispatch, getState, services): Promise<void> => {
    try {
      dispatch(callQualitySurveySetStatusSubmitSurvey(Status.LOADING));
      await services.callQualitySurveyService.submitSurvey(args);

      const { rating, reasons, otherReason } = args;

      services.analyticsService.pushToDataLayer({
        event: GAEventKey.SURVEY_SENT,
        variables: {
          app_version: packageJson.version,
          rating,
          reasons: reasons?.toString(),
          free_text: otherReason,
          internet_connection_type: 'not-known',
        },
      });

      dispatch(callQualitySurveySetStatusSubmitSurvey(Status.SUCCEEDED));
      dispatch(callQualitySurveyEndSurvey());
    } catch (error) {
      dispatch(callQualitySurveySetStatusSubmitSurvey(Status.FAILED));

      const { errorCode } = getErrorDataFromUnknownError(error);

      if (errorCode === API_ERRORS.CALL_QUALITY_SURVEY.CALL_QUALITY_SURVEY_EXPIRED) {
        services.analyticsService.pushToDataLayer({
          event: GAEventKey.SURVEY_EXPIRED,
        });
      }

      throw error;
    }
  };
