import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ModalUniStyle as s1 } from 'components/UI/styleRoutes';

class ModalUniHeader extends Component {
  static defaultProps = {
    className: '',
    style: {},
  };

  static propTypes = {
    className: PropTypes.string,
    closeHandler: PropTypes.func,
    style: PropTypes.object,
  };

  render() {
    return (
      <div className={`${s1.header} ${this.props.className}`} style={this.props.style}>
        {
          typeof this.props.closeHandler === 'function'
            ? <div className={`material-icons ${s1['close-back']}`} onClick={this.props.closeHandler}>arrow_back</div>
            : null
        }

        {
          typeof this.props.closeHandler === 'function'
            ? <div className={`material-icons ${s1.close}`} onClick={this.props.closeHandler}>close</div>
            : null
        }

        <div className={s1.title}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default ModalUniHeader;
