import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getCallDurationAsFormatted } from 'helpers';

import { IconCommon, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { ModalsCallDetailsDirection, ModalsCallDetailsHeaderProps } from '../../types';

import styles from './ModalsCallDetailsHeader.module.scss';

const ModalsCallDetailsHeader: React.FC<ModalsCallDetailsHeaderProps> = ({
  callDurationStart,
  callDurationEnd,
  callDirection,
  displayContactName,
  displayCompanyName,
  displayPhoneNumber,
  displayPhoneNumberType,
}) => {
  const hasContact = displayContactName.length > 0;
  const name = `${displayContactName} ${displayCompanyName}`.trim();
  const phoneNumber = `${displayPhoneNumber} ${displayPhoneNumberType}`.trim();
  const { callDurationAsFormatted } = getCallDurationAsFormatted({
    callDuration: (callDurationStart - callDurationEnd) / 1000,
  });

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>
        <FormattedMessage id="Modals.CallDetails.title" />
      </h2>

      <div className={styles.content}>
        <div className={styles.contentContact}>
          <IconCommon
            className={styles.contentContactIcon}
            size={IconCommonSizes.DEFAULT}
            name={
              callDirection === ModalsCallDetailsDirection.INCOMING
                ? IconCommonNames.INCOMING_CALL
                : IconCommonNames.OUTGOING_CALL
            }
          />

          <div className={styles.contentContactDisplay}>
            <span className={styles.contentContactDisplayName}>{hasContact ? name : phoneNumber}</span>
            {hasContact ? <span className={styles.contentContactDisplayPhone}>{phoneNumber}</span> : null}
          </div>
        </div>

        <div className={styles.contentDuration}>
          <span className={styles.contentDurationText}>
            <FormattedMessage id="Modals.CallDetails.call_duration" />
          </span>
          <span className={styles.contentDurationData}>{callDurationAsFormatted}</span>
        </div>
      </div>
    </div>
  );
};

export default ModalsCallDetailsHeader;
