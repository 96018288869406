import classNames from 'classnames';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { SIP } from '@constants';
import { SIPDataCallStatusesWebRTC } from '@redux/types';
import { testGetTestingAttributes, testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { useSIPCallMethodsOngoing } from '../../../../hooks/useSIPCallMethods';
import { SIPCallsListItemButtonFeaturesDTMFProps } from '../../types';
import SIPCallsListItemButtonTooltip from '../SIPCallsListItemButtonTooltip';
import SIPCallsListItemDropdownDTMF from '../SIPCallsListItemDropdownDTMF';

import styles from './SIPCallsListItemButtonFeaturesDTMF.module.scss';

const SIPCallsListItemButtonFeaturesDTMF: React.FC<SIPCallsListItemButtonFeaturesDTMFProps> = ({
  isCallOngoing,
  statusWebRTC,
  idWebRTC,
}) => {
  const intl = useIntl();
  const [stateIsDTMFDropdownOpen, setStateIsDTMFDropdownOpen] = useState<boolean>(false);
  const { sipCallSendDTMF } = useSIPCallMethodsOngoing();

  const isFeatureNotAvailable = !SIP.CALL_FEATURES.DTMF_ENABLED || !isCallOngoing;
  if (isFeatureNotAvailable) {
    return null;
  }

  const isCallActive = statusWebRTC === SIPDataCallStatusesWebRTC.ACTIVE;
  const isButtonDTMFDisabled = !isCallActive;

  const onClickHandlerDropdownOpen = (): void => {
    setStateIsDTMFDropdownOpen(true);
  };

  const onClickHandlerDropdownClose = (): void => {
    setStateIsDTMFDropdownOpen(false);
  };

  const onClickHandlerButtonsDTMFKeyboard = (valueDTMF: string): void => {
    sipCallSendDTMF({
      idWebRTC,
      valueWebRTCDTMF: valueDTMF,
    });
  };

  return (
    <div
      className={styles.root}
      {...testGetTestingAttributes(testIds.sipCallsListItemButtonFeatureDTMFRoot)}
    >
      <SIPCallsListItemButtonTooltip
        content={intl.formatMessage({
          id: stateIsDTMFDropdownOpen
            ? 'SIP.Calls.UI.ButtonTooltips.dtmf_close'
            : 'SIP.Calls.UI.ButtonTooltips.dtmf_open',
        })}
      >
        <IconButton
          className={classNames(styles.button, {
            [styles.disabled]: stateIsDTMFDropdownOpen,
          })}
          onClick={onClickHandlerDropdownOpen}
          iconName={IconCommonNames.DIALPAD}
          iconSize={IconCommonSizes.TINY}
          isDisabled={isButtonDTMFDisabled}
          propTestId={testIds.sipCallsListItemButtonFeatureDTMFButton}
        />
      </SIPCallsListItemButtonTooltip>
      <SIPCallsListItemDropdownDTMF
        isOpen={stateIsDTMFDropdownOpen}
        onClickHandlerButtons={onClickHandlerButtonsDTMFKeyboard}
        onCloseHandlerDropdown={onClickHandlerDropdownClose}
      />
    </div>
  );
};

export default SIPCallsListItemButtonFeaturesDTMF;
