import React from 'react';

import { StyleableComponent } from 'types';

const OnOffScheduling: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3238_10119)">
      <rect
        width="50"
        height="50"
        fill="#FF8959"
      />
      <path
        d="M0 8.87784C0 3.97475 3.97474 0 8.87784 0L41.1222 0C46.0253 0 50 3.97474 50 8.87784V41.1222C50 46.0253 46.0253 50 41.1222 50H8.87784C3.97475 50 0 46.0253 0 41.1222L0 8.87784Z"
        fill="#FF8959"
      />
      <g clipPath="url(#clip1_3238_10119)">
        <path
          d="M25 36.7187C18.5277 36.7187 13.2812 31.4723 13.2812 25C13.2812 18.5277 18.5277 13.2812 25 13.2812C31.4723 13.2812 36.7187 18.5277 36.7187 25C36.7187 31.4723 31.4723 36.7187 25 36.7187ZM26.1719 25V19.1406H23.8281V27.3437H30.8594V25H26.1719Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3238_10119">
        <rect
          width="50"
          height="50"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3238_10119">
        <rect
          width="28.125"
          height="28.125"
          fill="white"
          transform="translate(10.9375 10.9375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default OnOffScheduling;
