
import { selectPlansAndOffersPremiumState, useWebAppSelector } from '@redux';

import { PremiumPlanPricesWithComparison } from '../../components/Modals/ModalsPremiumPlan/types';

import { calcPriceWithDiscount, getPriceComparison, hasValidPrices } from './helpers';

export const usePremiumPlansWithComparison = (): PremiumPlanPricesWithComparison[] => {
  const yearlyPeriod = 12;
  const basePeriod = 1;

  const premiumPlans = useWebAppSelector(selectPlansAndOffersPremiumState);

  const yearlyOption = premiumPlans.find((plan) => plan.month === yearlyPeriod);
  const monthlyOption = premiumPlans.find((plan) => plan.month === basePeriod);

  if (hasValidPrices(yearlyOption) && hasValidPrices(monthlyOption)) {
    return [
      {
        ...yearlyOption,
        ...getPriceComparison(yearlyOption, monthlyOption),
      },
      {
        ...monthlyOption,
        ...getPriceComparison(monthlyOption, yearlyOption),
      },
    ];
  }

  if (hasValidPrices(yearlyOption)) {
    return [
      {
        ...yearlyOption,
        hasPriceAdvantage: false,
        priceWithDiscount: calcPriceWithDiscount(yearlyOption.price, yearlyOption.discountPercentage),
      },
    ];
  }

  if (hasValidPrices(monthlyOption)) {
    return [
      {
        ...monthlyOption,
        hasPriceAdvantage: false,
        priceWithDiscount: calcPriceWithDiscount(monthlyOption.price, monthlyOption.discountPercentage),
      },
    ];
  }

  return [];
};
