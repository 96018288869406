import { RemoveThreadsRequest, MarkThreadsAsReadRequest, ThreadsService } from 'types';
import { GetGroupThreadIdRequest, GetThreadIdResponse, RetrieveThreadsRequest, ShortMessage } from 'types/server/main';

import { apiMobile } from 'api';

const threadsService: ThreadsService = {
  fetchThreadId: (creatorCategoryId, receiverPhoneNumberList) =>
    apiMobile.post<GetThreadIdResponse, GetGroupThreadIdRequest>('/v5/get-thread-id', {
      creator: { categoryId: creatorCategoryId },
      receiver: { phoneNumbers: receiverPhoneNumberList },
    }),

  fetchThreads: (offset, limit = 50) =>
    apiMobile.get<ShortMessage[], RetrieveThreadsRequest>('/v4/retrieve-threads', {
      offset,
      limit,
    }),

  markThreadsAsRead: (ids) => apiMobile.post<void, MarkThreadsAsReadRequest>('/v4/mark-as-read-threads', { ids }),

  removeThreads: (ids) => apiMobile.post<void, RemoveThreadsRequest>('/v4/delete-message-threads', { ids }),
};

export default threadsService;
