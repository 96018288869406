import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { testGetTestingAttributes } from '@testing';
import { IconCommon, IconCommonNames } from 'components/Icon';

import { ButtonFormIconTextProps } from '../../types';

import styles from './ButtonFormIconText.module.scss';

const ButtonFormIconText: React.FC<ButtonFormIconTextProps & PropsWithChildren> = ({
  onClick,
  className = '',
  children,
  isUserB2B = false,
  iconName = IconCommonNames.ADD,
  propTestId = '',
}) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(
      styles.root,
      {
        [styles.isB2B]: isUserB2B,
      },
      className,
    )}
    {...testGetTestingAttributes(propTestId)}
  >
    <IconCommon name={iconName} />
    {children}
  </button>
);

export default ButtonFormIconText;
