import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Search, Select } from '@onoff/ui';

import { countryTranslations } from '@constants/countries';

import CountryFlag from './CountryFlag';
import FallbackFlag from './FallbackFlag';

interface CountrySelectorProps {
  isDisabled?: boolean;
  countryCode: CountryCode;
  onChange: (countryCode: CountryCode) => void;
  countries: CountryCode[];
  withCallingCode?: boolean;
  lazyLoadFlags?: boolean;
}

const CountrySelector: React.FC<CountrySelectorProps> = ({
  isDisabled,
  countryCode: selectedCountryCode,
  countries,
  onChange,
  withCallingCode,
  lazyLoadFlags,
}) => {
  const intl = useIntl();

  const [countrySelectorDropdownElem, setCountrySelectorDropdownElem] = useState<HTMLDivElement | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);

  const intersectionObserverSupported = 'IntersectionObserver' in window;

  const filterBySearchString = (country: CountryCode) =>
    countryTranslations[intl.locale][country]?.toLowerCase().includes(searchValue.toLowerCase());

  const countryOptions = countries
    .filter(filterBySearchString)
    .sort()
    .map((countryCode) => ({
      id: countryCode,
      flag:
        lazyLoadFlags && selectedCountryCode !== countryCode && intersectionObserverSupported ? (
          <FallbackFlag data-country={countryCode} />
        ) : (
          <CountryFlag code={countryCode} />
        ),
      label: <span>{countryTranslations[intl.locale][countryCode]}</span>,
    }));

  const loadFlagImagesWhenVisible = useCallback(
    async (entries: IntersectionObserverEntry[], intersectionObserver: IntersectionObserver) => {
      if (countryOptions.length) {
        entries.map(async (entry) => {
          if (entry.intersectionRatio > 0 || entry.isIntersecting) {
            const image = entry.target;
            const hasAltTagSet = image.getAttribute('alt'); // alt tag is set when image is loaded

            if (hasAltTagSet) {
              // Image has been loaded already
              return;
            }

            // data-country is passed to FallbackFlag (this file)
            const country = image.getAttribute('data-country');

            if (country) {
              image.setAttribute('src', `${process.env.PUBLIC_URL}/flags/${country}.svg`);
              image.setAttribute('alt', `Flag of ${country}`);
            }

            // Removing the observer
            intersectionObserver.unobserve(image);
          }
        });
      }
    },
    [countryOptions],
  );

  useEffect(() => {
    if (!lazyLoadFlags || !countrySelectorDropdownElem || !intersectionObserverSupported) {
      return undefined;
    }

    const observer = new IntersectionObserver(loadFlagImagesWhenVisible);

    countrySelectorDropdownElem.querySelectorAll('img').forEach((el) => {
      observer.observe(el);
    });
    return () => observer.disconnect();
  }, [countrySelectorDropdownElem, intersectionObserverSupported, lazyLoadFlags, loadFlagImagesWhenVisible]);

  const onChangeHandler = (id: CountryCode) => {
    onChange(id);
  };

  return (
    <Select
      usePortal
      isDisabled={isDisabled}
      selected={selectedCountryCode}
      onDropdownToggle={(isOpen) => {
        setSearchValue('');

        if (isOpen) {
          setTimeout(() => {
            if (searchRef.current) {
              searchRef.current.focus();
            }
          }, 50);
        }
      }}
      onChange={(id) => onChangeHandler(id as CountryCode)}
      onDropdownElementInit={setCountrySelectorDropdownElem}
      strategy="fixed"
      dropdownElementProps={{
        style: { zIndex: 210, maxHeight: 310 }, // Modal has z-index 200
      }}
    >
      <Search
        ref={searchRef}
        variant="variant-02"
        onChange={(event) => setSearchValue(event.target.value)}
        value={searchValue}
        autoFocus
      />
      {countryOptions.map(({ id, label, flag }) => (
        <Select.Item
          id={id}
          key={id}
          icon={flag}
        >
          {withCallingCode ? `+(${getCountryCallingCode(id)}) ${id}` : label}
        </Select.Item>
      ))}
    </Select>
  );
};

export default React.memo(CountrySelector);
