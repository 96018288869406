import React, { PropsWithChildren } from 'react';

import { Loading } from '@onoff/ui';

import { PageBase } from 'components/PageBase';
import TopPageInfoBanner from 'components/TopPageInfoBanner';

import { LayoutProps } from '../../types';
import Header from '../Header';
import SideMenuSettings from '../SideMenuSettings';

import styles from './LayoutSettings.module.scss';

const LayoutSettings: React.FC<LayoutProps & PropsWithChildren> = ({ children, isAvailableOnMobile }) => (
  <PageBase isAvailableOnMobile={isAvailableOnMobile}>
    <div className={styles.root}>
      <TopPageInfoBanner />
      <Header />
      <div className={styles.page}>
        <SideMenuSettings />
        <div className={styles.wrapper}>
          <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
        </div>
      </div>
    </div>
  </PageBase>
);

export default LayoutSettings;
