export enum SwitchDashboardSize {
  DEFAULT = 'default',
  SMALL = 'small',
}

export enum SwitchSimpleThemes {
  DEFAULT = 'default',
  B2B = 'b2b',
  B2C = 'b2c',
}
