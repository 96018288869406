import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Help as IconHelp } from '@onoff/icons';

import useSupportLink from '../../hooks/useSupportLink';

import styles from './ButtonSupport.module.scss';

export interface ButtonSupportProps {
  isMinimized: boolean;
}

const ButtonSupport: React.FC<ButtonSupportProps> = ({ isMinimized }) => {
  const supportLink = useSupportLink();

  return (
    <a
      href={supportLink}
      className={classnames(styles.root, { [styles.minimized]: isMinimized })}
      target="_blank"
      rel="noreferrer"
    >
      <IconHelp size="small" />
      {!isMinimized && (
        <span>
          <FormattedMessage id={'SideMenuCategories.button_support'} />
        </span>
      )}
    </a>
  );
};

export default ButtonSupport;
