export enum ModalSize {
  /**
   * xs => 360px
   */
  XS = 'xs',
  /**
   * s => 450px
   */
  S = 's',
  /**
   * m => 500px
   */
  M = 'm',
  /**
   * l => 550px
   */
  L = 'l',
  /**
   * xl => 730px
   */
  XL = 'xl',
  /**
   * xxl => 820px
   */
  XXL = 'xxl',
}

export enum ModalKeycode {
  ESCAPE = 'Escape',
}
