import { UserDeviceElement, UserDevicesResponse, StringCollectionRequest } from '../server/main';

export enum MultiDeviceDeviceType {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export type MultiDeviceDeviceFromServer = UserDeviceElement;

export type MultiDeviceDeviceWithRequiredDeviceId = MultiDeviceDeviceFromServer & {
  deviceId: Pick<UserDeviceElement, 'deviceId'>;
};

export type MultiDeviceDevice = MultiDeviceDeviceWithRequiredDeviceId & {
  deviceType: MultiDeviceDeviceType;
  isOwnDevice: boolean;
};

export type MultiDeviceFetchDeviceListResponse = UserDevicesResponse;

export type MultiDeviceLogoutDeviceRequest = StringCollectionRequest;
