import React from 'react';

import { StyleableComponent } from 'types';

const ChevronLeft: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.7105 6.71047C14.3205 6.32047 13.6905 6.32047 13.3005 6.71047L8.71047 11.3005C8.32047 11.6905 8.32047 12.3205 8.71047 12.7105L13.3005 17.3005C13.6905 17.6905 14.3205 17.6905 14.7105 17.3005C15.1005 16.9105 15.1005 16.2805 14.7105 15.8905L10.8305 12.0005L14.7105 8.12047C15.1005 7.73047 15.0905 7.09047 14.7105 6.71047Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronLeft;
