import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, UserGeoInfoReduxState, UserGeoInfoActionTypes } from '../../types';

export const userGeoInfoInitialState: UserGeoInfoReduxState = {
  statuses: {
    fetchUserGeoInfo: Status.IDLE,
  },
  data: {
    ip: '',
    countryCode: '',
    countryName: '',
    regionCode: '',
    regionName: '',
    city: '',
    zipCode: '',
    timeZone: '',
    latitude: 0,
    longitude: 0,
    metroCode: 0,
  },
};

export const userGeoInfoReducer: Reducer<UserGeoInfoReduxState, UserGeoInfoActionTypes> = (state, action) => {
  const reducerState: UserGeoInfoReduxState = state || userGeoInfoInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.USER_GEO_INFO_SET_STATUS_FETCH_USER_GEO_INFO: {
      const { fetchUserGeoInfo } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          fetchUserGeoInfo,
        },
      };
    }

    case REDUX_ACTION_TYPES.USER_GEO_INFO_SET_GEO_INFO_DATA: {
      const { data } = action;

      return {
        ...reducerState,
        data,
      };
    }

    default:
      return reducerState;
  }
};
