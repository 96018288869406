import {
  PlansAndOfferProductPricesResponse,
  FetchAvailablePlansResponse,
  PlansAndOffersProduct,
  PlansAndOffersAvailableUserOfferReturn,
} from 'types';

import { isNumber, isObject, isString } from 'helpers';

import { PlansAndOffersPlan, PlansAndOffersProductIdAndDuration } from '../../../types';

const isPlansAndOffersProduct = (value: unknown): value is PlansAndOffersProduct =>
  isObject(value) && isNumber(value.month) && isString(value.productId);

export const getProductIdsAndDurationsFromUserOffers = (
  userOffers: PlansAndOffersAvailableUserOfferReturn['offers'],
): PlansAndOffersProductIdAndDuration[] => {
  const results: PlansAndOffersProductIdAndDuration[] = [];

  userOffers.forEach((offer) => {
    if (offer.offerProductId && offer.month) {
      results.push({
        productId: offer.offerProductId,
        month: offer.month,
      });
    }
  });

  return results;
};

export const getProductIdsAndDurationsFromAvailablePlans = (
  plans: FetchAvailablePlansResponse['plans'],
): PlansAndOffersProductIdAndDuration[] => {
  if (!plans || plans.length === 0) {
    return [];
  }

  // The Plans are already sorted by the last one being the largest plan by Back-end
  const largestPlan = plans[plans.length - 1];

  if (!largestPlan.productIds) {
    return [];
  }

  return largestPlan.productIds.filter(isPlansAndOffersProduct);
};

// TODO: backend team will do this filtering on their end in future, so we will be able to remove this code
export const filterProductIdsAndDurations = (
  data: PlansAndOffersProductIdAndDuration[],
  excludeDuration?: number,
): PlansAndOffersProductIdAndDuration[] => {
  if (excludeDuration) {
    return data.filter((item) => item.month !== excludeDuration);
  }

  return data;
};

export const getPricesAndDurations = (
  productIdsAndDurations: PlansAndOffersProductIdAndDuration[],
  prices: PlansAndOfferProductPricesResponse['productPrices'],
): PlansAndOffersPlan[] => {
  const result: PlansAndOffersPlan[] = [];

  prices.forEach((priceObject) => {
    const id = priceObject.productId;
    const product = productIdsAndDurations.find(
      (productIdAndDurationObject) => productIdAndDurationObject.productId === id,
    );

    if (product) {
      result.push({
        ...priceObject,
        duration: product.month,
      });
    }
  });

  return result.sort((a, b) => a.duration - b.duration);
};
