import React from 'react';

import { StyleableComponent } from 'types';

const CallNotes: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3238_10119)">
      <rect
        width="50"
        height="50"
        fill="#D17B0F"
      />
      <path
        d="M0 8.87784C0 3.97475 3.97474 0 8.87784 0L41.1222 0C46.0253 0 50 3.97474 50 8.87784V41.1222C50 46.0253 46.0253 50 41.1222 50H8.87784C3.97475 50 0 46.0253 0 41.1222L0 8.87784Z"
        fill="#D17B0F"
      />
      <g clipPath="url(#clip1_3238_10119)">
        <path
          d="M27.3438 13.2812H17.9688C16.6797 13.2812 15.6367 14.3359 15.6367 15.625L15.625 34.375C15.625 35.6641 16.668 36.7188 17.957 36.7188H32.0313C33.3203 36.7188 34.375 35.6641 34.375 34.375V20.3125L27.3438 13.2812ZM29.6875 32.0313H20.3125V29.6875H29.6875V32.0313ZM29.6875 27.3438H20.3125V25H29.6875V27.3438ZM26.1719 21.4844V15.0391L32.6172 21.4844H26.1719Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3238_10119">
        <rect
          width="50"
          height="50"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3238_10119">
        <rect
          width="28.125"
          height="28.125"
          fill="white"
          transform="translate(10.9375 10.9375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CallNotes;
