import { useWebAppDispatch, integrationsStatusChangedHandler } from '@redux';

import {
  UsePubNubMessagesHandlerIntegrationsReturn,
  PubNubPushMessageIntegrationsChangeStatusPayload,
} from '../../types';

export const usePubNubMessagesHandlerIntegrations = (): UsePubNubMessagesHandlerIntegrationsReturn => {
  const dispatch = useWebAppDispatch();

  const pubNubMessageHandlerIntegrationsChangeStatus = ({
    integrationsEnabled,
  }: PubNubPushMessageIntegrationsChangeStatusPayload): void => {
    dispatch(integrationsStatusChangedHandler({ integrationsEnabled }));
  };

  return {
    pubNubMessageHandlerIntegrationsChangeStatus,
  };
};
