import { FormattedMessage } from 'react-intl';

import styles from './KYCRequirement.module.scss';

const KYCRequirement = () => (
  <>
    <h2 className={styles.header}>
      <FormattedMessage id="FeatureBlockSplash.kyc_required_header" />
    </h2>
    <div>
      <FormattedMessage id="FeatureBlockSplash.kyc_required_description" />
    </div>
  </>
);

export default KYCRequirement;
