import { withScope } from '@sentry/browser';
import { captureException } from '@sentry/react';
import axios, { AxiosError, AxiosHeaders, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiErrorValue } from 'types';

import { containsCallRecordings, isB2BProject, startsWithHttpOrHttps } from 'helpers';
import { getErrorDataFromUnknownError } from 'helpers/errorHandling';

import { API_ERRORS, LOCALSTORAGE_KEYS } from '@constants';
import { handleUnauthorized } from 'components/App/helpers';

import packageJson from '../../../package.json';
import { getApiError, parseQueryParams } from '../helpers';
import { ApiBaseConfig, ApiError, ApiHTTPResponseCodes } from '../types';

const baseUrl = process.env.REACT_APP_API_URL || '';

const xUserAgent = `${isB2BProject() ? 'b2b' : 'onoff'}-web/${packageJson.version}`;

class ApiBase {
  private readonly baseURL: string;

  private readonly apiBaseConfig: ApiBaseConfig = {
    useAuthorizationToken: true,
  };

  constructor(baseURL: string, apiBaseConfig?: ApiBaseConfig) {
    this.baseURL = baseURL;

    if (apiBaseConfig) {
      this.apiBaseConfig = { ...this.apiBaseConfig, ...apiBaseConfig };
    }

    axios.interceptors.request.use((config) => {
      if (startsWithHttpOrHttps(config.url) && containsCallRecordings(config.url)) {
        return {
          ...config,
          timeout: 300000,
          headers: {
            ...config.headers,
            ...this.generateAuthHeader(),
          } as AxiosHeaders,
        };
      }

      return config;
    });
  }

  public request = <RES>(config: AxiosRequestConfig): Promise<RES> => this.handleResponse(axios.request(config));

  public get = <RES, REQ = unknown>(url: string, params: REQ): Promise<RES> =>
    this.handleResponse(axios.get(url, this.createConfig({ params })));

  public post = <RES, REQ = unknown>(url: string, data: REQ): Promise<RES> =>
    this.handleResponse(axios.post(url, data, this.createConfig()));

  public put = <RES, REQ = unknown>(url: string, data: REQ): Promise<RES> =>
    this.handleResponse(axios.put(url, data, this.createConfig()));

  public patch = <RES, REQ = unknown>(url: string, data: REQ): Promise<RES> =>
    this.handleResponse(axios.patch(url, data, this.createConfig()));

  public delete = <RES, REQ = unknown>(url: string, params: REQ): Promise<RES> =>
    this.handleResponse(axios.delete(url, this.createConfig({ params })));

  private createConfig = <REQ>(data?: REQ): AxiosRequestConfig => ({
    baseURL: this.baseURL,
    headers: this.makeHeaders(),
    withCredentials: false,
    validateStatus: (status) => status === ApiHTTPResponseCodes.SUCCESS,
    ...(data === undefined ? {} : data),
    paramsSerializer: (params) => parseQueryParams(params),
  });

  // eslint-disable-next-line class-methods-use-this
  private handleResponse = <RES>(req: AxiosPromise) =>
    req
      .then((axiosResponse: AxiosResponse<RES>) => axiosResponse.data)
      .catch((error: AxiosError<ApiError | ApiErrorValue | ApiErrorValue[]>) => {
        const errorResponse = error.response;

        if (axios.isCancel(error)) {
          const axiosError = { code: API_ERRORS.GENERAL.REQUEST_ABORTED } as ApiError;
          return Promise.reject(axiosError);
        }

        if (errorResponse) {
          const responseStatus = errorResponse.status;
          const responseHeaders = errorResponse.headers;
          const seqId = responseHeaders?.['onoff-sequence'];

          if (responseStatus === ApiHTTPResponseCodes.BAD_REQUEST) {
            withScope((scope) => {
              if (seqId) {
                scope.setTag('sequenceNumber', seqId);
              }
              scope.setTag('responseStatus', responseStatus);
              captureException(Error(JSON.stringify(getErrorDataFromUnknownError(errorResponse))));
            });
          }

          if (responseStatus === ApiHTTPResponseCodes.UNAUTHORIZED) {
            handleUnauthorized();
          }
        }

        return Promise.reject(getApiError(error));
      });

  // eslint-disable-next-line class-methods-use-this
  private readonly makeHeaders = (): AxiosRequestConfig['headers'] => ({
    ...this.generateUserAgentHeader(),
    ...this.generateInstanceIdHeader(),
    ...this.generateAuthHeader(),
  });

  private readonly generateAuthHeader = () => {
    // don't store token, because we will have then sync them in each api service
    // and redux will depend on api instances
    const authorizationToken = window.localStorage.getItem(LOCALSTORAGE_KEYS.ACCESS_TOKEN) || '';
    if (authorizationToken.length > 0 && this.apiBaseConfig.useAuthorizationToken) {
      // headers.Authorization = `Basic ${authorizationToken.replace(/"/g, '')}`;
      return {
        Authorization: `Basic ${authorizationToken.replace(/"/g, '')}`,
      };
    }

    return {};
  };

  // eslint-disable-next-line class-methods-use-this
  private readonly generateUserAgentHeader = () => ({
    'X-User-Agent': xUserAgent,
  });

  // eslint-disable-next-line class-methods-use-this
  private readonly generateInstanceIdHeader = () => {
    const multiDeviceToken = window.localStorage.getItem(LOCALSTORAGE_KEYS.MULTI_DEVICE_TOKEN_AKA_MOBILE_TOKEN) || '';
    if (multiDeviceToken.length > 0) {
      return {
        'X-Instance-Id': multiDeviceToken,
      };
    }

    return {};
  };
}

export const getInstance = (endpoint: string, config?: ApiBaseConfig): ApiBase =>
  new ApiBase(`${baseUrl}/${endpoint}`, config);
