import {
  GAEventKey,
  PurchaseCreditCard,
  PurchaseCreditCardAddRequest,
  PurchaseCreditCardAddResponse,
  PurchaseCreditCardDeleteRequest,
  PurchaseCreditCardSetAsDefaultRequest,
  Status,
} from 'types';

import {
  CreditCardsActionSetData,
  CreditCardsActionSetStatus,
  CreditCardsFetchAndSetDataHandlerReturn,
  REDUX_ACTION_TYPES,
  ThunkResult,
} from '../../types';

export const creditCardsSetStatus = (status: Status): CreditCardsActionSetStatus => ({
  type: REDUX_ACTION_TYPES.CREDITCARDS_SET_STATUS,
  status,
});

export const creditCardsSetData = (data: PurchaseCreditCard[]): CreditCardsActionSetData => ({
  type: REDUX_ACTION_TYPES.CREDITCARDS_SET_DATA,
  data,
});

export const creditCardsFetchAndSetDataHandler =
  (): ThunkResult<Promise<CreditCardsFetchAndSetDataHandlerReturn>> =>
  async (dispatch, _, services): Promise<CreditCardsFetchAndSetDataHandlerReturn> => {
    try {
      dispatch(creditCardsSetStatus(Status.LOADING));

      const apiReturn = await services.purchaseService.fetchCreditCardsList();

      if (apiReturn.isFailure) {
        throw new Error('Dev Error: Something went wrong during the fetch credit cards.');
      }

      // we are using some enums instead of strings, all properties are the same
      const creditCards = apiReturn.creditCards as PurchaseCreditCard[];

      dispatch(creditCardsSetData(creditCards));
      dispatch(creditCardsSetStatus(Status.SUCCEEDED));

      return { creditCards };
    } catch {
      dispatch(creditCardsSetStatus(Status.FAILED));

      return { creditCards: [] };
    }
  };

export const creditCardsAddCardHandler =
  ({
    sid,
    isDefaultCreditCard = true,
  }: PurchaseCreditCardAddRequest): ThunkResult<Promise<PurchaseCreditCardAddResponse>> =>
  async (dispatch, _, services): Promise<PurchaseCreditCardAddResponse> => {
    try {
      dispatch(creditCardsSetStatus(Status.LOADING));

      const { isFailure, paymentMethodId = '' } = await services.purchaseService.addCreditCard({
        sid,
        isDefaultCreditCard,
      });

      if (isFailure) {
        throw new Error('Dev Error: Something went wrong during adding the credit card.');
      }

      const { isFailure: fetchCreditCardFailure, creditCards: fetchedCreditCards = [] } =
        await services.purchaseService.fetchCreditCardsList();

      if (fetchCreditCardFailure) {
        throw new Error('Dev Error: Something went wrong during the fetch credit cards.');
      }

      // we are using some enums instead of strings, all the properties are the same
      const creditCards = fetchedCreditCards as PurchaseCreditCard[];

      dispatch(creditCardsSetData(creditCards));
      dispatch(creditCardsSetStatus(Status.SUCCEEDED));

      return { paymentMethodId };
    } catch {
      dispatch(creditCardsSetStatus(Status.FAILED));

      return {
        paymentMethodId: undefined,
      };
    }
  };

export const creditCardsDeleteCardHandler =
  ({ paymentMethodId }: PurchaseCreditCardDeleteRequest): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(creditCardsSetStatus(Status.LOADING));

      const apiDeleteCreditCardReturn = await services.purchaseService.deleteCreditCard({
        paymentMethodId,
      });

      if (apiDeleteCreditCardReturn.isFailure) {
        throw new Error('Dev Error: Something went wrong during delete the credit card.');
      }

      services.analyticsService.pushToDataLayer({ event: GAEventKey.REMOVE_CREDIT_CARD_SUCCESS });

      const apiFetchCreditCardsListReturn = await services.purchaseService.fetchCreditCardsList();

      if (apiFetchCreditCardsListReturn.isFailure) {
        throw new Error('Dev Error: Something went wrong during the fetch credit cards.');
      }

      // we are using some enums instead of strings, all the properties are the same
      const creditCards = apiFetchCreditCardsListReturn.creditCards as PurchaseCreditCard[];

      dispatch(creditCardsSetData(creditCards));
      dispatch(creditCardsSetStatus(Status.SUCCEEDED));
    } catch {
      dispatch(creditCardsSetStatus(Status.FAILED));
    }
  };

export const creditCardsSetAsDefaultCardHandler =
  ({ paymentMethodId }: PurchaseCreditCardSetAsDefaultRequest): ThunkResult<Promise<void>> =>
  async (dispatch, _, services): Promise<void> => {
    try {
      dispatch(creditCardsSetStatus(Status.LOADING));

      const apiSetAsDefaultCreditCardReturn = await services.purchaseService.setAsDefaultCreditCard({
        paymentMethodId,
      });

      if (apiSetAsDefaultCreditCardReturn.isFailure) {
        throw new Error('Dev Error: Something went wrong during set the credit card as default.');
      }

      const apiFetchCreditCardsListReturn = await services.purchaseService.fetchCreditCardsList();

      if (apiFetchCreditCardsListReturn.isFailure) {
        throw new Error('Dev Error: Something went wrong during the fetch credit cards.');
      }

      // we are using some enums instead of strings, all the properties are the same
      const creditCards = apiFetchCreditCardsListReturn.creditCards as PurchaseCreditCard[];

      dispatch(creditCardsSetData(creditCards));
      dispatch(creditCardsSetStatus(Status.SUCCEEDED));
    } catch {
      dispatch(creditCardsSetStatus(Status.FAILED));
    }
  };
