import { useState, useEffect } from 'react';

type FlagImg = {
  alt: string;
  src: string;
};

type UseCountryFlag = {
  flag: FlagImg;
  onError: () => void;
};

const flagPath = `${process.env.PUBLIC_URL}/flags/`;

const defaultFlag = {
  alt: 'Flag image missing',
  src: `${flagPath}fallback-flag.svg`,
};

const useCountryFlag = (country = ''): UseCountryFlag => {
  const [flag, setFlag] = useState<FlagImg>(defaultFlag);

  const onError = () => {
    setFlag(defaultFlag);
  };

  useEffect(() => {
    if (country.length > 0) {
      setFlag({
        alt: `Flag of ${country.toUpperCase()}`,
        src: `${flagPath}${country.toLowerCase()}.svg`,
      });
    }
  }, [country]);

  return {
    flag,
    onError,
  };
};

export default useCountryFlag;
