import React from 'react';

import { StyleableComponent } from 'types';

const CallsOverInternet: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_3238_10119)">
      <rect
        width="50"
        height="50"
        fill="#935CDE"
      />
      <path
        d="M0 8.87784C0 3.97475 3.97474 0 8.87784 0L41.1222 0C46.0253 0 50 3.97474 50 8.87784V41.1222C50 46.0253 46.0253 50 41.1222 50H8.87784C3.97475 50 0 46.0253 0 41.1222L0 8.87784Z"
        fill="#935CDE"
      />
      <g clipPath="url(#clip1_3238_10119)">
        <path
          d="M25 36.7187C18.5277 36.7187 13.2812 31.4723 13.2812 25C13.2812 18.5277 18.5277 13.2812 25 13.2812C31.4723 13.2812 36.7187 18.5277 36.7187 25C36.7187 31.4723 31.4723 36.7187 25 36.7187ZM22.3164 33.9848C21.1602 31.5324 20.4905 28.8792 20.3441 26.1719H15.6977C15.9257 27.9753 16.6726 29.6736 17.8475 31.0606C19.0225 32.4476 20.575 33.4634 22.3164 33.9848V33.9848ZM22.6914 26.1719C22.8684 29.0301 23.6852 31.7148 25 34.0844C26.3504 31.6522 27.1392 28.9486 27.3086 26.1719H22.6914V26.1719ZM34.3023 26.1719H29.6559C29.5095 28.8792 28.8397 31.5324 27.6836 33.9848C29.425 33.4634 30.9775 32.4476 32.1525 31.0606C33.3274 29.6736 34.0743 27.9753 34.3023 26.1719V26.1719ZM15.6977 23.8281H20.3441C20.4905 21.1208 21.1602 18.4676 22.3164 16.0152C20.575 16.5366 19.0225 17.5524 17.8475 18.9394C16.6726 20.3264 15.9257 22.0247 15.6977 23.8281V23.8281ZM22.6926 23.8281H27.3074C27.1384 21.0515 26.35 18.3479 25 15.9156C23.6496 18.3478 22.8608 21.0514 22.6914 23.8281H22.6926ZM27.6836 16.0152C28.8397 18.4676 29.5095 21.1208 29.6559 23.8281H34.3023C34.0743 22.0247 33.3274 20.3264 32.1525 18.9394C30.9775 17.5524 29.425 16.5366 27.6836 16.0152V16.0152Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3238_10119">
        <rect
          width="50"
          height="50"
          rx="10"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_3238_10119">
        <rect
          width="28.125"
          height="28.125"
          fill="white"
          transform="translate(10.9375 10.9375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CallsOverInternet;
