export const testIsQAAutomationEnabled = (): boolean => process.env.REACT_APP_QA_AUTOMATION_ENABLED === 'true';

export const testIsTestingEnvironment = (): boolean => process.env.NODE_ENV === 'test';

export const testGetTestingAttributes = (id = ''): Record<string, string> => {
  if (!testIsQAAutomationEnabled() || id.length === 0) {
    return {};
  }

  return {
    'data-testid': id,
  };
};
