import classNames from 'classnames';
import React from 'react';

import { StyleableComponent } from 'types';

import { IllustrationCommon } from 'components/Illustration';
import { Modal, ModalSize } from 'components/Modal';
import { useUserCapabilities } from 'hooks';

import { ModalsConfirmationType } from '../../enums';
import { getButtonColorScheme, getIllustrationColorScheme, getIllustrationType } from '../../helpers';
import ModalsConfirmationButtons from '../ModalsConfirmationButtons';

import styles from './ModalsConfirmation.module.scss';

export interface ModalsConfirmationProps extends StyleableComponent {
  isOpen: boolean;
  type: ModalsConfirmationType;
  title: string;
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm: () => void;
  onCancel: () => void;
  content?: React.ReactNode;
  isLoading?: boolean;
}

const ModalsConfirmation: React.FC<ModalsConfirmationProps> = ({
  isOpen,
  type,
  title,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  className,
  content,
  isLoading = false,
}) => {
  const { isUserB2B } = useUserCapabilities();
  const illustrationType = getIllustrationType(type);
  const buttonColorScheme = getButtonColorScheme(type, isUserB2B);
  const illustrationColorScheme = getIllustrationColorScheme(type, isUserB2B);

  return (
    <Modal
      isOpen={isOpen}
      canEscClose={false}
      canOverlayClose={false}
      size={ModalSize.XL}
      className={classNames(styles.root, className)}
    >
      <div className={styles.illustrationContainer}>
        {illustrationType && (
          <IllustrationCommon
            name={illustrationType}
            className={classNames(styles.illustration, illustrationColorScheme && styles[illustrationColorScheme])}
          />
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.textContainer}>
          <h3 className={styles.title}>{title}</h3>
          <div className={styles.description}>{content}</div>
        </div>
        <div className={styles.buttons}>
          <ModalsConfirmationButtons
            colorScheme={buttonColorScheme}
            confirmText={confirmButtonText}
            cancelText={cancelButtonText}
            onConfirm={onConfirm}
            onCancel={onCancel}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalsConfirmation;
