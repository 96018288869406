import { createSelector } from '@reduxjs/toolkit';

import { SelectPushState, SelectPushStatuses, SelectPushIdPushLast, SelectPushHasMissingPushes } from '../../types';

export const selectPushState: SelectPushState = (state) => state.push;

export const selectPushStatuses: SelectPushStatuses = createSelector(
  [selectPushState],
  (pushState) => pushState.statuses,
);

export const selectPushIdPushLast: SelectPushIdPushLast = createSelector([selectPushState], (pushState) => ({
  idPushLast: pushState.idPushLast,
}));

export const selectPushHasMissingPushes: SelectPushHasMissingPushes = createSelector(
  [selectPushState],
  (pushState) => ({
    hasMissingPushes: pushState.hasMissingPushes,
  }),
);
