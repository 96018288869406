import { useCallback } from 'react';

import { useWebAppSelector, selectSIPDataCallsCapabilities } from '@redux';
import { SIPStatisticsScopes } from '@redux/types';

import { sipDebugConsoleLogger, sipGetModifiedSDPForPCMACodec } from '../../../../../../../helpers';
import { useSIPStatistics } from '../../../../../../useSIPStatistics';

import { UseWebRTCSending, OnSending } from './types';

export const useWebRTCSending: UseWebRTCSending = () => {
  const { isCallsWebRTCHdCodecEnabled } = useWebAppSelector(selectSIPDataCallsCapabilities);

  const { sipSendErrorToSentry } = useSIPStatistics();

  const onSending: OnSending = useCallback(
    ({ event }): void => {
      /**
       * "onSending"
       * This is the first INVITE with the "sipUserAgent?.call(rtcSessionCallTarget, rtcSessionCallOptions)"
       *
       * This API is only available for Outgoing Calls (SIPWebRTCOriginators.LOCAL)
       * https://jssip.net/documentation/api/session/#event_sending
       */
      sipDebugConsoleLogger('SIP WebRTC: SENDING EVENT: ', { event });

      /**
       * PCMA Codec, a.k.a HD Calls
       */
      if (isCallsWebRTCHdCodecEnabled) {
        try {
          /**
           * We now, param reassign is needed, and it is OK for this case
           */
          const { sdpNew } = sipGetModifiedSDPForPCMACodec({ sdpCurrent: event.request.body });
          // eslint-disable-next-line no-param-reassign
          event.request.body = sdpNew;
        } catch (error) {
          sipSendErrorToSentry({
            scope: SIPStatisticsScopes.SIP_WEBRTC,
            error: `SESSION INVITE PCMA CODEC FAILED! "${error instanceof Error ? error.message : error}"`,
          });
        }
      }
    },
    [isCallsWebRTCHdCodecEnabled, sipSendErrorToSentry],
  );

  return { onSending };
};
