import React from 'react';
import { useIntl } from 'react-intl';

import { testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { SIPCallsListItemButtonFeaturesCallNotesProps } from '../../types';
import SIPCallsListItemButtonTooltip from '../SIPCallsListItemButtonTooltip';

import styles from './SIPCallsListItemButtonFeaturesCallNotes.module.scss';

const SIPCallsListItemButtonFeaturesCallNotes: React.FC<SIPCallsListItemButtonFeaturesCallNotesProps> = ({
  isCallOngoing,
  isCallWaiting,
  onClickHandlerCallNotesModalOpen,
}) => {
  /**
   * Only the Ongoing Calls can have Call Notes feature
   */
  const isFeatureNotAvailable = !isCallOngoing;
  if (isFeatureNotAvailable) {
    return null;
  }

  const intl = useIntl();

  return (
    <SIPCallsListItemButtonTooltip content={intl.formatMessage({ id: 'SIP.Calls.UI.ButtonTooltips.notes' })}>
      <IconButton
        className={styles.button}
        iconName={IconCommonNames.EDIT}
        iconSize={IconCommonSizes.TINY}
        isDisabled={isCallWaiting}
        onClick={onClickHandlerCallNotesModalOpen}
        propTestId={testIds.sipCallsListItemButtonFeatureCallNotesRoot}
      />
    </SIPCallsListItemButtonTooltip>
  );
};

export default SIPCallsListItemButtonFeaturesCallNotes;
