import React from 'react';

import { StyleableComponent } from 'types';

const NumberRenewal: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M200 100C200 155.228 155.228 200 100 200C44.7715 200 0 155.228 0 100C0 44.7715 44.7715 0 100 0C155.228 0 200 44.7715 200 100Z"
      fill="#E6E6E6"
      fillOpacity="0.1"
    />
    <path
      d="M187.368 99.9998C187.368 148.252 148.252 187.368 100 187.368C51.7478 187.368 12.6316 148.252 12.6316 99.9998C12.6316 51.7475 51.7478 12.6313 100 12.6313C148.252 12.6313 187.368 51.7475 187.368 99.9998Z"
      fill="#E6E6E6"
      fillOpacity="0.2"
    />
    <path
      d="M172.105 99.9998C172.105 139.822 139.823 172.105 100 172.105C60.1774 172.105 27.8948 139.822 27.8948 99.9998C27.8948 60.1772 60.1774 27.8945 100 27.8945C139.823 27.8945 172.105 60.1772 172.105 99.9998Z"
      fill="#F3F4F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184.434 108.242L30.3458 149.53C27.0774 150.405 23.7359 148.537 22.8833 145.355L10.5422 99.2972C9.69019 96.1176 11.6499 92.8288 14.9183 91.9531L169.007 50.6652C172.275 49.7894 175.617 51.6578 176.469 54.8373L188.81 100.895C189.662 104.077 187.703 107.366 184.434 108.242Z"
      fill="#E8E9EB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M185.335 106.761L31.2466 148.049C27.9783 148.925 24.6367 147.057 23.7841 143.874L11.443 97.8167C10.591 94.6372 12.5507 91.3484 15.8191 90.4726L169.908 49.1847C173.176 48.3089 176.517 50.1773 177.369 53.3569L189.711 99.4146C190.563 102.597 188.604 105.885 185.335 106.761Z"
      fill="white"
    />
    <path
      d="M58.0449 104.875C62.6444 111.444 61.048 120.498 54.4793 125.097C47.9105 129.697 38.8569 128.1 34.2575 121.531C29.658 114.963 31.2544 105.909 37.8231 101.31C44.3918 96.7101 53.4454 98.3065 58.0449 104.875Z"
      fill="#F4F4F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.4108 93.7775C69.1059 92.6397 69.7811 91.4701 70.9189 91.1653L164.157 66.1822C165.295 65.8773 166.464 66.5526 166.769 67.6904L168.046 72.4574C168.351 73.5952 167.676 74.7647 166.538 75.0696L73.3003 100.053C72.1625 100.358 70.993 99.6823 70.6881 98.5445L69.4108 93.7775ZM128.699 99.972C129.004 101.11 128.329 102.279 127.191 102.584L77.5432 115.887C76.4054 116.192 75.2359 115.517 74.931 114.379L73.6537 109.612C73.3488 108.474 74.024 107.305 75.1618 107L124.81 93.6969C125.948 93.392 127.117 94.0672 127.422 95.205L128.699 99.972Z"
      fill="#E3E4E8"
    />
    <path
      d="M171.8 123.969C171.8 134.352 163.383 142.769 153 142.769C142.617 142.769 134.2 134.352 134.2 123.969C134.2 113.586 142.617 105.169 153 105.169C163.383 105.169 171.8 113.586 171.8 123.969Z"
      fill="currentColor"
    />
    <path
      d="M163.581 121.776C163.298 120.37 162.745 119.034 161.955 117.846C161.178 116.677 160.188 115.67 159.039 114.879C157.87 114.075 156.558 113.513 155.176 113.225C154.45 113.075 153.711 113.002 152.97 113.005V110.561L148.2 114.224L152.97 117.886V115.447C153.551 115.445 154.132 115.501 154.692 115.618C155.766 115.842 156.787 116.279 157.695 116.903C158.59 117.518 159.361 118.302 159.965 119.213C160.903 120.624 161.403 122.288 161.4 123.991C161.4 125.133 161.176 126.264 160.74 127.317C160.529 127.825 160.27 128.31 159.968 128.768C159.664 129.222 159.319 129.647 158.938 130.036C157.776 131.216 156.302 132.025 154.695 132.365C153.577 132.594 152.426 132.594 151.308 132.365C150.234 132.14 149.213 131.703 148.305 131.078C147.41 130.464 146.64 129.68 146.037 128.77C145.1 127.358 144.6 125.694 144.6 123.991H142.2C142.202 126.18 142.845 128.32 144.047 130.136C144.825 131.304 145.813 132.31 146.961 133.101C148.743 134.328 150.848 134.982 153 134.978C153.731 134.978 154.461 134.903 155.177 134.755C156.558 134.465 157.87 133.903 159.039 133.101C159.613 132.707 160.148 132.258 160.636 131.759C161.125 131.26 161.567 130.716 161.956 130.133C163.161 128.32 163.804 126.18 163.8 123.991C163.8 123.247 163.727 122.505 163.581 121.776Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.279 175.794L141.655 173.411L139 172.279L141.384 170.654L142.515 168L144.14 170.384L146.794 171.515L144.411 173.14L143.279 175.794Z"
      fill="#E4E4E4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2793 75.7944L10.6547 73.4106L8 72.2793L10.3838 70.654L11.5151 68L13.1397 70.3844L15.7944 71.5151L13.4106 73.1397L12.2793 75.7944Z"
      fill="#E4E4E4"
    />
  </svg>
);

export default NumberRenewal;
