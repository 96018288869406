import {
  Category,
  CategorySwitchStatusRequest,
  CategoryPlanStatusTypes,
  PlansStatistics,
  PlansStatisticsKeys,
} from 'types';

import { Categories, CategorySwitchStatusTypes } from '@redux/types';

import { CategoryColorsStatic } from './colors';

export const getCategoryById = (categories: Category[], id: Category['id'] = ''): Category | undefined =>
  categories?.find((category) => category?.id === id);

export const getCategoriesByVisibility = (categories: Category[] = []): Category[] =>
  categories.filter((category) => category.isVisible);

export const getCategoryPhoneNumber = (category?: Category): string => {
  const { virtualPhoneNumber: { number = '' } = {} } = category || {};
  return number;
};

export const isCategoryExpired = (category?: Category): boolean => {
  const { virtualPhoneNumber: { planStatus = '' } = {} } = category || {};
  return planStatus === CategoryPlanStatusTypes.EXPIRED;
};

export const isCategoryLandlineNumber = (category?: Category | null): boolean => {
  const { virtualPhoneNumber: { smsSupported = false } = {} } = category || {};
  return !smsSupported;
};

export const isCategoryMessageEnabled = (category?: Category | null): boolean => {
  const { messageEnabled = false } = category || {};
  return messageEnabled;
};

export const isCategoryCallEnabled = (category?: Category | null): boolean => {
  const { callEnabled = false } = category || {};
  return callEnabled;
};

export const getCategoryNameFirstLetter = (name: Category['name']): string => (name || '')[0] || '...';

export const sortCategoriesByExpiredFirst = (categories: Category[]): Category[] =>
  [...categories].sort((catA: Category, catB: Category) =>
    isCategoryExpired(catA) || isCategoryExpired(catB)
      ? new Date(
          catA?.virtualPhoneNumber?.expirationDate || new Date().setFullYear(new Date().getFullYear() + 1),
        ).getTime() -
        new Date(
          catB?.virtualPhoneNumber?.expirationDate || new Date().setFullYear(new Date().getFullYear() + 1),
        ).getTime()
      : 0,
  );

export const filterCategoriesByActiveAndNonExpired = (categories: Category[]): Category[] =>
  categories.filter((category) => category.active && !isCategoryExpired(category));

export const filterCategoriesByActiveLandlineNumbers = (categories: Category[]): Category[] =>
  filterCategoriesByActiveAndNonExpired(categories.filter((category) => isCategoryLandlineNumber(category)));

export const filterCategoriesByCanSendMessages = (categories: Category[]): Category[] =>
  filterCategoriesByActiveAndNonExpired(categories).filter(
    (category) => isCategoryMessageEnabled(category) && !isCategoryLandlineNumber(category),
  );

export const filterCategoriesByCanMakeCalls = (categories: Category[]): Category[] =>
  filterCategoriesByActiveAndNonExpired(categories).filter((category) => isCategoryCallEnabled(category));

/**
 * The formula has been provided by Business => https://onoffapp.atlassian.net/browse/WAP-1425?focusedCommentId=85382
 *
 * [[ 30 - (current_date - expiration_date) ]]
 *
 * @param {string} expirationDate
 * @returns {number} The remaining days for removing the category by the expirationDate
 */
export const calculateCategoryExpiredRemainingDays = (expirationDate: string): number => {
  const theDaysForKeepingTheNumber = 30; // provided by PM
  const oneDayAsMilliseconds = 1000 * 60 * 60 * 24;
  const remainingDays = Math.round(
    theDaysForKeepingTheNumber -
      (new Date().getTime() - new Date(expirationDate || '').getTime()) / oneDayAsMilliseconds,
  );
  return remainingDays;
};

export const normalizeCategoryName = (name: string): string =>
  name
    .replace(/(<([^>]+)>)/gim, '')
    .substring(0, 50)
    .trim();

export const createCategoryStatusBySwitchType = ({
  category,
  switchTo,
  switchFor,
}: {
  category: Category;
  switchTo: boolean;
  switchFor: CategorySwitchStatusTypes;
}): CategorySwitchStatusRequest => {
  const status = {
    categoryId: category.id,
    enable: category.active,
    callEnable: category.callEnabled,
    messageEnable: category.messageEnabled,
    voicemailEnable: category.voicemailEnabled,
  };

  switch (switchFor) {
    case CategorySwitchStatusTypes.CATEGORY:
      return {
        ...status,
        enable: switchTo,
        callEnable: switchTo,
        messageEnable: switchTo,
      };

    case CategorySwitchStatusTypes.CALLS:
      return {
        ...status,
        enable: category.messageEnabled || switchTo,
        callEnable: switchTo,
      };

    case CategorySwitchStatusTypes.MESSAGES:
      return {
        ...status,
        enable: category.callEnabled || switchTo,
        messageEnable: switchTo,
      };

    case CategorySwitchStatusTypes.VOICEMAILS:
      return {
        ...status,
        voicemailEnable: switchTo,
      };

    default:
      return status;
  }
};

export const createCategoryColor = (category: Category): Category['color'] => {
  if (category.virtualPhoneNumber.planStatus === CategoryPlanStatusTypes.EXPIRED) {
    return CategoryColorsStatic.EXPIRED;
  }

  if (!category.active) {
    return CategoryColorsStatic.DISABLED;
  }

  return category.color;
};

export const changeCategoriesColors = (categories: Categories): Categories =>
  Object.values(categories).reduce((prevCategories, category) => {
    const colouredCategory = {
      ...category,
      color: createCategoryColor(category),
    };

    // eslint-disable-next-line no-param-reassign
    prevCategories[category.id] = colouredCategory;

    return prevCategories;
  }, {});

export const canCategorySendSMS = (category: Category): boolean => category?.virtualPhoneNumber?.smsSupported;

export const canCategorySendMMS = (category: Category): boolean => category?.virtualPhoneNumber?.mmsSupported;

export const isCategoryStatisticsDataValid = (statistics: PlansStatistics): boolean => {
  const requiredProperties: PlansStatisticsKeys[] = [
    'categoryId',
    'date',
    'calls',
    'remainingCalls',
    'sms',
    'remainingSms',
  ];

  return requiredProperties.every((prop) => statistics[prop] !== undefined);
};

export const isCategoryStatisticsArrayValid = (
  statistics: PlansStatistics[],
  categoryIds: Category['id'][],
): boolean => {
  if (categoryIds.length !== statistics.length) {
    return false;
  }

  return statistics.every(isCategoryStatisticsDataValid);
};
