import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, AuthenticationReduxState, AuthenticationActionTypes } from '../../types';

export const authenticationInitialState: AuthenticationReduxState = {
  statuses: {
    statusCaptchaCheck: Status.IDLE,
    statusCaptchaVerifyAndRegisterToken: Status.IDLE,
    statusRenewAuthKey: Status.IDLE,
    statusLogin: Status.IDLE,
    statusLogout: Status.IDLE,
    statusPasswordRecoveryRequest: Status.IDLE,
    statusPasswordRecoveryVerify: Status.IDLE,
    statusPasswordRecoveryChange: Status.IDLE,
  },
  captcha: {
    isCaptchaRequired: true,
    isCaptchaRendered: false,
  },
  login: {
    loginErrorCode: '',
  },
};

export const authenticationReducer: Reducer<AuthenticationReduxState, AuthenticationActionTypes> = (state, action) => {
  const reducerState: AuthenticationReduxState = state ?? authenticationInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_CAPTCHA_CHECK: {
      const { statusCaptchaCheck } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusCaptchaCheck,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_CAPTCHA_VERIFY_AND_REGISTER: {
      const { statusCaptchaVerifyAndRegisterToken } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusCaptchaVerifyAndRegisterToken,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_RENEW_AUTH_KEY: {
      const { statusRenewAuthKey } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusRenewAuthKey,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_LOGIN: {
      const { statusLogin } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusLogin,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_LOGOUT: {
      const { statusLogout } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusLogout,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_REQUEST: {
      const { statusPasswordRecoveryRequest } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusPasswordRecoveryRequest,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_VERIFY: {
      const { statusPasswordRecoveryVerify } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusPasswordRecoveryVerify,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_STATUS_PASSWORD_RECOVERY_CHANGE: {
      const { statusPasswordRecoveryChange } = action;

      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          statusPasswordRecoveryChange,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_CAPTCHA_REQUIRE: {
      const { isCaptchaRequired } = action;

      return {
        ...reducerState,
        captcha: {
          ...reducerState.captcha,
          isCaptchaRequired,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_CAPTCHA_RENDERED: {
      const { isCaptchaRendered } = action;

      return {
        ...reducerState,
        captcha: {
          ...reducerState.captcha,
          isCaptchaRendered,
        },
      };
    }

    case REDUX_ACTION_TYPES.AUTHENTICATION_SET_LOGIN_ERROR_CODE: {
      const { loginErrorCode } = action;

      return {
        ...reducerState,
        login: {
          ...reducerState.login,
          loginErrorCode,
        },
      };
    }

    default:
      return reducerState;
  }
};
