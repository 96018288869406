import React from 'react';

import { Loading } from '@onoff/ui';

import styles from './SIPCallsListItemNotificationButton.module.scss';

type SIPCallsListItemNotificationButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  isLoading?: boolean;
};

export const SIPCallsListItemNotificationButton: React.FC<SIPCallsListItemNotificationButtonProps> = ({
  children,
  onClick,
  isLoading,
}) => (
  <button
    className={styles.root}
    onClick={onClick}
    disabled={isLoading}
  >
    {isLoading ? <Loading /> : children}
  </button>
);
