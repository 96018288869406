import React from 'react';

import { SIPDataCallStatusesView } from '@redux/types';
import { testIds } from '@testing';
import { IconButton, IconCommonNames, IconCommonSizes } from 'components/Icon';

import { useSIPCallMethodsOngoing } from '../../../../hooks/useSIPCallMethods';
import { SIPCallsListItemButtonUIViewProps } from '../../types';

import styles from './SIPCallsListItemButtonUIView.module.scss';

const SIPCallsListItemButtonUIView: React.FC<SIPCallsListItemButtonUIViewProps> = ({ statusView, idWebRTC }) => {
  const { sipCallChangeView } = useSIPCallMethodsOngoing();
  const isCallViewMinimized = statusView === SIPDataCallStatusesView.MINIMIZED;
  const isCallViewCompleted = statusView === SIPDataCallStatusesView.COMPLETED;

  if (isCallViewCompleted) {
    return null;
  }

  const onClickHandlerChangeView = (): void => {
    sipCallChangeView({
      idWebRTC,
      statusView: isCallViewMinimized ? SIPDataCallStatusesView.DEFAULT : SIPDataCallStatusesView.MINIMIZED,
    });
  };

  return (
    <IconButton
      iconName={isCallViewMinimized ? IconCommonNames.OPEN_IN_FULL : IconCommonNames.CLOSE_FULL}
      iconSize={IconCommonSizes.TINY}
      onClick={onClickHandlerChangeView}
      className={styles.root}
      propTestId={testIds.sipCallsListItemButtonUIViewRoot}
    />
  );
};

export default SIPCallsListItemButtonUIView;
