import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';

import { showSuccessToast } from '@onoff/toast-notification';

import { Category, Status } from 'types';

import { isCategoryLandlineNumber } from 'helpers';

import { MESSAGES } from '@constants';
import {
  useWebAppDispatch,
  useWebAppSelector,
  messagesSetStatusSendMessageUpdate,
  messagesSendMessageHandler,
  selectMessagesStatuses,
} from '@redux';
import { isSMSValueValid, SMS } from 'components/SMS';
import { ApplicationPaths } from 'routes';

import { createSelectedPhoneNumberWithId, getPhoneNumberList } from '../../helpers';
import { useSelectedPhoneNumber } from '../../hooks';
import { ModalsNewThreadContentProps, SelectedPhoneNumber, SelectedPhoneNumberWithId } from '../../types';
import ModalsNewThreadContactField from '../ModalsNewThreadContactField';
import ModalsNewThreadNotification, { ModalsNewThreadNotificationVariant } from '../ModalsNewThreadNotification';

import styles from './ModalsNewThreadContent.module.scss';

const ModalsNewThreadContent: React.FC<ModalsNewThreadContentProps> = ({
  selectedPhoneNumberList = [],
  selectedCategory,
  onSendSucceeded,
}) => {
  const intl = useIntl();
  const { pathname: currentPath } = useLocation();
  const dispatch = useWebAppDispatch();
  const { sendMessage: statusSendMessage } = useWebAppSelector(selectMessagesStatuses);

  /**
   * contact field empty -> focus contact input
   * contact field prefilled -> focus message input
   */
  const focusContactFieldInitially = selectedPhoneNumberList.length === 0;

  const [activeCategory, setActiveCategory] = useState<Category>();
  const [hasFocusTextArea, setHasFocusTextArea] = useState<boolean>(!focusContactFieldInitially);
  const [messageValue, setMessageValue] = useState<string | File>('');
  const [selectedPhoneNumberWithIdList, setSelectedPhoneNumberWithIdList] =
    useSelectedPhoneNumber(selectedPhoneNumberList);
  const [isCategorySelectedByUser, setIsCategorySelectedByUser] = useState<boolean>(false);

  const multiRecipientContactLimit = MESSAGES.MULTI_RECIPIENT_CONTACT_LIMIT;
  const hasInvalidSelection = !!selectedPhoneNumberWithIdList.find((phoneNumberWithId) => !phoneNumberWithId.isValid);
  const isSendingDisabled =
    hasInvalidSelection || !isSMSValueValid(messageValue) || selectedPhoneNumberWithIdList.length < 1;
  const isSending = statusSendMessage !== Status.IDLE;

  const handleChangeCategory = useCallback(
    (category: Category) => {
      setActiveCategory(category);
      setHasFocusTextArea(true);
      if (!isCategorySelectedByUser) {
        setIsCategorySelectedByUser(true);
      }
    },
    [isCategorySelectedByUser],
  );

  const handleRemoveSelectedPhoneNumber = (id: SelectedPhoneNumberWithId['id']) => {
    setSelectedPhoneNumberWithIdList(
      selectedPhoneNumberWithIdList.filter(
        (selectedPhoneNumberWithIdFromList) => id !== selectedPhoneNumberWithIdFromList.id,
      ),
    );
  };

  const handleSelectPhoneNumber = (phoneNumber: SelectedPhoneNumber) => {
    setSelectedPhoneNumberWithIdList([...selectedPhoneNumberWithIdList, createSelectedPhoneNumberWithId(phoneNumber)]);
  };

  const handleSendMessage = (value: string | File, sourceCategory: Category) => {
    dispatch(
      messagesSendMessageHandler({
        value,
        sourceCategory,
        targetPhoneNumbers: getPhoneNumberList(selectedPhoneNumberWithIdList),
      }),
    );
  };

  const callbackCategorySelectedByUserHandler = (): void => {
    if (!isCategorySelectedByUser) {
      const firstContactWithCategory = selectedPhoneNumberWithIdList.find(
        (selectedPhoneNumber) => selectedPhoneNumber.category,
      );
      if (firstContactWithCategory?.category) {
        setActiveCategory((currentActiveCategory) => {
          if (!currentActiveCategory || firstContactWithCategory.category?.id !== currentActiveCategory.id) {
            return firstContactWithCategory.category;
          }
          return currentActiveCategory;
        });
      }
    }
  };

  const callbackSelectedCategoryHandler = (): void => {
    if (selectedCategory !== undefined && !isCategoryLandlineNumber(selectedCategory)) {
      handleChangeCategory(selectedCategory);
    }
  };

  const onSendSucceededNotification = useCallback(() => {
    if (currentPath !== ApplicationPaths.MESSAGES) {
      showSuccessToast({
        message: intl.formatMessage({ id: 'ModalNewThread.Notifications.success_description' }),
      });
    }
  }, [currentPath, intl]);

  const callbackSendMessageSucceeded = (): void => {
    if (statusSendMessage === Status.SUCCEEDED) {
      dispatch(messagesSetStatusSendMessageUpdate(Status.IDLE));
      setMessageValue('');
      onSendSucceeded();
      onSendSucceededNotification();
    }
  };

  const callbackSendMessageFailed = (): void => {
    if (statusSendMessage === Status.FAILED) {
      dispatch(messagesSetStatusSendMessageUpdate(Status.IDLE));
    }
  };

  useEffect(callbackCategorySelectedByUserHandler, [isCategorySelectedByUser, selectedPhoneNumberWithIdList]);
  useEffect(callbackSelectedCategoryHandler, [selectedCategory, handleChangeCategory]);
  useEffect(callbackSendMessageSucceeded, [dispatch, statusSendMessage, onSendSucceeded, onSendSucceededNotification]);
  useEffect(callbackSendMessageFailed, [dispatch, statusSendMessage]);

  return (
    <div className={styles.root}>
      <div className={styles.modalsNewThreadContactFieldContainer}>
        <ModalsNewThreadContactField
          className={styles.contactField}
          isInputDisabled={selectedPhoneNumberWithIdList.length >= multiRecipientContactLimit}
          onRemoveContact={handleRemoveSelectedPhoneNumber}
          onSelectContact={handleSelectPhoneNumber}
          selectedPhoneNumberWithIdList={selectedPhoneNumberWithIdList}
          hasFocusInitially={focusContactFieldInitially}
        />
        {selectedPhoneNumberWithIdList.length >= multiRecipientContactLimit && (
          <ModalsNewThreadNotification>
            <FormattedMessage id={'ModalNewThread.receivers_limit'} />
          </ModalsNewThreadNotification>
        )}
        {hasInvalidSelection && (
          <ModalsNewThreadNotification variant={ModalsNewThreadNotificationVariant.ERROR}>
            <FormattedMessage id={'ModalNewThread.invalid_recipient_in_list'} />
          </ModalsNewThreadNotification>
        )}
      </div>
      <SMS
        activeThreadCategory={activeCategory}
        hasFocus={hasFocusTextArea}
        isNewThread
        isDisabled={isSendingDisabled}
        isSending={isSending}
        onBlur={() => setHasFocusTextArea(false)}
        onChange={setMessageValue}
        onChangeCategory={handleChangeCategory}
        onFocus={() => setHasFocusTextArea(true)}
        onSend={handleSendMessage}
        value={messageValue}
      />
    </div>
  );
};

export default ModalsNewThreadContent;
