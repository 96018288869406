import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { SideMenuStyle as s1 } from 'components/UI/styleRoutes';
import sha1 from 'helpers/sha1';

import Modal from 'components/Universal/ModalUni';

import * as CategoriesActions from '@redux/actions/categories';
import * as StealthActions from '@redux/actions/stealth';

import { InputPassword } from '../Input';

import styles from './StealthModal.module.scss';
import { showErrorToast } from '@onoff/toast-notification';
import { getIntl } from '@intl';

class StealthModal extends Component {
  static contextTypes = {
    serverData: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      passCode1: '',
      passCode2: '',
    };

    this.resetState = this.resetState.bind(this);
    this.closeAndReset = this.closeAndReset.bind(this);
    this.requestUnlock = this.requestUnlock.bind(this);
    this.requestLock = this.requestLock.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePassCode1 = this.handlePassCode1.bind(this);
    this.handlePassCode2 = this.handlePassCode2.bind(this);
    this.modalContent = this.modalContent.bind(this);
  }

  componentDidMount() {
    this.resetState();
  }

  resetState() {
    this.setState({ step: 1, passCode1: '', passCode2: '' });
  }

  closeAndReset() {
    this.props.stealthActions.stealthCloseModal();
    this.resetState();
  }

  async requestUnlock(categoryId) {
    const { text } = window.serverData;
    const privatePassword = sha1.hash(this.state.passCode1);
    const switchOn = false;
    const category = this.props.categories.data[categoryId];

    /*
     *  "/mobile/v3/get-categories" returns "privatePass" but "/mobile/switch-category-privacy" returns "privatePassword"
     */
    if ([category.privatePass, category.privatePassword].includes(privatePassword)) {
      this.props.categoriesActions.switchCategoryPrivacy(categoryId, switchOn).then(() => {
        this.closeAndReset();
      });
    } else {
      showErrorToast({
        heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_error' }),
        message: text.SideMenu.passwords_dont_match,
      });
    }
  }

  async requestLock(categoryId) {
    const privatePassword = sha1.hash(this.state.passCode1);
    const switchOn = true;
    this.props.categoriesActions.switchCategoryPrivacy(categoryId, switchOn, privatePassword).then(() => {
      this.closeAndReset();
    });
  }

  handleSubmit() {
    const { text } = window.serverData;
    const { step, passCode1, passCode2 } = this.state;

    switch (step) {
      case 1:
        if (passCode1.length === 4) {
          this.setState({ step: 2 });
        }
        break;
      case 2:
        if (passCode1 === passCode2) {
          this.requestLock(this.props.stealth.categoryId);
        } else {
          showErrorToast({
            heading: getIntl().formatMessage({ id: 'Notifications.Toast.title_error' }),
            message: text.SideMenu.passwords_dont_match,
          });
          this.resetState();
        }
        break;
      default:
    }
  }

  handlePassCode1(value) {
    if (!isNaN(value)) {
      this.setState({ passCode1: value });
    }
  }

  handlePassCode2(value) {
    if (!isNaN(value)) {
      this.setState({ passCode2: value });
    }
  }

  modalContent() {
    let modalContent = null;
    const text = window.serverData.text.SideMenu;
    const { categories, stealth } = this.props;

    if (stealth.categoryId) {
      const currentCategory = categories.data[stealth.categoryId];

      // Deactivate stealth for the category
      if (currentCategory && currentCategory.isPrivate && currentCategory.isVisible) {
        modalContent = (
          <Modal.Body className={s1['stealth-box']}>
            <InputPassword
              className={styles.passwordInputWrapper}
              hasToggleVisibility
              onChange={this.handlePassCode1}
              value={this.state.passCode1}
              maxLength={4}
            />
            <div>
              <b>{text.enter_passcode}</b>
            </div>
            <button
              className="btn blue"
              onClick={this.requestUnlock.bind(this, stealth.categoryId)}
              type="button"
            >
              {text.enter}
            </button>
          </Modal.Body>
        );
      } else {
        switch (this.state.step) {
          case 1:
            modalContent = (
              <Modal.Body className={s1['stealth-box']}>
                <div>{text.stealth_step_1}</div>
                <div>{text.stealth_step_2}</div>
                <div>{text.stealth_step_3}</div>
                <InputPassword
                  className={styles.passwordInputWrapper}
                  hasToggleVisibility
                  onChange={this.handlePassCode1}
                  value={this.state.passCode1}
                  maxLength={4}
                />
                <div>
                  <b>{text.enter_passcode}</b>
                </div>
                <div className={s1.caption}>{text.stealth_explanation}</div>
                <button
                  className="btn blue"
                  onClick={this.handleSubmit}
                  type="button"
                >
                  {text.next}
                </button>
              </Modal.Body>
            );
            break;
          case 2:
            modalContent = (
              <Modal.Body className={s1['stealth-box']}>
                <InputPassword
                  className={styles.passwordInputWrapper}
                  hasToggleVisibility
                  onChange={this.handlePassCode2}
                  value={this.state.passCode2}
                  maxLength={4}
                />
                <div>
                  <b>{text.confirm_passcode}</b>
                </div>
                <div className={s1.caption}>{text.stealth_explanation}</div>
                <button
                  className="btn blue"
                  onClick={this.handleSubmit}
                  type="button"
                >
                  {text.enter}
                </button>
              </Modal.Body>
            );
            break;
          default:
            break;
        }
      }
    }

    return modalContent;
  }

  render() {
    const text = window.serverData.text.SideMenu;
    const text2 = window.serverData.text.Modals;

    return (
      <Modal
        contentLabel={text2.stealth_mode}
        isOpen={this.props.stealth.isStealthModalOpen}
        onRequestClose={this.closeAndReset}
      >
        <Modal.Wrap customClass={s1['stealth-modal']}>
          <Modal.Header>{text.stealth_title}</Modal.Header>
          {this.modalContent()}
        </Modal.Wrap>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  categoriesActions: bindActionCreators(CategoriesActions, dispatch),
  stealthActions: bindActionCreators(StealthActions, dispatch),
});

const mapStateToProps = (state) => ({
  categories: state.categories,
  stealth: state.stealth,
});

export default connect(mapStateToProps, mapDispatchToProps)(StealthModal);
