import { callQualitySurveyInitializeSurveyHandler, useWebAppDispatch } from '@redux';

import { UsePubNubMessagesHandlerCallQualitySurveyReturn } from '../../types';

export const usePubNubMessagesHandlerCallQualitySurvey = (): UsePubNubMessagesHandlerCallQualitySurveyReturn => {
  const dispatch = useWebAppDispatch();

  const pubNubMessageHandlerCallQualitySurveyOpenPopup = async () => {
    dispatch(callQualitySurveyInitializeSurveyHandler());
  };

  return {
    pubNubMessageHandlerCallQualitySurveyOpenPopup,
  };
};
