import React from 'react';

import { StyleableComponent } from 'types';

const EndCall: React.FC<StyleableComponent> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.51077 15.4804L6.51077 13.8904C6.99077 13.5104 7.27077 12.9304 7.27077 12.3204V9.72035C10.2908 8.74035 13.5608 8.73035 16.5908 9.72035V12.3304C16.5908 12.9404 16.8708 13.5204 17.3508 13.9004L19.3408 15.4804C20.1408 16.1104 21.2808 16.0504 22.0008 15.3304L23.2208 14.1104C24.0208 13.3104 24.0208 11.9804 23.1708 11.2304C16.7608 5.57035 7.10077 5.57035 0.690767 11.2304C-0.159233 11.9804 -0.159233 13.3104 0.640767 14.1104L1.86077 15.3304C2.57077 16.0504 3.71077 16.1104 4.51077 15.4804V15.4804Z"
      fill="currentColor"
    />
  </svg>
);

export default EndCall;
