import classNames from 'classnames';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useIntl } from 'react-intl';

import { BorderWrapperCore } from 'components/BorderWrapper';
import { ButtonFlag, ButtonRoundFlat } from 'components/Button';
import { DropdownPhoneTypes } from 'components/Dropdowns';

import { usePhoneNumberState } from '../../hooks';
import { InputContactPhoneNumberProps } from '../../types';
import InputCommonLayout from '../InputCommonLayout';
import InputCore from '../InputCore';

import styles from './InputContactPhoneNumber.module.scss';

const InputContactPhoneNumber = forwardRef<HTMLInputElement, InputContactPhoneNumberProps>(
  (
    {
      onChange,
      countryCode,
      error = '',
      number = '',
      type = '',
      className = '',
      label = '',
      onRemovePhone,
      onSelectCountry = () => undefined,
      onSelectPhoneType = () => undefined,
      propTestIds = {
        input: '',
        countryButton: '',
        categoryButton: '',
        categoryDropdown: '',
      },
    },
    ref,
  ) => {
    const intl = useIntl();
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

    const {
      hasFocus,
      isEmpty,
      hasHover,
      onBlur,
      onFocus,
      onMouseOver,
      onMouseLeave,
      onChangeHandler,
      defaultValue,
      currentCountryCode,
    } = usePhoneNumberState({
      value: number,
      countryCode,
      ref: inputRef,
      onChange,
    });

    const hasError = error.length > 0;

    const callbackPrefilledPhoneNumberHandler = (): void => {
      const numberParsed = parsePhoneNumberFromString(number, countryCode);
      const numberFormatted = numberParsed?.formatInternational() || number;
      if (numberFormatted.length > 0 && onChangeHandler) {
        onChangeHandler(numberFormatted);
      }
    };
    useEffect(callbackPrefilledPhoneNumberHandler, [onChangeHandler, countryCode, number]);

    return (
      <InputCommonLayout
        className={classNames(styles.root, className)}
        label={label}
        error={error}
        isWrappedByDiv={true}
      >
        <div className={styles.wrapper}>
          <ButtonFlag
            className={styles.flag}
            onClick={onSelectCountry}
            currentCountry={currentCountryCode}
            propTestId={propTestIds.countryButton}
          />
          <BorderWrapperCore
            className={styles.borderWrapper}
            hasFocus={hasFocus}
            isEmpty={isEmpty}
            hasError={hasError}
            hasHover={hasHover}
          >
            <InputCore
              className={styles.input}
              ref={inputRef}
              defaultValue={defaultValue}
              onChange={onChangeHandler}
              placeholder={intl.formatMessage({ id: 'InputContactPhoneNumber.placeholder' })}
              onBlur={onBlur}
              onFocus={onFocus}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
              propTestId={propTestIds.input}
            />
          </BorderWrapperCore>
          <DropdownPhoneTypes
            className={styles.dropdown}
            selectedValue={type}
            onSelect={onSelectPhoneType}
            propTestIds={{
              button: propTestIds.categoryButton,
              dropdown: propTestIds.categoryDropdown,
            }}
          />
          {onRemovePhone !== undefined && <ButtonRoundFlat onClick={onRemovePhone} />}
        </div>
      </InputCommonLayout>
    );
  },
);

export default InputContactPhoneNumber;
