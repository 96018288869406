import React, { HTMLAttributes } from 'react';

import fallbackFlagImg from 'assets/images/fallback-flag.svg';

import styles from './FallbackFlag.module.scss';

const FallbackFlag: React.FC<HTMLAttributes<HTMLImageElement>> = ({ ...props }) => (
  <span className={styles.flag}>
    <img
      alt=""
      src={fallbackFlagImg}
      {...props}
    />
  </span>
);

export default FallbackFlag;
