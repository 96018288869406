import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import { IconCommon, IconCommonNames } from 'components/Icon';

import { useInputState } from '../../hooks';
import { InputSearchCountryProps } from '../../types';
import InputCore from '../InputCore';

import styles from './InputSearchCountry.module.scss';

const InputSearchCountry: React.FC<InputSearchCountryProps> = ({
  onChange,
  value,
  className = '',
  autoFocus = false,
}) => {
  const { onChangeHandler } = useInputState({
    value,
    onChange,
  });
  const intl = useIntl();

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.wrapper}>
        <IconCommon
          className={styles.searchIcon}
          name={IconCommonNames.SEARCH}
        />
        <InputCore
          className={styles.input}
          value={value}
          onChange={onChangeHandler}
          placeholder={intl.formatMessage({ id: 'InputSearchCountry.placeholder' })}
          autoFocus={autoFocus}
        />
      </div>
    </div>
  );
};

export default InputSearchCountry;
