import { Scope } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { addExceptionMechanism } from '@sentry/utils';
import React, { PropsWithChildren, useEffect } from 'react';

import { ErrorServerErrorPage } from 'pages/error';
import { sentryService } from 'services';

const SentryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const beforeCapture = (scope: Scope) => {
    scope.addEventProcessor((event) => {
      addExceptionMechanism(event, {
        handled: false,
      });
      return event;
    });
  };

  useEffect(() => {
    sentryService.init();
  });

  return (
    <Sentry.ErrorBoundary
      beforeCapture={beforeCapture}
      fallback={<ErrorServerErrorPage />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryProvider;
