import { createSelector, Selector } from '@reduxjs/toolkit';

import { Contact, ContactsSimpleContactsMap, ContactsStatuses, Category, ContactListGroupKeys } from 'types';

import {
  isContactFilteredBySearchString,
  isContactFilterByFavorite,
  isContactFilterByVisibility,
  matchPhoneNumberWithContact,
  findContactsByPhoneNumber,
} from 'helpers';

import {
  WebAppState,
  ContactsReduxState,
  SelectContactsState,
  SelectContactsByPhoneNumbers,
  SelectContactsDeleteContactStatus,
  SelectContactsStatuses,
  SelectContactsActiveContactCategory,
  SelectContactsActiveContact,
  SelectContactsByCategoryId,
  SelectContactsVisible,
  SelectContactsDropCaps,
} from '../../types';
import { selectCategoriesData, selectCategoriesDataWithDynamicColor } from '../categories';
import { selectIntegrationsContacts } from '../integrations';
import { selectUserCountryIsoCode } from '../user';

export const selectContactsState: SelectContactsState = (state) => state.contacts;

export const selectSimpleContacts = createSelector([selectContactsState], (state) => state.simpleContacts);

export const selectContactsActiveId = createSelector([selectContactsState], (state) => state.activeId);

export const selectContactsSimpleContactsMap = createSelector(
  [selectContactsState],
  (state) => state.simpleContactsMap,
);

export const selectContactsActiveContact: SelectContactsActiveContact = createSelector(
  [selectContactsSimpleContactsMap, selectContactsActiveId],
  (contactsMap = {}, activeId = '') => contactsMap[activeId] || undefined,
);

export const selectContactsActiveContactCategory: SelectContactsActiveContactCategory = createSelector(
  [selectContactsActiveContact, selectCategoriesDataWithDynamicColor],
  (contact, categories) => {
    const { categoryId = '' } = contact || {};
    return (categories || {})[categoryId] || undefined;
  },
);

export const selectContactsSearchString = createSelector([selectContactsState], (state) => state.searchString);

export const selectContactsPhoneNumberContactMap = createSelector(
  [selectContactsState],
  (state: ContactsReduxState) => state.phoneNumberContactMap,
);

export const selectContactByPhoneNumber = (phoneNumber: string): Selector<WebAppState, Contact | undefined> =>
  createSelector(
    [selectContactsPhoneNumberContactMap, selectUserCountryIsoCode],
    (phoneNumberContactMap: ContactsSimpleContactsMap, userCountryIsoCode) =>
      matchPhoneNumberWithContact(phoneNumber, phoneNumberContactMap, userCountryIsoCode),
  );

export const selectContactsVisible: SelectContactsVisible = (isFilterFavorites) =>
  createSelector(
    [selectSimpleContacts, selectIntegrationsContacts, selectContactsSearchString, selectCategoriesData],
    (contactList, integrationContacts, searchString, categoriesData) => {
      const localContactsList = contactList
        .filter(
          (contact) =>
            isContactFilteredBySearchString(searchString, contact) &&
            isContactFilterByFavorite(isFilterFavorites, contact) &&
            isContactFilterByVisibility(categoriesData, contact),
        )
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

      return isFilterFavorites
        ? { [ContactListGroupKeys.ONOFF]: localContactsList }
        : { [ContactListGroupKeys.ONOFF]: localContactsList, ...integrationContacts };
    },
  );

export const selectContactsDropcaps: SelectContactsDropCaps = (isFilterFavorites) =>
  createSelector([selectContactsVisible(isFilterFavorites)], (contactList) =>
    contactList[ContactListGroupKeys.ONOFF]
      .reduce<{ key: string; dropcap: string }[]>((result, contact, index) => {
        const { displayName, key } = contact;
        const currentValue = (displayName[0] || '').toUpperCase();
        const item = { key, dropcap: currentValue };

        if (index === 0) {
          result.push(item);
        } else {
          const prevValue = result[result.length - 1].dropcap;
          if (prevValue !== currentValue) {
            result.push(item);
          }
        }

        return result;
      }, [])
      .reduce<Record<string, string>>((resultAccumulator, { key, dropcap }) => {
        const result = { ...resultAccumulator };
        result[key] = dropcap;
        return result;
      }, {}),
  );

export const selectContactsByCategoryId: SelectContactsByCategoryId = (categoryId: Category['id']) =>
  createSelector([selectSimpleContacts], (contacts): Contact[] =>
    contacts.filter((contact) => contact.categoryId === categoryId),
  );

export const selectContactsByPhoneNumbers: SelectContactsByPhoneNumbers = (phoneNumbers?: Array<string>) =>
  createSelector(
    [selectContactsPhoneNumberContactMap, selectUserCountryIsoCode],
    (phoneNumberContactMap, countryCode) => findContactsByPhoneNumber(phoneNumberContactMap, phoneNumbers, countryCode),
  );

export const selectContactsStatuses: SelectContactsStatuses = createSelector(
  [selectContactsState],
  (state) => state.statuses,
);

export const selectContactsDeleteContactStatus: SelectContactsDeleteContactStatus = createSelector(
  [selectContactsStatuses],
  (contactStatues: ContactsStatuses) => contactStatues.deleteContact,
);
