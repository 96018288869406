import {
  useWebAppDispatch,
  userUpdate,
  userPubNubUpdateAccountTypeAndFeaturesHandler,
  authenticationLogoutHandler,
} from '@redux';

import {
  UsePubNubMessagesHandlerUserReturn,
  PubNubPushMessageUserChangeUserPayload,
  PubNubPushMessageUserChangeUserAccountTypePayload,
} from '../../types';

export const usePubNubMessagesHandlerUser = (): UsePubNubMessagesHandlerUserReturn => {
  const dispatch = useWebAppDispatch();

  const pubNubMessageHandlerUserChangeUser = (user: PubNubPushMessageUserChangeUserPayload): void => {
    dispatch(userUpdate(user));
  };

  const pubNubMessageHandlerUserChangeUserAccountType = ({
    accountType,
    userFeatures,
  }: PubNubPushMessageUserChangeUserAccountTypePayload): void => {
    dispatch(userPubNubUpdateAccountTypeAndFeaturesHandler({ accountType, userFeatures }));
  };

  const pubNubMessageHandlerUserForceLogout = (): void => {
    dispatch(authenticationLogoutHandler({ shouldCallLogoutAPI: false }));
  };

  return {
    pubNubMessageHandlerUserChangeUser,
    pubNubMessageHandlerUserChangeUserAccountType,
    pubNubMessageHandlerUserForceLogout,
  };
};
