import { Reducer } from '@reduxjs/toolkit';

import { Status } from 'types';

import { REDUX_ACTION_TYPES, ThreadsActionTypes, ThreadsReduxState } from '../../types';

export const threadsInitialState: ThreadsReduxState = {
  activeThreadId: '',
  allLoaded: false,
  data: [],
  searchString: '',
  offset: undefined,
  statuses: {
    fetchThreads: Status.IDLE,
  },
};

export const threadsReducer: Reducer<ThreadsReduxState, ThreadsActionTypes> = (state, action) => {
  const reducerState: ThreadsReduxState = state || threadsInitialState;

  switch (action.type) {
    case REDUX_ACTION_TYPES.THREADS_ADD: {
      const { threads, isAllLoaded } = action;
      const lastThread = threads[threads.length - 1];
      const offset = threads.length ? lastThread.id : reducerState.offset;

      return {
        ...reducerState,
        data: [...reducerState.data, ...action.threads],
        offset,
        allLoaded: isAllLoaded ?? reducerState.allLoaded,
      };
    }

    case REDUX_ACTION_TYPES.THREADS_REMOVE: {
      return {
        ...reducerState,
        data: reducerState.data.filter((thread) => !action.threadIds.includes(thread.threadId)),
      };
    }

    case REDUX_ACTION_TYPES.THREADS_UPDATE: {
      const newThreadData = action.updates.reduce((acc, update) => {
        acc[update.threadId] = update;
        return acc;
      }, {});

      return {
        ...reducerState,
        data: reducerState.data.map((thread) => ({
          ...thread,
          ...(newThreadData[thread.threadId] !== undefined ? newThreadData[thread.threadId] : {}),
        })),
      };
    }

    case REDUX_ACTION_TYPES.THREADS_SET_SEARCH_STRING: {
      return {
        ...reducerState,
        searchString: action.searchString,
      };
    }

    case REDUX_ACTION_TYPES.THREADS_SET_STATUS: {
      return {
        ...reducerState,
        statuses: {
          ...reducerState.statuses,
          [action.request]: action.status,
        },
      };
    }

    case REDUX_ACTION_TYPES.THREADS_SET_ACTIVE_THREAD_ID: {
      return {
        ...reducerState,
        activeThreadId: action.activeThreadId,
      };
    }

    default: {
      return reducerState;
    }
  }
};
