import classNames from 'classnames';
import { CountryCode } from 'libphonenumber-js';
import React, { useRef } from 'react';

import fallbackFlag from 'assets/images/fallback-flag.svg';

import styles from './CountryFlag.module.scss';

interface CountryFlagProps extends React.HTMLAttributes<HTMLSpanElement> {
  code: CountryCode | 'EU';
}

const CountryFlag: React.FC<CountryFlagProps> = ({ code, className }) => {
  const imageRef = useRef<HTMLImageElement>(null);

  const handleSetFallbackFlag = () => {
    if (imageRef.current) {
      imageRef.current.src = fallbackFlag;
    }
  };

  return (
    <span className={classNames(styles.root, className)}>
      <img
        ref={imageRef}
        src={`${process.env.PUBLIC_URL}/flags/${code.toLowerCase()}.svg`}
        onError={handleSetFallbackFlag}
        alt={`Flag of ${code}`}
        loading="lazy"
      />
    </span>
  );
};

export default CountryFlag;
