import { SESSIONSTORAGE_KEYS } from '@constants';

import { CallsSessionStorageCallNote } from './types';

export const callsGetAllCallNotesFromSessionStorage = (): CallsSessionStorageCallNote[] => {
  try {
    const note = window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.SIP_CALLS_CALL_NOTES);
    if (note === null) {
      return [];
    }

    return JSON.parse(note);
  } catch {
    return [];
  }
};

export const callsSetCallNoteToSessionStorage = (newCallNote: CallsSessionStorageCallNote): void => {
  try {
    const existingCallNotesExceptNewNote = callsGetAllCallNotesFromSessionStorage().filter(
      ({ transactionId }) => transactionId !== newCallNote.transactionId,
    );

    const newCallNotes: CallsSessionStorageCallNote[] = [newCallNote, ...existingCallNotesExceptNewNote];
    const newCallNotesAsStringify: string = JSON.stringify(newCallNotes);

    window.sessionStorage.setItem(SESSIONSTORAGE_KEYS.SIP_CALLS_CALL_NOTES, newCallNotesAsStringify);
  } catch {
    // ignore
  }
};

export const callsGetCallNoteByTransactionIdFromSessionStorage = (
  transactionId: string,
): CallsSessionStorageCallNote | undefined => {
  const foundCallNote = callsGetAllCallNotesFromSessionStorage().find(
    (existingCallNote) => existingCallNote.transactionId === transactionId,
  );
  return foundCallNote;
};

export const callsRemoveCallNoteByTransactionIdFromSessionStorage = (transactionId: string): void => {
  try {
    const existingCallNotesExceptThisNote = callsGetAllCallNotesFromSessionStorage().filter(
      (existingCallNote) => existingCallNote.transactionId !== transactionId,
    );

    const newCallNotesAsStringify: string = JSON.stringify(existingCallNotesExceptThisNote);

    window.sessionStorage.setItem(SESSIONSTORAGE_KEYS.SIP_CALLS_CALL_NOTES, newCallNotesAsStringify);
  } catch {
    // ignore
  }
};
