import classnames from 'classnames';
import React from 'react';

import { StyleableComponent, TestableComponent } from 'types';

import { SwitchSimple, SwitchToggle } from 'components/Switch';

import styles from './CategoryCommonSwitch.module.scss';

export interface CategoryCommonSwitchProps extends StyleableComponent, TestableComponent {
  text: string;
  isSwitchedOn: boolean;
  onSwitch: SwitchToggle;
  hasBorderTop?: boolean;
  isDisabled?: boolean;
}

const CategoryCommonSwitch: React.FC<CategoryCommonSwitchProps> = ({
  text,
  isSwitchedOn,
  onSwitch,
  propTestId,
  hasBorderTop = true,
  className = '',
  isDisabled = false,
}) => (
  <div
    className={classnames(styles.root, className, {
      [styles.hasBorderTop]: hasBorderTop,
      [styles.disabled]: isDisabled,
    })}
  >
    <span
      className={classnames(styles.text, {
        [styles.disabled]: isDisabled,
      })}
    >
      {text}
    </span>
    <SwitchSimple
      isSwitchedOn={isSwitchedOn}
      onSwitch={onSwitch}
      className={styles.switch}
      propTestId={propTestId}
      isDisabled={isDisabled}
    />
  </div>
);

export default CategoryCommonSwitch;
